import React, { useEffect } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import {TextField,Divider,Button, Container, Grid, Typography, Fab} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Conexao from '../../../../../../providers/Conexao'
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import AddIcon from '@material-ui/icons/Add';


import WrapTextIcon from '@material-ui/icons/WrapText';
import OpacityIcon from '@material-ui/icons/Opacity';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalAlterarDepartamento = (props) => {
  
    const [open, setOpen] = React.useState(false)
    const [empresa, setEmpresa] = React.useState(props.empresa)
    const [descricao, setDescricao] = React.useState('')
    const [responsavel, setResponsavel] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [departamento, setDepartamento] = React.useState(props.departamento)


    useEffect(()=>{
        if(open){
        
            setDepartamento(props.departamento)
            setDescricao(props.departamento.descricao)
            setResponsavel(props.departamento.responsavel)
            setEmail(props.departamento.emailresponsavel)
        }
    }, [open])
    const alterDepartamento = () =>{  
      if(descricao ==''){
        Swal.fire({
          title : 'Printerconnect',
          icon : 'warning', 
          text : 'Por favor preencha o campo Descrição !', 
          showCancelButton : false, 
          showConfirmButton : false, 
          timer : 2000, 
        
          })

      }else{
        Conexao.post('bilhetagemController/updateDepartamentoBilling',{
          idempresa: props.empresa.idempresa,
          idcliente: props.empresa.idcliente, 
          iddepartamento: departamento.iddepartamento,
          descricao: descricao,
          responsavel: responsavel,
          email: email

         
         

      }).then( response =>{
          
          if(response.data.status =='OK'){                
              Swal.fire({
              title : 'Printerconnect',
              icon : 'success', 
              text : 'Editado com sucesso !', 
              showCancelButton : false, 
              showConfirmButton : false, 
              timer : 2000, 
            
              }) 
              props.getDepartamento()     
                                   
                  
            
              setOpen(false);
           


              
      }else{
          Swal.fire({
              title : 'Printerconnect',
              icon : 'warning', 
              text : 'Não foi possível Editar/Atualizar', 
              showCancelButton : false, 
              showConfirmButton : false, 
              timer : 2000, 
            
              })
      }
      
      }).catch(error =>{
          alert(error)
          Swal.fire({
              title : 'Printerconnect',
              icon : 'error', 
              text : 'Não foi possível Editar/atualizar', 
              showCancelButton : false, 
              showConfirmButton : false, 
              timer : 2000, 
            
              })
      })

      }    
       
    }

  

  return (
    <div>
      <Tooltip title='Alterar Centro de Custo'>
     
     <BorderColorIcon style={{cursor: 'pointer'}} onClick={()=>setOpen(true)}/>
     

      </Tooltip>
      <Dialog
        hideBackdrop
        disableBackdropClick disableEscapeKeyDown
        fullWidth
        maxWidth='md'
        onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          Alterar Centro de Custo
        </DialogTitle>
        <DialogContent dividers >

         <Typography variant='caption' style={{color:'tomato'}}>*Campo Obrigatório *Descrição</Typography><p />
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <TextField
                value={descricao}
                onChange={(e)=>setDescricao(e.target.value)}
            
              
                size={'small'}
                label='Descrição*'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
             
         
            </Grid>
            <Grid item xs={12}>
            <TextField
                value={responsavel}
                onChange={(e)=>setResponsavel(e.target.value)}
            
                
                size={'small'}
                label='Responsável*'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
              </Grid>
              <Grid item xs={12}>
            <TextField
               value={email}
               onChange={(e)=>setEmail(e.target.value)}
              
                size={'small'}
                label='E-mail*'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
              </Grid>
            
     
             

            </Grid>
            
            

        </DialogContent>
        <DialogActions>
     
        <Button variant='contained' style={{cursor:'pointer', backgroundColor:'green', margin: 2, width:'120px', color:'#eee' }} onClick={() => { alterDepartamento() }}  ><SaveIcon/> Alterar</Button>
            <Button  color='secondary' variant='contained' autoFocus onClick={() => setOpen(false)} ><ClearIcon /> Fechar</Button>
          
 

        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalAlterarDepartamento)