import React from 'react';
import PrintIcon from '@material-ui/icons/Print';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {Typography} from '@material-ui/core'
import ImagemInterface from './Imagens/Interface.png'

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import Testimonial from './Testemonial'



export default function(props){
    return <React.Fragment><div id='sobre-container' style ={{paddingTop:"50px"}}>
        <Typography variant = 'h1'>Sobre o Printerconnect</Typography>
        <br/>
        <br/>
        <div id='sobre-container-text'>
        <p>Sua empresa ainda realiza manualmente a coleta dos contadores de impressão? Esta não é a forma mais eficiente! Veja como a realidade de <strong>Provedores de Impressão</strong> podem ser transformadas.<br/>
        Printerconnect é uma <strong>Solução Modular</strong>, focada em empresas de <strong>Outsourcing de Impressão</strong>, entregando agilidade, confiabilidade e segurança para tornar o dia a dia das empresas mais eficientes :</p>
        <br/>
    
    <div >
        <Testimonial/>
    </div>
       
        </div>
        
    </div>
   
   
    </React.Fragment>
}