import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, TextField, Grid, InputAdornment} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Icone from '@material-ui/icons/Category'
import SearchIcon from '@material-ui/icons/Search'
import Fab from '@material-ui/core/Fab';
import FabIcon from '@material-ui/icons/Add'
import { useState } from 'react';
import ModalMarcas from './Marcas/ModalMarcas'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = useState(false)

    const [codigoBarras, setCodigoBarras] = useState('')
    const [codigoFabricante, setCodigoFabricante]= useState('')
    const [marca, setMarca]= useState('')
    const [grupo, setGrupo]= useState('')
    const [subGrupo, setSubGrupo]= useState('')
    const [descricao, setDescricao]= useState('')

    const handleClose = () =>{
        setOpen(false)
    }
       
    const handleClickOpen = () =>{
        setOpen(true)
    }
  return (
    <div>
      <Fab color='primary' onClick={handleClickOpen}>
        <FabIcon  />
      </Fab>
      <Dialog 
        disableBackdropClick
        disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastro de Novo Produto
        </DialogTitle>
        <DialogContent dividers style = {{height:"350px", overflow:"auto"}}>
         <Grid container spacing={1}>
             <Grid item xs = {12}>
                 <TextField
                    label='C. Barras'
                    disabled
                    variant='outlined'
                    fullWidth
                    size='small'
                    value = {codigoBarras}
                    onChange={(e)=>setCodigoBarras(e.target.value)}
                    InputLabelProps={{shrink : true}}
                 />
             </Grid>
        
             
         </Grid>
         
        
         <Grid container spacing={1} style ={{marginTop:'10px'}}>
             <Grid item xs = {12}>
                 <TextField
                    label='C. Barras'
                    variant='outlined'
                    fullWidth
                    size='small'
                    value = {codigoFabricante}
                    onChange={(e)=>setCodigoFabricante(e.target.value)}
                    InputLabelProps={{shrink : true}}
                 />
             </Grid>
        
             
         </Grid>
         
 
         <Grid container spacing={1} style ={{marginTop:'10px'}}>
         <Grid item xs = {12}>
                 <TextField
                    label='Marca'
                    fullWidth
                    size='small'
                    variant='outlined'
                    value={marca.descricao ? marca.descricao : ''}
                    InputLabelProps={{shrink : true}}
                    InputProps ={{
                        endAdornment : <InputAdornment position='end'><ModalMarcas/></InputAdornment>
                    }}
                 />
             </Grid>
        
             
         </Grid>


         <Grid container spacing={1} style ={{marginTop:'10px'}}>
             <Grid item xs = {12}>
                 <TextField
                    label='Grupo'
                    fullWidth
                    size='small'
                    variant='outlined'
                    value={grupo.descricao ? grupo.descricao : ''}
                    InputLabelProps={{shrink : true}}
                    InputProps ={{
                        endAdornment : <InputAdornment position='end'><SearchIcon/></InputAdornment>
                    }}
                 />
             </Grid>

         </Grid>
    
         <Grid container spacing={1} style ={{marginTop:'10px'}}>            
             <Grid item xs = {12}>
                 <TextField
                    label='Subgrupo'
                    fullWidth
                    size='small'
                    variant='outlined'
                    value={subGrupo.descricao ? subGrupo.descricao : ''}
                    InputLabelProps={{shrink : true}}
                    InputProps ={{
                        endAdornment : <InputAdornment position='end'><SearchIcon/></InputAdornment>
                    }}
                 />
             </Grid>
         </Grid>
     
         <Grid container spacing={1} style ={{marginTop:'10px'}}>
             <Grid item xs = {12}>
                 <TextField
                    label='Descrição'
                    fullWidth
                    size='small'
                    variant='outlined'
                    InputLabelProps={{shrink : true}}
                    value = {descricao}
                    onChange={(e)=>setDescricao(e.target.value)}
                 />
             </Grid>

         </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalPadrao)