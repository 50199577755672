import React , {useState, useEffect, useRef} from 'react'
import {withRouter} from 'react-router-dom'




const InterfaceCliente = (props)=>{
    return <div>adsasda</div>
}



export default withRouter(InterfaceCliente)


