import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {Grid, TextField, InputAdornment, Tooltip, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TextFieldMoeda from '../../../../components/TextFieldMoedaDecimal3'
import ModalBuscaCliente from '../../../Clientes/ModalBuscaCliente'
import Conexao from '../../../../providers/Conexao'
import Swal from 'sweetalert2';
import IconeGruposFranquia from '@material-ui/icons/AccountTree'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalCadastro(props) {
  const [open, setOpen] = React.useState(false);

  const [descricao, setDescricao] = React.useState('');
  const [quantidadeFranquia, setQuantidadeFranquia] = React.useState('0');
  const [valorFranquia, setValorFranquia] = React.useState('0');
  const [valorExcedente, setValorExcedente] = React.useState('0');
  const [gerarCredito, setGerarCredito] = React.useState('f');
  const [quantidadeMinima, setQuantidadeMinima] = React.useState('0');
  const [valorMinimo, setValorMinimo] = React.useState('0');
  const [contador, setContador] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.closeModal();
  };

  React.useEffect(()=>{
        if(props.grupoFranquia && props.grupoFranquia.idgrupofranquiacontrato){
            alert('teste')
            setDescricao(props.grupoFranquia.descricao)
            setQuantidadeFranquia(props.grupoFranquia.quantidadefranquia)
            setValorFranquia(props.grupoFranquia.valorfranquia)
            setValorExcedente(props.grupoFranquia.valorexcedente)
            setQuantidadeMinima(props.grupoFranquia.quantidademinima)
            setValorMinimo(props.grupoFranquia.valorminimo)
            setGerarCredito(props.grupoFranquia.gerarcredito)
            setOpen(true)
        }
  }, [props.grupoFranquia])
 
 
  return (
    <div>
         <div className='add-icon-modal' style = {{marginTop:'-100px',marginRight:'30px' }}>
            <Tooltip title='Adicionar um novo grupo de franquia'>
                <Fab color='primary' onClick={handleClickOpen}>
                    <AddIcon/>
                </Fab>
            </Tooltip>
        </div>
        
      <Dialog fullWidth maxWidth='sm' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableBackdropClick disableEscapeKeyDown hideBackdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastrar Novo Grupo
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField
                        label ='Atribuir Contador'
                        valor={contador}
                        onChange={(e)=>setContador(e.target.value)}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        size='small'
                        select 
                        SelectProps={{native: true}}
                        >
                           <option value ='f'>Selecione um contador</option>
                          <optgroup label='Contadores Monocromáticos'/>
                          <option value ='contadorMonoA4'> Contador A4 Mono</option>
                          <option value ='contadorMonoA3'> Contador A3 Mono</option>
                          <option value ='contadorMonoCopia'> Contador Copia Mono</option>
                          <option value ='contadorMonoPrinter'> Contador Impressao mono</option>
                          <option value ='contadorMonoFax'> Contador Fax Mono</option>
                          <option value ='contadorMonoOutros'> Contador Outros Mono</option>
                          <option value ='contadorMonoTotal'>Contador Total Mono</option>
                          <optgroup label='Contadores Coloridos'/>
                          <option value ='contadorColorA4'> Contador A4 Color</option>
                          <option value ='contadorColorA3'> Contador A3 Color</option>
                          <option value ='contadorColorCopia'> Contador Color Copia</option>
                          <option value ='contadorColorPrinter'> Contador Printer Color</option>
                          <option value ='contadorColorFax'> Contador Fax Color</option>
                          <option value ='contadorColorOutros'> Contador Outros Color</option>
                          <option value ='contadorColorTotal'> Contador Total Color</option>
                          <optgroup label='Contadores Gerais'/>
                          <option value ='contadorTotalGeral'> Contador Total Geral</option>
                          <optgroup label='Contadores Scanner'/>
                          <option value ='contadorMonoScanner'> Contador Scanner Mono</option>
                          <option value ='contadorColorScanner'> Contador Scanner Color</option>
                          <option value ='contadorTotalScanner'> Contador Scanner Total</option>
                          <optgroup label='Contadores Lineares'/>
                          <option value ='contadorMetros'> Contador Metros</option>
                          <option value ='contadorCentimetros'> Contador Centimetros</option>
                          <option value ='contadorPolegadas'> Contador Polegadas</option>

                    </TextField>
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField
                        label ='Descrição do grupo'
                        valor={descricao}
                        onChange={(e)=>setDescricao(e.target.value)}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        size='small'
                        />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField
                        label ='Quantidade Contratada'
                        valor={quantidadeFranquia}
                        onChange={(e)=>setQuantidadeFranquia(e.target.value)}
                        inputProps={{ 'inputMode': 'numeric', 'pattern': '[0-9]*' }}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        size='small'
                        />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField
                        label ='Valor Contratado'
                        valor={valorFranquia}
                        onChange={(e)=>setValorFranquia(e.target.value)}
                        decimal = {3}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        size='small'
                        />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField
                        label ='Quantidade Mínima'
                        valor={quantidadeMinima}
                        onChange={(e)=>setQuantidadeMinima(e.target.value)}
                        inputProps={{ 'inputMode': 'numeric', 'pattern': '[0-9]*' }}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        size='small'
                        />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField
                        label ='Valor Mínimo'
                        valor={valorMinimo}
                        onChange={(e)=>setValorMinimo(e.target.value)}
                        decimal = {3}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        size='small'
                        />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField
                        label ='Valor Excedente'
                        valor={valorExcedente}
                        onChange={(e)=>setValorExcedente(e.target.value)}
                        decimal = {3}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        size='small'
                        />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField
                        label ='Gerar Crédito'
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        size='small'
                        select
                        value={gerarCredito}
                        onChange={(e)=>setGerarCredito(e.target.value)}
                        SelectProps={{native : true}}
                        >
                            <option value = 't'>Gerar Crédito</option>
                            <option value = 'f'>Não Gerar Crédito</option>
                    </TextField>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' color='secondary' onClick={()=>setOpen(false)}>Cancelar</Button>
            <Button variant='contained' color='primary' onClick={()=>{
              if(contador =='f' || contador ==''){
                Swal.fire({
                  title : 'Printerconnect', 
                  text : 'Vincule um tipo de contador antes de continuar!', 
                  icon :'warning',
                  showConfirmButton : false, 
                  confirmButtonText : 'Sim', 
                  showCancelButton : false, 
                  cancelButtonText : 'Não', 
                  timer : 1500
                }) 

                return
              }
                Swal.fire({
                    title : 'Printerconnect', 
                    text : 'Deseja cadastrar este novo Grupo de Franquia agora?', 
                    icon :'question',
                    showConfirmButton : true, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : true, 
                    cancelButtonText : 'Não'
                  }).then(resposta=>{
                      if(resposta.isConfirmed){
                        Conexao.post('contratosController/insertGrupoFranquiaContrato', {
                            idUsuario : props.contrato.idusuario, 
                            idEmpresa : props.contrato.idempresa, 
                            idContrato : props.contrato.idcontrato, 
                            descricao : descricao, 
                            quantidadeFranquia : quantidadeFranquia, 
                            valorFranquia : valorFranquia, 
                            valorExcedente : valorExcedente, 
                            quantidadeMinima : quantidadeMinima, 
                            valorMinimo : valorMinimo, 
                            gerarCredito : gerarCredito, 
                            contador : contador
                        }).then(response=>{
                            if(response.data.status == 'OK'){
                                Swal.fire({
                                    title : 'Printerconnect', 
                                    text : 'Grupo de franquia incluído com sucesso!', 
                                    icon :'success',
                                    showConfirmButton : false, 
                                    confirmButtonText : 'Sim', 
                                    showCancelButton : false, 
                                    cancelButtonText : 'Não', 
                                    timer : 1500
                                  })  
                                  handleClose();
                                  props.updateData();
                            }else{
                                Swal.fire({
                                    title : 'Printerconnect', 
                                    text : 'Não foi possível incluir!', 
                                    icon :'warning',
                                    showConfirmButton : false, 
                                    confirmButtonText : 'Sim', 
                                    showCancelButton : false, 
                                    cancelButtonText : 'Não', 
                                    timer : 1500
                                  })
                            }
                        }).catch(error=>{
                            Swal.fire({
                                title : 'Printerconnect', 
                                text : 'Não foi possível incluir!', 
                                icon :'error',
                                showConfirmButton : false, 
                                confirmButtonText : 'Sim', 
                                showCancelButton : false, 
                                cancelButtonText : 'Não', 
                                timer : 1500
                              })
                        })
                      }
                  })
            }}>Gravar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
