import React from 'react'
import BlockIcon from '@material-ui/icons/Block';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {Typography, CircularProgress, Button, Checkbox} from '@material-ui/core'

export default function(props){

    const renderOpcao = () =>{
        if(props.loading){
            return <CircularProgress style ={{width :'20px', height:'20px'}} color='secondary'/>
        }else{
                return <Checkbox  checked ={props.ativo =='t'} onClick={()=>{
                    props.onclick()
                }} />
       
        }
    }
    return renderOpcao();
}