import React, { useEffect } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import {TextField,Divider,Button, Container, Grid, Typography} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';

import WrapTextIcon from '@material-ui/icons/WrapText';
import OpacityIcon from '@material-ui/icons/Opacity';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Equipamentos from '../Equipamentos/Equipamentos';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import DisplayCompletoToner from '../Suprimentos/DisplayCompletoToner';
import DisplayToner from '../../../components/DisplayToner';



const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalDetalhes = (props) => {
  
    const [open, setOpen] = React.useState(false)
   

  

  return (
    <div>
      <Tooltip title='Ver detalhes do Equipamento'>
        <LocalPrintshopIcon style={{cursor:'pointer', fontSize: 30, color:'#444' }} onClick={() => { setOpen(true) }} />
      </Tooltip>
      <Dialog
        hideBackdrop
        disableBackdropClick disableEscapeKeyDown
        fullWidth
        maxWidth='md'
        onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          {props.titulo}
        </DialogTitle>
        <DialogContent dividers >

          <Grid container spacing={1}>

            <Grid item xs={2}>
              <TextField
                value={props.equipamento.codigoadicional ? props.equipamento.codigoadicional : ''}
                size={'small'}
                label='Placa'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}


              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                size={'small'}
                value={props.equipamento.numeroserie ? props.equipamento.numeroserie : ''}
                label='Número de série'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
            </Grid>
            
            <Grid item xs={6}>
              <TextField
                value={props.equipamento.setor ? props.equipamento.setor : ''}
                size={'small'}
                label='Setor'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}


              />
            </Grid>
          </Grid>
          <br />
          

          <Grid container spacing={1}>

            <Grid item xs={6}>
              <TextField
               
                size={'small'}
                label='Modelo'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={props.equipamento.modelo ? props.equipamento.modelo : ''}


              />
            </Grid>
            <Grid item xs={6}>
              <TextField
               value={props.equipamento.marca ? props.equipamento.marca : ''}
                size={'small'}
                label='Marca'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
            </Grid>
          </Grid>
          
          <br />
       
      
          <Grid container spacing={1}>
            <Grid item xs={2}>
            <TextField
               value={props.equipamento.detalhesColeta.datacoleta  ? props.equipamento.detalhesColeta.datacoleta : ''}
                size={'small'}
                label='Data última coleta'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
             

            </Grid>
            <Grid item xs={3}>
            <TextField
               value={props.equipamento.detalhesColeta.horacoleta ? props.equipamento.detalhesColeta.horacoleta.substr(0,8) : ''}
                size={'small'}
                label='Hora última coleta'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
              </Grid>
              <Grid item xs={3}>
            <TextField
               value={props.equipamento.detalhesColeta.enderecoip ? props.equipamento.detalhesColeta.enderecoip : ''}
                size={'small'}
                label='Endereço IP'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
              </Grid>
              <Grid item xs={4}>
            <TextField
               value={props.equipamento.detalhesColeta.macaddress ? props.equipamento.detalhesColeta.macaddress : ''}
                size={'small'}
                label='Endereço MAC'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
              </Grid>
             
              <Grid item xs={12}>
              <br />
            <TextField
               value={props.equipamento.detalhesColeta.mensagempainel ? props.equipamento.detalhesColeta.mensagempainel : ''}
                size={'small'}
                label='Mensagem do painel'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
              </Grid>

     
             

            </Grid>
            
          <br />
          <br />



          <Grid container spacing={1}>

            <Grid item xs={2}>             
            <DisplayToner color="#00f"  percentual={props.equipamento.detalhesColeta.ciano} width={'65px'} height={'65px'} marginTop={'-22px'} contador={props.equipamento.detalhesColeta.contadorciano} descricao={props.equipamento.detalhesColeta.numeroserieciano} />
           

            </Grid>
            <Grid item xs={2}>
            <DisplayToner color='#cf3476'  percentual={props.equipamento.detalhesColeta.magenta} width={'65px'} height={'65px'} marginTop={'-22px'} contador={props.equipamento.detalhesColeta.contadormagenta} descricao={props.equipamento.detalhesColeta.numeroseriemagenta}/>

            </Grid>
            <Grid item xs={2}>
            <DisplayToner color="#ffd600" percentual={props.equipamento.detalhesColeta.amarelo} width={'65px'} height={'65px'} marginTop={'-22px'} contador={props.equipamento.detalhesColeta.contadoramarelo} descricao={props.equipamento.detalhesColeta.numeroserieamarelo} />

            </Grid>
            <Grid item xs={2}>
            <DisplayToner color="#000"  percentual={props.equipamento.detalhesColeta.preto} width={'65px'} height={'65px'} marginTop={'-22px'} contador={props.equipamento.detalhesColeta.contadorpreto} descricao={props.equipamento.detalhesColeta.numeroseriepreto} />

            </Grid>

 
            <Grid item xs={2}>
              <TextField
                label='Monocromático'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                className='textNumberLarge1'
                defaultValue={props.equipamento.detalhesColeta.contadormono}
                inputProps={{
                  "min": 0,
                  "max": 99999999
                }}
                pattern="^-?[0-9]\d*\.?\d*$"
                InputProps={{ min: 0 }}
                type='number'

              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label='Colorido'
                pattern="^-?[0-9]\d*\.?\d*$"
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                className='textNumberLarge1'
                defaultValue={props.equipamento.detalhesColeta.contadorcolor}
                InputProps={{ min: 0 }}
                type='number'

              />
            </Grid>

          </Grid>


        </DialogContent>
        <DialogActions>
       
           
            <Button  color='secondary' variant='contained' autoFocus onClick={() => setOpen(false)} ><ClearIcon /> Fechar</Button>
 

        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalDetalhes)