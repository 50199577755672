import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {Grid, TextField, InputAdornment, Tooltip, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TextFieldMoeda from '../../../../components/TextFieldMoedaDecimal3'
import ModalBuscaCliente from '../../../Clientes/ModalBuscaCliente'
import Conexao from '../../../../providers/Conexao'
import Swal from 'sweetalert2';
import IconeGruposFranquia from '@material-ui/icons/AccountTree'
import EditaIcon from '@material-ui/icons/Brush';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalCadastro(props) {
  const [open, setOpen] = React.useState(false);

  const [descricao, setDescricao] = React.useState('asdasdasdasdasd');
  const [quantidadeFranquia, setQuantidadeFranquia] = React.useState('0');
  const [valorFranquia, setValorFranquia] = React.useState('0');
  const [valorExcedente, setValorExcedente] = React.useState('0');
  const [gerarCredito, setGerarCredito] = React.useState('0');
  const [quantidadeMinima, setQuantidadeMinima] = React.useState('0');
  const [valorMinimo, setValorMinimo] = React.useState('0');

  const handleClickOpen = () => {
    setOpen(true);
    
   
   
  };
  const handleClose = () => {
    setOpen(false);
    
  };




  React.useEffect(()=>{
    if(open){
      setDescricao(props.grupoFranquia.descricao)
      setQuantidadeFranquia(props.grupoFranquia.quantidadefranquia)
      setValorFranquia(props.grupoFranquia.valorfranquia)
      setValorExcedente(props.grupoFranquia.valorexcedente)
      setQuantidadeMinima(props.grupoFranquia.quantidademinima)
      setValorMinimo(props.grupoFranquia.valorminimo)
      setGerarCredito(props.grupoFranquia.gerarcredito)
    }
  },[open])


 
  return (
    <div>
     
        
        <Button variant='contained' color='primary' onClick={handleClickOpen}>
            <EditaIcon />
        </Button>


      <Dialog fullWidth maxWidth='sm' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableBackdropClick disableEscapeKeyDown hideBackdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Editar Grupo de Franquia {descricao}
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField 
                      value={descricao}
                      onChange={(e)=>setDescricao(e.target.value)}
                      label='Descricao do grupo'
                      fullWidth
                      variant='outlined'
                      size='small'
                      InputLabelProps={{shrink:true}}
                      />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                  <TextField 
                        value={quantidadeFranquia}
                        onChange={(e)=>setQuantidadeFranquia(e.target.value)}
                        label='Quantidade Franquia'
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}
                        />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                  <TextField 
                        value={valorFranquia}
                        onChange={(e)=>setValorFranquia(e.target.value)}
                        label='Valor Franquia'
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}
                        />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                  <TextField 
                        value={quantidadeMinima}
                        onChange={(e)=>setQuantidadeMinima(e.target.value)}
                        label='Quantidade Mínima'
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}
                        />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                  <TextField 
                        value={valorMinimo}
                        onChange={(e)=>setValorMinimo(e.target.value)}
                        label='Valor Mínimo'
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}
                        />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                <TextField 
                        value={valorExcedente}
                        onChange={(e)=>setValorExcedente(e.target.value)}
                        label='Valor Excedente'
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}
                        />
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField
                        label ='Gerar Crédito'
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        size='small'
                        select
                        value={gerarCredito}
                        onChange={(e)=>setGerarCredito(e.target.value)}
                        SelectProps={{native : true}}
                        >
                            <option value = 't'>Gerar Crédito</option>
                            <option value = 'f'>Não Gerar Crédito</option>
                    </TextField>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' color='secondary' onClick={()=>setOpen(false)}>Cancelar</Button>
            <Button variant='contained' color='primary' onClick={()=>{
                Swal.fire({
                    title : 'Printerconnect', 
                    text : 'Deseja alterar este novo Grupo de Franquia agora?', 
                    icon :'question',
                    showConfirmButton : true, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : true, 
                    cancelButtonText : 'Não'
                  }).then(resposta=>{
                      if(resposta.isConfirmed){
                        Conexao.post('contratosController/updateGrupoFranquiaContrato', {
                            idusuario : props.grupoFranquia.idusuario, 
                            idempresa : props.grupoFranquia.idempresa, 
                            idcontrato : props.grupoFranquia.idcontrato, 
                            idgrupofranquiacontrato : props.grupoFranquia.idgrupofranquiacontrato, 
                            descricao : descricao, 
                            quantidadeFranquia : quantidadeFranquia, 
                            valorFranquia : valorFranquia, 
                            valorExcedente : valorExcedente, 
                            quantidadeMinima : quantidadeMinima, 
                            valorMinimo : valorMinimo, 
                            gerarCredito : gerarCredito
                        }).then(response=>{
                            if(response.data.status == 'OK'){
                                Swal.fire({
                                    title : 'Printerconnect', 
                                    text : 'Grupo de franquia alterado com sucesso!', 
                                    icon :'success',
                                    showConfirmButton : false, 
                                    confirmButtonText : 'Sim', 
                                    showCancelButton : false, 
                                    cancelButtonText : 'Não', 
                                    timer : 1500
                                  })  
                                  handleClose();
                                  props.updateData();
                            }else{
                                Swal.fire({
                                    title : 'Printerconnect', 
                                    text : 'Não foi possível alterar!', 
                                    icon :'warning',
                                    showConfirmButton : false, 
                                    confirmButtonText : 'Sim', 
                                    showCancelButton : false, 
                                    cancelButtonText : 'Não', 
                                    timer : 1500
                                  })
                            }
                        }).catch(error=>{
                          console.log(error)
                            Swal.fire({
                                title : 'Printerconnect', 
                                text : 'Não foi possível alterar!', 
                                icon :'error',
                                showConfirmButton : false, 
                                confirmButtonText : 'Sim', 
                                showCancelButton : false, 
                                cancelButtonText : 'Não', 
                                timer : 1500
                              })
                        })
                      }
                  })
            }}>Gravar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
