import React, {useState, useEffect, useRef} from 'react'
import {TextField, InputAdornment, Button} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
var md5 = require('md5');


const Login = function(props){
    const [login, setLogin] = useState('')
    const [senha, setSenha] = useState('')

    const autenticar = () =>{
        console.log(md5(senha))
        Conexao.post('usuariosController/auth', {
            login : login, 
            senha : md5(senha)
        }).then(response =>{
            
            if(response.status == 200){
                if(response.data){
                    sessionStorage.setItem('token@printerconnect', response.data.idusuario);
                    if(response.data.empresaPadrao && response.data.empresaPadrao != null){
                        sessionStorage.setItem('empresa@printerConnect', JSON.stringify(response.data));
                        props.history.push({pathname : '/home/revenda/'+response.data.idusuario, state : {
                            idempresa : response.data.idusuario, 
                            empresa : response.data
                          }})
                    }else{
                        sessionStorage.setItem('empresa@printerConnect', []);
                        props.history.push({pathname : '/home/revenda/'+response.data.idusuario, state : {
                            idempresa : response.data.idusuario, 
                            empresa : []
                          }})
                    }
                    
                    
                }else{
                    Swal.fire({
                        title: 'Falha de Autenticação',
                        text: 'Não foi possível autenticar ! Usuário o Senha inválidos !',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                      })
                }
            }else{
                Swal.fire({
                    title: 'Falha de Conexão',
                    text: 'Ocorreu um erro ao processar requisição !',
                    icon: 'error',
                    confirmButtonText: 'OK'
                  })
            }
            
        }).catch( error =>{
            Swal.fire({
                title: 'Falha de Conexão',
                text: 'Não foi possível acessar recurso remoto',
                icon: 'error',
                confirmButtonText: 'OK'
              })
        })
    }
    return <div id='login-container-main'>
            <div id='login-container-form'>
                <div>
                <div>
                    <TextField
                        variant='outlined'
                        label='Login'
                        placeholder='Informe o seu email de acesso'
                        InputLabelProps = {{shrink : true}}
                        fullWidth = {true}
                        onChange = {(e)=>setLogin(e.target.value)}
                        value ={login}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon style = {{color : "#ccc"}} />
                                </InputAdornment>
                            ),
                            }}
                     />
                </div>

                <div style ={{marginTop:"20px"}}>
                    <TextField
                        variant='outlined'
                        label='Password'
                        placeholder='Informe a sua palavra passe'
                        InputLabelProps = {{shrink : true}}
                        fullWidth = {true}
                        type = 'password'
                        onChange = {(e)=>setSenha(e.target.value)}
                        value ={senha}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon style = {{color : "#ccc"}} />
                                </InputAdornment>
                            ),
                            }}
                     />
                </div>
               
                </div>

                <div >
                    <Button variant='contained' color ='primary' onClick={autenticar}>ENTRAR</Button>
                </div>
            </div>
    </div>
}

export default withRouter(Login)