import React, {Fragment, useEffect} from 'react'
import {Typography} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 

import PrintIcon from '@material-ui/icons/Print';
import DisplayToner from '../../../components/DisplayToner'

export default function(props){
    const [classe, setClasse] = React.useState('card-dash-equipamento-container-main')


    const renderStatus = (status) =>{

        switch(status){
            case 1:return <HelpIcon className={'icon-status-coleta'+status}/>
                
            case 2:return <PowerSettingsNewIcon className={'icon-status-coleta'+status}/>
                
            case 3:return <SettingsPowerIcon className={'icon-status-coleta'+status}/>
                
            case 4:return <PrintIcon className={'icon-status-coleta'+status}/>
    
            case 5:return <ReportProblemIcon className={'icon-status-coleta'+status}/>
    
            default:return <HelpIcon className={'icon-status-coleta'}/>
        }
            
       }

      

       const renderClasseAlert = (coleta) =>{
           if(coleta.nivelpreto >= 0 && coleta.nivelpreto <= 25){
               setClasse('card-dash-equipamento-container-main card-error')
           }else if(coleta.nivelpreto >= 26 && coleta.nivelpreto <= 50){
               setClasse('card-dash-equipamento-container-main card-warning')
           }
       }

    return <div className= {classe} style={{textAlign:"center", border:"solid 1px #ccc", backgroundColor:"#fff!important"}}>
      
         
            <div className='texto-limitado' style = {{fontSize:"9px!important"}}>{props.coleta && props.coleta.modelo.substr(0, 25)}</div>
            <div  style ={{ alignItems:"center"}}><DisplayToner width={'42px'} height={'42px'}   percentual = {props.coleta ? props.coleta.nivel : 0} noTop color = {props.coleta && props.coleta.cor}  /></div>
            <div style = {{fontSize:"9px!important", textAlign:"center!important"}}>{props.coleta && props.coleta.numeroserie}</div>
            
      
        
    </div>
}