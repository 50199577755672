import React, {useState, useRef, Fragment, useEffect} from 'react'
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
  import "react-circular-progressbar/dist/styles.css";
  import Typography from '@material-ui/core/Typography';




  export default function(props){
    
    return <div className={'nivel-toner-container'} style = {{marginTop:props.marginTop ? props.marginTop : 0}} >
            <div>
            {!props.noTop &&<div style ={{ color:props.color,}} className="nivel-toner-container-rotulo-top">{props.contador}</div>}
                <div style = {{width : props.width, height : props.height, textAlign:"center!important"}} className="nivel-toner-container-nivel">
                    <CircularProgressbar  value={props.percentual ? props.percentual : 0} text={props.percentual ? props.percentual+'%' : 0+'%'} strokeWidth ={props.lineSize} styles={buildStyles({
                        textColor: props.color,
                        pathColor: props.color,                        
                    })}/>
                </div>
                {props.descricao && <div style ={{ color:props.color,}} className="nivel-toner-container-rotulo">{props.descricao}</div>}
            </div>    
            </div> 

  }

