import React from 'react'
import { Circle, TileLayer,withLeaflet,  Popup, Marker,  Tooltip, ZoomControl, Polygon, Polyline, Map  } from "react-leaflet";
import MeasureControlDefault from 'react-leaflet-measure'; //https://www.npmjs.com/package/react-leaflet-measure
import L from 'leaflet';
import "leaflet/dist/leaflet.css";//https://towardsdatascience.com/creating-a-bubbles-map-using-react-leaflet-e75124ca1cd2
import { point } from 'leaflet';
import Principal from '../../pages/Principal/Principal'
import Conexao from '../../providers/Conexao'
import { Icon, DivIcon } from "leaflet";
//import {Marker,} from 'react-leaflet-enhanced-marker' //https://www.npmjs.com/package/react-leaflet-enhanced-marker
import MarkerClusterGroup from "react-leaflet-markercluster";
import IconeTecnico from './icones/iconeAtendenteTecnico.png'
import IconeRevenda from './icones/iconeRevenda.png'
import IconeCliente from './icones/iconeCliente.png'
import Swal from 'sweetalert2';
/*


                                        */


    const iconTecnico = new Icon({
      iconUrl: IconeTecnico,
      iconSize: [32, 54], 
      iconAnchor :[
          20, 30
      ], 
      popupAnchor: [0, 0]
    });

    const iconeRevenda = new Icon({
      iconUrl: IconeRevenda,
      iconSize: [54, 54], 
      iconAnchor :[
          0, 0
      ], 
      popupAnchor: [0, 0]
    });

    const iconeCliente = new Icon({
      iconUrl: IconeCliente,
      iconSize: [54, 54], 
      iconAnchor :[
          25, 0
      ], 
      popupAnchor: [0, 0]
    });


export default function (props){

    const position = [51.505, -0.09]
    const [posicaoInicio, setPosicaoInicio] = React.useState(null);
    const [dataEmpresa, setDataEmpresa]= React.useState([]);
    const [tecnicos, setTecnicos] = React.useState([]);
    const [regioes, setRegioes] = React.useState([]);
    const [chamadosAbertos, setChamadosAbertos] = React.useState([]);



    React.useEffect(()=>{
        getDataEmpresa();
        getRegioes()
        getPosicoes();

        const timer = setInterval(()=>{
            getPosicoes()
            
        }, 120000)
        
        return ()=>{
            clearInterval(timer);
        }
    },[]);

    const handlePositionLine = (posicao)=>{
      console.log(posicao.lat)
    // setPosicaoInicio([posicao.lat, posicao.lng])
    
    }


    const getPosicoes = ()=>{
        if(props.mostrarTecnicos){
            getPosicaoTecnicos();
        }
        if(props.mostrarChamados){
          getChamadosAbertos();
        }
    }

    const getDataEmpresa = async () =>{
      Conexao.post('empresasController/getPosicaoRevenda?idusuario='+props.idusuario+'&idempresa='+props.idempresa).then(response=>{
          if(response.data.status =='OK'){
            setDataEmpresa(response.data.payload)
          }else{
            setDataEmpresa([])
          }
        }).catch(error=>{
          setDataEmpresa([])
        })
  }

  

    const getPosicaoTecnicos = async () =>{
        Conexao.post('funcionariosController/getPosicaoTecnicosMapa', {
            idusuario : props.idusuario, 
            idempresa : props.idempresa 
          }).then(response=>{
            if(response.data.status =='OK'){
              setTecnicos(response.data.payload)
            }else{
              setTecnicos([])
            }
          }).catch(error=>{
            setTecnicos([])
          })
    }

   

    const getRegioes = () =>{
      Conexao.get('empresasController/getRegioes?idUsuario='+props.idusuario+'&idEmpresa='+props.idempresa).then(response=>{
        if(response.data.status =='OK'){
          setRegioes(response.data.payload)
        }else{
          setRegioes([])
        }
      }).catch(error=>{
        setRegioes([])
      })
    }

    const getChamadosAbertos = async () =>{
      Conexao.post('chamadosTecnicosController/getChamadosAbertosMapa?idusuario='+props.idusuario+'&idempresa='+props.idempresa).then(response=>{
          if(response.data.status =='OK'){
            setChamadosAbertos(response.data.payload)
          }else{
            setChamadosAbertos([])
          }
        }).catch(error=>{
          setChamadosAbertos([])
        })
  }

    return <div style ={{height: "100%", marginTop:"0px", width: "100%", }}>
        <Map
          id="modalMap"                        
          animate={true}
          style={{ height: "100%", width: "100%", }}
          minZoom={5}
          zoom={props.defaultZoon ? props.defaultZoon : 9 }                        
          zoomControl={true}                                                        
          center={[props.latitude ? props.latitude : -22.2244322, props.longitude ? props.longitude : -49.8917842]}    
          onclick={(e)=>{
           handlePositionLine(e.latlng)
           //console.log(e)
          }}    
          >
            <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />


            {
              regioes && regioes.map(function(regiao, i){
                return <Circle
                fillColor={regiao.cor}
                stroke ={false}                
                center = {[regiao.latitude, regiao.longitude]}
                radius = {regiao.raio}
              />
              })
            }



            <MarkerClusterGroup>
            {
              dataEmpresa && dataEmpresa.map(function(empresa, i){
              return <Marker 
                        key={'revenda'+i}                   
                        position={[empresa.latitude, empresa.longitude]}                                        
                        icon={iconeRevenda}>
                            <Popup>
                                <div style ={{display:"flex", width:"300px"}}>
                                    <div style ={{width:"20%"}}>
                                    <img src={IconeRevenda} height={48}/>
                                    </div>
                                    
                                    <div style ={{width:"80%", paddingLeft:"10px"}}>
                                        <span style={{fontSize:"12px", fontWeight:"bold"}}>{empresa.razaosocial}</span><br/>
                                    
                                    </div> 
                                </div>                    
                            </Popup> 
                          
                        </Marker>
              })
            }
            </MarkerClusterGroup>


            <MarkerClusterGroup>
             {
                 tecnicos && tecnicos.map(function(tecnico, i){
                     return <Marker 
                     key={'TEC'+i}                   
                     position={[tecnico.latitude, tecnico.longitude]}  
                     onclick={()=>{
                       if(props.movendoChamado != null){
                          
                        Swal.fire({
                          title : 'Printerconnect', 
                          text : `Deseja transferir o chamado ${props.movendoChamado.idchamadotecnico+'/'+props.movendoChamado.sequenciaatendimento} para o técnico ${tecnico.nomecompleto} `,
                          icon : 'question', 
                          showConfirmButton : true, 
                          confirmButtonText : 'Sim', 
                          showCancelButton : true, 
                          cancelButtonText : 'Não'
                        });
                       }
                      
                     }}                                      
                     icon={iconTecnico}>
                       {props.movendoChamado == null && <Popup>
                       <div style ={{display:"flex", width:"300px"}}>
                                    <div style ={{width:"8%"}}>
                                    <img src={IconeTecnico} height={54}/>
                                    </div>
                                    
                                    <div style ={{width:"92%", paddingLeft:"10px"}}>
                                        <span style={{fontSize:"14px", fontWeight:"bold"}}>{tecnico.nomecompleto}</span><br/>
                                        <span style={{fontSize:"10px", fontWeight:"bold"}}>Data: {tecnico.dataposicao}</span><br/>
                                        <span style={{fontSize:"10px", fontWeight:"bold"}}>Hora: {tecnico.horaposicao ? tecnico.horaposicao.substr(0,8):''}</span><br/>
                                    
                                    </div> 
                                </div>
                      </Popup>}
                       
                     </Marker>
                 })
             }

            </MarkerClusterGroup>




            <MarkerClusterGroup>
             {
                 chamadosAbertos && chamadosAbertos.map(function(chamado, i){
                     return<Marker 
                     key={'TEC'+i}                   
                     position={[chamado.latitude ? chamado.latitude : 0, chamado.longitude ? chamado.longitude : 0]}                                        
                     icon={IconeCliente}>
                       <Popup>
                       <div style ={{display:"flex", width:"300px"}}>
                                    <div style ={{width:"8%"}}>
                                    <img src={IconeCliente} height={54}/>
                                    </div>
                                    
                                    <div style ={{width:"92%", paddingLeft:"10px"}}>
                                        <span style={{fontSize:"14px", fontWeight:"bold"}}>{chamado.razaosSocial}</span><br/>
                                       
                                    </div> 
                                </div>
                      </Popup>
                       
                     </Marker>
                 })
             }

            </MarkerClusterGroup>            
          </Map>
        </div>
  
}