import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import DisplayToner from '../../components/DisplayToner'
import DisplayLinearToner from '../../components/DisplayLinearToner'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CheckBoxNaoLida from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxLida from '@material-ui/icons/CheckBox'
import TemplateRevenda from '../Template/TemplateRevenda';


let temporizador = null;
const AlertasSuprimentos = (props)=>{
    const classes = useStyles();
    const [idLeitura, setIdLeitura]= React.useState(-1);
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [ registros, setRegistros] = useState(0)
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [ alertas, setAlertas] = useState([])
   


    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const refTipoDataPesquisa = React.createRef();
    const refDataInicial = React.createRef();
    const refDataFinal = React.createRef();
    const refPlaca = React.createRef();
    const refNumeroSerie = React.createRef();
    const refModelo = React.createRef();
    const refMarca = React.createRef();
    
    const refRazaoSocial = React.createRef();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(()=>{
        setPage(0)
    }, [])

    useEffect(()=>{
        getData();
    }, [page, rowsPerPage])


    

    const clearForm = () =>{
        refTipoDataPesquisa.current.value  ='COLETA';
        refDataInicial.current.value  ='';
        refDataFinal.current.value  ='';
        refPlaca.current.value  ='';
        refNumeroSerie.current.value  ='';
        refModelo.current.value  ='';
        refMarca.current.value  ='';
        refRazaoSocial.current.value  ='';
        setPage(0)
    }
   

    const getData = () =>{
        setLoading(true)
        Conexao.post('coletasController/getAlertasAtolamentos', {
            idempresa : props.location.state.empresa.idempresa, 
            idusuario : props.location.state.empresa.idusuario, 
            tipodata : refTipoDataPesquisa.current ?  refTipoDataPesquisa.current.value : '', 
            datainicial : refDataInicial.current ?  refDataInicial.current.value : '', 
            datafinal : refDataFinal.current ?  refDataFinal.current.value : '', 
            placa : refPlaca.current ?  refPlaca.current.value : '', 
            numeroserie :refNumeroSerie.current ?  refNumeroSerie.current.value : '' , 
            modelo :refModelo.current ?  refModelo.current.value : '', 
            marca : refMarca.current ?  refMarca.current.value : '', 
            razaosocial  : refRazaoSocial.current ?  refRazaoSocial.current.value : '', 
            limit: rowsPerPage,
            offSet :  page 
        }).then(response=>{            
            if(response.data.status == 'OK' ){
                
                setAlertas(response.data.payload.dados)
                setRegistros(response.data.payload.registros)
            }else{
                setAlertas([])
                setRegistros(0)
            }
            setLoading(false)
        }).catch(error =>{
           
            setAlertas([])
           
            Swal.fire({
                title : 'Printerconnect', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
            setLoading(false)
        });
    }

   
   

   

  

    return <TemplateRevenda atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Atolamentos de Papel
            </Link>                
        </Breadcrumbs>
    }>
       <Grid container spacing = {2}>
            <Grid item xs = {7}></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage='Linhas por página'
                />
            </Grid>
        </Grid>
        
        <Grid container spacing = {2}>
            
            <Grid item xs = {2}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    inputRef={refDataInicial}             
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    inputRef={refDataFinal}             
                   />
            </Grid>

            <Grid item xs = {2}>
                <TextField
                    label ='Número Série'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    inputRef={refNumeroSerie}                   
                   />
            </Grid>
            <Grid item xs = {4}>
                <TextField
                    label ='Marca'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    inputRef={refMarca}
                    size ='small'
                    />                   
            </Grid>
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getData()}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>clearForm()}><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
            
            <Grid item xs = {3}>
                <TextField
                    label ='Modelo'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    inputRef={refModelo}                   
                   />
            </Grid>
            <Grid item xs = {4}>
                <TextField
                    label ='Razão Social'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    inputRef={refRazaoSocial}
                    size ='small'
                    />                   
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Setor'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                   
                    size ='small'
                    />                   
            </Grid>


        </Grid>
        <br/>
        
        <div className='div-form-container'>
        <Grid spacing={1}>
            <Table  className='table table-bordered table-striped table-hover table-sm' style ={{fontSize:"10px"}}>
                <TableHead>
                    <TableCell align='center' valign='top' style = {{width:"10%", verticalAlign:"top"}}>Data</TableCell>
                              
                    <TableCell align='left' valign='top' style = {{width:"30%", verticalAlign:"top",  fontSize:"10px"}}>Equipamento</TableCell>
                    <TableCell align='left' valign='top' style = {{width:"40%", verticalAlign:"top",  fontSize:"10px"}}>Descrição</TableCell>
                    
                    <TableCell align='center' valign='top' style = {{width:"10%", verticalAlign:"top",  fontSize:"10px"}}>Contadores</TableCell>
                    <TableCell align='center' valign='top' style = {{width:"5%", verticalAlign:"top",  fontSize:"10px"}}>Lida</TableCell>
                </TableHead>
                <TableBody>
                {
                    alertas && alertas.map(function(alerta, i){
                        return <TableRow>
                       <TableCell align='center' valign='top' style = {{verticalAlign:"top",  fontSize:"9px"}}><Typography variant='caption' color='primary'>{alerta.dataalerta+' '+(alerta.horaalerta && alerta.horaalerta.substr(0,8))}</Typography></TableCell>
            
            
                      
                       <TableCell align='left' valign='top' style = {{verticalAlign:"top",  fontSize:"9px"}}>
                           <Typography variant='caption' color='primary' ><strong>{alerta.numeroserie}</strong><br/>{' '+alerta.marca+' - '+alerta.modelo}</Typography><br/>
                           <Typography variant='caption' valign='top' color='primary' >{alerta.razaosocial}</Typography><br/>
                       </TableCell>
                       <TableCell align='left' valign='top' style = {{verticalAlign:"top",  fontSize:"9px"}} ><Typography variant='caption' color='primary'>{alerta.prtalertdescription}</Typography></TableCell>
                       <TableCell align='center' valign='top' style = {{ verticalAlign:"top",  fontSize:"9px"}}>
                            <Typography variant='caption' color='primary'>{alerta.contadortotal}</Typography><br/>
                            <Typography variant='caption' color='primary'>{'PB:'+ alerta.contadormono+' CL:'+(alerta.contadorcolor ? alerta.contadorcolor : 0)}</Typography>
                        </TableCell>
                        <TableCell align='center' valign='top' style = {{verticalAlign:"top",  fontSize:"9px"}}>
                            <Button><CheckBoxNaoLida color='primary'/></Button>
                        </TableCell>
                    </TableRow>
                    })

                }
                </TableBody>
                
                
                <TableBody>
                   
                
                </TableBody>
            </Table>
        </Grid>                     
        </div>
        <div className='fabIncluir'>
           
        </div>
     
    </TemplateRevenda>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(AlertasSuprimentos)