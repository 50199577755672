import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, TextField, Grid, Divider} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {Link} from 'react-router-dom'
import IconUser from '@material-ui/icons/VpnKeyOutlined'
import md5 from 'md5';
import Conexao from '../../../providers/Conexao'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalTrocaSenha = (props) => {
    const [open, setOpen] = React.useState(false)
    const [senha, setSenha] = React.useState('')
    const [novaSenha, setNovaSenha] = React.useState('')
    const [confirmaSenha, setConfirmaSenha] = React.useState('')

    const handleClickOpen = () =>{
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }
  return (
    <div>
      <Tooltip title='Cadastrar um novo veículo'>
      <a onClick={handleClickOpen} style={{cursor:'pointer'}}>
          <div style={{display:'flex'}}>
              <div style ={{width : '25%', textAlign:'center'}}><IconUser style={{color:"#444"}}/></div>
              <div style ={{width : '75%'}}><Typography variant='caption' className='itemMenuItem' style={{color:"#444"}}>Mudar Senha</Typography></div>
          </div> 
      </a>
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Alterar Senha
        </DialogTitle>
        <DialogContent dividers >
          <Grid container spacing={1}>
            <Grid item xs = {4}>
              <Typography variant='subtitle1'>Senha Atual</Typography>
            </Grid>
            <Grid item xs = {8}>
              <TextField
                variant='outlined'
                fullWidth
                type='password'
                value={senha}
                onChange={(e)=>{
                  setSenha(e.target.value)
                }}
                InputLabelProps={{shrink:true}}
                size='small'/>
            </Grid>
          </Grid>
          <br/>
          <Grid container spacing={1}>
            <Grid item xs = {4}>
              <Typography variant='subtitle1'>Nova Senha</Typography>
            </Grid>
            <Grid item xs = {8}>
              <TextField
                variant='outlined'
                fullWidth
                type='password'
                value={novaSenha}
                onChange={(e)=>{
                  setNovaSenha(e.target.value)
                }}
                InputLabelProps={{shrink:true}}
                size='small'/>
            </Grid>
          </Grid>
          <br/>
          <Grid container spacing={1}>
            <Grid item xs = {4}>
              <Typography variant='subtitle1'>Confirmação</Typography>
            </Grid>
            <Grid item xs = {8}>
              <TextField
                variant='outlined'
                fullWidth
                type='password'
                value={confirmaSenha}
                onChange={(e)=>{
                  setConfirmaSenha(e.target.value)
                }}
                InputLabelProps={{shrink:true}}
                size='small'/>
            </Grid>
          </Grid>
          <br/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button autoFocus  color="primary" variant='contained' onClick={()=>{
            if(senha =='' || novaSenha == '' || confirmaSenha == ''){
              Swal.fire({
                title : 'Datacount', 
                text : 'Todos os campos devem ser informados!', 
                icon:'warning', 
                showConfirmButton : false, 
                showCancelButton : false, 
                timer : 1500,
              })
              return
            }
            if(novaSenha != confirmaSenha){
              Swal.fire({
                title : 'Datacount', 
                text : 'A senha e a confirmação devem ser iguais!', 
                icon:'warning', 
                showConfirmButton : false, 
                showCancelButton : false, 
                timer : 1500,
              }) 
              return
            }
            Swal.fire({
              title : 'Datacount', 
              text : 'Deseja alterar a senha agora?', 
              icon:'question', 
              showConfirmButton : true, 
              confirmButtonText : 'Sim', 
              showCancelButton : true, 
              cancelButtonText : 'Não'
            }).then(resp=>{
              if(resp.isConfirmed){
                  Conexao.post('clientesController/atualizarSenhaCliente', {
                    idempresa : props.idempresa, 
                    idcliente : props.idcliente, 
                    idusuario : props.idusuario, 
                    idacesso : props.idacesso, 
                    senha : md5(senha), 
                    novasenha : md5(novaSenha)
                  }).then(response=>{
                    if(response.status == 200 && response.data.status == 'OK'){
                      Swal.fire({
                        title : 'Datacount', 
                        text : 'Senha alterada com sucesso!', 
                        icon:'success', 
                        showConfirmButton : false, 
                        showCancelButton : false, 
                        timer : 1500,
                      })
                    }else{
                      Swal.fire({
                        title : 'Datacount', 
                        text : 'Não foi possível alterar', 
                        icon:'warning', 
                        showConfirmButton : false, 
                        showCancelButton : false, 
                        timer : 1500,
                      })
                    }
                    setOpen(false)
                  }).catch(error=>{
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Não foi possível alterar', 
                      icon:'error', 
                      showConfirmButton : false, 
                      showCancelButton : false, 
                      timer : 1500,
                    })
                    setOpen(false)
                  })
              }
            })
          }}>
            gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalTrocaSenha)