import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import WindowIcon from '@material-ui/icons/Dashboard'
import {Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import IconTecnico from '@material-ui/icons/Accessibility'
import IconRegiao from '@material-ui/icons/Public'
import ModalNovaJanelaTecnico from './ModalBuscaTecnicoNovaJanela'
import ModalNovaJanelaRegiao from './ModalBuscaRegioesNovaJanela'
import ModalNovaJanelaGenerica from './ModalNovaJanelaGenerica'
import Delete from '@material-ui/icons/DeleteForever'
import AllInboxIcon from '@material-ui/icons/AllInbox';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = React.useState(false)
    const [janelas, setJanelas] = React.useState([])
    const [loading, setLoading]= React.useState(false)
    const {idusuario, idempresa} = props;

    const handleClose = () =>{
        props.updateJanelas()
        setOpen(false)
    }
    
    const handleClickOpen = () =>{
        setOpen(true)
    }

    React.useEffect(()=>{
      if(open){
        getJanelas();
      }
    }, [open])


    const getJanelas = async () =>{
        
      setLoading(true)

      Conexao.get('chamadosTecnicosController/getJanelasTelaGestao?idusuario='+idusuario+'&idempresa='+idempresa).then(response=>{

          if(response.data.status =='OK'){
              setJanelas(response.data.payload)
          }else{
              setJanelas([])
          }

          setLoading(false)

      }).catch(error=>{
          setJanelas([])
          setLoading(false)
      })

  }


  const renderTipoBandeja = (bandeja) =>{
    if(bandeja =='T' ){
      return <Tooltip title ='Bandeja relacionada a um técnico especifico, apenas ele poderá ver os chamados.'><IconTecnico/></Tooltip>;
    }else if(bandeja =='R'){
      return <Tooltip title ='Bandeja relacionada a uma região, todos os técnicos vinculados a ela terão acesso aos chamados.'><IconRegiao/></Tooltip>;
    }else{
      return <Tooltip title ='Bandeja genérica, usada apenas para movimentar e gerenciar chamados, técnicos não veem chamados nela armazenado.'><AllInboxIcon/></Tooltip> 
    }
    
  }
  return (
    <React.Fragment>
     
       
        <Tooltip title='Adicionar/Remover janelas dessa visualização'><WindowIcon  onClick={handleClickOpen} style ={{cursor:'pointer'}}/></Tooltip>
   
    
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        fullWidth
        maxWidth='lg'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          GERENCIAMENTO DE BANDEJAS DE GESTÃO
        </DialogTitle>
        <DialogContent dividers style = {{height:"65vh", overflow:"auto", overflowX:'hidden'}}>
          <Table className='table table-sm table-bordered table-striped table-hover ' style ={{fontSize:'12px'}}>
            <TableHead>
              <TableCell align='center' style = {{width:'3%', fontSize:'12px'}}>#</TableCell>
              <TableCell align='left' style = {{width:'15%', fontSize:'12px'}}>DESCRIÇÃO DA BANDEJA</TableCell>
              <TableCell align='center' style = {{width:'3%', fontSize:'12px'}}>TIPO</TableCell>
              <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>ANDAMENTO</TableCell>
              <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>CAMINHO</TableCell>
              <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>ATENDENDO</TableCell>
              <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>FINALIZADO</TableCell>
              <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>A.PEÇAS</TableCell>
              <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>RETORNO</TableCell>
              <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>ORÇAMENTO</TableCell>
              <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>BG COLOR</TableCell>
              <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>COLOR</TableCell>
              <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>EXCLUIR</TableCell>
            </TableHead>
            <TableBody>
              {
                janelas && janelas.map((janela, i)=>{
                  return <TableRow key = {'LJ'+i}>
                  <TableCell align='center'  style ={{fontSize:'12px'}}>{(i + 1)}</TableCell>
                  <TableCell align='left'  style ={{fontSize:'12px'}}>{janela.nomerecipiente}</TableCell>
                  <TableCell align='center' style ={{fontSize:'12px'}}>{renderTipoBandeja(janela.tipobandeja)  }</TableCell>
                  <TableCell align='center'  style ={{fontSize:'12px'}}> <input type = "checkbox" id = "subscribeNews" name = "subscribe" value = "t" checked={janela.statuscaminho == 't'}/></TableCell>
                <TableCell align='center'  style ={{fontSize:'12px'}}><input type = "checkbox" id = "subscribeNews" name = "subscribe" value = "t" checked={janela.statuscaminho == 't'}/></TableCell> 
                <TableCell align='center'  style ={{fontSize:'12px'}}><input type = "checkbox" id = "subscribeNews" name = "subscribe" value = "t" checked={janela.statuscaminho == 't'}/></TableCell>
                <TableCell align='center'  style ={{fontSize:'12px'}}><input type = "checkbox" id = "subscribeNews" name = "subscribe" value = "t" checked={janela.statuscaminho == 't'}/></TableCell>
                <TableCell align='center'  style ={{fontSize:'12px'}}><input type = "checkbox" id = "subscribeNews" name = "subscribe" value = "t" checked={janela.statuscaminho == 't'}/></TableCell>
                <TableCell align='center'  style ={{fontSize:'12px'}}><input type = "checkbox" id = "subscribeNews" name = "subscribe" value = "t" checked={janela.statuscaminho == 't'}/></TableCell>
                <TableCell align='center'  style ={{fontSize:'12px'}}><input type = "checkbox" id = "subscribeNews" name = "subscribe" value = "t" checked={janela.statuscaminho == 't'}/></TableCell>
                <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>{janela.backgroundcolor}</TableCell>
                <TableCell align='center' style = {{width:'5%', fontSize:'12px'}}>{janela.fontcolor}</TableCell>
                  <TableCell align='center'  style ={{fontSize:'12px'}}>
                    <Button variant='contained' color='secondary' onClick={()=>{
                      Swal.fire({
                        title : 'Printerconnect', 
                        text : 'Deseja remover esta bandeja de gestão agora?', 
                        icon :'error', 
                        showConfirmButton : true, 
                        confirmButtonText : 'SIM', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                      }).then(resposta =>{
                      if(resposta.isConfirmed){
                         
                          Conexao.post('chamadosTecnicosController/deleteBandeja',{
                              idusuario : idusuario, 
                              idempresa : idempresa, 
                              idbandeja :  janela.idbandeja, 
                              tipo : janela.tipobandeja
                      
                          }).then(response=>{
                              if(response.data.status=='OK'){
                                 getJanelas()
                              }
                          }).catch(error=>{
                      
                          })
                    }
                })
                    }}><Delete/></Button>
                  </TableCell>
                </TableRow>
                })
              }
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <ModalNovaJanelaTecnico conexao = {Conexao} usuario={idusuario} empresa={idempresa} cor='' getJanelas ={()=>getJanelas()}/>
          <ModalNovaJanelaRegiao conexao = {Conexao} usuario={idusuario} empresa={idempresa} cor='' getJanelas ={()=>getJanelas()}/>
          <ModalNovaJanelaGenerica conexao = {Conexao} usuario={idusuario} empresa={idempresa} cor='' getJanelas ={()=>getJanelas()}/>

 
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


export default withRouter(ModalPadrao)