import React, {useState, useRef, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import Template from '../Template/Template'
import {Breadcrumbs, Typography} from '@material-ui/core'
import {Link } from 'react-router-dom'



const DashboardCliente = (props) =>{

    return <Template breadcrumbs ={
        <Breadcrumbs aria-label="breadcrumb">  
            <Typography>Você esta em:</Typography>          
                <Link color="inherit" to={'/webclient/home'} >
                    Home
                </Link>   
                <Typography>Dashboard</Typography>       
            </Breadcrumbs>
    }>
        <span>teste</span>
    </Template>
}

export default withRouter(DashboardCliente)