import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Cloud from '@material-ui/icons/Backup'
import GetAppIcon from '@material-ui/icons/GetApp';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip,Table,  TableHead, TableRow,Badge, TableCell, TableBody} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AllInboxIcon from '@material-ui/icons/AllInbox';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalListaUploads = (props) => {

  const [open, setOpen] = React.useState(false)
  const handleClose = () =>{
    setOpen(false)
  }

  return (
    <div>

      <Badge color='secondary' badgeContent={props.uploads.length}><AllInboxIcon style ={{cursor:"pointer"}} onClick={()=>setOpen(true)}/></Badge>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        fullWidth
        maxWidth='lg'
        hideBackdrop
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Upload de dados para o modelo : {props.modelo}
        </DialogTitle>
        <DialogContent dividers style = {{height:"350px", overflow:"auto"}}>
            <Table>
                <TableHead>
                    <TableCell>Data</TableCell>
                    <TableCell>Hora</TableCell>
                    <TableCell>Nome do Arquivo</TableCell>
                    <TableCell>Download</TableCell>
                </TableHead>
                <TableBody>
                    {
                        props.uploads && props.uploads.map(function(cliente, i){
                            return <TableRow>
                            <TableCell>{cliente.datacadastro}</TableCell>
                            <TableCell>{cliente.horacadastro.substr(0,8)}</TableCell>
                            <TableCell>{cliente.nomearquivo}</TableCell>
                            <TableCell>
                                <a href ={'https://www.printerconnect.com.br/homologacoes/'+cliente.nomearquivo} target='_blank'><GetAppIcon/></a></TableCell>
                        </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalListaUploads)