
import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField, InputAdornment} from '@material-ui/core'
import Swal from 'sweetalert2'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import CurrencyInput  from 'react-currency-masked-input'



function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyInput 
        {...other}
        {...props}
        defaultValue = {0}
        
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        separator=','
      />
    );
  }

  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };


const TextFieldMoeda = (props) =>{
   

   
    return <TextField 
            {...props} 
            InputProps={{
             inputComponent : TextMaskCustom    
            }}                              
            />
}

export default TextFieldMoeda;
