import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import SettingsIcon from '@material-ui/icons/Settings';

import Tooltip from '@material-ui/core/Tooltip';

import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import ModalNovoConsumivel from './ModalNovoConsumivel'


const CadastroConsumiveis = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [consumiveis, setConsumiveis] = useState([])

    const [edicao, setEdicao] = useState([])
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [consumivelEdicao, setConsimivelEdicao] = useState()




    useEffect(()=>{
        getConsumiveis();
    }, [])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])


    const getConsumiveis = () =>{
        
        setLoading(true)
        Conexao.post('consumiveisController/getConsumiveis', {
            filtro : filtro, 
            idusuario : props.match.params.idempresa
        }).then(response =>{
            
            if(response.status == 200){
               
                if(response.data.status == 'OK'){
                    setConsumiveis(response.data.payload);
                    console.log(response.data.payload)
                }else{
                    setConsumiveis([])
                }
            }else{
                setConsumiveis([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
        })
    }

    const verificaEmpresaAtiva = (id, operacao) =>{
        
       return true;
    }

    

    


    return <Principal atualizar = {loading}>
        <Container >
        <Accordion elevation='0'>
            <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <div style = {{display : 'flex', fontSize:'10px!important'}}>
            <Typography color="textPrimary" >Você esta em : </Typography>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Cadastro de Consumíveis</Typography>
            </Breadcrumbs>
        </div>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing = {3}>
               
                <Grid item xs={4}>
                    <TextField
                        label='Filtro'
                        disabled= {parametroFiltro == 'ativos' || parametroFiltro == 'inativos' || parametroFiltro == 'todos'}
                        variant='outlined'
                        value={filtro}
                        onChange = {(e)=>setFiltro(e.target.value)}
                        InputLabelProps = {{shrink : true}}
                        fullWidth/>

                </Grid>
                <Grid item xs = {1}>
                    <Button className='fullButton' variant='outlined' color='primary' onClick={getConsumiveis}>Pesquisar</Button>
                </Grid>
            </Grid>
            </AccordionDetails>
        </Accordion>
        
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
        
        
        
        </Grid>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
            <Table  >
                <TableHead>
                    <TableCell align='center' style = {{width:"5%"}}>#</TableCell>
                    <TableCell align='left' style = {{width:"85%"}}>Descrição</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Editar</TableCell>

                    <TableCell align='center' style = {{width:"5%"}}>Excluir</TableCell>
                </TableHead>
                <TableBody>
                    {
                        consumiveis && consumiveis.map(function(consumivel, i){
                            return <TableRow id = {'EMP'+i}>
                                        <TableCell align='center'><Typography variant='caption' color='primary'>{(i + 1)}</Typography></TableCell>
                                        <TableCell align='left' >
                                            <Typography variant='caption' color='primary'>{consumivel.descricao}</Typography>
                                        
                                        </TableCell>

                                        <TableCell align='center'>
                                        <Tooltip title ='Editar informações da marca'>
                                            <Button onClick = {()=>{
                                                    setConsimivelEdicao(consumivel)
                                                   
                                                }}><EditIcon color='primary'/></Button>
                                            </Tooltip> 
                                                            
                                        </TableCell>
                                        <TableCell align='center'>
                                        <Tooltip title ='Excluir Modelo'>
                                            <Button onClick = {()=>{
                                                    Swal.fire({
                                                        title :'Gestão de Equipamentos', 
                                                        icon : 'question',
                                                        text :`Deseja realmente excluir o consumível ${consumivel.descricao} ?`,
                                                        showConfirmButton : true, 
                                                        confirmButtonText : 'Confirmar', 
                                                        showCancelButton : true, 
                                                        cancelButtonText : 'Cancelar',  

                                                    }).then(resposta =>{
                                                        if(resposta.isConfirmed){
                                                            Conexao.post('consumiveisController/deleteConsumivel', {
                                                                idusuario : props.match.params.idempresa, 
                                                                idconsumivel : consumivel.idconsumivel
                                                            }).then(response =>{                                                                
                                                                if(response.status == 200){
                                                                   
                                                                    if(response.data.status == 'OK'){
                                                                        getConsumiveis()
                                                                        Swal.fire({
                                                                            icon : 'success', 
                                                                            title :'Cadastro de Marcas', 
                                                                            text :'Marca excluído com sucesso !',
                                                                            showConfirmButton : false, 
                                                                            confirmButtonText : 'Confirmar', 
                                                                            showCancelButton : false, 
                                                                            cancelButtonText : 'Cancelar',
                                                                            timer : 1500,
                                                                           
                                                                        })

                                                                        
                                                                    }else{
                                                                        Swal.fire({
                                                                            icon : 'warning', 
                                                                            title :'Cadastro de Consumíveis', 
                                                                            text :'Não foi possível excluir',
                                                                            showConfirmButton : false, 
                                                                            confirmButtonText : 'Confirmar', 
                                                                            showCancelButton : false, 
                                                                            cancelButtonText : 'Cancelar',
                                                                            timer : 1500
                                                                        })
                                                                    }
                                                                }else{
                                                                    Swal.fire({
                                                                            icon : 'warning', 
                                                                            title :'Cadastro de Consumíveis', 
                                                                            text :'Não foi possível excluir',
                                                                            showConfirmButton : false, 
                                                                            confirmButtonText : 'Confirmar', 
                                                                            showCancelButton : false, 
                                                                            cancelButtonText : 'Cancelar',
                                                                            timer : 1500
                                                                        })
                                                                }
                                                                
                                                            }).catch(error =>{
                                                                Swal.fire({
                                                                    icon : 'error', 
                                                                    title :'Cadastro de Consumíveis', 
                                                                    text :'Não foi possível excluir',
                                                                    showConfirmButton : false, 
                                                                    confirmButtonText : 'Confirmar', 
                                                                    showCancelButton : false, 
                                                                    cancelButtonText : 'Cancelar',
                                                                    timer : 1500
                                                                })
                                                            })
                                                        }
                                                    })
                                                   
                                                }}><DeleteForeverIcon className='iconDanger' style={{border:'none!important'}}/></Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                        })
                    }
                    {
                       consumiveis.length == 0  && <TableRow>
                       <TableCell colSpan={4} align='left'>* Não existe(m) registro(s)</TableCell>
                      
                   </TableRow>
                   }
                </TableBody>
            </Table>
        </Grid>
        <ModalNovoConsumivel 
            consumivelEdicao ={consumivelEdicao}
            limparEdicao ={()=>setConsimivelEdicao(null)}
            idUsuario = {props.match.params.idempresa} 
            updateData = {()=>getConsumiveis()} />
        </Container>

    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroConsumiveis)