import React, {useState,useEffect, useRef, Fragment} from 'react'
import {Grid, Container, TextField, Divider, Typography, Button} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import ModalEditarEquipamento from './ModalEditarEquipamento'




export default function TabEquipamento(props){
    


    return <div style ={{paddingTop:"0px", paddingTop:'0px'}}>
       
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Número de Série'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.numeroserie}
                    size='small'
                    />
            </Grid>

            <Grid item xs={3}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Placa'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.codigoadicional}
                    size='small'
                    />
            </Grid>
            <Grid item xs={5}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Marca'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.marca}
                    size='small'
                    />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Número de Série'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.modelo}
                    size='small'
                    />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Observacoes'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.observacoes}
                    size='small'
                    />
            </Grid>

        </Grid>
    
        <Typography>Informações sobre instalação</Typography>
        <br/>

        <Grid container spacing={2}>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='ID Cliente'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.idcliente}
                    size='small'
                    />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Razão Social'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.razaosocial}
                    size='small'
                    />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Nome Fantasia'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.nomefantasia}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Endereço'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.logradouro}
                    size='small'
                    />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='NRº'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.numero}
                    size='small'
                    />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Complemento'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.complemento}
                    size='small'
                    />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='CEP'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.cep}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Bairro'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.bairro}
                    size='small'
                    />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Cidade'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.cidade}
                    size='small'
                    />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Estado'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.estado}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Email'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.emailinstalacao ? props.equipamento.emailinstalacao: props.equipamento.email }
                    size='small'
                    />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Telefone Comercial'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.comercialinstalacao ? props.equipamento.comercialinstalacao: props.equipamento.telefonecomercial }
                    size='small'
                    />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    variant='outlined'
                    label='Telefone Celular'
                    InputLabelProps={{shrink : true}}
                    value = {props.equipamento.celularinstalacao ? props.equipamento.celularinstalacao: props.equipamento.telefonecelular }
                    size='small'
                    />
            </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
            <Grid item xs = {12} style ={{textAlign:"right"}}>
                <ModalEditarEquipamento equipamento={props.equipamento} idusuario={props.equipamento.idusuario} handleClose = {()=>props.handleClose()}/>
            </Grid>
        </Grid>
    </div>
}