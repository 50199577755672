import React, {useState, useEffect, useRef, Fragment} from 'react'
import Drawer from '@material-ui/core/Drawer'
import { Button, Divider, Grid, Typography, Tooltip} from '@material-ui/core'
import ModalMonitorIndividual from './ModalMonitoramentoIndividual'
import Swal from 'sweetalert2'
import LockIcon from '@material-ui/icons/Lock'
import Close from '@material-ui/icons/Close'
import DetailIcon from '@material-ui/icons/Apps'
import { Alert, AlertTitle } from '@material-ui/lab'


export default function SideBarPadrao(props){

    const [open, setOpen] = useState(false)

    const toggleDrawer = () =>{
        setOpen(!open)
    }

    return <div>
    <React.Fragment >
       
        <Drawer
             
            disableEscapeKeyDown
          anchor={'right'}
          open={props.chaveDca && props.chaveDca != '' }
          onClose={props.closeModal}
        >
            <div style ={{width: "100%", height : '55px', backgroundColor:"#37474F"}}></div>
            <div style ={{width: "100%", height : '28px', backgroundColor:"#CFD8DC", textAlign:'center'}}>
                <Typography variant='caption' style={{textAlign : 'center'}}>INFORMAÇÕES DO DCA</Typography>
            </div>
            
          <div id='div-side-menu' style ={{
            width : '400px', 
            padding : '30px', 
            paddingTop : '30px'
            
          }}>
            
           
          </div>
        </Drawer>
      </React.Fragment>
  </div>
}
