import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, TextField} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SettingsIcon from '@material-ui/icons/Settings';
import IconMarca from '@material-ui/icons/Commute'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [descricao, setDescricao] = React.useState('')

    const handleClickOpen = () =>{
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }
  return (
    <div>
    
      <Tooltip title='Cadastrar um novo modelo'>  
        <Button disabled={props.disabled} autoFocus onClick={handleClickOpen} variant='contained' color="primary">
            <AddIcon /> Nova Marca
        </Button>         
        
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Cadastrar Nova Marca
        </DialogTitle>
        <DialogContent dividers >
         <TextField 
            label='Descrição da Marca'
            fullWidth
            value={descricao}
            onChange={(e)=>setDescricao(e.target.value)}
            InputLabelProps={{shrink:true}}
            variant='outlined'
            />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
          <Button disabled={loading}  autoFocus onClick={handleClose} color="primary" variant='contained' onClick={()=>{
                Swal.fire({
                    title : 'Printerconnect - Frota', 
                    text : 'Deseja gravar esta nova marca?', 
                    icon :'question', 
                    showConfirmButton : true, 
                    showCancelButton:true, 
                }).then(resposta=>{
                    if(resposta.isConfirmed){
                        if(descricao ==''){
                            Swal.fire({
                                title : 'Printerconnect - Frota', 
                                text : `Não é possível incluir sem uma descrição para a marca!`, 
                                icon :'warning',
                                timer : 1500 
                            })
                            return;
                        }
                        setLoading(true)
                        Conexao.post('veiculosController/insertMarca',{
                            idusuario : props.usuario, 
                            descricao : descricao
                        }).then(response=>{
                            if(response.data.status == 'OK'){
                                setOpen(false)
                                setDescricao('')
                                props.getMarcas();
                                setLoading(false)
                            }else{
                                Swal.fire({
                                    title : 'Printerconnect - Frota', 
                                    text : `Não é possível incluir!`, 
                                    icon :'warning',
                                    timer : 1500 
                                })
                                setOpen(false)
                                setLoading(false)
                            }
                        }).catch(error=>{
                            Swal.fire({
                                title : 'Printerconnect - Frota', 
                                text : `Não é possível incluir!`, 
                                icon :'error',
                                timer : 1500 
                            })
                            setOpen(false)
                            setLoading(false)
                        })
                    }
                })
            }}>
            GRAVAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalPadrao)