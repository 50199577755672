import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import CategoryIcon from '@material-ui/icons/Category';
import Tooltip from '@material-ui/core/Tooltip';
import {Grid, TextField, Table, TableCell, TableHead, TableBody, TableRow} from '@material-ui/core'
import Conexao from '../../../providers/Conexao'
import Swal from 'sweetalert2'



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalGruposFranquia(props) {
  const [open, setOpen] = React.useState(false);
  const [grupos, setGrupos] = React.useState([]);
  const [grupoEdicao, setGrupoEdicao] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
    getGruposFranquia()
    setGrupoEdicao([])
  };
  const handleClose = () => {
    setOpen(false);
  };

  const refDescricao = React.useRef();
  const insertGrupoFranquia = () =>{

    if(refDescricao.current && refDescricao.current.value == ''){
        Swal.fire({
            title :'Printerconnect', 
            text : 'Informe uma descrição para o grupo de franquia !', 
            icon : 'warning', 
            timer : 1500, 
            showCancelButton : false, 
            showConfirmButton : false
        });
        return false;
    }

    Swal.fire({
      title :'Printerconnect', 
      text : 'Deseja incluir este grupo ?', 
      icon : 'info',                     
      showCancelButton : true, 
      cancelButtonText : "Não",
      showConfirmButton : true,
      confirmButtonText : 'Sim', 
  }).then(resposta =>{
    if(resposta.isConfirmed){
      Conexao.post('contratosController/insertGrupoFranquia', {
        idUsuario : props.idUsuario,
        idEmpresa : props.idEmpresa, 
        descricao : refDescricao.current ? refDescricao.current.value : ''
      }).then( response =>{
        if(response.data.status =='OK'){
            Swal.fire({
                title :'Printerconnect', 
                text : 'Grupo de franquia inserido com sucesso !', 
                icon : 'success', 
                timer : 1500, 
                showCancelButton : false, 
                showConfirmButton : false
            });
            getGruposFranquia()
        }else{
            Swal.fire({
                title :'Printerconnect', 
                text : 'Não foi possível inserir', 
                icon : 'warning', 
                timer : 1500, 
                showCancelButton : false, 
                showConfirmButton : false
            });
        }
        refDescricao.current.value = '';
      }).catch(error =>{
        Swal.fire({
            title :'Printerconnect', 
            text : 'Não foi possível inserir', 
            icon : 'error', 
            timer : 1500, 
            showCancelButton : false, 
            showConfirmButton : false
        });
        refDescricao.current.value = '';
       
      });
    }
  })



      
  }


  const removeGruposFranquia =(id)=>{
    Swal.fire({
      title :'Printerconnect', 
      text : 'Deseja remover este grupo ?', 
      icon : 'info',                     
      showCancelButton : true, 
      cancelButtonText : "Não",
      showConfirmButton : true,
      confirmButtonText : 'Sim', 
  }).then(resposta =>{
    if(resposta.isConfirmed){
      Conexao.post('contratosController/removeGruposFranquia', {
        idusuario : props.idUsuario,
        idempresa : props.idEmpresa, 
        idgrupofranquia : id
      }
      ).then(response =>{
        if(response.data.status == 'OK'){
          getGruposFranquia()
          Swal.fire({
            title :'Printerconnect', 
            text : 'Registro removido com sucesso !', 
            icon : 'success',                     
            showCancelButton : false, 
            cancelButtonText : "Não",
            showConfirmButton : false,
            confirmButtonText : 'Sim', 
            timer : 1000
        })
        }else{
          Swal.fire({
            title :'Printerconnect', 
            text : 'Não foi possivel remover', 
            icon : 'warning',                     
            showCancelButton : false, 
            cancelButtonText : "Não",
            showConfirmButton : false,
            confirmButtonText : 'Sim', 
            timer : 1000
        })
        }
      }).catch(error =>{
        Swal.fire({
          title :'Printerconnect', 
          text : 'Não foi possivel remover !', 
          icon : 'error',                     
          showCancelButton : false, 
          cancelButtonText : "Não",
          showConfirmButton : false,
          confirmButtonText : 'Sim', 
          timer : 1000
      })
      })
    }
  })
  }

  const updateGrupoFranquia = () =>{
    if(refDescricao.current &&  refDescricao.current.value == ''){
      Swal.fire({
        title :'Printerconnect', 
        text : 'Informe o nome do grupo de franquia', 
        icon : 'warning',                     
        showCancelButton : false, 
        cancelButtonText : "Não",
        showConfirmButton : false,
        confirmButtonText : 'Sim', 
        timer : 1000
    })
      return 
    }

    Swal.fire({
      title :'Printerconnect', 
      text : 'Deseja gravar ?', 
      icon : 'info',                     
      showCancelButton : true, 
      cancelButtonText : "Não",
      showConfirmButton : true,
      confirmButtonText : 'Sim', 
  }).then(resposta =>{
    if(resposta.isConfirmed){
      Conexao.post('contratosController/updateGrupoFranquia', {
        idusuario : props.idUsuario,
        idempresa : props.idEmpresa, 
        idgrupofranquia : grupoEdicao.idgrupofranquia, 
        descricao : refDescricao.current ? refDescricao.current.value : ''
      }
      ).then(response =>{
        if(response.data.status == 'OK'){
          getGruposFranquia()
          Swal.fire({
            title :'Printerconnect', 
            text : 'Registro alterado com sucesso !', 
            icon : 'success',                     
            showCancelButton : false, 
            cancelButtonText : "Não",
            showConfirmButton : false,
            confirmButtonText : 'Sim', 
            timer : 1000
        })
        }else{
          Swal.fire({
            title :'Printerconnect', 
            text : 'Não foi possivel alterar', 
            icon : 'warning',                     
            showCancelButton : false, 
            cancelButtonText : "Não",
            showConfirmButton : false,
            confirmButtonText : 'Sim', 
            timer : 1000
        })
        }
      }).catch(error =>{
        Swal.fire({
          title :'Printerconnect', 
          text : 'Não foi possivel alterar !', 
          icon : 'error',                     
          showCancelButton : false, 
          cancelButtonText : "Não",
          showConfirmButton : false,
          confirmButtonText : 'Sim', 
          timer : 1000
      })
      })
    }
  })



    
  }


  const getGruposFranquia = () =>{
    Conexao.post('contratosController/getGruposFranquia', {
      idusuario : props.idUsuario,
      idempresa : props.idEmpresa, 
    }
    ).then(response =>{
      if(response.data.status == 'OK'){
        setGrupos(response.data.payload)
      }else{
        setGrupos([])
      }
    }).catch(error =>{
      setGrupos([])
    })
  }

  return (
    <div>
     
      <Tooltip title='Gerenciar Grupos de Franquia'>
            <Fab size ='small' color="secondary"  aria-label="add"onClick={handleClickOpen} >
                <CategoryIcon />
            </Fab>
        </Tooltip>
      <Dialog maxWidth={'md'} fullWidth hideBackdrop disableBackdropClick disableEscapeKeyDown onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Lista de Grupos de Franquia disponíveis
        </DialogTitle>
        <DialogContent dividers style ={{height:"500px", overflow:"auto", overflowX:"hidden"}}>
          <Grid container spacing={1}>
            <Grid item xs = {10}>
              <TextField
                label='Nome da Franquia'
                variant='outlined'
                fullWidth
                inputRef={refDescricao}
                InputLabelProps={{shrink : true}} />
            </Grid>
            <Grid item xs = {2}>
              {
                grupoEdicao && grupoEdicao.idgrupofranquia ? <Button variant='contained' fullWidth onClick={()=>updateGrupoFranquia()}>Alterar</Button> :<Button variant='contained' fullWidth onClick={()=>insertGrupoFranquia()}>Incluir</Button>
              }
              
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableCell style ={{width:"10%", textAlign:"center"}}>ID</TableCell>
              <TableCell  style ={{width:"70%", textAlign:"left"}}>descrição</TableCell>
              <TableCell  style ={{width:"10%", textAlign:"center"}}>Editar</TableCell>
              <TableCell  style ={{width:"10%", textAlign:"center"}}>Remover</TableCell>
            </TableHead>

            <TableBody>
              {
                grupos && grupos.map(function(grupo, i){
                  return  <TableRow>
                  <TableCell style ={{textAlign:"center"}}>{grupo.idgrupofranquia}</TableCell>
                  <TableCell  style ={{textAlign:"left"}}>{grupo.descricao}</TableCell>
                  <TableCell  style ={{textAlign:"center"}}><Button variant='outlined' color='primary' onClick={()=>{
                    Swal.fire({
                      title :'Printerconnect', 
                      text : 'Deseja editar o registro Atual ?', 
                      icon : 'info',                     
                      showCancelButton : true, 
                      cancelButtonText : "Não",
                      showConfirmButton : true,
                      confirmButtonText : 'Sim'
                  }).then(resposta =>{
                    if(resposta.isConfirmed){
                      refDescricao.current.value = grupo.descricao
                      setGrupoEdicao(grupo)
                    }
                  })
                  }}>Editar</Button></TableCell>
                  <TableCell  style ={{textAlign:"center"}}><Button variant='contained' color='secondary' style ={{color:"#f00!important"}} onClick={()=>{
                    removeGruposFranquia(grupo.idgrupofranquia)
                  }}>Excluir</Button></TableCell>
                </TableRow>
                })
              }
            </TableBody>
          </Table>
         
        </DialogContent>
        <DialogActions>
         
          <Button variant='contained' size='large' onClick={handleClose} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
