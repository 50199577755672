import React, {useState, useEffect} from 'react'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Conexao from '../../providers/Conexao'
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CachedIcon from '@material-ui/icons/Autorenew';
import CommentIcon from '@material-ui/icons/Comment';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import ItemJanela from './ItemJanela'
import {Tooltip, Button,Badge} from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import Swal from 'sweetalert2'
import ModalAterarBandeja from './ModalAlterarJanela'




export default function JanelaGestao(props){
    const [chamados, setChamados] = useState([])
    const [loading, setLoading] =useState(false)
    const [janelaFila, setJanelaFila]=useState(null)
    const [updatedFila, setUpdatedFila] =useState(false)
    const {data} = props
    




    useEffect(()=>{
        if(props.atualizarJanelas == data.idbandeja  ){
            props.setLoading(true);
            getChamadosJanela();
            props.resetMovimentacao()
            setTimeout(()=>{
                props.setLoading(false);
            }, 600)
        }
    }, [props.atualizarJanelas])

    useEffect(()=>{
        getChamadosJanela();
        const timer = setInterval(()=>{
            getChamadosJanela()
        }, data.tipobandeja == 'T' ? 180000 : 300000)

        return ()=>{
            clearInterval(timer)
        }
    },[])

    const getChamadosJanela = async (atulizouFila = '') =>{
        
        if(janelaFila!= null && atulizouFila == ''){
            return;
        }
       
        
            setLoading(true)
            Conexao.post('chamadosTecnicosController/getChamadosByJanelaGestaoNew?idusuario='+props.idusuario+'&idbandeja='+data.idbandeja+'&tipobandeja='+data.tipobandeja+'&idtecnico='+data.idtecnico+'&idregiao='+data.idregiao, {
                statuscaminho : data.statuscaminho,
                statusatendendo : data.statusatendendo, 
                statusandamento : data.statusandamento,
                statusaguardandopecas : data.statusaguardandopecas,
                statusfinalizado : data.statusfinalizado,
                statusretorno : data.statusretorno,
                statusorcamento : data.statusorcamento,
    
            }).then(response=>{
                if(response.data.status =='OK'){
                    setChamados(response.data.payload)
                }else{
                    setChamados([])
                }
                setLoading(false)
            }).catch(error=>{
                setChamados([])
                setLoading(false)
            })
       

    }



   



    const renderClassJanela = () =>{
        if(data.idbandeja == props.janelaOrigem && props.movendoChamado){
            return 'janela-container-main janela-selecionada';

        }else if(data.idbandeja != props.janelaOrigem && props.movendoChamado){
            return 'janela-container-main movendo-chamado'
        }else{
            return 'janela-container-main'
        }
    }


    const handleRLDDChange =(reorderedItems) =>{  
        setJanelaFila(reorderedItems) 
        setUpdatedFila(true)
      }


      const updateChamadoBandeja = async () =>{
        props.setQuestion(true)
        Swal.fire({
            title: 'Printerconnect',
            text : `Deseja mover o chamado ${props.chamadoSelecionado.idchamadotecnico+'/'+props.chamadoSelecionado.sequenciaatendimento} para a bandeja ${data.nomerecipiente}?`, 
            icon : 'question', 
            showConfirmButton : true, 
            confirmButtonText : 'Sim', 
            showCancelButton : true, 
            cancelButtonText : 'Não'
        }).then(resposta=>{
            if(!resposta.isConfirmed){
                props.setQuestion(false)
                return; 
            }else{
                props.setQuestion(true)
               
                Conexao.post('chamadosTecnicosController/transfereChamadoJanela?idusuario='+data.idusuario+'&idempresa='+props.chamadoSelecionado.idempresa+'&destino='+data.idbandeja+'&idtecnico='+data.idtecnico+'&idchamadotecnico='+props.chamadoSelecionado.idchamadotecnico+'&posicao='+(chamados.length + 1)+'&idregiao='+data.idregiao,{
                    tipoAtendimento : props.chamadoSelecionado.descricaoatendimento, 
                    razaoSocial : props.chamadoSelecionado.razaosocial
                }).then(response=>{

                    if(response.data.status =='OK'){
                      
                        props.setAtualizarJanelas(props.chamadoSelecionado.idbandeja);
                        console.log('Movido da janela : ' + props.chamadoSelecionado.idbandeja+' para a janela : '+data.idbandeja);
                        getChamadosJanela()
                        if(data.idtecnico && data.idtecnico != null && data.idtecnico > 0){
                            const chave = props.tecnicos.filter(tecnico => (tecnico.idtecnico == data.idtecnico && tecnico.idusuario == data.idusuario)).map(function(tecnico){
                                return tecnico.chavepush
                            })
                            
                            if(chave){
                                Conexao.post('chamadosTecnicosController/pushNotification', {
                                    destino : chave[0], 
                                    titulo : 'Aviso de Novo Atendimento - Printerconnect',  
                                    mensagem : 'Novo Chamado ('+props.chamadoSelecionado.descricaoatendimento+') aberto para '+props.chamadoSelecionado.razaosocial,
                                    data :{
                                        atualizarTela : props.chamadoSelecionado.tipoatendimento == 8 ? 'preventivas': 'meusChamados', 
                                        mensagem : props.chamadoSelecionado.tipoatendimento == 8 ? 'Nova Manutenção Preventiva recebida': 'Novo Chamado Técnico recebido.',
                                    }
                                }).then(response=>{
                                    
                                }).catch(error=>{
                                    console.log(error)
                                })
                            }
                        }
                        

                        if(props.chamadoSelecionado.idtecnico && props.chamadoSelecionado.idtecnico != null && props.chamadoSelecionado.idtecnico > 0){
                            const destino = props.tecnicos.filter(tecnico => (tecnico.idtecnico == props.chamadoSelecionado.idtecnico  && tecnico.idusuario == data.idusuario)).map(function(tecnico){
                                return tecnico.chavepush
                            })
                           
                            if(destino){
                                Conexao.post('chamadosTecnicosController/pushNotification', {
                                    destino : destino[0], 
                                    titulo : 'Retirada de Chamado da Fila - Printerconnect',  
                                    mensagem : 'O Chamado aberto para '+props.chamadoSelecionado.razaosocial+' não esta mais em sua fila de atendimento.',
                                    data :{
                                        atualizarTela : props.chamadoSelecionado.tipoatendimento == 8 ? 'preventivas': 'meusChamados', 
                                        mensagem : props.chamadoSelecionado.tipoatendimento == 8 ? 'Chamado de Manutenção Preventiva removida da sua fila.': 'Chamado Técnico removido da sua fila.', 
                                        removerChamado : 'T', 
                                        idChamado : props.chamadoSelecionado.idchamadotecnico
                                    }
                                }).then(response=>{
        
                                }).catch(error=>{
                                    
                                })
                            }
                        }



                        if(data.idregiao && data.idregiao != null && data.idregiao > 0){
                            const dados = props.tecnicos.filter(tecnico => (tecnico.idregiao == data.idregiao && tecnico.idusuario == data.idusuario && tecnico.chavepush)).map(function(tecnico){
                                return tecnico.chavepush
                            }) 
                            if(dados){
                                Conexao.post('chamadosTecnicosController/sendFCMulticast', {
                                    destino : dados, 
                                    titulo : 'Novo Chamado Técnico - Printerconnect',  
                                    mensagem : 'O Chamado aberto para '+props.chamadoSelecionado.razaosocial+' foi adicionado em sua região',
                                    data :{
                                        atualizarTela : 'Regiao', 
                                        mensagem : 'Novo chamado técnico adicionado em sua região', 
                                    }
                                }).then(response=>{
        
                                }).catch(error=>{
                                    
                                })
                            }
                        } 



                        if(props.chamadoSelecionado.idregiao && props.chamadoSelecionado.idregiao != null && props.chamadoSelecionado.idregiao > 0){
                            const dados = props.tecnicos.filter(tecnico => (tecnico.idregiao == props.chamadoSelecionado.idregiao && tecnico.idusuario == data.idusuario && tecnico.chavepush)).map(function(tecnico){
                                return tecnico.chavepush
                            })
                           
                            if(dados){
                                Conexao.post('chamadosTecnicosController/sendFCMulticast', {
                                    destino : dados, 
                                    titulo : 'Retirada de Chamado da Fila - Printerconnect',  
                                    mensagem : 'O Chamado aberto para '+props.chamadoSelecionado.razaosocial+' não esta mais disponível para sua região.',
                                    data :{
                                        atualizarTela : 'Regiao', 
                                        mensagem : 'Um chamado técnico foi removido da sua região', 
                                    }
                                }).then(response=>{
        
                                }).catch(error=>{
                                    
                                })
                            }
                        }

                        
                        
                    }else{
                        //setJanelas([])
                        Swal.fire({
                            title: 'Printerconnect',
                            text : 'Não foi possivel alterar!', 
                            icon : 'warning', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1000 
                        })
                    }
                    props.setQuestion(false)
                }).catch(error=>{
                    Swal.fire({
                        title: 'Printerconnect',
                        text : 'Não foi possivel alterar! Erro servidor.', 
                        icon : 'error', 
                        showCancelButton : false, 
                        showConfirmButton : false, 
                        timer : 1000 
                    })
                    props.setQuestion(false)
                })
            }
        })
        

    }




    return <div id={data.idbandeja} className={renderClassJanela()} >
        <div className='janela-container-title' style = {{backgroundColor : data.backgroundcolor}}>
            <div className='janela-title-loading'></div>
            <div className='janela-title-name texto-limitado'>{(props.indice + 1)+' - '+data.nomerecipiente}</div>
            {janelaFila == null ? <div className='janela-title-options'>
            <div className='itens'>
               
            </div>

            
                <div className='itens'><CachedIcon className={loading ? 'loading-icon loader' : 'loading-icon '} style = {{cursor:!props.movendoChamado ? '' : ''}} onClick={()=>{
                    if(!props.movendoChamado){
                        getChamadosJanela();
                    }
                    
                }}/></div>
                <div className='itens'><Tooltip title ='Enviar mensagem para o aparelho celular do técnico'><CommentIcon/></Tooltip></div>
                <div className='itens'><Tooltip title ='Reorganizar a sequencia de atendimento da bandeja/técnico selecionado'><FormatLineSpacingIcon onClick={()=>{
                    setJanelaFila(chamados.filter(item=> item.situacao == 4).map(function(chamado, i){
                        return {
                            ...chamado, 
                            id:i
                        }
                    }))
                }}/></Tooltip></div>
                <div className='itens'><Tooltip title = 'Selecione o chamado e deposite ele aqui!'><Badge color='secondary' badgeContent={props.movendoChamado && data.idbandeja && (data.idtecnico == 0 || data.idregiao == 0)!= props.janelaOrigem ?  0 : chamados.length}><AllInboxIcon className={props.movendoChamado && data.idbandeja && (data.idtecnico == 0 || data.idregiao == 0)!= props.janelaOrigem ? 'blinkIcon': ''}  onClick={()=>{
                  if(data.idtecnico == 0 && data.idregiao == 0){
                      return;
                  }
                    if(props.movendoChamado && data.idbandeja != props.janelaOrigem ){
                       
                        updateChamadoBandeja()
                       
                    }
                }}/></Badge></Tooltip></div>
            </div> : <div className='janela-title-options' style={{paddingTop : '2px', paddingBlock : '2px'}}>
                    <button className='btn-danger' onClick={()=>{
                        Swal.fire({
                            title : 'Printerconnect', 
                            text : 'Deseja realmente cancelar as alterações?', 
                            icon :'question', 
                            showConfirmButton :true, 
                            confirmButtonText : 'Sim', 
                            showCancelButton : true, 
                            cancelButtonText : 'Não'
                        }).then(resposta=>{
                            if(resposta.isConfirmed){
                                setJanelaFila(null)
                                setUpdatedFila(false)
                            }
                        })
                    }}>CANCELAR</button>
                    <button disabled={!updatedFila} className='btn-success' onClick={()=>{
                        Swal.fire({
                            title : 'Printerconnect', 
                            text : 'Deseja realmente gravar as alterações?', 
                            icon :'question', 
                            showConfirmButton :true, 
                            confirmButtonText : 'Sim', 
                            showCancelButton : true, 
                            cancelButtonText : 'Não'
                        }).then(resposta=>{
                            if(resposta.isConfirmed){
                                setLoading(true)
                                
                               Conexao.post('chamadosTecnicosController/updateFilaAtendimentoJanela', {
                                   chamados : janelaFila.map(function(chamado, i){
                                       return {idchamadotecnico : chamado.idchamadotecnico, idempresa : chamado.idempresa, posicao : ( i + 1)}
                                   }), 
                                   idusuario : data.idusuario, 
                                   idbandeja : data.idbandeja, 
                                   statuscaminho : data.statuscaminho,
                                    statusatendendo : data.statusatendendo, 
                                    statusandamento : data.statusandamento,
                                    statusaguardandopecas : data.statusaguardandopecas,
                                    statusfinalizado : data.statusfinalizado,
                                    statusretorno : data.statusretorno,
                                    statusorcamento : data.statusorcamento,
                               }).then(response=>{
                                    
                                   if(response.data.status =='OK'){
                                        
                                        setJanelaFila(null)
                                        //setChamados(response.data.payload)
                                        getChamadosJanela('atulizouFila');
                                        if(data.idtecnico && data.idtecnico != null && data.idtecnico > 0){
                                            const dados = props.tecnicos.filter(tecnico => (tecnico.idtecnico == data.idtecnico && tecnico.idusuario == data.idusuario)).map(function(tecnico){
                                                return {chavepush:tecnico.chavepush, nomecompleto:tecnico.nomecompleto}
                                            })
                                          
                                            if(dados[0]){
                                                Conexao.post('chamadosTecnicosController/pushNotification', {
                                                    destino : dados[0].chavepush, 
                                                    titulo : 'Alteração de fila de atendimento - Printerconnect',  
                                                    mensagem : 'Olá '+dados[0].nomecompleto+', sua fila de atendimento acaba de ser redefinida!',
                                                    data :{
                                                        atualizarTela :  'meusChamados', 
                                                        mensagem : 'Sua fila de atendimento sofreu alterações!',
                                                    }
                                                }).then(response=>{
                                                    
                                                }).catch(error=>{
                                                    
                                                })
                                            }
                                        }
                                                                     
                                   

                                   }else{
                                    
                                   }
                                   
                                   setLoading(false)
                               }).catch(error=>{
                                alert(error )
                                setLoading(false)
                               })
                            }
                        })
                    }}>GRAVAR</button>
                </div>}            
        </div>
        
        {janelaFila == null ? <div className='janela-area-chamados'>
                {
                    chamados && chamados.length == 0 ? <div className='rotulo-empty-data'>* sem informações *</div>: chamados.map(function(chamado, i){
                        return <ItemJanela 
                                    chamado = {chamado} 
                                    setChamadoMover = {(chamado)=>{
                                        props.setChamadoMover(chamado);                                        
                                    }}/>
                    }) 
                }
        </div> : 
        <div className='janela-area-chamados-fila' style ={{paddingBottom : '50px'}}>
                {
                    <RLDD                    
                    onChange={handleRLDDChange}
                    items={janelaFila}
                    itemRenderer={(item, index) => {
                        return (
                            <ItemJanela 
                                    chamado = {item} 
                                    classe= {'move-fila-tecnico'}
                                   />
                            );
                        }}                        
                    />
                    
                }
        </div>}

        
    </div>
}