import React from 'react'
import {Typography} from '@material-ui/core'



export default function(props){
    return <div>
        <Typography  style = {{fontSize : props.fontData}} {...props}>{props.data  ? props.data : ''}</Typography>
        <Typography style = {{fontSize : props.fontHora}} {...props}>{props.hora   ? props.hora : ''}</Typography>
    </div>
}