import { getToken } from "./Autenticate";

var axios = require('axios');
var porta = 80;
var server = '192.168.86.112'
var protocolo ='http'


const axiosInstance = axios.create({
  timeout: 60000,
  
  /* other custom settings */
});

export default axiosInstance;
