import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Grid, TextField} from '@material-ui/core'
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'


let contador = -1;
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoItemColeta(props) {
  const [open, setOpen] = React.useState(false);
  

  



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  
  React.useEffect(()=>{
   
  },[open])



  return (
    <div>
    
    <Button color ='primary' variant='contained' onClick = {handleClickOpen}><ExtensionTwoToneIcon color ='primary' />Cadastrar novo Erro</Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" fullWidth maxWidth={'md'} open={open} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastro de Erro {props.modelo.descricao}
        </DialogTitle>
        <DialogContent dividers style={{height:"400px"}}>
          <Grid contained spacing={1}>
            <Grid item xs = {12}>
              <TextField
                label='Descrição do erro'
                variant='outlined'
                InputLabelProps={{shrink:true}}
                fullWidth />
            </Grid>
          </Grid>
          <br/>
          <Grid container spacing={1}>
            <Grid item xs = {2}>
              <TextField
                label='Código de Erro'
                variant='outlined'
                InputLabelProps={{shrink:true}}
                fullWidth />
            </Grid>
            <Grid item xs = {2}>
              <TextField
                label='Localização'
                variant='outlined'
                InputLabelProps={{shrink:true}}
                fullWidth />
            </Grid>
            <Grid item xs = {2}>
              <TextField
                label='Grupo'
                variant='outlined'
                InputLabelProps={{shrink:true}}
                fullWidth />
            </Grid>
            <Grid item xs = {2}>
              <TextField
                label='Severidade'
                variant='outlined'
                InputLabelProps={{shrink:true}}
                select
                SelectProps={{native:true}}
                fullWidth>
                  <option >1</option>
                  <option >2</option>
                  <option >3</option>
                  <option >4</option>
              </TextField>
            </Grid>
            <Grid item xs = {4}>
              <TextField
                label='Abrir Chamado'
                variant='outlined'
                InputLabelProps={{shrink:true}}
                select
                SelectProps={{native:true}}
                fullWidth>
                  <option value ='F'>NÃO</option>
                  <option value ='T'>SIM</option>
              </TextField>
            </Grid>
            
          </Grid>
          <br/>
          <Grid contained spacing={1}>
            <Grid item xs = {12}>
              <TextField
                label='Como Resolver ?'
                variant='outlined'
                InputLabelProps={{shrink:true}}
                multiline
                rows={8}
                rowsMax={8}
                fullWidth />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus color='primary' variant='contained' onClick={()=>{
             
              Swal.fire({
                  title : 'Printercollect', 
                  text : 'Deseja gravar as operações ?', 
                  icon : 'question', 
                  showCancelButton : true, 
                  cancelButtonText : 'Cancelar', 
                  showConfirmButton : true, 
                  confirmButtonText : 'Confirmar', 
              }).then(resposta =>{
                  if(resposta.isConfirmed){

                      Conexao.post('parametrosController/insertParametros', {
                          idmodelo : props.modelo.idmodelo, 
                         
                      }).then(response =>{
                            if(response.data.status == 'OK'){
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Parametro inserido com sucesso !', 
                                    icon : 'success', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                            }else{
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Não foi possível inserir', 
                                    icon : 'warning', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                            }
                            setOpen(false)
                      }).catch(error =>{
                        Swal.fire({
                            title : 'Printercollect', 
                            text : 'Não Foi possível inserir', 
                            icon : 'success', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1500
                          })
                          setOpen(false)
                      });
                     
                     
                  }
              })
            
          }} color="primary" variant='contained'>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
