import React , {useState, useEffect, useRef, Fragment} from 'react'
import Image from 'react-bootstrap/Image'
import {Typography, Divider, Button} from '@material-ui/core'
import Swal from 'sweetalert2'
import {Tooltip} from '@material-ui/core'
import { LogoNodejs, SpeedometerOutline, FlashOutline, FlashOffOutline, SettingsOutline, KeyOutline , BatteryChargingOutline as CarregandoBateria, BatteryFullOutline as BateriaFull, BatteryHalfOutline as BateriaMeio,BatteryDeadOutline as BateriaZero
} from 'react-ionicons'

export default function(props){

    const [ativo, setAtivo] = React.useState(false)

    return <div className={props.selecionado.idmotorista && props.selecionado.idmotorista == props.motorista.idmotorista ? 'card-selecao card-ponto' : 'card-ponto'} style ={{
        marginTop : '10px',
        padding : '10px',
        borderRadius : '5px', 
        backgroundColor : '#fff', 
        boxShadow : '0px 0px 20px #e5e5e5', 
        display : 'flex'
    }}>
        
            <div style ={{width:'15%'}}>
                <img 
                style ={{
                    width : '64px', 
                    height : '64px', 
                    borderRadius :'32px'
                }}
                src ={props.motorista.url ?  'https://icones.pro/wp-content/uploads/2021/03/avatar-de-personne-icone-homme.png' : 'https://icones.pro/wp-content/uploads/2021/03/avatar-de-personne-icone-homme.png' }/>
            </div>
            <div style ={{width:'65%'}}>
                <div>
                    <Typography variant='subtitle2'><strong>Motorista: {props.motorista.nome}</strong></Typography>
                </div>
 
            </div>
            <div style ={{width:'20%', display:'flex', alignContent:'center', alignItems:'center'}}>
                <Button variant='outlined' color='success'  onClick={()=>{
                        Swal.fire({
                            title :'Printerconnect - Frota',
                            text : 'Deseja selecionar o motorista atual?',
                            icon :'question', 
                            showConfirmButton : true, 
                            confirmButtonText : 'Sim', 
                            showCancelButton:true, 
                            cancelButtonText : 'Não'
                        }).then(responsta=>{
                            if(responsta.isConfirmed){
                                //props.setTrajeto(props.trajeto.pontos);
                                props.setMotorista(props.motorista)
                            }
                        })
                    }}>selecionar</Button>
            </div>
        
    </div>
}