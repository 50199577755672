import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SearcIcon from '@material-ui/icons/Search'
import {Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = React.useState(false)
    const [marcas, setMarcas] = React.useState([])

    const handleClickOpen = () =>{
        getMarcas();
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }

    const getMarcas = () =>{
       
        Conexao.get('veiculosController/getMarcas?idusuario='+props.usuario).then(response=>{
            if(response.data.status =='OK'){
                setMarcas(response.data.payload)
            }else{
                setMarcas([])
            }
            
        }).catch(error=>{
            
            setMarcas([])
         
        });
    }
  return (
    <div>
      <Tooltip title='Selecionar uma marca'>
          <SearcIcon color='primary' onClick={handleClickOpen} style={{cursor:'pointer'}}/>
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>        
        <DialogContent dividers style = {{height:"350px", overflow:"auto"}}>
            <Table className='table table-sm table-small table-striped table-bordered table-hover' fullWidth>
                <TableHead>
                    <TableCell align='center' style = {{width : '10%'}}>#</TableCell>
                    <TableCell style = {{width : '75%'}}>Descrição</TableCell>
                    <TableCell align='center' style = {{width : '15%'}}>Selecionar</TableCell>
                </TableHead>
                <TableBody>
                    {
                        marcas && marcas.map((marca, i)=><TableRow>
                        <TableCell align='center' >{(i + 1)}</TableCell>
                        <TableCell >{marca.descricao}</TableCell>
                        <TableCell align='center' ><Button variant='contained' color ='primary' onClick={()=>{
                            if(props.setValorMarca){
                                props.setValorMarca({
                                    idmarca : marca.idmarca, 
                                    descricao : marca.descricao
                                })
                            }
                            setOpen(false)
                        }}><CheckIcon/></Button></TableCell>
                    </TableRow>)
                    }
                </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalPadrao)