import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {TextField, Grid, InputAdornment, LinearProgress} from '@material-ui/core'
import Swal from 'sweetalert2'
import Api from '../../../providers/Conexao'
import SincIcon from '@material-ui/icons/Sync'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const [atualizar, setAtualizar] = React.useState(false);
  const [collector, setCollector] = React.useState('f');
  const [billing, setBilling] = React.useState('f');
  const [chamados, setChamados] = React.useState('f');
  const [suprimentos, setSuprimentos] = React.useState('f');
  const [enviarSenha, setEnviarSenha] = React.useState('t');


  const [email, setEmail]= React.useState('');
  const [senha, setSenha]= React.useState('');
  const [nomeCompleto, setNomeCompleto]= React.useState('');
  const [departamento, setDepartamento]= React.useState('');
  const [telefone, setTelefone]= React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
    setEmail('')
    gerarSenha()
    setNomeCompleto('')
    setDepartamento('')
    setTelefone('')
    setCollector('f')
    setBilling('f')
    setChamados('f')
    setSuprimentos('f')
  };
  const handleClose = () => {
    setOpen(false);
  };


  const gerarSenha = () => {
    var stringAleatoria = '';
    var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = 0; i < 3; i++) {
        stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    
    caracteres = '0123456789';
    for (var i = 0; i < 4; i++) {
        stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    setSenha(stringAleatoria)
  };



  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Cadastro novo usuário
      </Button>
      <Dialog maxWidth='lg' hideBackdrop onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastrar nova Permissão de Acesso.
        </DialogTitle>
        <DialogContent dividers style={{width:"600px"}}>
        { atualizar && <LinearProgress color='secondary' />}
          <Grid container spacing={1}>
              <Grid item xs = {12}>
                  <TextField
                    label='Email'
                    InputLabelProps ={{shrink :  true}}
                    variant='outlined'
                    placeholder={'[nome cliente]@datacount.com.br'}
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    fullWidth />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={1}>
              <Grid item xs = {12}>
                  <TextField
                    label='Senha'
                    InputLabelProps ={{shrink :  true}}
                    variant='outlined'
                    InputProps ={{
                      startAdornment : <InputAdornment position ='start'><SincIcon style = {{cursor:"pointer"}} onClick = {()=>{
                        Swal.fire({
                          title : 'Datacount', 
                          text:'Deseja gerar uma senha aleatória ?', 
                          icon : 'question', 
                          showConfirmButton :true, 
                          confirmButtonText : 'Sim', 
                          showCancelButton : true, 
                          cancelButtonText : 'Não'
                        }).then(resposta=>{
                          if(resposta.isConfirmed){
                            gerarSenha();
                          }
                        });
                      }}/></InputAdornment>
                    }}
                    value={senha}
                    onChange={(e)=>setSenha(e.target.value)}
                    fullWidth />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={1}>
              <Grid item xs = {12}>
                  <TextField
                    label='Nome Completo'
                    placeholder={props.cliente.razaosocial}
                    InputLabelProps ={{shrink :  true}}
                    variant='outlined'
                    value={nomeCompleto}
                    onChange={(e)=>setNomeCompleto(e.target.value)}
                    fullWidth />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={1}>
              <Grid item xs = {6}>
                  <TextField
                    label='Departamento'
                    InputLabelProps ={{shrink :  true}}
                    value={departamento}
                    onChange={(e)=>setDepartamento(e.target.value)}
                    variant='outlined'
                    fullWidth />
              </Grid>
              <Grid item xs = {6}>
                  <TextField
                    label='Telefone'
                    value={telefone}
                    onChange={(e)=>setTelefone(e.target.value)}
                    InputLabelProps ={{shrink :  true}}
                    variant='outlined'
                    fullWidth />
              </Grid>
              <Grid item xs = {6} style={{textAlign:"left"}}>
                <Typography>Enviar Senha via email</Typography>
                { enviarSenha == 't' ? <CheckCircleOutlineIcon style ={{cursor:'pointer'}} onClick= {()=>setEnviarSenha('f')}/> : <RadioButtonUncheckedIcon style ={{cursor:'pointer'}} onClick= {()=>setEnviarSenha('t')} /> }
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={1}>
              <Grid item xs = {3} style={{textAlign:"center"}}>
                <Typography>Collector</Typography>
                { collector == 't' ? <CheckCircleOutlineIcon style ={{cursor:'pointer'}} onClick= {()=>setCollector('f')}/> : <RadioButtonUncheckedIcon style ={{cursor:'pointer'}} onClick= {()=>setCollector('t')} /> }
              </Grid>
              <Grid item xs = {3} style={{textAlign:"center"}}>
                <Typography>Billing</Typography>
                 { billing == 't' ? <CheckCircleOutlineIcon style ={{cursor:'pointer'}} onClick= {()=>setBilling('f')}/> : <RadioButtonUncheckedIcon style ={{cursor:'pointer'}} onClick= {()=>setBilling('t')}/> }
              </Grid>
              <Grid item xs = {3} style={{textAlign:"center"}}>
                <Typography>Chamados</Typography>
                 { chamados == 't' ? <CheckCircleOutlineIcon style ={{cursor:'pointer'}} onClick= {()=>setChamados('f')}/> : <RadioButtonUncheckedIcon style ={{cursor:'pointer'}} onClick= {()=>setChamados('t')} /> }
              </Grid>
              <Grid item xs = {3} style={{textAlign:"center"}}>
                <Typography>Suprimentos</Typography>
                 { suprimentos == 't' ? <CheckCircleOutlineIcon style ={{cursor:'pointer'}} onClick= {()=>setSuprimentos('f')}/> : <RadioButtonUncheckedIcon style ={{cursor:'pointer'}} onClick= {()=>setSuprimentos('t')} /> }
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus onClick={()=>{
            Swal.fire({
              title : 'Datacount', 
              text : 'Deseja inserir um novo acesso de clientes agora ?', 
              icon : 'question', 
              showConfirmButton : true, 
              confirmButtonText : 'Sim', 
              showCancelButton : true, 
              cancelButtonText : 'Não'
            }).then(resposta=>{
              if(resposta.isConfirmed){
                if(email == '' || senha == ''){
                  Swal.fire({
                    title : 'Datacount', 
                    text : 'Informe os dados antes de continuar', 
                    icon : 'warning', 
                    showConfirmButton : false, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : false, 
                    cancelButtonText : 'Não', 
                    timer : 1000
                  })
                  return;
                }
                setAtualizar(true)
                Api.post('clientesController/insertAcessoCliente', {
                  idusuario : props.idusuario, 
                  idempresa : props.idempresa, 
                  idcliente : props.idcliente, 
                  nomeCompleto : nomeCompleto, 
                  email : email, 
                  senha : senha, 
                  departamento : departamento, 
                  telefoneContato : telefone, 
                  collector : collector, 
                  billing : billing, 
                  chamados : chamados, 
                  suprimentos : suprimentos, 
                  enviarSenha : enviarSenha
                }).then(response =>{
                  if(response.data.status == 'OK'){
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Registro inserido com sucesso !', 
                      icon : 'success', 
                      showConfirmButton : false, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : false, 
                      cancelButtonText : 'Não', 
                      timer : 1000
                    })
                    props.getData();
                  }else{
                    Swal.fire({
                      title : 'Datacount', 
                      text : 'Não foi possível incluir', 
                      icon : 'warning', 
                      showConfirmButton : false, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : false, 
                      cancelButtonText : 'Não', 
                      timer : 1000
                    })
                  }
                  setAtualizar(false)
                  setOpen(false)
                }).catch(error =>{
                  Swal.fire({
                    title : 'Datacount', 
                    text : 'Não foi possível incluir', 
                    icon : 'error', 
                    showConfirmButton : false, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : false, 
                    cancelButtonText : 'Não', 
                    timer : 1000
                  })
                  setAtualizar(false)
                  setOpen(false)
                });
              }
            })
          }} color="primary"  variant='contained'>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
