import React from 'react'
import {TextField, InputAdornment} from '@material-ui/core'
import DisplayToner from '../../../components/DisplayToner'


export default function(props){

    return <TextField
        {...props}
        label={props.rotulo}
        select
        SelectProps={{ native: true }}
        size='large'
        fullWidth
        type='number'
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        InputProps={{
            startAdornment: <InputAdornment position='start'>     

                <DisplayToner                     
                    {...props} />
                 
            </InputAdornment>
      
        }}
        
        helperText={props.target  ? 'target : ' + props.target : ''}
    >
        <option>0</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>

    </TextField>
}
