import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Fab from '@material-ui/core/Fab';
import {Grid, TextField} from '@material-ui/core'
import Swal from 'sweetalert2'
import Conexao from '../../providers/Conexao'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoParametro(props) {
  const [open, setOpen] = React.useState(false);

  const refDescricao = React.createRef();
  const refMarca= React.createRef();
  const refNome = React.createRef();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if(!props.botaoAtivo){
      props.closeModal();
    }
  };

  return (
    <div>
     
      {props.botaoAtivo  &&<Fab color="primary" aria-label="add" onClick={handleClickOpen}>
        <ExtensionTwoToneIcon />
        </Fab>}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open || props.nomeModelo} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastro de Nova Homologação
        </DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label='Chave de Pesquisa'
                        fullWidth
                        variant='outlined'
                        inputRef={refDescricao}
                        defaultValue={props.nomeModelo}
                        InputLabelProps ={{shrink : true}}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Marca'
                        fullWidth
                        variant='outlined'
                        inputRef={refMarca}
                        InputLabelProps ={{shrink : true}}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Nome Amigável'
                        fullWidth
                        variant='outlined'
                        inputRef={refNome}
                        InputLabelProps ={{shrink : true}}/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant='contained' color="secondary">
            Cancelar
          </Button>
          <Button autoFocus variant='contained' color='primary' onClick={()=>{
              Swal.fire({
                title : 'Printercollect', 
                text : 'Deseja gravar a informação atual ?', 
                showConfirmButton : true, 
                confirmButtonText : 'Confirmar', 
                showCancelButton : true, 
                cancelButtonText : 'Cancelar', 
                icon : 'question'
              }).then(resposta=>{
                  if(resposta.isConfirmed){
                    Conexao.post('parametrosController/insertParametroBase', {
                        descricao : refDescricao.current ? refDescricao.current.value : '', 
                        marca : refMarca.current ? refMarca.current.value : '', 
                        nome : refNome.current ? refNome.current.value : ''
                    }).then( response =>{
                      
                        if(response.data.status.trim() =='OK'){
                            Swal.fire({
                                title : 'Printercollect', 
                                text :'Registro inserido com sucesso !',
                                showConfirmButton : false, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : false, 
                                icon : 'success', 
                                timer : 1500 
                            })
                            props.getData()
                        }else{
                            Swal.fire({
                                title : 'Printercollect', 
                                text :'Não foi possível inserir',
                                showConfirmButton : false, 
                                confirmButtonText : 'Confirmar', 
                                showCancelButton : false, 
                                icon : 'warning', 
                                timer : 1500 
                            })
                        }
                        handleClose()
                    }).catch(error =>{
                     
                      handleClose()
                    });
                  }
              })
          }} color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
