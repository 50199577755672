import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../providers/Conexao'
import DisplayStatus from '../../../components/DisplayStatus'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import CategoryIcon from '@material-ui/icons/Category';
import PrintIcon from '@material-ui/icons/Print';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import LinkIcon from '@material-ui/icons/Link';
import {Badge} from '@material-ui/core'


const GestaoTermos = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading]= useState(false)

    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [paginas, setPaginas]= React.useState(1);

    const [tiposChamados, setTiposChamados] = useState([])
    const [situacoesChamados, setSituacoesChamados] = useState([])
    const [contratos, setContratos] = useState([])


    React.useEffect(()=>{
        getContratosGrid();
        getParametrosAberturaChamado();
    }, [])
    

  


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getParametrosAberturaChamado = () =>{
        Conexao.get('chamadosTecnicosController/getParametrosAberturaChamado').then(response=>{
            if(response.data.status == 'OK'){
                setSituacoesChamados(response.data.payload.situacoesChamados);
                setTiposChamados(response.data.payload.tiposChamados)
            }
        }).catch(error=>{

        });
    }

    const getContratosGrid = () =>{
        setLoading(true)
        Conexao.post('contratosController/getContratosGrid', {
            idUsuario :props.match.params.idempresa, 
            idEmpresa : props.match.params.chaveEmpresa
        }).then(response=>{
            setLoading(false)
            if(response.data.status == 'OK'){
                setContratos(response.data.payload);
            }

            
        }).catch(error=>{
            setLoading(false)
        });
    }



    


   

   

    return <Principal atualizar = {loading}>
    <Grid container spacing = {1}>
            <Grid item xs = {7}><Typography>Gerenciamento Termos E/S</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
            <Grid item xs = {2}>
                <TextField
                    label ='Tipo de Data'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    size='small'   
                    select
                    SelectProps={{native:true}}
                >
                    <option>LANÇAMENTO</option>
                    <option>INICIO</option>
                    <option>ENCERRAMENTO</option>
                </TextField>
            </Grid>

            <Grid item xs = {2}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    size='small'   
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Número Contrato'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    size='small'    
                   />
            </Grid>
            <Grid item xs = {4}>
                <TextField
                    label ='Cliente'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    size='small'    
                   />
            </Grid>
           
            
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getContratosGrid()} ><SearchIcon/> Buscar</Button>
                <Button color='secondary' variant='contained' ><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
        <Grid container spacing = {2}>
            <Grid item xs = {4}>
                <TextField
                    label ='Modelo'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    size='small'              
                   />
            </Grid>
            <Grid item xs = {4}>
                <TextField
                    label ='Cliente'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    size='small'              
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Setor'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    size='small'              
                   />
            </Grid>
           
        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"} >
                    Home
                </Link>
            <Typography color="textPrimary">Termos E/S</Typography>
            </Breadcrumbs>
        </div> 
        <div className='div-form-container'>
    <Grid container style = {{marginTop:"10px"}} spacing={1}>
        <Table  style = {{fontSize:"10px"}} className='table table-hover' >
            <TableHead>
                <TableCell align='left' style = {{width:"5%"}}>Nº</TableCell>
                <TableCell align='center' style = {{width:"10%"}}>Contrato</TableCell>
                <TableCell align='center' style = {{width:"10%"}}>Data</TableCell>
                <TableCell align='center' style = {{width:"10%"}}>Operação</TableCell>
                <TableCell align='left' style = {{width:"30%"}}>Cliente</TableCell>
                <TableCell align='left' style = {{width:"10%"}}>Equipamento</TableCell>
                <TableCell align='center' style = {{width:"5%"}}>Processamento</TableCell>   
                <TableCell align='center' style = {{width:"5%"}}>Status</TableCell>               
               
                
            </TableHead>
            <TableBody>
                
            </TableBody>

            <TableBody>
                
               
               
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>
    <div className='fabIncluir'>
        
    </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(GestaoTermos)