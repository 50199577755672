import React, {Fragment, useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import Navbar from './Components/Navbar'
import LinearProgress from '@material-ui/core/LinearProgress';
import BarraPrincipal from './BarraPrincipal'

const Principal = function(props){
    
    return <Fragment>
    <div id='navbar-fixed-top'>
        <BarraPrincipal getData={props.getData} />

        {props.atualizar && <LinearProgress color='secondary' className='div-progressbar' />}

        <div id='div-principal-header' style={{ marginTop: "0px", paddingLeft: "0px" }}>
            <div style={{ width: "100%", textAlign: "center" }}>
                <Navbar />
            </div>    
        </div>
        
    </div>
    
    
    <div id={props.full ?  props.full : 'form-container-area'}>
        {
            props.children 
        }
    </div>

    </Fragment>
}

export default withRouter(Principal)