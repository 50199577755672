import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import { TableCell, Table, TableHead, TableBody, TableRow,  TextField , Grid} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Conexao from '../../providers/Conexao'
import {withRouter} from 'react-router-dom'
import Swal from 'sweetalert2'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PersonIcon from '@material-ui/icons/Person';
import LinearProgress from '@material-ui/core/LinearProgress';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalBuscaCliente = (props) =>{
  const [open, setOpen] = React.useState(false);
  const [filtro, setFiltro] = React.useState('');
  const [clientes, setClientes] = React.useState([]);
  const [loading, setLoading]= React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    
    setOpen(false);
  };

  const getClientes = () =>{
    if(filtro == '' || filtro.length < 2){
      Swal.fire({
        title :'Gestão de Clientes', 
        text:'Você precisa informar o nome do cliente !', 
        icon :'warning', 
        showCancelButton : false, 
        cancelButtonText : 'Cancelar', 
        showConfirmButton : false, 
        confirmButtonText : 'Confirmar', 
        timer : 1000
      })
      return;
    }
    setLoading(true)
    Conexao.post('clientesController/'+(props.collector == true ? 'buscaLicencasCollector' : 'buscarCliente'), {        
        idempresa : props.match.params.chaveEmpresa,
        tipoFiltro : 'razaoSocial',
        filtro : filtro, 
        chaveCollector : props.chaveCollector ?  props.chaveCollector : 'f'
    }).then( response =>{
        if(response.data.status =='OK'){
            setClientes(response.data.payload)
        }else{
          setClientes([])
          Swal.fire({
            title :'Gestão de Clientes', 
            text:'Cliente não localizado ou cliente não possui uma chave de coleta !', 
            icon :'warning', 
            showCancelButton : false, 
            cancelButtonText : 'Cancelar', 
            showConfirmButton : false, 
            confirmButtonText : 'Confirmar', 
            timer : 1000
          })
        }
        setLoading(false)
    }).catch( error =>{
        setClientes([])
        setLoading(false)
    });
  }

  return (
    <div>
      
      <SearchIcon style ={{cursor:'pointer'}} onClick={handleClickOpen} />
    
   
      
      <Dialog onClose={handleClose} 
        fullWidth={true}
        disableBackdropClick disableEscapeKeyDown hideBackdrop
        maxWidth={'md'} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} >
          Localizar Cliente
        </DialogTitle>
        <DialogContent dividers>
        
        <Grid container spacing={2}>
            <Grid item xs = {10}>
            <TextField
                fullWidth
                value = {filtro}
                onChange={(e)=>setFiltro(e.target.value)}
                variant='outlined'
                label='Filtro'
                InputLabelProps ={{shrink : true}}  
            />
            </Grid>
            <Grid item xs = {2}>
            <Button color='primary' variant='contained' fullWidth size='large' onClick={()=>getClientes()}>Pesquisar</Button>
            </Grid>
        </Grid>
        
        { loading && <LinearProgress color="secondary" />}
        <div style ={{height:'350px', overflow:"auto"}}>
            <Table>
                <TableHead>
                    <TableCell style ={{width:"30%"}} align='center'>CNPJ</TableCell>
                    <TableCell style ={{width:"60%"}} align='left'>Razão Social</TableCell>
                    <TableCell style ={{width:"10%"}} align='center'>Selecionar</TableCell>
                </TableHead>

                <TableBody>
                    {
                        clientes && clientes.map((cliente, i)=>{
                            return <TableRow key = {'LC'+i}>
                                    <TableCell style ={{width:"30%"}} align='center'>{cliente.cpf_cnpj}</TableCell>
                                    <TableCell style ={{width:"60%"}} align='left'>{cliente.razaosocial}</TableCell>
                                    <TableCell style ={{width:"10%"}} align='center'>
                                        <Button variant='outlined' color = 'primary' onClick={()=>{


                                          Swal.fire({
                                            title :'Gestão de Clientes', 
                                            text:'Deseja selecionar este cliente ?', 
                                            icon :'question', 
                                            showCancelButton : true, 
                                            cancelButtonText : 'Cancelar', 
                                            showConfirmButton : true, 
                                            confirmButtonText : 'Confirmar'
                                          }).then(resposta=>{
                                            if(resposta.isConfirmed){
                                                if(props.updateCliente){
                                                  if(props.fullData){
                                                    props.updateCliente(cliente)
                                                  }else{
                                                    props.updateCliente({idcliente:cliente.idcliente, razaosocial:cliente.razaosocial})
                                                  }
                                                }else{
                                                    Swal.fire({
                                                        title :'Gestão de Clientes', 
                                                        text:'Não existe função vinculada a este evento !', 
                                                        icon :'error', 
                                                        showCancelButton : true, 
                                                        cancelButtonText : 'OK', 
                                                        showConfirmButton : false, 
                                                    })
                                                }
                                              
                                                setOpen(false)
                                            }
                                          })
                                         
                                        }}><PersonIcon color='primary' /></Button>
                                    </TableCell>
                                </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </div>
          
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">            
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalBuscaCliente)