import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {Grid, TextField, InputAdornment, Tooltip, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TextFieldMoeda from '../../../../components/TextFieldMoeda'
import ModalBuscaCliente from '../../../Clientes/ModalBuscaCliente'
import Conexao from '../../../../providers/Conexao'
import Swal from 'sweetalert2';
import IconeGruposFranquia from '@material-ui/icons/AccountTree'
import IconeGerenciar from '@material-ui/icons/Build'
import IconePrinters from '@material-ui/icons/Print'
import IconeAditivos from '@material-ui/icons/FileCopy'
import IconeTermos from '@material-ui/icons/CompareArrows'
import IconClose from '@material-ui/icons/Close'
import IconeEncerrarContrato from '@material-ui/icons/Cancel'
import IconeAlteracao from '@material-ui/icons/Build'



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalCadastro(props) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [cliente, setCliente] = React.useState({idcliente:'', razaosocial:''});
  const [tiposContrato, setTiposContrato] = React.useState([]);
  const [tiposChamados, setTiposChamados] = React.useState([]);
  const [funcionarios, setFuncionarios] = React.useState([]);

  const [idTipoContrato, setTipoContrato]= React.useState('F');
  const [idVendedor, setIdVendedor]= React.useState('F');
  const [dataCelebracao, setDataCelebracao]= React.useState('');
  const [dataInicio, setDataInicio]= React.useState('');
  const [dataFim, setDataFim]= React.useState('');
  const [diaLeitura, setDiaLeitura]= React.useState('10');
  const [indiceReajuste, setIndiceReajuste]= React.useState('');
  const [custoVisita, setCustoVisita]= React.useState('');
  const [custoTecnico, setCustoTecnico]= React.useState('');
  const [custoAdministrativo, setCustoAdministrativo]= React.useState('');
  const [idTipoChamadoTecnico, setIdTipoChamadoTecnico]= React.useState('F');
  const [gerarExcedente, setGerarExcedente]= React.useState('F');
  const [gerarCredito, setGerarCredito]= React.useState('F');
  const [observacoes, setObservacoes] = React.useState(''); 

  const [gruposFranquiaCadastro, setGruposFranquiaCadastro]= React.useState([]); 
  const {contrato} = props;

 
 React.useEffect(()=>{
    if(open){

        setDiaLeitura(contrato.dialeitura);
        setDataCelebracao(contrato.datacelebracao)
        setIndiceReajuste(contrato.indicereajuste);
        setCustoAdministrativo(contrato.custoadministrativo);
        setCustoTecnico(contrato.custotecnico)
        setCustoVisita(contrato.custoporvisita);
        setGerarCredito(contrato.gerarcredito)
        setGerarExcedente(contrato.gerarexcedente);
        setDataFim('');
        setDataInicio('');
        setObservacoes(contrato.observacoes)
        if(props.title =='Renovação'){
          setDataInicio(contrato.datafimvigencia)
          setDataFim(contrato.datafimvigencia);
        }else{
          setDataInicio(contrato.datainiciovigencia)
          setDataFim(contrato.datafimvigencia);
        }
        
    }
 },[open])




  const handleClickOpen = () => {
    Swal.fire({
      title : 'Printerconnect', 
      text : props.title =='Alteração' ? 'Deseja lançar alterações no contrato? Tais alterações são consideradas como aditivos.': 'Deseja abrir o modo de renovação de contrato?', 
      icon :'question',
      showConfirmButton : true, 
      confirmButtonText : 'Sim', 
      showCancelButton : true, 
      cancelButtonText : 'Não'
  }).then(resp =>{
      if(resp.isConfirmed){
        setOpen(true);
      }
  })
    
  };
  const handleClose = () => {
    setOpen(false);
  };


 

 const processarData = () =>{
  Conexao.post('contratosController/insertNovoAditivoContrato',{
    idUsuario : contrato.idusuario, 
    idEmpresa : contrato.idempresa, 
    idContrato : contrato.idcontrato, 
    nossoNumero : contrato.nossonumero, 
    idCliente : contrato.idcliente, 
    dataVigenciaInicio: dataInicio, 
    dataVigenciaFim : dataFim, 
    dataCelebracao : dataCelebracao, 
    diaLeitura : diaLeitura, 
    indiceReajuste : indiceReajuste, 
    custoVisita : custoVisita, 
    custoTecnico : custoTecnico, 
    custoAdministrativo : custoAdministrativo, 
    observacoes : observacoes, 
    renovacao : props.title =='Alteração' ?'f': 't'
  }).then(response=>{
    if(response.data.status =='OK'){
      Swal.fire({
        title : 'Printerconnect', 
        text : 'Aditivo incluído com sucesso!', 
        icon :'success',
        showConfirmButton : false, 
        confirmButtonText : 'Ok', 
        showCancelButton : false, 
        cancelButtonText : 'Não', 
        timer : 1500
      })
      props.updateData();
      setOpen(false)
    }else{
      Swal.fire({
        title : 'Printerconnect', 
        text : 'Não foi possível incluir aditivo!', 
        icon :'warning',
        showConfirmButton : false, 
        confirmButtonText : 'Ok', 
        showCancelButton : false, 
        cancelButtonText : 'Não', 
        timer : 1500
      })
    }
  }).catch(error=>{
    Swal.fire({
      title : 'Printerconnect', 
      text : 'Não foi possível incluir aditivo!', 
      icon :'error',
      showConfirmButton : false, 
      confirmButtonText : 'Ok', 
      showCancelButton : false, 
      cancelButtonText : 'Não', 
      timer : 1500
    })
  });
 }

  return (
    <div>
      
        <Button variant='outlined' color='primary' fullWidth className='default' onClick={handleClickOpen}>
            <div>
                <div>{props.title !='Alteração' ? <IconeAditivos/>:<IconeAlteracao/>}</div>
                <div style ={{fontSize :'11px'}}>{props.title}</div>
            </div>
        </Button>
      <Dialog fullWidth maxWidth='lg' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableBackdropClick disableEscapeKeyDown hideBackdrop
        scroll={'body'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.title =='Alteração' ? 'Alteração Contratual - '+(contrato.aditivo + 1) + 'º alteração' : ' Renovação de Contrato'}
        </DialogTitle>
        <DialogContent dividers >
            
            <Grid container spacing = {1} style ={{marginTop:'20px'}}>
                <Grid item xs = {2}>
                    <TextField
                        label='Nosso Número'
                        readonly
                        fullWidth
                        value={contrato && contrato.nossonumero}
                        size='small'
                        InputProps={{'read-only':'true'}}
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>

                    </TextField>
                </Grid>
                <Grid item xs = {5}>
                    <TextField
                        label='Tipo de Contrato'
                        readonly
                        fullWidth
                        value={contrato && contrato.tipocontrato}
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>

                    </TextField>
                </Grid>
                <Grid item xs = {1}>
                    <TextField
                        label='Código'
                        readonly
                        value={contrato && contrato.idcliente}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                       
                        />
                </Grid>
                <Grid item xs = {4}>
                    <TextField
                        label='Razão Social'
                        readonly
                        value={contrato && contrato.razaosocial}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing = {1}>
               
                <Grid item xs = {3}>
                <TextField
                        label='Vendedor'
                        value={contrato && contrato.nomevendedor}
                        fullWidth
                       
                        size='small'
                        
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                           
                    </TextField>
                </Grid>
               
                <Grid item xs = {2}>
                    <TextField
                        label='Data Celebração'
                        readonly
                        value={dataCelebracao}
                        size='small'
                        fullWidth
                    
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextField
                        label='Data Inicio'
                        disabled={props.title =='Renovação'}
                        value={dataInicio}
                        onChange={(e)=>setDataInicio(e.target.value)}
                        size='small'
                        fullWidth
                        type='date'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextField
                        label='Data Fim'
                        value={dataFim}
                        size='small'
                        type='date'
                        fullWidth
                        onChange={(e)=>setDataFim(e.target.value)}
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {1}>
                    <TextField
                        label='Leitura Dia'
                        value={diaLeitura}
                        onChange={(e)=>setDiaLeitura(e.target.value)}
                        size='small'
                        fullWidth
                       
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Indíce de Reajuste'
                        onChange={(e)=>setIndiceReajuste(e.target.value)}
                        size='small'
                        value={indiceReajuste}
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing = {1}>               
               
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Visita Técnica'
                        value={custoVisita}
                        onChange={(e)=>setCustoVisita(e.target.value)}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Técnico'
                        value={custoTecnico}
                        onChange={(e)=>setCustoTecnico(e.target.value)}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Administrativo'
                        value={custoAdministrativo}
                        onChange={(e)=>setCustoAdministrativo(e.target.value)}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>

                <Grid item xs = {6}>
                    <TextField
                        label='Ordem de Serviço (tipo) Padrão'
                       
                        fullWidth
                        
                        size='small'
                      
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                           
                    </TextField>
                </Grid>
               
            </Grid>
            <br/>
            <Grid container spacing ={ 1}>
                <Grid item xs = {6}>
                    <TextField
                        label='Gerar excedente'
                        value={contrato && contrato.gerarexcedente=='t' ?'SIM' :'NÃO'}
                        fullWidth
                        
                        size='small'
                       
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                            
                    </TextField>
                </Grid>
                <Grid item xs = {6}>
                    <TextField
                        label='Habilitar controle de crédito'
                        value={contrato && contrato.gerarcredito=='t' ?'SIM' :'NÃO'}
                        fullWidth
                        
                        size='small'
                       
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                          
                    </TextField>
                </Grid>
            </Grid>
            
            <br/>
            <Grid container spacing = {1}>
                <Grid item xs = {12}>
                    <TextField
                        label='Observações gerais'
                       onChange={(e)=>setObservacoes(e.target.value)}
                        value={observacoes}
                        fullWidth
                        multiline                        
                        inputProps ={{
                            maxlength : 500
                        }}
                        size='small'
                        rows={4}
                        rowsMax={4}
                        InputLabelProps={{shrink : true}}
                        variant='outlined'/>
                </Grid>
              </Grid>
        </DialogContent>
        <DialogActions>
        <Button autoFocus size='large' onClick={handleClose} color="secondary" variant='contained'>
            cancelar
          </Button>
          <Button autoFocus size='large' onClick={handleClose} color="primary" variant='contained' onClick={()=>{
             
             Swal.fire({
                title : 'Printerconnect', 
                text : 'Deseja gravas as alterações realizadas no contrato atual? Será criado um novo aditivo!', 
                icon :'question',
                showConfirmButton : true, 
                confirmButtonText : 'Sim', 
                showCancelButton : true, 
                cancelButtonText : 'Não'
              }).then(resp =>{
                  if(resp.isConfirmed){

                      if(dataInicio == '' || dataFim == ''){
                        Swal.fire({
                          title : 'Printerconnect', 
                          text : 'Você não informou as datas de inicio e fim da nova vigência! Deseja manter as datas originais (anteriores)?', 
                          icon :'error',
                          showConfirmButton : true, 
                          confirmButtonText : 'Sim', 
                          showCancelButton : true, 
                          cancelButtonText : 'Não'
                        }).then(resposta=>{
                            if(resposta.isConfirmed){
                              setDataInicio(contrato.datainiciovigencia)
                              setDataFim(contrato.datafimvigencia);
                              processarData();                      
                            }else{
                              return;
                            }
                           
                        })                        
                      }else{
                        processarData();
                      }
                      
                     
                  }
              })
              
               
              
          }}>
            gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
