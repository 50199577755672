import React, {useState, useEffect} from 'react'

import { TextField } from '@material-ui/core'




export default function PRTAlertGroup(props){

    return <TextField 
                label='PRTAlertGroup RFC 1759'
                select 
                SelectProps={{native:true}}
                variant='outlined'
                {...props}
                fullWidth
                >
        <option value='f'></option>
        <option value='1'>other</option>
        <option value='3'>hostResourcesMIBStorageTable</option>
        <option value='4'>hostResourcesMIBDeviceTable</option>
        <option value='5'>generalPrinter</option>
        <option value='6'>cover</option>
        <option value='7'>localization</option>
        <option value='8'>input</option>
        <option value='9'>output</option>
        <option value='10'>marker</option>
        <option value='11'>markerSupplies</option>
        <option value='12'>markerColorant</option>
        <option value='13'>mediaPath</option>
        <option value='14'>channel</option>
        <option value='15'>interpreter</option>
        <option value='16'>consoleDisplayBuffer</option>
        <option value='17'>consoleLights</option>
    </TextField>
}