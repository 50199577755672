import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import NavigationIcon from '@material-ui/icons/Navigation'
import Fab from '@material-ui/core/Fab';
import MapaFullPage from '../../components/Mapas/MapView'



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    paddingLeft : 10, 
    paddingRight : 10, 
    paddingTop : 0, 
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: '#fff',
    fontSize : '48px'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 1,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
        <Fab size="large" color="primary" aria-label="add" onClick={()=>{{handleClickOpen()}}} >
            <NavigationIcon/>
        </Fab>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullScreen hideBackdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.title}
        </DialogTitle>
        <DialogContent dividers>
          <MapaFullPage    
            idusuario={props.idusuario}
            idempresa={props.idempresa} 
            latitude = {props.latitude}
            longitude = {props.longitude}       
            mostrarTecnicos={true}
            mostrarVeiculos={false}
            mostrarChamados={true}
            mostrarClientes={false}
            mostrarRegioes={true}
            movendoChamado={props.movendoChamado}
            defaultZoon={9}/>
        </DialogContent>
        
      </Dialog>
    </div>
  );
}
