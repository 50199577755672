import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../providers/Conexao'

import Swal from 'sweetalert2'

import {withRouter} from 'react-router-dom'
import Template from '../Template/Template';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RemoveIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Build from '@material-ui/icons/Build'



const WebClientesDCA = (props)=>{
    const { idcliente, idusuario, idempresa, razaosocial} = props.location.state.cliente;
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dcas, setDcas] = useState([])
    const [dcasFiltro, setDcasFiltro] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [ registros, setRegistros] = useState(0)
    const refTipoDataPesquisa = React.createRef();
    const refDataPesquisa = React.createRef();
    const refChaveInstalacao = React.createRef();
    const refRazaoSocial = React.createRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  

    useEffect(()=>{
        getDcas();
    }, [page])


    const getDcas = () =>{
        setLoading(true)
        Conexao.post('webClientController/getDcas', {
            idcliente : idcliente, 
            idusuario : idusuario, 
            idempresa : idempresa,

        }).then( response =>{
            if(response.data.status == 'OK'){
                setDcas(response.data.payload)

            }else{
                setDcas([])
              
            }
            setLoading(false)
        }).catch( error =>{
            setDcas([])
          
            setLoading(false)
        });
    }

   

    const renderUltimoAcesso = (acesso) =>{
        if(acesso == 'printerconnect-app-collector'){
                return <div><PersonIcon color='primary' /><br/><Typography variant='caption' color='primary' style ={{fontSize:"9px"}}>MANUAL</Typography></div>
        }else{
            return <div><SettingsApplicationsIcon  color='primary'/><br/><Typography variant='caption' color='primary' style ={{fontSize:"9px"}}>SERVICE</Typography></div>
        }
    }

    return <Template atualizar = {loading} cliente={props.location.state.cliente} getData={getDcas}>
        <div style ={{paddingLeft : '30px', paddingRight : '30px'}}>
            <div style={{backgroundColor:"#fff",paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px', borderLeft: 'solid 4px #37474F'}}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>            
                <Grid item xs = {7}><Typography variant='h5'>Situação de Chaves DCA</Typography></Grid>
                <Grid item xs = {5}>
                <TablePagination
                    component="div"
                    count={registros}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                        </Grid>
                    </Grid>
        </div>
        <br/>
       
      
       <div className='div-form-container'>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
            <Table  className='table table-bordered table-striped table-hover table-small table-sm'>
                <TableHead>
                 
                    <TableCell align='left' style = {{width:"10%"}}>
                        <Typography  variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='Chave'? "bold" : ''}} >Chave Itentificação</Typography>
                    </TableCell>
                    <TableCell align='left' style = {{width:"30%"}}>
                    <Typography  variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='Cliente'? "bold" : ''}} >Hostname</Typography>
                    </TableCell>       
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography  variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='Criação'? "bold" : ''}} >Criação</Typography>
                    </TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography  variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='Instalação'? "bold" : ''}} >Instalação</Typography>
                    </TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography  variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='Acesso'? "bold" : ''}} >Acesso</Typography>
                    </TableCell>
                   

                    <TableCell align='center' style = {{width:"10%"}}>
                    <Typography  variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='HostName'? "bold" : ''}} >Status</Typography>
                    </TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>
                    <Typography  variant='caption' style ={{cursor:'pointer', fontSize:'10px!important',fontWeight : colunaFiltro =='IP'? "bold" : ''}} >Endereço IP</Typography>
                    </TableCell>

                    
                </TableHead>
                <TableBody>
                   
                   {
                       dcas && dcas.map(function(dca, i){
                           return <TableRow >
                           <TableCell align='left' style={{fontSize:"10px"}}>{dca.chave}</TableCell>
                           <TableCell align='left' style={{fontSize:"10px"}}>{dca.hostname}</TableCell>
              
                            <TableCell align='center'>
                               <LabeData fontData='10px'  data = {dca.datacadastro} hora ={dca.horacadastro.substr(0,8)}  fontHora = {9}/>
                               </TableCell>
                            <TableCell align='center'>
                                <LabeData fontData='10px'  data = {dca.dataativacao && dca.dataativacao} hora ={dca.horaativacao && dca.horaativacao.substr(0,8)}  fontHora = {9}/>
                            </TableCell>
                            <TableCell align='center'>
                                <LabeData fontData='10px'  data = {dca.dataatualizacao && dca.dataatualizacao} hora ={dca.horaatualizacao && dca.horaatualizacao.substr(0,8)}  fontHora = {9}/>
                            </TableCell>
                                             
                            <TableCell align='center' style={{fontSize:"10px"}}>
                               
                                <div>{renderUltimoAcesso(dca.ultimoacessovia)}</div>
                            </TableCell>
                            <TableCell align='center' style={{fontSize:"10px"}}>
                            {dca.enderecoip}
                            </TableCell> 
                           
                       </TableRow>
                       })
                   }
                   {
                       dcas.length == 0  && <TableRow>
                       <TableCell colSpan={6} align='left'>* Não existe(m) registro(s)</TableCell>
                      
                   </TableRow>
                   }
                </TableBody>
            </Table>
        </Grid>   
        </div>                  
    
        <div className='fabIncluir'>
        {/*<ModalNovoDca idusuario={props.match.params.idempresa} idempresa={props.match.params.chaveEmpresa} getData = {()=>getDcas()}/>*/}
        </div>
        </div>
    </Template>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(WebClientesDCA)