import React, {useState, useEffect, useRef, Fragment} from 'react'

import {TextField,   Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../providers/Conexao'

import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import DisplayToner from '../../../components/DisplayToner'
import DisplayLinearToner from '../../../components/DisplayLinearToner'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import ModalDetalhesLeitura from './ModalDetalhesColeta'
import BlockIcon from '@material-ui/icons/Block';
import Alert from '@material-ui/lab/Alert'
import LinkIcon from '@material-ui/icons/Link';

import GestureIcon from '@material-ui/icons/Gesture';
import DoorOpen from '@material-ui/icons/MeetingRoom';
import LinkOn from '@material-ui/icons/Link'
import LinkOff from '@material-ui/icons/LinkOff'




import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';


let temporizador = null;

const GestaoColetas = (props)=>{
    let history = useHistory();
    const classes = useStyles();
    const [idLeitura, setIdLeitura]= React.useState(-1);
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)  
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [departamentos, setDepartamento] = useState([])
    const [filtro, setFiltro] = useState([]) 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [coletas, setColetas] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [registros, setRegistros]= React.useState(0);
    const refTipoDataPesquisa = React.useRef();
    const refDataInicial = React.useRef();
    const refDataFinal = React.useRef();
    const refPlaca = React.useRef();
    const refNumeroSerie = React.useRef();
    const refModelo = React.useRef();
    const refMarca = React.useRef();    
    const refRazaoSocial = React.useRef();
    const refStatus = React.useRef();
    const refSetor = React.useRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getData()
    },[page,rowsPerPage ]);
    

    useEffect(()=>{
        getDepartamentos()
    },[]);
    
    
    const clearForm = () =>{
      
        if(refDataInicial.current){
            refDataInicial.current.value  = '';
        }
        if(refDataFinal.current){
            refDataFinal.current.value  = '';
        }
        if(refPlaca.current){
            refPlaca.current.value  ='';
        }
        if(refNumeroSerie.current){
            refNumeroSerie.current.value  ='';
        }
        if(refModelo.current){
            refModelo.current.value  ='';
        }
        if(refMarca.current){
            refMarca.current.value  ='';
        }
        if(refRazaoSocial.current){
            refRazaoSocial.current.value  ='';
        }
        if(refSetor.current){
            refSetor.current.value  ='';
        }

        getData();   
        setPage(0);
        
    }
   

    const getDepartamentos = () =>{

        Conexao.post('coletasController/getDepartamentos', {
            idempresa : props.match.params.chaveEmpresa, 
            idcliente :props.match.params.idcliente,
        }).then(response=>{
            if(response.data.status =='OK'){
                setDepartamento(response.data.payload);
            }else{
                setDepartamento();
            }
        }).catch(error =>{
            setDepartamento();
        })
    }

    const getData = () =>{
        setLoading(true)
        Conexao.post('coletasController/getContadores', {
            idempresa : props.match.params.chaveEmpresa, 
            tipodata : refTipoDataPesquisa.current ?  refTipoDataPesquisa.current.value : '', 
            datainicial : refDataInicial.current ?  refDataInicial.current.value : '', 
            datafinal : refDataFinal.current ?  refDataFinal.current.value : '', 
            placa : refPlaca.current ?  refPlaca.current.value : '', 
            numeroserie :refNumeroSerie.current ?  refNumeroSerie.current.value : '' , 
            modelo :refModelo.current ?  refModelo.current.value : '', 
            marca : refMarca.current ?  refMarca.current.value : '', 
            razaosocial: refRazaoSocial.current ?  refRazaoSocial.current.value : '',
            departamento: refSetor.current ?  refSetor.current.value : '',
            status: refStatus.current ?  refStatus.current.value : '',
            idcliente :props.match.params.idcliente,
            limit : rowsPerPage,
            offSet: page
        }).then(response=>{
            if(response.data.status == 'OK'){
                setColetas(response.data.payload.dados)
                setRegistros(response.data.payload.registros);
                     console.log(response.data.payload.registros)
            }else{
                setColetas([])
                setRegistros([])
            }
            setLoading(false)
        }).catch(error =>{
            setColetas([])
            setLoading(false)
            Swal.fire({
                title : 'Printerconnect', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
        });
    }

   
   const renderStatus = (status, ip, dias, ativo, painel) =>{
    if(ativo == 'f'){
        return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title ={'Este equipamento esta desativado, atualizações de coleta não são processadas'}><LockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESATIVADA</Typography></div>        
    }
    if(dias > 0){
        return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title ={'Sem comunicação há '+dias+' dia(s)'}><BlockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>SEM CONEXÃO</Typography></div>        
    }
    switch(status){
    case 1:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
            
        case 2:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PowerSettingsNewIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ON LINE</Typography></div>
            
        case 3:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><SettingsPowerIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ENERGY SAVING</Typography></div>
            
        case 4:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PrintIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>IMPRIMINDO</Typography></div>

        case 5:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title={painel}><ReportProblemIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"9px", marginTop:"0px"}}>OCORRÊNCIA</Typography></div>
        
        case 6:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title={painel}><NotificationsActiveIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ATENÇÃO</Typography></div>
        
        case 7:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title={painel}><GestureIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ATOLAMENTO</Typography></div>

        case 8:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title={painel}><DoorOpen className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"9px", marginTop:"0px"}}>Porta Aberta</Typography></div>

        default:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
    }
        
   }

   const renderNivelToner = (nivel, cor, rotulo) =>{
        if(!nivel){
            return <DisplayToner width={60} heigth = {60} color = {cor} percentual={0} lineSize={5}  descricao={rotulo}/>
        }
        if(nivel < 0 ){
            return <DisplayToner width={60} heigth = {60} color = {cor} percentual={100} lineSize={6} descricao={rotulo} />
        }else{
            return <DisplayToner width={60} heigth = {60} color = {cor} percentual={nivel} lineSize={6}  descricao={rotulo}/>
        }
        
    
   }


   const checarStatus = (ativo, status) => {   
       if(ativo =='t' && status == '5'){
           return 'table-row-inativo linha-coleta-alerta'

       }else if (status =='t'){
           return 'table-row-inativo'
        
       }


   }

  
    return <Principal atualizar = {loading} >
          <br />
       <Grid container spacing = {2}>
         
            <Grid item xs = {7}><Typography>Gerenciamento de Coletas de Contadores</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        
        <Grid container spacing = {2}>
            <Grid item xs = {2}>
                <TextField
                    label ='Tipo de Pesquisa'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    select
                    SelectProps={{native : true}}
                    inputRef={refTipoDataPesquisa}
                    
                    size ='small'
                    >
                    <option>COLETA</option>
                    <option>CADASTRO</option>
                    
                    </TextField>
            </Grid>

            
            <Grid item xs = {2}>
                <TextField
                    label ='Data Início'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    type= 'date'    
                   
                    inputRef={refDataInicial}             
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Data Fim'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    type= 'date'
                   
                    inputRef={refDataFinal}             
                   />
            </Grid>
            <Grid item xs = {1}>
                <TextField
                    label ='Placa'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}}  
                    inputRef={refPlaca}                  
                   />
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Número Série'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    inputRef={refNumeroSerie}                   
                   />
            </Grid>
            
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getData()}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>clearForm()}><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
        <Grid item xs = {2}>
                <TextField
                    label ='Status'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    select
                    SelectProps={{native : true}}
                    inputRef={refStatus}
                    
                    size ='small'
                    >
                    <option value ={-1}>TODOS</option>
                        <option value ={1}>DESCONHECIDO</option>
                        <option value ={2}>ON LINE</option>
                        <option value ={3}>ENERGY SAVING</option>
                        <option value ={4}>IMPRIMINDO</option>
                        <option value ={5}>OCORRENCIA</option>
                        <option value ={6}>ATENÇÃO</option>
                        <option value ={7}>ATOLAMENTO</option>
                        <option value ={8}>PORTA ABERTA</option>                  
                    
                    </TextField>
            </Grid>

       
            <Grid item xs = {2}>
                <TextField
                    label ='Marca'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    inputRef={refMarca}
                    size ='small'
                    />                   
            </Grid>
            <Grid item xs = {5}>
                <TextField
                    label ='Modelo'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    inputRef={refModelo}                   
                   />
            </Grid>
            
            <Grid item xs = {3}>
                <TextField
                    label ='Setor'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    inputRef={refSetor}                   
                    size ='small'
                    />                   
            </Grid>
            


        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home-clientes/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Gerenciamento de Coletas</Typography>
            </Breadcrumbs>
            <br/>
            <span>* Click no número de série para abrir os detalhes da coleta</span>
        </div> 
        <div className='div-form-container'>
        <Grid spacing={1}>
            <Table  >
                <TableHead>
                    <TableCell align='center' style = {{width:"8%"}}>Status</TableCell>
                    <TableCell align='center' style = {{width:"3%"}}>Conexão</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>1º Leitura</TableCell>                    
                    <TableCell align='center' style = {{width:"10%"}}>Atualização</TableCell>
                    <TableCell align='left' style = {{width:"20%"}}>Equipamento</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Setor</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Contadores</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Suprimentos</TableCell>
                </TableHead>
                
                
                <TableBody>
                   
                {
                    coletas && coletas.map(function(coleta, i){
                        return <TableRow key = {'ROWCOL'+i} className={checarStatus(coleta.ativo, coleta.statussistema)} >
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>{renderStatus(coleta.statussistema, coleta.enderecoip, coleta.dias,coleta.ativo, coleta.mensagempainel)}</TableCell>
                    <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>{coleta.dias > 0  ? <LinkOff style={{color:'#f44336'}}/> : <LinkOn style={{color:'#00897b'}}/> }</TableCell>
                    <TableCell align='center'style ={{paddingTop:"5px", paddingBottom:"5px"}} ><Typography color='primary' variant='caption'>{coleta.datacadastro+' '+coleta.horacadastro.substr(0, 8)}</Typography></TableCell>                    
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}><Typography color='primary' variant='caption'>{coleta.datacoleta+' '+coleta.horacoleta.substr(0, 8)}</Typography></TableCell>
                        <TableCell align='left' style ={{paddingTop:"5px", paddingBottom:"5px"}}>
                            <Typography color='primary' variant='caption'><strong >{coleta.razaosocial}</strong></Typography><br/>                            
                            <Typography color='primary'  onClick = {()=>{    
                                                         
                                                         props.history.push({pathname : '/detalhes_Coleta_cliente/revenda/'+props.match.params.idempresa+'/empresa/'+props.match.params.chaveEmpresa+'/coleta/'+coleta.idcoleta+'/cliente/'+props.match.params.idcliente, state : {coleta : coleta, departamentos : departamentos}});
                            }}><strong className='span-link'>{coleta.numeroserie}</strong><span style ={{fontSize:"12px", marginLeft:"5px"}}>{coleta.modelo}{' ('+coleta.marca+')'}</span> </Typography>
                            {coleta.monitorartoner=='t' && <div style={{color:"#444"}}><NetworkCheckIcon style={{color:"#00897b"}} /><span style ={{fontSize:"9px"}}>Alerta de suprimento <strong>GLOBAL </strong> 
                                habilitado : C{coleta.monitoramentociano}% M{coleta.monitoramentomagenta}% Y{coleta.monitoramentoamarelo}% K{coleta.monitoramentopreto}% </span> </div>}
                            
                        </TableCell>
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>
                        <Typography variant='caption' >{coleta.departamento}</Typography>

                        </TableCell>
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>
                            
                        <Typography variant='caption' className='conter-total'>{coleta.contadormetros == null ? coleta.contadortotal : coleta.contadormetros}</Typography><br/>
                        <Typography variant='caption' className='conter-mono'>PB: {coleta.contadormetros == null ? coleta.monototal : coleta.contadormetros}</Typography>
                        <Typography variant='caption' className='conter-color'> CL: {coleta.colortotal}</Typography>
                        </TableCell>
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>
                        <DisplayLinearToner value ={coleta.nivelciano ? coleta.nivelciano : 0} rotulo = 'ciano' alerta={coleta.alertaciano == 't'} nivelAlerta={coleta.nivelalertaciano}/>
                            <DisplayLinearToner value ={coleta.nivelamarelo ? coleta.nivelamarelo : 0} rotulo='magenta' alerta={coleta.alertamagenta == 't'} nivelAlerta={coleta.nivelalertamagenta}/> 
                            <DisplayLinearToner value ={coleta.nivelmagenta ? coleta.nivelmagenta : 0} rotulo ='amarelo' alerta={coleta.alertaamarelo == 't'} nivelAlerta={coleta.nivelalertaamarelo}/>
                            <DisplayLinearToner value ={coleta.nivelpreto ? coleta.nivelpreto : 0} rotulo = 'preto' alerta={coleta.alertapreto == 't'} nivelAlerta={coleta.nivelalertapreto}/>
                            
                            { /*<div className="div-container-nivel-toner">
                                <div className="div-container-cirular-nivel">{renderNivelToner(coleta.nivelcian, "#304ffe", 'C')}</div>
                                <div className="div-container-cirular-nivel">{renderNivelToner(coleta.nivelamarelo, "#b71c1c", 'Y')}</div> 
                                <div className="div-container-cirular-nivel">{renderNivelToner(coleta.nivelmagenta, "#ffa000", 'M')}</div>
                                <div className="div-container-cirular-nivel">{renderNivelToner(coleta.nivelpreto, "#000", 'K')}</div>
                         </div>*/}
                        </TableCell>
                    </TableRow>
                    })
                    
                    }
                    {
                        coletas.length == 0 && <TableRow>
                        <TableCell colSpan={6} align='left'>
                        <Alert severity='info'>{loading ?'Aguarde... buscando informações.' : '* Não existem contadores para o filtro especificado.'}</Alert>
                        </TableCell>
                       
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </Grid>                     
        </div>
        <div className='fabIncluir'>
           
        </div>
      <ModalDetalhesLeitura departamentos={departamentos} idleitura = {idLeitura} closeModal = {()=>setIdLeitura(-1)}/>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(GestaoColetas)