import React, {Fragment, useState, useEffect, useRef} from 'react'
import './Index.css'
import NavPrincipal from './Componentes/Navbar'
import Carrossel from './Componentes/Carrossel'
import CardsTop from './Componentes/CardsTop'
import Sobrenos from './Componentes/Sobrenos'
import Faleconosco from './Componentes/Formcontato'
import Rodape from './Componentes/Rodape'
import Fab from '@material-ui/core/Fab';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Planos from './Componentes/Planos'


const Index =(props) =>{
    const [pos, setPos] = React.useState(0)


    React.useEffect(()=>{     
        window.addEventListener("scroll", handleScroll);     
    }, [])

    const  handleScroll = () => {
        setPos(window.pageYOffset)
    };

    


    return <div id='div-main'>
        
        <NavPrincipal  {...props}/>
        <Carrossel/>     
        <CardsTop />
        <Sobrenos />
      
        <Faleconosco />
        
        <Rodape />
        {pos > 250 && <div className='fabIncluir'>       
        <AnchorLink href='#nav-container-main'>  <Fab size="small" color="secondary" >
          <ArrowUpwardIcon />
        </Fab></AnchorLink>


        </div>}
        
        
    </div>
}


export default Index;