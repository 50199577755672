import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {Grid, TextField, InputAdornment, Tooltip, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TextFieldMoeda from '../../../../components/TextFieldMoeda'
import ModalBuscaCliente from '../../../Clientes/ModalBuscaCliente'
import Conexao from '../../../../providers/Conexao'
import Swal from 'sweetalert2';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalCadastro(props) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [cliente, setCliente] = React.useState({idcliente:'', razaosocial:''});
  const [tiposContrato, setTiposContrato] = React.useState([]);
  const [tiposChamados, setTiposChamados] = React.useState([]);
  const [funcionarios, setFuncionarios] = React.useState([]);

  const [idTipoContrato, setTipoContrato]= React.useState('F');
  const [idVendedor, setIdVendedor]= React.useState('F');
  const [dataCelebracao, setDataCelebracao]= React.useState('');
  const [dataInicio, setDataInicio]= React.useState('');
  const [dataFim, setDataFim]= React.useState('');
  const [diaLeitura, setDiaLeitura]= React.useState('10');
  const [indiceReajuste, setIndiceReajuste]= React.useState('');
  const [custoVisita, setCustoVisita]= React.useState('');
  const [custoTecnico, setCustoTecnico]= React.useState('');
  const [custoAdministrativo, setCustoAdministrativo]= React.useState('');
  const [idTipoChamadoTecnico, setIdTipoChamadoTecnico]= React.useState('F');
  const [gerarExcedente, setGerarExcedente]= React.useState('F');
  const [gerarCredito, setGerarCredito]= React.useState('F');
  const [observacoes, setObservacoes] = React.useState(''); 

  const [gruposFranquiaCadastro, setGruposFranquiaCadastro]= React.useState([]); 


  const insertGrupoFranquiaLista =  (franquia) =>{
    const grupoFranquia = gruposFranquiaCadastro;
    grupoFranquia.push(franquia)
    setGruposFranquiaCadastro(grupoFranquia)
  }
  /*
  {
      idUsuario : '', 
      idEmpresa : '', 
      idGrupoFranquia : '', 
      descricao : '', 
      quantidadeFranquia: '', 
      valorFranquia : '', 
      valorExcedente : ''
  }
  */


  React.useEffect(()=>{
    if(open){
        setCliente({idcliente:'', razaosocial:''})
        getComboFuncionarios()
        getTiposContratos();
        getTiposChamados();

        setIdVendedor('f')
        setTipoContrato('F')
        setIdVendedor('F')
        setDataCelebracao('')
        setDataInicio('')
        setDataFim('')
        setDiaLeitura('10')
        setIndiceReajuste('0')
        setCustoVisita('0')
        setCustoTecnico('0')
        setCustoAdministrativo('0')
        setIdTipoChamadoTecnico('F')
        setGerarExcedente('F')
        setGerarCredito('F')
        setObservacoes('')
 
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getComboFuncionarios = async () =>{
    Conexao('funcionariosController/getComboFuncionarios?idUsuario='+props.idUsuario+'&idEmpresa='+props.idEmpresa).then(response=>{
        if(response.data.status == 'OK'){
            setFuncionarios(response.data.payload);
        }
    }).catch(error=>{

    })
  }

  const getTiposContratos = async () =>{
        Conexao.get('contratosController/getTiposContratos').then(response=>{
            if(response.data.status == 'OK'){
            
                setTiposContrato(response.data.payload)
            }
        }).catch(error=>{

        });
    }


    const getTiposChamados = async () =>{
        Conexao.get('chamadosTecnicosController/getTiposChamados').then(response=>{
            if(response.data.status == 'OK'){
            
                setTiposChamados(response.data.payload)
            }
        }).catch(error=>{

        });
    }

    const insertNovoContrato = async ()=>{
        setError(false)

       

        if(idVendedor =='F' || 
            idTipoContrato == 'F' || 
            idTipoChamadoTecnico == 'F' ||
            cliente.idcliente == '' || 
            cliente.razaosocial == '' ){
            Swal.fire({
                title :'Printerconnect', 
                text : 'Informe o Tipo de Contrato', 
                icon : 'question', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            });
            setError(true)
            return
        }
        Conexao.post('contratosController/insertNovoContrato', {
            idUsuario : props.idUsuario, 
            idEmpresa : props.idEmpresa, 
            idTipoContrato : idTipoContrato, 
            idCliente : cliente.idcliente, 
            razaoSocial : cliente.razaosocial, 
            idVendedor : idVendedor, 
            dataCelebracao : dataCelebracao, 
            dataVigenciaInicio : dataInicio, 
            dataVigenciaFim : dataFim, 
            diaLeitura : diaLeitura, 
            indiceReajuste :  indiceReajuste, 
            custoVisita : custoVisita, 
            custoTecnico : custoTecnico, 
            custoAdministrativo : custoAdministrativo, 
            tipoChamadoTecnico : idTipoChamadoTecnico, 
            gerarExcedente : gerarExcedente, 
            gerarCredito : gerarCredito, 
            observacoes : observacoes 
        }).then(response =>{
            if(response.data.status == 'OK'){
                Swal.fire({
                    title :'Printerconnect', 
                    text : 'Contrato lançado com sucesso !', 
                    icon : 'success', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1500
                });
                props.getData();
            }else{
                Swal.fire({
                    title :'Printerconnect', 
                    text : 'Não foi possivel lançar contrato !', 
                    icon : 'warning', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1500
                });
            }
            setOpen(false)
        }).catch(error =>{
            Swal.fire({
                title :'Printerconnect', 
                text : 'Não foi possivel lançar contrato !', 
                icon : 'error', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 1500
            });
            setOpen(false)
        });
    }

  return (
    <div>
        <Tooltip title='Cadastrar um novo Contrato'>
        <Fab color="primary" onClick={handleClickOpen} aria-label="add" style ={{marginTop:"20px"}}>
            <AddIcon />
        </Fab>
        </Tooltip>
      <Dialog fullWidth maxWidth='lg' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableBackdropClick disableEscapeKeyDown hideBackdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Novo Contrato
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing = {1}>
                <Grid item xs = {5}>
                    <TextField
                        label='Tipo de Contrato'
                        placeholder='Selecione o tipo do contrato'
                        fullWidth
                        select
                        size='small'
                        error={error && idTipoContrato =='F'}
                        SelectProps={{native : true}}
                        InputLabelProps={{shrink : true}}
                        value={idTipoContrato}
                        onChange={(e)=>setTipoContrato(e.target.value)}
                        error={error && idTipoContrato == 'F'}
                        variant='outlined'>
                            <option value = 'F'>Selecione um tipo de contrato</option>
                            {
                                tiposContrato && tiposContrato.map(function(tipoContrato, i){
                                    return <option value = {tipoContrato.idtipocontrato}>{tipoContrato.descricao}</option>
                                })
                            }
                    </TextField>
                </Grid>
                <Grid item xs = {2}>
                    <TextField
                        label='Código Cliente'
                        placeholder='Selecione um cliente'
                        value={cliente.idcliente}
                        error={error && cliente.idcliente == ''}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        value={cliente.idcliente}
                        InputProps ={{
                            startAdornment : <InputAdornment position='start'><ModalBuscaCliente  updateCliente={(cliente)=>setCliente(cliente)}/></InputAdornment>
                        }}
                        />
                </Grid>
                <Grid item xs = {5}>
                    <TextField
                        label='Razão Social'
                        value={cliente.razaosocial}
                        error={error && cliente.razaosocial == ''}
                        placeholder='Selecione um cliente'
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing = {1}>
               
                <Grid item xs = {3}>
                <TextField
                        label='Vendedor'
                        value={idVendedor}
                        onChange={(e)=>setIdVendedor(e.target.value)}
                        error={error && idVendedor == 'F'}
                        placeholder='Selecione o vendedor deste contrato'
                        fullWidth
                        select
                        size='small'
                        SelectProps={{native : true}}
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                            <option value = 'F'>Selecione o vendedor responsável</option>
                            {
                                funcionarios && funcionarios.map(function(funcionario){
                                    return <option value = {funcionario.idtecnico}>{funcionario.nomecompleto}</option>
                                })
                            }
                    </TextField>
                </Grid>
               
                <Grid item xs = {2}>
                    <TextField
                        label='Data Celebração'
                        value={dataCelebracao}
                        onChange={(e)=>setDataCelebracao(e.target.value)}
                        size='small'
                        fullWidth
                        type='date'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextField
                        label='Data Inicio'
                        value={dataInicio}
                        onChange={(e)=>setDataInicio(e.target.value)}
                        size='small'
                        fullWidth
                        type='date'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextField
                        label='Data Fim'
                        value={dataFim}
                        onChange={(e)=>setDataFim(e.target.value)}
                        size='small'
                        fullWidth
                        type='date'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {1}>
                    <TextField
                        label='Leitura Dia'
                        value={diaLeitura}
                        onChange={(e)=>setDiaLeitura(e.target.value)}
                        size='small'
                        fullWidth
                        defaultValue={10}
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Indíce de Reajuste'
                        value={indiceReajuste}
                        onChange={(e)=>setIndiceReajuste(e.target.value)}
                        size='small'
                        placeholder='0,0'
                        defaultValue={0}
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing = {1}>               
               
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Visita Técnica'
                        value={custoVisita}
                        onChange={(e)=>setCustoVisita(e.target.value)}
                        placeholder='0,0'
                        defaultValue={0}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Técnico'
                        value={custoTecnico}
                        onChange={(e)=>setCustoTecnico(e.target.value)}
                        placeholder='0,0'
                        defaultValue={0}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Administrativo'
                        value={custoAdministrativo}
                        onChange={(e)=>setCustoAdministrativo(e.target.value)}
                        placeholder='0,0'
                        defaultValue={0}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>

                <Grid item xs = {6}>
                    <TextField
                        label='Ordem de Serviço (tipo) Padrão'
                        value={idTipoChamadoTecnico}
                        onChange={(e)=>setIdTipoChamadoTecnico(e.target.value)}
                        fullWidth
                        select
                        size='small'
                        SelectProps={{native : true}}
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                            <option value = ''>Selecione o tipo padrão de Ordem de Serviço para este contrato</option>
                            {
                                tiposChamados && tiposChamados.map(function(tipoChamado, i){
                                    return <option value = {tipoChamado.idtipochamadotecnico}>{tipoChamado.descricao}</option>
                                })
                            }
                            
                    </TextField>
                </Grid>
               
            </Grid>
            <br/>
            <Grid container spacing ={ 1}>
                <Grid item xs = {6}>
                    <TextField
                        label='Gerar excedente'
                        value={gerarExcedente}
                        onChange={(e)=>setGerarExcedente(e.target.value)}
                        fullWidth
                        select
                        size='small'
                        SelectProps={{native : true}}
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                            
                            <option value = 'F'>NÃO</option>
                            <option value = 'T'>SIM</option>
                            
                    </TextField>
                </Grid>
                <Grid item xs = {6}>
                    <TextField
                        label='Habilitar controle de crédito'
                        value={gerarCredito}
                        onChange={(e)=>setGerarCredito(e.target.value)}
                        fullWidth
                        select
                        size='small'
                        SelectProps={{native : true}}
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                            <option value = 'F'>NÃO</option>
                            <option value = 'T'>SIM</option>
                            
                    </TextField>
                </Grid>
            </Grid>
            
            <br/>
            <Grid container spacing = {1}>
                <Grid item xs = {12}>
                    <TextField
                        label='Observações gerais'
                        value={observacoes}
                        onChange={(e)=>setObservacoes(e.target.value)}
                        placeholder='Observações gerais sobre o contrato aqui'
                        fullWidth
                        multiline                        
                        inputProps ={{
                            maxlength : 500
                        }}
                        size='small'
                        rows={8}
                        rowsMax={8}
                        InputLabelProps={{shrink : true}}
                        variant='outlined'/>
                </Grid>
              </Grid>
        </DialogContent>
        <DialogActions>
        <Button autoFocus size='large' onClick={handleClose} color="secondary" variant='contained'>
            cancelar
          </Button>
          <Button autoFocus size='large' onClick={handleClose} color="primary" variant='contained' onClick={()=>{
               Swal.fire({
                title :'Printerconnect', 
                text : 'Deseja realmente incluir um novo Contrato agora ?', 
                icon : 'question', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton : true, 
                confirmButtonText : 'Confirmar',
            }).then(resposta =>{
                if(resposta.isConfirmed){
                    insertNovoContrato()
                }
            })
              
          }}>
            gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
