import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../../../providers/Conexao'
import Chip from '@material-ui/core/Chip';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../../../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ComputerIcon from '@material-ui/icons/Computer';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';
import AddIcon from '@material-ui/icons/Add';



import ModalIncluirDepartamento from './Components/ModalIncluirDepartamento';
import ModalAlterarDepartamento from './Components/ModalAlterarDepartamento';
import ModalVincularDepartamento from './Components/ModalVincularDepartamento';
import ModalVincularUserDep from './Components/ModalVincularUserDep';

const CadastrarDepartamento = (props)=>{
   
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [empresa, setEmpresa] = React.useState(props.location.state.empresa)
  
    const [departamento, setDepartamento] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [registros, setRegistros]= React.useState(0);

    const refDataInicial = React.useRef();
    const refDataFinal = React.useRef();
    const refNomeFila = React.useRef();
    const refHostName = React.useRef();   
    const refUserName = React.useRef();
    const refNomeDocumento = React.useRef();
    const refDescricao = React.useRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(()=>{
      
       getDepartamento();
     
        
    },[]);
    useEffect(()=>{
       
    },[page,rowsPerPage ]);

    const getDepartamento = () =>{
        setLoading(true)
        //setCentroDeCusto([])
        Conexao.post('bilhetagemController/getDepartamentosBilling',{
            idcliente: empresa.idcliente,
            idempresa: empresa.idempresa,  
            descricao: refDescricao.current ? refDescricao.current.value : '',         
           
           

        }).then(response=>{
     
            if(response.data.status =='OK'){
              // console.log(response.data.payload)
               setDepartamento(response.data.payload);
               //console.log(centroDeCusto)
               
              
                
            }else{
                setDepartamento([])
              
            }
            setLoading(false)
        }).catch(error=>{setLoading(false)});
    }

    
    const clearForm = () =>{
        if(refDescricao.current){
            refDescricao.current.value  = '';
        }
        getDepartamento()
    }


  

    return <Principal atualizar = {loading}>
        <br />
        <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Cadastrar Departamento</Typography></Grid>            
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
       
        <Grid container spacing={2}>
          
            <Grid item xs={9}>
                <TextField
                     inputRef ={refDescricao}
                    label='Descrição'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                >
                   

                </TextField>
            </Grid>


          

            <Grid item xs = {3} spacing={3} className='container-buttons'>
                <Button color = 'primary' variant='contained' style={{margin: 2}} onClick={()=>{getDepartamento()}}><SearchIcon/> Pesquisar</Button>
             
                <Button color='secondary' variant='contained' style={{margin: 2}} onClick={()=>{clearForm()}} ><ClearIcon/> Limpar</Button>
            </Grid>
            
        </Grid>


        <br/>
        <div>
        <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={'grafico-coleta-clientes/revenda/'+empresa.idusuario+'/empresa/'+empresa.idempresa+'/cliente/'+empresa.idcliente} >
                    Home
                </Link>
            <Typography color="textPrimary">Cadastrar Departamento</Typography>
            </Breadcrumbs>
        </div>
       <div className='div-form-container'>

        <Table className='table-small' style = {{fontSize:"16px", color:"#444"}}>
            <TableHead>
                <TableCell style={{width:"20%", fontSize:"16px"}}>Descrição</TableCell>
                <TableCell style={{width:"20%", fontSize:"16px"}}>Responsável</TableCell>
                <TableCell  style={{width:"20%", fontSize:"16px"}}>E-mail</TableCell>
                <TableCell align='center' style={{width:"8%", fontSize:"16px"}}>Vinc. User</TableCell>
                <TableCell align='center' style={{width:"5%", fontSize:"16px"}}>Alterar</TableCell>
                <TableCell align='center' style={{width:"5%", fontSize:"16px"}}>Excluir</TableCell>
               
               
             
            </TableHead>
            <TableBody>
               
                   { departamento && departamento.map(function(departamento, i){
                       return <TableRow key={i}>
                        <TableCell style={{ fontSize:"14px"}}><Typography variant='caption' color={'primary'}>{departamento.descricao}</Typography></TableCell>  
                        <TableCell style={{ fontSize:"14px"}}>
                   <Typography  style={{ fontSize:"14px"}} variant='caption' color={'primary'}>{departamento.responsavel}</Typography><br/>
                        </TableCell>
                   <TableCell style={{ fontSize:"14px"}}><Typography variant='caption' style={{fontSize:"14px"}} color={'primary'}>{departamento.emailresponsavel}</Typography></TableCell>
                   <TableCell align='center' style={{ fontSize:"14px"}}><Typography variant='caption' style={{fontSize:"14px"}} color={'primary'}><ModalVincularUserDep style={{cursor:'pointer'}} empresa={empresa} departamento={departamento} /></Typography></TableCell>
                   <TableCell align='center' style={{ fontSize:"14px"}}><Typography variant='caption' style={{fontSize:"14px"}} color={'primary'}><ModalAlterarDepartamento style={{cursor:'pointer'}} empresa={empresa} departamento={departamento} getDepartamento={()=>{
                       getDepartamento()
                   }} /></Typography></TableCell>
                     <TableCell align='center' style={{ fontSize:"14px"}}><DeleteForeverIcon style={{cursor:'pointer'}}onClick={()=>{
                         Swal.fire({
                             title: 'PrinterConnect',
                             text: 'Deseja Realmente excluir o departamento ?',
                             icon: 'question',
                             showCancelButton: true,
                             cancelButtonText: 'Não',
                             showConfirmButton: true,
                             confirmButtonText: 'Sim'
                         }).then(resposta =>{
                             if(resposta.isConfirmed){
                                Conexao.post('bilhetagemController/deleteDepartamento',{
                                    idcliente: empresa.idcliente,
                                    idempresa: empresa.idempresa,
                                    iddepartamento: departamento.iddepartamento      
                                  
                                }).then(response=>{
                                    if(response.data.status == 'OK'){
                                        Swal.fire({
                                            title : 'Printerconnect',
                                            icon : 'success', 
                                            text : 'Excluído com sucesso !', 
                                            showCancelButton : false, 
                                            showConfirmButton : false, 
                                            timer : 2000, 
                                          
                                            })
                                        getDepartamento()

                                    }else{
                                        
                                            Swal.fire({
                                                title : 'Printerconnect',
                                                icon : 'warning', 
                                                text : 'Não foi possível Excluir !', 
                                                showCancelButton : false, 
                                                showConfirmButton : false, 
                                                timer : 2000, 
                                              
                                                })
                                             
                                         
                                    }
                                })
                                
                             }
                         })
                     }}/></TableCell>
                     
                      
                        
                    </TableRow >
                     
                    
                    })  
                    }
                
                
            </TableBody>
      
        </Table>
                
            <div className='fabIncluir'>
                <ModalIncluirDepartamento empresa={empresa} getDepartamento={()=>{
                       getDepartamento()
                   }} />
            </div>
       </div>
       
       </Principal>

}


export default withRouter(CadastrarDepartamento)

