import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SettingsIcon from '@material-ui/icons/Settings';
import IconMarca from '@material-ui/icons/Commute'
import {Table, TableHead, TableBody, TableRow, TableCell, TextField} from '@material-ui/core'
import ModalNovoModelo from './ModalNovoModelo'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [modelos, setModelos] = React.useState([])
    const [indiceLinha, setIndiceLinha] = React.useState(-1)
    const [descricao, setDescricao]= React.useState('')
    const [idMarca, setIdMarca] = React.useState(-1)
    const [marcas, setMarcas]= React.useState([])

    const handleClickOpen = () =>{
        setIdMarca(-1)
        setIndiceLinha(-1)
        setDescricao('')
        setOpen(true)
        getModelos();
    }

    const handleClose = () =>{
        setOpen(false)
    }

    const getModelos = () =>{
      setLoading(true)
      Conexao.post('veiculosController/getModelos',{
        idusuario : props.usuario
      }).then(response=>{
        if(response.data.status =='OK'){
          setModelos(response.data.payload)
        }else{
          setModelos([])
        }
        setLoading(false)
      }).catch(error =>{
        setModelos([])
        setLoading(false)
      })
    }

    const getMarcas = () =>{
      setLoading(true)
      Conexao.get('veiculosController/getMarcas?idusuario='+props.usuario).then(response=>{
          if(response.data.status =='OK'){
              setMarcas(response.data.payload)
              
          }else{
              setMarcas([])
          }
          setLoading(false)

      }).catch(error=>{
       
          setMarcas([])
          setLoading(false)

      })
  }
  return (
    <div>
    
      <Tooltip title='Cadastrar um novo modelo'>           
        <Fab size='small' onClick={handleClickOpen} style={{cursor:'pointer'}}>
            <IconMarca color='primary' />
        </Fab>
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='md'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Cadastro de Modelos
        </DialogTitle>
        <DialogContent dividers style = {{height:"350px", overflow:"auto"}}>
          <Table className='table table-sm table-small table-striped table-hover' >
              <TableHead>
                  <TableCell align='center' style  ={{width :'15%'}}>ID</TableCell>
                  <TableCell style  ={{width :'40%'}}>Descrição</TableCell>
                  <TableCell style  ={{width :'25%'}}>Marca</TableCell>
                  <TableCell align='center' style  ={{width :'10%'}}>Editar</TableCell>
                  <TableCell align='center' style  ={{width :'10%'}}>Excluir</TableCell>
              </TableHead>
              <TableBody>
                {
                  modelos && modelos.map(function(modelo, i){
                    return <TableRow key = {i}>
                      <TableCell align='center'>{i + 1}</TableCell>
                      <TableCell>{
                      indiceLinha != i ? modelo.descricao : <TextField value={descricao} onChange={(e)=>setDescricao(e.target.value)} fullWidth /> 
                      }</TableCell>
                      <TableCell>{
                         indiceLinha != i ? modelo.marca : <TextField value={idMarca} onChange={(e)=>setIdMarca(e.target.value)} fullWidth select SelectProps={{native:true}}>
                            <option value ={modelo.idmarca}>{modelo.marca}</option>
                            {
                             marcas && marcas.map(function(marca, i){
                                 return <option value ={marca.idmarca}>{marca.descricao}</option>
                             })
                          }
                           </TextField>
                        }</TableCell>
                      <TableCell align='center'>{indiceLinha != i ? <SettingsIcon color='primary' style = {{cursor:'pointer'}} onClick={()=>{
                        getMarcas()
                        setIdMarca(modelo.idMarca)
                        setIndiceLinha(i)
                        setDescricao(modelo.descricao)
                      }}/> : <SaveIcon color='info' style = {{cursor:'pointer'}} onClick={()=>{
                        Swal.fire({
                          title : 'Printerconnect - Frota', 
                          text : 'Deseja gravas as alterações?', 
                          icon : 'question', 
                          showCancelButton : true, 
                          showCancelButton :true,
                        }).then(resposta =>{
                          Conexao.post('veiculosController/updateModelo', {
                            idusuario : props.usuario, 
                            idmarca : idMarca, 
                            idmodelo : modelo.idmodelo, 
                            descricao : descricao
                          }).then(response=>{
                            if(response.data.status =='OK'){
                              Swal.fire({
                                title : 'Printerconnect - Frota', 
                                text : 'Registro atualizado com sucesso!', 
                                icon : 'success', 
                                showConfirmButton : false, 
                                showCancelButton :false,
                                timer : 1000
                              })
                              getModelos()
                            }else{
                              Swal.fire({
                                title : 'Printerconnect - Frota', 
                                text : 'Não foi possível atualizar', 
                                icon : 'warning', 
                                showConfirmButton : false, 
                                showCancelButton :false,
                                timer : 1000
                              })
                            }
                            setIdMarca(-1)
                            setIndiceLinha(-1)
                            setDescricao('')
                          }).catch(error=>{
                            Swal.fire({
                              title : 'Printerconnect - Frota', 
                              text : 'Não foi possível atualizar', 
                              icon : 'error', 
                              showConfirmButton : false, 
                              showCancelButton :false,
                              timer : 1000
                            })
                            setIdMarca(-1)
                            setIndiceLinha(-1)
                            setDescricao('')
                          })
                        }
                        );
                      }}/> }</TableCell>
                      <TableCell align='center'>{indiceLinha != i ?<DeleteIcon color='secondary' style = {{cursor:'pointer'}} onClick={()=>{
                        
                         Conexao.post('veiculosController/deleteModelo', {
                          idusuario : props.usuario, 
                          idmodelo : modelo.idmodelo
                        }).then(response=>{
                          if(response.data.status =='OK'){
                            Swal.fire({
                              title : 'Printerconnect - Frota', 
                              text : 'Registro removido com sucesso!', 
                              icon : 'success', 
                              showConfirmButton : false, 
                              showCancelButton :false,
                              timer : 1000
                            })
                            getModelos()
                          }else{
                            Swal.fire({
                              title : 'Printerconnect - Frota', 
                              text : 'Não foi possível excluir', 
                              icon : 'warning', 
                              showConfirmButton : false, 
                              showCancelButton :false,
                              timer : 1000
                            })
                          }
                          setIdMarca(-1)
                          setIndiceLinha(-1)
                          setDescricao('')
                        }).catch(error=>{
                          Swal.fire({
                            title : 'Printerconnect - Frota', 
                            text : 'Não foi possível excluir', 
                            icon : 'error', 
                            showConfirmButton : false, 
                            showCancelButton :false,
                            timer : 1000
                          })
                          setIdMarca(-1)
                          setIndiceLinha(-1)
                          setDescricao('')
                        })



                      }}/>: <CancelIcon color='error' style = {{cursor:'pointer'}} onClick={()=>{
                         setIdMarca(-1)
                         setIndiceLinha(-1)
                         setDescricao('')
                      }}/> }</TableCell>
                      
                    </TableRow>
                  })
                }
              </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <ModalNovoModelo disabled={loading}  usuario={props.usuario} getModelos={()=>getModelos()}/>
          <Button disabled={loading} autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalPadrao)