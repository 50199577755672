import React, {Fragment, useState, useEffect} from 'react'
import Conexao from '../../providers/Conexao'





const ListaAlertas = (props) =>{
    const [atolamentos, setAtolamentos] = useState([]);

    useEffect(()=>{
        if(props.numeroserie){
            getData()
        }
        
    },[props.numeroserie]);


    function getData(){
        Conexao.post('coletasController/getAlertasEquipamentosCollector', {
            idempresa : props.idempresa , 
            idcliente : props.idcliente, 
            offSet : 0, 
            limit : 10, 
            numeroserie : props.numeroserie
        }).then(response=>{
            setAtolamentos(response.data.payload.data)
        }).catch(error =>{

        });
    }

    return<div className='container-alertas-detalhes-equipamento'>

    </div >
}

export default ListaAlertas