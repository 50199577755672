import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import { Alert, AlertTitle } from '@material-ui/lab';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import BlockIcon from '@material-ui/icons/Block';
import UsbIcon from '@material-ui/icons/Usb';
import {Badge} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import PrintIcon from '@material-ui/icons/Print';
import Tooltip from '@material-ui/core/Tooltip';
import GraficoDca from './Componentes/GraficoPiza'//https://recharts.org/en-US/examples/TwoLevelPieChart
import DisplayToner from '../../components/DisplayToner'
import GraficoChamados from './Componentes/GaficoAberturaChamados'//https://recharts.org/en-US/examples/TwoLevelPieChart
import CardEquipamento from './Componentes/CardEquipamentos'
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import Calendar from '@material-ui/icons/CalendarToday'
import Warning from '@material-ui/icons/Warning'
import Notification from '@material-ui/icons/Notifications'
import Chart from 'react-google-charts'
import './DashboardFull.css'



let timer = null;
const DashboardColetas = (props)=>{
   
    const [dataHistoricoEquipamentos, setDataHistoricoEquipamentos] = React.useState([])
    const [idEmpresa, setIdEmpresa ] = React.useState(2)

    React.useEffect(()=>{
        getHistoricoEquipamentosLigados();
    },[])        
    


    const getHistoricoEquipamentosLigados = async() =>{
        Conexao.get('dashboardController/getHistoricoEquipamentosLigados?idEmpresa='+idEmpresa).then(response=>{
            if(response.data.status =='OK'){
                setDataHistoricoEquipamentos(response.data.payload)
            }else{
                setDataHistoricoEquipamentos([]);
            }
        }).catch(error=>{

        });
    } 

   
return <Principal full = {'div-dashboard-full'} atualizar = {null} >
     
        <div id='div-dashboard-container' >
           <div id='dashboard-container-cards-coletas'>
               <div className='card-coletas' style = {{width:"25%"}}>
                    
                    <div style ={{width:"100%", backgroundColor:"#fff"}}>
                        <div className='card-coleta-item card-info'>
                            <div style ={{width:"30%"}}><PrintIcon/></div>
                            <div style ={{width:"70%", padding:"5px"}}>
                                <Typography variant='h2'>5</Typography>
                                <Typography variant='caption'>Equipamentos Monitorados</Typography>
                            </div>
                        </div>
                        <div style ={{padding:"5px"}}>
                            <div><PrintIcon/> <Typography variant='caption'>Equipamentos Monitorados</Typography></div>
                            <div><Warning/> <Typography variant='caption'>Equipamentos Monitorados</Typography> </div>
                            <div><Notification/> <Typography variant='caption'>Equipamentos Monitorados</Typography> </div>
                            <div><PrintIcon/> <Typography variant='caption'>Equipamentos Monitorados</Typography> </div>
                        </div>
                    </div>
               </div>
               <div className='card-coletas' style = {{width:"75%", paddingLeft:"10px", paddingRight:"10px"}}>
                    <div  style = {{ height:"150px"}}>
                        <Typography variant='caption' color='primary'>Equipamentos Ligados</Typography>
                        <Chart
  width={'500px'}
  height={'300px'}
  chartType="AreaChart"
  loader={<div>Loading Chart</div>}
  data={[
    ['Year', 'Sales', 'Expenses'],
    ['2013', 1000, 400],
    ['2014', 1170, 460],
    ['2015', 660, 1120],
    ['2016', 1030, 540],
  ]}
  options={{
    isStacked: true,
    height: 300,
    legend: { position: 'top', maxLines: 3 },
    vAxis: { minValue: 0 },
  }}
  rootProps={{ 'data-testid': '2' }}
/>
                    </div> 
               </div>             
               
           </div>
          
     
           
        </div>
        
      
    </Principal>
}



export default withRouter(DashboardColetas)
{
    /*

    <div className='card-coletas' style ={{backgroundColor:"#3F51B5", color:"#fff"}}>
                        <div style = {{textAlign:"center", width:"40%"}}><PrintIcon style ={{color:"#fff", width:"100%"}}/></div>
                        <div style = {{textAlign:"right", width:"60%"}}>
                            <Typography variant='h2'>5</Typography>
                            <Typography variant='caption'>Equipamentos Monitorados</Typography>
                        </div>
                    </div>

                    */
}