import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Typography, Grid, Divider} from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import logoPrinterConnectNovo from './Components/img/logoPrinterConnectNovo.png';
import FourdevLogo from './Components/img/FourdevLogo.png'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ContactSupportIcon style={{cursor:'pointer', fontSize: 27}} onClick={()=>{setOpen(true)}}/>
      
      
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth='sm'>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Sobre Nós
        </DialogTitle>
        <DialogContent dividers>


          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant='caption'  >A <b>A3M Soluções Digitais</b> é uma empresa de tecnologia e desenvolvimento de sistemas focada em soluções para <b>Outsourcing de Impressão.</b></Typography><br />
            <Typography variant='caption'>Contamos com profissionais que atuam a mais de 15 anos nesse ramo.</Typography>< br />
            <Typography variant='caption'> Outsourcing de Impressão na era do IoT</Typography>< br />

          </Grid>

              <Divider/>
             <br/>
              <Typography variant='body1' style={{textAlign:'center', color:'secondary'}}><b>Nosso contato</b></Typography> 
          <br/>
          <Divider/>
          <Grid item xs = {12} style={{textAlign:'center'}}>
              <Typography variant='caption' ><b>Endereço:</b> Rua Narciso Ribeiro, 319   </Typography><br/>
              <Typography variant='caption' ><b>Cep:</b> 17523-230 Jardim Nacional </Typography><br/>
              <Typography variant='caption' ><b>Telefone:</b> (14) 9 9871-7644</Typography><br/>
              <Typography variant='caption' ><b>Email Contato: </b>contato@printerconnect.com.br</Typography><br/>
              <Typography variant='caption' ><b>Email Suporte:</b> suporte@printerconnect.com.br</Typography><br/>
          </Grid>
          <Divider/>
        <br />
        <Grid item xs = {12} style={{textAlign:'center'}}>
        <Typography  variant="h6" >
              Printerconnect Admin
            </Typography>
             <Typography  variant="caption" style ={{fontSize:"9px"}}>
              MULTIEMPRESA Versão: 2.21.0.1
            </Typography>    <br></br>
        <Typography  variant="button" style ={{fontSize:"9px"}}>
               A3M Soluções Digitais
            </Typography>
            </Grid>
      
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary" variant='outlined'>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
