import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Container, Grid, TextField, InputAdornment, Table, TableBody, TableHead, TableRow, TableCell, CircularProgress} from '@material-ui/core'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Conexao from '../../../providers/Conexao'
import { useRef } from 'react';
import AttachFileIcon from '@material-ui/icons/GetApp';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalBuscaModelo = (props) =>{
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modelos, setModelos] = React.useState([]);
  const txtFiltro = useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const getModelos = () =>{
      setLoading(true)
      Conexao.post('modelosController/getModelos', {
          buscaAcessorio : props.buscaAcessorio ? 'T' : 'F',
          idusuario : props.idUsuario ? props.idUsuario : props.match.params.idempresa, 
          filtro : txtFiltro.current ? txtFiltro.current.value : ''
      }).then(response =>{
        if(response.data.status =='OK'){
            setModelos(response.data.payload)
        }else{
            setModelos([])
        }
        setLoading(false)
      }).catch(error =>{
        setModelos([])
        setLoading(false)
      })
  }

  return (
    <div>
      
     
     <SearchIcon color='primary' style ={{cursor:"pointer"}} onClick={handleClickOpen}/>
      

      <Dialog onClose={handleClose} fullWidth aria-labelledby="customized-dialog-title" open={open} disableBackdropClick disableEscapeKeyDown maxWidth='md'>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Localizar Modelo
        </DialogTitle>
        <DialogContent dividers style={{height:"500px"}}>
            <Grid container spacing={1}>
               <Grid item xs={8}>
                <TextField  
                        label ='Filtro'
                        InputLabelProps ={{shrink : true}}
                        variant = 'outlined'
                        fullWidth
                        inputRef = {txtFiltro}
                        />
               </Grid>
               <Grid item xs ={4}>
                  <Button onClick ={()=>getModelos()}>{!loading ? <SearchIcon/>: <CircularProgress style ={{width:'26px', height:'26px'}} />} Pesquisar</Button>
               </Grid>
            </Grid>
            <Table>
                <TableHead>
                    <TableCell align='left' style ={{width:"45%"}}>Descrição</TableCell>
                    <TableCell  align='center' style ={{width:"35%"}}>Marca</TableCell>
                    <TableCell  align='center' style ={{width:"15%"}}>Contadores</TableCell>
                    <TableCell  align='center' style ={{width:"15%"}}>Selecionar</TableCell>
                </TableHead>

                <TableBody>
                    {
                        modelos && modelos.map(function(modelo, i){
                            return <TableRow>
                            <TableCell align='left' style ={{width:"45%"}}>{modelo.descricao}</TableCell>
                            <TableCell  align='center' style ={{width:"35%"}}>{modelo.descricaomarca}</TableCell>
                            <TableCell  align='center' style ={{width:"15%"}}>{modelo.numeradores}</TableCell>
                            <TableCell  align='center' style ={{width:"15%"}}><Button onClick={()=>{
                                Swal.fire({
                                    title :'Gestão de Equipamentos', 
                                    text:'Deseja selecionar este equipamento ?', 
                                    icon :'question', 
                                    showCancelButton : true, 
                                    cancelButtonText : 'Cancelar', 
                                    showConfirmButton : true, 
                                    confirmButtonText : 'Confirmar'
                                }).then(resposta=>{
                                    if(resposta.isConfirmed){
                                        if(props.setModelo){
                                            props.setModelo(modelo)
                                        }else{
                                            Swal.fire({
                                                title :'Gestão de Equipamentos', 
                                                text:'Não existe função vinculada a este evento !', 
                                                icon :'error', 
                                                showCancelButton : true, 
                                                cancelButtonText : 'OK', 
                                                showConfirmButton : false, 
                                            })
                                        }
                                       
                                        setOpen(false)
                                    }
                                })
                            }}><AttachFileIcon/></Button></TableCell>
                        </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withRouter(ModalBuscaModelo)
