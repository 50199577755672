import React, {useEffect, useState, Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import {Container, Grid, TextField, Typography, Divider, Button} from '@material-ui/core'
import TextFieldCNPJ from '../../components/TextFieldCNPJ'
import TextFieldCep from '../../components/TextFieldCep'
import TextFieldCelular from '../../components/TextFieldCelular'
import TextFieldTelefone from '../../components/TextFieldTelefone'
import Swal from 'sweetalert2'
import Conexao from '../../providers/Conexao'
import TextFieldEmail from '../../components/TextFieldEmail'

const FormNovoFornecedor = function(props){
    const [idFornecedor, setIdFornecedor]= useState('')
    const [cnpj, setCnpj] = useState('')
    const [inscEstadual, setInscEstadual] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')
    const [nomeFantasia, setNomeFantasia] = useState('')
    const [endereco, setEndereco] = useState('')
    const [numero, setNumero] = useState('')
    const [cep, setCep] = useState('')
    const [complemento, setComplemento] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade,setCidade] = useState('')
    const [estado, setEstado] = useState('')
    const [telefoneComercial, setTelefoneComercial] = useState('')
    const [telefoneCelular, setTelefoneCelular]  = useState('')
    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)


    useEffect(()=>{
        if(props.edicaoFornecedor.idfornecedor && props.edicaoFornecedor.idfornecedor!= '' ){
            setCnpj(props.edicaoFornecedor.cpf_cnpj)
            setInscEstadual(props.edicaoFornecedor.rg_insc)
            setRazaoSocial(props.edicaoFornecedor.razaosocial)
            setNomeFantasia(props.edicaoFornecedor.nomefantasia)
            setCep(props.edicaoFornecedor.cep)
            setEndereco(props.edicaoFornecedor.logradouro)
            setNumero(props.edicaoFornecedor.numero)
            setComplemento(props.edicaoFornecedor.complemento)
            setBairro(props.edicaoFornecedor.bairro)
            setCidade(props.edicaoFornecedor.cidade)
            setEstado(props.edicaoFornecedor.estado)
            setTelefoneComercial(props.edicaoFornecedor.telefonecomercial)
            setTelefoneCelular(props.edicaoFornecedor.telefonecelular)
            setEmail(props.edicaoFornecedor.email)
       
        }
    }, [props.edicaoFornecedor])

    const setEnderecoByCep = (data)=>{
      
        if(!data.erro && data.logradouro){
            setEndereco(data.logradouro)
            setComplemento(data.complemento)
            setBairro(data.bairro)
            setCidade(data.localidade)
            setEstado(data.uf)
        }else{
            setEndereco('')
            setComplemento('')
            setBairro('')
            setCidade('')
            setEstado('')         
        }
    }

    const insertFornecedor = () =>{
        Conexao.post('fornecedoresController/insertFornecedor', {
            idusuario : props.location.state.empresa.idrevenda, 
            idempresa : props.location.state.empresa.idempresa, 
            cnpj, inscEstadual, razaoSocial, nomeFantasia, endereco, numero, cep, 
            complemento, bairro,cidade, estado , telefoneCelular, telefoneComercial, email
        }).then(response =>{
           
            if(response.data.status == 'OK'){
                Swal.fire({
                    title :'Gestão de Fornecedores', 
                    text :'Fornecedor cadastrado com Sucesso !', 
                    icon:'success',
                    showConfirmButton : false, 
                    showCancelButton : false, 
                    timer : 1500, 
                    onClose : ()=>{
                        
                        props.updatefornecedores();
                        props.closeModal();
                    }
                })
            }else{
                Swal.fire({
                    title :'Gestão de Fornecedores', 
                    text :'Não foi possível cadastrar !', 
                    icon : 'warning', 
                    showConfirmButton : false, 
                    showCancelButton : false, 
                    timer : 1500, 
                    onClose : ()=>{
                        props.closeModal();
                    }
                })
            }
        }).catch(error =>{
            Swal.fire({
                title :'Gestão de Fornecedores', 
                text :`Não foi possível cadastrar ! ${error}`, 
                icon : 'warning', 
                showConfirmButton : false, 
                showCancelButton : false, 
                timer : 1500, 
                onClose : ()=>{
                    props.closeModal();
                }
            })
        })
    }


    const updatefornecedor = () =>{
        Conexao.post('fornecedoresController/updatefornecedor', {
            idFornecedor : props.edicaoFornecedor.idfornecedor, 
            cnpj, 
            inscEstadual : inscEstadual ? inscEstadual : '', 
            razaoSocial, 
            nomeFantasia, 
            endereco, 
            numero, 
            cep, 
            complemento, 
            bairro,
            cidade, 
            estado , 
            telefoneCelular, 
            telefoneComercial, 
            email
        }).then(response =>{
           
            if(response.data.status == 'OK'){
                Swal.fire({
                    title :'Gestão de Fornecedores', 
                    text :'Fornecedor atualizado com Sucesso !', 
                    icon:'success',
                    showConfirmButton : false, 
                    showCancelButton : false, 
                    timer : 1500, 
                    onClose : ()=>{
                        
                        props.updatefornecedores();
                        props.closeModal();
                    }
                })
            }else{
                Swal.fire({
                    title :'Gestão de Fornecedores', 
                    text :'Não foi possível atualizar !', 
                    icon : 'warning', 
                    showConfirmButton : false, 
                    showCancelButton : false, 
                    timer : 1500, 
                    onClose : ()=>{
                        props.closeModal();
                    }
                })
            }
        }).catch(error =>{
            Swal.fire({
                title :'Gestão de Fornecedores', 
                text :`Não foi possível atualizar ! ${error}`, 
                icon : 'warning', 
                showConfirmButton : false, 
                showCancelButton : false, 
                timer : 1500, 
                onClose : ()=>{
                    props.closeModal();
                }
            })
        })
    }


    return <Container >
        <Divider/>
            <br/>
        <Grid container spacing={2}>
          
            <Grid item xs = {2} align='left'>
                <TextFieldCNPJ 
                    variant = 'outlined'
                    label='CNPJ'
              
                    helperText  = {error && !cnpj ? 'O CNPJ é obrigatório' : ''}
                    error={error}
                    value={cnpj}
                    onChange ={(e)=>setCnpj(e.target.value)}
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                />
            </Grid>
            <Grid item xs = {2} align='left'>
                <TextField 
                    variant = 'outlined'
                    label='Insc. Estadual'
                    value={inscEstadual}
                    onChange ={(e)=>setInscEstadual(e.target.value)}
              
                    variant='outlined'
                    inputProps ={{
                       maxlength : 20
                   }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                />
            </Grid>
            <Grid item xs = {5} align='left'>
                <TextField 
                    variant = 'outlined'
                    value={razaoSocial}
                    label='Razão Social'
                    onChange ={(e)=>setRazaoSocial(e.target.value)}
                    helperText  = {error && !razaoSocial ? 'A Razão Social é obrigatório' : ''}
                    error={error}
              
                    variant='outlined'
                    inputProps ={{
                       maxlength : 150
                   }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                    inputProps={{
                        'size' :'150', 
                        'maxlength' :'150'
                    }}
                />
            </Grid>
            <Grid item xs = {3} align='left'>
                <TextField 
                    variant = 'outlined'
                    value={nomeFantasia}
                    label='Nome Fantasia'
                    onChange ={(e)=>setNomeFantasia(e.target.value)}
                    helperText  = {error && !nomeFantasia ? 'O nome Fantasia é obrigatório' : ''}
                    error={error}
              
                    inputProps={{
                        'size' :'100', 
                        'maxlength' :'100'
                    }}
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                />
            </Grid>
        </Grid>

        <br/>
        <Grid container spacing={2}>

            <Grid item xs = {2} align='left'>
                <TextFieldCep 
                    variant = 'outlined'
                    label='Cep'
                    value={cep}
                    helperText  = {error && !cep ? 'O CEP é obrigatório' : ''}
                    error={error}
                    onChange ={(e)=>setCep(e.target.value)}
              
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                    retornoCep = {(data)=>{
                       setEnderecoByCep(data)
                    }}
                />
            </Grid>
            <Grid item xs = {6} align='left'>
                <TextField 
                    value={endereco}
                    label='Endereço'
                    onChange ={(e)=>setEndereco(e.target.value)}
                    variant = 'outlined'
              
                    variant='outlined'
                    inputProps={{
                        'size' :'100', 
                        'maxlength' :'100'
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                />
            </Grid>
            <Grid item xs = {1} align='left'>
                <TextField 
                    variant = 'outlined'
                    label='Número'
                    value = {numero}
                    onChange ={(e)=>setNumero(e.target.value)}
              
                    variant='outlined'
                    inputProps={{
                        'size' :'8', 
                        'maxlength' :'8'
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                />
            </Grid>
            <Grid item xs = {3} align='left'>
                <TextField 
                    variant = 'outlined'
                    label='Complemento'
                    value = {complemento}
                    onChange ={(e)=>setComplemento(e.target.value)}
              
                    variant='outlined'
                    inputProps={{
                        'size' :'60', 
                        'maxlength' :'60'
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                />
            </Grid>
        </Grid>

        <br/>
        <Grid container spacing={2}>
            
            <Grid item xs = {4} align='left'>
                <TextField 
                    variant = 'outlined'
                    label='Bairro'
                    value = {bairro}
                    onChange ={(e)=>setBairro(e.target.value)}
              
                    variant='outlined'
                    inputProps={{
                        'size' :'60', 
                        'maxlength' :'60'
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                />
            </Grid>
            <Grid item xs = {6} align='left'>
                <TextField 
                    variant = 'outlined'
              
                    label='Cidade'
                    value = {cidade}
                    helperText  = {error && !cidade ? 'A cidade é obrigatória' : ''}
                    error={error}
                    onChange ={(e)=>setCidade(e.target.value)}
                    variant='outlined'
                    inputProps={{
                        'size' :'150', 
                        'maxlength' :'150'
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                />
            </Grid>
            <Grid item xs = {2} align='left'>
                <TextField 
                    variant = 'outlined'
              
                    label='Estado'
                    helperText  = {error && !estado ? 'UF é obrigatório' : ''}
                    error={error}
                    value = {estado}
                    onChange ={(e)=>setEstado(e.target.value)}
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}  
                    inputProps={{
                        'size' :'2', 
                        'maxlength' :'2'
                    }}                 
                    fullWidth
                    size='small'
                />
            </Grid>
        </Grid>
        <br/>
        <Grid container spacing={2}>
            <Grid item xs = {2} align='left'>
                <TextFieldTelefone 
                    variant = 'outlined'
                    label='Telefone Comercial'
                    value = {telefoneComercial}
                    onChange ={(e)=>setTelefoneComercial(e.target.value)}
              
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                />
            </Grid>
            <Grid item xs = {2} align='left'>
                <TextFieldCelular 
                    variant = 'outlined'
                    label='Telefone Celular'
                    value = {telefoneCelular}
                    onChange ={(e)=>setTelefoneCelular(e.target.value)}
              
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                />
            </Grid>

            <Grid item xs = {8} align='left'>
                <TextFieldEmail 
                    variant = 'outlined'
                    value = {email}
                    helperText  = {error && !email ? 'Informe o email de contato' : ''}
                    error={error}
                    onChange ={(e)=>setEmail(e.target.value)}
              
                    variant='outlined'
                    inputProps={{
                        'size' :'250', 
                        'maxlength' :'250'
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small'
                />
            </Grid>
        </Grid>
        <br/>
        <Divider/>
        <br/>
        <Grid container spacing={2}>
        <Grid item xs = {8} align={'right'}></Grid>
            <Grid item xs = {2} align={'right'}>
                <Button size='large' fullWidth color ='secondary' variant='contained' onClick = {()=>{
                    Swal.fire({
                            title : 'Gestão de Fornecedores', 
                            text : 'Deseja cancelar a operação?',
                            icon : 'info',  
                            showCancelButton : true, 
                            showConfirmButton :true, 
                            confirmButtonText : 'Confirmar',
                            cancelButtonText : 'Cancelar' 
                        }).then((result)=>{
                            if(result.isConfirmed){
                                props.closeModal();
                            }
                        })
                }}>Cancelar</Button>
            </Grid>
            <Grid item xs = {2} align='left'>
                <Button size='large' fullWidth  color ='primary' variant='contained' onClick = {()=>{
                    setError(false)
                    if(cnpj != '' && razaoSocial != '' && nomeFantasia != '' && cep != '' && cidade != '' && estado != '' && email != ''){
                        
                        Swal.fire({
                            title : 'Gestão de Fornecedores', 
                            text : 'Deseja cadastrar/alterar o fornecedor atual ?',
                            icon : 'info',  
                            showCancelButton : true, 
                            showConfirmButton :true, 
                            confirmButtonText : 'Confirmar', 
                            cancelButtonText :'Cancelar'
                        }).then((result)=>{
                            if(result.isConfirmed){
                                if(props.edicaoFornecedor.idfornecedor && props.edicaoFornecedor.idfornecedor != ''){
                                    updatefornecedor(); 
                                }else{
                                    insertFornecedor();
                                }
                                
                            }
                        })
                    }else{
                        setError(true)
                        Swal.fire({
                            title : 'Gestão de Fornecedores', 
                            text : 'Campos obrigatórios não preenchidos !', 
                            icon : 'error', 
                            showCancelButton : false, 
                            showConfirmButton :true, 
                            confirmButtonText : 'OK', 
                        }).then((result)=>{
                            if(result.isConfirmed){

                            }
                        })
                    }
                }}>Confirmar</Button>
            </Grid>
        </Grid>
        <br/>
    </Container>
}


export default withRouter(FormNovoFornecedor)