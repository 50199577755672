import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/Toc';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import ModalNovoParametro from './ModalNovoParametro'
import ModalListaItemColeta from './ModalListaItemColeta'
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DisplayOnOff from '../../components/DisplayStatus'


const CadastroTabelaErros = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [erros, setErros] = React.useState([]);
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [parametros, setParametros] = useState([])
    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const refIdModelo = React.createRef();
    const refMarca = React.createRef();
    const refModelo = React.createRef();


    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getParametros()
    }, [])

    useEffect(()=>{
        if(openModal){
           
        }
    }, [openModal])


    const getParametros = () =>{
        Conexao.post('parametrosController/getListaErrosDisponiveis').then(response=>{
            if(response.data.status =='OK'){
                setErros(response.data.payload)
            }else{
                setErros([])
            }
        }).catch(error =>{
            setErros([])
        })
    }

   

    return <Principal atualizar = {loading} getData={()=>getParametros()}>
     <Grid container spacing = {2}>
<Grid item xs = {7}><Typography>Templates Erros - Existe(m) {erros.length} erro(s). </Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
        <Grid item xs = {1}>
                <TextField
                    label ='ID'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}     
                    inputRef={refIdModelo}             
                   />
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Marca'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}     
                    inputRef={refMarca}             
                   />
            </Grid>
            <Grid item xs = {6}>
                <TextField
                    label ='Modelo'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    inputRef={refModelo}                  
                   />
            </Grid>

            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getParametros()}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' ><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Templates Erros</Typography>
            </Breadcrumbs>
        </div> 
        <div className='div-form-container'>
        <Grid container style = {{marginTop:"9px"}} spacing={1}>
            <Table  >
                <TableHead>
                    <TableCell align='left' style = {{width:"10%", fontSize:'10px'}}>Modelo</TableCell>
                    <TableCell align='center' style = {{width:"5%", fontSize:'10px'}}>Código</TableCell>
                    <TableCell align='center' style = {{width:"5%", fontSize:'10px'}}>Grupo</TableCell>  
                    <TableCell align='center' style = {{width:"5%", fontSize:'10px'}}>Local</TableCell>                    
                    <TableCell align='center' style = {{width:"5%", fontSize:'10px'}}>Nivel</TableCell>
                    <TableCell align='center' style = {{width:"5%", fontSize:'10px'}}>Training</TableCell>
                    <TableCell align='left' style = {{width:"45%", fontSize:'10px'}}>Descrição</TableCell>
                    <TableCell align='center' style = {{width:"5%", fontSize:'10px'}}>Manutenção</TableCell>
                    <TableCell align='center' style = {{width:"10%", fontSize:'10px'}}>Consumivel</TableCell>
                    <TableCell align='center' style = {{width:"5%", fontSize:'10px'}}>Suprimento</TableCell>

                </TableHead>
                <TableBody>
                    {
                        erros && erros.map(function(erro, i){
                            return  <TableRow>
                            <TableCell align='left' style = {{ fontSize:'11px'}}>{erro.descricao}</TableCell>
                            <TableCell align='center' style = {{ fontSize:'11px'}}>{erro.prtalertcode}</TableCell>
                            <TableCell align='center' style = {{ fontSize:'11px'}} >{erro.prtalertgroupo}</TableCell>  
                            <TableCell align='center' style = {{ fontSize:'11px'}} >{erro.prtalertlocation}</TableCell>                    
                            <TableCell align='center'  style = {{ fontSize:'11px'}}>{erro.prtalertseveritylevel}</TableCell>
                            <TableCell align='center'  style = {{ fontSize:'11px'}}>{erro.prtalerttraininglevel}</TableCell>
                            <TableCell align='left'  style = {{ fontSize:'11px'}}>{erro.prtalertdescription}</TableCell>
                            <TableCell align='center'  style = {{ fontSize:'11px'}}><DisplayOnOff ativo={erro.alertamanutencao} onclick={()=>{
                                setLoading(true)
                                Conexao.post('parametrosController/updateParametroErro',{
                                    action : 'manutencao', 
                                    codigo : erro.prtalertcode, 
                                    grupo : erro.prtalertgroupo, 
                                    local :erro.prtalertlocation, 
                                    atual : erro.alertamanutencao
                                }).then(response=>{
                                    if(response.data.status =='OK'){
                                        getParametros()
                                    }
                                    setLoading(false)
                                }).catch(error=>{
                                    setLoading(false)
                                });
                            }}/></TableCell>
                            <TableCell align='center'  style = {{ fontSize:'11px'}}><DisplayOnOff ativo={erro.alertaconsumivel} onclick={()=>{

                                setLoading(true)
                                Conexao.post('parametrosController/updateParametroErro',{
                                    action : 'consumivel', 
                                    codigo : erro.prtalertcode, 
                                    grupo : erro.prtalertgroupo, 
                                    local :erro.prtalertlocation, 
                                    atual : erro.alertaconsumivel
                                }).then(response=>{
                                    if(response.data.status =='OK'){
                                        getParametros()
                                    }
                                    setLoading(false)
                                }).catch(error=>{
                                    setLoading(false)
                                });
                            }}/></TableCell>
                            <TableCell align='center'  style = {{ fontSize:'11px'}}><DisplayOnOff ativo={erro.alertasuprimento} onclick={()=>{
                                setLoading(true)
                                Conexao.post('parametrosController/updateParametroErro',{
                                    action : 'suprimento', 
                                    codigo : erro.prtalertcode, 
                                    grupo : erro.prtalertgroupo, 
                                    local :erro.prtalertlocation, 
                                    atual : erro.alertasuprimento
                                }).then(response=>{
                                    if(response.data.status =='OK'){
                                        getParametros()
                                    }
                                    setLoading(false)
                                }).catch(error=>{
                                    setLoading(false)
                                });
                            }}/></TableCell>
                            
                        </TableRow>
                        })
                    }
                   
                  
                </TableBody>
            </Table>
        </Grid>  
        </div>                   
      
       
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroTabelaErros)