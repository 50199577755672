import React,{useEffect, useState, useRef, Fragment} from 'react'
import './Containermapa.css'
import MapView from './MapaView'
import LogoPConnect from './icones/logoHorizontal250px.png'
import {TextField, InputAdornment, Typography, Button} from '@material-ui/core'
import IconeSearchMotorista from '@material-ui/icons/Person'
import IconeSearchCar from '@material-ui/icons/Search'
import SearchIcon from '@material-ui/icons/Search'
import Slider from '@material-ui/core/Slider'
import Switch from '@material-ui/core/Switch'
import IconeDelete from '@material-ui/icons/DeleteForever'
import IconePlay from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'
import ModalSelecionaVeiculo from './ModalSelecionaVeiculo'
import Swal from 'sweetalert2'
import dayjs from 'dayjs';
import InconCalendar from '@material-ui/icons/CalendarToday'
import "react-datepicker/dist/react-datepicker.css";
import { Circle, TileLayer,withLeaflet,  Popup, Marker,FeatureGroup,  Tooltip, ZoomControl, Polygon, Polyline, Map  } from "react-leaflet";
import MeasureControlDefault from 'react-leaflet-measure'; //https://www.npmjs.com/package/react-leaflet-measure
import L from 'leaflet';
import "leaflet/dist/leaflet.css";//https://towardsdatascience.com/creating-a-bubbles-map-using-react-leaflet-e75124ca1cd2
import { point } from 'leaflet';
import {Divider, LinearProgress} from '@material-ui/core';
import Conexao from '../../../providers/Conexao'
import { Icon, DivIcon } from "leaflet";
//import {Marker,} from 'react-leaflet-enhanced-marker' //https://www.npmjs.com/package/react-leaflet-enhanced-marker
import BatteryCharging from '@material-ui/icons/BatteryChargingFull';
import BatteryOff from '@material-ui/icons/BatteryUnknown'
import SinalCelular0 from '@material-ui/icons/SignalCellular0Bar'
import SinalCelular1 from '@material-ui/icons/SignalCellular1Bar'
import SinalCelular2 from '@material-ui/icons/SignalCellular2Bar'
import SinalCelular3 from '@material-ui/icons/SignalCellular3Bar'
import SinalCelular4 from '@material-ui/icons/SignalCellular4Bar'
import CombustivelIcon from '@material-ui/icons/LocalGasStation'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import VeiculoIcon from '@material-ui/icons/DirectionsCar'
import IconeVoltage from '@material-ui/icons/BatteryFullRounded'
import SelectInput from '@material-ui/core/Select/SelectInput'
import MarkerVeiculo from './MarkerVeiculo'
import MenuLaterial from './MenuLateral'
import MenuLateralMotorista from './MenuLateralMotoristas'
import IconeOdometro from '@material-ui/icons/ThreeSixty'
import IconCar from '@material-ui/icons/DirectionsCar'
import IconUser from '@material-ui/icons/Person'
import { LogoNodejs, SpeedometerOutline, FlashOutline, FlashOffOutline, SettingsOutline, KeyOutline , BatteryChargingOutline as CarregandoBateria, BatteryFullOutline as BateriaFull, BatteryHalfOutline as BateriaMeio,BatteryDeadOutline as BateriaZero
 } from 'react-ionicons'



let filtroTempo = [0, 24];


//https://github.com/prabhuignoto/react-chrono#-installation


export default function(props){
    const [opcaoFiltro, setOpcaoFiltro] = useState('veicle');
    const [veiculo, setVeiculo] = useState({
        idusuario : -1, 
        marca : '', 
        modelo : '', 
        placa : '', 
        idrastreador : ''
    })
    const [dataAtual, setDataAtual] = useState('');
    const [filtro, setFiltro] = useState(false);
    const [value, setValue] = useState([0,100]);
    const [hora, setHora]= React.useState(0);
    const [posicoes, setPosicoes] = React.useState(0);
    const [sliderHoras, setSliderHoras]= React.useState([0,24]);
    const [placaVelocidade, setPlacaVelocidade] = useState('placa');
    const [loading, setLoading] = React.useState(false)
    const [tipoRotulo, setTipoRotulo] = React.useState(false)
    const [trajetoVeiculo, setTrajetoVeiculo] = React.useState([]);
    const [address, setAddress] = React.useState([])
    const [contador, setContador]= React.useState(0)
    const [regioes, setRegioes] = React.useState([]);
    const [veiculosMarker, setViculosMarker] = React.useState([]);
    const [centroMapa, setCentroMapa] = React.useState([(props.match.params.latitude && props.match.params.latitude != null) ? props.match.params.latitude : -22.2244322, (props.match.params.longitude && props.match.params.longitude != null) ? props.match.params.longitude : -49.8917842]);
    //const [breakPoints, setBreakPoints] = React.useState([])
    const breakPoints = [];
    const [moveTrajetoVeiculo, setMoveTrajetoVeiculo]= React.useState([]);
    const [positionData, setPositionData] = React.useState([]);

    const [openDetalhes, setOpenDetalhes]= useState(false);
    const [openDrawer, setOpenDrawer]= useState(false);

    const [historicoTrajeto, setHistoricoTrajeto] = React.useState([]);
    const [poline, setPoline] = React.useState([]);

    
    const getDataAtual = () =>{
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        return ano +'-'+  mes + '-'+ dia;
    }
    const handlePositionLine = (posicao)=>{
     
    
    }
 

    React.useEffect(()=>{
     
        // getRegioes()
         getVeiculosByEmpresa(sliderHoras);
         const timer = setInterval(()=>{
           getVeiculosByEmpresa(sliderHoras);
         }, 10000)
 
 
 
         return ()=> clearInterval(timer)
     },[]);


    React.useEffect(()=>{
       
        let agora = new Date().getHours()
        filtroTempo[1] = '24';
        setHora(agora)
       
        setDataAtual(getDataAtual())
  
    }, [])




    React.useEffect(()=>{
       
      if(veiculo && veiculo.placa && veiculo.placa != ''){
        getTrajetoVeiculo(veiculo)
      }
  
    }, [dataAtual])

    

  
    
    

    React.useEffect(()=>{
        console.log(filtroTempo)
   
     }, [filtroTempo])
      const marks = [
        {
        value: 0,
        label: '00:00',
        },
        {
        value: 1,
        label: '01:00',
        },
        {
        value: 2,
        label: '02:00',
        },
        {
        value: 3,
        label: '03:00',
        },
        {
        value: 4,
        label: '04:00',
        },
        {
        value: 5,
        label: '05:00',
        },
        {
        value: 6,
        label: '06:00',
        },
        {
        value: 7,
        label: '07:00',
        },
        {
        value: 8,
        label: '08:00',
        },
        {
        value: 9,
        label: '09:00',
        },
        {
        value: 10,
        label: '10:00',
        },
        {
        value: 11,
        label: '11:00',
        },
        {
        value: 12,
        label: '12:00',
        },
        {
        value: 13,
        label: '13:00',
        },
        {
        value: 14,
        label: '14:00',
        },
        {
        value: 15,
        label: '15:00',
        },
        {
        value: 16,
        label: '16:00',
        },
        {
        value: 17,
        label: '17:00',
        },
        {
        value: 18,
        label: '18:00',
        },
        {
        value: 19,
        label: '19:00',
        },
        {
        value: 20,
        label: '20:00',
        },
        {
        value: 21,
        label: '21:00',
        },
        {
        value: 22,
        label: '22:00',
        },
        {
        value: 23,
        label: '23:00',
        },
        {
        value: 24,
        label: '23:59',
        },

      ];
      const hangeSlider = (event, newValue) => {   
                 
        
        if(newValue[0]> hora){
          //  return;
        }
        if(newValue[1] < newValue[0]){
            return
        }
       
      filtroTempo[0] = newValue[0];
      filtroTempo[1] = newValue[1];
      setSliderHoras([newValue[0], newValue[1]])
     // setHabilitarBotao(true)
     
     
    };


    function getVeiculosByEmpresa(){
      
        Conexao.get('fleetController/getVeiculosByEmpresa?idUsuario='+props.match.params.revenda+'&idEmpresa='+props.idempresa).then(response=>{
          if(response.data.status =='OK'){
            setViculosMarker(response.data.payload)
            if(response.data.payload[0]){   
           
                 
            }
            
          }else{
            setViculosMarker([])
          }
        }).catch(error=>{
          setViculosMarker([])
        })
      }

    function getTrajetoVeiculo(dataVeiculo){
      setLoading(true)
        Conexao.post('veiculosController/getTrajetos?idusuario=', {
            idusuario :props.match.params.revenda , 
            data : dataAtual, 
            horaInicial : sliderHoras[0] , 
            horaFinal : sliderHoras[1], 
            placa : dataVeiculo.placa, 
            imei : dataVeiculo.idrastreador
        }).then(response=>{
            if(response.data.status =='OK'){

                setTrajetoVeiculo(response.data.payload)
               
               
            }else{
                setTrajetoVeiculo([])
            }
            setLoading(false)
          }).catch(error=>{
            setTrajetoVeiculo([])
            setLoading(false)
          })
    }


    function listarPontosTrajeto(){
        setLoading(true)
        Conexao.post('veiculosController/listarPontosTrajeto', {
            idusuario :props.match.params.revenda , 
            data : dataAtual, 
            horainicial : sliderHoras[0] , 
            horafinal : sliderHoras[1],             
            idrastreador : veiculo.idrastreador
        }).then(response=>{
            if(response.data.status =='OK'){

                setHistoricoTrajeto(response.data.payload)
               
               
            }else{
                setHistoricoTrajeto([])
            }
            setLoading(false)
          }).catch(error=>{
            setHistoricoTrajeto([])
            setLoading(false)
          })
    }

    const moveTrajeto = () =>{
       for(let i = 0; i< trajetoVeiculo.length; i++){           
            setTimeout(()=>{
                let temp = moveTrajetoVeiculo;
                if(temp){
                    temp.push(trajetoVeiculo[i])
                    setMoveTrajetoVeiculo(temp)
                    console.log(moveTrajetoVeiculo)
                }
                
            }, 1000)
       }
        
    }

    const renderStateBattery =(carregando) =>{
      if(carregando =='t'){
      return <BatteryCharging />
      }else{
        return <BatteryOff />
      }
      
    }

    const rendernivelBattery =(nivel) =>{      
      switch(nivel){
        case 0:return '0%'
        case 1:return '5%'
        case 2:return '10%'
        case 3:return '25%'
        case 4:return '50%'
        case 5:return '80%'
        case 6:return '100%'
      }
    }

    const renderNivelSinal =(nivel, type = 'icon') =>{   
      if(type == 'icon'){
        switch(nivel){
          case 0:return <SinalCelular0 style ={{color:'red'}}/>
          case 1:return <SinalCelular1 style ={{color:'orangered'}}/>
          case 2:return <SinalCelular2 style ={{color:'orange'}}/>
          case 3:return <SinalCelular3 style ={{color:'green'}}/>
          case 4:return <SinalCelular4 style ={{color:'lime'}}/>
        }
      } else{
        switch(nivel){
          case 0:return <span>Sem sinal</span>
          case 1:return <span>Extremamente fraco</span>
          case 2:return <span>Fraco</span>
          case 3:return <span>Bom</span>
          case 4:return <span>Excelente</span>
        }
      }  
      
    }

    const limeOptions ={color:'#000'}

    return(

        <div id='container-fleet-map'>
            
            <div id='toolbar-maps-fleet'>
              
            <div style ={{width:'12%', paddingTop:'10px', }}>
             
                    <TextField 
                        label='Data Pesquisa'
                        variant='outlined'
                        InputLabelProps={{shrink:true}}
                        type='date'                                             
                        defaultValue={getDataAtual()} 
                        onChange={(e)=>{
                          setDataAtual(e.target.value)
                          setHistoricoTrajeto([])
                          
                          
                        }}                      
                        InputProps={{
                            endAdorment : <InputAdornment position='end'>
                                <InconCalendar />
                            </InputAdornment>
                        }}
                        fullWidth/>

                </div>
                <div style ={{width:'20%', paddingTop:'10px', paddingLeft:'4px' }}>
                    <TextField 
                        label='Pesquisar veículo'
                        variant='outlined'
                        InputLabelProps={{shrink:true}}
                        value={veiculo.placa}
                        InputProps={{
                          readOnly : true, 
                          startAdornment : <InputAdornment position='start'><ModalSelecionaVeiculo setVeiculo={(veiculo)=>{
                            setVeiculo(veiculo)
                            getTrajetoVeiculo(veiculo);
                            if(veiculo.latitude && veiculo.longitude){
                                setCentroMapa([veiculo.latitude, veiculo.longitude])
                                
                            }
                            setHistoricoTrajeto([])
                           
                        }}  usuario={props.match.params.revenda} style={{cursor:'pointer'}}/> </InputAdornment>, 
                        endAdornment : <InputAdornment position='end'><Button   color='secondary' onClick = {()=>{
                          Swal.fire({
                              title : 'Printerconnect - Frota', 
                              text  : 'Deseja limpar a pesquisa?', 
                              icon : 'question', 
                              showConfirmButton : true,
                              showCancelButton : true
                          }).then(resp=>{
                              if(resp.isConfirmed){
                                  setVeiculo({
                                      idusuario : -1, 
                                      marca : '', 
                                      modelo : '', 
                                      placa : '', 
                                      idrastreador : ''
                                  })
                                  setHistoricoTrajeto([])
                              }
                          })
                      }}><IconeDelete/></Button></InputAdornment>
                        
                        }}
                        fullWidth/>
                </div>
               
                <div style ={{width:'58%', paddingLeft:'15px', paddingRight:'15px',paddingTop:'0px'}} className='oculta'>
                        
                        <div >
                            <Typography variant='caption'>Intervalo de pesquisa</Typography>
                            <Slider
                                                               
                                value={sliderHoras}
                                aria-labelledby="vertical-slider"
                                color ='secondary'
                                min={0}
                                max={24}
                                step={1}                                
                                onChange={hangeSlider}
                                marks={marks}
                                classes={'labelMarcadores'}
                                style= {{marginLeft:"5px"}}
                            />
                        </div>

                </div>
                
               
                <div style ={{width:'10%', paddingLeft:'15px', paddingRight:'15px', textAlign:'center'}} className='oculta'>
                <div><Typography variant='caption'>Localizar Motorista</Typography></div>
                    <MenuLateralMotorista clearPesquisaVeiculo = {()=>{
                      setVeiculo({
                        idusuario : -1, 
                        marca : '', 
                        modelo : '', 
                        placa : '', 
                        idrastreador : ''
                      })
                    setHistoricoTrajeto([])
                    }} idusuario={props.match.params.revenda} setTrajeto = {(trajeto)=>{
              
              setHistoricoTrajeto(trajeto)
            }}/>
                </div>
               
            </div>
            {loading && <LinearProgress id='progress-map'/>}
            {/*<MapView 
                posicoes={posicoes} 
                filtroTempo={sliderHoras} 
                dataAtual={dataAtual} 
                veiculo={veiculo} 
                display={placaVelocidade}
                empresa={props.match.params.empresa} 
                revenda={props.match.params.revenda} 
                latitude={props.match.params.latitude} 
                longitude={props.match.params.longitude}/>
            <img src ={LogoPConnect} id='log-pconnect'/>*/
}
<div style ={{height: "100%", marginTop:"0px", width: "100%", }}>
        <Map
          id="modalMap"                        
          animate={true}
          style={{ height: "100%", width: "100%", }}
          minZoom={5}
          zoom={props.defaultZoon ? props.defaultZoon : 12 }                        
          zoomControl={true}           
          maxZoom={18}                                             
          center={centroMapa}    
          onclick={(e)=>{
           handlePositionLine(e.latlng)
           //console.log(e)
          }}    
          >
            <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />


            {
              regioes && regioes.map(function(regiao, i){
                return <Circle
                fillColor={regiao.cor}
                stroke ={false}                
                center = {[regiao.latitude, regiao.longitude]}
                radius = {regiao.raio}
              />
              })
            }

           {
              (veiculo.placa == '' || !veiculo.placa ) && historicoTrajeto.length == 0 && veiculosMarker && veiculosMarker.map(function(veiculo, i){
                return <MarkerVeiculo index={i} veiculo={veiculo} click={()=>setOpenDetalhes(true)}/>
              }) 
            }

{
              (veiculo.placa != '' || veiculo.placa ) && historicoTrajeto.length == 0 && veiculosMarker && veiculosMarker.filter(item => item.idrastreador == veiculo.idrastreador).map(function(veiculo, i){
                return <MarkerVeiculo index={i} veiculo={veiculo} click={()=>setOpenDetalhes(true)}/>
              })
            }

                <FeatureGroup>
 
                {
                  historicoTrajeto && historicoTrajeto.map(function(ponto, i){
                    return <Marker position={[ponto.latitude, ponto.longitude]} key = {'T'+i}
                    icon={L.divIcon({
                      className: 'custom', 
                      iconAnchor :[0, -25], 
                      html: '<div class="'+(ponto.movimento == 0 ? 'div-marker-point': 'div-marker-point-move')+'">'+(historicoTrajeto.length - i)+'</div>'
                      })}
                      >
                          <Popup>
                          <div className ='popup-container-main'>
                              <div className ='popup-container-status'>
                                <div className='popup-container-placa'>{veiculo.placa}</div>
                                <div className='popup-container-ignicao'>
                                <div style ={{width : '20%', textAlign:'center'}}>{renderNivelSinal(ponto.gsmlevel, 'icon')}</div>
                                  <div style ={{width : '80%', textAlign:'left', fontSize:'18px'}}>{renderNivelSinal(ponto.gsmlevel, 'label')}</div>
                                </div>
                                <div className='popup-container-movimento'>
                                  <div style ={{width : '30%', textAlign:'center'}}>{renderStateBattery(ponto.charging)}</div>
                                  <div style ={{width : '70%', textAlign:'left', fontSize:'18px'}}>{rendernivelBattery(veiculo.voltagemexterna)}</div>               
                                </div>              
                              </div>
                                  <div style={{ paddingTop :'5px'}}>
                                  <IconCar size={32}/><Typography variant='caption' style ={{fontWeight:'bold', color:'#404040', fontSize:'16px', paddingTop:'4px', paddingLeft:'5px'}}>{veiculo.marca+' ' +veiculo.modelo}</Typography>
                                  </div>
                                  <div style={{ paddingTop :'5px'}}>
                                  <IconUser /><Typography variant='caption' style ={{fontWeight:'bold', color:'#404040', fontSize:'12px', paddingTop:'4px', paddingLeft:'5px'}}>{veiculo.marca+' ' +veiculo.modelo}</Typography>
                                  </div>
                              <div style={{display:'flex', paddingTop :'5px'}} className='mini-icon'>
                                  <div style ={{width : '10%', paddingLeft:'3px'}}><CalendarIcon /></div>
                                  <div style ={{width : '90%'}}>{ponto.dataposicao+' '+(ponto.horaposicao && ponto.horaposicao.substr(0,8))}</div>
                              </div>
                              <div style={{display:'flex', paddingTop :'5px' }} >
                                <div style={{ width :'25%', textAlign:'center'}} className='mini-icon'>
                                  <KeyOutline   color={ponto.ignicao == 1 ? '#388E3C' : '#B71C1C'}                
                                      height="18px"
                                      width="18px"/><br/><span style={{paddingLeft :'5px', color : ponto.ignicao == 1 ? '#388E3C' : '#B71C1C'}}>{ponto.ignicao == 1 ? 'Ligado' : 'Desligado'}</span>
                                    
                                </div>
                                <div style={{ width :'25%', textAlign:'center'}} className='mini-icon'>
                                  {
                                      ponto.velocidade > 0 ? <FlashOutline   color={'#388E3C'}                  
                                        height="18px"
                                        width="18px"/> : <FlashOffOutline   color={'#0D47A1' }                  
                                        height="18px"
                                        width="18px"/> 
                                    }
                                  <br/><span style={{paddingLeft :'5px', color : ponto.velocidade > 0 ? '#388E3C' : '#0D47A1' }}>{ponto.velocidade > 0 ? ponto.velocidade+' Km/h' : 'Parado'}</span>
                                </div>
                                <div style={{ width :'25%', textAlign:'center'}} className='mini-icon'>
                                  <CombustivelIcon /><br/>
                                  <span>{ponto.consumo} Km/l</span>
                                </div>
                                
                                <div style={{ width :'25%', textAlign:'center'}} className='mini-icon'>
                                  <SpeedometerOutline   color={'#0D47A1' }                  
                                        height="18px"
                                        width="18px"/><br/>
                                  <span>{ponto.odometro} Km(s)</span>
                                </div>
                              </div>
                              
                              <br/>
                              <Divider/>
                                <div style ={{fontSize:'10px', color:'#424242', marginTop:'10px'}}>{loading ? <LinearProgress variant='indeterminate' /> : 
                                  address}</div>
                            </div>
                          </Popup>
                        </Marker>
                  })
                }

                {
                  historicoTrajeto && <Polyline positions={historicoTrajeto.map(function(pos){
                    return [pos.latitude, pos.longitude]
                  })} color="#444" />
                }
                </FeatureGroup>

              
                 
          </Map>
        
        </div>
            <MenuLaterial loading={loading} veiculo={veiculo} listarPontosTrajeto={()=>listarPontosTrajeto()}  posicoesTrajeto={trajetoVeiculo} setTrajeto = {(trajeto)=>{
              
              setHistoricoTrajeto(trajeto)
            }}/>
        </div>
    )
}