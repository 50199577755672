import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {Divider, TextField, Grid, Container, InputAdornment} from '@material-ui/core'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalNovaMarca = (props) => {
  const [open, setOpen] = React.useState(false)
  const [descricao, setDescricao] = React.useState('')


  React.useEffect(()=>{
    if(open){
        setDescricao('')
    }
  }, [open])


  React.useEffect(()=>{
    if(props.edicaoMarca.idmarca){
        setDescricao(props.edicaoMarca.descricao)
    }
  }, [props.edicaoMarca])



  const insertMarcas = () =>{
      if(!descricao && descricao == ''){
          Swal.fire({
              title : 'Printerconnect', 
              icon : 'info',
              text : 'Você precisa informar a descrição !', 
              showCancelButton : false, 
              showConfirmButton : false, 
              timer : 1500
          })
          return false
      }else{
        Swal.fire({
            title : 'Printerconnect', 
            text : 'Deseja Cadastrar/Atualizar a marca agora ?',
            icon :'question', 
            showCancelButton : true,
            cancelButtonText : 'Cancelar', 
            showConfirmButton : true, 
            confirmButtonText:'Confirmar'
        }).then(responsta =>{
            if(responsta.isConfirmed){
                Conexao.post('marcasController/'+(props.edicaoMarca.idmarca ? 'updateMarca' :'insertMarca'), {
                    idusuario : props.match.params.idempresa, 
                    idmarca : props.edicaoMarca.idmarca ?  props.edicaoMarca.idmarca : 0, 
                    descricao
                }).then( response =>{
                    if(response.data.status =='OK'){
                            Swal.fire({
                            title : 'Printerconnect',
                            icon : 'success', 
                            text : 'Marca cadastrada/atualizada com sucesso !', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1000
                            })
                    }else{
                        Swal.fire({
                            title : 'Printerconnect',
                            icon : 'warning', 
                            text : 'Não foi possível cadastrar/atualizar', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1000
                            })
                    }
                    props.updateMarcas();
                    setOpen(false)
                    props.setEdicao();
                }).catch(error =>{
                    Swal.fire({
                        title : 'Printerconnect',
                        icon : 'error', 
                        text : 'Não foi possível cadastrar/atualizar', 
                        showCancelButton : false, 
                        showConfirmButton : false, 
                        timer : 1000
                        })
                        props.setEdicao();
                })
            }
        })
      }
      
      
  }
  return (
    <div>
        <div className='fabIncluir'>
        <Fab size="large" color="primary" aria-label="add" onClick={()=>setOpen(true)} >
          <AddIcon />
        </Fab>
      </div>
     
      <Dialog 
        disableBackdropClick disableEscapeKeyDown hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={()=>setOpen(false)} aria-labelledby="customized-dialog-title" open={open || props.open}>
        <DialogTitle id="customized-dialog-title" onClose={()=>{
            setOpen(false)
            props.setEdicao();
        }}
            >
          Cadastro/Alteração de Marcas
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing= {2}>
                
                <Grid item xs = {12}>
                    <TextField
                        label = 'Descrição'
                        variant='outlined'
                        fullWidth
                        value = {descricao}
                        onChange={(e)=>setDescricao(e.target.value)}
                        InputLabelProps = {{shrink : true}}
                        />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>{
              props.setEdicao();
              setOpen(false)}} variant='contained' color="secondary">
            Cancelar
          </Button>

          <Button autoFocus onClick={()=>{
           
              insertMarcas()
          }} variant='contained'  color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalNovaMarca)