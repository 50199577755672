import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import UsbTwoToneIcon from '@material-ui/icons/UsbTwoTone';
import Fab from '@material-ui/core/Fab';
import Conexao from '../providers/Conexao'
import {Grid, TextField, Divider, InputAdornment, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import AttachFileIcon from '@material-ui/icons/AttachFile';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function IconeLocalizarClienteModal(props) {
  const [open, setOpen] = React.useState(false);
  const [clientes, setClientes]= React.useState([]);
  const [loading, setLoading]= React.useState(false);
  const refFiltroPesquisa = React.useRef();



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

 

  const getClientes = () =>{ 
    setLoading(true)
    Conexao.post('clientesController/buscarCliente', {
      idusuario : props.idusuario, 
      idempresa : props.idempresa, 
      tipoFiltro : 'razaoSocial', 
      filtro : refFiltroPesquisa.current ? refFiltroPesquisa.current.value : '', 
      chaveCollector : props.chaveCollector ? 't' : ''

    }).then(response =>{
        if(response.data.status =='OK'){
            setClientes(response.data.payload);
        }else{
            setClientes([])
        }
        setLoading(false)
    }).catch(error =>{
        setClientes([])
        setLoading(false)
    });
  }
  return (
    <div>
      {
        props.renderButton ? <Button disabled={props.disabled}  style={{height:'100%', }} onClick={handleClickOpen}><SearchIcon /> </Button>:<SearchIcon onClick={handleClickOpen} style = {{cursor:'pointer'}}/>
      }
     
      
      <Dialog 
        maxWidth='md'
        fullWidth
        onClose={handleClose} 
        aria-labelledby="customized-dialog-title" 
        open={open} hideBackdrop 
        disableBackdropClick 
        disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.chaveCollector ? 'Localizar Chave de Instalação ' : 'Localizar Cliente'}
        </DialogTitle>
        <DialogContent dividers style ={{height : '500px'}}>
         <Grid container spacing = {2}>
           
           <Grid item xs = {10}>
             <TextField
                label = 'Filtro'
                variant= 'outlined'
                fullWidth
                InputLabelProps = {{shrink : true}}
                inputRef ={refFiltroPesquisa} />
           </Grid>
           <Grid item xs = {2}>
             <Button color ='primary' onClick={()=>getClientes()} disabled={loading}><SearchIcon /> Pesquisar</Button>
           </Grid>
         </Grid>
         <span style={{marginTop : '10px', fontSize : '9px'}}> * Clique na linha para selecionar o cliente !</span>
         <Table className='table table-striped table-hover table-sm table-small table-scroll' style={{marginTop : '10px'}}>
           <TableHead>
             <TableCell align='center' style ={{width:'10%'}}>Código</TableCell>
             <TableCell align='center' style ={{width:'20%'}}>CNPJ</TableCell>
             <TableCell align='left' style ={{width:'70%'}}>Razão Social</TableCell>
             
          
           </TableHead>

           <TableBody>
             {
               clientes && clientes.map(function(cliente, i){
                 return <TableRow onClick = {()=>{
                  setOpen(false)
                  props.selectCliente(cliente);
                }}>
                 <TableCell align='center' >{cliente.idcliente}</TableCell>
                 <TableCell align='center' >{cliente.cpf_cnpj}</TableCell>
                 <TableCell align='left' >{cliente.razaosocial}</TableCell>
                 
                 
               </TableRow>
               })
             }
           </TableBody>
         </Table>
         
       
        </DialogContent>
       
      </Dialog>
    </div>
  );
}
