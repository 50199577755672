import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Fab from '@material-ui/core/Fab';
import {Grid, TextField, InputAdornment} from '@material-ui/core'
import Swal from 'sweetalert2'
import Conexao from '../../providers/Conexao'
import Search from '@material-ui/icons/Search'
import ModalListErros from './ModeloListaErrosAtivos'
import Build from '@material-ui/icons/Build'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoParametro(props) {
  const [open, setOpen] = React.useState(false);
  const [persistir, setPersistir]= React.useState('f');
  const [tabela, setTabela]= React.useState('');
  const [alertaToner, setAlertaToner]= React.useState('f');
  const [corToner, setCorToner]= React.useState('');
  const [alertaPecas, setAlertaPecas]= React.useState('f');
  const [trocarDescricao, setTrocarDescricao]= React.useState('f');
  const [updateStatusColeta, setUpdateStatusColeta]= React.useState('f');
  const [descricao, setDescricao]= React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
   
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <Build />
      </Button>
      
      <Dialog scroll='body' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open || props.nomeModelo} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastro de Erro
        </DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label='Descrição do alerta'
                        placeholder='Descrição do erro'
                        multiline
                        rows={4}
                        rowsMax={4}
                        fullWidth
                        variant='outlined'
                        value={props.erro.prtalertdescription}
                   
                        InputLabelProps ={{shrink : true}}/>
                </Grid>
            </Grid>
            <Grid container spacing={2}style ={{marginTop:'10px'}}>
                <Grid item xs={3}>
                    <TextField
                        label='Código'
                        placeholder='Código do Erro'
                        fullWidth
                        variant='outlined'
                        value={props.erro.prtalertcode}
                        InputLabelProps ={{shrink : true}}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label='Grupo'
                        placeholder='Grupo do erro'
                        fullWidth
                        variant='outlined'
                        value={props.erro.prtalertgroupo}
                        InputLabelProps ={{shrink : true}}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label='Localização'
                        placeholder='Localização (setor) do erro'
                        fullWidth
                        variant='outlined'
                        value={props.erro.prtalertlocation}
                        InputLabelProps ={{shrink : true}}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label='Nivel'
                        placeholder='Criticidade do erro'
                        fullWidth
                        variant='outlined'
                        value={props.erro.prtalertseveritylevel}
                        InputLabelProps ={{shrink : true}}/>
                </Grid>
              </Grid>
              <Grid container spacing={2} style = {{marginTop:'10px'}}>
                <Grid item xs={3}>
                    <TextField
                        label='Training'
                        placeholder='Nivel de treinamento necessário'
                        fullWidth
                        variant='outlined'
                        value={props.erro.prtalerttraininglevel}
                        InputLabelProps ={{shrink : true}}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label='Importar'                       
                        fullWidth
                        variant='outlined'
                        value={persistir}
                        onChange={(e)=>setPersistir(e.target.value)}
                        InputLabelProps ={{shrink : true}}
                        select 
                        SelectProps={{native : true}}>
                          <option value='f'>Não</option>
                          <option value='t'>Sim</option>
                    </TextField>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label='Tabela'
                        placeholder='Tabela de insersão'
                        fullWidth
                        variant='outlined'
                        value={tabela}
                        onChange={(e)=>setTabela(e.target.value)}
                        InputLabelProps ={{shrink : true}}
                        select 
                        SelectProps={{native : true}}>
                          <option>logAlertasColetas</option>
                          <option>logAlertasSuprimento</option>
                          <option>logAtolamentos</option>
                          <option>logAlertasParts</option>
                          <option>logAlertasManutacao</option>
                    </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2} style = {{marginTop:'10px'}}>
                <Grid item xs={6}>
                    <TextField
                        label='Gerar alerta Toner'                       
                        fullWidth
                        variant='outlined'
                        value={alertaToner}
                        onChange={(e)=>setAlertaToner(e.target.value)}
                        InputLabelProps ={{shrink : true}}
                        select 
                        SelectProps={{native : true}}>
                          <option value='f'>Não</option>
                          <option value='t'>Sim</option>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label='Cor do suprimento?'                       
                        fullWidth
                        variant='outlined'
                        value={corToner}
                        onChange={(e)=>setCorToner(e.target.value)}
                        InputLabelProps ={{shrink : true}}
                        select 
                        SelectProps={{native : true}}>
                          <option value='f'></option>
                          <option value='C'>Ciano</option>
                          <option value='M'>Magenta</option>
                          <option value='Y'>Amarelo</option>
                          <option value='K'>Preto</option>
                    </TextField>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label='Gerar alerta Peças'                       
                        fullWidth
                        variant='outlined'
                        value={alertaPecas}
                        onChange={(e)=>setAlertaPecas(e.target.value)}
                        InputLabelProps ={{shrink : true}}
                        select 
                        SelectProps={{native : true}}>
                          <option value='f'>Não</option>
                          <option value='t'>Sim</option>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label='Sobrescrever Descrição do alerta'                       
                        fullWidth
                        variant='outlined'
                        value={trocarDescricao}
                        onChange={(e)=>setTrocarDescricao(e.target.value)}
                        InputLabelProps ={{shrink : true}}
                        select 
                        SelectProps={{native : true}}>
                          <option value='f'>Não</option>
                          <option value='t'>Sim</option>
                    </TextField>
                </Grid>

            </Grid>
            <Grid container spacing={2} style = {{marginTop:'10px'}}>
                <Grid item xs={12}>
                    <TextField
                        label='Descrição alternativa'
                        placeholder='Descrição do erro'
                        multiline
                        rows={4}
                        rowsMax={4}
                        fullWidth
                        variant='outlined'
                        value={props.erro.prtalertdescription}
                        value={descricao}
                        onChange={(e)=>setDescricao(e.target.value)}
                        InputLabelProps ={{shrink : true}}/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant='contained' color="secondary">
            Cancelar
          </Button>
          <Button autoFocus variant='contained' color='primary' onClick={()=>{
              Swal.fire({
                title : 'Printercollect', 
                text : 'Deseja gravar a informação atual ?', 
                showConfirmButton : true, 
                confirmButtonText : 'Confirmar', 
                showCancelButton : true, 
                cancelButtonText : 'Cancelar', 
                icon : 'question'
              }).then(resposta=>{
                  if(resposta.isConfirmed){
                    Conexao.post('parametrosController/updateErroDisponivel', {
                      codigo : props.erro.prtalertcode, 
                      grupo : props.erro.prtalertgroupo, 
                      local : props.erro.prtalertlocation, 
                      persistir : persistir, 
                      nomeTabela : tabela, 
                      gerarAlertaToner : alertaToner, 
                      corAlerta : corToner, 
                      gerarAlertaPeca : alertaPecas, 
                      updateColeta : updateStatusColeta, 
                      substituirTexto :trocarDescricao , 
                      descricaoAlternativa : descricao

                    }).then(response=>{

                    }).catch(error =>{

                    })
                  }
              })



          }} color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
