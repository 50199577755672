import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {Grid, TextField, InputAdornment, Tooltip, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TextFieldMoeda from '../../../../components/TextFieldMoeda'
import ModalBuscaCliente from '../../../Clientes/ModalBuscaCliente'
import Conexao from '../../../../providers/Conexao'
import Swal from 'sweetalert2';
import IconeGruposFranquia from '@material-ui/icons/AccountTree'
import ModalNovoGrupo from './ModalNovoGrupoFranquia'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditaIcon from '@material-ui/icons/Brush';
import ModalEditarGrupo from './ModalEditarGrupoFranquia'
import ModalNovoTermoES from './ModalNovotermoES'
import IconeTermos from '@material-ui/icons/CompareArrows'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalCadastro(props) {
  const [open, setOpen] = React.useState(false);



  const handleClickOpen = () => {
    setOpen(true);
   
  };
  const handleClose = () => {
    setOpen(false);
  };

 
  return (
    <div>
        <Tooltip title='Termos de instalação e retirada'>
            <Button variant='outlined' color='primary' fullWidth disabled={props.contrato && props.contrato.idtipocontrato != 1} className='default' onClick={handleClickOpen}>
                <div>
                    <div><IconeTermos/></div>
                        <div style ={{fontSize :'11px'}}>Termos</div>
                </div>
            </Button>
        </Tooltip>
      <Dialog fullWidth maxWidth='lg' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableBackdropClick disableEscapeKeyDown hideBackdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Gerenciamento de Termos de Instalação e Retirada
        </DialogTitle>
        <DialogContent dividers style ={{height:'75vh', overflow:'auto', overflowX:'hidden'}}>
            <Table className='table table-sm table-small table-striped table-hover table-bordered'>
                <TableHead>
                    <TableCell align='center' style ={{width:'8%'}}>Número</TableCell>
                    <TableCell align='center' style ={{width:'8%'}}>Operação</TableCell>
                    <TableCell align='center' style ={{width:'8%'}}>Data</TableCell>
                    <TableCell align='center' style ={{width:'5%'}}>Patrimônio</TableCell>
                    <TableCell align='center' style ={{width:'10%'}}>Número Série</TableCell>
                    <TableCell align='left' style ={{width:'25%'}}>Modelo</TableCell>
                    <TableCell align='center' style ={{width:'5%'}}>Status</TableCell>
                    <TableCell align='center' style ={{width:'10%'}}>V Minimo</TableCell>
                </TableHead>

                <TableBody>
                   
                </TableBody>
           
            </Table>
        </DialogContent>
        <DialogActions>
           <ModalNovoTermoES contrato={props.contrato} />
        </DialogActions>
      </Dialog>

     
    </div>
  );
}
