import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Grid, TextField} from '@material-ui/core'
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'


let contador = -1;
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalEdicaoItemColeta(props) {
  const [open, setOpen] = React.useState(false);
  const [camposDisponiveis, setCamposDisponiveis] = React.useState(false);



  const refNome = React.createRef();
  const refValor = React.createRef();
  const refCalculo = React.createRef();
  const refFormula = React.createRef();
  const refAcentos = React.createRef();
  const refVirtual = React.createRef();
  const refValorVirtual = React.createRef();



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.closeModal();
  };

  React.useEffect(()=>{

  },[props.modelo.idmodelo])


  

  React.useEffect(()=>{
    
        if(refNome.current){
            refNome.current.value = props.parametro.nomeparametro
            refValor.current.value = props.parametro.valorparametro
            refNome.current.value = props.parametro.nomeparametro
            refNome.current.value = props.parametro.nomeparametro
        }
        
    
  },[refNome.current])

  

  return (
    <div>
    
    
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" fullWidth maxWidth={'md'} open={props.parametro.idparametro} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edição de parametros (OIDs) de Coleta {props.modelo.descricao}
        </DialogTitle>
        <DialogContent dividers style={{height:"400px"}}>
          <Grid container spacing={2}>
              <Grid item xs = {4}>
                  <TextField  
                    label='Rótulo da informação'
                    fullWidth
                    variant='outlined'
                    defaultValue={props.parametro.nomeparametro}
                    InputLabelProps={{shrink : true}}
                    inputRef={refNome}
                    />
              </Grid>
              <Grid item xs = {8}>
                  <TextField  
                    label='Parametro (OID) de Coleta'
                    fullWidth
                    variant='outlined'
                    defaultValue={props.parametro.valorparametro}
                    inputRef={refValor}
                    InputLabelProps={{shrink : true}}
                    />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={2}>
            <Grid item xs = {2}>
                <TextField  
                        label='Campo calculado ?'
                        fullWidth
                        disabled
                        variant='outlined'
                        inputRef={refCalculo}
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}>
                            {
                                props.parametro.campocalculado == 't' ? <option value='t'>SIM</option>:<option value='f'>NÃO</option>
                            }
                            <option value='f'>NÃO</option>
                            <option value='t'>SIM</option>
                        </TextField>
            </Grid>
            <Grid item xs = {10}>
                    <TextField  
                        label='Formula do Cálculo'
                        fullWidth
                        variant='outlined'
                        inputRef={refFormula}
                        defaultValue={props.parametro.formulacalculo}
                        multiline
                        rows={6}
                        rowsMax={6}
                        InputLabelProps={{shrink : true}}
                        />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={2}>
            <Grid item xs = {4}>
                <TextField  
                        label='Remover acentos do retorno ?'
                        fullWidth
                        variant='outlined'
                        inputRef={refAcentos}
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}>
                            {
                                props.parametro.removeacentos == 't' ? <option value='t'>SIM</option>:<option value='f'>NÃO</option>
                            }
                            <option value='f'>NÃO</option>
                            <option value='t'>SIM</option>
                        </TextField>
            </Grid>
            <Grid item xs = {4}>
                <TextField  
                        label='Este é um campo virtual ?'
                        fullWidth
                        disabled
                        variant='outlined'
                        inputRef={refVirtual}
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}>
                            {
                                props.parametro.campovirtual == 't' ? <option value='t'>SIM</option>:<option value='f'>NÃO</option>
                            }
                            <option value='f'>NÃO</option>
                            <option value='t'>SIM</option>
                        </TextField>
            </Grid>
            <Grid item xs = {4}>
                <TextField  
                        label='Valor Campo Virtual'
                        disabled={refVirtual.current && refVirtual.current.value != 't'}
                        fullWidth
                        variant='outlined'
                        defaultValue={props.parametro.valorvirtual}
                        inputRef={refValorVirtual}
                        InputLabelProps={{shrink : true}}
                       
                        />
            </Grid>
        </Grid>
        <br/>
        <Typography variant='caption'><span>* Formato do retorno "$dados->dados->[nome da variavel]"</span></Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button autoFocus onClick={()=>{
              if(refNome.current.value == 'F' || (refValor.current.value == '' && refFormula.current.value == '') || (refCalculo.current.value =='SIM' && refFormula.current.value == '')){
                Swal.fire({
                    title : 'Printercollect', 
                    text : 'Informe todos os parametros necessários !', 
                    icon : 'error', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1500
                  })
              }else{
              Swal.fire({
                  title : 'Printercollect', 
                  text : 'Deseja gravar as operações ?', 
                  icon : 'question', 
                  showCancelButton : true, 
                  cancelButtonText : 'Cancelar', 
                  showConfirmButton : true, 
                  confirmButtonText : 'Confirmar', 
              }).then(resposta =>{
                  if(resposta.isConfirmed){

                      Conexao.post('parametrosController/updateParametros', {
                          idparametro : props.parametro.idparametro,
                          nomeparametro : refNome.current ? refNome.current.value : '', 
                          valorparametro : refValor.current ? refValor.current.value : '', 
                          campocalculado : refCalculo.current ? refCalculo.current.value : '', 
                          formulacalculo : refFormula.current ? refFormula.current.value : '', 
                          removeacentos : refAcentos.current ? refAcentos.current.value : '', 
                          campovirtual : refVirtual.current ? refVirtual.current.value : '', 
                          valorvirtual : refValorVirtual.current ? refValorVirtual.current.value : ''
                      }).then(response =>{
                            if(response.data.status == 'OK'){
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Parametro inserido com sucesso !', 
                                    icon : 'success', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                                  props.getData();
                                  props.closeModal();
                            }else{
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Não foi possível inserir', 
                                    icon : 'warning', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                            }
                            setOpen(false)
                      }).catch(error =>{
                        Swal.fire({
                            title : 'Printercollect', 
                            text : 'Não Foi possível inserir', 
                            icon : 'success', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1500
                          })
                          setOpen(false)
                      });
                     
                     
                  }
              })
            }
          }} color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
