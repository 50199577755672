import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Container, Button} from '@material-ui/core'
import UsbIcon from '@material-ui/icons/Usb';
import BuildIcon from '@material-ui/icons/Build';
import DevicesIcon from '@material-ui/icons/Devices';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PrintIcon from '@material-ui/icons/Print';
import CheckIcon from '@material-ui/icons/Check';

import CloseIcon from '@material-ui/icons/Close';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id='precos' style = {{paddingLeft:"5%", paddingRight:"5%", display:"flex", justifyContent:"center", minHeight:"90vh", backgroundColor:"#fff"}}>
        <div>
            <div style ={{textAlign:"center"}}><Typography variant='h5'>Planos</Typography></div>
            <br/>
            <AppBar position="center" color="default" elevation={0}>
                <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
                >
                <Tab label="Gestão de Atendimento/Suprimentos" icon={<DevicesIcon />} {...a11yProps(0)} />
                <Tab label="Coleta de Contadores" icon={<UsbIcon />} {...a11yProps(1)} />
                <Tab label="Bilhetagem de Impressão" icon={<PrintIcon />} {...a11yProps(2)} />
                <Tab label="Rastreamento de Frota" icon={<LocalShippingIcon />} {...a11yProps(3)} />
               
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <div className='price-container-main'>
                    <div className='price-container-item'>
                        <div className='price-item-header'>
                            <Typography variant='subtitle1'>Standard</Typography>
                        </div>
                        <div className='price-item-container'>
                            <div style = {{textAlign:"center"}}>
                                <Typography variant='h4'>R$ 99,00</Typography>
                            </div>
                            <hr/>
                            <ul>
                                <li><CheckIcon/> Cadastros de Equipamentos</li>
                                <li><CheckIcon/> Cadastros de Clientes</li>
                                <li><CheckIcon/> Cadastros de Fornecedores</li>
                                <li><CheckIcon/> Cadastros de Técnicos</li>
                                <li><CheckIcon/> Gestão de Contratos</li>
                                <li><CheckIcon/> Gestão de Ordens de Serviço</li>
                                <li><CheckIcon/> Gestão de Regiões</li>
                                <li><CheckIcon/> Portal de Abertura de Chamados</li>
                                <li><CheckIcon/> Portal de Solicitações de Suprimentos</li>
                            </ul>
                        </div>
                        <br/>
                        <div style = {{textAlign:"center"}}>
                            <Button variant='contained' color='secondary'>Entrar em contato</Button>
                        </div>
                        
                        <br/>
                    </div>

                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <div className='price-container-main'>
                    <div className='price-container-item'>
                        <div className='price-item-header'>
                            <Typography variant='subtitle1'>Basic</Typography>
                        </div>
                        <div className='price-item-container'>
                            <div style = {{textAlign:"center"}}>
                                <Typography variant='h4'>R$ 2,00</Typography>
                            </div>
                            <hr/>
                            <ul>
                                <li><CheckIcon/> Coleta de Contadores Gerais</li>
                                <li><CheckIcon/> Coleta de Contadores de Peças</li>
                                <li><CheckIcon/> Alertas Automáticos</li>
                                <li><CheckIcon/> Solicitações Automáticas de Suprimentos</li>
                                <li><CheckIcon/> Alertas de Trocas Prematuras</li>
                                <li><CheckIcon/> Relatório de Contadores Diários</li>
                                <li><CheckIcon/> Relatório de Contadores Mensais</li>
                                <li><CheckIcon/> Relatório de Fechamento Mensal</li>
                            </ul>
                        </div>
                        <br/>
                        <div style = {{padding:"10px"}}>
                            <Typography variant='caption'>*  Valor por equipamento para uma franquia de até 250 equipamentos.</Typography>
                        </div>
                        <div style = {{textAlign:"center"}}>
                            <Button variant='contained' color='secondary'>Entrar em contato</Button>
                        </div>
                        
                        <br/>
                    </div>

                    <div className='price-container-item'>
                        <div className='price-item-header'>
                            <Typography variant='subtitle1'>Professional</Typography>
                        </div>
                        <div className='price-item-container'>
                            <div style = {{textAlign:"center"}}>
                                <Typography variant='h4'>R$ 1,50</Typography>
                            </div>
                            <hr/>
                            <ul>
                                <li><CheckIcon/> Coleta de Contadores Gerais</li>
                                <li><CheckIcon/> Coleta de Contadores de Peças</li>
                                <li><CheckIcon/> Alertas Automáticos</li>
                                <li><CheckIcon/> Solicitações Automáticas de Suprimentos</li>
                                <li><CheckIcon/> Alertas de Trocas Prematuras</li>
                                <li><CheckIcon/> Relatório de Contadores Diários</li>
                                <li><CheckIcon/> Relatório de Contadores Mensais</li>
                                <li><CheckIcon/> Relatório de Fechamento Mensal</li>
                            </ul>
                        </div>
                        <br/>
                        <div style = {{padding:"10px"}}>
                            <Typography variant='caption'>* Valor por equipamento para uma franquia de 501 até 1000 equipamentos.</Typography>
                        </div>
                        <div style = {{textAlign:"center"}}>
                            <Button variant='contained' color='secondary'>Entrar em contato</Button>
                        </div>
                        
                        <br/>
                    </div>


                    <div className='price-container-item'>
                        <div className='price-item-header'>
                            <Typography variant='subtitle1'>Enterprise</Typography>
                        </div>
                        <div className='price-item-container'>
                            <div style = {{textAlign:"center"}}>
                                <Typography variant='h4'>R$ 1,10</Typography>
                            </div>
                            <hr/>
                            <ul>
                                <li><CheckIcon/> Coleta de Contadores Gerais</li>
                                <li><CheckIcon/> Coleta de Contadores de Peças</li>
                                <li><CheckIcon/> Alertas Automáticos</li>
                                <li><CheckIcon/> Solicitações Automáticas de Suprimentos</li>
                                <li><CheckIcon/> Alertas de Trocas Prematuras</li>
                                <li><CheckIcon/> Relatório de Contadores Diários</li>
                                <li><CheckIcon/> Relatório de Contadores Mensais</li>
                                <li><CheckIcon/> Relatório de Fechamento Mensal</li>

                            </ul>
                        </div>
                        <br/>
                        <div style = {{padding:"10px"}}>
                            <Typography variant='caption'>*  Valor por equipamento para uma franquia acima de 1000 equipamentos.</Typography>
                        </div>
                        <div style = {{textAlign:"center"}}>
                            <Button variant='contained' color='secondary'>Entrar em contato</Button>
                        </div>
                        
                        <br/>
                    </div>



                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
            <div className='price-container-main'>
                    <div className='price-container-item'>
                        <div className='price-item-header'>
                            <Typography variant='subtitle1'>Standard</Typography>
                        </div>
                        <div className='price-item-container'>
                            <div style = {{textAlign:"center"}}>
                                <Typography variant='h4'>Consulte</Typography>
                            </div>
                            <hr/>
                            <ul>
                                <li><CheckIcon/> Relatório  por computadores</li>
                                <li><CheckIcon/> Relatório  por impressoras</li>
                                <li><CheckIcon/> Relatório  por usuários</li>
                                <li><CheckIcon/> Relatório  por centros de custo</li>
                                <li><CheckIcon/> Cotas por usuários</li>
                                <li><CheckIcon/> Cotas por impressoras</li>
                                <li><CheckIcon/> Cotas por centros de custo</li>
                            </ul>
                        </div>
                        <br/>
                        <div style = {{textAlign:"center"}}>
                            <Button variant='contained' color='secondary'>Entrar em contato</Button>
                        </div>
                        
                        <br/>
                    </div>

                </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <div className='price-container-main'>
                    <div className='price-container-item'>
                        <div className='price-item-header'>
                            <Typography variant='subtitle1'>OBD II</Typography>
                        </div>
                        <div className='price-item-container'>
                            <div style = {{textAlign:"center"}}>
                                <Typography variant='h4'>R$ 69,00</Typography>
                            </div>
                            <hr/>
                            <ul>
                                <li><CheckIcon/> Mapa Ao Vivo</li>
                                <li><CheckIcon/> Histórico de trajeto</li>
                                <li><CheckIcon/> Cercas virtuais</li>
                                <li><CheckIcon/> Alertas de velocidade</li>
                                <li><CheckIcon/> Relatorios de trajeto</li>
                                <li><CheckIcon/> Consumo de Combustível</li>
                                <li><CloseIcon/> Identificação do motorista (Crachá)</li>
                                <li><CloseIcon/> Bloqueio do veículo</li>
                            </ul>
                        </div>
                        <br/>
                        <div style = {{textAlign:"center"}}>
                            <Button variant='contained' color='secondary'>Entrar em contato</Button>
                        </div>
                        
                        <br/>
                    </div>

                    <div className='price-container-item'>
                        <div className='price-item-header'>
                            <Typography variant='subtitle1'>Standard</Typography>
                        </div>
                        <div className='price-item-container'>
                            <div style = {{textAlign:"center"}}>
                                <Typography variant='h4'>R$ 69,00</Typography>
                            </div>
                            <hr/>
                            <ul>
                                <li><CheckIcon/> Mapa Ao Vivo</li>
                                <li><CheckIcon/> Histórico de trajeto</li>
                                <li><CheckIcon/> Cercas virtuais</li>
                                <li><CheckIcon/> Alertas de velocidade</li>
                                <li><CheckIcon/> Relatorios de trajeto</li>
                                <li><CloseIcon/> Consumo de Combustível</li>
                                <li><CheckIcon/> Identificação do motorista (Crachá)</li>
                                <li><CheckIcon/> Bloqueio do veículo</li>
                            </ul>
                        </div>
                        <br/>
                        <div style = {{textAlign:"center"}}>
                            <Button variant='contained' color='secondary'>Entrar em contato</Button>
                        </div>
                        
                        <br/>
                    </div>

                </div>
            </TabPanel>
           
      </div>
    </div>
  );
}
