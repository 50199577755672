import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Grid, InputAdornment, TextField} from '@material-ui/core'

import { useEffect } from 'react';
import { useState } from 'react';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: "100%",
    width :'100%'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function AbasClientes(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  

  return (
    <div>
      <Tabs
        orientation="horizontal"
        
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
       
      >
        <Tab label="Cadastro" {...a11yProps(0)} />
        <Tab label="Locais" {...a11yProps(1)} />
        <Tab label="Licenças" {...a11yProps(2)} />
        <Tab label="DCA's" {...a11yProps(3)} />
        <Tab label="Novos Equipamentos" {...a11yProps(4)} />
        <Tab label="Configurações" {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={value} index={0} >
      <Grid container spacing={1} style ={{marginTop:"10px"}}>
        <Grid container>
            <Grid item xs = {2} ><Typography variant='subtitle2' style ={{fontWeight :'bold'}}>CNPJ *</Typography></Grid>
            <Grid item xs = {3} >
                <TextField                  
                        required
                        
                        value={props.cliente.cpf_cnpj}                   
                        disabled={props.edicaoClientes && props.edicaoClientes.idcliente }
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2' style ={{fontWeight :'bold'}}>Razão Social *</Typography></Grid>
            <Grid item xs = {10} >
                <TextField                   
                    value={props.cliente.razaosocial} 
                   
                    inputProps={{maxlength :150}} 
                    required
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>
        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2' style ={{fontWeight :'bold'}}>Nome Fantasia *</Typography></Grid>
            <Grid item xs = {6} >
                <TextField                   
                     value={props.cliente.nomefantasia}
                    required
                    inputProps={{maxlength :100}}                    
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2'>CEP</Typography></Grid>
            <Grid item xs = {2} >
            <TextField                    
                    value={props.cliente.cep}          
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' 
                    inputProps={{maxlength :10}}                    
                    
                    />
            </Grid>
        </Grid>
       
 

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2'>Endereço</Typography></Grid>
            <Grid item xs = {6} >
                <TextField  
                    value={props.cliente.logradouro}                                   
                    variant='outlined'
                    inputProps={{maxlength :60}} 
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>
        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2'>Nº</Typography></Grid>
            <Grid item xs = {2} >
                <TextField                                                                            
                   value={props.cliente.numero}    
                    variant='outlined'
                    inputProps ={{
                        maxlength : 10
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2'>Complemento</Typography></Grid>
            <Grid item xs = {4} >
                <TextField                                                                            
                    value={props.cliente.complemento}    
                    variant='outlined'
                    inputProps={{maxlength :30}} 
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>


        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2'>Bairro</Typography></Grid>
            <Grid item xs = {6} >
                <TextField                                                                            
                  value={props.cliente.bairro}    
                    variant='outlined'
                    inputProps={{maxlength :60}} 
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2' style ={{fontWeight :'bold'}}>Cidade *</Typography></Grid>
            <Grid item xs = {8} >
                <TextField                                                                            
                    value={props.cliente.cidade}    
                    required
                    InputLabelProps ={{shrink : true}} 
                    inputProps={{maxlength :150}} 
                    variant='outlined'
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2' style ={{fontWeight :'bold'}}>Estado *</Typography></Grid>
            <Grid item xs = {1} >
                <TextField                                                                            
                    value={props.cliente.estado}    
                     required
                     inputProps={{
                          'size' :'2', 
                          'maxlength' :'2', 
                           maxlength : 2
                      }}
                      variant='outlined'
                     InputLabelProps ={{shrink : true}} 
                    size='small' />
            </Grid>
        </Grid>
        

      
        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2'>Telefone</Typography></Grid>
            <Grid item xs = {2} >
                <TextField                                                                            
                   value={props.cliente.telefonecomercial}    
                    variant='outlined'
                    inputProps ={{
                        maxlength : 16
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2'>Celular</Typography></Grid>
            <Grid item xs = {2} >
                <TextField                                                                            
                   value={props.cliente.telefonecelular}    
                    variant='outlined'
                    inputProps ={{
                        maxlength : 16
                    }}
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>

        <Grid container style ={{marginTop :5}}>
            <Grid item xs = {2} ><Typography variant='subtitle2' style ={{fontWeight :'bold'}}>Email *</Typography></Grid>
            <Grid item xs = {10} >
                <TextField                                                                            
                    clearError={true} 
                    required              
                    value={props.cliente.email}    
                    inputProps ={{
                        maxlength : 250
                    }}
                
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}                   
                    fullWidth
                    size='small' />
            </Grid>
        </Grid>
        </Grid>  
      </TabPanel>
     
    </div>
  );
}
