import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../providers/Conexao'
import IconGo from '@material-ui/icons/KeyboardArrowRight'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Template from '../Template/Template';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import Tooltip from '@material-ui/core/Tooltip';
import DisplayToner from '../../../components/DisplayToner'

import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

let temporizador = null;


const WebClienteAlertasSuprimento = (props)=>{
    const { idcliente, idusuario, idempresa, razaosocial} = props.location.state.cliente;
    const classes = useStyles();
    const [idLeitura, setIdLeitura]= React.useState(-1);
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [registros, setRegistros] = useState([])
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [ alertas, setAlertas] = useState([])
   


    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const refTipoDataPesquisa = React.createRef();
    const refDataInicial = React.createRef();
    const refDataFinal = React.createRef();
    const refPlaca = React.createRef();
    const refNumeroSerie = React.createRef();
    const refModelo = React.createRef();
    const refMarca = React.createRef();
    const [tipoOrdenacao, setTipoOrdenacao] = useState('Serial')
    const refRazaoSocial = React.createRef();
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getData();
    }, [])

    useEffect(()=>{
        temporizador = setInterval(()=>{
            getData();
        }, 120000); 

        return ()=>{
            clearInterval(temporizador)
        }
    }, [])


    useEffect(()=>{
     
    }, [alertas])

    

    const clearForm = () =>{
        refTipoDataPesquisa.current.value  ='COLETA';
        refDataInicial.current.value  ='';
        refDataFinal.current.value  ='';
        refPlaca.current.value  ='';
        refNumeroSerie.current.value  ='';
        refModelo.current.value  ='';
        refMarca.current.value  ='';
        refRazaoSocial.current.value  ='';
    }
   

    const getData = () =>{
        Conexao.post('webClientController/getAlertasSuprimentos', {
            idempresa : idempresa, 
            idusuario : idusuario,
            idcliente : idcliente,            
            numeroserie :refNumeroSerie.current ?  refNumeroSerie.current.value : '' , 
            modelo :refModelo.current ?  refModelo.current.value : '', 
            marca : refMarca.current ?  refMarca.current.value : '', 
            
        }).then(response=>{
            
            if(response.data.status == 'OK' ){
                
                setAlertas(response.data.payload)
                setRegistros(response.data.payload);
            }else{
                setAlertas([])
                setRegistros([])
            }
        }).catch(error =>{
            setRegistros([])
            setAlertas([])
           
            Swal.fire({
                title : 'Printerconnect', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
        });
    }

   
   const renderStatus = (status, ip) =>{
    switch(status){
    case 1:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
            
        case 2:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PowerSettingsNewIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ON LINE</Typography></div>
            
        case 3:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><SettingsPowerIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ENERGY SAVING</Typography></div>
            
        case 4:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PrintIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>IMPRIMINDO</Typography></div>

        case 5:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><ReportProblemIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>OCORRÊNCIA</Typography></div>

        default:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
    }
        
   }

   const renderNivelToner = (nivel, rotulo, descricao, contador) =>{
       if(!nivel){
           nivel = 0;
       }
        switch(rotulo){
            case 'CIANO': return <DisplayToner  
                rotulo ='ciano'                 
                width={"48px"} 
                height={"48px"} 
                color = {"#0d47a1"} 
                percentual={nivel} 
                lineSize={8}  
                contador={contador}
                descricao={descricao}/>

            case 'MAGENTA': return <DisplayToner  
                rotulo ='magenta' 
                width={"48px"} 
                height={"48px"} 
                color = {"#c62828"} 
                percentual={nivel} 
                lineSize={8}  
                contador={contador}
                descricao={descricao}/>

            case 'AMARELO': return <DisplayToner  
                rotulo ='amarelo' 
                width={"48px"} 
                height={"48px"} 
                color = {"#ffb300"} 
                percentual={nivel} 
                lineSize={8}  
                contador={contador}
                descricao={descricao}/>

            case 'PRETO': return <DisplayToner  
                rotulo ='preto' 
                width={"48px"} 
                height={"48px"} 
                color= {"#000"} 
                percentual={nivel} 
                lineSize={8} 
                contador={contador}
                descricao={descricao} />
        }
        
    
   }

   const renderContadores = (dados) =>{
    if(!dados.contadortotalgeralresolucao){
        dados.contadorcolortotalresolucao = dados.contadorcolortotalresolucao ? dados.contadorcolortotalresolucao  : 0;
        dados.contadormonototalresolucao = dados.contadormonototalresolucao ? dados.contadormonototalresolucao  : 0;
        dados.contadortotalgeralresolucao = (dados.contadormonototalresolucao ? dados.contadormonototalresolucao : 0) +(dados.contadorcolortotalresolucao ? dados.contadorcolortotalresolucao : 0);
        return <React.Fragment>
                    <Typography variant='caption' color='primary'><strong>{dados.contadorcolortotalresolucao  } </strong></Typography><br/>
                    <Typography variant='caption' style ={{fontSize:"9px"}}>[{dados.contadormonototalresolucao+' - '+dados.contadormonototalresolucao}]</Typography>
        </React.Fragment>
    }else{
        return <React.Fragment>
            <Typography variant='caption' color='primary'><strong>{dados.contadortotalgeralresolucao  } </strong></Typography><br/>
            <Typography variant='caption' style ={{fontSize:"9px"}}>[{dados.contadormonototalresolucao+' - '+dados.contadorcolortotalresolucao}]</Typography>
        </React.Fragment>
    }
   }
   const rendeTipoOrdenacao = (value = null, tipoPesquisa = 'ordenacao') =>{
      
    switch(tipoOrdenacao){
        
        

        case 'Serial':  
            if(tipoPesquisa == 'ordenacao'){
                return 'Número de Série'
            }else{
                setAlertas(registros.filter(keyword => keyword.numeroserie.toLowerCase().includes(value.toLowerCase())));
            }           
                  
        break;

        case 'Marca': 
            if(tipoPesquisa == 'ordenacao'){
                return 'Marca '
            }else{
                setAlertas(registros.filter(keyword => keyword.marca.toLowerCase().includes(value.toLowerCase())));
            }
                 
        break;

        case 'Modelo': 
            if(tipoPesquisa == 'ordenacao'){
                return 'Modelo'   
            }else{
                setAlertas(registros.filter(keyword => keyword.modelo.toLowerCase().includes(value.toLowerCase())));
            }
              
        break;

        case 'ordenarMedidor': 
            if(tipoPesquisa == 'ordenacao'){
                return 'Medidor' 
            }else{
                setAlertas(registros.filter(keyword => keyword.mediro.toLowerCase().includes(value.toLowerCase())));
            }            
                   
        break;

        case 'ordenarNivelCiano':  
            if(tipoPesquisa == 'ordenacao'){
                return 'Nível Toner Ciano'
            }else{
               // setColetas(registros.filter(keyword => keyword.nivelciano != null &&   keyword.nivelciano.toLowerCase().includes(value.toLowerCase())));
            }           
                  
        break;

        case 'ordenarNivelMagenta':  
            if(tipoPesquisa == 'ordenacao'){
                return 'Nível Toner Magenta'
            }else{
               // setColetas(registros.filter(keyword => keyword.nivelmagenta != null &&  keyword.nivelmagenta.toLowerCase().includes(value.toLowerCase())));
            }           
                    
        break;

        case 'ordenarNivelAmarelo':
            if(tipoPesquisa == 'ordenacao'){
                return 'Nível Toner Amarelo'         
            }else{
                //setColetas(registros.filter(keyword => keyword.nivelamarelo != null &&   keyword.nivelamarelo.toLowerCase().includes(value.toLowerCase())));
            }             
            
        break;

        case 'ordenarNivelPreto':    
            if(tipoPesquisa == 'ordenacao'){
                return 'Nível Toner Preto'  
            }else{
                //setColetas(registros.filter(keyword => keyword.nivelpreto != null &&  keyword.nivelpreto.toLowerCase().includes(value.toLowerCase())));
            }         
                  
        break;
    }    
}


    return <Template atualizar = {loading} cliente={props.location.state.cliente} breadcrumbs ={
        <Breadcrumbs aria-label="breadcrumb">  
            <Typography>Você esta em:</Typography>          
                <Link color="inherit" to={'/webclient/home'} >
                    Home
                </Link>   
                <Typography>Alertas de Suprimento</Typography>       
            </Breadcrumbs>
    }>
        <div style ={{paddingLeft: '30px', paddingRight: '30px', paddingTop : '30px'}}>
       <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Alertas de suprimento baixo</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        
        <Grid container spacing = {2}>

            <Grid item xs = {4}>
                <TextField
                        variant='outlined'
                        fullWidth
                        size='small'
                        inputRef={refNumeroSerie}
                        onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                                
                                rendeTipoOrdenacao(e.target.value, 'pesquisa')
                                
                                
                            }
                        }}
                        InputProps={{
                            startAdornment:(<InputAdornment position="start"><SearchIcon style={{color:'#444'}}/></InputAdornment>), 
                            endAdornment:(<InputAdornment position="end">
                                <Tooltip title ='Buscar dados atualizados...'>
                                    <Button variant='contained'  onClick={()=>{
                                        if(refNumeroSerie.current){
                                            refNumeroSerie.current.value = ''
                                        }
                                        getData()
                                    }}><IconGo style={{color:'#000'}}/></Button>
                                </Tooltip>
                            </InputAdornment>), 
                        }}/>
                        <Typography variant='caption'>Filtro: {tipoOrdenacao} </Typography>
            </Grid>
           
        </Grid>
       
        <br/>
         
        <div className='div-form-container'>
        <Grid spacing={1}>
            <Table className='table table-bordered table-striped table-hover table-sm' >
                <TableHead>
                    <TableCell align='center' style = {{width:"8%"}}>Nível Alerta</TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>Contador</TableCell>
                    <TableCell align='left' style = {{width:"15%", cursor:'pointer'}} onClick={()=>setTipoOrdenacao('Serial')}>Número Série</TableCell>                    
                    <TableCell align='left' style = {{width:"30%", cursor:'pointer'}} onClick={()=>setTipoOrdenacao('Modelo')}>Modelo</TableCell>  
                    <TableCell align='center' style = {{width:"8%"}}>Nivel Trocado</TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>Contador</TableCell>
      
                </TableHead>
                <TableBody>
                {
                    alertas && alertas.map(function(alerta, i){
                        return <TableRow>
                        <TableCell align='center' >                        
                            {renderNivelToner(alerta.nivelabertura, alerta.cor, alerta.numeroserietoner, alerta.dataabertura+' '+alerta.horaabertura.substr(0,8) )}                         
                        </TableCell>
                        <TableCell align='center' >
                        <Typography variant='caption' color='primary'><strong>{alerta.contadortotal  } </strong></Typography><br/>
                        <Typography variant='caption' style ={{fontSize:"9px"}}>[{alerta.totalmono+' - '+alerta.totalcolor}]</Typography>
                        </TableCell>
                        <TableCell align='left' >            
                           
                            <Typography variant='caption'>{alerta.numeroserie}</Typography>
                        </TableCell>                    
                        <TableCell align='left' >            
                            <Typography variant='subtitle2'>{alerta.modelo}</Typography>
                          
                        </TableCell>
                        <TableCell align='center' >                            
                            {alerta.nivelencerramento && <React.Fragment>                                
                            {renderNivelToner(alerta.nivelencerramento,alerta.cor, alerta.numeroserienovotoner , alerta.dataresolucao+' '+alerta.horaresolucao.substr(0,8) )}                           
                            </React.Fragment>}
                        </TableCell>
                        <TableCell align='center' >
                        {
                            alerta.resolvido == 't' && renderContadores(alerta)
                        }
                        </TableCell>
                   
                    </TableRow>
                    })

                }
                </TableBody>
                
                
                <TableBody>
                   
                
                </TableBody>
            </Table>
        </Grid>                     
        </div>
        <div className='fabIncluir'>
           
        </div>
     </div>
    </Template>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(WebClienteAlertasSuprimento)