import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField, InputAdornment} from '@material-ui/core'
import Swal from 'sweetalert2'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';




function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(',/\d/,/\d/,')',  ' ', /\d/,/\d/, /\d/, /\d/, '-',/\d/, /\d/, /\d/,/\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }

  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };


const TextFieldTelefone = (props) =>{
   

   
    return <TextField 
            {...props} 
            InputProps={{
             inputComponent : TextMaskCustom    
            }}                              
            />
}

export default TextFieldTelefone
;
