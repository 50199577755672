import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import SettingsIcon from '@material-ui/icons/Settings';
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import FormNovoFornecedor from './FormNovoFornecedor'
import DisplayStatus from '../../components/DisplayStatus'



const CadastroFornecedores = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [fornecedores, setFornecedores] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const refStatus = React.useRef();
    const refCnpj = React.useRef();
    const refRazaoSocial = React.useRef();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getFonecedores();
    }, [])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])


    const getFonecedores = () =>{
        
        setLoading(true)
        Conexao.post('fornecedoresController/getFornecedores?idusuario='+props.location.state.empresa.idrevenda, {
            status : refStatus.current ? refStatus.current.value : '', 
            cnpj : refCnpj.current ? refCnpj.current.value : '',  
            razaosocial : refRazaoSocial.current ? refRazaoSocial.current.value : '', 
        }).then(response =>{
            
            if(response.status == 200){
               
                if(response.data.status == 'OK'){
                    setFornecedores(response.data.payload);
                    console.log(response.data.payload)
                }else{
                    setFornecedores([])
                }
            }else{
                setFornecedores([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
        })
    }

    const verificaEmpresaAtiva = (id, operacao) =>{
        
       return true;
    }

        
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    


    return <Principal atualizar = {loading}>
         <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Cadastro de Fornecedores</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
            <Grid item xs = {2}>
                <TextField
                    label ='Status'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    select
                    SelectProps={{native : true}}
                    inputRef = {refStatus}
                    >
                    <option>ATIVO</option>
                    <option>INATIVO</option>
                    <option>TODOS</option>
                    </TextField>
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='CNPJ'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}     
                    inputRef = {refCnpj} 
                   />
            </Grid>
            <Grid item xs = {6}>
                <TextField
                    label ='Razão Social'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    inputRef = {refRazaoSocial}         
                   />
            </Grid>
           
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick ={()=>getFonecedores()} ><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>{
                    refStatus.current.value ='ATIVO';
                    refCnpj.current.value = '';
                    refRazaoSocial.current.value = '';
                }} ><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Fornecedores</Typography>
            </Breadcrumbs>
        </div> 
    <div className='div-form-container'>
    <Grid container style = {{marginTop:"10px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-sm'>
            <TableHead>
                <TableCell align='center'>#</TableCell>
                <TableCell align='center'>CNPJ</TableCell>
                <TableCell>Razão Social</TableCell>
                <TableCell align='center'>Cidade</TableCell>
                <TableCell align='center'>Estado</TableCell>
                <TableCell align='center'>Editar</TableCell>
                <TableCell align='center'>Status</TableCell>
            </TableHead>
            <TableBody>
                {
                    fornecedores && fornecedores.map(function(fornecedor, i){
                        return <TableRow id = {'EMP'+i}>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{(i + 1)}</Typography></TableCell>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{fornecedor.cpf_cnpj}</Typography></TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2' color='primary'>{fornecedor.razaosocial}</Typography>
                                    
                                    </TableCell>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{fornecedor.cidade}</Typography></TableCell>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{fornecedor.estado}</Typography></TableCell>
                                    <TableCell align='center'>
                                        <Tooltip title ='Editar informações da Fornecedor'>
                                            <Button onClick = {()=>{
                                                setEdicao(fornecedor)
                                                setOpenModal(true)
                                                handleClose();
                                            }}><BuildTwoToneIcon color='primary'/></Button>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align='center'>


                                        

                                        <Tooltip title = {fornecedor.ativo =='t' ?'Este fornecedor esta ATIVO' : 'Este fornecedor esta INATIVO'}>
                                            <DisplayStatus ativo = {fornecedor.ativo} onclick={()=>{
                                             Swal.fire({
                                                icon : 'info',
                                                text :'Deseja Realmente realizar operação  ?',                  
                                                showCancelButton: true,
                                                confirmButtonText: 'Confirmar',
                                                cancelButtonText : 'Cancelar', 
                                                allowOutsideClick: () => !Swal.isLoading()
                                                }).then((result) => {
                                                if (result.isConfirmed) {
                                                    Conexao.get('fornecedoresController/changeStatus?idfornecedor='+fornecedor.idfornecedor+'&status='+fornecedor.ativo).then(response =>{
                                                        if(response.data.status == 'OK'){                                                        
                                                            getFonecedores()                                                         
                                                                
                                                            //setFornecedores(fornecedores.filter(item => item.idempresa != empresa.idempresa ))
                                                            Swal.fire({
                                                                text:'Operação realizada com sucesso !', 
                                                                icon :'success', 
                                                                timer : 1500, 
                                                                showCancelButton:false, 
                                                                showConfirmButton:false
                                                            })
                                                            }
                                                            
                                                           
                                                       
                                                    }).catch(error =>{

                                                    })
                                                }
                                                })

                                        
                                        }}/>
                                        </Tooltip>                                              
                                    </TableCell>
                                </TableRow>
                    })
                }
                {
                       fornecedores.length == 0  && <TableRow>
                       <TableCell colSpan={7} align='left'>* Não existe(m) registro(s)</TableCell>
                      
                   </TableRow>
                   }
            </TableBody>
        </Table>
    </Grid>
    </div>
    <ModalFull fixedSize={true} titulo = {edicao.idempresa ? 'Edição do Fornecedor : '+edicao.razaosocial :'Cadastro de Novo Fornecedor'} showModal = {openModal} closeModal= {()=>setOpenModal(false)}>
        {<FormNovoFornecedor
            mode={edicao.idempresa ? 'edicao' : 'cadastro'} 
            edicaoFornecedor = {edicao}
            empresa = {props.location.state.empresa.idrevenda} 
            closeModal= {()=>setOpenModal(false)}  
            updatefornecedores= {()=>{
            getFonecedores();
            setOpenModal(false)}}
            />
        }
    </ModalFull>

    <div className='fabIncluir'>
        <Fab size="large" color="primary" aria-label="add" onClick={()=>setOpenModal(true)} >
          <AddIcon />
        </Fab>
    </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroFornecedores)