import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {Grid, TextField, InputAdornment, Tooltip, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TextFieldMoeda from '../../../../components/TextFieldMoeda'
import ModalBuscaCliente from '../../../Clientes/ModalBuscaCliente'
import Conexao from '../../../../providers/Conexao'
import Swal from 'sweetalert2';
import IconeGruposFranquia from '@material-ui/icons/AccountTree'
import ModalNovoGrupo from './ModalNovoGrupoFranquia'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditaIcon from '@material-ui/icons/Brush';
import ModalEditarGrupo from './ModalEditarGrupoFranquia'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalCadastro(props) {
  const [open, setOpen] = React.useState(false);
  const [gruposFranquia, setGruposFranquias] = React.useState([]);
  const [grupoFranquia, setGrupoFranquia] = React.useState([]);


  const handleClickOpen = () => {
    setOpen(true);
    getGruposFranquiaContrato();
  };
  const handleClose = () => {
    setOpen(false);
  };

   const getGruposFranquiaContrato = () =>{
       Conexao.post('contratosController/getGruposFranquiaContrato', {
        idusuario : props.contrato.idusuario, 
        idempresa : props.contrato.idempresa, 
        idcontrato : props.contrato.idcontrato, 
       }).then(response=>{
            if(response.data.status == 'OK'){
                setGruposFranquias(response.data.payload);
            }else{
                setGruposFranquias([]);
            }
       }).catch(error =>{
            setGruposFranquias([]);
       })
   }
  return (
    <div>
        <Tooltip title='Grupos de franquia do contrato atual'>
            <Button variant='outlined' color='primary' fullWidth disabled={props.contrato && props.contrato.idtipocontrato != 1} className='default' onClick={handleClickOpen}>
                <div>
                    <div><IconeGruposFranquia/></div>
                        <div style ={{fontSize :'11px'}}>Franquias</div>
                </div>
            </Button>
        </Tooltip>
      <Dialog fullWidth maxWidth='lg' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableBackdropClick disableEscapeKeyDown hideBackdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Grupos de Franquia Contrato : {props.contrato.nossonumero}
        </DialogTitle>
        <DialogContent dividers style ={{height:'75vh', overflow:'auto', overflowX:'hidden'}}>
            <Table className='table table-sm table-small table-striped table-hover table-bordered'>
                <TableHead>
                    <TableCell align='left' style ={{width:'22%'}}>Descrição</TableCell>
                    <TableCell align='center' style ={{width:'10%'}}>Contador</TableCell>
                    <TableCell align='center' style ={{width:'8%'}}>Franquia</TableCell>
                    <TableCell align='center' style ={{width:'8%'}}>V Franquia</TableCell>
                    <TableCell align='center' style ={{width:'8%'}}>Qtde Mínima</TableCell>
                    <TableCell align='center' style ={{width:'8%'}}>V Minimo</TableCell>
                    <TableCell align='center' style ={{width:'8%'}}>Valor Excedente</TableCell>
                    <TableCell align='center' style ={{width:'8%'}}>Gerar Crédito</TableCell>
                    <TableCell align='center' style ={{width:'5%'}}>Editar</TableCell>
                    <TableCell align='center' style ={{width:'5%'}}>Excluir</TableCell>
                </TableHead>

                <TableBody>
                    {
                        gruposFranquia && gruposFranquia.map(function(grupo, i){
                            return  <TableRow>
                            <TableCell align='left' >{grupo.descricao}</TableCell>
                            <TableCell align='center' >{grupo.contador}</TableCell>
                            <TableCell align='center' >{grupo.quantidadefranquia}</TableCell>
                            <TableCell align='center' >{grupo.valorfranquia}</TableCell>
                            <TableCell align='center' >{grupo.quantidademinima}</TableCell>
                            <TableCell align='center' >{grupo.valorminimo}</TableCell>
                            <TableCell align='center' >{grupo.valorexcedente}</TableCell>
                            <TableCell align='center' >{grupo.gerarcredito =='t' ? 'SIM' : 'NÃO'}</TableCell>
                            <TableCell align='center' >
                                <ModalEditarGrupo grupoFranquia={grupo} updateData={()=>{
                                    getGruposFranquiaContrato();
                                }}/>
                            </TableCell>
                            <TableCell align='center' >
                                <Button variant='contained' color='secondary' onClick={()=>{
                                    Swal.fire({
                                        title : 'Printerconnect', 
                                        text : 'Deseja remover este novo Grupo de Franquia agora? Ele não será removido caso existam equipamentos vinculados a ele!', 
                                        icon :'warning',
                                        showConfirmButton : true, 
                                        confirmButtonText : 'Sim', 
                                        showCancelButton : true, 
                                        cancelButtonText : 'Não'
                                      }).then(resposta=>{
                                          if(!resposta.isConfirmed){
                                              return;
                                          }
                                        Conexao.post('contratosController/removeGruposFranquiaContrato', {
                                            idusuario : props.contrato.idusuario, 
                                            idempresa : props.contrato.idempresa, 
                                            idcontrato : props.contrato.idcontrato,
                                            idgrupofranquia : grupo.idgrupofranquiacontrato,
                                        }).then(response=>{
                                            if(response.data.status =='OK'){
                                                Swal.fire({
                                                    title : 'Printerconnect', 
                                                    text : 'Grupo de franquia removido com sucesso!', 
                                                    icon :'success',
                                                    showConfirmButton : false, 
                                                    confirmButtonText : 'Sim', 
                                                    showCancelButton : false, 
                                                    cancelButtonText : 'Não', 
                                                    timer : 1500, 
                                                   
                                                  })  
                                                  getGruposFranquiaContrato()
                                            }else{
                                                Swal.fire({
                                                    title : 'Printerconnect', 
                                                    text : 'Não foi possível remover!', 
                                                    icon :'warning',
                                                    showConfirmButton : false, 
                                                    confirmButtonText : 'Sim', 
                                                    showCancelButton : false, 
                                                    cancelButtonText : 'Não', 
                                                    timer : 1500
                                                  })  
                                            }
                                        }).catch(error=>{
                                            Swal.fire({
                                                title : 'Printerconnect', 
                                                text : 'Não foi possível remover!', 
                                                icon :'error',
                                                showConfirmButton : false, 
                                                confirmButtonText : 'Sim', 
                                                showCancelButton : false, 
                                                cancelButtonText : 'Não', 
                                                timer : 1500
                                              }) 
                                        })
                                      });
                                }}>
                                    <DeleteIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                        })
                    }
                </TableBody>
           
            </Table>
        </DialogContent>
        <DialogActions>
            <ModalNovoGrupo grupoFranquia={grupoFranquia} contrato = {props.contrato} updateData = {()=>getGruposFranquiaContrato()} closeModal={()=>setGrupoFranquia([])}/>
        </DialogActions>
      </Dialog>

     
    </div>
  );
}
