import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Grid, TextField, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import Conexao from '../../providers/Conexao'
import PowerSettingsNewTwoToneIcon from '@material-ui/icons/PowerSettingsNewTwoTone';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';

import Swal from 'sweetalert2'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ModalEdicaoItemColetaWEB from './ModalEdicaoItemColetaWEB'
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ModalNovoItemColetaWeb from './ModalNovoItemColetaWeb';


let contador = -1;
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalListaItemColetaWeb(props) {
  const [open, setOpen] = React.useState(false);
  const [parametros, setParametros] = React.useState([]);
  const [parametro, setParametro] = React.useState([]);


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setParametros([])
    props.getData();
    props.closeModal()
  };




  const getListaParametros = () =>{
      Conexao.get('parametrosController/getListaParametrosWeb?idmodelo='+props.modeloWeb.idmodelo).then(response =>{
        if(response.data.status =='OK'){
            setParametros(response.data.payload);
        }else{
            setParametros([]);
        }
      }).catch(error =>{
        setParametros([]);
      })
  }

  return (
    <div>
    
      <Dialog 
        onClose={handleClose} 
        aria-labelledby="customized-dialog-title" 
        fullWidth maxWidth={'xl'}
         open={props.modeloWeb.idmodelo} 
         hideBackdrop 
         disableBackdropClick 
         scroll='body'
         disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Lista de Parametros (xPath) do modelo {props.modeloWeb.descricao}
        </DialogTitle>
        <DialogContent dividers >
          <Table>
              <TableHead>
                  <TableCell align='center' style ={{width:"5%"}}>#</TableCell>
                  <TableCell align='left' style ={{width:"10%"}}>Rótulo</TableCell>
                  <TableCell align='left' style ={{width:"10%"}}>Conversão</TableCell>
                  <TableCell align='left' style ={{width:"35%"}}>BaseURL</TableCell>
                  <TableCell align='left' style ={{width:"40%"}}>Valor Parametro</TableCell>
                  <TableCell align='center' style ={{width:"5%"}}>Status</TableCell>
                  <TableCell align='center' style ={{width:"5%"}}>Excluir</TableCell>
              </TableHead>
              <TableBody>
                  {
                      parametros && parametros.map(function(parametro, i){
                          return <TableRow key = {i}>
                            <TableCell align='center'>{(i + 1)}</TableCell>
                          <TableCell align='left' ><Typography variant='subtitle1' style = {{color:"#00f", cursor:"pointer"}} onClick={()=>{
                             Swal.fire({
                              title : 'Printerconnect', 
                              text : 'Deseja alterar este parametro ?',
                              showConfirmButton :true, 
                              confirmButtonText : 'Confirmar', 
                              showCancelButton : true, 
                              cancelButtonText :'Cancelar', 
                              icon:'question'
                            }).then(resposta =>{
                                if(resposta.isConfirmed){
                                  setParametro(parametro);
                                }
                            });
                          }}>{parametro.nomeparametro}</Typography></TableCell>
                          <TableCell align='left' >{parametro.conversao}</TableCell>
                          <TableCell align='left' >{atob(parametro.url)}</TableCell>
                          <TableCell align='left' >{atob(parametro.valorparametro)}</TableCell>
              
                          <TableCell align='center' >{parametro.ativo == 't' ? <PowerSettingsNewTwoToneIcon className='statusAtivo' onClick={()=>{
                            Swal.fire({
                              title : 'Printerconnect', 
                              text : 'Deseja desativar este parametro ?',
                              showConfirmButton :true, 
                              confirmButtonText : 'Confirmar', 
                              showCancelButton : true, 
                              cancelButtonText :'Cancelar', 
                              icon:'question'
                            }).then(resposta =>{
                              if(resposta.isConfirmed){
                                Conexao.get('parametrosController/updateStatusParametroWeb?idParametro='+parametro.idparametro+'&action=desativar').then(response =>{
                                  if(response.data.status == 'OK'){
                                    parametro.ativo = 'f';
                                    getListaParametros();
                                  }else{
                                    Swal.fire({
                                      title : 'Printerconnect', 
                                      text : 'Não foi possível desabilitar',
                                      showConfirmButton :false, 
                                      confirmButtonText : 'Confirmar', 
                                      showCancelButton : false, 
                                      cancelButtonText :'Cancelar',
                                      icon:'warning', 
                                      timer : 1500 
                                    });
                                  }
                                }).catch(error =>{
                                  Swal.fire({
                                    title : 'Printerconnect', 
                                    text : 'Não foi possível desabilitar',
                                    showConfirmButton :false, 
                                    confirmButtonText : 'Confirmar', 
                                    showCancelButton : false, 
                                    cancelButtonText :'Cancelar',
                                    icon:'error', 
                                    timer : 1500 
                                  });
                                });
                              }
                            })
                          }}/> : <ClearIcon className='statusInativo' onClick={()=>{
                            Swal.fire({
                              title : 'Printerconnect', 
                              text : 'Deseja reativar este parametro ?',
                              showConfirmButton :true, 
                              confirmButtonText : 'Confirmar', 
                              showCancelButton : true, 
                              cancelButtonText :'Cancelar', 
                              icon:'question'
                            }).then(resposta =>{
                              if(resposta.isConfirmed){
                                Conexao.get('parametrosController/updateStatusParametroWeb?idParametro='+parametro.idparametro+'&action=ativar').then(response =>{
                                  if(response.data.status == 'OK'){
                                    parametro.ativo = 't';
                                    getListaParametros();
                                  }else{
                                    Swal.fire({
                                      title : 'Printerconnect', 
                                      text : 'Não foi possível reativar',
                                      showConfirmButton :false, 
                                      confirmButtonText : 'Confirmar', 
                                      showCancelButton : false, 
                                      cancelButtonText :'Cancelar',
                                      icon:'warning', 
                                      timer : 1500 
                                    });
                                  }
                                }).catch(error =>{
                                  Swal.fire({
                                    title : 'Printerconnect', 
                                    text : 'Não foi possível reativar',
                                    showConfirmButton :false, 
                                    confirmButtonText : 'Confirmar', 
                                    showCancelButton : false, 
                                    cancelButtonText :'Cancelar',
                                    icon:'error', 
                                    timer : 1500 
                                  });
                                });
                              }
                            })
                          }}/>}</TableCell>
                           <TableCell align='center' > <DeleteForeverIcon className='statusInativo' onClick={()=>{
                            Swal.fire({
                              title : 'Printerconnect', 
                              text : 'Deseja excluir este parametro definitivamente ?',
                              showConfirmButton :true, 
                              confirmButtonText : 'Confirmar', 
                              showCancelButton : true, 
                              cancelButtonText :'Cancelar', 
                              icon:'question'
                            }).then(resposta =>{
                              if(resposta.isConfirmed){
                                Conexao.get('parametrosController/removerParametroWeb?idParametro='+parametro.idparametro).then(response =>{
                                  if(response.data.status == 'OK'){
                   
                                    getListaParametros();
                                  }else{
                                    Swal.fire({
                                      title : 'Printerconnect', 
                                      text : 'Não foi possível excluir !',
                                      showConfirmButton :false, 
                                      confirmButtonText : 'Confirmar', 
                                      showCancelButton : false, 
                                      cancelButtonText :'Cancelar',
                                      icon:'warning', 
                                      timer : 1500 
                                    });
                                  }
                                }).catch(error =>{
                                  Swal.fire({
                                    title : 'Printerconnect', 
                                    text : 'Não foi possível excluir !',
                                    showConfirmButton :false, 
                                    confirmButtonText : 'Confirmar', 
                                    showCancelButton : false, 
                                    cancelButtonText :'Cancelar',
                                    icon:'error', 
                                    timer : 1500 
                                  });
                                });
                              }
                            })
                          }}/> </TableCell>
                      </TableRow>
                      })
                  }
              </TableBody>
          </Table>
        </DialogContent>
        <DialogActions spacing = {5}>
        <ModalNovoItemColetaWeb modeloWeb={props.modeloWeb} getData ={()=>{getListaParametros()}}/>
        <ModalEdicaoItemColetaWEB modelo={props.modeloWeb} getData ={()=>{getListaParametros()}} closeModal = {()=>setParametro([])} parametro={parametro}/>
          <Button autoFocus onClick={handleClose} color="primary">
            Fechar
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
