import React, {useCallback} from 'react'
import Swal from 'sweetalert2'

import {Typography, Tooltip, Button} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

export default function ButtonAtivoInativo(props){

    console.log(props)

    const updateStatus = useCallback((controller, operacao)=>{
        Swal.fire({
            title :'Datacount', 
            icon : 'question',
            text : 'Deseja realmente habilitar/desabilitar este registro ?', 
            showConfirmButton : true, 
            showCancelButton : true, 
            confirmButtonText : 'Confirmar', 
            cancelButtonText : 'Cancelar'
        }).then((resposta)=>{
           if(resposta.isConfirmed){
            props.conexao.get(props.controller+'/'+operacao+'?idempresa='+props.idempresa+'&'+props.identificador+'='+props.chave).then(response=>{
                if(response.data.status =='OK'){
                    Swal.fire({
                        title : 'Datacount', 
                        text:'Operação realizada com sucesso !', 
                        showCancelButton : false, 
                        showConfirmButton : false, 
                        timer  : 1500, 
                        icon : 'success'
                    })
                    props.updateData();
                }else{
                    Swal.fire({
                        title : 'Datacount', 
                        text:'Operação não realizada', 
                        showCancelButton : false, 
                        showConfirmButton : false, 
                        timer  : 1500, 
                        icon : 'warning'
                    })
                }
            }).catch(error=>{
                Swal.fire({
                    title : 'Datacount', 
                    text:'Ocorreu um erro inesperado !', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer  : 1500, 
                    icon : 'error'
                })
            })
           }
        })
           
    }, [])
   

    return props.action != 'enableRegistro' ? 
        <Button variant='outlined' color='primary' style={{backgroundColor: ' #B2DFDB'}} fullWidth onClick = {()=>{
            updateStatus(props.controller,props.action)
        }}><Typography style={{fontSize:"12px", color: '#000'}}>Ativo</Typography></Button> : 
        <Button variant='outlined' color='primary' style={{backgroundColor: ' #FFCDD2'}} fullWidth onClick = {()=>{
            updateStatus(props.controller, props.action)
        }}><Typography style={{fontSize:"12px", color: '#000'}}>Inativo</Typography></Button>
    
}