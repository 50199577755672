import React from 'react'
import './HorizontalNavMenu.css'

export default function HorizontalNav(props){

    return <div id="nav">
    <ul>
        <li><a href="#">Home</a></li>
        <li><a href="#">Tutoriais Web</a>
            <ul className='sombra'>
                <li><a href="#">Programas</a></li>
                <li><a href="#">Internet</a></li>
                <li><a href="#">Videos</a></li>
                <li><a href="#">Acessibilidade</a></li>
            </ul> 
        </li>
        <li><a href="#">Design Web</a>
            <ul className='sombra'>
                <li><a href="#">Photoshop</a></li>
                <li><a href="#">Firework</a></li>
                <li><a href="#">Corel Draw</a></li>
                <li><a href="#">Flash</a></li>
            </ul> 
        </li>
        <li><a href="#">Programação</a>
            <ul className='sombra'>
                <li><a href="#">JAVA</a></li>
                <li><a href="#">Jquery</a></li>
                <li><a href="#">Javascript</a></li>
                <li><a href="#">PHP</a></li>
            </ul>
        </li>
        <li><a href="#">Fale Conosco</a></li>
    </ul>
</div>
}