import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {Divider, TextField, Grid, Container, InputAdornment} from '@material-ui/core'
import TextFieldTelefone from '../../../components/TextFieldTelefone';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalNovoDepartamento = (props) => {
  const [open, setOpen] = React.useState(false)
  
  const [descricao, setDescricao] = React.useState('')
  const [nomeContato, setNomeContato]= React.useState('')
  const [telefoneContato, setTelefoneContato]= React.useState('')
  const [email, setEmail]= React.useState('')
  const [idLocalCliente, setIdLocalCliente]= React.useState(-1)



  React.useEffect(()=>{
      if(open){
        clear()
      }
  }, [open])

  const clear = () =>{
    setDescricao('')
    setNomeContato('')
    setTelefoneContato('')
    setEmail('')
    setIdLocalCliente(-1)
  }


  React.useEffect(()=>{
    if(props.edicao.iddepartamento){
      setDescricao(props.edicao.descricao)
      setTelefoneContato(props.edicao.telefonecontato)
      setNomeContato(props.edicao.nomecontato)
      setEmail(props.edicao.email)
    }
}, [props.edicao.iddepartamento])

  
 

  const processarDepartamento = () =>{
    if(idLocalCliente == -1 && !props.edicao.iddepartamento){
      Swal.fire({
        title :'Cadastro de Departamentos', 
        text :'Você não vinculou este departamento a nenhum local de instalação do cliente ! Deseja continuar ?', 
        icon :'question', 
        showCancelButton : true, 
        showConfirmButton : true,
        confirmButtonText : 'Confirmar', 
        cancelButtonText : 'Cancelar',  
    }).then(resposta =>{
        if(resposta.isConfirmed){
          Conexao.post('departamentosController/'+(props.edicao.iddepartamento ? 'updateDepartamento' : 'insertDepartamento'), {
            idempresa : props.match.params.chaveEmpresa, 
            idcliente : props.idcliente, 
            iddepartamento : props.edicao.iddepartamento ? props.edicao.iddepartamento : 0, 
            descricao : descricao, 
            nomecontato : nomeContato, 
            telefonecontato :telefoneContato, 
            email : email, 
            idlocal : idLocalCliente >= 0 ?  props.listaLocaisCliente[idLocalCliente].idlocal : 0
            
            
           
        }).then( response =>{
            if(response.data.status =='OK'){
                Swal.fire({
                    title :'Cadastro de Departamentos', 
                    text :'Departamento inserido/atualizado com sucesso !', 
                    icon :'success', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 500, 
                })
            }else{
                Swal.fire({
                    title :'Cadastro de Departamentos', 
                    text :'Não foi possível inserir/cadastrar o departamento !', 
                    icon :'warning', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 500, 
                })
            }
            props.closeEdicao()
        clear()
            props.getDepartamentos()
            setOpen(false)
        }).catch( error =>{
            Swal.fire({
                title :'Cadastro de Departamentos', 
                text :'Não foi possível inserir/cadastrar o departamento !', 
                icon :'error', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 500, 
            })
            props.closeEdicao()
        clear()
            setOpen(false)
        });
        }else{
          
          
        }
    })
    }else{
      Conexao.post('departamentosController/'+(props.edicao.iddepartamento ? 'updateDepartamento' : 'insertDepartamento'), {
        idempresa : props.match.params.chaveEmpresa, 
        idcliente : props.idcliente, 
        iddepartamento : props.edicao.iddepartamento ? props.edicao.iddepartamento : 0, 
        descricao : descricao, 
        nomecontato : nomeContato, 
        telefonecontato :telefoneContato, 
        email : email, 
        idlocal : idLocalCliente >= 0 ?  props.listaLocaisCliente[idLocalCliente].idlocal : 0
        
        
       
    }).then( response =>{
        if(response.data.status =='OK'){
            Swal.fire({
                title :'Cadastro de Departamentos', 
                text :'Departamento inserido/atualizado com sucesso !', 
                icon :'success', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 500, 
            })
        }else{
            Swal.fire({
                title :'Cadastro de Departamentos', 
                text :'Não foi possível inserir/cadastrar o departamento !', 
                icon :'warning', 
                showCancelButton : false, 
                showConfirmButton : false, 
                timer : 500, 
            })
        }
        props.getDepartamentos()
        clear()
        props.closeEdicao()
        setOpen(false)
    }).catch( error =>{
        Swal.fire({
            title :'Cadastro de Departamentos', 
            text :'Não foi possível inserir/cadastrar o departamento !', 
            icon :'error', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 500, 
        })
        props.closeEdicao()
        clear()
        setOpen(false)
    });
    }
    
  }
 


  return (
    <div>
        <div className='fabIncluir'>
        <Fab size="large" color="primary" aria-label="add" onClick={()=>setOpen(true)} >
          <AddIcon />
        </Fab>
      </div>
     
      <Dialog 
        disableBackdropClick
        fullWidth
        maxWidth='sm'
        onClose={()=>setOpen(false)} aria-labelledby="customized-dialog-title" open={open || props.edicao.iddepartamento}>
        <DialogTitle id="customized-dialog-title" onClose={()=>{
          props.closeEdicao()
            setOpen(false)
            
        }}
            >
          Cadastro/Alteração de Departamentos
        </DialogTitle>
        <DialogContent dividers >
          {
            !props.edicao.iddepartamento && 
          
        <Grid container spacing= {2}>
                
                <Grid item xs = {12}>
                  <TextField
                          label = 'Local de Instalação (Filial)'
                          variant='outlined'
                          fullWidth
                          InputLabelProps = {{shrink : true}}
                          select
                          SelectProps ={{native : true}}
                          onChange = {(e)=>{
                            setIdLocalCliente(e.target.value)
                          }}
                          >
                            <option value = {-1}></option>
                            {
                              props.listaLocaisCliente && props.listaLocaisCliente.map(function(localCliente, i){
                                return <option id={'optdepto-'+i} value ={i}>{localCliente.logradouro+', '+localCliente.numero +' ('+localCliente.cidade+', '+localCliente.estado+' )'}</option>
                              })
                            }
                  </TextField>
                  </Grid>
              </Grid>}
            <br/>
              <Grid container spacing= {2}>
                
                <Grid item xs = {12}>
                <TextField
                        label = 'Descrição'
                        variant='outlined'
                        fullWidth
                        value={descricao}
                        onChange={(e)=>setDescricao(e.target.value)}
                        InputLabelProps = {{shrink : true}}
                        />
                </Grid>
              </Grid>
            <br/>
            <Grid container spacing= {2}>
                
                <Grid item xs = {5}>
                    <TextFieldTelefone
                        label = 'Telefone Contato'
                        variant='outlined'
                        fullWidth
                        value={telefoneContato}
                        onChange={(e)=>setTelefoneContato(e.target.value)}
                        InputLabelProps = {{shrink : true}}
                        />
                </Grid>

                <Grid item xs = {7}>
                    <TextField
                        label = 'Nome Contato'
                        variant='outlined'
                        fullWidth
                        value={nomeContato}
                        onChange={(e)=>setNomeContato(e.target.value)}
                        InputLabelProps = {{shrink : true}}
                        />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing= {2}>
                
                <Grid item xs = {12}>
                <TextField
                        label = 'Email'
                        variant='outlined'
                        fullWidth
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        InputLabelProps = {{shrink : true}}
                        />
                </Grid>
            </Grid>
            
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>{
             props.closeEdicao()
              setOpen(false)}} variant='outlined' color="secondary">
            Cancelar
          </Button>

          <Button autoFocus onClick={()=>{
              Swal.fire({
                  icon : 'question',
                  title : 'Cadastro de Equipamentos', 
                  text : 'Deseja cadastrar/atualizar este departamento ?', 
                  showConfirmButton : true, 
                  confirmButtonText : 'Confirmar', 
                  showCancelButton : true, 
                  cancelButtonText : 'Cancelar'
              }).then( result =>{
                  if(result.isConfirmed){
                    processarDepartamento()
                  }
              })
              
          }} variant='outlined'  color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalNovoDepartamento)