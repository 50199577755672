import React, { useEffect } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import {TextField,Divider,Button, Container, Grid, Typography} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Conexao from '../../../../providers/Conexao';
import TextFieldEmail from '../../../../components/TextFieldEmail'

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import EditIcon from '@material-ui/icons/Edit';

import WrapTextIcon from '@material-ui/icons/WrapText';
import OpacityIcon from '@material-ui/icons/Opacity';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';




const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalEditarUsuario = (props) => {
  
    const [open, setOpen] = React.useState(false)
    const [nomeCompleto, setNomeCompleto] = React.useState(props.usuario.nomecompleto);
    const [email, setEmail] = React.useState(props.usuario.email);
    const [pin, setPin] = React.useState(props.usuario.pincode);
    const [error, setError] = React.useState(false)

React.useEffect(()=>{
    if(open){
        setNomeCompleto(props.usuario.nomecompleto)
        setEmail(props.usuario.email)
        setPin(props.usuario.pincode)
        setError(false)
    }

},[open])



    const alteraDadosUsuario = () =>{   
      
      setError(false)
      
      if(nomeCompleto == null || email == null || nomeCompleto == '' || email == '') {
        setError(true)
        Swal.fire({
          title : 'Printerconnect',
          icon : 'warning', 
          text : 'É necessário preencher os campos "Nome Completo e E-mail" para Gravar.', 
          showCancelButton : false, 
          showConfirmButton : true, 
          timer : 3000, 
        
          })
         
          
    
        }else{
          
            Conexao.post('userPrinterController/updateUser',{
                idcliente: props.empresa.idcliente,
                idusuario: props.usuario.idusuario,
                idempresa: props.empresa.idempresa,
                nomecompleto: nomeCompleto,  
                email: email,
                pincode: pin  
                    }).then( response =>{
                        if(response.data.status =='OK'){
                            Swal.fire({
                            title : 'Printerconnect',
                            icon : 'success', 
                            text : 'Editado com sucesso !', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 2000, 
                          
                            })                           
                                
                          
                            setOpen(false);
                            props.atualizar();


                            
                    }else{
                        Swal.fire({
                            title : 'Printerconnect',
                            icon : 'warning', 
                            text : 'Não foi possível Editar/Atualizar', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 2000, 
                          
                            })
                    }
                    }).catch(error =>{
                        Swal.fire({
                            title : 'Printerconnect',
                            icon : 'warning', 
                            text : 'Não foi possível Editar/atualizar', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 2000, 
                          
                            })
                    })
                }
              }
            

  return (
    <div>
      <Tooltip title='Alterar Detalhes do Usuário'>
        <EditIcon style={{cursor:'pointer', fontSize: 30, }} color={'primary'} onClick={() => { setOpen(true) }} />
      </Tooltip>
      <Dialog
        hideBackdrop
        disableBackdropClick disableEscapeKeyDown
        fullWidth
        maxWidth='sm'
        onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          {props.titulo}
        </DialogTitle>
        <DialogContent dividers >

          <Grid container spacing={1}>

            <Grid item xs={8}>
              <TextField
                value={props.usuario.username}
                size={'small'}
                label='Username'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}


              />
            </Grid>
            <Grid item xs={4}>
              <TextField
              value={props.usuario.dominio}
                size={'small'}
                label='Domínio'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
            </Grid>
  
            <Grid item xs={12}>
            <br />
            
              <TextField
               value={nomeCompleto}
               onChange={(e)=>setNomeCompleto(e.target.value)}
               error={error && (nomeCompleto == ''|| nomeCompleto == null)  }
                size={'small'}
                label='Nome Completo'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}


              />
            </Grid>
          </Grid>
          <br />
          

          <Grid container spacing={1}>
            <Grid item xs={12}>
            <TextFieldEmail
               value={email}
               error={error && (email == ''|| email == null)  }
               onChange={(e)=>setEmail(e.target.value)}
               size={'small'}
               label='E-mail'
               variant='outlined'
               fullWidth
               InputLabelProps={{ shrink: true }}


             />
            </Grid>
            <Grid item xs={4}>
                <br />
              <TextField
                 value={pin}
                 onChange={(e)=>setPin(e.target.value)}    
                size={'small'}
                label='PIN'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}

              />
            </Grid>
          </Grid>
          
       

        </DialogContent>
        <DialogActions>
       
             <Button color='primary' variant='contained' onClick={()=>{alteraDadosUsuario()}} ><SaveIcon /> Gravar</Button>
            <Button  color='secondary' variant='contained' autoFocus onClick={() => setOpen(false)} ><ClearIcon /> Fechar</Button>
 

        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalEditarUsuario)