import React, {useState, useEffect} from 'react'

import { TextField } from '@material-ui/core'




export default function PRTAlertCode(props){

    return <TextField 
                label='PRTAlertCode RFC 1759'
                select 
                SelectProps={{native:true}}
                variant='outlined'
                {...props}
                fullWidth
                >
        <option value='f'></option>
        <optgroup label='general'>
            <option value='1'>other</option>
            <option value='2'>unknown</option>
        </optgroup>
        <optgroup label='serveral groups'>
            <option value='3'>coverOpen</option>
            <option value='4'>coverClosed</option>
            <option value='5'>interlockOpen</option>
            <option value='6'>interlockClosed</option>
            <option value='7'>configurationChange</option>
            <option value='8'>jam</option>
        </optgroup>
        <optgroup label='general Printer group'>
            <option value='501'>doorOpen</option>
            <option value='502'>doorClosed</option>
            <option value='503'>powerUp</option>
            <option value='504'>powerDown</option>
        </optgroup>
        <optgroup label='Input Group'>
            <option value='801'>inputMediaTrayMissing</option>
            <option value='802'>inputMediaSizeChange</option>
            <option value='803'>inputMediaWeightChange</option>
            <option value='804'>inputMediaTypeChange</option>
            <option value='805'>inputMediaColorChange</option>
            <option value='806'>inputMediaFormPartsChange</option>
            <option value='807'>inputMediaSupplyLow</option>
            <option value='808'>inputMediaSupplyEmpty</option>
        </optgroup>
        <optgroup label='Output Group'>
            <option value='901'>outputMediaTrayMissing</option>
            <option value='902'>outputMediaTrayAlmostFull</option>
            <option value='903'>outputMediaTrayFull</option>
        </optgroup>
        <optgroup label='Marker group'>
            <option value='1001'>markerFuserUnderTemperature</option>
            <option value='1002'>markerFuserOverTemperature</option>
        </optgroup>
        <optgroup label='Marker Supplies group'>
            <option value='1101'>markerTonerEmpty</option>
            <option value='1102'>markerInkEmpty</option>
            <option value='1103'>markerPrintRibbonEmpty</option>
            <option value='1104'>markerTonerAlmostEmpty</option>
            <option value='1105'>markerInkAlmostEmpty</option>
            <option value='1106'>markerPrintRibbonAlmostEmpty</option>
            <option value='1107'>markerWasteTonerReceptacleAlmostFull</option>
            <option value='1108'>markerWasteInkReceptacleAlmostFull</option>
            <option value='1109'>markerWasteTonerReceptacleFull</option>
            <option value='1110'>markerWasteInkReceptacleFull</option>
            <option value='1111'>markerOpcLifeAlmostOver</option>
            <option value='1112'>markerOpcLifeOver</option>
            <option value='1113'>markerDeveloperAlmostEmpty</option>
            <option value='1114'>markerDeveloperEmpty</option>
        </optgroup>
        <optgroup label='Media Path Device Group'>
            <option value='1301'>mediaPathMediaTrayMissing</option>
            <option value='1302'>mediaPathMediaTrayAlmostFull</option>
            <option value='1303'>mediaPathMediaTrayFull</option>
        </optgroup>
        <optgroup label='interpreter Group'>
            <option value='1501'>interpreterMemoryIncrease</option>
            <option value='1502'>interpreterMemoryDecrease</option>
            <option value='1503'>interpreterCartridgeAdded</option>
            <option value='1504'>interpreterCartridgeDeleted</option>
            <option value='1505'>interpreterResourceAdded</option>
            <option value='1506'>interpreterResourceDeleted</option>
            <option value='1507'>interpreterResourceUnavailable</option>
        </optgroup>
    </TextField>
}