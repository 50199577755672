import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Grid, TextField} from '@material-ui/core'
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'


let contador = -1;
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalEdicaoItemColetaWEB(props) {
  const [open, setOpen] = React.useState(false);
  const [camposDisponiveis, setCamposDisponiveis] = React.useState(false);



  const refNome = React.createRef();
  const refUrl = React.createRef();
  const refConversao = React.createRef();
  const refXPath = React.createRef();
  const refAcentos = React.createRef();
  const refVirtual = React.createRef();
  const refUrlVirtual = React.createRef();



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.closeModal();
  };

  React.useEffect(()=>{

  },[props.modelo.idmodelo])


  

  React.useEffect(()=>{
    
        if(refNome.current){
            refNome.current.value = props.parametro.nomeparametro
            refUrl.current.value = props.parametro.valorparametro
            refNome.current.value = props.parametro.nomeparametro
            refNome.current.value = props.parametro.nomeparametro
        }
        
    
  },[refNome.current])

  

  return (
    <div>
    
    
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" fullWidth maxWidth={'lg'} open={props.parametro.idparametro} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edição de parametros (xPath) de Coleta {props.modelo.descricao}
        </DialogTitle>
        <DialogContent dividers >
          <Grid container spacing={2}>
              <Grid item xs = {3}>
                  <TextField  
                    label='Rótulo da informação'
                    fullWidth
                    variant='outlined'
                    defaultValue={props.parametro.nomeparametro }
                    InputLabelProps={{shrink : true}}
                    inputRef={refNome}
                    />
              </Grid>
              <Grid item xs = {9}>
                  <TextField  
                    label='Parametro (URL) de Coleta'
                    fullWidth
                    variant='outlined'
                    defaultValue={props.parametro.url ? atob(props.parametro.url) : ''}
                    inputRef={refUrl}
                    InputLabelProps={{shrink : true}}
                    />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={2}>
            <Grid item xs = {3}>
                <TextField  
                        label='Conversão'
                        fullWidth
                        variant='outlined'
                        inputRef={refConversao}
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}>
                              <option value={props.parametro.conversao}>{props.parametro.conversao}</option>
                              <option value='Nenhuma'>Nenhuma</option>
                              <option value='SomenteNumeros'>SomenteNumeros</option>
                              <option value='RemoverEspeciais'>RemoverEspeciais</option>
                              <option value='ApenasTexto'>ApenasTexto</option>
                              <option value='HexNumber'>HexNumber</option>
                              <option value='HexNumber2'>HexNumber2</option>
                              <option value='arredondarInteiro'>arredondarInteiro</option>
                              <option value='removerEspacos'>removerEspacos</option>
                              <option value='removerTabulacao'>removerTabulacao</option>
                              <option value='impressoesConsumivelSamsung'>impressoesConsumivelSamsung</option>
                              <option value='duracaoConsumivelSamsung'>duracaoConsumivelSamsung</option>
                              <option value='nivelTonerBrotherStep10'>nivelTonerBrotherStep10</option>
                              <option value='nivelTonerBrotherStep20'>nivelTonerBrotherStep20</option>
                              <option value='memoriaFirmwareBrother'>memoriaFirmwareBrother</option>
                              <option value='nivelConsumieisBrother'>nivelConsumieisBrother</option>
                              <option value='textoJSON'>textoJSON</option>
                              <option value='integerJSON'>integerJSON</option>
                              <option value='arredondarInteiroJSON'>arredondarInteiroJSON</option>
                              <option value='removerEspacosJSON'>removerEspacosJSON</option>
                              <option value='removerTabulacaoJSON'>removerTabulacaoJSON</option>
                              <option value='RemoverEspeciaisJSON'>RemoverEspeciaisJSON</option>
                              <option value='ApenasTextoJSON'>ApenasTextoJSON</option>
                              <option value='regularExpression'>regularExpression</option>
                        </TextField>
            </Grid>
            <Grid item xs = {9}>
                    <TextField  
                        label='Caminho xPath'
                        fullWidth
                        variant='outlined'
                        inputRef={refXPath}
                        defaultValue={props.parametro.valorparametro ? atob(props.parametro.valorparametro) : ''}
                        multiline
                        rows={6}
                        rowsMax={6}
                        InputLabelProps={{shrink : true}}
                        />
              </Grid>
          </Grid>
          <br/>
          
        
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button autoFocus onClick={()=>{
              if(refNome.current.value == 'F' || (refUrl.current.value == '' && refXPath.current.value == '') || (refConversao.current.value =='SIM' && refXPath.current.value == '')){
                Swal.fire({
                    title : 'Printercollect', 
                    text : 'Informe todos os parametros necessários !', 
                    icon : 'error', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1500
                  })
              }else{
              Swal.fire({
                  title : 'Printercollect', 
                  text : 'Deseja gravar as operações ?', 
                  icon : 'question', 
                  showCancelButton : true, 
                  cancelButtonText : 'Cancelar', 
                  showConfirmButton : true, 
                  confirmButtonText : 'Confirmar', 
              }).then(resposta =>{
                  if(resposta.isConfirmed){

                      Conexao.post('parametrosController/updateParametrosWeb', {
                          idparametro : props.parametro.idparametro,
                          nomeparametro : refNome.current ? refNome.current.value : '', 
                          url : refUrl.current ? btoa(refUrl.current.value) : '', 
                          conversao : refConversao.current ? refConversao.current.value : '', 
                          valorparametro : refXPath.current ? btoa(refXPath.current.value) : '', 
                         
                      }).then(response =>{
                            if(response.data.status == 'OK'){
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Parametro alterado com sucesso !', 
                                    icon : 'success', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                                  props.getData();
                                  props.closeModal();
                            }else{
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Não foi possível alterar', 
                                    icon : 'warning', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                            }
                            setOpen(false)
                      }).catch(error =>{
                        Swal.fire({
                            title : 'Printercollect', 
                            text : 'Não Foi possível alterar', 
                            icon : 'success', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1500
                          })
                          setOpen(false)
                      });
                     
                     
                  }
              })
            }
          }} color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
