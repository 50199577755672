import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Grid, TextField} from '@material-ui/core'
import ExtensionTwoToneIcon from '@material-ui/icons/WebAssetOutlined';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'


let contador = -1;
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoItemColetaWeb(props) {
  const [open, setOpen] = React.useState(false);
  const [camposDisponiveis, setCamposDisponiveis] = React.useState(false);

  const inicialState = [
    'divider'
,'marca'
,'modelo'
,'numeroSerie'
,'placa'
,'setor'
,'statussistema'
,'statuscopiadora'
,'statusimpressora'
,'statusscanner'
,'sysuptime'
,'mensagempainel'
,'divider'
,'ethernet'
,'macaddress'
,'wireless'
,'macwifi'
,'wifimode'
,'ssid'
,'wifisecurity'
,'wifistatus'
,'smbv1v2'
,'portasmb'
,'statussmb'
,'divider'
,'memoria'
,'documentserver'
,'documentserverfree'
,'harddisc'
,'harddiscfree'
,'harddiscserial'
,'harddiscsize'
,'divider'
,'versaofirmware'
,'subfirmware'
,'xoacommonversion'
,'versaoxoa'
,'versaoup'
,'versaodofirmwareengine'
,'versaodofirmwareui'
,'versaobootrom'
,'versaosistemafich'
,'versaodoscanner'
,'versaofax'
,'versaotabuleiro1'
,'versaotabuleiro2'
,'versaotabuleiro3'
,'versaotabuleiro4'
,'firmwarerede'
,'firmwarenib'
,'firmwareweb'
,'firmwarelanguage'
,'firmwarepjl'
,'firmwarerpcs'
,'firmwarepcl5e'
,'firmwarepclxl'
,'firmwareps3'
,'firmwarepdf'
,'divider'
,'tonercianotype'
,'nivelciano'
,'numeroserieciano'
,'contadorciano'
,'capacidadetonerciano'
,'pagesremainingcian'
,'modelotonerciano'
,'datainstalacaocartuchociano'
,'divider'
,'tonermagentatype'
,'nivelmagenta'
,'numeroseriemagenta'
,'contadormagenta'
,'capacidadetonermagenta'
,'pagesremainingmagenta'
,'modelotonermagenta'
,'datainstalacaocartuchomagenta'
,'divider'
,'toneramarelotype'
,'nivelamarelo'
,'numeroserieamarelo'
,'contadoramarelo'
,'capacidadetoneramarelo'
,'pagesremainingyellow'
,'modelotoneramarelo'
,'datainstalacaocartuchoamarelo'
,'divider'
,'tonerpretotype'
,'nivelpreto'
,'numeroseriepreto'
,'contadorpreto'
,'capacidadetonerpreto'
,'pagesremainingblack'
,'modelotonerpreto'
,'datainstalacaocartuchopreto'
,'divider'
,'modelocilindrociano'
,'nivelcilindrociano'
,'contadorcilindrociano'
,'serialcilindrociano'
,'capacidadecilindrociano'
,'divider'
,'modelocilindromagenta'
,'nivelcilindromagenta'
,'contadorcilindromagenta'
,'serialcilindromagenta'
,'capacidadecilindromagenta'
,'divider'
,'modelocilindroamarelo'
,'nivelcilindroamarelo'
,'contadorcilindroamarelo'
,'serialcilindroamarelo'
,'capacidadecilindroamarelo'
,'divider'
,'modelocilindropreto'
,'nivelcilindropreto'
,'contadorcilindropreto'
,'serialcilindropreto'
,'capacidadecilindropreto'
,'divider'
,'contadorunitlaser'
,'capacidadelaser'
,'nivellaser'
,'contadorunitfusao'
,'capacidadefusao'
,'nivelfusor'
,'contadorunittransferencia'
,'capacidadeunidadetransferencia'
,'nivelunidadetransferencia'
,'nivelreservatoriotonerusado'
,'maintenancekitcurrentlevel'
,'maintenancekitmaxcapacity'
,'contadorroletegavata1'
,'capacidaderoletegavata1'
,'contadorroletegavata2'
,'capacidaderoletegavata2'
,'contadorroletegavata3'
,'capacidaderoletegavata3'
,'contadorroletegavata4'
,'capacidaderoletegavata4'
,'contadorroletebypass'
,'capacidaderoletebypass'
,'contadorroleteardf'
,'capacidaderoleteardf'
,'contadorroletereversoardf'
,'capacidaderoletereversoardf'
,'contadorreversor1'
,'capacidadereversor1'
,'contadorreversor2'
,'capacidadereversor2'
,'contadorreversorbypass'
,'capacidadereversorbypass'
,'usageinkunit'
,'documentfeederkit1'
,'divider'
,'contadormonoa6'
,'contadormonob5'
,'contadormonoa5'
,'contadormonolether'
,'contadormonoa4'
,'contadorMonoA4Especial',
,'contadormonoexecutivo'
,'contadormonooficio'
,'contadormonoa3'
,'contadorMonoA3Especial'
,'divider'
,'contadormonocopia'
,'contadorCopiaMonoA6'
,'contadorCopiaMonoA5'
,'contadorColiaMonoLether'
,'contadorCopiaMonoA4'
,'contadorCopiaMonoA4B'
,'contadorCopiaMonoA3'
,'contadorCopiaMonoA3B'
,'contadorCopiaMonoOutros'
,'divider'
,'contadormonoprinter'
,'contadorPrinterMonoA6'
,'contadorPrinterMonoA5'
,'contadorPrinterMonoLether'
,'contadorPrinterMonoA4'
,'contadorPrinterMonoA4B'
,'contadorPrinterMonoA3'
,'contadorPrinterMonoA3B'
,'contadorPrinterMonoOutros'
,'divider'
,'contadormonofax'
,'contadormonooutros'
,'divider'
,'contadorcolora6'
,'contadorcolorb5'
,'contadorcolora5'
,'contadorcolorlether'
,'contadorcolora4'
,'contadorColorA4Especial'
,'contadorcolorexecutivo'
,'contadorcoloroficio'
,'contadorcolora3'
,'contadorColorA3Especial'
,'divider'
,'contadorcolorcopia'
,'contadorCopiaColorA6'
,'contadorCopiaColorA5'
,'contadorCopiaColorLether'
,'contadorCopiaColorA4'
,'contadorCopiaColorA4B'
,'contadorCopiaColorA3'
,'contadorCopiaColorA3B'
,'contadorCopiaColorOutros'
,'divider'
,'contadorcolorprinter'
,'contadorPrinterColorA6'
,'contadorPrinterColorA5'
,'contadorPrinterColorLether'
,'contadorPrinterColorA4'
,'contadorPrinterColorA4B'
,'contadorPrinterColorA3'
,'contadorPrinterColorA3B'
,'contadorPrinterColorOutros'
,'divider'
,'contadorcolorfax'
,'contadorcoloroutros'
,'contadorcopiacorintegral'
,'contadorcopiacorunica'
,'contadorcopiaduascores'
,'contadorprintercorintegral'
,'contadorprintercopiacorunica'
,'contadorprintercopiaduascores'
,'faxcorunica'
,'divider'
,'contadormonoscanner'
,'contadorcolorscanner'
,'divider'
,'contadortotalgeral'
,'contadortotalscanner'
,'contadormonototal'
,'contadorcolortotal'
,'contadorprinttotal'
,'contadorcopiatotal'
,'contadorfaxtotal'
,'divider'
,'contadorrelatorios'
,'duplex'
,'a3dlt'
,'ciclosmecanismo'
,'ciclosmecanismocolor'
,'divider'
,'contadorcentimetros'
,'contadorpolegadas'
,'contadormetros'
,'divider'
,'personalizado1'
,'personalizado2'
,'personalizado3'
,'personalizado4'
,'personalizado5'
,'personalizado6'
,'personalizado7'
,'personalizado8'
,'personalizado9'
,'personalizado10'
,'personalizado11'
,'personalizado12'
,'personalizado13'
,'personalizado14'
,'personalizado15'
,'personalizado16'
,'personalizado17'
,'personalizado18'
,'personalizado19'
,'personalizado20'
,'texto1'
,'texto2'
,'texto3'
,'texto4'
,'texto5'
,'texto6'
,'texto7'
,'texto8'
,'texto9'
,'texto10'
,'texto11'
     ];

  const gruposOption = [
    'PARAMETROS GERAIS'
,'marca'
,'modelo'
,'numeroSerie'
,'placa'
,'setor'
,'statussistema'
,'statuscopiadora'
,'statusimpressora'
,'statusscanner'
,'sysuptime'
,'mensagempainel'
,'REDES E CONEXOES'
,'ethernet'
,'macaddress'
,'wireless'
,'macwifi'
,'wifimode'
,'ssid'
,'wifisecurity'
,'wifistatus'
,'smbv1v2'
,'portasmb'
,'statussmb'
,'MEMORIA E ARMAZENAMENTO'
,'memoria'
,'documentserver'
,'documentserverfree'
,'harddisc'
,'harddiscfree'
,'harddiscserial'
,'harddiscsize'
,'FIRMWARE'
,'versaofirmware'
,'subfirmware'
,'xoacommonversion'
,'versaoxoa'
,'versaoup'
,'versaodofirmwareengine'
,'versaodofirmwareui'
,'versaobootrom'
,'versaosistemafich'
,'versaodoscanner'
,'versaofax'
,'versaotabuleiro1'
,'versaotabuleiro2'
,'versaotabuleiro3'
,'versaotabuleiro4'
,'firmwarerede'
,'firmwarenib'
,'firmwareweb'
,'firmwarelanguage'
,'firmwarepjl'
,'firmwarerpcs'
,'firmwarepcl5e'
,'firmwarepclxl'
,'firmwareps3'
,'firmwarepdf'
,'TONER CIANO'
,'tonercianotype'
,'nivelciano'
,'numeroserieciano'
,'contadorciano'
,'capacidadetonerciano'
,'pagesremainingcian'
,'modelotonerciano'
,'datainstalacaocartuchociano'
,'TONVER MAGENTA'
,'tonermagentatype'
,'nivelmagenta'
,'numeroseriemagenta'
,'contadormagenta'
,'capacidadetonermagenta'
,'pagesremainingmagenta'
,'modelotonermagenta'
,'datainstalacaocartuchomagenta'
,'TONER AMARELO'
,'toneramarelotype'
,'nivelamarelo'
,'numeroserieamarelo'
,'contadoramarelo'
,'capacidadetoneramarelo'
,'pagesremainingyellow'
,'modelotoneramarelo'
,'datainstalacaocartuchoamarelo'
,'TONER PRETO'
,'tonerpretotype'
,'nivelpreto'
,'numeroseriepreto'
,'contadorpreto'
,'capacidadetonerpreto'
,'pagesremainingblack'
,'modelotonerpreto'
,'datainstalacaocartuchopreto'
,'CILINDRO CIANO'
,'modelocilindrociano'
,'nivelcilindrociano'
,'contadorcilindrociano'
,'serialcilindrociano'
,'capacidadecilindrociano'
,'CILINDRO MAGENTA'
,'modelocilindromagenta'
,'nivelcilindromagenta'
,'contadorcilindromagenta'
,'serialcilindromagenta'
,'capacidadecilindromagenta'
,'CILINDRO AMARELO'
,'modelocilindroamarelo'
,'nivelcilindroamarelo'
,'contadorcilindroamarelo'
,'serialcilindroamarelo'
,'capacidadecilindroamarelo'
,'CILINDRO PRETO'
,'modelocilindropreto'
,'nivelcilindropreto'
,'contadorcilindropreto'
,'serialcilindropreto'
,'capacidadecilindropreto'
,'SUPRIMENTOS E CONSUMIVEIS'
,'contadorunitlaser'
,'capacidadelaser'
,'nivellaser'
,'contadorunitfusao'
,'capacidadefusao'
,'nivelfusor'
,'contadorunittransferencia'
,'capacidadeunidadetransferencia'
,'nivelunidadetransferencia'
,'nivelreservatoriotonerusado'
,'maintenancekitcurrentlevel'
,'maintenancekitmaxcapacity'
,'contadorroletegavata1'
,'capacidaderoletegavata1'
,'contadorroletegavata2'
,'capacidaderoletegavata2'
,'contadorroletegavata3'
,'capacidaderoletegavata3'
,'contadorroletegavata4'
,'capacidaderoletegavata4'
,'contadorroletebypass'
,'capacidaderoletebypass'
,'contadorroleteardf'
,'capacidaderoleteardf'
,'contadorroletereversoardf'
,'capacidaderoletereversoardf'
,'contadorreversor1'
,'capacidadereversor1'
,'contadorreversor2'
,'capacidadereversor2'
,'contadorreversorbypass'
,'capacidadereversorbypass'
,'usageinkunit'
,'documentfeederkit1'
,'CONTADORES MONOCROMATICOS'
,'contadormonoa6'
,'contadormonob5'
,'contadormonoa5'
,'contadormonolether'
,'contadormonoa4'
,'contadorMonoA4Especial',
,'contadormonoexecutivo'
,'contadormonooficio'
,'contadormonoa3'
,'contadorMonoA3Especial'
,'CONTADORES DE COPIA MONOCROMATICO'
,'contadormonocopia'
,'contadorCopiaMonoA6'
,'contadorCopiaMonoA5'
,'contadorColiaMonoLether'
,'contadorCopiaMonoA4'
,'contadorCopiaMonoA4B'
,'contadorCopiaMonoA3'
,'contadorCopiaMonoA3B'
,'contadorCopiaMonoOutros'
,'CONTADORES PRINTER MONOCROMATICO'
,'contadormonoprinter'
,'contadorPrinterMonoA6'
,'contadorPrinterMonoA5'
,'contadorPrinterMonoLether'
,'contadorPrinterMonoA4'
,'contadorPrinterMonoA4B'
,'contadorPrinterMonoA3'
,'contadorPrinterMonoA3B'
,'contadorPrinterMonoOutros'
,'OUTROS CONTADORES MONOCROMATICOS'
,'contadormonofax'
,'contadormonooutros'
,'CONTADORES COLORIDOS'
,'contadorcolora6'
,'contadorcolorb5'
,'contadorcolora5'
,'contadorcolorlether'
,'contadorcolora4'
,'contadorColorA4Especial'
,'contadorcolorexecutivo'
,'contadorcoloroficio'
,'contadorcolora3'
,'contadorColorA3Especial'
,'CONTADORES COPIA COLOR'
,'contadorcolorcopia'
,'contadorCopiaColorA6'
,'contadorCopiaColorA5'
,'contadorCopiaColorLether'
,'contadorCopiaColorA4'
,'contadorCopiaColorA4B'
,'contadorCopiaColorA3'
,'contadorCopiaColorA3B'
,'contadorCopiaColorOutros'
,'CONTADORES PRINTER COLOR'
,'contadorcolorprinter'
,'contadorPrinterColorA6'
,'contadorPrinterColorA5'
,'contadorPrinterColorLether'
,'contadorPrinterColorA4'
,'contadorPrinterColorA4B'
,'contadorPrinterColorA3'
,'contadorPrinterColorA3B'
,'contadorPrinterColorOutros'
,'OUTROS CONTADORES COLOR'
,'contadorcolorfax'
,'contadorcoloroutros'
,'contadorcopiacorintegral'
,'contadorcopiacorunica'
,'contadorcopiaduascores'
,'contadorprintercorintegral'
,'contadorprintercopiacorunica'
,'contadorprintercopiaduascores'
,'faxcorunica'
,'CONTADORES SCANNER'
,'contadormonoscanner'
,'contadorcolorscanner'
,'CONTADORES TOTAIS'
,'contadortotalgeral'
,'contadortotalscanner'
,'contadormonototal'
,'contadorcolortotal'
,'contadorprinttotal'
,'contadorcopiatotal'
,'contadorfaxtotal'
,'OUTROS CONTADORES DIVERSOS'
,'contadorrelatorios'
,'duplex'
,'a3dlt'
,'ciclosmecanismo'
,'ciclosmecanismocolor'
,'CONTADORES LINEARES'
,'contadorcentimetros'
,'contadorpolegadas'
,'contadormetros'
,'CAMPOS PERSONALIZADOS'
,'personalizado1'
,'personalizado2'
,'personalizado3'
,'personalizado4'
,'personalizado5'
,'personalizado6'
,'personalizado7'
,'personalizado8'
,'personalizado9'
,'personalizado10'
,'personalizado11'
,'personalizado12'
,'personalizado13'
,'personalizado14'
,'personalizado15'
,'personalizado16'
,'personalizado17'
,'personalizado18'
,'personalizado19'
,'personalizado20'
,'texto1'
,'texto2'
,'texto3'
,'texto4'
,'texto5'
,'texto6'
,'texto7'
,'texto8'
,'texto9'
,'texto10'
,'texto11'
    ];

  const refRotulo = React.createRef();
  const refbaseUrl = React.createRef();
  const refXpath = React.createRef();
  const refConversao = React.createRef();





  const handleClickOpen = () => {
   
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(()=>{
    setCamposDisponiveis(inicialState);
  },[props.modeloWeb.idmodelo])
  React.useEffect(()=>{
    if(!open){
        props.getData();
    }
  },[open])



  return (
    <div>
    
    <Button color ='primary' onClick = {handleClickOpen}><ExtensionTwoToneIcon color ='primary'/> Lançar novo parametro</Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" fullWidth maxWidth={'md'} open={open} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastro de Novo Parametros (xPath) de coleta web {props.modeloWebscricao}
        </DialogTitle>
        <DialogContent dividers style={{height:"300px"}}>
          <Grid container spacing={2}>
              <Grid item xs = {4}>
                  <TextField  
                    label='Rótulo da informação'
                    fullWidth
                    variant='outlined'
                    InputLabelProps={{shrink : true}}
                    select
                    inputRef={refRotulo}
                    
                    SelectProps={{native : true}}>
                        <option value = 'F'>Selecione...</option>
                        
                        {
                            camposDisponiveis && camposDisponiveis.map(function(campo, i){
                           
                            return camposDisponiveis[i] =='divider' ? <optgroup label={gruposOption[i]}/>: <option >{camposDisponiveis[i]}</option>
                            })
                        }
                    </TextField>
              </Grid>
              <Grid item xs = {8}>
                  <TextField  
                    label='URL Base da informação'
                    placeholder='URL Base da informação'
                    fullWidth
                    variant='outlined'
                    inputRef={refbaseUrl}
                    InputLabelProps={{shrink : true}}
                    />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={2}>            
            <Grid item xs = {12}>
                    <TextField  
                        label='xPath da informação (Utilize o navegador para conseguir)'
                        fullWidth
                        placeholder={'Ex : //*[@id="UsagePage.EquivalentImpressionsTable.Fax.Total"]'}
                        variant='outlined'
                        inputRef={refXpath}
                        multiline
                        rows={3}
                        rowsMax={3}
                        InputLabelProps={{shrink : true}}
                        />
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={2}>
            <Grid item xs = {8}>
                <TextField  
                        label='Conversão a ser utilizada (deve ter sido previamente testada)'
                        fullWidth
                        variant='outlined'
                        inputRef={refConversao}
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}>
                             <option value='Nenhuma'>Nenhuma</option>
                              <option value='SomenteNumeros'>SomenteNumeros</option>
                              <option value='RemoverEspeciais'>RemoverEspeciais</option>
                              <option value='ApenasTexto'>ApenasTexto</option>
                              <option value='HexNumber'>HexNumber</option>
                              <option value='HexNumber2'>HexNumber2</option>
                              <option value='arredondarInteiro'>arredondarInteiro</option>
                              <option value='removerEspacos'>removerEspacos</option>
                              <option value='removerTabulacao'>removerTabulacao</option>
                              <option value='impressoesConsumivelSamsung'>impressoesConsumivelSamsung</option>
                              <option value='duracaoConsumivelSamsung'>duracaoConsumivelSamsung</option>
                              <option value='nivelTonerBrotherStep10'>nivelTonerBrotherStep10</option>
                              <option value='nivelTonerBrotherStep20'>nivelTonerBrotherStep20</option>
                              <option value='memoriaFirmwareBrother'>memoriaFirmwareBrother</option>
                              <option value='nivelConsumieisBrother'>nivelConsumieisBrother</option>

                              <option value='textoJSON'>textoJSON</option>
                              <option value='integerJSON'>integerJSON</option>
                              <option value='arredondarInteiroJSON'>arredondarInteiroJSON</option>
                              <option value='removerEspacosJSON'>removerEspacosJSON</option>
                              <option value='removerTabulacaoJSON'>removerTabulacaoJSON</option>
                              <option value='RemoverEspeciaisJSON'>RemoverEspeciaisJSON</option>
                              <option value='ApenasTextoJSON'>ApenasTextoJSON</option>
                              <option value='regularExpression'>regularExpression</option>
                              
                        </TextField>
            </Grid>
            
            
        </Grid>
       
       
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus color='primary' variant='contained' onClick={()=>{
              if(refRotulo.current.value == '' || (refbaseUrl.current.value == '' ) || (refXpath.current.value =='' && refConversao.current.value == '')){
                Swal.fire({
                    title : 'Printercollect', 
                    text : 'Informe todos os parametros necessários !', 
                    icon : 'error', 
                    showCancelButton : false, 
                    showConfirmButton : false, 
                    timer : 1500
                  })
              }else{
              Swal.fire({
                  title : 'Printercollect', 
                  text : 'Deseja gravar as operações ?', 
                  icon : 'question', 
                  showCancelButton : true, 
                  cancelButtonText : 'Cancelar', 
                  showConfirmButton : true, 
                  confirmButtonText : 'Confirmar', 
              }).then(resposta =>{
                  if(resposta.isConfirmed){
                    const parametros ={
                      idmodelo : props.modeloWeb.idmodelo, 
                      nomeparametro : refRotulo.current ? refRotulo.current.value : '', 
                      baseurl : refbaseUrl.current ? btoa(refbaseUrl.current.value) : '', 
                      valorparametro : refXpath.current ? btoa(refXpath.current.value) : '', 
                      conversao : refConversao.current ? refConversao.current.value : '', 
                    }
                   
                      Conexao.post('parametrosController/insertParametrosWeb', {
                         ...parametros

                      }).then(response =>{
                            if(response.data.status == 'OK'){
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Parametro inserido com sucesso !', 
                                    icon : 'success', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                            }else{
                                Swal.fire({
                                    title : 'Printercollect', 
                                    text : 'Não foi possível inserir', 
                                    icon : 'warning', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                            }
                            setOpen(false)
                      }).catch(error =>{
                        Swal.fire({
                            title : 'Printercollect', 
                            text : 'Não Foi possível inserir', 
                            icon : 'success', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1500
                          })
                          setOpen(false)
                      });
                     
                     
                  }
              })
            }
          }} >
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
