import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SettingsIcon from '@material-ui/icons/Settings';
import IconMarca from '@material-ui/icons/Apps'
import {Table, TableHead, TableBody, TableRow, TableCell, TextField} from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/DeleteForever'
import NovaMarca from './ModalNovaMarca'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [marcas, setMarcas] = React.useState([])
    const [idMarca, setIdMarca] = React.useState(-1)
    const [descricao, setDescricao] = React.useState('')

    const handleClickOpen = () =>{
        getMarcas()
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }

    const getMarcas = () =>{
        setLoading(true)
        Conexao.get('veiculosController/getMarcas?idusuario='+props.usuario).then(response=>{
            if(response.data.status =='OK'){
                setMarcas(response.data.payload)
            }else{
                setMarcas([])
            }
            setLoading(false)
        }).catch(error=>{
            setMarcas([])
            setLoading(false)
        })
    }
  return (
    <div>
    
      <Tooltip title='Cadastrar uma nova marca'>           
        <Fab size='small' onClick={handleClickOpen} style={{cursor:'pointer'}}>
            <IconMarca color='primary' />
        </Fab>
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Cadastro de Marcas
        </DialogTitle>
        <DialogContent dividers style = {{height:"350px", overflow:"auto"}}>
         <Table className='table table-sm table-small table-striped table-hover' >
            <TableHead>
                <TableCell align='center' style  ={{width :'20%'}}>ID</TableCell>
                <TableCell style  ={{width :'70%'}}>Descrição</TableCell>
                <TableCell align='center' style  ={{width :'5%'}}>Editar</TableCell>
                <TableCell align='center' style  ={{width :'5%'}}>Excluir</TableCell>
            </TableHead>
            <TableBody>
                {
                    marcas && marcas.map(function(marca, i){
                        return <TableRow key ={i}>
                            <TableCell align='center'>{i+1}</TableCell>
                            <TableCell>{idMarca == marca.idmarca ? <TextField
                                fullWidth value={descricao} onChange={(e)=>setDescricao(e.target.value)}/> : marca.descricao}</TableCell>
                            <TableCell align='center'>{idMarca == marca.idmarca ? <Button variant='contained' color='primary' onClick={()=>{
                                Swal.fire({
                                    title : 'Printerconnect - Frota', 
                                    text : 'Deseja gravar as alterações?', 
                                    icon :'question', 
                                    showConfirmButton : true, 
                                    showCancelButton:true, 
                                }).then(resposta=>{
                                    if(resposta.isConfirmed){
                                        setLoading(true)
                                        Conexao.post('veiculosController/updateMarca', {
                                            idusuario : props.usuario, 
                                            idmarca : marca.idmarca, 
                                            descricao : descricao
                                        }).then(response=>{
                                            if(response.data.status =='OK'){
                                                getMarcas();
                                                setIdMarca(-1)
                                                setDescricao('')
                                            }
                                        }).catch(error =>{

                                        })
                                    }
                                })
                            }}><SaveIcon/></Button> : <SettingsIcon color='primary' style ={{cursor:'pointer'}} onClick={()=>{
                                setIdMarca(marca.idmarca)
                                setDescricao(marca.descricao)
                            }}/>}</TableCell>
                            <TableCell align='center'>{idMarca == marca.idmarca ? <Button variant='contained' color='secondary' onClick={()=>{
                                Swal.fire({
                                    title : 'Printerconnect - Frota', 
                                    text : 'Deseja descartar as alterações?', 
                                    icon :'question', 
                                    showConfirmButton : true, 
                                    showCancelButton:true, 
                                }).then(resposta=>{
                                    if(resposta.isConfirmed){
                                        
                                        setIdMarca(-1)
                                        setDescricao('')
                                    }
                                })
                            }}><CancelIcon/></Button> : <DeleteIcon color='secondary' style ={{cursor:'pointer'}} onClick={()=>{
                                Swal.fire({
                                    title : 'Printerconnect - Frota', 
                                    text : 'Deseja realmente excluir?', 
                                    icon :'question', 
                                    showConfirmButton : true, 
                                    showCancelButton:true, 
                                }).then(resposta=>{
                                    if(resposta.isConfirmed){
                                        if(marcas[i].modelos.length > 0){
                                            Swal.fire({
                                                title : 'Printerconnect - Frota', 
                                                text : `Não é possível excluir, esta marca esta vinculada a ${marcas[i].modelos.length} modelo(s).`, 
                                                icon :'error',
                                                timer : 1500 
                                            })
                                        }else{
                                            setLoading(true)
                                            Conexao.post('veiculosController/deleteMarca', {
                                                idusuario : props.usuario, 
                                                idmarca : marca.idmarca
                                            }).then(response=>{
                                                if(response.data.status =='OK'){
                                                    getMarcas();
                                                    
                                                }
                                            }).catch(error =>{
    
                                            })
                                        }

                                    }
                                })
                            }}/>}</TableCell>
                        </TableRow>
                    })
                }
            </TableBody>
         </Table>
        </DialogContent>
        <DialogActions>
            <NovaMarca disabled={loading} usuario={props.usuario} getMarcas = {()=>getMarcas()}/>
            <Button disabled={loading} autoFocus onClick={handleClose} variant='contained' color="secondary">
                Cancelar
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalPadrao)