import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { SketchPicker,   } from 'react-color'
import BrushIcon from '@material-ui/icons/Brush'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalSelecionaCor = (props) => {
    const [backgroundColor, setBackgroundColor]= React.useState('#000')
    const [open, setOpen]= React.useState(false)


    const handleColorBackground = (color) => {
       
      setBackgroundColor(color.hex )
      props.setCor(color.hex);
    };

  return (
    <div>
      <Tooltip title='Selecionar uma empresa cadastrada'>
        <BrushIcon  color="primary"  onClick={()=>setOpen(true)} style={{cursor:'pointer'}}/>
         
      </Tooltip>
      <Dialog 
        hideBackdrop
        disableBackdropClick disableEscapeKeyDown
        onClose={()=>setOpen(false)}
        aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">
          Selecionar Color
        </DialogTitle>
        <DialogContent dividers style = {{height:"350px", overflow:"auto"}}>
            <SketchPicker color={backgroundColor} onChangeComplete={ handleColorBackground } />
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={()=>{
              
              setOpen(false)}
              }>
            Selecionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalSelecionaCor)