// paginationUtils.js

export function paginateArray(data, itemsPerPage, currentPage) {
  const startIndex = (currentPage ) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageItems = data.slice(startIndex, endIndex);
  return currentPageItems;
}

export function calculateTotalPages(data, itemsPerPage) {
  return Math.ceil(data.length / itemsPerPage);
}

export function nextPage(currentPage) {
  return currentPage + 1;
}

export function previousPage(currentPage) {
  return currentPage > 1 ? currentPage - 1 : currentPage;
}

export function ordenarDataColeta(data, posicao = 'asc') {
  if(posicao == 'asc'){
    return data.sort((a, b)=>{
      const dateA = new Date(a.datacoleta + 'T' + a.horacoleta.split('.')[0]);
      const dateB = new Date(b.datacoleta + 'T' + b.horacoleta.split('.')[0]);
      return dateA - dateB;
    })
  }else{
    return data.sort((a, b)=>{
      const dateA = new Date(a.datacoleta + 'T' + a.horacoleta.split('.')[0]);
      const dateB = new Date(b.datacoleta + 'T' + b.horacoleta.split('.')[0]);
      return dateB - dateA;
    })
  }
}

export function ordenarSerial(data, posicao){
  return data.sort((a, b)=>{
    if(posicao =='asc'){
      return a.numeroserie.localeCompare(b.numeroserie)
    }else{
      return b.numeroserie.localeCompare(a.numeroserie)
    }
  });
}

export function ordenarMarca(data, posicao){
  return data.sort((a, b)=>{
    if(posicao =='asc'){
      return a.marca.localeCompare(b.marca)
    }else{
      return b.marca.localeCompare(a.marca)
    }
  });
}

export function ordenarModelo(data, posicao){
  return data.sort((a, b)=>{
    if(posicao =='asc'){
      return a.modelo.localeCompare(b.modelo)
    }else{
      return b.modelo.localeCompare(a.modelo)
    }
  });
}

export function ordenarMedidor(data, posicao){
  return data.sort((a, b)=>{
    return posicao =='asc' ? parseInt(a.contadortotal) - parseInt(b.contadortotal) : parseInt(b.contadortotal) - parseInt(a.contadortotal)
  });
}

export function ordenarNivelCiano(data, posicao){
  return data.sort((a, b)=>{
    return posicao =='asc' ? parseInt(a.nivelciano ? a.nivelciano : 0) - parseInt(b.nivelciano ? b.nivelciano : 0) : parseInt(b.nivelciano ? b.nivelciano : 0) - parseInt(a.nivelciano ? a.nivelciano : 0)
  });
}

export function ordenarNivelMagenta(data, posicao){
  return data.sort((a, b)=>{
    return posicao =='asc' ? parseInt(a.nivelmagenta ? a.nivelmagenta : 0) - parseInt(b.nivelmagenta ?b.nivelmagenta : 0) : parseInt(b.nivelmagenta ? b.nivelmagenta : 0) - parseInt(a.nivelmagenta ? a.nivelmagenta : 0)
  });
}

export function ordenarNivelAmarelo(data, posicao){
  return data.sort((a, b)=>{
    return posicao =='asc' ? parseInt(a.nivelamarelo ? a.nivelamarelo : 0) - parseInt(b.nivelamarelo ? b.nivelamarelo  : 0) : parseInt(b.nivelamarelo ? b.nivelamarelo : 0) - parseInt(a.nivelamarelo ? a.nivelamarelo : 0)
  });
}

export function ordenarNivelPreto(data, posicao){
  return data.sort((a, b)=>{
    return posicao =='asc' ? parseInt(a.nivelpreto ? a.nivelpreto  : 0) - parseInt(b.nivelpreto ? b.nivelpreto : 0) : parseInt(b.nivelpreto ? b.nivelpreto : 0) - parseInt(a.nivelpreto ? a.nivelpreto  : 0)
  });
}
