import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TabEquipamento from './TabEquipamento'
import TabContadores from './TabContadores'
import TabConsumiveis from './TabConsumiveis'
import Conexao from '../../../providers/Conexao'
import { useEffect } from 'react';
import { useState } from 'react';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function TabsGestaoEquipamento(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [contadores, setContadores] = useState([])
  const [consumiveis, setConsumiveis] = useState([])

  useEffect(()=>{
    if(props.equipamento){
      
        getContadores(props.equipamento.idusuario, props.equipamento.idmodelo);
        getConsumiveis(props.equipamento.idusuario, props.equipamento.idmodelo);
    }
    
  }, [props.equipamento])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getContadores = (usuario, modelo) =>{
    Conexao.post('modelosController/getTiposNumeradores', {
      idusuario : usuario, 
      idmodelo : modelo
    }).then(response =>{
      if(response.data.status =='OK'){
        setContadores(response.data.payload)
      }else{
        setContadores([])
      }
    }).catch(error =>{
      setContadores([])
    });
  }

  const getConsumiveis = (usuario, modelo) =>{
    Conexao.post('modelosController/getConsumiveis', {
      idusuario : usuario, 
      idmodelo : modelo
    }).then(response =>{
      if(response.data.status =='OK'){
        setConsumiveis(response.data.payload)
      }else{
        setConsumiveis([])
      }
    }).catch(error =>{
      setConsumiveis([])
    });
  }

  

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Cadastro" {...a11yProps(0)} />
        
      </Tabs>
      <TabPanel value={value} index={0} >

          <TabEquipamento equipamento = {props.equipamento} handleClose = {()=>props.handleClose()}/>
      </TabPanel>
     
    </div>
  );
}
