import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import Filter9PlusIcon from '@material-ui/icons/Filter9Plus';
import StorageIcon from '@material-ui/icons/Storage';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalOrganizaFila(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleRLDDChange = (newItems) =>{
    props.setChamadosFila(newItems);
   }

  return (
    <div>
     
      <Filter9PlusIcon color="primary" onClick={handleClickOpen} style={{cursor:'pointer'}}/>
      <Dialog   onClose={handleClose}  aria-labelledby="customized-dialog-title" open={open} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Organizar sequencia de atendimento
        </DialogTitle>
        <DialogContent dividers >
            <div  style={{height:"450px",width:"300px",  overflow:"hidden", overflowX:"hidden"}}>
            <RLDD
                items={props.chamadosFila}
                itemRenderer={(item) => {
                    return (
                    <div key={item.id} className="item listGroup-item listGroup-item-atual">
                        <div style ={{width:"20%", textAlign:"center", fontSize:"14px", display:"flex", alignItems:'center', justifyContent:"center"}}><Typography variant='caption' color='primary' style ={{fontSize:"12px"}}>{item.chamado}</Typography></div>
                        <div style ={{width:"80%", fontSize:"10px"}} className='texto-limitado'>
                            <Typography variant='subtitle1' className='texto-limitado' style ={{fontSize:"10px"}} color='primary' >{item.razaosocial}</Typography>
                            <Typography variant='subtitle2' className='texto-limitado' style ={{fontSize:"10px"}} color='primary' >{item.numeroserie+' - '+item.modelo}</Typography>
                        </div>                                                                    
                        </div>
                    );
                }}
                onChange={handleRLDDChange}
                />
           </div>
        </DialogContent>
        <DialogActions>
          
          <Button variant='contained' autoFocus onClick={handleClose} color="primary">
            FECHAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
