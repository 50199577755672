import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DraftsTwoToneIcon from '@material-ui/icons/DraftsTwoTone';
import MailTwoToneIcon from '@material-ui/icons/MailTwoTone';
import { Grid, Container, TextField, LinearProgress, InputAdornment, Tooltip, Badge} from '@material-ui/core'
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import Conexao from '../../../providers/Conexao'
import StorageIcon from '@material-ui/icons/Storage';
import ModalOrganizaFila from './ModalOrganizaFila'
import Swal from 'sweetalert2'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SmartphoneIcon from '@material-ui/icons/Smartphone';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalTriagemChamados(props) {
  const [open, setOpen] = React.useState(false);
  const [loadingFila, setLoadingFila] = React.useState(false);
  const [bandejas, setBandejas] = React.useState([]);
  const [tiposAtendimento, setTiposAtendimento] = React.useState([]);
  const [chamadosFila, setChamadosFila] = React.useState([]);
  const [totalEmailNotificacao, setTotalEmailNotificacao] = React.useState(1);
  const refAtendente = React.useRef();
  const refSituacaoEquipamento = React.useRef();
  const refDataAgendada = React.useRef();
  const refTipoAtendimento = React.useRef();

  const handleClickOpen = () => {
    setOpen(true);
    getBandejas();
    getTiposAtendimentoTecnico();
    setChamadosFila([])
    
  };
  const handleClose = () => {
    setOpen(false);
  };
  

  const getBandejas = () =>{
    Conexao.get('chamadosTecnicosController/getJanelasTelaGestao?idusuario='+props.chamado.idusuario+'&idempresa='+props.chamado.idempresa).then(response =>{
      if(response.data.status =='OK'){
        setBandejas(response.data.payload);
      }else{
        setBandejas([]);
      }
    }).catch(error =>{
      setBandejas([]);
    });
  }


  const getChamadosFilaTecnico = (funcionario) =>{
    
    setLoadingFila(true)
    Conexao.get('chamadosTecnicosController/getChamadosFilaTecnico?idUsuario='+props.chamado.idusuario+'&idTecnico='+funcionario+'&idChamado='+props.chamado.idchamadotecnico).then(response =>{
      if(response.data.status =='OK'){
        const chamadosTemp = response.data.payload;
        chamadosTemp.push({
          id : response.data.payload.length, 
          numero : props.chamado.idchamadotecnico, 
          visita : props.chamado.sequenciaatendimento, 
          chamado : props.chamado.chamado, 
          razaosocial : props.chamado.razaosocial, 
          modelo : props.chamado.modelo, 
          codigoadicional : props.chamado.codigoadicional, 
          setor : props.chamado.setor, 
          numeroserie : props.chamado.numeroserie
        });
        setChamadosFila(chamadosTemp)

      }else{
        setChamadosFila([])
      }
      setLoadingFila(false)
    }).catch(error =>{
      setLoadingFila(false)
      setChamadosFila([])
    });
  }
  
 
 
   const processarTriagemChamado = () =>{
     if( refAtendente.current  &&  refAtendente.current.value.trim() =='F'){
      Swal.fire({
        title : 'Printerconnect', 
        text : 'Você deve selecionar um técnico para atendimento !', 
        icon : 'warning', 
        showCancelButton : false, 
        cancelButtonText : 'Não', 
        showConfirmButton : true, 
        confirmButtonText : 'Sim', 
        timer : 1500
      })
      return
     }

     if( refDataAgendada.current  &&  refDataAgendada.current.value.trim() == ''){
      Swal.fire({
        title : 'Printerconnect', 
        text : 'Você deve a data prevista para o atendimento !', 
        icon : 'warning', 
        showCancelButton : false, 
        cancelButtonText : 'Não', 
        showConfirmButton : true, 
        confirmButtonText : 'Sim', 
        timer : 1500
      })
      return
     }
     Swal.fire({
       title : 'Printerconnect', 
       text : 'Deseja processar esta Ordem de Serviço e deixa-la disponível para atendimento ?', 
       icon : 'question', 
       showCancelButton : true, 
       cancelButtonText : 'Não', 
       showConfirmButton : true, 
       confirmButtonText : 'Sim'
     }).then(resposta =>{
       if(resposta.isConfirmed){
         const parametrosChamado = {
           idempresa : props.chamado.idempresa, 
           idusuario : props.chamado.idusuario, 
           idchamadotecnico : props.chamado.idchamadotecnico, 
           bandeja : refAtendente.current ? {idbandeja : bandejas[refAtendente.current.value].idbandeja ? bandejas[refAtendente.current.value].idbandeja : 0, idtecnico : bandejas[refAtendente.current.value].idtecnico ? bandejas[refAtendente.current.value].idtecnico : 0, idregiao : bandejas[refAtendente.current.value].idregiao} : 
           {idbandeja : -1, idtecnico : -1, idregiao :-1}, 
           situacaoequipamento : refSituacaoEquipamento.current ? refSituacaoEquipamento.current.value : -1, 
           dataagendada : refDataAgendada.current ? refDataAgendada.current.value : -1,
           tipoatendimento : refTipoAtendimento.current ? refTipoAtendimento.current.value : 1, 
           sequenciaatendimento : chamadosFila.map(function(chamado, i){
             return {
               idChamado : chamado.numero, 
               posicao : (i + 1)
           }})
         }

       
         Conexao.post('chamadosTecnicosController/processarTriagemChamado', {
            ...parametrosChamado
         }).then(response=>{
           if(response.data.status =='OK'){
            Swal.fire({
              title : 'Printerconnect', 
              text : 'Chamado técnico direcionado com sucesso!', 
              icon : 'success', 
              showCancelButton : false, 
              cancelButtonText : 'Não', 
              showConfirmButton : false, 
              confirmButtonText : 'Sim', 
              timer : 1000
            })
            props.getData();
            setOpen(false)

            if(parametrosChamado.bandeja.idregiao != 0 && parametrosChamado.bandeja.idtecnico == 0){
              props.notificarRegiao(parametrosChamado.bandeja.idregiao, 'Printerconnect - Aviso de novo de chamado', 'Um novo chamado técnico foi aberto em sua região, previsão de atendimento: '+parametrosChamado.dataagendada+'. Situação do equipamento: '+parametrosChamado.situacaoequipamento, 'meusChamados', 'Novo chamado na região' )

            }else {
              props.notificarTecnico(parametrosChamado.bandeja.idtecnico, 'Printerconnect - Aviso de novo de chamado', 'Um novo chamado tecnico foi aberto para você, previsão de atendimento: '+parametrosChamado.dataagendada+'. Situação do equipamento: '+parametrosChamado.situacaoequipamento, 'meusChamados', 'Chamado adicionado a sua fila' )

            }
           }
         }).catch(error=>{

         })
       }
     })
   }

   const handleRLDDChange = (newItems) =>{
    setChamadosFila(newItems);
   }


   const getTiposAtendimentoTecnico = async () =>{
     Conexao.post('chamadosTecnicosController/getTiposAtendimentoTecnico').then(response=>{
        if(response.data.status =='OK'){
          setTiposAtendimento(response.data.payload)
        }else{
          setTiposAtendimento([])
        }
     }).catch(error=>{
      setTiposAtendimento([])
     });
   }
  return (
    <div>
      
      <MailTwoToneIcon className='incon-small' color="primary" style ={{cursor:"pointer"}} onClick={handleClickOpen}/>
      <Dialog 
        
        onClose={handleClose} 
        fullWidth
        maxWidth='lg'
        aria-labelledby="customized-dialog-title" 
        open={open} 
        disableBackdropClick 
        disableEscapeKeyDown 
        hideBackdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Processar Chamado : {props.chamado.chamado+' - '+props.chamado.razaosocial}
        </DialogTitle>
        <DialogContent dividers style ={{height:"70vh"}}>
            <div className='div-flex-full'>
                <div style ={{width:"70%", height:"100%", padding:"5px"}}>
                  <Grid container spacing={1}>
                    <Grid item xs = {2}>
                      <TextField
                        size = 'small'
                        label='Chamado'
                        value ={props.chamado.chamado}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {2}>
                      <TextField
                        size = 'small'
                        label='Ocorrência'
                        value ={props.chamado.sequenciaatendimento}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {2}>
                      <TextField
                        size = 'small'
                        label='Data Abertura'
                        value ={props.chamado.dataabertura}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {3}>
                      <TextField
                        size = 'small'
                        label='Tipo de Atendimento'
                        value ={props.chamado.tipo}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {3}>
                      <TextField
                        size = 'small'
                        label='Situação'
                        value ={props.chamado.situacao}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                  </Grid>
                  <br/>
                  <Grid container spacing={1}>
                    <Grid item xs = {3}>
                      <TextField
                        size = 'small'
                        label='Número Série'
                        value ={props.chamado.numeroserie}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {1}>
                      <TextField
                        size = 'small'
                        label='Placa'
                        value ={props.chamado.codigoadicional}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {2}>
                      <TextField
                        size = 'small'
                        label='Marca'
                        value ={props.chamado.marca}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {6}>
                      <TextField
                        size = 'small'
                        label='Modelo'
                        value ={props.chamado.modelo}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                  </Grid>
                  <br/>
                  <Grid container spacing={1}>
                    <Grid item xs = {1}>
                      <TextField
                        size = 'small'
                        label='Código'
                        value ={props.chamado.idcliente}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {7}>
                      <TextField
                        size = 'small'
                        label='Razão Social'
                        value ={props.chamado.razaosocial}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {4}>
                      <TextField
                        size = 'small'
                        label='Setor'
                        value ={props.chamado.setor}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>                   
                  </Grid>
                  <br/>
                  <Grid container spacing={1}>
                    <Grid item xs = {4}>
                      <TextField
                        size = 'small'
                        label='Nome do Solicitante'
                        value ={props.chamado.reclamante}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {8}>
                      <TextField
                        size = 'small'
                        label='Email do Solicitante'
                        value ={props.chamado.emailreclamante}
                        fullWidth
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                  </Grid>
                  <br/>
                  <Grid container spacing={1}>
                    <Grid item xs = {3}>
                      <TextField
                        size = 'small'
                        label='Data Agendada'
                        fullWidth
                        type ='date'
                        variant='outlined'
                        inputRef={refDataAgendada}
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {5}>
                      <TextField
                        size = 'small'
                        label='Situação do Equipamento'
                        fullWidth
                        variant='outlined'
                        select
                        inputRef={refSituacaoEquipamento}
                        SelectProps={{native:true}}
                        InputLabelProps={{shrink : true}}>
                          <option>FUNCIONAMENTO NORMAL</option>
                          <option>FUNCIONAMENTO PRECÁRIO</option>
                          <option>SCANNER INOPERANTE</option>
                          <option>IMPRESSÃO INOPERANTE</option>
                          <option>APRESENTANDO BARULHO</option>
                          <option>ERRO NO PAINEL</option>
                      </TextField>
                    </Grid>

                    <Grid item xs = {4}>
                      <TextField
                        size = 'small'
                        label='Tipo de Atendimento'
                        fullWidth
                        variant='outlined'
                        select
                        inputRef={refTipoAtendimento}
                        SelectProps={{native:true}}
                        InputLabelProps={{shrink : true}}>
                          {
                            tiposAtendimento && tiposAtendimento.map(tipo=>{
                            return <option value={tipo.idtipoatendimento}>{tipo.descricao}</option>
                            })
                          }
                      </TextField>
                    </Grid>
                    
                  </Grid>
                  <br/>
                  <Grid container spacing={1}>
                  <Grid item xs = {2}>
                      <TextField
                        size = 'small'
                        label='Peças'
                        fullWidth
                        
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {2}>
                      <TextField
                        size = 'small'
                        label='Serviço'
                        fullWidth
                        
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                    <Grid item xs = {2}>
                      <TextField
                        size = 'small'
                        label='Total'
                        fullWidth
                        
                        variant='outlined'
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                  </Grid>
                  <br/>
                  <Grid container spacing={1}>
                    <Grid item xs = {12}>
                      <TextField
                        size = 'small'
                        label='Defeito Reclamado'
                        fullWidth
                        multiline
                        rows={3}
                        rowsMax={3}
                        variant='outlined'
                        value ={props.chamado.defeitoreclamado}
                        InputLabelProps={{shrink : true}}/>
                    </Grid>
                  </Grid>
                </div>
                <div style ={{width:"30%", border:'solid 1px #ccc', borderRadius:"5px",  padding:"5px", marginTop:'10px', }}>
                <TextField
                        size = 'small'
                        label='Bandeja de gestão'
                        fullWidth
                        variant='outlined'
                        select
                        inputRef={refAtendente}
                        SelectProps={{native:true}}
                       
                        onChange={(e)=>{
                          if(e.target.value != 'F'){                          
                            
                            getChamadosFilaTecnico(bandejas[e.target.value].idbandeja);
                          }else{
                            //setChamadosFila([])
                          }
                        }}
                        InputLabelProps={{shrink : true}}>
                          <option value ='F'></option>
                        {
                          
                          bandejas && bandejas.map(function(bandeja, i){
                            return <option value={i}>{bandeja.tipobandeja=='T' ? 'Técnico: '+bandeja.idbandeja+' - '+bandeja.nomerecipiente: 'Região: '+bandeja.idbandeja+' - '+bandeja.nomerecipiente}</option>
                          })
                          
                        }
                      </TextField>
                <div style ={{height:"100%", width:"100%", }}> 
                      { loadingFila && <LinearProgress color='secondary'/> }
                {
                  chamadosFila && open == true && <RLDD
                  items={chamadosFila}
                  itemRenderer={(item) => {
                      return (
                      <div key={item.id} className="item listGroup-item listGroup-item-atual">
                          <div style ={{width:"20%", textAlign:"center", fontSize:"14px", display:"flex", alignItems:'center', justifyContent:"center"}}><Typography variant='caption' color='primary' style ={{fontSize:"12px"}}>{item.chamado}</Typography></div>
                          <div style ={{width:"80%", fontSize:"10px"}} className='texto-limitado'>
                              <Typography variant='subtitle1' className='texto-limitado' style ={{fontSize:"10px"}} color='primary' >{item.razaosocial}</Typography>
                           
                          </div>                                                                    
                          </div>
                      );
                  }}
                  onChange={handleRLDDChange}
                  />
                }   
                
                </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs = {1}>
                <Tooltip title ='Notificações via e-mail'><Badge color ='secondary' badgeContent={totalEmailNotificacao} style ={{marginLeft:"10px"}}><MailOutlineIcon/> </Badge></Tooltip>
                <Tooltip title ='Notificações via push'><Badge color ='secondary' badgeContent={totalEmailNotificacao} style ={{marginLeft:"10px"}}><SmartphoneIcon/> </Badge></Tooltip>
            </Grid>
            <Grid item xs = {1}>
               
            </Grid>
            <Grid item xs = {6}>
            
            </Grid>
            <Grid item xs = {2}>
              <Button autoFocus onClick={handleClose} color="secondary" variant='contained' size='large' fullWidth>
              cancelar
              </Button>
            </Grid>
            <Grid item xs = {2}>           
              <Button autoFocus color="primary" variant='contained' size='large' fullWidth onClick={processarTriagemChamado}>
                CONFIRMAR
              </Button>
            </Grid>

            
          </Grid>
        
        </DialogActions>
      </Dialog>
    </div>
  );
}

{
  /*

  <RLDD
  items={chamadosFila}
  itemRenderer={(item) => {
    return (
      <div key={item.id} className="item listGroup-item listGroup-item-atual" style ={{display:"flex"}}>
        <div style ={{width:"20%", textAlign:"center", fontSize:"10px"}}><Typography variant='caption' style ={{fontSize:"10px"}}>{item.chamado}</Typography></div>
        <div style ={{width:"80%", fontSize:"10px"}} className='texto-limitado'><Typography variant='caption' className='texto-limitado' style ={{fontSize:"10px"}}>{item.razaosocial}</Typography></div>
        
        
        </div>
    );
  }}
  onChange={handleRLDDChange}
/>

*/
}
