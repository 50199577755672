import React, { useEffect } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import {TextField,Divider,Button, Container, Grid, Typography, Fab} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';



import Conexao from '../../../../../providers/Conexao'
import TransferList from '../../CentroDeCusto/Components/TransferList'


import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import AddIcon from '@material-ui/icons/Add';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PrintIcon from '@material-ui/icons/Print';

import WrapTextIcon from '@material-ui/icons/WrapText';
import OpacityIcon from '@material-ui/icons/Opacity';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalVincularFila = (props) => {
  
    const [open, setOpen] = React.useState(false)
    const [empresa, setEmpresa] = React.useState(props.empresa)
   //const [centro, setCentro] = React.useState(props.centro)
    const [descricao, setDescricao] = React.useState('')
    const [responsavel, setResponsavel] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [vinculados, setVinculados] = React.useState([])
    const [naoVinculados, setNaovinculados] = React.useState([])

    const [selecionados, setSelecionados] = React.useState([])

    useEffect(()=>{
      if(open == true){
        
      }
       
   },[open]);

    
  
    const getListDepartamento = () =>{       
        Conexao.post('bilhetagemController/vincularUsuariosCentroCusto',{
            idempresa: props.empresa.idempresa,
            idcliente: props.empresa.idcliente,
            idcentrocusto: props.centro.idcentrocusto         
           

        }).then( response =>{
           if(response.data.status == 'OK'){
             setVinculados(response.data.payload.vinculados)
             setNaovinculados(response.data.payload.naoVinculados)
            


           }else{
             setVinculados([])
             setNaovinculados([])

           }
              
        }).catch(error=>{
              setVinculados([])
              setNaovinculados([])
        })
    }

    const postListaUserSelecionados = () =>{      
      Conexao.post('',{
        idempresa: props.empresa.idempresa,
        idcliente: props.empresa.idcliente,
        idcentrocusto: props.centro.idcentrocusto,
        usuarios: selecionados

      }).then(response =>{
        if(response.data.status == 'OK'){
          Swal.fire({
            title : 'Printerconnect',
            icon : 'success', 
            text : 'Atualizado com Sucesso !', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 2000, 
          
            })      

            setOpen(false)

        }else {
          Swal.fire({
            title : 'Printerconnect',
            icon : 'warning', 
            text : 'Não foi possível adicionar ao Centro de Custo.', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 2000, 
          
            })

        }

      }).catch(error =>{
        Swal.fire({
            title : 'Printerconnect',
            icon : 'error', 
            text : 'Não foi possível Editar/atualizar', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 2000, 
          
            })
    })
}
   

  

  return (
    <div>
      <Tooltip title='Vincular Departamento ao Centro de Custo'>
     
        <PrintIcon style={{cursor:'pointer'}} onClick={()=>{getListDepartamento()
          setOpen(true)}} />
        
 

      </Tooltip>
      <Dialog
        hideBackdrop
        disableBackdropClick disableEscapeKeyDown
        fullWidth 
        maxWidth='md'    
        onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
          Vincular Departamento ao "{props.centro.descricao}"
        </DialogTitle>
        <DialogContent dividers >

       <TransferList  vinculados={vinculados} naoVinculados={naoVinculados} setSelecionados = {(data)=>{
         setSelecionados(data)
       }}/>
            
            

        </DialogContent>
        <DialogActions>
     
        <Button variant='contained' style={{cursor:'pointer', backgroundColor:'green', margin: 2, width:'120px', color:'#eee' }}   onClick={()=>{
         postListaUserSelecionados()
        }}><SaveIcon/> Salvar</Button>
            <Button  color='secondary' variant='contained' autoFocus onClick={() => setOpen(false)} ><ClearIcon /> Fechar</Button>
          
 

        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalVincularFila)