import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Grid, TextField, InputAdornment} from '@material-ui/core'
import ModalBuscaCliente from './ModalBuscaCliente'
import TextFieldCEP from '../../components/TextFieldCep'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DialogoNovoLocalCliente(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [cliente, setCliente] = React.useState({idcliente: '', razaosocial: ''})

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Cadastrar
      </Button>
      <Dialog onClose={handleClose} maxWidth='md' fullWidth aria-labelledby="customized-dialog-title" open={open} hideBackdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastro de Novo Local (Cliente)
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
              <Grid item xs = {2}>
                  <TextField
                    label='Código Cliente'
                    variant='outlined'
                    value={cliente.idcliente}
                    InputLabelProps={{shrink:true}}
                    InputProps ={{
                        startAdornment : <InputAdornment position='start'><ModalBuscaCliente updateCliente={(cliente)=>setCliente(cliente)}/></InputAdornment>
                    }}
                    fullWidth/>
              </Grid>
              <Grid item xs = {6}>
                  <TextField
                    label='Razão Social'
                    variant='outlined'
                    value={cliente.razaosocial}
                    InputLabelProps={{shrink:true}}   
                              
                    fullWidth/>
              </Grid>
              <Grid item xs = {2}>
                  <TextField
                    label='Latitude'
                    variant='outlined'
                    value={props.latitude}
                               
                    InputLabelProps={{shrink:true}}                    
                    fullWidth/>
              </Grid>
              <Grid item xs = {2}>
                  <TextField
                    label='Longitude'
                    variant='outlined'
                    value={props.longitude}
                                 
                    InputLabelProps={{shrink:true}}                    
                    fullWidth/>
              </Grid>
          </Grid>
          <br/>
          <Grid container spacing={1}>
              <Grid item xs = {2}>
                  <TextFieldCEP
                    label='Cep'
                    variant='outlined'
                    size='small'                 
                    InputLabelProps={{shrink:true}}
                    retornoCep={(response)=>{

                    }}
                    fullWidth/>
              </Grid>
              <Grid item xs = {4}>
                  <TextField
                    label='Endereço'
                    variant='outlined'
                    size='small'                 
                    value={props.longitude}
                    InputLabelProps={{shrink:true}}                    
                    fullWidth/>
              </Grid>
              <Grid item xs = {2}>
                  <TextField
                    label='Número'
                    variant='outlined'
                    size='small'                 
                    value={props.longitude}
                    InputLabelProps={{shrink:true}}                    
                    fullWidth/>
              </Grid>
              <Grid item xs = {4}>
                  <TextField
                    label='Complemento'
                    variant='outlined'
                    size='small'                 
                    value={props.longitude}
                    InputLabelProps={{shrink:true}}                    
                    fullWidth/>
              </Grid>
           </Grid>
           <br/>
          <Grid container spacing={1}>
              <Grid item xs = {4}>
                  <TextField
                    label='Bairro'
                    variant='outlined'
                    size='small'                 
                    InputLabelProps={{shrink:true}}            
                    fullWidth/>
              </Grid>
              <Grid item xs = {7}>
                  <TextField
                    label='Cidade'
                    variant='outlined'
                    size='small'                 
                    InputLabelProps={{shrink:true}}            
                    fullWidth/>
              </Grid>
              <Grid item xs = {1}>
                  <TextField
                    label='UF'
                    variant='outlined'
                    size='small'                 
                    InputLabelProps={{shrink:true}}            
                    fullWidth/>
              </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
