import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Typography, Grid, Divider} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      
      <Typography onClick={handleClickOpen} style = {{fontSize:"10px", padding:"5px", paddingLeft:"8px", cursor:"pointer"}}>Sobre a A3M Soluções.</Typography>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth='sm'>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Sobre Nós
        </DialogTitle>
        <DialogContent dividers>
          <Grid item xs = {12}>
              <Typography variant='h6'>A3M Soluções Digitais</Typography>
              <Typography variant='subtitle1'>Outsourcing de Impressão na era do IoT.</Typography>
          </Grid>
          <Divider/>
          <br/>
       

          <Grid item xs = {12}>
              <Typography variant='caption'>Endereço : Rua Narciso Ribeiro, 319 Cep 17523-230 Jadim Nacional</Typography><br/>
              <Typography variant='caption'>Telefone : (14) 9 9871-7644</Typography><br/>
              <Typography variant='caption'>Email : contato@printerconnect.com.br, suporte@printerconnect.com.br</Typography><br/>
          </Grid>
          <br/>
          <br/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary" variant='outlined'>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
