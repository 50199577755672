import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import { TableCell, Table, TableHead, TableBody, TableRow,  TextField , Grid} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Conexao from '../../providers/Conexao'
import {withRouter} from 'react-router-dom'
import Swal from 'sweetalert2'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PersonIcon from '@material-ui/icons/Person';
import LinearProgress from '@material-ui/core/LinearProgress';
import DetalhesIcon from '@material-ui/icons/Assignment'
import AbasClientes from './Componentes/AbasClientes';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalDetalhesCliente = (props) =>{
  const [open, setOpen] = React.useState(false);
  const [filtro, setFiltro] = React.useState('');
  const [clientes, setClientes] = React.useState([]);
  const [loading, setLoading]= React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    
    setOpen(false);
  };

 

  return (
    <div style={{height:'100%', width:'100%', padding : '0px'}}>
      
      
      <Button 
          onClick={handleClickOpen}
          variant = 'outlined' 
          disabled={props.disabled} 
          fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
        >
              <DetalhesIcon /> Detalhes
      </Button>
   
      
      <Dialog onClose={handleClose} 
        fullWidth={true}
        scroll='body'
        disableBackdropClick disableEscapeKeyDown hideBackdrop
        maxWidth={'md'} aria-labelledby="customized-dialog-title" open={open}>
        
        <DialogContent dividers>
        <AbasClientes  cliente={props.cliente}/>
        
          
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">            
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalDetalhesCliente)