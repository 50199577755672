import React from 'react'
import {withRouter} from 'react-router-dom'
import {Map, Circle, TileLayer,withLeaflet, Marker, Popup, Tooltip, ZoomControl, Polygon, Polyline  } from "react-leaflet";
import MeasureControlDefault from 'react-leaflet-measure'; //https://www.npmjs.com/package/react-leaflet-measure
import L from 'leaflet';
import "leaflet/dist/leaflet.css";//https://towardsdatascience.com/creating-a-bubbles-map-using-react-leaflet-e75124ca1cd2
import { point } from 'leaflet';
import Principal from '../Principal/Principal'
import {Typography, Divider, TextField, Button, Grid} from '@material-ui/core'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Slider from '@material-ui/core/Slider';
import { SketchPicker,   } from 'react-color'
import Swal from 'sweetalert2'
import Conexao from '../../providers/Conexao'
import { Icon, DivIcon } from "leaflet";
import IconeCliente from './icones/iconCliente.png'
import IconDefault from './icones/markerDefault.png'
import DialogoNovoLocalCliente  from './DialogoNovoLocalCliente'
import MarkerClusterGroup from "react-leaflet-markercluster";
import EditIcon from '@material-ui/icons/Edit';

const MapaCadastroRegioes = (props) =>{

    const position = [51.505, -0.09]
    const [posicaoInicio, setPosicaoInicio] = React.useState({latitude : props.location.state.localClientes.latitude, longitude :props.location.state.localClientes.longitude, razaosocial:props.location.state.localClientes.razaosocial} );
    const [raio, setRaio] = React.useState(500);
    const [color, setColor] = React.useState('#db4a29');
    const MeasureControl = withLeaflet(MeasureControlDefault);
    const [incluindoRegiao, setIncluindoRegiao]= React.useState(false);
    const [gravandoRegiao, setGravandoRegiao]= React.useState(false);
    const [regioes, setRegioes] = React.useState([]);
    const [locaisInstalacao, setLocaisInstalacao] = React.useState([]);


    React.useEffect(()=>{
      getRegioes();
      getLocalMapa()
    }, []);

    React.useEffect(()=>{
      console.log(props.location.state.localClientes)
    }, [props.location.state.localClientes]);


    

    const resetInclusaoRegiao = () =>{
      setRaio(500)
      setColor('#db4a29')
      setPosicaoInicio({latitude : props.location.state.localClientes.latitude, longitude :props.location.state.localClientes.longitude, razaosocial:props.location.state.localClientes.razaosocial})
      setIncluindoRegiao(false)
    }
 
    const iconCliente = new Icon({
    
        iconUrl: IconeCliente,
        iconSize: [42, 42], 
        iconAnchor :[
            10, 25
        ], 
        popupAnchor: [0, 0]
      });

      const iconePadrao = new Icon({
    
        iconUrl: IconDefault,
        iconSize: [42, 42], 
        iconAnchor :[
            10, 25
        ], 
        popupAnchor: [0, 0]
      });

    const handlePositionLine = (posicao)=>{
      if(incluindoRegiao){
        setPosicaoInicio({'latitude':posicao.lat, 'longitude':posicao.lng})
      }
     
    
    }

    const measureOptions = {
      position: 'topright',
      primaryLengthUnit: 'meters',
      secondaryLengthUnit: 'kilometers',
      primaryAreaUnit: 'sqmeters',
      secondaryAreaUnit: 'acres',
      activeColor: '#db4a29',
      completedColor: '#9b2d14'
    };

    const hangeSlider = (event, newValue) => {   
      if(posicaoInicio){
        setRaio(newValue)
      }           
    };

   const handleChangeComplete = (color) => {
      setColor(color.hex )
    };

    const refDescricao = React.useRef();

    const getRegioes = () =>{
      Conexao.get('empresasController/getRegioes?idUsuario='+props.match.params.idempresa+'&idEmpresa='+props.match.params.chaveEmpresa).then(response=>{
        if(response.data.status =='OK'){
          setRegioes(response.data.payload)
        }else{
          setRegioes([])
        }
      }).catch(error=>{
        setRegioes([])
      })
    }

    const getLocalMapa = () =>{
        Conexao.get('LocaisInstalacaoClienteController/getLocalMapa?idlocal='+props.location.state.localClientes.idlocal+'&idempresa='+props.match.params.chaveEmpresa).then(response=>{
            if(response.data.status =='OK'){
              setLocaisInstalacao(response.data.payload)
            }else{
              setLocaisInstalacao([])
            }
          }).catch(error=>{
            setLocaisInstalacao([])
          })  
    }

    

    return <Principal full={true} navOculta={true}>
       <div style ={{height: "74vh", marginTop:"10px", width:"100%" }}>

        <Map
          id="modalMap"                        
          animate={true}
          style={{ height: "100%", width: "100%", }}
          minZoom={5}
          zoom={6}   
          onmousemove = {(e)=>{
            if(incluindoRegiao){
              setPosicaoInicio({latitude : e.latlng.lat, longitude : e.latlng.lng})
            }
            
          }}                     
          zoomControl={false}                                                        
          center={[-22.2244322,-49.8917842]}    
          onclick={(e)=>{
           handlePositionLine(e.latlng)
           setGravandoRegiao(true)
           setIncluindoRegiao(false)
           //console.log(e)
          }}    
          >
            <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <ZoomControl position="topright"  />
           

           
             {/*posicaoInicio && <Marker key ={'MKR01'} posicao={[-22.2244322,-49.8939729]}/> */}
            {
              regioes && regioes.map(function(regiao, i){
                return <Circle
                fillColor={regiao.cor}
                stroke ={false}                
                center = {[regiao.latitude, regiao.longitude]}
                radius = {regiao.raio}
              />
              })
            }

            {
               posicaoInicio && 
               posicaoInicio.latitude && 
               posicaoInicio.longitude && 
               <Marker 
                    icon={iconePadrao} 
                    position ={[posicaoInicio.latitude, posicaoInicio.longitude]} >
                      <Popup>
                        <Typography variant='subtitle2'>Razão Social : {posicaoInicio.razaosocial}</Typography>
                      <Typography variant='caption'>Latitude : {posicaoInicio.latitude}</Typography><br/>
                      <Typography variant='caption'>Longitude : {posicaoInicio.longitude}</Typography><br/>
                      </Popup>
                </Marker>
            }
            <MarkerClusterGroup>
            {
                locaisInstalacao && locaisInstalacao.map(function(local, i){
                    return <Marker 
                    key = {'LC'+i}
                    icon={iconCliente} 
                    position ={[local.latitude, local.longitude]} >
                       <Popup className='popup-parada'>
                         <Typography variant='caption'>Razão Social : {local.razaosocial}</Typography>
                         
                       </Popup>
                    </Marker>
                })
            }
            </MarkerClusterGroup>
          </Map>
          <div className='fabIncluir sobreposicaoMaxima'>
            {!incluindoRegiao && <Fab color="primary" aria-label="add" onClick={()=>{
              setIncluindoRegiao(true)
              setGravandoRegiao(false)}}>
              <EditIcon />
        </Fab> }
          </div>
        
        </div> 
        {gravandoRegiao &&
        <div id ='overlay-mapa-regiao'>
            <Typography variant='subtitle2' style ={{fontSize:"12px"}}>
                {props.location.state.localClientes.razaosocial}
            </Typography>
            <Divider/>
            <br/>
            <Typography variant='subtitle2' style ={{fontSize:"12px"}}>
                Endereço
            </Typography>
            <Typography variant='subtitle2' style ={{fontSize:"12px"}}>
                {props.location.state.localClientes.logradouro+', '+props.location.state.localClientes.numero+' - '+props.location.state.localClientes.complemento}
            </Typography>
            <br/>
            <Typography variant='subtitle2' style ={{fontSize:"12px"}}>
                CEP/Bairro
            </Typography>
            <Typography variant='subtitle2' style ={{fontSize:"12px"}}>
                {props.location.state.localClientes.cep+', '+props.location.state.localClientes.bairro}
            </Typography>
            <br/>
            <Typography variant='subtitle2' style ={{fontSize:"12px"}}>
                Cidade
            </Typography>
            <Typography variant='subtitle2' style ={{fontSize:"12px"}}>
                {props.location.state.localClientes.cidade+' - '+props.location.state.localClientes.estado}
            </Typography>
            <br/>
            <Typography variant='subtitle2' style ={{fontSize:"12px"}}>
                Coordenadas
            </Typography>
            <Typography variant='subtitle2' style ={{fontSize:"12px"}}>
                {posicaoInicio && posicaoInicio.latitude+' / '+posicaoInicio.longitude}
            </Typography>
            <br/>
            <Button variant='contained' color='primary' fullWidth size='large' onClick={()=>{
                Swal.fire({
                    title :'Printerconenct', 
                    text :'Deseja gravar a posição atual para este local de instalação ?', 
                    icon :'question', 
                    showConfirmButton : true, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : true, 
                    cancelButtonText : 'Não'
                }).then(resposta =>{
                    if(resposta.isConfirmed){
                       Conexao.post('clientesController/updatePosicaoLocalIntalacao',{
                        idEmpresa : props.location.state.localClientes.idempresa, 
                        idCliente : props.location.state.localClientes.idcliente, 
                        idLocal : props.location.state.localClientes.idlocal, 
                        latitude : posicaoInicio.latitude, 
                        longitude : posicaoInicio.longitude
                       }).then(response=>{
                            if(response.data.status == 'OK'){
                                getLocalMapa()
                            }
                       }).catch(error=>{}); 
                    }
                })
            }}>Cadastrar</Button>
            <br/>
            <Button variant='contained' color='secondary' fullWidth size='large' style={{marginTop:"10px"}} onClick = {()=>{
                Swal.fire({
                    title :'Printerconenct', 
                    text :'Deseja cancelar o posicionamento ?', 
                    icon :'question', 
                    showConfirmButton : true, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : true, 
                    cancelButtonText : 'Não'
                }).then(resposta =>{
                    if(resposta.isConfirmed){
                        resetInclusaoRegiao(); 
                        setGravandoRegiao(false)
                        setIncluindoRegiao(false)
                    }
                })

            }}>Cancelar</Button>

        </div>}
    </Principal>
}

export default withRouter(MapaCadastroRegioes)