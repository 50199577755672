import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import SettingsIcon from '@material-ui/icons/Settings';

import Tooltip from '@material-ui/core/Tooltip';

import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import ModalNovoTipoNumerador from './ModalNovoTipoNumerador'


const CadastroTipoNumeradores = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [tiposNumeradores, setTipoNumeradores] = useState([])
    const [edicao, setEdicao] = useState([])
    const [filtro, setFiltro] = useState('')
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getTipoNumeradores();
    }, [])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])


    const getTipoNumeradores = () =>{
        
        setLoading(true)
        Conexao.post('tiposNumeradoresController/getTipoNumeradores', {
            filtro : filtro, 
            idusuario : props.match.params.idempresa
        }).then(response =>{
           
            if(response.status == 200){
                
                if(response.data.status == 'OK'){
                    setTipoNumeradores(response.data.payload);
                    
                  
                }else{
                    setTipoNumeradores([])
                }
            }else{
                setTipoNumeradores([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
        })
    }

    const verificaEmpresaAtiva = (id, operacao) =>{
        
       return true;
    }

    

    


    return <Principal atualizar = {loading}>
        <Container >
        <Accordion elevation='0'>
            <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <div style = {{display : 'flex', fontSize:'10px!important'}}>
            <Typography color="textPrimary" >Você esta em : </Typography>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Cadastro de Tipos de Numeradores</Typography>
            </Breadcrumbs>
        </div>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing = {3}>
               
                <Grid item xs={4}>
                    <TextField
                        label='Filtro'
                      
                        variant='outlined'
                        value={filtro}
                        onChange = {(e)=>setFiltro(e.target.value)}
                        InputLabelProps = {{shrink : true}}
                        fullWidth/>

                </Grid>
                <Grid item xs = {1}>
                    <Button className='fullButton' variant='outlined' color='primary' onClick={getTipoNumeradores}>Pesquisar</Button>
                </Grid>
            </Grid>
            </AccordionDetails>
        </Accordion>
        
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
        
        
        
        </Grid>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
            <Table  >
                <TableHead>
                    <TableCell align='center' style = {{width:"5%"}}>#</TableCell>
                    <TableCell align='left' style = {{width:"95%"}}>Descrição</TableCell>
                </TableHead>
                <TableBody>
                    {
                        tiposNumeradores && tiposNumeradores.map(function(tipoNumeradores, i){
                            return <TableRow id = {'EMP'+i}>
                                        <TableCell align='center'><Typography variant='caption' color='primary'>{(i + 1)}</Typography></TableCell>
                                        <TableCell align='left' >
                                            <Typography variant='caption' color='primary'>{tipoNumeradores.descricao}</Typography>
                                        
                                        </TableCell>

                                       
                                    </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </Grid>
        <ModalFull titulo = {edicao.idempresa ? 'Edição do Técnicos : '+edicao.descricao :'Cadastro de Novo Técnico'} showModal = {openModal} closeModal= {()=>setOpenModal(false)}>

            
        </ModalFull>

      
        </Container>
        <ModalNovoTipoNumerador edicao={edicao} closeEdicao = {()=>setEdicao([])} getTipoNumeradores = {()=>getTipoNumeradores()}/>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroTipoNumeradores)