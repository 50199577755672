import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {Divider, TextField, Grid, Container, InputAdornment} from '@material-ui/core'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalNovoTipoNumerador = (props) => {
    const [open, setOpen] = React.useState(false)
    const [descricao, setDescricao] = React.useState('')
    const [observacoes, setObservacoes] = React.useState('')



    React.useEffect(()=>{
        if(open){
            setDescricao('')
            setObservacoes('')
        }
    }, [open])


    React.useEffect(()=>{
      if(props.edicao.idtiponumerador){
       // alert('teste')
          setDescricao(props.edicao.descricao)
          setObservacoes(props.edicao.observacoes)
      }
  }, [props.edicao.idtiponumerador])

    const insertTipoNumeradores = () =>{
        Swal.fire({
            title : 'Printerconnect',
            icon : 'question', 
            text : 'Deseja Cadastrar/Alterar ?', 
            showCancelButton : true, 
            showConfirmButton : true, 
            confirmButtonText : 'Confirmar', 
            cancelButtonText : 'Cancelar',
            }).then((resposta)=>{
                if(resposta.isConfirmed){
                    Conexao.post('tiposNumeradoresController/'+( props.edicao.idtiponumerador ? 'updateTipoNumeradores' : 'insertTipoNumeradores'), {
                        idusuario : props.match.params.idempresa,
                        idtiponumerador  : (props.edicao.idtiponumerador  ? props.edicao.idtiponumerador : 0), 
                        descricao, 
                        observacoes
                    }).then( response =>{
                        if(response.data.status =='OK'){
                            Swal.fire({
                            title : 'Printerconnect',
                            icon : 'success', 
                            text : 'Tipo de Numerador cadastrada/atualizada com sucesso !', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1000, 
                            onClose : ()=>{
                                props.getTipoNumeradores()
                                props.closeEdicao();
                            }
                            })
                            
                    }else{
                        Swal.fire({
                            title : 'Printerconnect',
                            icon : 'warning', 
                            text : 'Não foi possível cadastrar/atualizar', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1000, 
                            onClose : ()=>{
                              props.getTipoNumeradores()
                              props.closeEdicao();
                          }
                            })
                    }
                    }).catch(error =>{
                        Swal.fire({
                            title : 'Printerconnect',
                            icon : 'warning', 
                            text : 'Não foi possível cadastrar/atualizar', 
                            showCancelButton : false, 
                            showConfirmButton : false, 
                            timer : 1000, 
                            onClose : ()=>{
                              props.getTipoNumeradores()
                              props.closeEdicao();
                          }
                            })
                    })
                }
            })
        
    }

  return (
    <div>
        <div className='fabIncluir'>
        <Fab size="large" color="primary" aria-label="add" onClick={()=>setOpen(true)} >
          <AddIcon />
        </Fab>
      </div>
     
      <Dialog 
        disableBackdropClick disableEscapeKeyDown hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={()=>{
          setOpen(false)
          props.closeEdicao();
          }} aria-labelledby="customized-dialog-title" open={open || props.edicao.idtiponumerador}>
        <DialogTitle id="customized-dialog-title" onClose={()=>{
            setOpen(false)
            props.closeEdicao();
         
        }}
            >
          Cadastro/Alteração de Tipos de Numeradores
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing= {2}>
                
                <Grid item xs = {12}>
                    <TextField
                            label = 'Descrição'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                maxLength : 60
                            }}
                            InputLabelProps = {{shrink : true}}
                            value={descricao}
                            onChange={(e)=>setDescricao(e.target.value)}
                            />
                </Grid>
                <Grid item xs = {12}>
                    <TextField
                        label = 'Observações'
                        variant='outlined'
                        multiline
                        rows={3}
                        rowsMax={3}
                        fullWidth
                        InputLabelProps = {{shrink : true}}
                        inputProps={{
                            maxLength : 500
                        }}
                        value={observacoes}
                            onChange={(e)=>setObservacoes(e.target.value)}
                        />
                </Grid>
            </Grid>

            <Grid container spacing= {2}>
                
                
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' autoFocus onClick={()=>{
              props.closeEdicao();
              setOpen(false)}} variant='contained' color="secondary">
            Cancelar
          </Button>

          <Button  autoFocus onClick={()=>{
           insertTipoNumeradores()
           setOpen(false)
              
          }} variant='contained'  color="primary" >
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalNovoTipoNumerador)