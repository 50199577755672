import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Grid, TextField, Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/BuildOutlined';
import Fab from '@material-ui/core/Fab';
var md5 = require('md5');


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalAlterarSenha = (props) => {
    const [open, setOpen] = React.useState(false)
    const [senha, setSenha] = React.useState('')
    const [confirmacao, setConfirmacao]= React.useState('')

    const handleClickOpen = () =>{
        setOpen(true)
        setSenha('')
        setConfirmacao('')
    }

    const handleClose = () =>{
        setOpen(false)
    }
  return (
    <div>
      <Tooltip title='Alterar Senha da revenda'>
          <Button  variant='contained' onClick={handleClickOpen} style={{cursor:'pointer'}}>
              <Typography variant='caption'>Alterar</Typography>
          </Button>
        
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} className='texto-limitado'>
         Alterar Senha Revenda : {props.nome}
        </DialogTitle>
        <DialogContent dividers >
          <Grid container spacing ={1}>
            <Grid item xs = {4}>
              <Typography variant='subtitle1'>Nova Senha</Typography>
            </Grid>
            <Grid item xs ={8}>
              <TextField
                variant='outlined'
                fullWidth
                type='password'
                value={senha}
                onChange={(e)=>setSenha(e.target.value)}
                InputLabelProps={{shrink : true}}/>
            </Grid>
          </Grid>
          <br/>
          <Grid container spacing ={1}>
            <Grid item xs = {4}>
              <Typography variant='subtitle1'>Confirmação Senha</Typography>
            </Grid>
            <Grid item xs ={8}>
              <TextField
                variant='outlined'
                fullWidth
                type='password'
                value={confirmacao}
                onChange={(e)=>setConfirmacao(e.target.value)}
                InputLabelProps={{shrink : true}}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='outlined'>
            Cancelar
          </Button>
          <Button autoFocus onClick={()=>{
            if(senha == '' || confirmacao == '' || (senha != confirmacao)){
              Swal.fire({
                title : 'Datacount', 
                text  : 'A senha e a confirmação da senha devem ser iguais, e não deve ser em branco...', 
                icon : 'warning', 
                showConfirmButton: false, 
                confirmButtonText : 'Sim', 
                showCancelButton : false, 
                cancelButtonText :'Não', 
                timer : 1500,
              })
              return
            }
            Swal.fire({
              title : 'Datacount', 
              text  : 'Deseja alterar a senha da revenda atual?', 
              icon : 'question', 
              showConfirmButton: true, 
              confirmButtonText : 'Sim', 
              showCancelButton : true, 
              cancelButtonText :'Não'
            }).then(resposta=>{
              if(resposta.isConfirmed){
                props.Conexao.post('empresasController/updateSenhaRevenda',{
                  idusuario: props.idusuario, 
                  senha : md5(senha)
                }).then(response=>{
                  if(response.status == 200 && response.data.status =='OK'){
                    Swal.fire({
                      title : 'Datacount', 
                      text  : 'Senha alterada com sucesso', 
                      icon : 'success', 
                      showConfirmButton: false, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : false, 
                      cancelButtonText :'Não', 
                      timer : 1500,
                    })
                  }else{
                    Swal.fire({
                      title : 'Datacount', 
                      text  : 'Não foi possível alterar senha', 
                      icon : 'warning', 
                      showConfirmButton: false, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : false, 
                      cancelButtonText :'Não', 
                      timer : 1500,
                    })
                  }
                }).catch(error=>{
                  Swal.fire({
                    title : 'Datacount', 
                    text  : 'Não foi possível alterar senha', 
                    icon : 'error', 
                    showConfirmButton: false, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : false, 
                    cancelButtonText :'Não', 
                    timer : 1500,
                  })
                })
              }
              setOpen(false)
            })
          }} color="primary" variant='contained'>
            gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalAlterarSenha)