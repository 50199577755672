import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {Grid, TextField, InputAdornment, Tooltip, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TextFieldMoeda from '../../../../components/TextFieldMoeda'
import ModalBuscaCliente from '../../../Clientes/ModalBuscaCliente'
import Conexao from '../../../../providers/Conexao'
import Swal from 'sweetalert2';
import IconeGruposFranquia from '@material-ui/icons/AccountTree'
import IconeGerenciar from '@material-ui/icons/Build'
import IconePrinters from '@material-ui/icons/Print'
import IconeAditivos from '@material-ui/icons/FileCopy'
import IconeTermos from '@material-ui/icons/CompareArrows'
import IconClose from '@material-ui/icons/Close'
import IconeEncerrarContrato from '@material-ui/icons/Cancel'
import ModalNovoAditivo from './ModalNovoAditivo'
import IconSave from '@material-ui/icons/Save'
import ModalGruposFranquia from './ModalGruposFranquia'
import ModalTermos from './ModalTemosInstalacaoRetirada'



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalCadastro(props) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [cliente, setCliente] = React.useState({idcliente:'', razaosocial:''});
  const [tiposContrato, setTiposContrato] = React.useState([]);
  const [tiposChamados, setTiposChamados] = React.useState([]);
  const [funcionarios, setFuncionarios] = React.useState([]);

  const [idTipoContrato, setTipoContrato]= React.useState('F');
  const [idVendedor, setIdVendedor]= React.useState('F');
  const [dataCelebracao, setDataCelebracao]= React.useState('');
  const [dataInicio, setDataInicio]= React.useState('');
  const [dataFim, setDataFim]= React.useState('');
  const [diaLeitura, setDiaLeitura]= React.useState('10');
  const [indiceReajuste, setIndiceReajuste]= React.useState('');
  const [custoVisita, setCustoVisita]= React.useState('');
  const [custoTecnico, setCustoTecnico]= React.useState('');
  const [custoAdministrativo, setCustoAdministrativo]= React.useState('');
  const [idTipoChamadoTecnico, setIdTipoChamadoTecnico]= React.useState('F');
  const [gerarExcedente, setGerarExcedente]= React.useState('F');
  const [gerarCredito, setGerarCredito]= React.useState('F');
  const [observacoes, setObservacoes] = React.useState(''); 

  const [gruposFranquiaCadastro, setGruposFranquiaCadastro]= React.useState([]); 
  const {contrato} = props;

 
 React.useEffect(()=>{
    if(open){
        console.log(contrato)
    }
 },[open])




  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


 

 

  return (
    <div>
        <Tooltip title='Cadastrar um novo Contrato'>
            <Button variant='outlined' color='primary' onClick={handleClickOpen}><IconeGerenciar color="primary"  aria-label="add"/></Button>
        </Tooltip>
      <Dialog fullWidth maxWidth='lg' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableBackdropClick disableEscapeKeyDown hideBackdrop
        scroll={'body'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Gestão de Contrato - Printerconnect
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing = {1}>
                <Grid item xs={1}>
                    <ModalGruposFranquia contrato={contrato}/>
                </Grid>
                <Grid item xs={1}>
                    <ModalNovoAditivo contrato={contrato} title = 'Alteração' renovar ={false} updateData= {()=>{
                        props.updateData();
                        setOpen(false)
                    }}/>
                </Grid>
                <Grid item xs={1}>
                    <ModalNovoAditivo contrato={contrato} title = 'Renovação' renovar ={true} updateData= {()=>{
                        props.updateData();
                        setOpen(false)
                    }}/>
                </Grid>
                <Grid item xs={1}>
                    <ModalTermos contrato={contrato} />
                </Grid>
                <Grid item xs={1}>
                    <Button variant='outlined' color='primary' fullWidth className='default'>
                        <div>
                            <div><IconePrinters/></div>
                            <div style ={{fontSize :'11px'}}>Equipamentos</div>
                        </div>
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title  ='Encerrar este contrato!'>
                        <Button variant='outlined' color='primary' fullWidth className='danger'>
                            <div>
                                <div><IconeEncerrarContrato/></div>
                                <div style ={{fontSize :'11px'}}>ENCERRAMENTO</div>
                            </div>
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={5}>
                   
                </Grid>
                <Grid item xs={1}>
                 <Button variant='contained' color='secondary' fullWidth onClick={handleClose}>
                        <div>
                            <div><IconClose/></div>
                            <div style ={{fontSize :'11px'}}>Sair</div>
                        </div>
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing = {1} style ={{marginTop:'20px'}}>
                <Grid item xs = {2}>
                    <TextField
                        label='Nosso Número'
                        fullWidth
                        value={contrato && contrato.nossonumero}
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>

                    </TextField>
                </Grid>
                <Grid item xs = {5}>
                    <TextField
                        label='Tipo de Contrato'
                        fullWidth
                        value={contrato && contrato.tipocontrato}
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>

                    </TextField>
                </Grid>
                <Grid item xs = {1}>
                    <TextField
                        label='Código'
                        value={contrato && contrato.idcliente}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                       
                        />
                </Grid>
                <Grid item xs = {4}>
                    <TextField
                        label='Razão Social'
                        value={contrato && contrato.razaosocial}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing = {1}>
               
                <Grid item xs = {3}>
                <TextField
                        label='Vendedor'
                        value={contrato && contrato.nomevendedor}
                        fullWidth
                       
                        size='small'
                        
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                           
                    </TextField>
                </Grid>
               
                <Grid item xs = {2}>
                    <TextField
                        label='Data Celebração'
                        value={contrato && contrato.datacelebracao}
                        size='small'
                        fullWidth
                    
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextField
                        label='Data Inicio'
                     
                        value={contrato && contrato.datainiciovigencia}
                        size='small'
                        fullWidth
                     
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextField
                        label='Data Fim'
                        value={contrato && contrato.datafimvigencia}
                        size='small'
                        fullWidth
                    
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {1}>
                    <TextField
                        label='Leitura Dia'
                        value={contrato && contrato.dialeitura}
                        size='small'
                        fullWidth
                       
                        InputLabelProps={{shrink : true}}
                        variant='outlined'                        
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Indíce de Reajuste'
                       
                        size='small'
                        value={contrato && contrato.indicereajuste}
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing = {1}>               
               
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Visita Técnica'
                        value={contrato && contrato.custoporvisita}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Técnico'
                        value={contrato && contrato.custotecnico}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>
                <Grid item xs = {2}>
                    <TextFieldMoeda
                        label='Administrativo'
                        value={contrato && contrato.custoadministrativo}
                        fullWidth
                        size='small'
                        InputLabelProps={{shrink : true}}
                        variant='outlined'
                        />
                </Grid>

                <Grid item xs = {6}>
                    <TextField
                        label='Ordem de Serviço (tipo) Padrão'
                       
                        fullWidth
                        
                        size='small'
                      
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                           
                    </TextField>
                </Grid>
               
            </Grid>
            <br/>
            <Grid container spacing ={ 1}>
                <Grid item xs = {6}>
                    <TextField
                        label='Gerar excedente'
                        value={contrato && contrato.gerarexcedente=='t' ?'SIM' :'NÃO'}
                        fullWidth
                        
                        size='small'
                       
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                            
                    </TextField>
                </Grid>
                <Grid item xs = {6}>
                    <TextField
                        label='Habilitar controle de crédito'
                        value={contrato && contrato.gerarcredito=='t' ?'SIM' :'NÃO'}
                        fullWidth
                        
                        size='small'
                       
                        InputLabelProps={{shrink : true}}
                        variant='outlined'>
                          
                    </TextField>
                </Grid>
            </Grid>
            
            <br/>
            <Grid container spacing = {1}>
                <Grid item xs = {12}>
                    <TextField
                        label='Observações gerais'
                      
                        value={contrato && contrato.observacoes}
                        fullWidth
                        multiline                        
                        inputProps ={{
                            maxlength : 500
                        }}
                        size='small'
                        rows={4}
                        rowsMax={4}
                        InputLabelProps={{shrink : true}}
                        variant='outlined'/>
                </Grid>
              </Grid>
        </DialogContent>
       
      </Dialog>
    </div>
  );
}
