import React, {useState, useEffect, useRef, createRef,  Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import Filter9PlusIcon from '@material-ui/icons/Filter9Plus';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SettingsIcon from '@material-ui/icons/Settings';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Tooltip from '@material-ui/core/Tooltip';
import AccessTimeTwoToneIcon from '@material-ui/icons/AccessTimeTwoTone';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import ModalNovoEquipamento from './Componentes/ModalNovoEquipamento'
import ContactMailTwoToneIcon from '@material-ui/icons/ContactMailTwoTone';
import AssignmentTurnedInTwoToneIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
import ReportProblemTwoToneIcon from '@material-ui/icons/ReportProblemTwoTone';
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AutorenewTwoToneIcon from '@material-ui/icons/AutorenewTwoTone';
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone';
import PrintTwoToneIcon from '@material-ui/icons/PrintTwoTone';
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import PowerSettingsNewTwoToneIcon from '@material-ui/icons/PowerSettingsNewTwoTone';
import HttpsTwoToneIcon from '@material-ui/icons/HttpsTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import GestaoEquipamento from './GestaoEquipamento'
import ButtonAtivoInativo from '../../components/Comuns/ButtonAtivoInativo'
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import ModalEditarEquipamento from './Componentes/ModalEditarEquipamento'

const CadastroEquipamentos = (props)=>{
    const classes = useStyles();
    const [parametroFiltro, setParametroFiltro] = useState('')
    const [filtro, setFiltro] =  useState('')
    const [loading, setLoading] =  useState(false)
    const [equipamentos, setEquipamentos] = useState([])

    const [equipamentoSelecao, setEquipamentoSelecao] = useState(null)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const[registros,setRegistros] = React.useState(0)


    const refRazaoSocial = React.useRef();
    const refStatusCadstro = useRef();
    const refTipoEquipamento = useRef();
    const refFinalidade = useRef();
    const refDisponibilidade = useRef();
    const refPlaca = useRef();
    const refNumeroSerie = useRef();
    const refMarca = useRef();
    const refModelo = useRef();
    const refSetor = useRef();



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };




    const verificaEmpresaAtiva = (id, operacao) =>{
        
       return true;
    }

    React.useEffect(()=>{
        getEquipamentos()
        setPage(0)
    }, [])

    React.useEffect(()=>{
        getEquipamentos();
    }, [page, rowsPerPage])
  
  
    const clearForm = () =>{     
        
        if(refPlaca.current){
            refPlaca.current.value  ='';
        }
        if(refNumeroSerie.current){
            refNumeroSerie.current.value  ='';
        }
        if(refModelo.current){
            refModelo.current.value  ='';
        }
        if(refMarca.current){
            refMarca.current.value  ='';
        }
        if(refRazaoSocial.current){
            refRazaoSocial.current.value  ='';
        }
        if(refSetor.current){
            refSetor.current.value = '';
        }
        getEquipamentos()
        setPage(0)
          
    }


    

    
  const getEquipamentos = () =>{
      setLoading(true)
        Conexao.post('equipamentosController/getEquipamentos', {
            idusuario : props.match.params.idempresa,
            idempresa :props.match.params.chaveEmpresa, 
            orderBy : 'equipamentos.numeroSerie', 
            statusCadastro : refStatusCadstro.current ? refStatusCadstro.current.value : '', 
            tipoEquipamento : refTipoEquipamento.current ? refTipoEquipamento.current.value : '', 
            finalidade : refFinalidade.current ? refFinalidade.current.value : '', 
            disponibilidade : refDisponibilidade.current ? refDisponibilidade.current.value : '', 
            placa : refPlaca.current ? refPlaca.current.value : '', 
            numeroSerie : refNumeroSerie.current ? refNumeroSerie.current.value : '', 
            marca : refMarca.current ? refMarca.current.value : '', 
            modelo : refModelo.current ? refModelo.current.value : '', 
            setor : refSetor.current ? refSetor.current.value : '', 
            limit: rowsPerPage,
            offSet :  page, 
        }).then(response=>{
            if(response.data.status =='OK'){
                setEquipamentos(response.data.payload.dados)
                setRegistros(response.data.payload.registros)
              
            }else{
                setEquipamentos([])
                setRegistros(0)
            }
            setLoading(false)
        }).catch( error=>{
            setEquipamentos([])
            setRegistros(0)
            setLoading(false)
        });
  }

  const createPatrimonioEquipamento = (equipamento) =>{
  

    Swal.fire({
        title : 'Printerconnect', 
        text : 'Deseja cadastrar uma placa para este equipamento agora ?', 
        icon : 'question', 
        showCancelButton : true, 
        confirmButtonText : 'Sim', 
        showCancelButton : true, 
        cancelButtonText : 'Não'
      }).then(resposta =>{
        if(resposta.isConfirmed){
            Swal.fire({                                
                icon: 'info',
                title: 'Aguarde....processando',
                showConfirmButton: false,
                timer: 1500
            })
            Conexao.get('equipamentosController/createPatrimonioEquipamento?idUsuario='
            +equipamento.idusuario+'&idEmpresa='+equipamento.idempresa+'&idEquipamento='+equipamento.idequipamento+'&numeroSerie='+equipamento.numeroserie).then(response =>{
                if(response.data.status =='OK'){
                   getEquipamentos();
                   Swal.fire({
                    title : 'Printerconnect', 
                    text : 'Placa gerada com sucesso !', 
                    icon : 'success', 
                    showCancelButton : false, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : false, 
                    timer : 1000, 
                  })

                }else{
                    Swal.fire({
                        title : 'Printerconnect', 
                        text : 'Não foi possível gerar', 
                        icon : 'warning', 
                        showCancelButton : false, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : false, 
                        timer : 1000, 
                      })
                }
            }).catch(error =>{
                Swal.fire({
                    title : 'Printerconnect', 
                    text : 'Não foi possível gerar', 
                    icon : 'error', 
                    showCancelButton : false, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : false, 
                    timer : 1000, 
                  })
            });
        }
      });
  }
    

  const renderDisponibilidade = (cliente, status) =>{
      if(status =='t'){
        return <div style = {{textAlign:"center"}}>
        <HttpsTwoToneIcon style ={{color:"#f44336"}} /><br/>
        <Typography variant='caption'>Reservada</Typography>
    </div> 
      }
      if(cliente){
        return <div style = {{textAlign:"center"}}>
            <HomeTwoToneIcon color='primary' /><br/>
            <Typography variant='caption'>Instalado</Typography>
        </div> 
      }else{
        return <div style = {{textAlign:"center"}}>
            <AccessTimeTwoToneIcon style ={{color:"#00897b"}} /><br/>
         <Typography variant='caption'>Disponível</Typography>
        </div>      
      }
      
  }
  const renderStatus = (status, bloqueios)=>{
    
    if(bloqueios.total > 0){
        return <div style = {{textAlign:"center"}}>
            <LockTwoToneIcon style ={{color:"#f44336"}}/><br/>
            <Typography variant='caption'>Bloqueada</Typography>
        </div> 
    }
    if(status.total == 0){
        return <div style = {{textAlign:"center"}}>
            <AccessTimeTwoToneIcon style ={{color:"#00897b"}}/><br/>
            <Typography variant='caption'>OK</Typography>
        </div> 
      }else{
        return <div style = {{textAlign:"center"}}>
            <Badge badgeContent={status.total} color="secondary">
            <ReportProblemTwoToneIcon style ={{color:"#f44336", cursor:"pointer"}}/>
            </Badge><br/>
         <Typography variant='caption'>Alertas</Typography>
        </div>      
      }  
  }

  const renderTipo = (tipo) =>{
    if(tipo == 't'){
        
        return <div>
        <AssignmentIcon color='primary'/><br/>
        <Typography variant='caption' style ={{fontSize:'10px'}}>Locação</Typography>
        </div>
    }else{
      
        return <div >
        <SupervisorAccountIcon color='primary'/><br/>
        <Typography variant='caption' style ={{fontSize:'10px'}}>Terceiros</Typography>
        </div>
    }    
  }
  const renderFinalidade = (acessorio) =>{
    if(acessorio == 't'){
        return <div>
            <DnsTwoToneIcon color='primary'/><br/>
            <Typography variant='caption' style ={{fontSize:'10px'}}>Acessório</Typography>
        </div>
    }else{
        return <div>
            <PrintTwoToneIcon color='primary'/><br/>
            <Typography variant='caption' style ={{fontSize:'10px'}}>Equipamento</Typography>
        </div>
    }    
  }

  const renderPlaca = (placa) =>{
    
    if(placa){
        return <Chip avatar={<Avatar>{'P'}</Avatar>} label={placa} variant="outlined" color ='primary' />
    }
    
  }

  const renderAtivo = (ativo) => {
      if(ativo =='t'){
        return <div>
                <PowerSettingsNewTwoToneIcon style ={{color:"#00897b"}}/><br/>
                <Typography variant='caption'>Ativo</Typography>
        </div>
      }else{
          return <div>
        <BlockTwoToneIcon style ={{color:"#f44336"}}/><br/>
        <Typography variant='caption'>Inativo</Typography>
        </div>
      }
  }

    return <Principal atualizar = {loading}>
        <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Gestão de Equipamentos</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {1}>
            <Grid item xs = {3}>
                <TextField
                    label='Status do cadastro'
                    inputRef={refStatusCadstro}
                    size = 'small'
                    select
                    SelectProps  = {{native:true}}
                    InputLabelProps={{shrink : true}}
                    fullWidth
                    variant='outlined'>
                        <option value ='t'>ATIVO</option>
                        <option value ='f'>INATIVO</option>
                    </TextField>
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label='Tipo de equipamento'
                    size = 'small'
                    inputRef={refTipoEquipamento}
                    select
                    SelectProps  = {{native:true}}
                    InputLabelProps={{shrink : true}}
                    fullWidth
                    variant='outlined'>
                        <option value =''>TODOS</option>
                        <option value ='f'>EQUIPAMENTO</option>
                        <option value ='t'>ACESSÓRIO</option>
                    </TextField>
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label='Finalidade'
                    size = 'small'
                    inputRef={refFinalidade}
                    select
                    SelectProps  = {{native:true}}
                    InputLabelProps={{shrink : true}}
                    fullWidth
                    variant='outlined'>
                        <option value ='t'>LOCAÇÃO</option>
                        <option value ='f'>VENDA/TERCEIROS</option>
                        <option value =''>TODOS</option>
                    </TextField>
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label='Dsponibilidade'
                    inputRef={refDisponibilidade}
                    size = 'small'
                    select
                    SelectProps  = {{native:true}}
                    InputLabelProps={{shrink : true}}
                    fullWidth
                    variant='outlined'>
                        <option value =''>TODOS</option>
                        <option value ='f'>DISPONÍVEL</option>
                        <option value ='t'>INSTALADO</option>
                        
                    </TextField>
            </Grid>
        </Grid>
        <br/>
        <Grid container spacing = {1}>
            <Grid item xs = {1}>
                <TextField
                    label ='Placa'
                    inputRef={refPlaca}
                    variant ='outlined'
                    size = 'small'
                    fullWidth
                    InputLabelProps={{shrink : true}}
            
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                label ='Número de Série'
                inputRef={refNumeroSerie}
                variant ='outlined'
                size = 'small'
                fullWidth
                InputLabelProps={{shrink : true}}
        
               />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Marca'
                    inputRef={refMarca}
                    variant ='outlined'
                    size = 'small'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    
                   />
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Descrição do Modelo'
                    variant ='outlined'
                    size = 'small'
                    inputRef={refModelo}
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Setor'
                    inputRef={refSetor}
                    variant ='outlined'
                    size = 'small'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    
                   />
            </Grid>
            
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>{getEquipamentos()}}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>{clearForm()}}><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>

        
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Gestão de equipamentos</Typography>
            </Breadcrumbs>
        </div>




        <div className='div-form-container'>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
            <Table  className='table table-bordered table-striped table-hover ' style ={{fontSize:'10px'}}>
                <TableHead>
                    <TableCell align='center' style = {{width:"4%"}}>Finalidade</TableCell>
                    <TableCell align='center' style = {{width:"4%"}}>Tipo</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Número Série</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Placa</TableCell>
                    <TableCell align='left' style = {{width:"20%"}}>Descrição Equipamento</TableCell>
                    <TableCell align='left' style = {{width:"25%"}}>Instalado</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Status</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Editar</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Ativo</TableCell>
                </TableHead>
                <TableBody>
                {
                    equipamentos && equipamentos.map(function(equipamento, i){
                        return <TableRow key = {'LEQT'+i}>
                        <TableCell align='center' >
                            <Typography variant='caption'>{renderTipo(equipamento.locacao)}</Typography>
                        </TableCell>
                        <TableCell align='center' >
                            <Typography variant='caption'>{renderFinalidade(equipamento.acessorio)}</Typography>
                        </TableCell>
                        <TableCell align='center' >
                            <Typography variant='subtitle2' style ={{fontSize:'12px'}}>{equipamento.numeroserie}</Typography>
                        </TableCell>
                        <TableCell align='center' >
                            {
                                equipamento.locacao =='t' && (!equipamento.codigoadicional || equipamento.codigoadicional =='') ? <Button variant='outlined' color='primary' onClick={()=>{
                                    createPatrimonioEquipamento(equipamento);
                                }}><AutorenewTwoToneIcon/></Button> : <Typography variant='subtitle2' style ={{fontSize:'12px'}}>{equipamento.codigoadicional}</Typography>
                            }
                            
                        </TableCell>
                        <TableCell align='left' >
                            <Typography  variant='subtitle2' color='primary' style ={{fontSize:'12px'}}>{equipamento.modelo}</Typography>
                            <Typography  variant='caption' style ={{fontSize:'10px'}}>{equipamento.marca}</Typography>
                        </TableCell>
                        <TableCell align='left' >                          
                        <Typography  variant='subtitle2' color='primary' style ={{fontSize:'12px'}}>{equipamento.razaosocial}</Typography>                 
                        </TableCell>
                        <TableCell align='center' >
                            {renderStatus(equipamento.alertas, equipamento.alertasBloqueantes)}
                        </TableCell>
                        <TableCell align='center' ><ModalEditarEquipamento equipamento={equipamento} getData={()=>getEquipamentos()}/></TableCell>
                        <TableCell align='center' >
                        
                        <ButtonAtivoInativo 
                                conexao={Conexao}
                                label={equipamento.ativo =='t' ? 'Equipamento ativo, click para desativar' : 'Equipamento inativo, click para ativar'} 
                                controller={'equipamentosController'}
                                action={equipamento.ativo =='t' ? 'disableRegistro' : 'enableRegistro'}
                                idempresa={props.match.params.chaveEmpresa} 
                                identificador='idequipamento'
                                chave={equipamento.idequipamento}                                         
                                updateData = {()=>getEquipamentos()} />
                        </TableCell>
                    </TableRow>
                    })
                }
                {
                       equipamentos.length == 0  && <TableRow>
                       <TableCell colSpan={6} align='left'>* Não existe(m) registro(s)</TableCell>
                      
                   </TableRow>
                   }
                </TableBody>
            </Table>
        </Grid>
        </div>
        <div className='fabIncluir'>
            <ModalNovoEquipamento                
                idUsuario = {props.match.params.idempresa}
                idEmpresa={props.match.params.chaveEmpresa}
                getEquipamentos={()=>getEquipamentos()} />
        </div>

        

        <GestaoEquipamento 
             getData = {()=>getEquipamentos()}
             equipamentoSelecao = {equipamentoSelecao}
             limparEquipamentoSelecao = {()=>setEquipamentoSelecao(null)}
             idUsuario = {props.match.params.idempresa}
             idEmpresa={props.match.params.chaveEmpresa}/>
       
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroEquipamentos)