import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import DisplayStatus from '../../components/DisplayStatus'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import DraftsTwoToneIcon from '@material-ui/icons/DraftsTwoTone';
import MailTwoToneIcon from '@material-ui/icons/MailTwoTone';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ModalNovoChamado from './ModalNovoChamado'
import LanguageIcon from '@material-ui/icons/Language';//pendereWeb 1
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback'; // TIPO ABERTURA MANUAL
import ComputerIcon from '@material-ui/icons/Computer';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BuildIcon from '@material-ui/icons/Build';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import WarningIcon from '@material-ui/icons/Warning';
import ModalTriagemChamados from './Componentes/ModalTriagemChamados'
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import ModalVerChamado from './ModalVerChamadoTecnico'
import IconeAutomatico from '@material-ui/icons/Build'
import IconeOrcamento from '@material-ui/icons/AddIcCall'

const CadastroChamadosTecnicos = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading]= useState(false)

    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [paginas, setPaginas]= React.useState(1);

    const [tiposChamados, setTiposChamados] = useState([])
    const [situacoesChamados, setSituacoesChamados] = useState([])
    const [chamados, setChamados] = useState([])
    const [regioes, setRegioes] = useState([])
    const [tecnicos, setTecnicos] = useState([])
    const [equipamento, setEquipamento]= useState([])

    React.useEffect(()=>{
        getParametrosAberturaChamado();
        getChamadosAbertosGestao();
        getTecnicos();
        getRegioes();
    }, [])
    

  


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getParametrosAberturaChamado = () =>{
        Conexao.get('chamadosTecnicosController/getParametrosAberturaChamado').then(response=>{
            if(response.data.status == 'OK'){
                setSituacoesChamados(response.data.payload.situacoesChamados);
                setTiposChamados(response.data.payload.tiposChamados)
            }
        }).catch(error=>{

        });
    }

    const getChamadosAbertosGestao = () =>{
        setLoading(true)
        Conexao.post('chamadosTecnicosController/getChamadosAbertosGestao', {
            idusuario : props.location.state.empresa.idrevenda, 
            idempresa : props.location.state.empresa.idempresa
        }).then(response=>{
           
            if(response.data.status == 'OK'){
                setChamados(response.data.payload);
               
            }else{
                setChamados([]);
            }
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
        });
    }


    const getTecnicos = async() =>{

        Conexao.get('funcionariosController/getComboFuncionarios?idUsuario='+props.location.state.empresa.idrevenda+'&idEmpresa='+props.location.state.empresa.idempresa).then(response=>{

            if(response.data.status =='OK'){
                setTecnicos(response.data.payload)
            }else{
                setTecnicos([])
            }


        }).catch(error=>{
            setTecnicos([])
          
        })
        
    }

    const getRegioes = async() =>{

        Conexao.get('empresasController/getComboRegioes?idusuario='+props.location.state.empresa.idrevenda+'&idempresa='+props.location.state.empresa.idempresa).then(response=>{

            if(response.data.status =='OK'){
                setRegioes(response.data.payload)
            }else{
                setRegioes([])
            }


        }).catch(error=>{
            setRegioes([])
          
        })
        
    }

    const notificarListaDeTecnicos = (idregiao, titulo, mensagem, janela, mensagemJanela, remover ='F', chamado = 0) =>{
        const dados = tecnicos.filter(tecnico => (tecnico.idregiao == idregiao&& tecnico.idusuario == props.location.state.empresa.idrevenda && tecnico.chavepush)).map(function(tecnico){
            return tecnico.chavepush
        })
       
        if(dados){
            Conexao.post('chamadosTecnicosController/sendFCMulticast', {
                destino : dados, 
                titulo : titulo,  
                mensagem : mensagem,
                data :{
                    atualizarTela : janela, 
                    mensagem : mensagemJanela, 
                    removerChamado : remover, 
                    idChamado : chamado
                }
            }).then(response=>{

            }).catch(error=>{
                
            })
        }
    }

    const notificarTecnico = (idtecnico, titulo, mensagem, janela, mensagemJanela, remover ='F', chamado = 0) =>{
        const dados = tecnicos.filter(tecnico => (tecnico.idtecnico == idtecnico && tecnico.idusuario == props.location.state.empresa.idrevenda && tecnico.chavepush)).map(function(tecnico){
            return tecnico.chavepush
        })
       
       
        if(dados){
            Conexao.post('chamadosTecnicosController/pushNotification', {
                destino : dados[0], 
                titulo : titulo,  
                mensagem : mensagem,
                data :{
                    atualizarTela : janela, 
                    mensagem : mensagemJanela, 
                    removerChamado : remover,
                    idChamado : chamado
                }
            }).then(response=>{

            }).catch(error=>{
                
            })
        }
    }



   
    const renderSituacao = (situacao, idusuario, idempresa, idcliente,  idchamado) =>{
        
        switch(situacao){
            case 1: return <div style ={{textAlign:"center",}}><AccessAlarmIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>TRIAGEM</span> </div>;            
            case 2: return <div style ={{textAlign:"center", }}><DirectionsRunIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>A CAMINHO</span> </div>; 
            case 3: return <div style ={{textAlign:"center", }}><BuildIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>ATENDENDO</span> </div>; 
            case 4: return <div style ={{textAlign:"center", }}><AccessTimeIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>EM ANDAMENTO</span> </div>; 
            case 5: return <div style ={{textAlign:"center", }}><CheckIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>A. PEÇAS</span> </div>; 
            case 6: return <div style ={{textAlign:"center", }}><CheckIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>PRÉ FINALIZADO</span></div>; 
            case 7: return <div style ={{textAlign:"center", }}><WarningIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>SOLICITAÇÃO. RETORNO</span> </div>; 
            case 8: return <div style ={{textAlign:"center", }}><IconeOrcamento className='incon-small'/><br/><span style = {{fontSize:'9px'}}>ORÇAMENTO PENDENTE</span></div>; 
            case 9: return <div style ={{textAlign:"center", }}><DoneAllIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>CONCLUÍDA</span> </div>; 
            case 10: return <div style ={{textAlign:"center", }}><AttachFileIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>AGUARDANDO PEÇAS</span> </div>; 
            default: return 
        }
        /*
          https://printerconnect.com.br/api/chamadosTecnicosController/verChamadoTecnico?idusuario=1&idempresa=2&idcliente=3912&idchamado=33
        */
    }

    const renderStyleCol = (situacao) =>{
       /* switch(situacao){
            case 1 : return 'col-triagem';
            case 2 : return 'col-caminho';
            case 3 : return 'col-atendendo';
            case 4 : return 'col-andamento';
            case 5 : return 'col-pecas';
            case 6 : return 'col-finalizado';
            case 7 : return 'col-retorno';
            case 8 : return 'col-orcamento';
            case 9 : return 'col-concluida';
            case 10 : return 'col-parcial';
            case 11 : return 'col-cancelada';
        }*/
    }

    const renderTipoAbertura = (tipo) =>{
        
        switch(tipo){
            case 'MANUAL': return <div style ={{textAlign:"center", color:"#444"}}><PhoneCallbackIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>MANUAL</span> </div>; 
            case 'NOVA OCORRENCIA': return <div style ={{textAlign:"center", color:"#444"}}><IconeAutomatico className='incon-small'/><br/><span style = {{fontSize:'9px'}}>NOVA OCORRENCIA</span> </div>; 
            case 'WEB': return <div style ={{textAlign:"center", color:"#444"}}><ComputerIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>WEB</span> </div>; 
            case 'ALERTA': return <div style ={{textAlign:"center", color:"#444"}}><NotificationsActiveIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>ALERTA</span> </div>; 
            case 'TERMO E/S': return <div style ={{textAlign:"center", color:"#444"}}><PhoneCallbackIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>TERMO E/S</span> </div>; 
            case 'CHEK-LIST': return <div style ={{textAlign:"center", color:"#444"}}><FolderOpenIcon className='incon-small'/><br/><span style = {{fontSize:'9px'}}>CHEK-LIST</span> </div>;           
            default: return 
        }
    }

   

    return <Principal atualizar = {loading}>
    <Grid container spacing = {1}>
            <Grid item xs = {7}><Typography>Triagem de Chamados Técnicos</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
            <Grid item xs = {2}>
                <TextField
                    label ='Tipo de Data'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    size='small'   
                    select
                    SelectProps={{native:true}}
                >
                    <option>ABERTURA</option>
                    <option>ATIVAÇÃO</option>
                    <option>TRAJETO</option>
                    <option>ATENDIMENTO</option>
                    <option>FECHAMENTO</option>
                </TextField>
            </Grid>

            <Grid item xs = {2}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    size='small'   
                   />
            </Grid>
            <Grid item xs = {1}>
                <TextField
                    label ='Chamado'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    size='small'    
                   />
            </Grid>
            <Grid item xs = {1}>
                <TextField
                    label ='O.S'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    size='small'    
                   />
            </Grid>
            <Grid item xs = {1}>
                <TextField
                    label ='Placa'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    size='small'    
                   />
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Número Serie'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    size='small'              
                   />
            </Grid>
            
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getChamadosAbertosGestao()}><SearchIcon/> Buscar</Button>
                <Button color='secondary' variant='contained' ><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
        <Grid container spacing = {2}>
            <Grid item xs = {4}>
                <TextField
                    label ='Modelo'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    size='small'              
                   />
            </Grid>
            <Grid item xs = {4}>
                <TextField
                    label ='Cliente'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    size='small'              
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Setor'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    size='small'              
                   />
            </Grid>
           
        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Triagem de Chamados Técnicos</Typography>
            </Breadcrumbs>
        </div> 
        <div className='div-form-container'>
    <Grid container  spacing={1}>
        <Table  className='table table-bordered table-striped table-hover'>
            <TableHead>
                <TableCell align='center' style = {{width:"5%"}}>#</TableCell>
                <TableCell align='center' style = {{width:"7%"}}>Chamado</TableCell>
                <TableCell align='center' style = {{width:"7%"}}>Abertura</TableCell>
                <TableCell align='left' style = {{width:"30%"}}>Cliente/Equipamento</TableCell>
                <TableCell align='center' style = {{width:"10%"}}>Tipo</TableCell>                
                <TableCell align='center' style = {{width:"7%"}}>Abertura</TableCell>
                <TableCell align='center' style = {{width:"8%"}}>Status</TableCell>
                
            </TableHead>
            <TableBody>
                {
                    chamados && chamados.map(function(chamado, i){
                        return <TableRow key ={i}>
                        <TableCell align='center' style = {{width:"5%"}}>{  
                         chamado.ativada =='t'  ? <DraftsTwoToneIcon style ={{cursor:"pointer"}} className='incon-small' onClick={()=>{
                             if(chamado.idsituacaochamadotecnico != 4 && chamado.idsituacaochamadotecnico != 5){
                                Swal.fire({
                                    title : 'Printerconnect', 
                                    text : 'Não é possivel retirar da fila um chamado que está em atendimento ou ja foi concluído', 
                                    icon : 'warning', 
                                    showConfirmButton : true,                                
                                    confirmButtonText : 'OK', 
                                    showCancelButton : false, 
                                    cancelButtonText : 'Não', 
                                    
                                  })
                                  return
                             }
                            Swal.fire({
                                title : 'Printerconnect', 
                                text : 'Deseja retirar este chamado da fila de atendimento agora ?', 
                                icon : 'question', 
                                showCancelButton : true, 
                                confirmButtonText : 'Sim', 
                                showCancelButton : true, 
                                cancelButtonText : 'Não'
                              }).then(resposta =>{
                                if(resposta.isConfirmed){                                    

                                    Swal.fire({
                                
                                        icon: 'info',
                                        title: 'Aguarde....processando',
                                        showConfirmButton: false,
                                        timer: 1500
                                    })
                                  Conexao.get('chamadosTecnicosController/removeChamadoFilaTecnico?idUsuario='+chamado.idusuario+'&idEmpresa='+chamado.idempresa+'&idChamado='+chamado.idchamadotecnico).then(response=>{
                                      if(response.data.status =='OK'){
                                        if(chamado.idregiao !=  0 && chamado.idtecnico == 0){
                                            notificarListaDeTecnicos(chamado.idregiao, 'Printerconnect - Aviso de retirada de chamado', 'Um chamado técnico foi removido da sua região', 'Regiao', 'Chamado removido da sua região' , 'T', chamado.idchamadotecnico)
                                        }else if(chamado.idregiao == 0 && chamado.idtecnico != 0){
                                            notificarTecnico(chamado.idtecnico, 'Printerconnect - Aviso de retirada de chamado', 'Um chamado técnico foi removido da sua fila de atendimento', 'meusChamados', 'Chamado técnico removido da sua fila' ,'T', chamado.idchamadotecnico)
                                        }
                                        Swal.fire({
                                            title : 'Printerconnect', 
                                            text : 'Chamado retornado com sucesso', 
                                            icon : 'success', 
                                            showCancelButton : false, 
                                            confirmButtonText : 'Sim', 
                                            showCancelButton : false, 
                                            cancelButtonText : 'Não', 
                                            timer : 1000
                                        })
                                        getChamadosAbertosGestao();
                                      } else{
                                        Swal.fire({
                                            title : 'Printerconnect', 
                                            text : 'Não foi possível realizar operação', 
                                            icon : 'warning', 
                                            showCancelButton : false, 
                                            confirmButtonText : 'Sim', 
                                            showCancelButton : false, 
                                            cancelButtonText : 'Não', 
                                            timer : 1000
                                        })
                                      }
                                  }).catch(error=>{
                                      console.log(error)
                                    Swal.fire({
                                        title : 'Printerconnect', 
                                        text : 'Não foi possível realizar operação', 
                                        icon : 'error', 
                                        showCancelButton : false, 
                                        confirmButtonText : 'Sim', 
                                        showCancelButton : false, 
                                        cancelButtonText : 'Não', 
                                        timer : 1000
                                    })
                                  })
                                }
                              });
                         }}/> :    <ModalTriagemChamados 
                                        chamado={chamado} 
                                        getData={()=>getChamadosAbertosGestao()} 
                                        notificarTecnico = {(tecnico, titulo, msg, tela, msgTela)=>{
                                  
                                            notificarTecnico(tecnico, titulo, msg, tela, msgTela)
                                        }}
                                        notificarRegiao = {(tecnico, titulo, msg, tela, msgTela)=>{
                                            notificarListaDeTecnicos(tecnico, titulo, msg, tela, msgTela)
                                        }}
                                    />                    
                           
                    }   </TableCell>
                        
                        <TableCell align='center' style = {{width:"10%"}}><span variant='subtitle2' color='primary' style={{fontSize:'11px'}}>{chamado.idchamadotecnico+'/'+chamado.sequenciaatendimento}</span></TableCell>
                        <TableCell align='center' style = {{width:"10%"}}><span variant='subtitle2' color='primary' style={{fontSize:'11px'}}>{chamado.dataabertura}</span></TableCell>
                        <TableCell align='left' style = {{width:"35%"}}>
                            <Typography variant='subtitle2' color='primary' className='texto-limitado'><strong>{chamado.razaosocial}</strong></Typography>
                            <span variant='caption' color='primary' style={{fontSize:'11px'}}>{chamado.numeroserie+' - '+chamado.modelo}</span>
                        </TableCell>
                        <TableCell align='center' style = {{width:"20%"}}><span variant='subtitle2' color='primary' style={{fontSize:'11px'}} >{chamado.tipo}</span></TableCell>                
                        <TableCell align='center' style = {{width:"10%"}}>
                            {
                                
                                renderTipoAbertura( chamado.tipoabertura )
                            }
                        </TableCell>
                        <TableCell align='center' style = {{width:"10%"}} className={renderStyleCol(chamado.idsituacaochamadotecnico)}>
                            <ModalVerChamado getChamadosAbertosGestao={()=>getChamadosAbertosGestao()}  chamado={chamado} icone={renderSituacao( chamado.idsituacaochamadotecnico, chamado.idusuario, chamado.idempresa, chamado.idcliente, chamado.idchamadotecnico )}/>
                 
                        </TableCell>
                        
                    </TableRow>
                    })
                }
            </TableBody>

    
           
              
        </Table>
    </Grid>
    </div>
    <div className='fabIncluir'>
        <ModalNovoChamado updateData = {()=>getChamadosAbertosGestao()}  idusuario={props.location.state.empresa.idrevenda} idempresa={props.location.state.empresa.idempresa} tiposChamado = {tiposChamados} situacoesChamado ={situacoesChamados} />
    </div>


    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroChamadosTecnicos)