import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import DisplayStatus from '../../components/DisplayStatus'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';

import Pagination from '@material-ui/lab/Pagination';

import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ModalNovoVeiculo from './ModalNovoVeiculo'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ModalMarcas from './ModalMarcas'
import ModalModelos from './ModalModelos'
import ModalEditaVeiculo from './ModalEditarVeiculo'
import ModalVinculaRastreador  from './ModalVinculaRastreador'

const LicencasCollector = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)

   
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [paginas, setPaginas]= React.useState(1);
    const [ registros, setRegistros] = useState(0)
    const [veiculos, setVeiculos]= useState([])

    const refPlaca = useRef();
    const refFrota = useRef();
    const refModelo = useRef();

    

    useEffect(()=>{
   
        getVeiculos()
    }, [page])

    useEffect(()=>{
        if(!openModal){
        
        }
        getVeiculos()
    }, [])

    


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getVeiculos = () =>{
        setLoading(true)
        Conexao.post('veiculosController/getVeiculos',{
            idusuario : props.match.params.idempresa, 
            idempresa : props.match.params.chaveEmpresa, 
            placa : refPlaca.current ? refPlaca.current.value : '', 
            frota : refFrota.current ? refFrota.current.value : '', 
            modelo : refModelo.current ? refModelo.current.value : '', 
        }).then(response=>{
            if(response.data.status =='OK'){
                setVeiculos(response.data.payload)
            }else{
                setVeiculos([])
            }
            setLoading(false)
        }).catch(error=>{
            setVeiculos([])
            setLoading(false)
        });
    }




   

    return <Principal atualizar = {loading}>
    <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Cadastro de Veículos</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>

            <Grid item xs = {2}>
                <TextField
                    label ='Placa'
                    inputRef={refPlaca}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Frota'
                    inputRef={refFrota}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                        
                   />
            </Grid>
            <Grid item xs = {5}>
                <TextField
                    label ='Modelo'
                    inputRef={refModelo}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                                  
                   />
            </Grid>
            <Grid item xs = {3} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getVeiculos()} ><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' ><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Cadastro de Veículos</Typography>
            </Breadcrumbs>
        </div> 
        <div className='div-form-container'>
    <Grid container style = {{marginTop:"10px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-sm' style ={{fontSize:'12px'}}>
            <TableHead>
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Placa</TableCell>
                <TableCell align='center' style = {{width:"5%",fontSize:'12px'}}>Frota</TableCell>
                <TableCell align='left' style = {{width:"15%",fontSize:'12px'}}>Marca</TableCell>
                <TableCell align='left' style = {{width:"35%",fontSize:'12px'}}>Modelo</TableCell> 
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Odômetro</TableCell>               
                <TableCell align='center' style = {{width:"15%",fontSize:'12px'}}>Rastreador</TableCell>
                <TableCell align='center' style = {{width:"5%",fontSize:'12px'}}>Editar</TableCell>
                <TableCell align='center' style = {{width:"5%",fontSize:'12px'}}>Inativar</TableCell>
            </TableHead>

            <TableBody>
                {
                    veiculos && veiculos.map(function(veiculo, i){
                        return <TableRow>
                            <TableCell align='center'>{veiculo.placa}</TableCell>
                            <TableCell align='center'>{veiculo.frota}</TableCell>
                            <TableCell align='left'>{veiculo.marca}</TableCell>
                            <TableCell align='left'>{veiculo.modelo}</TableCell>
                            <TableCell align='center'>{veiculo.odometro}</TableCell>
                            <TableCell align='center'>{veiculo.idrastreador ? veiculo.idrastreador : <ModalVinculaRastreador getVeiculos={()=>getVeiculos()} placa = {veiculo.placa} modelo={veiculo.modelo} usuario={props.match.params.idempresa} />}</TableCell>
                            <TableCell align='center'><ModalEditaVeiculo veiculo = {veiculo} usuario={props.match.params.idempresa} getVeiculos={()=>{
            getVeiculos()
        }}/></TableCell>
                            <TableCell align='center'><DeleteIcon color='secondary' style ={{cursor:'pointer'}} onClick = {()=>{
                                Swal.fire({
                                    title : 'Printerconnect - Frota', 
                                    text : 'Deseja realmente inativar este veículo ?', 
                                    icon : 'question', 
                                    showCancelButton : true, 
                                    showConfirmButton : true
                                }).then(resposta =>{
                                    if(resposta.isConfirmed){
                                        Conexao.post('veiculosController/deleteVeiculo', {
                                            idusuario : props.match.params.idempresa, 
                                            placa : veiculo.placa, 
                                            status : 'f'
                                        }).then(response=>{
                                            if(response.data.status =='OK'){
                                                getVeiculos();
                                                Swal.fire({
                                                    title : 'Printerconnect - Frota', 
                                                    text : 'Veículo inativado com sucesso!', 
                                                    icon:'success', 
                                                    showCancelButton:false, 
                                                    showConfirmButton : false, 
                                                    timer : 1000
                                                })
                                            }else{
                                                Swal.fire({
                                                    title : 'Printerconnect - Frota', 
                                                    text : 'Não foi possível inativar veículo', 
                                                    icon:'warning', 
                                                    showCancelButton:false, 
                                                    showConfirmButton : false, 
                                                    timer : 1000
                                                })
                                            }
                                        }).catch(error=>{
                                            Swal.fire({
                                                title : 'Printerconnect - Frota', 
                                                text : 'Não foi possível inativar veículo', 
                                                icon:'error', 
                                                showCancelButton:false, 
                                                showConfirmButton : false, 
                                                timer : 1000
                                            })
                                        })
                                    }
                                })
                            }}/></TableCell>
                        </TableRow>
                    })
                }
           
               
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>
    <div className='fabIncluir'>
        <div style ={{paddingBottom:'10px'}}>
            <ModalModelos usuario={props.match.params.idempresa}/>
        </div>
        <div style ={{paddingBottom:'10px'}}>
            <ModalMarcas usuario={props.match.params.idempresa}/>
        </div>
       
        <ModalNovoVeiculo getVeiculos={()=>{
            getVeiculos()
        }} usuario={props.match.params.idempresa}/>
    </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(LicencasCollector)