import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {Grid, Container, InputAdornment, TextField, Divider} from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print';
import PersonIcon from '@material-ui/icons/Person';
import ModalBuscaEquipamentoChamado from './ModalBuscaEquipamentoChamado'
import Swal from 'sweetalert2'
import Conexao from '../../providers/Conexao'
import { Alert, AlertTitle } from '@material-ui/lab';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoChamado(props) {
  const [open, setOpen] = React.useState(false);
  const refTipoChamado = React.useRef();
  const refReclamante = React.useRef();
  const refEmailReclamante = React.useRef();
  const refContadorMono = React.useRef();
  const refContadorColor = React.useRef();
  const refContadorTotal = React.useRef();
  const refDefeito = React.useRef();
  const [equipamento, setEquipamento] = React.useState({
    codigoadicional : '', 
    numeroserie : '', 
    idcliente : '', 
    razaosocial : '', 
    marca : '', 
    modelo : '', 
    setor : ''
  });
  const [chamadosAbertosSerial, setChamadosAbertosSerial] = React.useState(false);


  React.useEffect(()=>{
    if(equipamento && equipamento.numeroserie.trim() != ''){
     chamadoAbertoBySerial(equipamento);
    }
 
 }, [equipamento])

 const chamadoAbertoBySerial = async(equipamento) =>{
     Conexao.get('chamadosTecnicosController/chamadoAbertoBySerial?idUsuario='
     +equipamento.idusuario+'&idEmpresa='+equipamento.idempresa+'&numeroSerie='+equipamento.numeroserie).then(response=>{
      if(response.data.status =='OK'){
        setChamadosAbertosSerial(response.data.payload[0].total > 0 ?true : false)
        if(response.data.payload[0].total > 0){
          Swal.fire({
            title : 'Printerconnct', 
            text : `Existe(m) ${response.data.payload[0].total } aberto(s) para este número de série !`, 
            icon : 'warning', 
            showConfirmButton : false, 
            showCancelButton : false, 
            timer : 1500,
          });
        }
      }else{
        setChamadosAbertosSerial(0)
      }
     }).catch(error=>{
      setChamadosAbertosSerial(0)
     })
 }

  const handleClickOpen = () => {
    setOpen(true);
    setEquipamento({
      codigoadicional : '', 
      numeroserie : '', 
      idcliente : '', 
      razaosocial : '', 
      marca : '', 
      modelo : '', 
      setor : ''
    })
    setChamadosAbertosSerial(false)
  };
  const handleClose = () => {
    setOpen(false);
  };

  const insertNovoChamadoTecnico = () =>{
    if(refTipoChamado.current && refTipoChamado.current.value == -1){
      Swal.fire({
        title : 'Printerconnct', 
        text : 'Informe o tipo de atendimento', 
        icon : 'warning', 
        showConfirmButton : false, 
        showCancelButton : false, 
        timer : 1500,
      });
      return;
    }


    if(equipamento.numeroserie == ''){
      Swal.fire({
        title : 'Printerconnct', 
        text : 'Selecione um equipamento !', 
        icon : 'warning', 
        showConfirmButton : false, 
        showCancelButton : false, 
        timer : 1500,
      });
      return;
    }
    Swal.fire({
                                
      icon: 'info',
      title: 'Aguarde....processando',
      showConfirmButton: false,
      timer: 1500
  })

    Conexao.post('chamadosTecnicosController/insertNovoChamadoTecnico', {
      idusuario : props.idusuario, 
      idempresa : props.idempresa, 
      idcliente : equipamento.idcliente,
      idcontrato : equipamento.idcontrato, 
      numeroserie : equipamento.numeroserie,  
      idsituacao : 1, 
      idtipo : refTipoChamado.current ? refTipoChamado.current.value : -1, 
      reclamante : refReclamante.current ? refReclamante.current.value : '',  
      email : refEmailReclamante.current ? refEmailReclamante.current.value : '',
      contadorMono : refContadorMono.current ? refContadorMono.current.value : 0,  
      contadorColor : refContadorColor.current ? refContadorColor.current.value : 0,  
      contadorTotal : refContadorTotal.current ? refContadorTotal.current.value : 0,   
      defeito : refDefeito.current ? refDefeito.current.value : '',    

    }).then(response=>{
      if(response.data.status== 'OK'){
        Swal.fire({
          title : 'Printerconnct', 
          text : 'Ordem de Serviço aberta com sucesso !', 
          icon : 'success', 
          showConfirmButton : false, 
          showCancelButton : false, 
          timer : 1500,
        });
        setOpen(false)
        props.updateData();
        
      }else{
        Swal.fire({
          title : 'Printerconnct', 
          text : 'Não foi possível abrir Ordem de Serviço', 
          icon : 'warning', 
          showConfirmButton : false, 
          showCancelButton : false, 
          timer : 1500,
        });
        setOpen(false)
      }
    }).catch(error=>{
      Swal.fire({
        title : 'Printerconnct', 
        text : 'Não foi possível abrir Ordem de Serviço', 
        icon : 'error', 
        showConfirmButton : false, 
        showCancelButton : false, 
        timer : 1500,
      });
      setOpen(false)
    });
  }

  return (
    <div>

         <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
            <AddIcon />
        </Fab>
      <Dialog fullWidth maxWidth='md' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastro de Novo Chamado 
        </DialogTitle>
        <DialogContent dividers>
        <Grid container spacing = {2}>
                <Grid item xs ={4}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Tipo de Atendimento'
                        variant='outlined'
                        inputRef={refTipoChamado}
                        placeholder='Selecione equipamento'
                        InputLabelProps ={{shrink : true}}
                        select 
                        SelectProps={{native : true}}
                      >
                        <option value ='-1'></option>
                        {
                          props.tiposChamado && props.tiposChamado.map(function(tipo, i){
                            return <option value ={tipo.idtipochamadotecnico} key={'opt'+i}>{tipo.descricao}</option>
                          })
                        }
                    </TextField>
                </Grid>

                <Grid item xs ={3}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Status do atendimento'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}
                        select 
                        SelectProps={{native : true}}
                      >
                        <option value ='1' >PENDENTE TRIAGEM</option>
                        
                    </TextField>
                </Grid>
                <Grid item xs ={3}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Número Série'
                        value={equipamento.numeroserie}
                        variant='outlined'
                        placeholder='Selecione equipamento'
                        InputLabelProps ={{shrink : true}}
                        InputProps ={{
                            startAdornment : <InputAdornment position ='start'>
                              <ModalBuscaEquipamentoChamado 
                                getEquipamento = {(equipamento)=>setEquipamento(equipamento)}
                                idusuario = {props.idusuario} 
                                idempresa = {props.idempresa}/>
                              </InputAdornment>
                        }}
                         />
                </Grid>
                <Grid item xs ={2}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Patrimônio'
                        value={equipamento.codigoadicional}
                        variant='outlined'
                        placeholder='Placa'
                        InputLabelProps ={{shrink : true}}                        
                         />
                </Grid>
          </Grid>
          <br/>
            <Grid container spacing = {2}>
                
                <Grid item xs ={5}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Marca'
                        value={equipamento.marca}
                        variant='outlined'
                        placeholder='Marca do equipamento'
                        InputLabelProps ={{shrink : true}}                        
                         />
                </Grid>
                <Grid item xs ={7}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Modelo'
                        value={equipamento.modelo}
                        variant='outlined'
                        placeholder='Modelo do equipamento'
                        InputLabelProps ={{shrink : true}}                                              
                         />
                </Grid>
               
            </Grid>
            <br/>
            <Grid container spacing = {2}>
              <Grid item xs ={2}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Código'
                        placeholder='Código'
                        value={equipamento.idcliente}
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}            
                        InputProps ={{
                            startAdornment : <InputAdornment position ='start'><PersonIcon color='primary'/></InputAdornment>
                        }}             
                         />
                </Grid>
              <Grid item xs ={6}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Cliente'
                        placeholder='Razão social'
                        value={equipamento.razaosocial}
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}            
                                    
                         />
                </Grid>
                <Grid item xs ={4}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Setor'
                        placeholder='Setor'
                        value={equipamento.setor}
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}            
                                    
                         />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing = {2}>
              <Grid item xs ={4}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Nome Reclamante'
                        placeholder='Informe o nome do solicitante...'
                        variant='outlined'
                        inputRef = {refReclamante}
                        inputProps={{
                          maxlength : 60
                        }}   
                        InputLabelProps ={{shrink : true}}                                                
                         />
                </Grid>
                <Grid item xs ={8}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Email Reclamante'
                        inputProps={{
                          maxlength : 250
                        }}   
                        inputRef={refEmailReclamante}
                        placeholder='Informe o email do solicitante...'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                                                
                         />
                </Grid>
          </Grid>
          <br/>
            <Grid container spacing = {2}>
              <Grid item xs ={2}>
                <TextField
                        size ='small'
                        fullWidth
                        label ='Contador Mono'
                        inputRef={refContadorMono}
                        defaultValue={0}
                        placeholder='Contador Mono informado'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                                                
                         />
              </Grid>
              <Grid item xs ={2}>
                <TextField
                        size ='small'
                        fullWidth
                        label ='Contador Color'
                        defaultValue={0}
                        inputRef={refContadorColor}
                        placeholder='Contador Color informado'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                                                
                         />
              </Grid>
              <Grid item xs ={2}>
                <TextField
                        size ='small'
                        fullWidth
                        label ='Contador Total'
                        defaultValue={0}
                        inputRef={refContadorTotal}
                        placeholder='Contador Total informado'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                                                
                         />
              </Grid>
              <Grid item xs ={6}>
                <TextField
                        size ='small'
                        fullWidth
                        label ='Defeito relatado (até 500 caracteres)'
                        placeholder='Informe o defeito reclamado'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}     
                        inputRef={refDefeito}
                        multiline
                        rows={5}
                        rowsMax={5}  
                        inputProps={{
                          maxlength : 500
                        }}                                         
                         />
              </Grid>
            
            </Grid>
         
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            CANCELAR
          </Button>

          <Button autoFocus onClick={()=>{
            Swal.fire({
              title : 'Printerconnect', 
              text : 'Deseja gravar esta Ordem de Serviço ?', 
              icon : 'question', 
              showCancelButton : true, 
              confirmButtonText : 'Sim', 
              showCancelButton : true, 
              cancelButtonText : 'Não'
            }).then(resposta =>{
              if(resposta.isConfirmed){
                insertNovoChamadoTecnico()
              }
            });
          }} color="primary" variant='contained'>
            GRAVAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
