import React,{useState, useEffect, useRef} from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom'



const TemplateMain = function(props){

    
    return <div class="dropdown">
    <button class="mainmenubtn">Main Menu</button>
    <div class="dropdown-child">
      <a href="http://wwww.yourdomain.com/page1.html">Sub Menu 1</a>
      <a href="http://wwww.yourdomain.com/page2.html">Sub Menu 2</a>
      <a href="http://wwww.yourdomain.com/page3.html">Sub Menu 3</a>
      <a href="http://wwww.yourdomain.com/page4.html">Sub Menu 4</a>
      <a href="http://wwww.yourdomain.com/page5.html">Sub Menu 5</a>
    </div>
  </div>
}

export default withRouter(TemplateMain)