import React, {useState, useEffect} from 'react'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Conexao from '../../providers/Conexao'
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CachedIcon from '@material-ui/icons/Cached';
import CommentIcon from '@material-ui/icons/Comment';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WarningIcon from '@material-ui/icons/Warning'
import IconeACaminho from '@material-ui/icons/DirectionsRun'
import IconeAtendendo from '@material-ui/icons/Build'
import IconePecas from '@material-ui/icons/AttachFile'
import IconePreFinalizado from '@material-ui/icons/HowToReg'
import IconeRetorno from '@material-ui/icons/TransferWithinAStation'
import IconeOrcamento from '@material-ui/icons/AddIcCall'

export default function(props){

    const {chamado} = props

    
    const renderProgressBar = (nivel, status, tipoAtendimento,descricao) =>{
        //4,3,8,9
        if(tipoAtendimento == 3 ){
            return <div className='rotulo-install'>{descricao}</div>
        }else if(tipoAtendimento == 4){
            return <div className='rotulo-retirada'>{descricao}</div>
        }else if(tipoAtendimento == 8){
            return <div className='rotulo-preventiva'>PREVENTIVA</div>
        }else if(tipoAtendimento == 9){
            return <div className='rotulo-garantia'>{descricao}</div>
        }

        if(nivel == 0 && (status == 4 || status == 5)){
            return <WarningIcon className='icon-min-linha texto-limitado'/>
        }

        let cor = '#0f0';

        if(nivel >= 90){
            cor = '#0f0';
            
        }else if(nivel > 75 && nivel < 90){
            cor = 'yellow';

        }else if( nivel <75 && nivel >= 50){
            cor = '#f50';

        }else if(nivel <50 && nivel >= 25){
            cor = 'orange';

        }else{
            cor = '#f00';

        }

        return <div className='progress-container-main'>
            <div style ={{width : nivel+'%', height:"100%", backgroundColor:cor}}></div>
        </div>
    }

    const renderIconeStatus = (status) =>{
        switch(status){
            case 2: return <IconeACaminho style={{fontSize:'16px'}}/>
            case 3: return <IconeAtendendo style={{fontSize:'16px'}}/>
            case 4: return <AccessTimeIcon style={{fontSize:'16px'}}/>
            case 5: return <IconePecas style={{fontSize:'16px'}}/>
            case 6: return <IconePreFinalizado style={{fontSize:'16px'}}/>
            case 7: return <IconeRetorno style={{fontSize:'16px'}}/>
            case 8: return <IconeOrcamento style={{fontSize:'16px'}}/>
           
        }
    }



    return <div  
                className={'chamado-linha-flex texto-protegido status'+chamado.situacao }                 
                id={'chamado'+chamado.idchamadotecnico} 
                onDoubleClick={()=>{
                    if(chamado.situacao == 4  || chamado.situacao == 5){
                        props.setChamadoMover(chamado);                        
                    }else{

                    }
                    
                    }}>
                <div className='linha-flex-chamado' style ={{paddingTop:'3px'}}>{chamado && chamado.idchamadotecnico+'/'+chamado.sequenciaatendimento}</div>
                <div className='linha-flex-data' style ={{paddingTop:'3px'}}>{chamado && chamado.dataabertura}</div>
                <div className='linha-flex-empresa texto-limitado' style ={{paddingTop:'3px'}}>{chamado && chamado.razaosocial}</div>
                <div className='linha-flex-progress' style ={{paddingTop:'3px'}}>{renderProgressBar((chamado && chamado.nivelsla ? chamado.nivelsla :0),chamado.situacao, chamado.tipoatendimento, chamado.descricaoatendimento ) }</div>
                <div className='linha-flex-icone'>{renderIconeStatus(chamado && chamado.situacao ? chamado.situacao: 3)}</div>
                
            </div>
}