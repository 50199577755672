import React from 'react'
import {Jumbotron, Button} from 'react-bootstrap'
import Principal from '../pages/Principal/Principal'

export default function(props){
    return <Principal>
        <Jumbotron>
    <h1 className='display-gg'>401</h1>
    <p>
      Você não possui previlégios para acessar este recurso!
    </p>
    
  </Jumbotron>
  </Principal>
}