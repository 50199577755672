import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, Grid, TextField} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Icone from '@material-ui/icons/Search'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Close'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = useState(false)
    const [alterando, setAlterando]= useState(true)



    const handleClose = () =>{
        setOpen(false)
    }
       
    const handleClickOpen = () =>{
        setOpen(true)
    }
  return (
    <div>
      <Tooltip title='Selecionar uma marca cadastrada'>
        <Icone  onClick={handleClickOpen} style ={{cursor:'pointer'}}/>
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        fullWidth
        hideBackdrop
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastro de Marcas
        </DialogTitle>
        <DialogContent dividers style = {{height:"350px", overflow:"auto"}}>
         <Grid container spacing={1}>
            <Grid item xs={8}>
                <TextField
                    label='Descrição da marca'
                    variant='outlined'
                    fullWidth
                    size='small'
                    InputLabelProps={{shrink:true}}
                    />
            </Grid>
            <Grid item xs ={2} style = {{justifyContent:'center', padding:'5px'}}>
            <Button variant='contained' color='primary'><Icone/></Button>
            </Grid>            
         </Grid>
         <Grid container spacing={1}>
            <Grid item xs={8}>
                <TextField
                    label='Descrição da marca'
                    variant='outlined'
                    fullWidth
                    size='small'
                    InputLabelProps={{shrink:true}}
                    />
            </Grid>
            <Grid item xs={2}><Button variant='outlined' color='secondary' fullWidth><CancelIcon/></Button></Grid>
            <Grid item xs={2}><Button variant='outlined' color='primary' fullWidth><SaveIcon/></Button></Grid>
          </Grid>
        </DialogContent>
       
      </Dialog>
    </div>
  );
}


export default withRouter(ModalPadrao)