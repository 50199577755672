import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ModalNovoDca from './ModalNovoDca'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';


const CadastroDca = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
  
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dcas, setDcas] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [ registros, setRegistros] = useState(0)
    const refTipoDataPesquisa = React.createRef();
    const refDataPesquisa = React.createRef();
    const refChaveInstalacao = React.createRef();
    const refRazaoSocial = React.createRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  

    useEffect(()=>{
        getDcas();
    }, [page])


    const getDcas = () =>{
        setLoading(true)
        Conexao.post('dcaController/getDcaBilling', {
            idusuario : props.match.params.idempresa, 
            idempresa : props.match.params.chaveEmpresa, 
            tipoData : refTipoDataPesquisa.current ? refTipoDataPesquisa.current.value : '', 
            dataPesquisa : refDataPesquisa.current ? refDataPesquisa.current.value : '', 
            chaveInstalacao : refChaveInstalacao.current ? refChaveInstalacao.current.value : '', 
            razaoSocial : refRazaoSocial.current ? refRazaoSocial.current.value : '', 
            limit: rowsPerPage,
            offSet :  page

        }).then( response =>{
            if(response.data.status == 'OK'){
                setDcas(response.data.payload.dados)
                setRegistros(response.data.payload.registros)
            }else{
                setDcas([])
            }
            setLoading(false)
        }).catch( error =>{
            setDcas([])
            setLoading(false)
        });
    }

   

    const renderUltimoAcesso = (acesso) =>{
        if(acesso == 'printerconnect-app-collector'){
                return <div><PersonIcon color='primary' /><br/><Typography variant='caption' color='primary'>MANUAL</Typography></div>
        }else{
            return <div><SettingsApplicationsIcon  color='primary'/><br/><Typography variant='caption' color='primary'>SERVICE</Typography></div>
        }
    }

    return <Principal atualizar = {loading} getData={getDcas}>
        <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Controle de DCA (Printerconnect Billing)</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
            <Grid item xs = {2}>
                <TextField
                    label ='Data para a Pesquisa'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    select
                    SelectProps={{native : true}}
                    inputRef={refTipoDataPesquisa}
                    >
                    <option value = 'F'></option>
                    <option>CRIAÇÃO</option>
                    <option>INSTALAÇÃO</option>
                    <option>ACESSO</option>
                    </TextField>
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    inputRef={refDataPesquisa}             
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Chave de Instalação'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    inputRef={refChaveInstalacao}                  
                   />
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Razão Social'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    inputRef={refRazaoSocial}                   
                   />
            </Grid>
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getDcas()}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>{
                    refTipoDataPesquisa.current.value = ''
                    refDataPesquisa.current.value = ''
                    refChaveInstalacao.current.value = ''
                    refRazaoSocial.current.value = ''
                }}><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Controle de DCA</Typography>
            </Breadcrumbs>
        </div>
       <div className='div-form-container'>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
            <Table  >
                <TableHead>
                 
                    <TableCell align='left' style = {{width:"20%"}}>Chave Indentificação</TableCell>
                    <TableCell align='left' style = {{width:"40%"}}>Cliente</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Criação</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Instalação</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Acesso</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Status</TableCell>
                </TableHead>
                <TableBody>
                   
                   {
                       dcas && dcas.map(function(dca, i){
                           return <TableRow>
                           <TableCell align='left'>{dca.chavecoletor}</TableCell>
                           <TableCell align='left'>{dca.razaosocial}</TableCell>
                           <TableCell align='center'>
                               <LabeData data = {dca.datacadastro} hora ={dca.horacadastro.substr(0,8)} fontaData = {9} fontHora = {9}/>
                               </TableCell>
                           <TableCell align='center'>
                            <LabeData data = {dca.dataativacao && dca.dataativacao} hora ={dca.horaativacao && dca.horaativacao.substr(0,8)} fontaData = {9} fontHora = {9}/>
                               </TableCell>
                           <TableCell align='center'>
                                <LabeData data = {dca.dataatualizacao && dca.dataatualizacao} hora ={dca.horaatualizacao && dca.horaatualizacao.substr(0,8)} fontaData = {9} fontHora = {9}/>
                               </TableCell>
                      
                           <TableCell align='center'>{renderUltimoAcesso(dca.ultimoacessovia)}</TableCell>
                       </TableRow>
                       })
                   }
                   {
                       dcas.length == 0  && <TableRow>
                       <TableCell colSpan={6} align='left'>* Não existe(m) registro(s)</TableCell>
                      
                   </TableRow>
                   }
                </TableBody>
            </Table>
        </Grid>   
        </div>                  
    
        <div className='fabIncluir'>
        <ModalNovoDca idusuario={props.match.params.idempresa} idempresa={props.match.params.chaveEmpresa} getData = {()=>getDcas()}/>
        </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroDca)