import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {Grid, Container, InputAdornment, TextField, Divider, Tooltip} from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print';
import PersonIcon from '@material-ui/icons/Person';
import ModalBuscaEquipamentoChamado from './ModalBuscaEquipamentoChamado'
import Swal from 'sweetalert2'
import Conexao from '../../providers/Conexao'
import { Alert, AlertTitle } from '@material-ui/lab';
import IconeFoto from '@material-ui/icons/PhotoAlbum'
import IconAssinatura from '@material-ui/icons/Brush'
import ModalAssinatura from './ModalVerAssinaturaChamado'
import ModalVerImagens from './ModalVerImagensChamado'
import ConcluirIcone from '@material-ui/icons/Done'
import IconeCancelar from '@material-ui/icons/Block'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoChamado(props) {
  const [open, setOpen] = React.useState(false);
  const refTipoChamado = React.useRef();
  const refReclamante = React.useRef();
  const refEmailReclamante = React.useRef();
  const refContadorMono = React.useRef();
  const refContadorColor = React.useRef();
  const refContadorTotal = React.useRef();
  const refDefeito = React.useRef();
  const [equipamento, setEquipamento] = React.useState({
    codigoadicional : '', 
    numeroserie : '', 
    idcliente : '', 
    razaosocial : '', 
    marca : '', 
    modelo : '', 
    setor : ''
  });
  const [chamadosAbertosSerial, setChamadosAbertosSerial] = React.useState(false);


 


  const handleClickOpen = () => {
   setOpen(true)
  };
  const handleClose = () => {
    setOpen(false);
  };




  return (
    <div>

         <Tooltip title ='Ver detalhe do chamado técnico'>
           <div onClick={handleClickOpen} style ={{cursor:'pointer'}}>
            {
              props.icone
              //<a target='_blank' href ={"https://printerconnect.com.br/api/chamadosTecnicosController/verChamadoTecnico?idusuario="+idusuario+"&idempresa="+idempresa+"&idcliente="+idcliente+"&idchamado="+idchamado}><IconeOrcamento className='incon-small'/><br/><span style = {{fontSize:'9px'}}>ORÇAMENTO PENDENTE</span> </a>
            }
           </div>
            
            
        </Tooltip>
      <Dialog scroll='body' fullWidth maxWidth='md' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Situação do Atendimento : {props.chamado.situacao} 
        </DialogTitle>
        <DialogContent dividers>
          
          <Typography variant='caption' color='primary'>Informações de abertura</Typography>
          <br/>
        <Grid container spacing = {2} style ={{marginTop:'10px'}}>
                <Grid item xs ={4}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Tipo de Atendimento'
                        variant='outlined'
                        placeholder='Selecione equipamento'
                        InputLabelProps ={{shrink : true}}
                        value={props.chamado.tipo}
                      />
                </Grid>

                <Grid item xs ={3}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Status do atendimento'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}
                        value={props.chamado.situacao}
                      />
                </Grid>
                <Grid item xs ={3}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Número Série'
                        value={props.chamado.numeroserie}
                        variant='outlined'
                        placeholder='Selecione equipamento'
                        InputLabelProps ={{shrink : true}}
                        InputProps ={{
                            startAdornment : <InputAdornment position ='start'>
                             <PrintIcon/>
                              </InputAdornment>
                        }}
                         />
                </Grid>
                <Grid item xs ={2}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Patrimônio'
                        value={props.chamado.codigoadicional}
                        variant='outlined'
                        placeholder='Placa'
                        InputLabelProps ={{shrink : true}}                        
                         />
                </Grid>
          </Grid>
          <br/>
            <Grid container spacing = {2}>
                
                <Grid item xs ={5}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Marca'
                        value={props.chamado.marca}
                        variant='outlined'
                        placeholder='Marca do equipamento'
                        InputLabelProps ={{shrink : true}}                        
                         />
                </Grid>
                <Grid item xs ={7}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Modelo'
                        value={props.chamado.modelo}
                        variant='outlined'
                        placeholder='Modelo do equipamento'
                        InputLabelProps ={{shrink : true}}                                              
                         />
                </Grid>
               
            </Grid>
            <br/>
            <Grid container spacing = {2}>
              <Grid item xs ={2}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Código'
                        placeholder='Código'
                        value={props.chamado.idcliente}
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}            
                        InputProps ={{
                            startAdornment : <InputAdornment position ='start'><PersonIcon color='primary'/></InputAdornment>
                        }}             
                         />
                </Grid>
              <Grid item xs ={6}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Cliente'
                        placeholder='Razão social'
                        value={props.chamado.razaosocial}
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}            
                                    
                         />
                </Grid>
                <Grid item xs ={4}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Setor'
                        placeholder='Setor'
                        value={props.chamado.setor}
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}            
                                    
                         />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing = {2}>
              <Grid item xs ={4}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Nome Reclamante'
                        placeholder='Informe o nome do solicitante...'
                        variant='outlined'
                        value={props.chamado.reclamante}
                        inputProps={{
                          maxlength : 60
                        }}   
                        InputLabelProps ={{shrink : true}}                                                
                         />
                </Grid>
                <Grid item xs ={8}>
                    <TextField
                        size ='small'
                        fullWidth
                        label ='Email Reclamante'
                        inputProps={{
                          maxlength : 250
                        }}   
                        value={props.chamado.emailreclamante}
                        placeholder='Informe o email do solicitante...'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                                                
                         />
                </Grid>
          </Grid>
          <br/>
            <Grid container spacing = {2}>
              <Grid item xs ={2}>
                <TextField
                        size ='small'
                        fullWidth
                        label ='Contador Mono'
                       
                        defaultValue={0}
                        placeholder='Contador Mono informado'
                        value={props.chamado.contadormonoinicial}
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                                                
                         />
              </Grid>
              <Grid item xs ={2}>
                <TextField
                        size ='small'
                        fullWidth
                        label ='Contador Color'
                        value={props.chamado.contadorcolorinicial}
                        defaultValue={0}
                       
                        placeholder='Contador Color informado'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                                                
                         />
              </Grid>
              <Grid item xs ={2}>
                <TextField
                        size ='small'
                        fullWidth
                        label ='Contador Total'
                        defaultValue={0}
                        value={props.chamado.contadortotalinicial}
                        placeholder='Contador Total informado'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                                                
                         />
              </Grid>
              <Grid item xs ={6}>
                <TextField
                        size ='small'
                        fullWidth
                        label ='Defeito relatado (até 500 caracteres)'
                        placeholder='Informe o defeito reclamado'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}     
                        value={props.chamado.defeitoreclamado}
                        multiline
                        rows={5}
                        rowsMax={5}  
                        inputProps={{
                          maxlength : 500
                        }}                                         
                         />
              </Grid>
            
            </Grid>
         <div>
       
           <Typography variant='caption' color='primary'>Informações de atendimento</Typography>
            {props.chamado.datainiciotrajeto && <Grid container spacing = {1} style={{marginTop:'10px'}}>
             <Grid item xs={3}>
               <TextField
                  label='Data Trajeto'
                  variant='outlined'
                  InputLabelProps={{shrink:true}}
                  fullWidth
                  size='small'
                  value ={props.chamado.datainiciotrajeto + ' '+ (props.chamado.horainiciotrajeto ? props.chamado.horainiciotrajeto.substr(0,8) : '' )}
               />
             </Grid>
             <Grid item xs={5}>
               <TextField
                  label='Geolocalização'
                  variant='outlined'
                  InputLabelProps={{shrink:true}}
                  fullWidth
                  size='small'
                  value ={props.chamado.latitudetrajeto + ', '+ props.chamado.longitudetrajeto}
               />
             </Grid>
                
           </Grid>}
           {props.chamado.datainicioatendimento && <Grid container spacing = {1} style={{marginTop:'10px'}}>
             <Grid item xs={3}>
               <TextField
                  label='Data Atendimento'
                  variant='outlined'
                  InputLabelProps={{shrink:true}}
                  fullWidth
                  size='small'
                  value ={props.chamado.datainicioatendimento + ' '+ (props.chamado.horainicioatendimento ? props.chamado.horainicioatendimento.substr(0,8) : '' )}
               />
             </Grid>
             <Grid item xs={5}>
               <TextField
                  label='Geolocalização'
                  variant='outlined'
                  InputLabelProps={{shrink:true}}
                  fullWidth
                  size='small'
                  value ={props.chamado.latitudeatendimento + ', '+ props.chamado.longitudeatendimento}
               />
             </Grid>
                
           </Grid>}
           {props.chamado.dataprefinalizacao  && <Grid container spacing = {1} style={{marginTop:'10px'}}>
             <Grid item xs={3}>
               <TextField
                  label='Data Finalização'
                  variant='outlined'
                  InputLabelProps={{shrink:true}}
                  fullWidth
                  size='small'
                  value ={props.chamado.dataprefinalizacao + ' '+ (props.chamado.horaprefinalizacao ? props.chamado.horaprefinalizacao.substr(0,8) : '' )}
               />
             </Grid>
             <Grid item xs={5}>
               <TextField
                  label='Geolocalização'
                  variant='outlined'
                  InputLabelProps={{shrink:true}}
                  fullWidth
                  size='small'
                  value ={props.chamado.latitudeprefinalizacao + ', '+ props.chamado.longitudeprefinalizacao}
               />
             </Grid> 
             <Grid item xs = {2}>
          
              { (props.chamado.idsituacaochamadotecnico >= 6 && props.chamado.idsituacaochamadotecnico <= 10) && 
                
                <ModalAssinatura idusuario = {props.chamado.idusuario} idempresa = {props.chamado.idempresa} idcliente = {props.chamado.idcliente} idchamado = {props.chamado.idchamadotecnico}/>
                
              }

            </Grid> 

            <Grid item xs = {2}>
          
            { (props.chamado.idsituacaochamadotecnico >= 6 && props.chamado.idsituacaochamadotecnico <= 10) && 
         
         <ModalVerImagens idusuario = {props.chamado.idusuario} idempresa = {props.chamado.idempresa} idcliente = {props.chamado.idcliente} idchamado = {props.chamado.idchamadotecnico}/>
     
       }

            </Grid>                 
           </Grid>}
          {props.chamado.nometecnico &&  <Grid container spacing = {1} style={{marginTop:'10px'}}>
             <Grid item xs={12}>
               <TextField
                  label='Técnico responsável'
                  variant='outlined'
                  InputLabelProps={{shrink:true}}
                  fullWidth
                  size='small'
                  value ={props.chamado.nometecnico}
               />
             </Grid>
                           
           </Grid>}

           {props.chamado.dataprefinalizacao  &&<Grid container spacing = {1} style={{marginTop:'10px'}}>
             <Grid item xs={12}>
               <TextField
                  label='Solução'
                  variant='outlined'
                  InputLabelProps={{shrink:true}}
                  fullWidth
                  size='small'
                  multiline
                  rows={3}
                  rowsMax={3}
                  value ={props.chamado.solucaotecnica}
               />
             </Grid>
                   
           </Grid>}
         </div>
        </DialogContent>
        <DialogActions>
          

          
          
        { (props.chamado.idsituacaochamadotecnico == 1  || props.chamado.idsituacaochamadotecnico == 4) && <Button className='btn btn-error' autoFocus color="primary" variant='contained' onClick={()=>{
            Swal.fire({
              title : 'Printerconnect', 
              text : 'Deseja CANCELAR este atendimento ?', 
              icon : 'question', 
              showConfirmButton :true, 
              confirmButtonText : 'Sim', 
              showCancelButton : true, 
              cancelButtonText : 'Não'
            }).then(resposta=>{
              if(resposta.isConfirmed){
                Conexao.post('chamadosTecnicosController/cancelarAtendimento', {
                  idusuario : props.chamado.idusuario, 
                  idempresa : props.chamado.idempresa, 
                  idchamadotecnico : props.chamado.idchamadotecnico
                }).then(response=>{
                  if(response.data.status =='OK'){
                    if(props.getChamadosAbertosGestao){
                      props.getChamadosAbertosGestao();
                    }
                    Swal.fire({
                      title : 'Printerconnect', 
                      text : 'Ordem de Serviço cancelada com sucesso!', 
                      icon : 'success', 
                      showConfirmButton :false, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : false, 
                      cancelButtonText : 'Não', 
                      timer : 1500
                    }).then(()=>{
                                            
                      setOpen(false)
                    })
                  }else{
                    Swal.fire({
                      title : 'Printerconnect', 
                      text : 'Não foi possível cancelar!', 
                      icon : 'warning', 
                      showConfirmButton :false, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : false, 
                      cancelButtonText : 'Não', 
                      timer : 1500
                    })
                  }
                }).catch(error=>{
                  Swal.fire({
                    title : 'Printerconnect', 
                    text : 'Não foi possível cancelar!', 
                    icon : 'error', 
                    showConfirmButton :false, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : false, 
                    cancelButtonText : 'Não', 
                    timer : 1500
                  })
                })
              }
            })
          }}>
           <IconeCancelar/> Cancelar
          </Button>}

          { (props.chamado.idsituacaochamadotecnico >= 6 && props.chamado.idsituacaochamadotecnico <= 10) && <Button className='btn btn-success' autoFocus color="primary" variant='contained' onClick={()=>{
            Swal.fire({
              title : 'Printerconnect', 
              text : 'Deseja CONCLUIR este atendimento ?', 
              icon : 'question', 
              showConfirmButton :true, 
              confirmButtonText : 'Sim', 
              showCancelButton : true, 
              cancelButtonText : 'Não', 
              
            }).then(resposta=>{
              if(resposta.isConfirmed){
                Conexao.post('chamadosTecnicosController/concluirAtendimento', {
                  idusuario : props.chamado.idusuario, 
                  idempresa : props.chamado.idempresa, 
                  idchamadotecnico : props.chamado.idchamadotecnico
                }).then(response=>{
                  if(response.data.status =='OK'){
                    if(props.getChamadosAbertosGestao){
                      props.getChamadosAbertosGestao();
                    }
                    Swal.fire({
                      title : 'Printerconnect', 
                      text : 'Ordem de Serviço concluida com sucesso!', 
                      icon : 'success', 
                      showConfirmButton :false, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : false, 
                      cancelButtonText : 'Não', 
                      timer : 1500
                    }).then(()=>{
                      
                      
                      setOpen(false)
                    })
                  }else{
                    Swal.fire({
                      title : 'Printerconnect', 
                      text : 'Não foi possível concluir!', 
                      icon : 'warning', 
                      showConfirmButton :false, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : false, 
                      cancelButtonText : 'Não', 
                      timer : 1500
                    })
                  }
                }).catch(error=>{
                  Swal.fire({
                    title : 'Printerconnect', 
                    text : 'Não foi possível concluir!', 
                    icon : 'error', 
                    showConfirmButton :false, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : false, 
                    cancelButtonText : 'Não', 
                    timer : 1500
                  })
                })
              }
            })
          }}>
           <ConcluirIcone/> Concluir
          </Button>}
          { (props.chamado.idsituacaochamadotecnico >= 6 && props.chamado.idsituacaochamadotecnico <= 10) && <Button className='btn btn-info' autoFocus color="primary" variant='contained' onClick={()=>{
            Swal.fire({
              title : 'Printerconnect', 
              text : 'Deseja ABRIR UMA NOVA OCORRENCIA para este atendimento ?', 
              icon : 'question', 
              showConfirmButton :true, 
              confirmButtonText : 'Sim', 
              showCancelButton : true, 
              cancelButtonText : 'Não'
            }).then(resposta=>{
              if(resposta.isConfirmed){
                Conexao.post('chamadosTecnicosController/insertNovaOcorrenciaChamadoTecnico', {
                  idusuario : props.chamado.idusuario, 
                  idempresa : props.chamado.idempresa, 
                  idchamadotecnico : props.chamado.idchamadotecnico
                }).then(response=>{
                  if(response.data.status =='OK'){
                    if(props.getChamadosAbertosGestao){
                      props.getChamadosAbertosGestao();
                    }
                    Swal.fire({
                      title : 'Printerconnect', 
                      text : 'Ordem de Serviço concluida com sucesso!', 
                      icon : 'success', 
                      showConfirmButton :false, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : false, 
                      cancelButtonText : 'Não', 
                      timer : 1500
                    }).then(()=>{
                      
                      
                      setOpen(false)
                    })
                  }else{
                    Swal.fire({
                      title : 'Printerconnect', 
                      text : 'Não foi possível concluir!', 
                      icon : 'warning', 
                      showConfirmButton :false, 
                      confirmButtonText : 'Sim', 
                      showCancelButton : false, 
                      cancelButtonText : 'Não', 
                      timer : 1500
                    })
                  }
                }).catch(error=>{
                  Swal.fire({
                    title : 'Printerconnect', 
                    text : 'Não foi possível concluir!', 
                    icon : 'error', 
                    showConfirmButton :false, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : false, 
                    cancelButtonText : 'Não', 
                    timer : 1500
                  })
                })
              }
            })
          }}>
           <ConcluirIcone/> Nova Ocorrência
          </Button>}
          
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}




