import React from 'react'
import {withRouter} from 'react-router-dom'
import {Map, Circle, TileLayer,withLeaflet, Marker, Popup, Tooltip, ZoomControl, Polygon, Polyline  } from "react-leaflet";
import MeasureControlDefault from 'react-leaflet-measure'; //https://www.npmjs.com/package/react-leaflet-measure
import L from 'leaflet';
import "leaflet/dist/leaflet.css";//https://towardsdatascience.com/creating-a-bubbles-map-using-react-leaflet-e75124ca1cd2
import { point } from 'leaflet';
import Principal from '../Principal/Principal'
import {Typography, Divider, TextField, Button} from '@material-ui/core'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Slider from '@material-ui/core/Slider';
import { SketchPicker,   } from 'react-color'
import Swal from 'sweetalert2'
import Conexao from '../../providers/Conexao'
import { Icon, DivIcon } from "leaflet";


const iconTecnico = new Icon({
    
  iconUrl: require("./icones/tecnico1.png"),
  iconSize: [42, 42], 
  iconAnchor :[
      10, 25
  ], 
  popupAnchor: [0, 0]
});
var greenIcon = L.icon({
  iconUrl: 'leaf-green.png',
  shadowUrl: 'leaf-shadow.png',

  iconSize:     [38, 95], // size of the icon
  shadowSize:   [50, 64], // size of the shadow
  iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
  shadowAnchor: [4, 62],  // the same for the shadow
  popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});


const MapaCadastroRegioes = (props) =>{

    const position = [51.505, -0.09]
    const [posicaoInicio, setPosicaoInicio] = React.useState(null);
    const [raio, setRaio] = React.useState(500);
    const [color, setColor] = React.useState('#db4a29');
    const MeasureControl = withLeaflet(MeasureControlDefault);
    const [incluindoRegiao, setIncluindoRegiao]= React.useState(false);
    const [regioes, setRegioes] = React.useState([]);
    const [tecnicos, setTecnicos] = React.useState([]);


    React.useEffect(()=>{
      getRegioes();
      getTecnicos()
    }, []);

    const resetInclusaoRegiao = () =>{
      setRaio(500)
      setColor('#db4a29')
      setPosicaoInicio(null)
      setIncluindoRegiao(false)
    }



    const handlePositionLine = (posicao)=>{
      console.log(posicao.lat)
     setPosicaoInicio([posicao.lat, posicao.lng])
    
    }

    const measureOptions = {
      position: 'topright',
      primaryLengthUnit: 'meters',
      secondaryLengthUnit: 'kilometers',
      primaryAreaUnit: 'sqmeters',
      secondaryAreaUnit: 'acres',
      activeColor: '#db4a29',
      completedColor: '#9b2d14'
    };

    const hangeSlider = (event, newValue) => {   
      if(posicaoInicio){
        setRaio(newValue)
      }           
    };

   const handleChangeComplete = (color) => {
      setColor(color.hex )
    };

    const refDescricao = React.useRef();

    const getRegioes = () =>{
      Conexao.get('empresasController/getRegioes?idUsuario='+props.match.params.idempresa+'&idEmpresa='+props.match.params.chaveEmpresa).then(response=>{
        if(response.data.status =='OK'){
          setRegioes(response.data.payload)
        }else{
          setRegioes([])
        }
      }).catch(error=>{
        setRegioes([])
      })
    }


    const getTecnicos = () =>{
      Conexao.post('funcionariosController/getPosicaoTecnicosMapa', {
        idusuario : props.match.params.idempresa, 
        idempresa :props.match.params.chaveEmpresa 
      }).then(response=>{
        if(response.data.status =='OK'){
          setTecnicos(response.data.payload)
        }else{
          setTecnicos([])
        }
      }).catch(error=>{
        setTecnicos([])
      })
    }



    return <Principal full={true} navOculta={true}>
      <div style ={{height: "78vh", marginTop:"10px", width: "100%", }}>
        <Map
          id="modalMap"                        
          animate={true}
          style={{ height: "100%", width: "100%", }}
          minZoom={5}
          zoom={6}   
                     
          zoomControl={false}                                                        
          center={[-22.2244322,-49.8917842]}    
          onclick={(e)=>{
           handlePositionLine(e.latlng)
           //console.log(e)
          }}    
          >
            <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <ZoomControl position="topright"  />
           

           {posicaoInicio && <Circle
              fillColor={color}
              stroke ={false}
              onclick = {(e)=>{
                console.log(e)
              }}
              center = {posicaoInicio}
              radius = {raio}
            /> }
             {/*posicaoInicio && <Marker key ={'MKR01'} posicao={[-22.2244322,-49.8939729]}/> */}
            {
              regioes && regioes.map(function(regiao, i){
                return <Circle
                fillColor={regiao.cor}
                stroke ={false}
                
                center = {[regiao.latitude, regiao.longitude]}
                radius = {regiao.raio}
              />
              })
            }


            {
              tecnicos && tecnicos.map(function(tecnico, i){
                return <Marker key={'tec'+i} position ={[tecnico.latitude, tecnico.longitude]}  icon={iconTecnico}>
                  </Marker>
              })
            }
          </Map>
          {incluindoRegiao && <div id='overlay-mapa-regiao'>
            <div style={{textAlign:'center'}}>

              <TextField
                label='Nome da Região'
                inputRef={refDescricao}
                variant='outlined'
                fullWidth
                size='small'
                style = {{marginTop:"5px"}}
                />
              <Slider 
                min={1500} 
                max={500000} 
                step={500} 
                value={0} 
                value={raio}      
                onChange={hangeSlider} aria-labelledby="continuous-slider" style = {{marginTop:"10px"}}/>
                <Typography variant='caption' style ={{fontSize:"9px"}}>Distância (raio) : {raio/1000} Kilometro(s)</Typography>

                <SketchPicker
                  Slider={false}
                 style = {{marginTop:"20px"}} color={color} onChangeComplete={ handleChangeComplete }/>
                <br/>
                
            </div>
          </div>}
          <div className='fabIncluir sobreposicaoMaxima'>
           {!incluindoRegiao ?  <Fab color="primary" aria-label="add" onClick={()=>setIncluindoRegiao(true)}>
              <AddIcon />
            </Fab> : <div>
            <Button variant='contained' fullWidth color='primary' style = {{marginTop:"10px"}} onClick={()=>{
              Swal.fire({
                title : 'Printerconnect', 
                text :'Deseja incluir esta regiao agora ?', 
                icon : 'question', 
                showConfirmButton: true, 
                confirmButtonText : 'SIM', 
                showCancelButton : true, 
                cancelButtonText : 'NÃO'
              }).then(resposta=>{
                if(resposta.isConfirmed){
                  Conexao.post('empresasController/insertNovaRegiao', {
                    idUsuario : props.match.params.idempresa, 
                    idEmpresa : props.match.params.chaveEmpresa, 
                    descricao : refDescricao.current ? refDescricao.current.value : '', 
                    latitude : posicaoInicio[0], 
                    longitude : posicaoInicio[1], 
                    raio : raio, 
                    cor : color, 
                  }).then(response =>{
                    if(response.data.status =='OK'){
                      resetInclusaoRegiao();
                      getRegioes();
                    }
                  }).catch(error =>{

                  })
                }
              })
            }}>Gravar</Button>
                <br/>
            <Button variant='contained' fullWidth color='secondary'  style = {{marginTop:"10px"}} onClick={()=>{
              Swal.fire({
                title : 'Printerconnect', 
                text :'Deseja cancelar ?', 
                icon : 'question', 
                showConfirmButton: true, 
                confirmButtonText : 'SIM', 
                showCancelButton : true, 
                cancelButtonText : 'NÃO'
              }).then(resposta=>{
                if(resposta.isConfirmed){
                  resetInclusaoRegiao();
                }
              })
            }}>cancelar</Button>
            </div>}
          </div>
        </div>
    </Principal>
}

export default withRouter(MapaCadastroRegioes)