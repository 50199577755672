import React, {useCallback, useState, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import {Badge, Tooltip, Grid, Table, TableHead, TableBody, TableRow, TableCell, Divider, TextField} from '@material-ui/core';
import Conexao from '../../../providers/Conexao'
import Swal from 'sweetalert2'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalVinculaConsumiveis(props) {
  const [open, setOpen] = useState(false);
  const [consumiveis, setConsumiveis] = useState([]);
  const [consumiveisModelo, setConsumiveisModelo]= useState([]);
  const [target, setTarget]= useState(0);

  const refConsumivel = React.createRef();


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    if(open){
        getListaConsumiveis()
        getConsumiveis()
    }
  }, [open]);

  useEffect(()=>{
  
    getConsumiveis()
       
   
  }, []);


  const getListaConsumiveis = () =>{
    Conexao.post('consumiveisController/getConsumiveisFiltroModelo', {
        idusuario : props.modelo.idusuario, 
        idmodelo : props.modelo.idmodelo
    }).then(response =>{
        if(response.data.status == 'OK'){
            setConsumiveis(response.data.payload)
        }else{
            setConsumiveis([])
           
        }
    }).catch(error=>{
        setConsumiveis([])
        Swal.fire({
            title :'Gestão de Equipamentos', 
            text :'Não foi possível localizar lista de consumíveis !', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1000, 
            icon :'error'
        })
    });
  }


  const getConsumiveis = () =>{
      Conexao.post('modelosController/getConsumiveis', {
        idusuario : props.modelo.idusuario, 
        idmodelo : props.modelo.idmodelo
      }).then(response =>{
        if(response.data.status == 'OK'){
            setConsumiveisModelo(response.data.payload)
        }else{
            setConsumiveisModelo([])
            
        }
    }).catch(error=>{
        setConsumiveisModelo([])
        
    });
  }
  return (
    <div>
      
      <Tooltip title ='Editar informações da modelo'>
            <Button onClick={handleClickOpen}><Badge badgeContent={consumiveisModelo.length} color = 'primary'><RepeatOneIcon style ={{fontSize:"32px"}} color='primary'/></Badge></Button>
     </Tooltip>


      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth
        maxWidth='md'>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Consumíveis Vinculados : {props.modelo.descricao}
        </DialogTitle>
        <DialogContent dividers style={{height:"350px", overflow:"auto"}}>
          <Grid container spacing={2}>
              <Grid item xs = {6}>
                    <TextField
                        label = 'Descrição do Consumível'
                        placeholder ='Escolha o consumível'
                        variant='outlined'
                        select
                        SelectProps = {{native : true}}
                        InputLabelProps = {{shrink : true}}
                        fullWidth
                        inputRef = {refConsumivel}
                    >
                        {consumiveis && consumiveis.map(function(consumivel, i){
                            return <option value = {consumivel.idconsumivel}>{consumivel.descricao}</option>
                        })}

                    </TextField>
              </Grid>
              <Grid item xs = {2}>
                    <TextField
                        label = 'Target'
                        placeholder ='Capacidade'
                        variant='outlined'
                        InputLabelProps = {{shrink : true}}
                        fullWidth
                        value ={target}
                        onChange = {(e)=>setTarget(e.target.value)}
                    />
              </Grid>

              <Grid item xs = {2}>
                    <Button fullWidth variant ='outlined' onClick={()=>{
                         Swal.fire({
                            title :'Gestão de Equipamentos', 
                            text :'Não foi possível localizar lista de consumíveis !', 
                            showCancelButton : true,
                            cancelButtonText :'Cancelar', 
                            showConfirmButton : true, 
                            confirmButtonText :'Confirmar', 
                            icon :'question'
                        }).then(resposta =>{
                            if(resposta.isConfirmed){
                                Conexao.post('modelosController/insertConsumivel', {
                                    idusuario : props.modelo.idusuario, 
                                    idmarca : props.modelo.idmarca, 
                                    idmodelo : props.modelo.idmodelo, 
                                    idconsumivel : refConsumivel.current ? refConsumivel.current.value : -1, 
                                    target : target
                                }).then(response =>{
                                  
                                    if(response.data.status =='OK'){
                                        getListaConsumiveis();
                                        getConsumiveis()
                                        Swal.fire({
                                            title :'Gestão de Equipamentos', 
                                            text :'Consumível vinculado com sucesso !', 
                                            showCancelButton : false, 
                                            showConfirmButton : false, 
                                            timer : 1000, 
                                            icon :'success'
                                        })

                                    }else{
                                        Swal.fire({
                                            title :'Gestão de Equipamentos', 
                                            text :'Não foi possível inserir !', 
                                            showCancelButton : false, 
                                            showConfirmButton : false, 
                                            timer : 1000, 
                                            icon :'warning'
                                        })
                                    }
                                }).catch( error =>{
                                    Swal.fire({
                                        title :'Gestão de Equipamentos', 
                                        text :'Não foi possível inserir !', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer : 1000, 
                                        icon :'error'
                                    })
                                })
                            }
                        })
                    }}>Inserir</Button>
              </Grid>
          </Grid>
          <br/>
          <br/>
          <Table>
              <TableHead>
                  <TableCell>
                      <Typography variant='subtitle1'>Descrição do Consumível</Typography>
                  </TableCell>
                  <TableCell>
                      <Typography variant='subtitle1'>Target</Typography>
                  </TableCell>
                  <TableCell>
                      <Typography variant='subtitle1'>Remover</Typography>
                  </TableCell>
              </TableHead>
              <TableBody>
                  {
                      consumiveisModelo && consumiveisModelo.map(function(item, i){
                          return <TableRow>
                          <TableCell>
                              <Typography variant='subtitle2'>{item.descricao}</Typography>
                          </TableCell>
                          <TableCell>
                              <Typography variant='subtitle2'>{item.target}</Typography>
                          </TableCell>
                          <TableCell>
                              <Typography variant='subtitle2'>
                              <Button onClick = {()=>{
                                  Swal.fire({
                                    title :'Gestão de Equipamentos', 
                                    text :'Deseja realmente remover este consumível da lista ?', 
                                    showCancelButton : true,
                                    cancelButtonText :'Cancelar', 
                                    showConfirmButton : true, 
                                    confirmButtonText :'Confirmar', 
                                    icon :'question'
                                }).then(resposta =>{
                                    if(resposta.isConfirmed){
                                        Conexao.post('modelosController/deleteConsumivel', {
                                            idusuario : props.modelo.idusuario, 
                                            idmarca : props.modelo.idmarca, 
                                            idmodelo : props.modelo.idmodelo, 
                                            idconsumivel : item.idconsumivel, 
                                        }).then(response =>{
                                          
                                            if(response.data.status =='OK'){
                                                getListaConsumiveis();
                                                getConsumiveis()
                                                Swal.fire({
                                                    title :'Gestão de Equipamentos', 
                                                    text :'Consumível removido com sucesso !', 
                                                    showCancelButton : false, 
                                                    showConfirmButton : false, 
                                                    timer : 1000, 
                                                    icon :'success'
                                                })
        
                                            }else{
                                                Swal.fire({
                                                    title :'Gestão de Equipamentos', 
                                                    text :'Não foi possível remover !', 
                                                    showCancelButton : false, 
                                                    showConfirmButton : false, 
                                                    timer : 1000, 
                                                    icon :'warning'
                                                })
                                            }
                                        }).catch( error =>{
                                            Swal.fire({
                                                title :'Gestão de Equipamentos', 
                                                text :'Não foi possível remover !', 
                                                showCancelButton : false, 
                                                showConfirmButton : false, 
                                                timer : 1000, 
                                                icon :'error'
                                            })
                                        })
                                    }
                                })
                              }}><DeleteForeverIcon className='iconDanger' style={{border:'none!important'}}/></Button>
                              </Typography>
                          </TableCell>
                        </TableRow>
                      })
                  }
                  
              </TableBody>
          </Table>
          <Divider/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
