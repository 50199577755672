import React from 'react'
import './MenuPrincipal.css'
import {withRouter, Link} from 'react-router-dom'

import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';

import PrintIcon from '@material-ui/icons/Print';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import BuildIcon from '@material-ui/icons/Build';

import AssignmentIcon from '@material-ui/icons/Assignment';

import UsbIcon from '@material-ui/icons/Usb';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Badge from '@material-ui/core/Badge';
import Forward5Icon from '@material-ui/icons/Forward5';
import BallotIcon from '@material-ui/icons/Ballot';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Replay5Icon from '@material-ui/icons/Replay5';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import AddIcon from '@material-ui/icons/Add';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import BusinessIcon from '@material-ui/icons/Business';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import GrainIcon from '@material-ui/icons/Grain';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CategoryIcon from '@material-ui/icons/Category';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ComputerIcon from '@material-ui/icons/Computer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import WarningIcon from '@material-ui/icons/Warning';
import GestureIcon from '@material-ui/icons/Gesture'
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ModalSobre from '../ModalSobre'
import { Typography } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';
const MenuPrincipal = (props) =>{
    const [empresa, setEmpresa] = React.useState([]);
    
    
    React.useEffect(()=>{
        const emp = sessionStorage.getItem('cliente@printerConnect', []);
       
      
        setEmpresa(JSON.parse(emp))
       
        
      }, [props.location])
    return <div className="navbar">
    { empresa.billing == 't' || empresa.collector =='t' ?
    <Link to ={{pathname : '/grafico-coleta-clientes/revenda/'+empresa.idusuario+'/empresa/'+empresa.idempresa+'/cliente/'+empresa.idcliente}}> 
        <div><NetworkCheckIcon /></div><div>Dashboard</div>
    </Link> :<Link to ={{pathname : '#'}}> 
        <div><NetworkCheckIcon /></div><div>Dashboard</div>
    </Link> }
   

    

   

    <div className="subnav">
      <button className="subnavbtn"><div><PrintIcon /></div><div>Equipamentos</div><i className="fa fa-caret-down"></i></button>
      <div className="subnav-content">
     
      <Link to ={{pathname : '/equipamentos' ,state:{
        empresa
      }}}>
        <div><PrintIcon /></div><div>Equipamentos</div>
      </Link>
       
      </div>
    </div>

    <div className="subnav">
      <button className="subnavbtn"><div><Badge color='secondary' badgeContent='Breve'><AssignmentIcon /></Badge></div><div>Contratos</div><i className="fa fa-caret-down"></i></button>
      <div className="subnav-content">
       
      </div>
    </div>
      { empresa.chamados == 't' ?
    <div className="subnav" >
    <Link to ={{pathname : '/cadastrochamadostecnicos', state:{
      idusuario: empresa.idusuario,
      idempresa: empresa.idempresa,
      idcliente: empresa.idcliente
    }}}>
      <div style={{marginTop: '-13px'}}> <button className="subnavbtn"><div><Badge color='secondary' badgeContent='Breve'><BuildIcon /></Badge></div><div>Chamados</div><i className="fa fa-caret-down"></i></button></div>
      </Link>  
     
  </div> : <div> </div> }


    { empresa.suprimentos == 't' ?
    <div className="subnav">
      <button className="subnavbtn"><div><Badge color='secondary' badgeContent='Breve'><AddShoppingCartIcon /></Badge></div><div>Suprimentos</div><i className="fa fa-caret-down"></i></button>
      <div className="subnav-content">
        
      </div>
  </div> : <div> </div> }

   
{ empresa.collector == 't' ?
    <div className="subnav">
      <button className="subnavbtn"><div><UsbIcon /></div><div>Contadores</div><i className="fa fa-caret-down"></i></button>
      <div className="subnav-content">
      
      <Link to ={{pathname : '/cadastrarsetores', state:{
          empresa : empresa
        } }}>
        <div><HomeWorkIcon /></div><div>Setores</div>
        </Link>

      <Link to ={{pathname : '/contadores_cliente/revenda/'+empresa.idusuario+'/empresa/'+empresa.idempresa+'/cliente/'+empresa.idcliente  }}>
        <div><AccessAlarmsIcon /></div><div>Contadores Ao Vivo</div>
      </Link>

      <Link to ={{pathname : '/gestao_contadores_cliente/revenda/'+empresa.idusuario+'/empresa/'+empresa.idempresa+'/cliente/'+empresa.idcliente  }}>
        <div><EventNoteIcon /></div><div>Gestão de Coletas</div>
      </Link>

      <Link to ={{pathname : '/alertas-equipamentos-clientes', state:{
          empresa : empresa
        } }}>
        <div><WarningIcon /></div><div>Alertas Equipamentos</div>
        </Link>

        <Link to ={{pathname : '/alertas-atolamentos-clientes', state:{
          empresa : empresa
        } }}>
        <div><GestureIcon /></div><div>Atolamentos</div>
        </Link>

        <Link to ={{pathname : '/alertas-suprimentos-clientes', state:{
          empresa : empresa
        } }}>
        <div><NotificationsActiveIcon /></div><div>Alertas Toner</div>
        </Link>

      <Link to ={{pathname : '/trocas_suprimento_cliente/revenda/'+empresa.idusuario+'/empresa/'+empresa.idempresa+'/cliente/'+empresa.idcliente }}>
      <div><Replay5Icon /></div><div>Histórico de Trocas</div>
      </Link>



      <Link to ={{pathname : '/relatorios-cliente/revenda/'+empresa.idusuario+'/empresa/'+empresa.idempresa, state:{
        empresa : empresa
      } }}>
      <div><PrintIcon /></div><div>Relatórios</div>
      </Link>
      
        
      </div>
    </div> : <div> </div> }


    { empresa.billing == 't' ?
    <div className="subnav">
      <button className="subnavbtn"><div><VerifiedUserIcon /></div><div>Bilhetagem</div><i className="fa fa-caret-down"></i></button>
      <div className="subnav-content">
        <Link to ={{pathname : '/computadores', state:{
          empresa : empresa
        } }}>
        <div><ComputerIcon /></div><div>Computadores</div>
        </Link>
        <Link to ={{pathname : '/filasdeimpressao', state:{
          empresa : empresa
        } }}>
        <div><PrintIcon /></div><div>Filas de Impressão</div>
        </Link>
        <Link to ={{pathname : '/usuarios', state:{
          empresa : empresa
        } }}>
        <div><EmojiPeopleIcon /></div><div>Usuários</div>
        </Link>
        <Link to ={{pathname : '/cadastrardepartamento', state:{
          empresa : empresa
        } }}>
        <div><AccountBalanceIcon /></div><div>Departamento</div>
        </Link>

        <Link to ={{pathname : '/centrodecusto', state:{
          empresa : empresa
        } }}>
        <div><MonetizationOnIcon /></div><div>Centro de Custo</div>
        </Link>
        
        <Link to ={{pathname : '/logimpressao', state:{
          empresa : empresa
        } }}>
        <div><BallotIcon /></div><div>Log de Impressões</div>
        </Link>
        <Link to ={{pathname : '/relatoriosbilling', state:{
          empresa : empresa
        } }}>
        <div><PrintIcon /></div><div>Relatórios Billing</div>
        </Link>
     
      </div>
     
    </div> : <div> </div> }
    
    <a ><div><ModalSobre /></div><div><Typography variant='inherit'>Sobre nós</Typography></div></a>
    

  </div>
}

export default withRouter(MenuPrincipal)