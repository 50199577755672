import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Container, Grid, TextField, InputAdornment, Tooltip, Checkbox} from '@material-ui/core'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ModalBuscaModelo from '../../Modelos/Components/ModalBuscaModelo'
import LoopIcon from '@material-ui/icons/Loop';
import Swal from 'sweetalert2';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ModalBuscaClientes from '../../Clientes/ModalBuscaCliente'
import {Divider, Chip} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Conexao from '../../../providers/Conexao'



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoEquipamento(props) {
  const [open, setOpen] = React.useState(false);
  const [modeloTemp, setModeloTemp] = React.useState([]);
  const [modeloAcessorioCompativel, setModeloAcessorioCompativel] = React.useState([]);
  const [clienteTemp, setClienteTemp] = React.useState({idcliente:'', razaosocial:''});
  const [optLocacao, setOptLocacao]= React.useState(false);
  const [optAcessorio, setOptAcessorio ]= React.useState(false);
  const [numeroSerie, setNumeroSerie]= React.useState('');
  const [observacoes, setObservacoes]= React.useState('');
  const [codigoAdicional , setCodigoAdicional]= React.useState('');
  const [equipamentoExiste, setEquipamentoExiste]= React.useState(false);
  const [modelo, setModelo] = React.useState([])
  const [marca, setMarca] = React.useState([])

  const [error, setError] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  
  };

  useEffect(()=>{
    if(open){
        setError(false)
        setClienteTemp({idcliente:'', razaosocial:''})
        setModeloTemp([])
        setModeloAcessorioCompativel([])
        setMarca('')
        setModelo()
        setNumeroSerie('')
        setCodigoAdicional('')
        setObservacoes('')
        setEquipamentoExiste(false)
        setOptLocacao(false)
        setOptAcessorio(false)
    }
  }, [open])

  useEffect(()=>{
    if(props.equipamentoSelecao){
        //setClienteTemp({idcliente:equipamentoSelecao, razaosocial:''})
        setModeloTemp(props.equipamentoSelecao)
    }
  }, [props.equipamentoSelecao])


  

  const insertEquipamento = () =>{
    Conexao.post('equipamentosController/insertEquipamento',{
      idusuario : props.idUsuario, 
      idempresa : props.idEmpresa,
      idcliente : clienteTemp.idcliente, 
      marca: marca,
      modelo: modelo,
      numeroserie :numeroSerie, 
      codigoadicional :codigoAdicional, 
      observacoes :observacoes, 
      locacao : optLocacao ? 't':'f', 
      acessorio : optAcessorio ? 't':'f', 
      
    }).then( response =>{
      if(response.data.status=='OK'){
        props.getEquipamentos()
        Swal.fire({
          title :'Gestão de Equipamentos', 
          text :'Equipamento Cadastrado com sucesso !', 
          showCancelButton : false, 
          cancelButtonText : 'Cancelar', 
          showConfirmButton : false, 
          confirmButtonText : 'Confirmar', 
          icon:'success', 
          timer : 1000
        })
        
      }else{
        Swal.fire({
          title :'Gestão de Equipamentos', 
          text :'Não foi possível cadastrar !', 
          showCancelButton : false, 
          cancelButtonText : 'Cancelar', 
          showConfirmButton : false, 
          confirmButtonText : 'Confirmar', 
          icon:'warning', 
          timer : 1000
        })
      }
      setOpen(false)
    }).catch(error =>{
      Swal.fire({
        title :'Gestão de Equipamentos', 
        text :'Não foi possível cadastrar !', 
        showCancelButton : false, 
        cancelButtonText : 'Cancelar', 
        showConfirmButton : false, 
        confirmButtonText : 'Confirmar', 
        icon:'error', 
        timer : 1000
      })
      setOpen(false)
    });
  }



   const gerarPlaca = () =>{
     if(numeroSerie == ''){
      Swal.fire({
        title :'Gestão de Equipamentos', 
        text :'É necessário preencher o NÚMERO DE SÉRIE!', 
        showCancelButton : false, 
        cancelButtonText : 'Cancelar', 
        showConfirmButton : false, 
        confirmButtonText : 'Confirmar', 
        icon:'warning', 
        timer : 3000
      })
    

     }else{
    Conexao.get('equipamentosController/createPatrimonioEquipamento?idEmpresa='+props.idEmpresa+'&numeroSerie='+numeroSerie).then( response =>{
      if(response.data.status=='OK'){
        setCodigoAdicional(response.data.payload.idpatrimonio)
        Swal.fire({
          title :'Gestão de Equipamentos', 
          text :'Patrimonio gerado com sucesso!', 
          showCancelButton : false, 
          cancelButtonText : 'Cancelar', 
          showConfirmButton : false, 
          confirmButtonText : 'Confirmar', 
          icon:'success', 
          timer : 2000
        })
        
      }else{
        Swal.fire({
          title :'Gestão de Equipamentos', 
          text :'Não foi possível gerar !', 
          showCancelButton : false, 
          cancelButtonText : 'Cancelar', 
          showConfirmButton : false, 
          confirmButtonText : 'Confirmar', 
          icon:'warning', 
          timer : 2000
        })
      }
 
    }).catch(error =>{
      Swal.fire({
        title :'Gestão de Equipamentos', 
        text :'Não foi possível gerar patrimonio !', 
        showCancelButton : false, 
        cancelButtonText : 'Cancelar', 
        showConfirmButton : false, 
        confirmButtonText : 'Confirmar', 
        icon:'error', 
        timer : 1000
      })
      setOpen(false)
    });
  

   }
  }
  

  return (
    <div>
      
      <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
        <AddIcon />
      </Fab>


      <Dialog disableBackdropClick hideBackdrop  onClose={handleClose} fullWidth aria-labelledby="customized-dialog-title" open={open } disableBackdropClick disableEscapeKeyDown maxWidth='sm'>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastrar Equipamento
        </DialogTitle>
        <DialogContent dividers >
          <Grid container spacing={1}>
              <Grid item xs={12}>
                  <TextField
                  label='Número de Série'
                    error={(error == true && numeroSerie == '') || equipamentoExiste}
                    helperText={(error && numeroSerie =='') ? 'Informe o número de série' : ''}
                    fullWidth
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}
                    placeholder='Informe o serial'
                    value = {numeroSerie}
                    onChange = {(e)=>setNumeroSerie(e.target.value)}
                    size='small'
                    inputProps={{maxlength:"60"}}
                    onBlur = {(e)=>{
                      Conexao.post('equipamentosController/numeroSerieExiste', {
                        idusuario : props.idUsuario, 
                        idempresa : props.idEmpresa,
                        numeroserie : e.target.value
                      }).then(response =>{
                   
                        if(response.data.payload =='T'){
                          setEquipamentoExiste(true);
                          Swal.fire({
                            title :'Gestão de Equipamentos', 
                            text :'Já existe um equipamento cadastrado com este número de série', 
                            showCancelButton : false, 
                            showConfirmButton : true, 
                            confirmButtonText : 'OK', 
                            icon : 'info'
                          })
                        }else{
                          setEquipamentoExiste(false)
                        }
                       // setEquipamentoExiste(false)
                      }).catch(error =>{
                        setEquipamentoExiste(false)
                      })
                    }}
                    />
              </Grid>              
              </Grid>
        
              <Grid container spacing={1} style ={{marginTop:'10px'}}>
              <Grid item xs={12}>
                  <TextField
                    label='Patrimônio'
                    fullWidth
                    variant='outlined'
                    placeholder='Placa'
                    value = {codigoAdicional}
                    inputProps={{maxlength:"25"}}
                    onChange = {(e)=>setCodigoAdicional(e.target.value)}
                    InputProps ={{
                        startAdornment : (
                            <InputAdornment position ='start'>                              
                                <LoopIcon 
                                    style ={{cursor :'pointer'}}
                                    onClick={()=>{
                                    Swal.fire({
                                        title :'Gestão de Equipamentos', 
                                        text:'Deseja gerar um Número de Patrimônio automáticamente ?', 
                                        icon :'question', 
                                        showCancelButton : true, 
                                        cancelButtonText : 'Cancelar', 
                                        showConfirmButton : true, 
                                        confirmButtonText : 'Confirmar'
                                    }).then(resposta=>{
                                        if(resposta.isConfirmed){
                                          gerarPlaca()
                                         
                                          
                                        }
                                    })
                                }}/>
                            </InputAdornment>
                        )
                    }} 
                    size='small'
                    onBlur = {(e)=>{
                      if(e.target.value){
                        Conexao.post('equipamentosController/codigoAdicionalExiste', {
                          idusuario : props.idUsuario, 
                          idempresa : props.idEmpresa,
                          codigoadicional : e.target.value
                        }).then(response =>{
                    
                          if(response.data.payload =='T'){
                            
                            Swal.fire({
                              title :'Gestão de Equipamentos', 
                              text :'Já existe um equipamento cadastrado com este número de Patrimônio', 
                              showCancelButton : false, 
                              showConfirmButton : true, 
                              confirmButtonText : 'OK', 
                              icon : 'info'
                            })
                          }else{
                          
                          }
                        
                       // setEquipamentoExiste(false)
                      }).catch(error =>{
                        
                      })
                    }
                    }}
                    InputLabelProps ={{shrink : true}}
                    />
              </Grid>
              </Grid>

              <Grid container spacing={1} style ={{marginTop:'10px'}}>
              <Grid item xs={12}>
                    <TextField
                        label='Marca'
                        fullWidth
                        size='small'
                        error={error}  
                        helperText={(error && marca =='') ? 'Informe a Marca' : ''}                  
                        variant='outlined'
                        value = {marca}
                        onChange={(e)=>setMarca(e.target.value)}
                        
                        placeholder='Marca do equipamento'
                        InputLabelProps ={{shrink : true}}
                        />                    
                      
                      <br />
                         
                          
                </Grid>
                </Grid>



            <Grid container spacing={1} style ={{marginTop:'10px'}}>
               <Grid item xs={12}>
                  <TextField
                    label='Modelo'
                    fullWidth
                    variant='outlined'
                    placeholder='Modelo'                    
                    error={error}   
                    value = {modelo}
                    onChange={(e)=>setModelo(e.target.value)}                                     
                    helperText={(error && modelo =='') ? 'Informe o Modelo' : ''}                    
                    InputLabelProps ={{shrink : true}}
                    size='small'
                    
                    />
                       
                </Grid>              
          </Grid>

          <Grid container spacing={1} style ={{marginTop:'10px'}}>
               <Grid item xs={12}>
                  <TextField
                    label='Cliente'
                    fullWidth
                    variant='outlined'
                    placeholder='Modelo'                    
                    error={error}   
                    value = {clienteTemp && clienteTemp.idcliente+' - '+clienteTemp.razaosocial}
                    onChange={(e)=>setModelo(e.target.value)}                                     
                    helperText={(error && modelo =='') ? 'Informe o Modelo' : ''}                    
                    InputLabelProps ={{shrink : true}}
                    size='small'
                    InputProps={{
                      endAdornment : <InputAdornment position='end'>
                        <ModalBuscaClientes updateCliente={(cliente)=>{
                          setClienteTemp(cliente)
                        }}/>
                      </InputAdornment>
                    }}
                    />
                       
                </Grid>              
          </Grid>
             
            
             
       
          <Grid container spacing = {1} style ={{marginTop:'10px'}}>           
                <Grid item xs={12}>
                    <TextField 
                        size='small'
                        label='Observações '
                        multiline
                        rowsMax={2}
                        rows={2}
                        fullWidth
                        variant='outlined'                        
                        placeholder='Observações gerais'
                        value = {observacoes}
                        inputProps={{maxlength:"500"}}
                        onChange = {(e)=>setObservacoes(e.target.value)}
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>
                </Grid>
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>  
            <Grid item xs={6}>
                    <TextField 
                        size='small'
                        label='Equipamento de locação?'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''    
                        select 
                        SelectProps={{native:true}} 
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        onChange={(e)=>setOptLocacao(e.target.value)}
                      >
                        <option value={optLocacao}>{optLocacao ? 'SIM' : 'NÃO'}</option>
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                    </TextField>
                </Grid>

                <Grid item xs={6}>
                    <TextField 
                        size='small'
                        label='É um acessório?'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''    
                        select 
                        SelectProps={{native:true}} 
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        onChange={(e)=>setOptAcessorio(e.target.value)}
                      >
                         <option value={optAcessorio}>{optAcessorio ? 'SIM' : 'NÃO'}</option>
                         <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                    </TextField>
                </Grid>
          </Grid>
          <br/>
         
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={handleClose} variant='contained'>Cancelar</Button>
          <Button autoFocus disabled={equipamentoExiste} variant='contained' onClick={()=>{
            
            Swal.fire({
              title :'Gestão de Equipamentos', 
              text :'Deseja cadastrar o equipamento agora ?', 
              showCancelButton : true, 
              cancelButtonText : 'Cancelar', 
              showConfirmButton : true, 
              confirmButtonText : 'Confirmar', 
              icon:'question'
            }).then(resposta=>{
              if(resposta.isConfirmed){
                setError(false)
                if(numeroSerie == '' || modelo.descricaomarca =='' || modelo.descricao == '' ){
                  setError(true)
                  Swal.fire({
                    title :'Gestão de Equipamentos', 
                    text :'Campos obrigatórios não informados!', 
                    showConfirmButton : true, 
                    showCancelButton : false, 
                    icon : 'warning'
                  })
                }else{
                  insertEquipamento();
                }
               
              }
            })
          }} color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
