import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../providers/Conexao'

import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';


import ModalSuprimentos from '../Suprimentos/ModalSuprimentos';
import ModalChamados from '../Chamados/ModalChamados';
import ModalDetalhes from '../Equipamentos/ModalDetalhes';




const Equipamentos = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
  
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dcas, setDcas] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [equipamentos,setEquipamentos] = React.useState([]);
    const [empresa, setEmpresa] = React.useState(props.location.state.empresa)
    const[registros, setRegistros] = React.useState()
    const refPlaca = React.useRef();
    const refSerie = React.useRef();
    const refSetor = React.useRef();
 
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getEquipamentos()
        setPage(0)
    
        
    }, [])

    useEffect(()=>{
        getEquipamentos()

    },[rowsPerPage,page])

   

    const getEquipamentos = () =>{
        setLoading(true)
        Conexao.post('equipamentosController/getEquipamentos', {            
            idcliente: empresa.idcliente,
            idusuario: empresa.idusuario,
            idempresa: empresa.idempresa,
            placa: refPlaca.current ? refPlaca.current.value : '',
            numeroSerie: refSerie.current ? refSerie.current.value : '',
            setor: refSetor.current  ? refSerie.current.value : '',
            limit: rowsPerPage,
            offSet : page, 

        }).then(response=>{
            if(response.data.status == 'OK'){
                setEquipamentos(response.data.payload.dados)
                setRegistros(response.data.payload.registros)
                
                //console.log(response.data.payload)
            }else{
                setEquipamentos([])
                setRegistros([])
            }
            setLoading(false)
        }).catch(error =>{
            setEquipamentos([])
            setRegistros([])
            setLoading(false)
            Swal.fire({
                title : 'Printerconnect', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
        });
    }      

    const clearForm = () =>{
        if(refPlaca.current){
            refPlaca.current.value  = '';
        }
        if(refSerie.current){
            refSerie.current.value  = '';
        }
        if(refSetor.current){
            refSetor.current.value  ='';
        }
        setPage(0)
        getEquipamentos()
    
        
               
    }

   


    return <Principal atualizar = {loading} >

        <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Equipamentos</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        
        <Grid container spacing = {1}>
           
            <Grid item xs = {3}>
                <TextField
                    inputRef = {refPlaca}
                    label ='Placa'
                    variant ='outlined'
                    size ='small'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                        
                               
                   />
            </Grid>
            <Grid item xs = {4}>
                <TextField
                    inputRef = {refSerie}
                    label ='Número de série'
                    variant ='outlined'
                    size ='small'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                                     
                   />
            </Grid>          
           
          
            <Grid item xs = {3}>
                <TextField
                    inputRef = {refSetor}
                    label ='Setor'
                    variant ='outlined'
                    size ='small'
                    fullWidth
                    InputLabelProps={{shrink : true}}                       
                           
                   />
            </Grid>
          
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>{getEquipamentos()}}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>{clearForm()}} ><ClearIcon/> Limpar</Button>
            </Grid>
          
        </Grid>
        <br/>
        <div>
        <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={'grafico-coleta-clientes/revenda/'+empresa.idusuario+'/empresa/'+empresa.idempresa+'/cliente/'+empresa.idcliente} >
                    Home
                </Link>
            <Typography color="textPrimary">Equipamentos</Typography>
            </Breadcrumbs>
        </div>
       <div className='div-form-container'>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
            <Table  >
                <TableHead>
                 
                    <TableCell align='left' style = {{width:"10%"}}>Placa</TableCell>
                    <TableCell align='left' style = {{width:"30%"}}>Equipamento</TableCell>
                    <TableCell align='center' style = {{width:"20%"}}>Setor</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Pedir Toner</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Abrir chamado</TableCell>
                    <TableCell align='center' style = {{width:"10%"}}>Detalhes</TableCell>
                  
                </TableHead>
                    <TableBody>

                        {

                            equipamentos && equipamentos.map(function (equipamento, i) {
                                return <TableRow key={'ROWCOL' + i} >
                                    <TableCell align='left' style={{ width: "10%" }}>{equipamento.codigoadicional}</TableCell>
                                    <TableCell align='left' style={{ width: "30%" }}><Typography variant='body2'><b>{equipamento.modelo}</b></Typography><Typography variant='subtitle2'>{equipamento.numeroserie}</Typography></TableCell>
                                    <TableCell align='center' style={{ width: "20%" }}>{equipamento.setor}</TableCell>
                                    <TableCell align='center' style={{ width: "10%" }}><ModalSuprimentos titulo = {equipamento.modelo} equipamento ={equipamento} /></TableCell>
                                    <TableCell align='center' style={{ width: "10%" }}><ModalChamados titulo = {equipamento.modelo} equipamento ={equipamento}/></TableCell>
                                    <TableCell align='center' style={{ width: "10%" }}><ModalDetalhes titulo={equipamento.modelo} equipamento ={equipamento} /></TableCell>

                                </TableRow>
                            })
                        }

                    </TableBody>
            </Table>
        </Grid>   
        </div>                  
    
        <div className='fabIncluir'>
        
        </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(Equipamentos)