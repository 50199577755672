import React, {useState, useEffect, useRef, Fragment} from 'react'
import { withStyles } from '@material-ui/core/styles';
import {TextField,Divider,Button, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
  , TableRow, Badge} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import BuildIcon from '@material-ui/icons/Build';


import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalChamados = (props) => {

  useEffect(()=>{
   // console.log(props.location.state)
  })
  
  
    const [open, setOpen] = React.useState(false)

  return (
    <div>
      <Tooltip title='Abrir novo chamado'>
      <BuildIcon style={{cursor:'pointer', fontSize: 30, color:'#444' }} onClick={()=>{setOpen(true)}}/>
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='md'
        onClose={()=>setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={()=>setOpen(false)}>
          {props.titulo} 
        </DialogTitle>
        <DialogContent dividers >

        <Grid container spacing = {1}>
           
           <Grid item xs = {2}>
               <TextField
               value ={props.equipamento.codigoadicional ? props.equipamento.codigoadicional : ''} 
                   size={'small'}                            
                   label ='Placa'
                   variant ='outlined'
                   fullWidth
                   InputLabelProps={{shrink : true}} 
                       
                              
                  />
           </Grid>
           <Grid item xs = {4}>
               <TextField
                   size={'small'}   
                   value ={props.equipamento.numeroserie ? props.equipamento.numeroserie : ''}
                   label ='Número de série'
                   variant ='outlined'
                   fullWidth
                   InputLabelProps={{shrink : true}}  
                                    
                  />
           </Grid>
           
          
         
           <Grid item xs = {6}>
               <TextField
                   value ={props.equipamento.setor ? props.equipamento.setor : ''}
                   size={'small'}   
                   label ='Setor'
                   variant ='outlined'
                   fullWidth
                   InputLabelProps={{shrink : true}} 
                       
                          
                  />
           </Grid>
           <Grid item xs = {4}>
               <TextField
               value ={props.equipamento.marca ? props.equipamento.marca : ''} 
                   size={'small'}                            
                   label ='Marca'
                   variant ='outlined'
                   fullWidth
                   InputLabelProps={{shrink : true}} 
                       
                              
                  />
           </Grid>
           <Grid item xs = {8}>
               <TextField
                   size={'small'}   
                   value ={props.equipamento.modelo ? props.equipamento.modelo : ''}
                   label ='Modelo'
                   variant ='outlined'
                   fullWidth
                   InputLabelProps={{shrink : true}}  
                                    
                  />
           </Grid>
         
        </Grid>
        <br/>
        <br/>

        <Grid container spacing = {1}>
           
           <Grid item xs = {4}>
               <TextField
           
                   size={'small'}                            
                   label ='Nome do Reclamante'
                   variant ='outlined'
                   fullWidth
                   InputLabelProps={{shrink : true}} 
                       
                              
                  />
           </Grid>
           <Grid item xs = {8}>
               <TextField
           
                   size={'small'}                            
                   label ='E-mail do Reclamante'
                   variant ='outlined'
                   fullWidth
                   InputLabelProps={{shrink : true}} 
                       
                              
                  />
           </Grid>


           <Grid item xs = {2}>
                  <TextField
                    label='Cont. Mono'
                    variant='outlined'
                    InputLabelProps = {{shrink : true}}
                    fullWidth
                    className='textNumberLarge1'
                    defaultValue={props.equipamento.detalhesColeta.contadormono}                 
                    inputProps={{
                      "min":0, 
                      "max":99999999
                    }}
                    pattern="^-?[0-9]\d*\.?\d*$"
                    InputProps={{min:0}}
                    type='number'
                    size='small'
                
                  />
                </Grid>
                <Grid item xs = {2}>
                  <TextField
                    label='Cont. Colorido'
                    pattern="^-?[0-9]\d*\.?\d*$"
                    variant='outlined'
                    InputLabelProps = {{shrink : true}}
                    fullWidth
                    className='textNumberLarge1'
                    defaultValue={props.equipamento.detalhesColeta.contadorcolor}             
                    InputProps={{min:0}}
                    type='number'
                    size='small'
               
                  />
                </Grid>
                <Grid item xs = {2}>
                  <TextField
                    label='Cont. Total'
                    pattern="^-?[0-9]\d*\.?\d*$"
                    variant='outlined'
                    InputLabelProps = {{shrink : true}}
                    fullWidth
                    className='textNumberLarge1'
                    defaultValue={props.equipamento.detalhesColeta.contadormono + props.equipamento.detalhesColeta.contadorcolor}                
                    InputProps={{min:0}}
                    type='number'
                    size='small'
               
                  />
                </Grid>
                <Grid item xs = {6}>
                  <TextField
                    label='Defeito relatado (até 500 caractéres)'
                    variant='outlined'
                    InputLabelProps = {{shrink : true}}
                    fullWidth
                    size='small'
                   // inputRef={refObservacoes}
                    multiline
                    rows={6}
                    rowsMax={6}
                    
                  />
              </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={3} spacing={3} className='container-buttons'>
            <Button color='primary' variant='contained' ><SaveIcon /> Gravar</Button>
            <Button color='secondary' variant='contained' autoFocus onClick={() => setOpen(false)} ><ClearIcon /> Cancelar</Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalChamados)