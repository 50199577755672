import React from 'react';
import PrintIcon from '@material-ui/icons/Print';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {Typography, Button} from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BannerColeta from './Imagens/bannerCopiadoras.svg'
import BannerRastreador from './Imagens/bannerRastreador.svg'
import BannerAuditoria from './Imagens/bannerAudirotira.svg'


const CardsTop = (props) =>{
    return <div id='card-container-main'>
            <div className='card-container'>
                <div className='card-container-itens'>
                  
                        <img src={BannerColeta} />
                   
                
                </div>
                <div style ={{minHeight:"300px"}}>
                    <Typography variant='h5' color='primary'>Coleta Automática de Contadores</Typography>
                    <p>O Printerconnect é capaz de se conectar a qualquer equipamento de impressão que esteja conectado via rede TCP/IP, capturando informações específicas dos equipamentos.</p>
                </div>
                <Button variant='contained' color='secondary'>Saiba mais</Button>
            </div>

            
            <div className='card-container'>
                <div className='card-container-itens'>
                <img src={BannerRastreador} />
                
                </div>
                <div style ={{minHeight:"300px"}}>
                <Typography variant='h5' color='primary'>Rastreamento de Frota via GPS</Typography>
                <p>Com dispositivo GPS com tecnologia OBD-2, nosso módulo de Rastreamento é capaz de entregar uma gestão de frota eficiente e abastecer o sistema de gestão com informações importantes.</p>
                </div>
                <Button variant='contained' color='secondary'>Saiba mais</Button>
            </div>


            <div className='card-container'>
                <div className='card-container-itens'>
                <img src={BannerAuditoria} />
                
                </div>
                <div style ={{minHeight:"300px"}}>
                <Typography variant='h5' color='primary'>Bilhetagem e Gestão de Impressão</Typography>
                <p>Monitore a utilização do seu parque de impressão, com o Printerconnect você poderá saber: Quem, onde, quando e o quê, está sendo impresso.Tenha mais controle e elimine desperdícios.</p>
                </div>
                <Button variant='contained' color='secondary'>Saiba mais</Button>
            </div>



           
    </div>
}


export default CardsTop;