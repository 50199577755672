import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import DisplayStatus from '../../components/DisplayStatus'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ModalProduto from './ModalNovoProduto'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const LicencasCollector = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [licencasCollector, setLicencasCollector] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
   
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [paginas, setPaginas]= React.useState(1);
    const [ registros, setRegistros] = useState(0)
    const refDataCadastro = React.useRef();
    const refChaveLicenca = React.useRef();
    const refRazaoSocial = React.useRef();
    

    useEffect(()=>{
        
       
    }, [page])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])

    


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };




   

    return <Principal atualizar = {loading}>
    <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Cadastro de Produtos/Peças</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>

            <Grid item xs = {2}>
                <TextField
                    label ='Marca'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    select     
                    SelectProps={{native: true}} 
                    size='small'
                   >
                </TextField>
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Grupo'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    select     
                    SelectProps={{native: true}}   
                    size='small'      
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Sub Grupo'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    select     
                    SelectProps={{native: true}} 
                    size='small'             
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Código Barras'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    
                    size='small'             
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Código Fabricante'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    
                    size='small'             
                   />
            </Grid>
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>{}}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>{
                    refDataCadastro.current.value ='';
                    refChaveLicenca.current.value ='';
                    refRazaoSocial.current.value ='';
                  
                }}><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
            <Grid item xs = {6}>
                <TextField
                    label ='Descrição do produto'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    
                    size='small'             
                   />
            </Grid>
        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Produtos</Typography>
            </Breadcrumbs>
        </div> 
        <div className='div-form-container'>
    <Grid container style = {{marginTop:"10px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-sm'>
            <TableHead>
                <TableCell align='left' style = {{width:"10%", fontSize:'12px'}}>C. Barras</TableCell>
                <TableCell align='left' style = {{width:"10%", fontSize:'12px'}}>C. Fabricante</TableCell>
                <TableCell align='center' style = {{width:"10%", fontSize:'12px'}}>Marca</TableCell>                
                <TableCell align='center' style = {{width:"10%", fontSize:'12px'}}>Grupo</TableCell>
                <TableCell align='center' style = {{width:"10%", fontSize:'12px'}}>Subgrupo</TableCell>
                <TableCell align='left' style = {{width:"30%", fontSize:'12px'}}>Descrição do Produto</TableCell>
                <TableCell align='center' style = {{width:"10%", fontSize:'12px'}}>Estoque</TableCell>
                <TableCell align='center' style = {{width:"10%", fontSize:'12px'}}>Disponível</TableCell>
            </TableHead>

            <TableBody>
                
                
               
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>
    <div className='fabIncluir'>
        <ModalProduto/>
    </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(LicencasCollector)