import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Container, Grid, TextField, InputAdornment, Tooltip, Checkbox,Table, TableBody, TableRow,  TableHead, TableCell} from '@material-ui/core'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ModalBuscaModelo from '../../Modelos/Components/ModalBuscaModelo'
import LoopIcon from '@material-ui/icons/Loop';
import Swal from 'sweetalert2';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ModalBuscaClientes from '../../Clientes/ModalBuscaCliente'
import {Divider, Chip} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Conexao from '../../../providers/Conexao'
import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';

import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalCadastroTipoNumerador(props) {

  const [open, setOpen] = React.useState(false);
  const [contador, setContador] = React.useState('');
  const [multiplicador, setMultiplicador] = React.useState(0);
 


 
    const handleClickOpen = () =>{
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
    }


  

  return (
    <div>
      
      <Button variant='outlined' color='primary' onClick={handleClickOpen}><AddIcon /> Novo Tipo Numerador</Button>
      


      <Dialog disableBackdropClick hideBackdrop  onClose={handleClose} fullWidth aria-labelledby="customized-dialog-title" open={open } disableBackdropClick disableEscapeKeyDown maxWidth='sm'>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Vincular novo tipo de numerador
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField
                        label ='Atribuir Contador'
                        value={contador}
                        onChange={(e)=>setContador(e.target.value)}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        size='small'
                        select 
                        SelectProps={{native: true}}
                        >
                           <option value ='f'>Selecione um contador</option>
                          <optgroup label='Contadores Monocromáticos'/>
                          <option value ='contadorMonoA4'> Contador A4 Mono</option>
                          <option value ='contadorMonoA3'> Contador A3 Mono</option>
                          <option value ='contadorMonoCopia'> Contador Copia Mono</option>
                          <option value ='contadorMonoPrinter'> Contador Impressao mono</option>
                          <option value ='contadorMonoFax'> Contador Fax Mono</option>
                          <option value ='contadorMonoOutros'> Contador Outros Mono</option>
                          <option value ='contadorMonoTotal'>Contador Total Mono</option>
                          <optgroup label='Contadores Coloridos'/>
                          <option value ='contadorColorA4'> Contador A4 Color</option>
                          <option value ='contadorColorA3'> Contador A3 Color</option>
                          <option value ='contadorColorCopia'> Contador Color Copia</option>
                          <option value ='contadorColorPrinter'> Contador Printer Color</option>
                          <option value ='contadorColorFax'> Contador Fax Color</option>
                          <option value ='contadorColorOutros'> Contador Outros Color</option>
                          <option value ='contadorColorTotal'> Contador Total Color</option>
                          <optgroup label='Contadores Gerais'/>
                          <option value ='contadorTotalGeral'> Contador Total Geral</option>
                          <optgroup label='Contadores Scanner'/>
                          <option value ='contadorMonoScanner'> Contador Scanner Mono</option>
                          <option value ='contadorColorScanner'> Contador Scanner Color</option>
                          <option value ='contadorTotalScanner'> Contador Scanner Total</option>
                          <optgroup label='Contadores Lineares'/>
                          <option value ='contadorMetros'> Contador Metros</option>
                          <option value ='contadorCentimetros'> Contador Centimetros</option>
                          <option value ='contadorPolegadas'> Contador Polegadas</option>

                    </TextField>
                </Grid>
            </Grid>

            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs = {12}>
                    <TextField
                        label ='Multiplicador'
                        value={multiplicador}
                        onChange={(e)=>setMultiplicador(e.target.value)}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        size='small'
                        
                        />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button  color='secondary' onClick={handleClose} variant='contained'>Cancelar</Button>

            <Button autoFocus variant='contained'  onClick={()=>{
                
                Swal.fire({
                    title :'Gestão de Equipamentos', 
                    text :'Deseja gravar este novo tipo de numerador?', 
                    showCancelButton : true, 
                    cancelButtonText : 'Cancelar', 
                    showConfirmButton : true, 
                    confirmButtonText : 'Confirmar', 
                    icon:'question'
                }).then(resposta=>{
                    if(resposta.isConfirmed){
                        Conexao.post('equipamentosController/insertTipoNumeradorEquipamento', {
                            idempresa : props.equipamento.idempresa, 
                            idequipamento : props.equipamento.idequipamento, 
                            contador : contador, 
                            multiplicador : multiplicador

                        }).then(response=>{
                            if(response.data.status =='OK'){
                                setOpen(false)
                            }else{

                            }
                        }).catch(error=>{

                        })
                
                    
                    }else{
                                
                    }
                })
                }} color="primary">
                Gravar
                </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
