import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import Iconpatrametros from '@material-ui/icons/Apps'
import FormNovaEmpresa from './FormNovaEmpresa'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import DisplayStatus from '../../components/DisplayStatus'
import SearchIcon from '@material-ui/icons/Search';
import DesativarIcon from '@material-ui/icons/Block'
import AtivarIcon from '@material-ui/icons/Done'
import BuildIcon from '@material-ui/icons/Build'
import RemoveIcon from '@material-ui/icons/DeleteForever'
import ModalParametrosDataclassic from './ModalParametrosDataclassic';



const CadastroEmpresas = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [empresas, setEmpresas] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [selecionado, setSelecionado] = useState([])

    const refRazaoSocial = React.useRef();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        
        getEmpresas();
    }, [])


    useEffect(()=>{
        
    },[])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])


    const getEmpresas = () =>{
        
        setLoading(true)
        Conexao.post('empresasController/getEmpresas?idempresa='+props.location.state.empresa.idusuario, {
            filtro : 'razaoSocial', 
            tipoFiltro : refRazaoSocial.current ? refRazaoSocial.current.value : '',
        }).then(response =>{
            
            if(response.status == 200){
               
                if(response.data.status == 'OK'){
                    setEmpresas(response.data.payload);
                    console.log(response.data.payload)
                }else{
                    setEmpresas([])
                }
            }else{
                setEmpresas([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
        })
    }

    const verificaEmpresaAtiva = (id, operacao) =>{
        
       return true;
    }


    return <Principal atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Cadastro de Empresas
            </Link>                
        </Breadcrumbs>
    }>
        <div style={{paddingLeft : '10px', paddingTop : '5px', paddingBottom : '5px',marginTop : '10px', }}>
            <Grid container spacing = {2} style={{paddingBottom:'0px!important', paddingTop : '0px!important'}}>
            
                
                
                
            </Grid>
        </div>

        <Grid container spacing ={2} style={{ paddingTop: '5px', paddingBottom:'5px', marginTop:'30px'}}>
        <Grid item xs = {3} >
            <TextField
                InputProps={{
                    startAdornment : <InputAdornment position='start'><span>Nome</span></InputAdornment>
                }}
                variant='outlined'
                size='small'
                fullWidth
                inputRef={refRazaoSocial}
                onKeyDown={(e)=>{
                    if(e.keyCode == 13){
                        getEmpresas()
                    }
                }}
            />
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button variant = 'outlined'  style={{height:'100%', backgroundColor:"#fff"}} onClick={()=>{
                getEmpresas()
            }}><SearchIcon/></Button>
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button 
                variant = 'outlined' 
                disabled={selecionado && selecionado.idempresa} 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                onClick={()=>setOpenModal(true)}>
                    <AddIcon /> Novo
            </Button>
        </Grid>
        {/*<Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <ModalDetalhesCliente 
                disabled={selecionado && !selecionado.idcliente || selecionado.ativo == 'f'}
                cliente ={selecionado}
                
                />
            </Grid>*/}
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button variant = 'outlined' fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} disabled={selecionado && !selecionado.idempresa || selecionado.ativo == 'f'} onClick={()=>{
                
                setEdicao(selecionado)
                setOpenModal(true)
                handleClose();
            }}><BuildIcon /> Editar</Button>
        </Grid>
        <Grid item xs ={2} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <ModalParametrosDataclassic 
                revenda={true} 
                idusuario={selecionado.idusuario}
                updateData = {()=>{
                    setSelecionado([])
                    getEmpresas();
                }} Conexao={Conexao} idusuario={props.location.state.empresa.idusuario} empresa={selecionado} disabled={!selecionado.idempresa}/>
        </Grid>
        
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button 
                variant = 'outlined' 
                fullWidth 
                style={{height:'100%', 
                backgroundColor:"#fff", fontSize:'10px'}} 
                disabled={selecionado && !selecionado.idempresa || selecionado.ativo == 'f'} onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja DESATIVAR este cliente?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            Conexao.get('empresasController/disableEmpresa?idempresa='+selecionado.idselecionado+'&status='+empresa.ativo).then(response =>{
                                if(response.data.status == 'OK'){
                                    
                                   
                                    const empr = JSON.parse(sessionStorage.getItem('empresaAtiva@printerConnect'));
                                    
                                    if(empr.idempresa == empresa.idempresa){
                                        empr.ativa = empresa.ativo == 't' ? 'f' :'t'
                                        sessionStorage.setItem('empresaAtiva@printerConnect', JSON.stringify(empr))
                                        
                                    //setEmpresas(empresas.filter(item => item.idempresa != empresa.idempresa ))
                                    Swal.fire({
                                        text:'Operação realizada com sucesso !', 
                                        icon :'success', 
                                        timer : 1500, 
                                        showCancelButton:false, 
                                        showConfirmButton:false
                                    })
                                    }
                                    getEmpresas()
                                   
                                }else{
                                    Swal.fire({
                                        text:'Operação não realizada', 
                                        icon :'warning', 
                                        timer : 1500, 
                                        showCancelButton:false, 
                                        showConfirmButton:false
                                    })
                                }
                            }).catch(error =>{

                            })
                        }
                    })
                    
                   
                }}>
                    <DesativarIcon /> Desativar
            </Button>
        </Grid>
        <Grid item xs ={ 1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
            <Button 
                variant = 'outlined' 
                fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} 
                disabled={selecionado && !selecionado.idempresa || selecionado.ativo == 't'} onClick={()=>{
                    Swal.fire({
                        title : 'Datacount', 
                        text : 'Deseja REATIVAR este cliente?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            Conexao.get('empresasController/disableEmpresa?idempresa='+selecionado.idselecionado+'&status='+empresa.ativo).then(response =>{
                                if(response.data.status == 'OK'){
                                    
                                   
                                    const empr = JSON.parse(sessionStorage.getItem('empresaAtiva@printerConnect'));
                                    
                                    if(empr.idempresa == empresa.idempresa){
                                        empr.ativa = empresa.ativo == 't' ? 'f' :'t'
                                        sessionStorage.setItem('empresaAtiva@printerConnect', JSON.stringify(empr))
                                        
                                    //setEmpresas(empresas.filter(item => item.idempresa != empresa.idempresa ))
                                    Swal.fire({
                                        text:'Operação realizada com sucesso !', 
                                        icon :'success', 
                                        timer : 1500, 
                                        showCancelButton:false, 
                                        showConfirmButton:false
                                    })
                                    }
                                    getEmpresas()
                                   
                                }else{
                                    Swal.fire({
                                        text:'Operação não realizada', 
                                        icon :'warning', 
                                        timer : 1500, 
                                        showCancelButton:false, 
                                        showConfirmButton:false
                                    })
                                }
                            }).catch(error =>{

                            })
                        }
                    })
                    
                   
                }}>
                    <AtivarIcon /> Reativar
            </Button>
        </Grid>

        <Grid item spacing={1} style={{paddingLeft : '1px', paddingRight : '1px'}}>
        <Button variant = 'outlined' fullWidth style={{height:'100%', backgroundColor:"#fff", fontSize:'10px'}} disabled={(selecionado && !selecionado.idempresa) ||(selecionado && selecionado.padrao=='t')} onClick={()=>{
                 Swal.fire({
                    title : 'Datacount', 
                    text : 'Deseja tornar esta empresão padrão?', 
                    icon : 'error', 
                    showConfirmButton : true, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : true, 
                    cancelButtonText : 'Não'
                }).then(resposta=>{
                    if(resposta.isConfirmed){
                        Conexao.get('empresasController/setarEmpresaAtiva?idusuario='+selecionado.idusuario+'&idselecionado='+empresa.idempresa+'&status=ativo').then(response =>{
                            if(response.data.status == 'OK'){
                                
                               
                                getEmpresas()
                               
                            }else{
                                Swal.fire({
                                    text:'Operação não realizada', 
                                    icon :'warning', 
                                    timer : 1500, 
                                    showCancelButton:false, 
                                    showConfirmButton:false
                                })
                            }
                        }).catch(error =>{

                        })
                    }
                })
                
            }}><RemoveIcon /> Padrão</Button>
        </Grid>
    </Grid>


    

    <Grid container style = {{marginTop:"30px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-small table-sm'>
            <TableHead>
                <TableCell align='center'>ID</TableCell>
                <TableCell align='center'>CNPJ</TableCell>
                <TableCell>Razão Social</TableCell>
                <TableCell align='center'>Cidade</TableCell>
                <TableCell align='center'>Estado</TableCell>

                
            </TableHead>
            <TableBody>
                {
                    empresas && empresas.map(function(empresa, i){
                        return <TableRow id = {'EMP'+i} style ={{
                            backgroundColor : selecionado && selecionado.idempresa == empresa.idempresa ? "#E3F2FD" : '', textDecoration: empresa.ativo =='f' ? 'line-through!important' : '', 
                            opacity : selecionado.idempresa && selecionado.idempresa != empresa.idempresa ? 0.5 : 1 
                        }}   onClick={()=>{
                            if(selecionado && selecionado.idempresa == empresa.idempresa){
                                setSelecionado([])
                            }else{
                                setSelecionado(empresa)
                            }
                            
                            
                        }}>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{(i + 1)}</Typography></TableCell>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{empresa.cpf_cnpj}</Typography></TableCell>
                                    <TableCell>
                                        <Typography variant='subtitle2' color='primary'>{empresa.razaosocial}{empresa.padrao =='t' ? ' - (Empresa Padrão)' : ''}</Typography>
                                      
                                       
                                    </TableCell>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{empresa.cidade}</Typography></TableCell>
                                    <TableCell align='center'><Typography variant='caption' color='primary'>{empresa.estado}</Typography></TableCell>
                     
                                   
                                    
                                </TableRow>
                    })
                }
            </TableBody>
        </Table>
    </Grid>
    <ModalFull 
        overflow='body'
        size='lg'
        titulo = {edicao.idempresa ? 'Editar dados da Empresa selecionada' :'Cadastro de Nova Empresa'} 
        showModal = {openModal} 
        closeModal= {()=>setOpenModal(false)}>
        <FormNovaEmpresa
            mode={edicao.idempresa ? 'edicao' : 'cadastro'} 
            edicaoEmpresa = {edicao}
            empresa = {props.location.state.empresa}  
            revenda = {props.location.state.empresa.idusuario}
            updateEmpresas= {()=>{
            getEmpresas();
           
            setOpenModal(false)}}
            />
    </ModalFull>

    <div className='fabIncluir'>
        <Fab size="large" color="primary" aria-label="add" onClick={()=>setOpenModal(true)} >
          <AddIcon />
        </Fab>
    </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroEmpresas)