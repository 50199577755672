import React, {useState, useEffect, useRef, Fragment} from 'react'

import {TextField,   Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Badge} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import DisplayToner from '../../components/DisplayToner'
import DisplayLinearToner from '../../components/DisplayLinearToner'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'; //case 2 
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';//energsaving
import ReportProblemIcon from '@material-ui/icons/ReportProblem';//case 5
import PrintIcon from '@material-ui/icons/Print';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import ModalDetalhesLeitura from './ModalDetalhesColeta'
import BlockIcon from '@material-ui/icons/Block';
import Alert from '@material-ui/lab/Alert'
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ModalFullDetalhes from '../../components/ModalFull2'
import DetalhesColeta from './DetalhesColeta'
import GestureIcon from '@material-ui/icons/Gesture'
import DoorOpen from '@material-ui/icons/MeetingRoom'
import LinkOn from '@material-ui/icons/Link'
import LinkOff from '@material-ui/icons/LinkOff'
import Slider from '@material-ui/core/Slider'



let temporizador = null;

const GestaoColetasPorCliente = (props)=>{
    let history = useHistory();
    const classes = useStyles();
    const [idLeitura, setIdLeitura]= React.useState(-1);
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [licencasCollector, setLicencasCollector]= useState([])
  
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ coletas, setColetas] = useState([])
    const [ coleta, setColeta] = useState([])
    const [ registros, setRegistros] = useState(0)
   
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [parametroToner, setParametroToner] = React.useState(100);

    const refTipoDataPesquisa = React.useRef();
    const refDataInicial = React.useRef();
    const refDataFinal = React.useRef();
    const refPlaca = React.useRef();
    const refNumeroSerie = React.useRef();
    const refModelo = React.useRef();
    const refMarca = React.useRef();
    const refInativos = React.useRef();    
    const refRazaoSocial = React.useRef();
    const refStatus = React.useRef();
    const refNivelToner = React.useRef();
    const refCorToner = React.useRef();
    const refIdCliente = React.useRef();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        getLicencasCollectorEquipamentos();
        getData();
        setPage(0)
    }, [])


    useEffect(()=>{
        getData();
    }, [page, rowsPerPage])


    
    
    

    const clearForm = () =>{
        if(refTipoDataPesquisa.current){
            refTipoDataPesquisa.current.value  ='COLETA';
        }
        if(refDataInicial.current){
            refDataInicial.current.value  = '';
        }
        if(refDataFinal.current){
            refDataFinal.current.value  = '';
        }
        if(refPlaca.current){
            refPlaca.current.value  ='';
        }
        if(refNumeroSerie.current){
            refNumeroSerie.current.value  ='';
        }
        if(refModelo.current){
            refModelo.current.value  ='';
        }
        if(refMarca.current){
            refMarca.current.value  ='';
        }
        if(refRazaoSocial.current){
            refRazaoSocial.current.value  ='';
        }
        if(refInativos.current){
            refInativos.current.value  ='t';
        }

        if(refNivelToner.current){
            refNivelToner.current.value  ='100';
        }
        if(refCorToner.current){
            refCorToner.current.value  ='';
        }
       

    
        setPage(0)
          
    }
   

    const getData = () =>{
        setLoading(true)
        Conexao.post('coletasController/getContadores', {
            idempresa : props.match.params.chaveEmpresa, 
            tipodata : refTipoDataPesquisa.current ?  refTipoDataPesquisa.current.value : '', 
            datainicial : refDataInicial.current ?  refDataInicial.current.value : '', 
            datafinal : refDataFinal.current ?  refDataFinal.current.value : '', 
            placa : refPlaca.current ?  refPlaca.current.value : '', 
            numeroserie :refNumeroSerie.current ?  refNumeroSerie.current.value : '' , 
            modelo :refModelo.current ?  refModelo.current.value : '', 
            marca : refMarca.current ?  refMarca.current.value : '', 
            razaosocial  : refRazaoSocial.current ?  refRazaoSocial.current.value : '',
            idcliente : refIdCliente.current ? refIdCliente.current.value : props.location.state.idcliente, 
            limit: rowsPerPage,
            offSet :  page, 
            ativos : refInativos.current ?  refInativos.current.value : '', 
            status : refStatus.current ?  refStatus.current.value : '', 
            nivelToner : refNivelToner.current ? refNivelToner.current.value : 100,
            toner : 'f',
           
        }).then(response=>{
            if(response.data.status == 'OK'){
                setColetas(response.data.payload.dados)
                setRegistros(response.data.payload.registros)
            }else{
                setColetas([])
                setRegistros(0)
            }
            setLoading(false)
        }).catch(error =>{
            setColetas([])
            setRegistros(0)
            setLoading(false)
            Swal.fire({
                title : 'Datacount', 
                text : 'Sem conexão com servidor remoto !', 
                showCancelButton : true,
                cancelButtonText : 'Cancelar', 
                showConfirmButton :false, 
                icon : 'error'
            })
        });
    }


    const getLicencasCollectorEquipamentos = () =>{
        
        setLoading(true)
        Conexao.post('dcaController/getLicencasCollectorEquipamentos?idempresa='+props.match.params.chaveEmpresa, {
            idusuario : props.match.params.idempresa, 
            idempresa : props.match.params.chaveEmpresa,             
        
        }).then(response =>{
            
            if(response.data.status == 'OK'){
                setLicencasCollector(response.data.payload);
               
           
               
            }else{
                setLicencasCollector([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
        })
    }
   
    const renderStatus = (status, ip, dias, ativo, painel = "") =>{
        if(ativo =='f'){
            return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title ={'Monitoramento Inativo'}><LinkOffIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>Desabilitado</Typography></div>        
        }

        if(ativo == 'f'){
            return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title ={'Este equipamento esta desativado, atualizações de coleta não são processadas'}><LockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESATIVADA</Typography></div>        
        }

       /* if(dias > 0){
            return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title ={'Sem comunicação há '+dias+' dia(s)'}><BlockIcon className={'icon-status-coleta5'}/></Tooltip> <Typography style = {{fontSize:"9px", marginTop:"0px"}}>SEM CONEXÃO</Typography></div>        
        }*/



    switch(status){
        case 1:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
            
        case 2:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PowerSettingsNewIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>PRONTO</Typography></div>
            
        case 3:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><SettingsPowerIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ENERGY SAVING</Typography></div>
            
        case 4:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><PrintIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>IMPRIMINDO</Typography></div>

        case 5:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><ReportProblemIcon className={'icon-status-coleta'+status}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>OCORRÊNCIA</Typography></div>

        case 6:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title={painel}><NotificationsActiveIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ATENÇÃO</Typography></div>

        case 7:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title={painel}><GestureIcon className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"9px", marginTop:"0px"}}>ATOLAMENTO</Typography></div>

        case 8:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><Tooltip title={painel}><DoorOpen className={'icon-status-coleta'+status}/></Tooltip><Typography style = {{fontSize:"9px", marginTop:"0px"}}>Porta Aberta</Typography></div>


        default:return <div><span style = {{fontSize:"10px",}}>{ip}</span><br/><HelpIcon className={'icon-status-coleta'}/><Typography style = {{fontSize:"9px", marginTop:"0px"}}>DESCONHECIDO</Typography></div>
    }
        
   }

   const renderNivelToner = (nivel, cor, rotulo) =>{
        if(!nivel){
            return <DisplayToner width={60} heigth = {60} color = {cor} percentual={0} lineSize={5}  descricao={rotulo}/>
        }
        if(nivel < 0 ){
            return <DisplayToner width={60} heigth = {60} color = {cor} percentual={100} lineSize={6} descricao={rotulo} />
        }else{
            return <DisplayToner width={60} heigth = {60} color = {cor} percentual={nivel} lineSize={6}  descricao={rotulo}/>
        }
        
    
   }

   const hangeSlider = (event, newValue) => {   
                 
    setParametroToner(newValue)
   
  
 // setHabilitarBotao(true)
 
 
    };
    return <Principal atualizar = {loading} full={true} >
       <Grid container spacing = {1}>
           
            <Grid item xs={7}>
                <TextField 
                    label='Cliente'
                    variant='outlined'
                    fullWidth
                    size='small'
                    InputLabelProps={{shrink : true}}
                    select
                    inputRef={refIdCliente}
                    onChange={()=>{
                        getData()
                    }}
                    SelectProps={{native:true}}>
                        <option value={props.location.state.idcliente}>{props.location.state.idcliente+' - '+props.location.state.razaosocial}</option>
                     
                        { licencasCollector && licencasCollector.filter(item=> item.idcliente != props.location.state.idcliente).map(function(licenca, i){
                            return <option value={licenca.idcliente} key={i} >{licenca.idcliente+' - '+licenca.razaosocial}</option>
                        })
                        }
                        
                </TextField>
                
            </Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        
        
        <Grid container spacing = {1} style ={{marginTop:"10px"}}>
            
            <Grid item xs = {2}>
                <TextField
                    label ='Marca'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    inputRef={refMarca}
                    size ='small'
                    />                   
            </Grid>
            <Grid item xs = {7}>
                <TextField
                    label ='Modelo'
                    variant ='outlined'
                    fullWidth
                    size ='small'
                    InputLabelProps={{shrink : true}} 
                    inputRef={refModelo}                   
                   />
            </Grid>
         
            <Grid item xs = {3} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getData()}><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onClick={()=>clearForm()}><ClearIcon/> Limpar</Button>
            </Grid>

        </Grid>
        <Grid container spacing={1} style ={{marginTop:"10px"}}>
            <Grid item xs = {9} spacing={2}>
                <Breadcrumbs aria-label="breadcrumb">            
                    <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                        Home
                    </Link>
                <Typography color="textPrimary">Equipamentos por cliente</Typography>
                </Breadcrumbs>
               
                <span style ={{fontSize:"9px"}}>* Click no número de série para abrir os detalhes da coleta</span>
            </Grid>
            

        </Grid>
       
        <div className='div-form-container'>
        <Grid spacing={1}>
            <Table className='table table-bordered table-striped table-hover table-small table-sm' >
                <TableHead>
                    <TableCell align='center' style = {{width:"5%"}}>Sistema</TableCell>
                    <TableCell align='center' style = {{width:"5%"}}>Conexão</TableCell>  
                    <TableCell align='center' style = {{width:"10%"}}>Cadastro</TableCell>                    
                    <TableCell align='center' style = {{width:"10%"}}>Atualização</TableCell>
                    <TableCell align='left' style = {{width:"30%"}}>Equipamento</TableCell>
                    <TableCell align='center' style = {{width:"15%"}}>Contadores</TableCell>
                    <TableCell align='center' style = {{width:"20%"}}>Setor</TableCell>
                    <TableCell align='center' style = {{width:"8%"}}>Suprimentos</TableCell>
                </TableHead>
                
                
                <TableBody>
                   
                {
                    coletas && coletas.map(function(coleta, i){
                        return <TableRow key = {'ROWCOL'+i} className={coleta.ativo =='f' ? 'table-row-inativo':''} >
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>{renderStatus(coleta.statussistema, coleta.enderecoip, coleta.dias, coleta.ativo, coleta.mensagempainel)}</TableCell>
                        <TableCell align='center'style ={{paddingTop:"5px", paddingBottom:"5px"}} >
                        {coleta.dias  == 0 ? <div>
                                <div ><span style ={{fontSize:"9px"}}>DCA:{coleta.iddca}</span></div>
                                <div ><LinkOn style = {{color:"#00897b"}}/></div>
                                <div ><span style ={{fontSize:"9px"}}>{'ON LINE'}</span></div>
                            </div>:<div>
                                <div ><span style ={{fontSize:"9px"}}>DCA:{coleta.iddca}</span></div>
                                <div ><LinkOff style = {{color:"#f44336"}}/></div>
                                <div ><span style ={{fontSize:"9px"}}>{coleta.dias+' dia(s) atrás.'}</span></div>
                            </div>}
                             
                        </TableCell>                    
                        <TableCell align='center'style ={{paddingTop:"5px", paddingBottom:"5px"}} >
                                <Typography color='primary' variant='caption' style ={{fontSize:"11px"}}>{coleta.datacadastro}</Typography><br/>
                                <Typography color='primary' variant='caption' style ={{fontSize:"9px"}}>{coleta.horacadastro.substr(0, 8)}</Typography>
                        </TableCell>                    
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>
                            
                            <Typography color='primary' variant='caption' style ={{fontSize:"11px"}}>{coleta.datacoleta}</Typography><br/>
                            <Typography color='primary' variant='caption' style ={{fontSize:"9px"}}>{coleta.horacoleta.substr(0, 8)}</Typography>
                        </TableCell>
                        <TableCell align='left' style ={{paddingTop:"5px", paddingBottom:"5px", }}>
                            <Typography color='primary' variant='subtitle2' ><strong>{coleta.marca +' - '+coleta.modelo}</strong></Typography>                         
                            
                            <Typography color='primary' style={{fontSize :'14px'}} className='texto-limitado'  onClick = {()=>{
                                setColeta(coleta)                             
                               //props.history.push({pathname : '/detalhes_Coleta/revenda/'+props.match.params.idempresa+'/empresa/'+props.match.params.chaveEmpresa+'/coleta/'+coleta.idcoleta, state : coleta});
                            }}><strong className='span-link'>{coleta.numeroserie}</strong> {coleta.diascadastro == 0 && <span className='span-novo-item-coleta'>NOVO</span>} </Typography>
                           
                            
                            {coleta.monitorartoner=='t' && <div style={{color:"#444"}}><NetworkCheckIcon style={{color:"#00897b"}} /><span style ={{fontSize:"9px"}}>Alerta de suprimento <strong>GLOBAL </strong> 
                                habilitado : C{coleta.monitoramentociano}% M{coleta.monitoramentomagenta}% Y{coleta.monitoramentoamarelo}% K{coleta.monitoramentopreto}% </span> </div>}
                            
                        </TableCell>
                            <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>                            
                            <Typography variant='caption' className='conter-total'>{coleta.contadortotal}</Typography><br/>
                            <Typography variant='caption' className='conter-mono'>PB: {coleta.monototal}</Typography>
                            <Typography variant='caption' className='conter-color'> CL: {coleta.colortotal}</Typography>
                        </TableCell>
                        <TableCell align='center'style ={{paddingTop:"5px", paddingBottom:"5px"}} >
                                <Typography color='primary' variant='caption' style ={{fontSize:"11px"}}>{coleta.departamento}</Typography><br/>                  
                        </TableCell> 
                        <TableCell align='center' style ={{paddingTop:"5px", paddingBottom:"5px"}}>
                         
                            <DisplayLinearToner value ={coleta.nivelciano ? coleta.nivelciano : 0} rotulo = 'ciano' alerta={coleta.monitorartoner =='t' &&  coleta.alertaciano == 't'} nivelAlerta={coleta.nivelalertaciano}/>
                            <DisplayLinearToner value ={coleta.nivelmagenta ? coleta.nivelmagenta : 0} rotulo='magenta' alerta={coleta.monitorartoner =='t' &&  coleta.alertamagenta == 't'} nivelAlerta={coleta.nivelalertamagenta}/> 
                            <DisplayLinearToner value ={coleta.nivelamarelo ? coleta.nivelamarelo : 0} rotulo ='amarelo' alerta={coleta.monitorartoner =='t' &&  coleta.alertaamarelo == 't'} nivelAlerta={coleta.nivelalertaamarelo}/>
                            <DisplayLinearToner value ={coleta.nivelpreto ? coleta.nivelpreto : 0} rotulo = 'preto' alerta={coleta.monitorartoner =='t' && coleta.alertapreto == 't'} nivelAlerta={coleta.nivelalertapreto}/>
                            
                            { 
                            /*
                                <div className="div-container-nivel-toner">
                                    <div className="div-container-cirular-nivel">
                                        {renderNivelToner(coleta.nivelcian, "#304ffe", 'C')}
                                    </div>
                                    <div className="div-container-cirular-nivel">
                                        {renderNivelToner(coleta.nivelamarelo, "#b71c1c", 'Y')}
                                    </div> 
                                    <div className="div-container-cirular-nivel">
                                        {renderNivelToner(coleta.nivelmagenta, "#ffa000", 'M')}
                                    </div>
                                    <div className="div-container-cirular-nivel">
                                        {renderNivelToner(coleta.nivelpreto, "#000", 'K')}
                                    </div>
                                </div>
                            */
                         }
                        </TableCell>
                    </TableRow>
                    })
                    
                    }
                    {
                        coletas.length == 0 && <TableRow>
                        <TableCell colSpan={8} align='left'>
                            <Alert severity='info'>{loading ?'Aguarde... buscando informações.' : '* Não existem contadores para o filtro especificado.'}</Alert>
                        </TableCell>
                       
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </Grid>                     
        </div>
        <div className='fabIncluir'>
           
        </div>
      <ModalDetalhesLeitura idleitura = {idLeitura} closeModal = {()=>setIdLeitura(-1)}/>

      <ModalDetalhesLeitura idleitura = {idLeitura} closeModal = {()=>setIdLeitura(-1)}/>
      <ModalFullDetalhes 
        showModal={coleta && coleta.idcoleta} 
        marginTop
        setOpen = {()=>setColeta([])}
        titulo ={coleta.idcoleta && coleta.numeroserie+' - '+coleta.modelo}>
            <DetalhesColeta state = {coleta} showModal={coleta && coleta.idcoleta} />
        </ModalFullDetalhes>


    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(GestaoColetasPorCliente)