import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {Grid, Container, InputAdornment, TextField, Divider, Tooltip} from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print';
import PersonIcon from '@material-ui/icons/Person';
import ModalBuscaEquipamentoChamado from './ModalBuscaEquipamentoChamado'
import Swal from 'sweetalert2'
import Conexao from '../../providers/Conexao'
import { Alert, AlertTitle } from '@material-ui/lab';
import IconeFoto from '@material-ui/icons/PhotoAlbum'
import IconAssinatura from '@material-ui/icons/Brush'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoChamado(props) {
  const [open, setOpen] = React.useState(false);
 

 


  const handleClickOpen = () => {
   setOpen(true)
  };
  const handleClose = () => {
    setOpen(false);
  };




  return (
    <div>

      <Button autoFocus color="primary" variant='outlined' onClick={handleClickOpen}>
        <IconAssinatura/> Assinatura
      </Button>
      <Dialog scroll='body' fullWidth  onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Visualização de Assinatura
        </DialogTitle>
        <DialogContent dividers>
            <img src = {"https://printerconnect.com.br/api/imagens/mobile/assinaturas/assinatura_"+props.idchamado+"_empresa_"+props.idempresa+"_cliente_"+props.idcliente+".jpg"} />
        </DialogContent>
        <DialogActions>   
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}




