import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Conexao from '../../../providers/Conexao'
import {Grid, Divider} from '@material-ui/core'
import DisplayToner from '../../../components/DisplayToner'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalDetalhesColeta(props) {
  const [open, setOpen] = React.useState(false);
  const [coleta, setColeta]= React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.closeModal()
  };


  React.useEffect(()=>{
      if(props.idleitura > 0){
        getDetalhes();
      }else{
        setColeta([])
      }
    
  }, [props.idleitura])


  const getDetalhes = () =>{
    Conexao.get('coletasController/getDetalhes?idcoleta='+props.idleitura).then( response =>{
        if(response.data.status=='OK'){
            setColeta(response.data.payload);
        }
    }).catch( error =>{

    });
  }

  return (
    <div>
      
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.idleitura > 0} fullWidth maxWidth={'lg'} disableBackdropClick hideBackdrop disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Detalhes de Coleta
        </DialogTitle>
        <DialogContent dividers>
            <Grid container spacing = {1}>
                <Grid item xs = {11}>
                    <Grid container spacing = {1}>
                        <Grid item xs = {2}>
                            <Typography variant = 'subtitle1' className='detalhes-contadores'>adssas</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs = {1} className='detalhes-nivel'>                   
                        <DisplayToner marginTop={5} classe='no-class' color='#2196f3' width ={"70%"} heigth={"70%"} percentual ={coleta.nivelciano ? coleta.nivelciano : 0} descricao={coleta.numeroserieciano}/>
                        <DisplayToner marginTop={5} classe='no-class' color='#d50000' width ={"70%"} heigth={"70%"} percentual ={coleta.nivelmagenta ? coleta.nivelmagenta : 0 }  descricao={coleta.numeroseriemagenta}/>
                        <DisplayToner marginTop={5} classe='no-class' color='#ffa000' width ={"70%"} heigth={"70%"} percentual ={coleta.nivelamarelo ? coleta.nivelamarelo : 0}  descricao={coleta.numeroserieamarelo}/>
                        <DisplayToner marginTop={5} classe='no-class' color='#424242' width ={"70%"} heigth={"70%"} percentual ={coleta.nivelpreto ? coleta.nivelpreto : 0}  descricao={coleta.numeroseriepreto}/>
                  
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
