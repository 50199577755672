import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Fab from '@material-ui/core/Fab';
import RouteIcon from '@material-ui/icons/Person'
import DivAvatarVeiculo from './CardAvatarVeiculo'
import { Typography,TextField, InputAdornment , CircularProgress, Grid} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import IconeVelocidade from './IconeVelocidade'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {Tabs, Tab} from 'react-bootstrap'
import Moment from 'react-moment';
import 'moment-timezone';
import CardPosicoes from './CardPosicaoVeiculo'
import CardMotorista from './CardMotoristas'
import Swal from 'sweetalert2'
import Conexao from '../../../providers/Conexao'
import CalendarIcon from '@material-ui/icons/CalendarToday'




const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const {veiculo, posicoesTrajeto} = props;
  const [motoristas, setMotoristas] = React.useState([])
  const [motorista, setMotorista] = React.useState([])
  const [chaveTrajeto, setChaveTrajeto] = React.useState('');
  const [trajetos, setTrajetos] = React.useState([])


  const refDataInicial = React.useRef();
  const refDataFinal = React.useRef();


  const toggleDrawer = () =>{
    setState(false)
    diferencaHoras();
  }

  const refMotorista = React.useRef();


  React.useEffect(()=>{
    if(state){
     props.clearPesquisaVeiculo()
    }
  },[state])
  

  const diferencaHoras = () =>{
   
  }

  const listarMotoriscas = (filtro) =>{
    if(filtro.length < 3){
      return
    }
    setLoading(true)
    Conexao.post('veiculosController/listarMotoristas',{
      idusuario : props.idusuario, 
      filtro : filtro

    }).then(response=>{
      if(response.data.status=='OK'){
        setMotoristas(response.data.payload)
      }else{
        setMotoristas([])
      }
      setLoading(false)
    }).catch(error=>{
      setMotoristas([])
      setLoading(false)
      
    })
  }

  const listarPontosByMotorista = (filtro) =>{
    props.setTrajeto([])

    setLoading(true)
    Conexao.post('veiculosController/listarPontosByMotorista',{
      idusuario : props.idusuario, 
      idmotorista : motorista.idmotorista ? motorista.idmotorista : -1, 
      datainicial : refDataInicial.current ? refDataInicial.current.value : '' , 
      datafinal : refDataFinal.current ? refDataFinal.current.value : '', 


    }).then(response=>{
      if(response.data.status=='OK'){
        setTrajetos(response.data.payload)
      }else{
        setTrajetos([])
      }
      setLoading(false)
    }).catch(error=>{
      setTrajetos([])
      setLoading(false)
      
    })
  }
 
  return (
    <div>
      <React.Fragment key={'1'}>               
        <img 
            style ={{
                width : '48px', 
                height : '48px', 
                borderRadius :'32px', 
                cursor:'pointer'
            }}
            onClick={()=>setState(true)}
            src ='https://icones.pro/wp-content/uploads/2021/03/avatar-de-personne-icone-homme.png' />

       
       
          
          <Drawer            
            anchor={'right'} 
            hideBackdrop={false}  
            open={state}  
            variant="temporary" 
            type='paperAnchorDockedRight' 
            onClose={()=>{
              toggleDrawer()
            }}>
           
                <div style ={{width:'600px', padding:'20px', paddingTop:'10px', overflowX:'hidden' }}>
                 
                     
                    <br/> 
        
            
               
               
                
                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example" >
                <Tab eventKey={1} title="Motoristas" disabled={loading }>
                <br/>
                  <TextField 
                          fullWidth 
                          variant='outlined' 
                          placeholder='Digite o nome do motorista'
                          inputRef={refMotorista}
                          onKeyDown={(e)=>{
                            if(e.keyCode == 13){
                              listarMotoriscas(e.target.value)
                              setMotorista([])
                            }
                          }}
                          InputProps ={{
                            startAdornment : <InputAdornment position='start'>
                            {loading ? <CircularProgress size={22}/> : <SearchIcon/>}
                            </InputAdornment>
                          }}
                          className='input-blank'/>
                <div style ={{width : '100%', padding:'10px', paddingTop : '20px', }}>
                      {
                              motoristas && motoristas.map(function(item, i){
                                return <CardMotorista selecionado={motorista} setMotorista={(data)=>{
                                  setMotorista(data)
                                  setTrajetos([])
                                  refMotorista.current.value = data.nome
                                }}  setTrajeto={(data)=>{
                                                                                                      
                                  }} motorista={item}/> 
                              })
                            }

                        {
                          motoristas && motoristas.length == 0 && <span style ={{color: '#7777'}}>* Não existe(m) dado(s).</span>
                        }
                      </div>
                 </Tab>
                  <Tab eventKey={2} title="Trajeto(s) Percorrido(s)" disabled={loading || !motorista.idmotorista}>
                  <Grid container spacing={1} style ={{marginTop:'20px'}}>
                        <Grid item xs = {6}>
                            <TextField 
                                fullWidth 
                                variant='outlined' 
                                placeholder='Data Inicial'
                                type='date'
                                inputRef={refDataInicial}
                                InputLabelProps={{shrink : true}}
                                InputProps ={{
                                  startAdornment : <InputAdornment position='start'>
                                    <CalendarIcon/>
                                  </InputAdornment>
                                }}
                                size='small'/>
                        </Grid>
                        <Grid item xs = {6}>
                            <TextField 
                                fullWidth 
                                variant='outlined' 
                                placeholder='Data Final'
                                inputRef={refDataFinal}
                                type='date'
                                InputLabelProps={{shrink : true}}
                                InputProps ={{
                                  startAdornment : <InputAdornment position='start'>
                                    <CalendarIcon/>
                                  </InputAdornment>
                                }}
                                size='small'/>
                          </Grid>
                     </Grid>
                     <Button variant='outlined' color='primary' fullWidth style ={{marginTop:'10px'}} onClick={()=>{
                       listarPontosByMotorista()
                     }}>Pesquisar</Button>
                      <div style ={{width : '100%', padding:'10px', paddingTop : '20px', }}>
                      {
                        trajetos && trajetos.map(function(trajeto, i){
                          trajeto.motorista = trajeto.placa+' - '+trajeto.modelo 
                          return <CardPosicoes  setTrajeto={(data)=>{
                              if(!props.loading){
                                props.setTrajeto(data)
                                setChaveTrajeto(trajeto.chavetrajeto)
                              }                                                                      
                            }} trajeto={trajeto} chaveTrajeto={chaveTrajeto}/> 
                        })
                      }
                      
                      { trajetos && trajetos.length == 0 && <span style ={{color: '#7777'}}>* Não existe(m) dado(s).</span> }
                      
                      </div>
                  </Tab>                                    
                </Tabs>                
            </div> 
          </Drawer>
        </React.Fragment>
    </div>
  );
}