import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, Badge, TableCell, TableHead, Table, TableBody, TableRow} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {Divider, TextField, Grid, Container, InputAdornment} from '@material-ui/core'
import Filter9PlusIcon from '@material-ui/icons/Filter9Plus';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalTiposNumeradores = (props) => {
  const [open, setOpen] = React.useState(false)
  const [tiposNumeradores, setTiposNumeradores] = React.useState([])
  const [tipoNumerador, setTipoNumerador] = React.useState({
    idtiponumerador: -1, descricao: "", multiplicador:1
  })

  const [tiposNumeradoresModelos, setTiposNumeradoresModelos] = React.useState([])
  const txtMultiplicador = React.useRef();


  React.useEffect(()=>{
    if(props.open || open){
      getTipoNumeradoresFiltroModelo();
      getTiposNumeradores();
    }
  }, [props.open, open])


  React.useEffect(()=>{
    console.log(tiposNumeradoresModelos)
  }, [tiposNumeradoresModelos])


  const getTipoNumeradoresFiltroModelo = () =>{
    Conexao.post('/tiposNumeradoresController/getTipoNumeradoresFiltroModelo', {
      idusuario : props.idUsuario, 
      idmodelo : props.idModelo, 
      colorido : props.colorido
    }).then( response =>{
     
      if(response.data.status == 'OK'){
   
        setTiposNumeradores(response.data.payload)
        setTipoNumerador(response.data.payload[0])
      }else{
        setTiposNumeradores([])
      }
    }).catch(error =>{
      setTiposNumeradores([])
      Swal.fire({
        title :'GESTÃO DE EQUIPAMENTOS', 
        text : 'Não foi possível recuperar lista de Tipos de Contadores', 
        showCancelButton : false, 
        showConfirmButton : false, 
        timer : 3000
      })
    })
  }

  const getTiposNumeradores = () =>{
    Conexao.post('modelosController/getTiposNumeradores', {
      idmodelo : props.idModelo, 
      idusuario : props.idUsuario
    }).then(response =>{
      if(response.data.status =='OK'){
        setTiposNumeradoresModelos(response.data.payload)
      }else{
        setTiposNumeradoresModelos([])
      }
    }).catch(error =>{
      setTiposNumeradoresModelos([])
    })
  }

 

  
  return (
    <div>
        
        <Tooltip title='Cadastro de tipos de númeradores usados por este modelo de equipamento, eles serão vinculados aos equipamentos que forem atribuidos a este modelo.'>
        <Button  onClick={()=>setOpen(true)} >
        
            <Badge badgeContent={props.numeradores} color = 'primary'>
                <Filter9PlusIcon />
            </Badge>
            
        </Button>
        </Tooltip>
     
     
      <Dialog 
        disableBackdropClick
        fullWidth
        maxWidth='md'
        onClose={()=>{
          props.updateLista();
          setOpen(false)
          }} aria-labelledby="customized-dialog-title" open={open || props.open}>
        <DialogTitle id="customized-dialog-title" onClose={()=>{
          props.updateLista();
            setOpen(false)
          
        }}
            >
          Cadastro de Tipos de Numeradores : {props.modelo}
        </DialogTitle>
        <DialogContent dividers >
            <Container>
                <Grid container spacing ={2}>
                    <Grid item xs = {6}>
                        <TextField
                            label = 'Descrição do Numerador'
                            variant = 'outlined'
                            InputLabelProps = {{shrink : true}}
                            select 
                            SelectProps ={{native : true}}
                            onChange = {(e)=>{
                              setTipoNumerador(tiposNumeradores[e.target.value])
                              console.log(tiposNumeradores[e.target.value])
                            }}
                            fullWidth>
                              <option value = {tiposNumeradores[0] ? tiposNumeradores[0].idtiponumerador : -1}>{tiposNumeradores[0] ? tiposNumeradores[0].descricao : ''}</option>
                              {
                                tiposNumeradores && tiposNumeradores.map(function(tipo, i){
                                  return <option value = {i}>{tipo.descricao}</option>
                                })
                              }
                        </TextField>

                    </Grid>
                    <Grid item xs = {2}>
                        <TextField
                            label = 'Multiplicador'
                            variant = 'outlined'
                            InputLabelProps = {{shrink : true}}
                            type='number'
                            defaultValue = {tipoNumerador.multiplicador}
                            inputRef = {txtMultiplicador}
                            fullWidth/>

                    </Grid>
                    <Grid item xs = {1}>
                        <Button color = 'primary' variant='outlined' fullWidth size='large' onClick = {()=>{
                          Swal.fire({
                            title : 'Gestão de Equipamentos', 
                            text : 'Deseja vincular este tipo de numerador ?', 
                            icon : 'question', 
                            showCancelButton : true, 
                            showConfirmButton : true, 
                            cancelButtonText : 'Cancelar', 
                            confirmButtonText : 'Confirmar'
                          }).then(resposta =>{
                            if(resposta.isConfirmed){
                              if(tipoNumerador.descricao == '' || tipoNumerador.idtiponumerador == -1){
                                Swal.fire({
                                  title :'GESTÃO DE EQUIPAMENTOS', 
                                  text : 'Selecione um tipo de numerador !',
                                  icon : 'warning',  
                                  showCancelButton : false, 
                                  showConfirmButton : false, 
                                  timer : 3000
                                })
                              }else{
                                if(txtMultiplicador.current.value > 0){
                                  Conexao.post('modelosController/insertTipoNumerador', {
                                    idtiponumerador : tipoNumerador.idtiponumerador, 
                                    idmodelo : props.idModelo, 
                                    idusuario : props.idUsuario, 
                                    descricao : tipoNumerador.descricao, 
                                    multiplicador : txtMultiplicador ? txtMultiplicador.current.value  : tipoNumerador.multiplicador
                                  }).then(response=>{
                                    if(response.data.status =='OK'){
                                      Swal.fire({
                                        title :'GESTÃO DE EQUIPAMENTOS', 
                                        text : 'Tipo de Numerador vinculado com sucesso !',
                                        icon : 'success',  
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer : 3000
                                      })
                                      const tipo = tipoNumerador
                                      getTipoNumeradoresFiltroModelo();
                                      getTiposNumeradores();
                                    }else{
                                      Swal.fire({
                                        title :'GESTÃO DE EQUIPAMENTOS', 
                                        text : 'Não foi possível vincular',
                                        icon : 'warning',  
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer : 3000
                                      })
                                    }
                                  }).catch(error =>{
                                    Swal.fire({
                                      title :'GESTÃO DE EQUIPAMENTOS', 
                                      text : 'Não foi possível vincular',
                                      icon : 'error',  
                                      showCancelButton : false, 
                                      showConfirmButton : false, 
                                      timer : 3000
                                    })
                                  })
                                }else{
                                  Swal.fire({
                                    title :'GESTÃO DE EQUIPAMENTOS', 
                                    text : 'Informe o fator de multiplicação !!',
                                    icon : 'error',  
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 3000
                                  })
                                }
                              
                            }
                             
                            }
                          })

                        }}>Incluir</Button>

                    </Grid>
                </Grid>
            </Container>
           <div style ={{ height : "300px"}}>
                

                <Table>
                    <TableHead>
                        <TableCell align='center' style={{width:"10%"}}>#</TableCell>
                        <TableCell align='left' style={{width:"60%"}}>Descrição</TableCell>
                        <TableCell align='center' style={{width:"10%"}}>Multiplicador</TableCell>

                        <TableCell align='center' style={{width:"10%"}}>Excluir</TableCell>
                    </TableHead>

                    <TableBody>
                      {
                        tiposNumeradoresModelos  && tiposNumeradoresModelos.map(function(tipoNumeradorModelo, i){
                          return <TableRow>
                          <TableCell align='center' style={{width:"10%"}}>{i + 1}</TableCell>
                          <TableCell align='left' style={{width:"60%"}}>{tipoNumeradorModelo.descricao}</TableCell>
                          <TableCell align='center' style={{width:"10%"}}>{tipoNumeradorModelo.multiplicador}</TableCell>
      
                          <TableCell align='center' style={{width:"10%"}}><Button onClick={()=>{
                            Swal.fire({
                              title :'GESTÃO DE EQUIPAMENTOS', 
                              text : 'Deseja remover o vinculo deste tipo de numerador ?',
                              icon : 'question',  
                              showCancelButton : true, 
                              showConfirmButton : true, 
                              cancelButtonText : 'Cancelar', 
                              confirmButtonText : 'Confirmar'
                            }).then(resposta=>{
                              if(resposta.isConfirmed){
                                Conexao.post('modelosController/deleteTipoNumerador', {
                                  idtiponumerador : tipoNumeradorModelo.idtiponumerador, 
                                  idmodelo : props.idModelo, 
                                  idusuario : props.idUsuario, 
                                }).then(response =>{
                                  if(response.data.status == 'OK'){
                                    Swal.fire({
                                      title :'GESTÃO DE EQUIPAMENTOS', 
                                      text : 'Tipo de Numerador removido com sucesso !',
                                      icon : 'success',  
                                      showCancelButton : false, 
                                      showConfirmButton : false, 
                                      timer : 3000
                                    })
                                    getTipoNumeradoresFiltroModelo();
                                    getTiposNumeradores();
                                  }else{
                                    Swal.fire({
                                      title :'GESTÃO DE EQUIPAMENTOS', 
                                      text : 'Não foi possível remover !',
                                      icon : 'warning',  
                                      showCancelButton : false, 
                                      showConfirmButton : false, 
                                      timer : 3000
                                    })
                                  }
                                }).catch(error =>{
                                  Swal.fire({
                                    title :'GESTÃO DE EQUIPAMENTOS', 
                                    text : 'Não foi possível remover !',
                                    icon : 'error',  
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 3000
                                  })
                                })
                              }
                              
                            })
                          }}><DeleteForeverIcon className='iconDanger' style={{border:'none!important'}}/></Button></TableCell>
                      </TableRow>
                        })
                      }
                    </TableBody>
                </Table>
           </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>{
              props.updateLista();
              setOpen(false)}} variant='outlined' color="secondary">
            Fechar
          </Button>

          
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalTiposNumeradores)