import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {TextField, Grid, InputAdornment, CircularProgress} from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings';
import ListaMarcas from './ModalListaMarcas'
import ListarModelos from './ModalListaModelos'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = React.useState(false)
    const [loadingMarcas, setLoadingMarcas] = React.useState(false)
    const [loadingModelos, setLoadingModelos] = React.useState(false)
    const [marca, setMarca] = React.useState([])
    const [modelo, setModelo] = React.useState([])

    const refMarca = React.useRef();
    const refModelo = React.useRef();
    const refPlaca = React.useRef();
    const refFrota = React.useRef();
    const refAno = React.useRef();
    const refAnoModelo = React.useRef();
    const refCombustivel = React.useRef();
    const refCapacidade = React.useRef();
    const refObservacoes = React.useRef();

    const handleClickOpen = () =>{
        setOpen(true)
        setMarca({
            idmarca : -1, 
            descricao : '', 
        })
        setModelo({
            idmodelo : -1, 
            descricao : ''
        })
        
    }
    const resetModelo = () =>{
        setModelo({
            idmodelo : -1, 
            descricao : ''
        })
    }

    const handleClose = () =>{
        setOpen(false)
    }

  
  return (
    <div>
      <Tooltip title='Cadastrar um novo veículo'>
          <Fab color='primary' onClick={handleClickOpen} style={{cursor:'pointer'}}>
              <AddIcon/>
          </Fab>
        
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Cadastro de Novo Veículo
        </DialogTitle>
        <DialogContent dividers style = {{height:"350px", overflow:"auto"}}>
            <Grid container spacing ={1}>
                <Grid item xs ={3}>
                    <TextField
                        label='Placa*'
                        inputRef={refPlaca}
                        size='small'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        variant='outlined'/>
                </Grid>
                <Grid item xs ={2}>
                    <TextField
                        label='Nº Frota'
                        inputRef={refFrota}
                        size='small'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        variant='outlined'/>
                </Grid>
                <Grid item xs ={7}>
                    <TextField
                        label='Marca*'
                       
                        size='small'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        value = {marca.descricao}
                        InputProps={{
                            startAdornment : <InputAdornment position='start'>
                                <ListaMarcas  usuario={props.usuario} setValorMarca = {(valor)=>{
                                    setMarca(valor)
                                    resetModelo();                                  
                                }}/> 
                            </InputAdornment>
                        }}
                        variant='outlined'/>
                </Grid>
                
                
            </Grid>
            <br/>
            <Grid container spacing ={1}>
                <Grid item xs ={12}>
                    <TextField
                        label='Modelo*'
                      
                        size='small'
                        fullWidth
                        value = {modelo.descricao}
                        InputLabelProps={{shrink : true}}   
                        InputProps={{
                            startAdornment : <InputAdornment position='start'>
                                <ListarModelos usuario={props.usuario} marca = {marca.idmarca} disabled={!marca.idmarca} setValorModelo = {(valor)=>{                                  
                                    setModelo(valor)
                                }}/> 
                            </InputAdornment>
                        }}                                         
                        variant='outlined'/>
                          
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing ={1}>
                <Grid item xs ={2}>
                    <TextField
                        label='Ano fabric'
                        inputRef={refAno}
                        size='small'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        variant='outlined'/>
                </Grid>
                <Grid item xs ={2}>
                    <TextField
                        label='Ano Modelo'
                        inputRef={refAnoModelo}
                        size='small'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        variant='outlined'/>
                </Grid>
                <Grid item xs ={6}>
                    <TextField
                        label='Combustivel'
                        inputRef={refCombustivel}
                        size='small'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        select
                        SelectProps={{native : true}}
                        variant='outlined'>
                            <option value='f'></option>
                            <option value='GASOLINA'>GASOLINA</option>
                            <option value='ETANOL'>ETANOL</option>
                            <option value='FLEX'>FLEX</option>                            
                            <option value='DIESEL'>DIESEL</option>
                            <option value='GNV'>GNV</option>
                            <option value='HIBRIDO'>HIBRIDO</option>
                            <option value='ELETRICO'>ELETRICO</option>
                    </TextField>
                </Grid>
                <Grid item xs ={2}>
                    <TextField
                        label='Cap. Tanque'
                        inputRef={refCapacidade}
                        size='small'
                        fullWidth
                        InputLabelProps={{shrink : true}}
                        variant='outlined'/>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing ={1}>
                <Grid item xs ={12}>
                    <TextField
                        label='Observaçoes'
                        inputRef={refObservacoes}
                        size='small'
                        fullWidth
                        multiline
                        rows={5}
                        rowsMax={5}
                        InputLabelProps={{shrink : true}}
                        variant='outlined'/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant='contained' color="secondary">
            Cancelar
          </Button>
          <Button autoFocus onClick={()=>{
              
              if(refPlaca.current && refPlaca.current.value.trim() === ''){
                  Swal.fire({
                      title : 'Printerconnect - Frota', 
                      text : 'Você deve informar a placa do veículo!', 
                      icon : 'warning', 
                      showConfirmButton : true, 
                      showCancelButton : false
                  })
                  return
              }

              if(!marca.idmarca || marca.idmarca == -1){
                Swal.fire({
                    title : 'Printerconnect - Frota', 
                    text : 'Você deve informar a marca do veículo!', 
                    icon : 'warning', 
                    showConfirmButton : true, 
                    showCancelButton : false
                })
                return
            }

            if(!modelo.idmodelo || modelo.idmodelo == -1){
                Swal.fire({
                    title : 'Printerconnect - Frota', 
                    text : 'Você deve informar o modelo do veículo!', 
                    icon : 'warning', 
                    showConfirmButton : true, 
                    showCancelButton : false
                })
                return
            }

            if(refAno.current && refAno.current.value.trim() === ''){
                Swal.fire({
                    title : 'Printerconnect - Frota', 
                    text : 'Você deve informar o Ano de Fabricação do veículo!', 
                    icon : 'warning', 
                    showConfirmButton : true, 
                    showCancelButton : false
                })
                return
            }

            if(refAnoModelo.current && refAnoModelo.current.value.trim() === ''){
                Swal.fire({
                    title : 'Printerconnect - Frota', 
                    text : 'Você deve informar o Ano do Modelo do veículo!', 
                    icon : 'warning', 
                    showConfirmButton : true, 
                    showCancelButton : false
                })
                return
            }
            if(refCombustivel.current && refCombustivel.current.value.trim() === 'f'){
                Swal.fire({
                    title : 'Printerconnect - Frota', 
                    text : 'Você deve informar o tipo de combustivel do veiculo!', 
                    icon : 'warning', 
                    showConfirmButton : true, 
                    showCancelButton : false
                })
                return
            }
            if(refCapacidade.current && refCapacidade.current.value.trim() === ''){
                Swal.fire({
                    title : 'Printerconnect - Frota', 
                    text : 'Você deve informar a capacidade do tanque do veiculo!', 
                    icon : 'warning', 
                    showConfirmButton : true, 
                    showCancelButton : false
                })
                return
            }


              Swal.fire({
                  title :'Printerconnect - Frota', 
                  text :'Deseja cadastrar este novo veículo?', 
                  icon:'question', 
                  showConfirmButton :true, 
                  confirmButtonText : 'Sim', 
                  showCancelButton : true, 
                  cancelButtonText : 'Não'
              }).then(resposta=>{
                  if(resposta.isConfirmed){
                    Conexao.post('veiculosController/insertVeiculo', {
                        idusuario : props.usuario, 
                        placa : refPlaca.current ? refPlaca.current.value : '', 
                        frota : refFrota.current ? refFrota.current.value : '', 
                        marca : marca.idmarca,                         
                        modelo : modelo.idmodelo, 
                        ano : refAno.current ? refAno.current.value : 0, 
                        anoModelo : refAnoModelo.current ? refAnoModelo.current.value : 0, 
                        combustivel : refCombustivel.current ? refCombustivel.current.value : '', 
                        capacidade : refCapacidade.current ? refCapacidade.current.value : 0, 
                        observacoes : refObservacoes.current ? refObservacoes.current.value : '', 
                    }).then(response=>{
                        if(response.data.status =='OK'){
                            Swal.fire({
                                title : 'Printerconnect - Frota', 
                                text : 'Inserido com sucesso!', 
                                icon:'success', 
                                showCancelButton:false, 
                                showConfirmButton : false, 
                                timer : 1000
                            })
                            props.getVeiculos();
                            setOpen(false)
                        }else{
                            Swal.fire({
                                title : 'Printerconnect - Frota', 
                                text : 'Não foi possível inserir!', 
                                icon:'warning', 
                                showCancelButton:false, 
                                showConfirmButton : false, 
                                timer : 1000
                            })
                            setOpen(false)
                        }
                    }).catch(error=>{
                        Swal.fire({
                            title : 'Printerconnect - Frota', 
                            text : 'Não foi possível inserir. Erro remoto', 
                            icon:'error', 
                            showCancelButton:false, 
                            showConfirmButton : false, 
                            timer : 1000
                        })
                        setOpen(false)
                    })
                  }
              })
          }} variant='contained' color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalPadrao)