import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {Button} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SelecionaEmpresaAtiva from '../../components/SelecionaEmpresaAtiva'
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import BuildIcon from '@material-ui/icons/Build';
import {logout  } from '../../providers/Autenticate';
import {Tooltip} from '@material-ui/core'
import Conexao from '../../providers/Conexao'
import LogoDatabit from '../../components/imagens/logo-databit.png'
import logoDatacount from '../LandingPage/Componentes/logo-databit.png'


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    padding : 0
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    fontWeight:'normal!important',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    paddingLeft:'0px',
    paddingRight:"10px", 
    paddingLeft : '70px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));


let timer = null;
var varUsuario =null;
var varEmpresa = null;
export default function BarraPrincipal(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [empresa, setEmpresa] = React.useState([]);
  const [empresaAtiva, setEmpresaAtiva] = React.useState([]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [alertas, setAlertas]= React.useState({alertasSuprimentos:0});

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogoff = () =>{
    logout();
    setAnchorEl(null);
    handleMobileMenuClose();
    window.location.href ='/'
  }

  React.useEffect(()=>{
    const emp = sessionStorage.getItem('empresa@printerConnect', []);
    const empAtiva = JSON.parse(sessionStorage.getItem('empresaAtiva@printerConnect', null));
   
    varEmpresa = empAtiva ? empAtiva.idempresa : -1;
    varUsuario = empAtiva ? empAtiva.idusuario : -1;
    setEmpresa(JSON.parse(emp))
    if(empAtiva){
      setEmpresaAtiva(empAtiva)
  }else{
      setEmpresaAtiva({idempresa:'', razaosocial:''})
      
  }
    
  }, [])


 

  const setarEmpresaAtiva = (emp) =>{
    setEmpresaAtiva(emp)
  }

  React.useEffect(()=>{
   /* processaTemporizadores();
    setInterval(() => {
      processaTemporizadores();
    }, (600000));

    return ()=>{
      clearInterval(timer);
    }*/

    setTimeout(function run() {
      processaTemporizadores();     
      setTimeout(run, 60000);
    }, 60000);


    
  },[]);






  const processaTemporizadores = async () =>{
  //  gerAlertasInterfaceIcones()
  }


  React.useEffect(()=>{
    if(empresaAtiva.idempresa){
      varUsuario = empresa.idusuario ? empresa.idusuario : -1;
      varEmpresa =empresaAtiva.idempresa ? empresaAtiva.idempresa : -1
    }
    //console.log('Empresa ativa : '+empresaAtiva.idempresa)
  }, [empresaAtiva.idempresa])

 

  const gerAlertasInterfaceIcones =  () =>{
    //console.log('Empresa ativa POST: '+empresaAtiva.idempresa)
      Conexao.get('interfaceController/gerAlertasInterfaceIcones?idUsuario='+varUsuario+'&idEmpresa='+varEmpresa).then(response=>{
        if(response.data.status == 'OK'){
          setAlertas(response.data.payload)
        }
      }).catch(error=>{

      });
  } 

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
    
      <MenuItem onClick={handleLogoff}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Mensagens</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Alertas</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Chamados</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Suprimentos</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar  elevation={0} variant=''>
        <Toolbar>
        
          <div style={{width: '180px'}}>
            <div>
            <Typography variant='body1' style={{color: '#fff', fontSize:'18px'}}>Datacount Admin</Typography>
            </div>
            <div>
            <Typography variant='caption'  style={{color: '#fff'}}>MULTIEMPRESA Versão 1.21</Typography>
            </div>
            
          </div>
          <div className={classes.search} style = {{display :"flex"}}>
          <SelecionaEmpresaAtiva idUsuario={empresa.idusuario} empresaAtiva={empresaAtiva.idempresa} setEmpresaAtiva = {(emp)=>{
            setarEmpresaAtiva(emp)
          }}/>
          
          <div style={{paddingLeft:"10px"}}>
            <div>
              <Tooltip title='Empresa ativa (Em uso)'>
                <Typography className={classes.title}  noWrap style={{fontSize:"12px", cursor:'help'}}>
                    Empresa : {empresaAtiva.idempresa+' '+empresaAtiva.razaosocial+ ' '} 
                </Typography>
              </Tooltip>
            </div>
            <div>
              <Tooltip title='Revenda licenciada'>
              <Typography className={classes.title} style={{fontSize:"10px", cursor:'help'}}  noWrap>
                    Revenda : {empresa.nome} 
                </Typography>
              </Tooltip>
            </div>
          </div>
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton aria-label="" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <AddShoppingCartIcon />
              </Badge>
            </IconButton>

            <IconButton aria-label="" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <BuildIcon />
              </Badge>
            </IconButton>

            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={0} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={alertas.alertasSuprimentos} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
