import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../../providers/Conexao'
import Chip from '@material-ui/core/Chip';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'

import {Breadcrumbs} from '@material-ui/core'
import {Link} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ComputerIcon from '@material-ui/icons/Computer';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';
import Template from '../../Template/Template';
const PrintLoggerLogs = (props)=>{
   
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [cliente, setEmpresa] = React.useState(props.location.state.cliente)
  
    const [impressoes, setImpressoes] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [registros, setRegistros]= React.useState(0);

    const refDataInicial = React.useRef();
    const refDataFinal = React.useRef();
    const refNomeFila = React.useRef();
    const refHostName = React.useRef();   
    const refUserName = React.useRef();
    const refNomeDocumento = React.useRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(()=>{
        getImpressoes()
       // console.log(props.location.state.empresa.idcliente)        
    },[]);

    useEffect(()=>{
        getImpressoes()
    },[page,rowsPerPage]);

    const getImpressoes = () =>{
        setLoading(true)
        Conexao.post('bilhetagemController/getListaImpressoes?idEmpresa='+cliente.idempresa+'&idCliente='+cliente.idcliente+'&limit='+rowsPerPage+'&offSet='+page, {
            idcliente: cliente.idcliente,
            idusuario: cliente.idusuario,
            idempresa: cliente.idempresa,            
            dataInicial: refDataInicial.current ? refDataInicial.current.value : '',
            dataFinal:  refDataFinal.current ? refDataFinal.current.value : '',
            nomeFila: refNomeFila.current ? refNomeFila.current.value : '',
            hostName: refHostName.current ? refHostName.current.value : '',
            userName: refUserName.current ? refUserName.current.value : '',
            nomeDocumento: refNomeDocumento.current ? refNomeDocumento.current.value : '',
           

        }).then(response=>{
            if(response.data.status =='OK'){
                setImpressoes(response.data.payload.dados);
                setRegistros(response.data.payload.registros);
                
            }else{
                setImpressoes([])
                setRegistros([]);
            }
            setLoading(false)
        }).catch(error=>{setLoading(false)});
    }


    const clearForm = () =>{
        if(refDataInicial.current){
            refDataInicial.current.value  = '';
        }
        if(refDataFinal.current){
            refDataFinal.current.value  = '';
        }
        if(refNomeFila.current){
            refNomeFila.current.value  ='';
        }
        if(refHostName.current){
            refHostName.current.value  ='';
        }
        if(refUserName.current){
            refUserName.current.value  ='';
        }
        if(refNomeDocumento.current){
            refNomeDocumento.current.value  ='';
        }
        getImpressoes()
        setPage(0)
               
    }

    return <Template atualizar = {loading} cliente={props.location.state.cliente} breadcrumbs ={
        <Breadcrumbs aria-label="breadcrumb">  
            <Typography>Você esta em:</Typography>          
                <Link color="inherit" to={'/webclient/home'} >
                    Home
                </Link>   
                <Typography>PRINTLOGGER - Logs de Impressões</Typography>       
            </Breadcrumbs>
    }>
         <div style={{paddingLeft : '40px', paddingRight : '40px', paddingTop : '60px'}}>
        <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>PRINTLOGGER - Logs de Impressões</Typography></Grid>            
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Typography variant={'caption'} style={{color: 'tomato', position:'absolute', marginTop: '-20px'}}>* Pesquisa sem data selecionada = últimos 90 dias</Typography>
        <Grid container spacing={2}>
          
            <Grid item xs={2}>
                <TextField
                    inputRef = {refDataInicial}
                    label='Data Incial'
                    variant='outlined'
                    fullWidth
                    size='small'
                    InputLabelProps={{ shrink: true }}
                    type='date'
                />
                
            </Grid>
            <Grid item xs={2}>
                <TextField
                    inputRef ={refDataFinal}
                    label='Data Final'
                    variant='outlined'
                    fullWidth
                    size='small'
                    InputLabelProps={{ shrink: true }}
                    type='date'


                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    inputRef = {refNomeFila}
                    label='Nome da Impressora'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                >
                   

                </TextField>
            </Grid>


            <Grid item xs = {3}>
                <TextField
                    inputRef = {refUserName}
                    label ='Nome do usuário'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}               
                    size ='small'
                    />                   
            </Grid>

            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>{getImpressoes()}} ><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained'  onClick={()=>{clearForm()}} ><ClearIcon/> Limpar</Button>
            </Grid>
            <Grid item xs = {6}>  
                <TextField
                    inputRef = {refNomeDocumento}
                    label ='Nome do Documento'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}               
                    size ='small'
                    />                   
            </Grid>
            <Grid item xs = {4}>
                <TextField
                    inputRef = {refHostName}
                    label ='Nome da Host'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}               
                    size ='small'
                    />                   
            </Grid>
        </Grid>


        <br/>
       
       <div className='div-form-container' >

        <Table className='table-small'  style = {{fontSize:"14px", color:"#444"}}>
            <TableHead>
                <TableCell align='center' style={{width:"10%", fontSize:"14px"}}>Data</TableCell>
                <TableCell style={{width:"15%", fontSize:"14px"}}>Impressora</TableCell>
                <TableCell style={{width:"10%", fontSize:"14px"}}>Usuário</TableCell>
                <TableCell  style={{width:"30%", fontSize:"14px"}}>Documento</TableCell>
                <TableCell  align='center' style={{width:"3%", fontSize:"14px"}}>Página</TableCell>
                <TableCell  align='center' style={{width:"3%", fontSize:"14px"}}>Cópia</TableCell>
                <TableCell  align='center' style={{width:"3%", fontSize:"14px"}}>Total</TableCell>
            </TableHead>
            <TableBody>
                {
                    impressoes && impressoes.map(function(impressao, i){
                        return <TableRow key = {i}>
                        <TableCell  align='center' style={{ fontSize:"14px"}}><Typography variant='caption' color={'primary'}>{impressao.dataimpressao+' '+impressao.horaimpressao}</Typography></TableCell>                        
                        
                        <TableCell style={{ fontSize:"12px"}}>
                        <Typography variant='caption' color={'primary'}>{impressao.hostname+ '\\' +impressao.nomefila}</Typography><br/>
                        <span style ={{fontSize:"10px"}}>{impressao.processador+' ('+impressao.datatype+')'}</span>                        
                        </TableCell>
                        
                        <TableCell style={{ fontSize:"14px"}}><Typography variant='caption' color={'primary'}>{impressao.username}</Typography></TableCell>
                       
                        <TableCell >
                            
                                    <Typography style={{fontSize:"14px"}} variant='caption'  color={'primary'} ><b>{impressao.nomedocumento.length  > 60 ? impressao.nomedocumento.substr(0,60) + "...":impressao.nomedocumento} </b></Typography><br />
                                 
                                    <span style={{ fontSize: "9px" }}>{'Cor : ' + impressao.cor + ' | ' + impressao.duplex}</span><br />
                                    <span style={{ fontSize: "9px" }}>{'Papel: ' + impressao.papel + ' | ' + impressao.orientacao}</span><br />
                                    <span style={{ fontSize: "9px" }}>{'Resolução: ' + impressao.resolucao}</span><br />
                                  
                        </TableCell>
                        <TableCell  align='center' style={{ fontSize:"14px"}}>{impressao.paginas}</TableCell>
                        <TableCell  align='center' style={{ fontSize:"14px"}}>{impressao.copias}</TableCell>
                        <TableCell  align='center' style={{ fontSize:"14px"}}>{impressao.total}</TableCell>
                    </TableRow >
                    })
                }
            </TableBody>
        </Table>


       </div>
       </div>
       </Template>

}


export default withRouter(PrintLoggerLogs)

