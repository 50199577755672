import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {Divider, TextField, Grid, Container, InputAdornment} from '@material-ui/core'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalNovoModelo = (props) => {
  const [open, setOpen] = React.useState(false)
  const [descricao, setDescricao] = React.useState('')
  const [marcas, setMarcas] = React.useState([])
  const [marca, setMarca] = React.useState([])
  const [colorido, setColorido] = React.useState('F')

  React.useEffect(()=>{
    if(open){
      //alert('teste')
        getMarcas();
        setDescricao('')

        //console.log('Editando modelo : ' + JSON.stringify(props.edicaoModelo))
        
    }else{
       // setMarca([])
    }
  }, [open])

  React.useEffect(()=>{
    if(props.edicaoModelo && props.edicaoModelo.idmodelo){
      setDescricao(props.edicaoModelo.descricao);
      setMarca(props.edicaoModelo)
      setColorido(props.edicaoModelo.colorido)
      getMarcas();
     
    }
  }, [props.edicaoModelo])




  const getMarcas = () =>{
        
    
    Conexao.post('MarcasController/getMarcas', {
        filtro : null, 
        idusuario : props.match.params.idempresa
    }).then(response =>{
        
        if(response.status == 200){
           
            if(response.data.status == 'OK'){
                setMarcas(response.data.payload);
                console.log(response.data.payload)
            }else{
                setMarcas([])
            }
        }else{
            setMarcas([])
        }
       
    }).catch(error =>{
       
    })
}



const insertModelos = () =>{
    if(!marca || !marca.idmarca){
        Swal.fire({
            title : 'Printerconnect', 
            icon : 'info',
            text : 'Você precisa selecionar uma marca', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        return false
    }
    if(!descricao && descricao == ''){
        Swal.fire({
            title : 'Printerconnect', 
            icon : 'info',
            text : 'Você precisa informar a descrição !', 
            showCancelButton : false, 
            showConfirmButton : false, 
            timer : 1500
        })
        return false
    }else{
      Swal.fire({
          title : 'Printerconnect', 
          text : 'Deseja Cadastrar/Atualizar o modelo agora ?',
          icon :'question', 
          showCancelButton : true,
          cancelButtonText : 'Cancelar', 
          showConfirmButton : true, 
          confirmButtonText:'Confirmar'
      }).then(responsta =>{
          if(responsta.isConfirmed){
              Conexao.post('modelosController/'+(props.edicaoModelo.idmodelo ? 'updateModelo' :'insertModelo'), {
                  idusuario : props.match.params.idempresa, 
                  idmarca :  marca.idmarca, 
                  idmodelo : props.edicaoModelo.idmodelo   ?  props.edicaoModelo.idmodelo : 0, 
                  descricao, 
                  colorido : colorido 
              }).then( response =>{
                  if(response.data.status =='OK'){
                          Swal.fire({
                          title : 'Printerconnect',
                          icon : 'success', 
                          text : 'Modelo cadastrada/atualizada com sucesso !', 
                          showCancelButton : false, 
                          showConfirmButton : false, 
                          timer : 1000
                          })
                  }else{
                      Swal.fire({
                          title : 'Printerconnect',
                          icon : 'warning', 
                          text : 'Não foi possível cadastrar/atualizar', 
                          showCancelButton : false, 
                          showConfirmButton : false, 
                          timer : 1000
                          })
                  }
                  props.updateModelos();
                  setOpen(false)
                  props.closeEdicao();
                  
              }).catch(error =>{
                  Swal.fire({
                      title : 'Printerconnect',
                      icon : 'error', 
                      text : 'Não foi possível cadastrar/atualizar', 
                      showCancelButton : false, 
                      showConfirmButton : false, 
                      timer : 1000
                      })
                      setOpen(false)
                      props.closeEdicao();
              })
          }
      })
    }
    
    
}
  return (
    <div>
        <div className='fabIncluir'>
        <Fab size="large" color="primary" aria-label="add" onClick={()=>setOpen(true)} >
          <AddIcon />
        </Fab>
      </div>
     
      <Dialog 
        disableBackdropClick disableEscapeKeyDown hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={()=>setOpen(false)} aria-labelledby="customized-dialog-title" open={open || props.open}>
        <DialogTitle id="customized-dialog-title" onClose={()=>{
            setOpen(false)
            props.closeEdicao();
        }}
            >
          Cadastro/Alteração de Modelos
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing= {2}>
                
                <Grid item xs = {12}>
                    <TextField
                        label = 'Marca'
                        variant='outlined'
                        fullWidth
                        select
                        SelectProps ={{native : true}}
                        InputLabelProps = {{shrink : true}}
                        onChange = {(e)=>{
                            setMarca(marcas[e.target.value])
                        }}
                        >
                            {
                                props.edicaoModelo && props.edicaoModelo.idmodelo && <option id = {'OPTM-1'} value = {props.edicaoModelo.idmarca}>{props.edicaoModelo.descricaomarca}</option>
                            }
                            <option id = {'OPTM-1'} value = {-1}></option>
                            {
                                marcas && marcas.map((marca, i)=>{
                                    return <option id = {'OPTM' + i} value = {i}>{marca.descricao}</option>
                                })
                            }
                    </TextField>
                </Grid>
            </Grid>

            <Grid container spacing= {2}>
                
                <Grid item xs = {12}>
                    <TextField
                        label = 'Descrição'
                        variant='outlined'
                        fullWidth
                        value ={descricao}
                        onChange={(e)=>setDescricao(e.target.value)}
                        InputLabelProps = {{shrink : true}}
                        />
                </Grid>
            </Grid>


            <Grid container spacing= {2}>
                
                <Grid item xs = {12}>
                    <TextField
                        label = 'Colorido?'
                        variant='outlined'
                        fullWidth
                        value ={colorido}
                        onChange={(e)=>setColorido(e.target.value)}
                        InputLabelProps = {{shrink : true}}
                        select
                        SelectProps={{native:true}}
                        >
                          <option value ='f'>NÃO</option>
                          <option value ='t'>SIM</option>
                    </TextField>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>{
              props.closeEdicao();
              setOpen(false)}} variant='contained' color="secondary">
            Cancelar
          </Button>

          <Button autoFocus onClick={()=>{
           insertModelos()
              
          }} variant='contained'  color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalNovoModelo)