import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/Print';
import {Grid, TextField, Table, TableBody, TableHead, TableRow, TableCell} from '@material-ui/core'
import SearchIcon  from '@material-ui/icons/Search'
import Conexao from '../../providers/Conexao';
import GroupAddIcon from '@material-ui/icons/GroupAdd';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalBuscaEquipamentoChamado(props) {
  const [open, setOpen] = React.useState(false);
  const [equipamentos, setEquipamentos]= React.useState([]);
  const refTipoFiltro = React.useRef();
  const refFiltro = React.useRef();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



  const getEquipamentosAberturaChamado = () =>{
    Conexao.post('chamadosTecnicosController/getEquipamentosAberturaChamado?idUsuario='+props.idusuario+'&idEmpresa='+props.idempresa, {
      tipoFiltro : refTipoFiltro.current ? refTipoFiltro.current.value : '', 
      filtro :  refFiltro.current ? refFiltro.current.value : '',
      idusuario : props.idusuario, 
      idempresa : props.idempresa 
    }).then(response =>{
      if(response.data.status =='OK'){
        setEquipamentos(response.data.payload)
      }else{
        setEquipamentos([])
      }
    }).catch(error=>{
      setEquipamentos([])
    });
  }

  return (
    <div>
      <PrintIcon color="primary" onClick={handleClickOpen} style ={{cursor:'pointer'}}/>
      <Dialog disableBackdropClick disableEscapeKeyDown hideBackdrop onClose={handleClose} fullWidth maxWidth='md' aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Printerconnect - Localizar equipamento
        </DialogTitle>
        <DialogContent dividers style = {{height:"500px", overflow:"auto", overflowX:"hidden"}}>
         <Grid container spacing={1}>
             <Grid item xs ={3}>
                 <TextField
                    label='Localizar por'
                    inputRef={refTipoFiltro}
                    select
                    SelectProps={{native: true}}
                    variant='outlined'
                    size='small'
                    InputLabelProps={{shrink : true}}
                    fullWidth>
                        <option value ='numeroSerie'>Número de Série</option>
                        <option value ='patrimonio'>Placa</option>                        
                        <option value ='modelo'>Modelo</option>
                        <option value ='codigoCliente'>Código do Cliente</option>
                        <option value ='razaoSocial'>Razão Social</option>
                    </TextField>
             </Grid>
             <Grid item xs ={7}>
                 <TextField
                    label='Filtro'
                    inputRef={refFiltro}
                    placeholder='Digite um parametro para pesquisa'
                    variant='outlined'
                    size='small'
                    InputLabelProps={{shrink : true}}
                    fullWidth/>

             </Grid>
            
             <Grid item xs ={2}>
                 <Button fullWidth variant='outlined' color='primary' onClick={()=>getEquipamentosAberturaChamado()}><SearchIcon/> Pesquisar</Button>
             </Grid>
         </Grid>
         <br/>
             <Table style ={{fontSize:"10px"}} className='table table-bordered table-striped table-hover'>
                 <TableHead>
                     <TableCell style ={{width:"10%"}} align='center'>Placa</TableCell>
                     <TableCell style ={{width:"20%"}} align='center'>Número Série</TableCell>
                     <TableCell style ={{width:"40%"}}>Modelo/Cliente</TableCell>
                     <TableCell style ={{width:"10%"}} align='center'>Selecionar</TableCell>
                 </TableHead>
                 <TableBody>
                   {
                     equipamentos && equipamentos.map(function(equipamento, i){
                       return <TableRow key ={i}>
                       <TableCell style ={{width:"10%"}} align='center'>{equipamento.codigoadicional}</TableCell>
                       <TableCell style ={{width:"20%"}} align='center'>{equipamento.numeroserie}</TableCell>
                       <TableCell style ={{width:"40%"}}>
                          <Typography color='primary' variant='caption'>{equipamento.modelo}</Typography><br/>
                          <Typography color='primary' variant='caption'>{equipamento.idcliente +' - '+  equipamento.razaosocial+' ('+equipamento.nossonumero+')'}</Typography><br/>
                        </TableCell>
                       <TableCell style ={{width:"10%"}} align='center'>
                          <Button variant='outlined' size='large' fullWidth onClick={()=>{
                            setOpen(false)
                            props.getEquipamento(equipamento)}}><GroupAddIcon/></Button>
                       </TableCell>
                   </TableRow>
                     })
                   }
                 </TableBody>
             </Table>
        </DialogContent>
        
      </Dialog>
    </div>
  );
}
