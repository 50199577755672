import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {Grid, Container, Table, Divider, Tooltip, Badge, Typography, useIsFocusVisible} from '@material-ui/core'
import './GestaoMultiempresa.css'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Conexao from '../../providers/Conexao'
import JanelaGestao from './JanelaGestao'
import Swal from 'sweetalert2'
import WindowIcon from '@material-ui/icons/Dashboard'
import AlertIcon from '@material-ui/icons/AddAlert'
import WarningIcon from '@material-ui/icons/Warning'
import CallIcon from '@material-ui/icons/AddIcCall'
import LogoPrinterconnect from '../../../src/pages/web-cliente/LandingPage/Componentes/logoPrinterConnect.png'
import CachedIcon from '@material-ui/icons/Autorenew';
import ModalGestaoJanelas from './ModalGestaoJanelas'
import NoDataIcon from '@material-ui/icons/ErrorOutlineOutlined'
import ModalMapa from './ModalMapa'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import IconGrid from '@material-ui/icons/FormatAlignCenter'
const GestaoMultiempresa = (props) =>{

    const chamadoTeste = {
        "tipoatendimento": 1,
        "descricaoatendimento": "ATENDIMENTO PADRAO",
        "horastotal": 24,
        "idbandeja": 4,
        "idchamadotecnico": 5,
        "situacao": 3,
        "tipocontrato": 1,
        "numeroserie": "ZDEJBQAJ7000NBY",
        "idcliente": 16190,
        "razaosocial": "JUNTA DE EDUCADA CONV. BATISTA SP",
        "idempresa": 3,
        "sequenciaatendimento": 1,
        "dataabertura": "2021-12-10",
        "horaabertura": "14:22:20.792158",
        "dataativacao": "2021-12-10",
        "horaativacao": "14:26:17.472471",
        "nivelsla": 0,  
        "marca": "SAMSUNG",
        "modelo": "SAMSUNG M332X 382X 402X SERIES",
        "horaspercorridas": 91
    };

    const[chamadoSelecionado, setChamadoSelecionado] = useState(null)
    const[janelas, setJanelas] = useState([])
    const [janelaOrigem, setJanelaOrigem] = useState(-1)
    const [janelaDestino, setJanelaDestino] = useState(-1)
    const [atualizarJanelas, setAtualizarJanelas] = useState(-1)
    const [question, setQuestion] = useState(false)
    const [loading, setLoading] = useState(false)
    const [params, setParams]= useState(null)
    const [backdrop, setBackdrop] = useState(false)
    const [chamadosGestaoFila, setChamadosGestaoFila] = useState([])
    const [janelasMover, setJanelasMover]= useState([])
    
    const [regioes, setRegioes] = useState([])
    const [tecnicos, setTecnicos] = useState([])

    
    


    const {idempresa, idusuario, latitude, longitude, razaosocial} = props.match.params;

    useEffect(()=>{
        
        
        const divChamadoMove = document.getElementById('divChamadoSelecionado')      

        document.addEventListener('mousemove', (e) => {
            
                if(e.pageX > window.innerWidth / 2){
                
                    divChamadoMove.setAttribute('style','top:'+(e.pageY + 10)+'px; left:'+ (e.pageX + -370)+'px;');
    
                }else{
    
                    divChamadoMove.setAttribute('style','top:'+(e.pageY + 10)+'px; left:'+ (e.pageX + 25)+'px;'); 
    
                }
              
                      
        });
        document.addEventListener("keyup", escFunction, false);
        
        getJanelas()
        getTecnicos()
        getRegioes();
    }, [])

    const getFullData = () =>{
        setLoading(true)
        /*setTimeout(()=>{
            window.location.reload();
        }, 500)*/
        getJanelas()
        getTecnicos()
        getRegioes();
        
    }



    const escFunction = () =>{

        setChamadoSelecionado(null)
        setJanelaDestino(-1)
        setJanelaDestino(-1)
        setAtualizarJanelas(false)

        document.getElementById('gestao-chamados-container').classList.remove('pointer')
    }



    const getJanelas = async () =>{
        
        setLoading(true)

        Conexao.get('chamadosTecnicosController/getJanelasTelaGestao?idusuario='+idusuario+'&idempresa='+idempresa).then(response=>{

            if(response.data.status =='OK'){
                setJanelas(response.data.payload)
            }else{
                setJanelas([])
            }

            setLoading(false)

        }).catch(error=>{
            setJanelas([])
            setLoading(false)
        })

    }

   


    const renderProgressBar = (nivel) =>{
        let cor = '#0f0';
        if(nivel > 75){
            cor = '#0f0';
        }else if( nivel <75 && nivel >= 50){
            cor = '#f50';
        }else if(nivel <50 && nivel >= 25){
            cor = 'orange';
        }else{
            cor = '#f00';
        }
        return <div className='progress-container-main'>
            <div style ={{width : nivel+'%', height:"100%", backgroundColor:cor}}></div>
        </div>
    }

    const renderIconeStatus = (status) =>{
        switch(status){
            case 3: return <AccessTimeIcon/>
            case 10: return <HourglassEmptyIcon/>
        }
    }

    const setMoverConteudo = (idJanela) =>{         
        setQuestion(true)       
    }


    const getTecnicos = async() =>{

        Conexao.get('funcionariosController/getComboFuncionarios?idUsuario='+idusuario+'&idEmpresa='+idempresa).then(response=>{

            if(response.data.status =='OK'){
                setTecnicos(response.data.payload)
            }else{
                setTecnicos([])
            }


        }).catch(error=>{
            setTecnicos([])
          
        })
        
    }

    const getRegioes = async() =>{

        Conexao.get('empresasController/getComboRegioes?idusuario='+idusuario+'&idempresa='+idempresa).then(response=>{

            if(response.data.status =='OK'){
                setRegioes(response.data.payload)
            }else{
                setRegioes([])
            }


        }).catch(error=>{
            setRegioes([])
          
        })
        
    }

    const handleRLDDChange =(reorderedItems) =>{  
        setJanelasMover(reorderedItems) 
     //   setUpdatedFila(true)
      }

    const moverJanelas = () =>{
        const temp = janelas.map(function(janela, i){
            return {...janela, id: i}
        })
        setJanelasMover(temp)
    }


    return <div style ={{minHeight : '100vh', backgroundColor:'#000'}} className='gestao-chamado-main'>
            <div className= 'gestao-chamado-nav'>
                <div style = {{width:'15%', height:'100%', padding:'5px', paddingLeft:'20px'}}>
                    <img src={LogoPrinterconnect} width={170} style ={{marginTop :'-15px'}}/>
                </div>
                <div style = {{width:'67%', height:'100%', padding:'1px', textAlign:'center'}}>
                    <Typography style = {{color:'#fff'}}>{razaosocial}</Typography>
                </div>
                <div style = {{width:'15%', height:'100%', padding:'5px', paddingRight : '10px',  display:'flex', color:'#fff', justifyContent:'space-evenly'}}>
                    {chamadoSelecionado && chamadoSelecionado.situacao == 4 &&  <Tooltip title='Solte seu chamado aqui para excluir!'><DeleteIcon  style ={{cursor:'pointer',}} color='secondary' onClick={()=>{
                        setQuestion(true)
                        Swal.fire({
                            title: 'Printerconnect',
                            text : `Deseja ecluir definitivamente o chamado ${chamadoSelecionado.idchamadotecnico+'/'+chamadoSelecionado.sequenciaatendimento}?`, 
                            icon : 'question', 
                            showConfirmButton : true, 
                            confirmButtonText : 'Sim', 
                            showCancelButton : true, 
                            cancelButtonText : 'Não'
                        }).then(resposta=>{
                            if(resposta.isConfirmed){
                                Conexao.post('chamadosTecnicosController/cancelarAtendimento', {
                                    idusuario:idusuario, 
                                    idempresa:chamadoSelecionado.idempresa, 
                                    idchamadotecnico : chamadoSelecionado.idchamadotecnico
                                }).then(response=>{
                                    if(response.data.status =='OK'){
                                      setJanelaOrigem(chamadoSelecionado.idbandeja)
                                      setAtualizarJanelas(chamadoSelecionado.idbandeja)  
                                      
                                    }
                                }).catch(error=>{

                                });
                            }
                            setQuestion(false)
                        })
                    }}/></Tooltip>}
                    <Tooltip title='Quantidade de chamados aguardando orçamento'><Badge color ='secondary' badgeContent={0}><CallIcon/></Badge></Tooltip>
                    <Tooltip title='Quantidade de chamados solicitando retorno'><Badge color ='secondary' badgeContent={0}><WarningIcon/></Badge></Tooltip>
                    <Tooltip title=''><Badge color ='secondary' badgeContent={0}><AlertIcon/></Badge></Tooltip>
                    <Tooltip title='Organizar posição das janelas'><IconGrid onClick={()=>moverJanelas()} style ={{cursor:'pointer'}}/></Tooltip>
                    <ModalGestaoJanelas idusuario={idusuario} idempresa={idempresa} updateJanelas ={()=>getFullData()}/>
                    
                    
                </div>
            </div>
            <div style ={{display:janelasMover && janelasMover.length > 0 ? 'flex': '', backgroundColor:'#000', marginTop:'10px'}}>
            {janelasMover && janelasMover.length > 0 && <div style ={{width:'500px', marginTop:'49px', backgroundColor:'#000',}}>
                <div style ={{width:'100%', backgroundColor:'#444', color:'#fff', fontSize:'12px', textAlign:'center', height:'20px'}}>ORGANIZAÇÃO DE BANDEJAS</div>
                <div style={{display:'flex', justifyContent:'space-evenly', paddingTop:'5px', paddingBottom:'5px', paddingLeft:'5px', paddingRight:'5px'}}>
                    <button className='btn btn-danger' style ={{width:'48%', border:'none!important'}} onClick={()=>{
                        Swal.fire({
                            title : 'Printerconnect', 
                            text : 'Deseja cancelar a operação!', 
                            icon : 'question', 
                            showCancelButton : true, 
                            cancelButtonText :'Não', 
                            showConfirmButton : true, 
                            confirmButtonText : 'Sim'
                        }).then(resposta=>{
                            if(resposta.isConfirmed){
                                setJanelasMover([])
                            }
                        })
                    }}>Cancelar</button>
                    <button className='btn btn-success' style ={{width:'48%', border:'none!important'}} onClick={()=>{
                        Swal.fire({
                            title : 'Printerconnect', 
                            text : 'Deseja gravar a nova sequencia das janelas?', 
                            icon : 'question', 
                            showCancelButton : true, 
                            cancelButtonText :'Não', 
                            showConfirmButton : true, 
                            confirmButtonText : 'Sim'
                        }).then(resposta=>{
                            if(resposta.isConfirmed){
                                setLoading(true)
                                Conexao.post('chamadosTecnicosController/updateSequenciaJanelas', {
                                    idusuario: idusuario, 
                                    idempresa:idempresa, 
                                    bandejas : janelasMover.map(item=>{
                                        return item.idbandeja
                                    })
                                }).then(response=>{
                                    if(response.data.status =='OK'){
                                        window.location.reload();
                                    }
                                }).catch(error=>{
                                    Swal.fire({
                                        title : 'Printerconenct', 
                                        text :'Não foi possível atualizar', 
                                        icon : 'error', 
                                        showConfirmButton : false, 
                                        showCancelButton : false, 
                                        timer : 1500
                                    })
                                    setLoading(false)
                                })

                               
                            }
                        })
                    }}>Gravar</button>
                </div>
                <div style ={{width:'100%', height :'100%', backgroundColor:'#000', padding : '10px'}}>
                {janelasMover && <RLDD                    
                    onChange={handleRLDDChange}
                    items={janelasMover}
                    itemRenderer={(item, index) => {
                        return (
                            <div style={{fontSize :'12px', marginTop:'5px', borderRadius:'5px', backgroundColor:item.backgroundcolor ? item.backgroundcolor : '#000', color:item.fontcolor? item.fontcolor : '#fff',  padding:'5px', cursor:'pointer'}}>
                                {(index+1) + ' - '+item.nomerecipiente}</div>
                            );
                        }}                        
                    />}
                </div>
            </div>}
            <div id='gestao-chamados-container' style={{backgroundColor : '#000',}}>
                {
                    janelas && janelas.map(function(janela, i){
                        return <JanelaGestao 
                                    janelaOrigem={janelaOrigem} 
                                    setJanelaOrigem = {(id)=>{setJanelaOrigem(id)}}
                                    atualizarJanelas={atualizarJanelas}
                                    setAtualizarJanelas={(id)=>setAtualizarJanelas(id)}
                                    resetMovimentacao = {()=>{escFunction()}}
                                    janelaDestino={janelaDestino}
                                    setJanelaDestino = {(id)=>{
                                        setMoverConteudo(id)
                                    }}       
                                    setQuestion={(op)=>setQuestion(op)}                             
                                    data={janela}                                  
                                    idusuario={idusuario}                                                                        
                                    movendoChamado={chamadoSelecionado != null}  
                                    chamadoSelecionado={chamadoSelecionado}                                  
                                    setChamadoMover={(chamado)=>{
                                        setChamadoSelecionado(chamado)
                                        setJanelaOrigem(chamado.idbandeja)
                                    }}
                                    setLoading = {(opcao)=>{
                                        setTimeout(()=>{
                                            setLoading(opcao)
                                        },250)
                                    }}
                                    tecnicos = {tecnicos}
                                    regioes={regioes}
                                    indice = {i} />
                    })
                } 
                {
                    janelas.length == 0 && <div className='div-sem-info'>
                        
                                * Não há bandeja configuradas para exibição * 
                        
                        
                        </div>
                }
            </div>
            
            </div>
            
            <div className={chamadoSelecionado && ! question  ?  'divChamadoSelecionado' : 'divOculta'} id='divChamadoSelecionado'>
                <div className='chamado-linha-flex'>
                    <div className='linha-flex-chamado' style ={{paddingTop:'3px'}}>{chamadoSelecionado && chamadoSelecionado.idchamadotecnico+'/'+chamadoSelecionado.sequenciaatendimento}</div>
                    <div className='linha-flex-data' style ={{paddingTop:'3px'}}>{chamadoSelecionado && chamadoSelecionado.dataabertura}</div>
                    <div className='linha-flex-empresa texto-limitado' style ={{paddingTop:'3px'}}>{chamadoSelecionado && chamadoSelecionado.razaosocial}</div>
                    <div className='linha-flex-progress' style ={{paddingTop:'3px'}}>{renderProgressBar(chamadoSelecionado && chamadoSelecionado.nivelsla ? chamadoSelecionado.nivelsla :0 )}</div>
                    <div className='linha-flex-icone'>{renderIconeStatus(chamadoSelecionado && chamadoSelecionado.situacao ? chamadoSelecionado.situacao: 3)}</div>
                    
                </div>
                
            </div>

                
            <div className='fabIncluir'>
                <ModalMapa 
                    title='Visão Geográfica do Atendimento' 
                    idusuario={idusuario} 
                    idempresa={idempresa} 
                    latitude ={latitude} 
                    longitude ={longitude}
                    movendoChamado={chamadoSelecionado} 
                   />
            </div>
           

            <Backdrop  open={loading} onClick={null} style ={{zIndex:"9999998"}}>
                    <CircularProgress color="secondary" />
            </Backdrop>
        </div>
}


export default GestaoMultiempresa