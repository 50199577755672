import React, {useState, useEffect, Fragment} from 'react'
import { Typography, Divider } from '@material-ui/core'





export default function(props){
    const {veiculo} = props

    return <div className='div-avatar-veicle'>
        <div>
            <Typography variant='subtitle1'>{veiculo.marca+' '+veiculo.modelo}</Typography>
            <Divider/>
            <Typography variant='caption'>Placa: {veiculo.placa}</Typography><br/>
            <Typography variant='caption'>Imei: {veiculo.idrastreador}</Typography><br/>
        </div>
    </div>
}