import React from 'react'
import {Map, Circle, TileLayer,withLeaflet, Marker, Popup, Tooltip, ZoomControl, Polygon, Polyline  } from "react-leaflet";
import MeasureControlDefault from 'react-leaflet-measure'; //https://www.npmjs.com/package/react-leaflet-measure
import L from 'leaflet';
import "leaflet/dist/leaflet.css";//https://towardsdatascience.com/creating-a-bubbles-map-using-react-leaflet-e75124ca1cd2
import { point } from 'leaflet';
import Principal from '../../pages/Principal/Principal'

export default function (){

    const position = [51.505, -0.09]
    const [posicaoInicio, setPosicaoInicio] = React.useState(null);
    const MeasureControl = withLeaflet(MeasureControlDefault);




    const handlePositionLine = (posicao)=>{
      console.log(posicao.lat)
    // setPosicaoInicio([posicao.lat, posicao.lng])
    
    }

    const measureOptions = {
      position: 'topright',
      primaryLengthUnit: 'meters',
      secondaryLengthUnit: 'kilometers',
      primaryAreaUnit: 'sqmeters',
      secondaryAreaUnit: 'acres',
      activeColor: '#db4a29',
      completedColor: '#9b2d14'
    };

    return <Principal full={true} navOculta={true}>
      <div style ={{height: "78vh", marginTop:"10px", width: "100%", }}>
        <Map
          id="modalMap"                        
          animate={true}
          style={{ height: "100%", width: "100%", }}
          minZoom={5}
          zoom={6}                        
          zoomControl={true}                                                        
          center={[-22.2244322,-49.8917842]}    
          onclick={(e)=>{
           handlePositionLine(e.latlng)
           //console.log(e)
          }}    
          >
            <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <MeasureControl
              {...measureOptions}
              onMeasurefinish={(e)=>{
                console.log(e)
              }} />
            <Circle
              fillColor="#c62828"
              stroke ={false}
              onclick = {(e)=>{
                console.log(e)
              }}
              center = {[-22.2244322,-49.8939729]}
              radius = {70000}
              />
             {posicaoInicio && <Marker posicao={posicaoInicio}/> }
            
          </Map>
        </div>
    </Principal>
}