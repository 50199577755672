import React , {useState, useEffect, useRef, Fragment} from 'react'
import Image from 'react-bootstrap/Image'
import {Typography, Divider, Button} from '@material-ui/core'
import Swal from 'sweetalert2'
import {Tooltip} from '@material-ui/core'
import { LogoNodejs, SpeedometerOutline, FlashOutline, FlashOffOutline, SettingsOutline, KeyOutline , BatteryChargingOutline as CarregandoBateria, BatteryFullOutline as BateriaFull, BatteryHalfOutline as BateriaMeio,BatteryDeadOutline as BateriaZero
} from 'react-ionicons'

export default function(props){

    const [ativo, setAtivo] = React.useState(false)

    return <div className={props.chaveTrajeto == props.trajeto.chavetrajeto || props.chaveTrajeto =='ALL'  ? 'card-selecao card-ponto' : 'card-ponto'} style ={{
        marginTop : '10px',
        padding : '10px',
        borderRadius : '5px', 
        backgroundColor : '#fff', 
        boxShadow : '0px 0px 20px #e5e5e5', 
        display : 'flex'
    }}>
        
            <div style ={{width:'15%',textAlign:'center'}}>
                <div style ={{fontSize:'10px', width:'100%', textAlign:'center',color:'green',   padding :'2px', paddingLeft :'5px', paddingRight :'5px', borderRadius:'5px'}}>{props.trajeto.dataignicaoon}</div>
                <img 
                style ={{
                    width : '64px', 
                    height : '64px', 
                    borderRadius :'32px'
                }}
                src ={props.trajeto.url ? props.trajeto.url : 'https://icones.pro/wp-content/uploads/2021/03/avatar-de-personne-icone-homme.png' }/>
                <div style ={{fontSize:'10px', width:'100%', textAlign:'center', color:'red',   padding :'2px', paddingLeft :'5px', paddingRight :'5px', borderRadius:'5px'}}>{props.trajeto.dataignicaooff}</div>
            </div>
            <div style ={{width:'65%'}}>
                <div>
                    <Typography variant='subtitle2'><strong>{props.trajeto.placa ? 'Veículo' : 'Motorista'}: {props.trajeto.motorista ? props.trajeto.motorista : 'Desconhecido'}</strong></Typography>
                </div>
                
                <div>
                    <Tooltip title ='Horario da ativação da ignição'>
                        <Typography variant='caption' style={{color:'green',   padding :'2px', paddingLeft :'5px', paddingRight :'5px', borderRadius:'5px'}}>
                        <FlashOutline   color={'green'}                  
                            height="18px"
                            width="18px"/> 
                      {props.trajeto.horaignicaoon.substr(0,8)}</Typography>
                    </Tooltip> -
                    <Tooltip  title ='Horario da desativação da ignição'> 
                        <Typography variant='caption' style={{color:'red',   padding :'2px', paddingLeft :'5px', paddingRight :'5px', borderRadius:'5px'}}>
                            <FlashOffOutline   color={'red' }                  
                                height="18px"
                                width="18px"/>
                            {props.trajeto.horaignicaooff.substr(0,8)}</Typography>
                    </Tooltip>
                </div>
               
                <div>
                    <Typography variant='caption'>Distância percorrida: {props.trajeto.kilometragemfinal - props.trajeto.kilometrageminicial+' km(s).' }</Typography>
                </div>
                
                
            </div>
            <div style ={{width:'20%', display:'flex', alignContent:'center', alignItems:'center'}}>
                <Button variant='outlined' color='success'  onClick={()=>{
                        Swal.fire({
                            title :'Printerconnect - Frota',
                            text : 'Deseja mostrar este trajeto agora?',
                            icon :'question', 
                            showConfirmButton : true, 
                            confirmButtonText : 'Sim', 
                            showCancelButton:true, 
                            cancelButtonText : 'Não'
                        }).then(responsta=>{
                            if(responsta.isConfirmed){
                                props.setTrajeto(props.trajeto.pontos);
                            }
                        })
                    }}>mostrar</Button>
            </div>
        
    </div>
}