import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ModalBuscaCliente from '../Clientes/ModalBuscaCliente'

const ContadoresMensais = (props)=>{
    const [loading, setLoading] = useState(false)
    const [cliente, setCliente] = useState({razaosocial:'', idcliente:''})
    const refAno = useRef();
    const refMes = useRef();


    return <Principal atualizar = {loading} >
        <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Consulta Contadores Mensais</Typography></Grid>
            <Grid item xs = {5}>
            
            </Grid>
        </Grid>
        <br/>
        <Grid container spacing = {2}>
            <Grid item xs = {2}>
                <TextField
                    label ='Ano'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    type = 'number'
                    inputRef={refAno}
                    />
            </Grid>
            <Grid item xs = {1}>
                <TextField
                    label ='Mês'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    type = 'number'
                    inputRef={refMes}
                    />
            </Grid>
            <Grid item xs = {7}>
                <TextField
                    label ='Cliente'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    value={cliente.razaosocial}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ModalBuscaCliente updateCliente ={(cliente)=>setCliente(cliente)}/>
                          </InputAdornment>
                        ),
                      }}          
                   />
            </Grid>
            
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' ><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' onCLick={()=>{
                    setCliente({razaosocial:'', idcliente:''})
                }} ><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Contadores mensais</Typography>
            </Breadcrumbs>
        </div>
       <div className='div-form-container'>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
           
        </Grid>   
        </div>                  
    

    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(ContadoresMensais)