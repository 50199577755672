import { getToken } from "./Autenticate";

var axios = require('axios');



const axiosInstance = axios.create({
  baseURL:  'https://nominatim.openstreetmap.org/',
  timeout: 30000,
  headers: {    
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*',     
  }
  
});

export default axiosInstance;
