import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../../providers/Conexao'
import Chip from '@material-ui/core/Chip';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ComputerIcon from '@material-ui/icons/Computer';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';

const Computadores = (props)=>{
   
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [empresa, setEmpresa] = React.useState(props.location.state.empresa)
  
    const [computadores, setComputadores] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [registros, setRegistros]= React.useState(0);

    const refDataInicial = React.useRef();
    const refDataFinal = React.useRef();
    const refNomeFila = React.useRef();
    const refHostName = React.useRef();   
    const refUserName = React.useRef();
    const refNomeDocumento = React.useRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(()=>{
        getComputadores()
     // console.log(computadores.chaveempresa)
        
    },[]);
   

    const getComputadores = () =>{
        setLoading(true)
        Conexao.post('computadoresController/getComputadores',{
            idcliente: empresa.idcliente,
            idusuario: empresa.idusuario,
            idempresa: empresa.idempresa,         
           
           

        }).then(response=>{
            if(response.data.status =='OK'){
              // console.log(response.data.payload)
                setComputadores(response.data.payload); 
            }else{
                setComputadores([])
              
            }
            setLoading(false)
        }).catch(error=>{setLoading(false)});
    }

    return <Principal atualizar = {loading}>
        <br />
        <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Controle de Computadores</Typography></Grid>            
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
       
        <Grid container spacing={2}>
          
            <Grid item xs={5}>
                <TextField
                    label='Nome do Computador'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                >
                </TextField>
            </Grid>

            <Grid item xs = {5}>
                <TextField
                    label ='Domínio'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}               
                    size ='small'
                    />                   
            </Grid>

            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' ><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained'  ><ClearIcon/> Limpar</Button>
            </Grid>
            
        </Grid>


        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={'grafico-coleta-clientes/revenda/'+empresa.idusuario+'/empresa/'+empresa.idempresa+'/cliente/'+empresa.idcliente} >
                    Home
                </Link>
            <Typography color="textPrimary">Controle de Computadores</Typography>
            </Breadcrumbs>
        </div>
       <div className='div-form-container'>

        <Table className='table-small' style = {{fontSize:"14px", color:"#444"}}>
            <TableHead>
                <TableCell style={{width:"25%", fontSize:"14px"}}>Nome do Computador</TableCell>
                <TableCell align='center' style={{width:"15%", fontSize:"14px"}}>Domínio</TableCell>
                <TableCell align='center' style={{width:"20%", fontSize:"14px"}}>Endereço</TableCell>
                <TableCell align='center' style={{width:"20%", fontSize:"14px"}}>Sistema Operacional</TableCell>
                <TableCell  align='center' style={{width:"15%", fontSize:"14px"}}>Plataforma do S.O</TableCell>             
            </TableHead>
                <TableBody>
                    {computadores && computadores.map(function (computador, i) {
                        return <TableRow key={i}>
                            <TableCell style={{ fontSize: "14px" }}><Typography variant='caption' color={'primary'}>{computador.hostname}</Typography></TableCell>

                            <TableCell align='center' style={{ fontSize: "14px" }}>
                                <Typography variant='caption' color={'primary'}>{computador.dominio}</Typography><br />
                            </TableCell>
                            <TableCell align='center' style={{ fontSize: "14px" }}><Typography variant='caption' color={'primary'}>{computador.enderecoip}</Typography></TableCell>
                            <TableCell align='center' style={{ fontSize: "14px" }}><Typography variant='caption' color={'primary'}>{computador.sistemaoperacional}</Typography><br />                            </TableCell>
                            <TableCell align='center' style={{ fontSize: "14px" }}>{computador.plataforma}</TableCell>
                        </TableRow >
                    })
                    }
                </TableBody>
        </Table>


       </div>
       </Principal>

}

export default withRouter(Computadores)

