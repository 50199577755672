import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField, InputAdornment} from '@material-ui/core'
import Swal from 'sweetalert2'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';




function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/,/\d/,'.', /\d/, /\d/, /\d/, '.',/\d/, /\d/, /\d/,'-', /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }

  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };


const TextFieldCNPJ = (props) =>{
    const [error, setError] = useState(false)
    


    const validarCampo = (val) =>{
        if(val == ''){
            return true;
        }
        val = val.replace(/[^\d]+/g,'');
        if(val.length == 11){
            return validarCPF(val)
        }else if(val.length > 11){
            return validarCNPJ(val)
        }else{
            return false;
        }
    }
    const  validarCPF = (strCPF) =>{
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF == "00000000000") return false;

        for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

        Soma = 0;
        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
        return true;
    }


    const validarCNPJ = (cnpj) =>{
        cnpj = cnpj.replace(/[^\d]+/g,'');
 
   
     
    if (cnpj.length != 14)
    
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
       
        return false;
         
        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0,tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
       
            return false;
            
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
          
            return false;
          
        return true;
        }

   
    return <TextField 
            {...props} 
            InputProps={{
             inputComponent : TextMaskCustom    
            }}                              
            helperText={error || props.error ? 'CPF Inválido':''} error={error || props.error}  onBlur = {(e)=> {
            if(props.validate == false){
                return true;
            }
            if(!validarCampo(e.target.value)){
                setError(true)
                Swal.fire({
                        title: 'Ops!',
                        text: 'O CPF informado é inválido !',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
            }else{
                setError(false)
            }
           
            }
            
        }/>
}

export default TextFieldCNPJ;
