import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Fab from '@material-ui/core/Fab';
import RouteIcon from '@material-ui/icons/Directions'
import DivAvatarVeiculo from './CardAvatarVeiculo'
import { Typography,TextField, InputAdornment , CircularProgress} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import IconeVelocidade from './IconeVelocidade'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {Tabs, Tab} from 'react-bootstrap'
import Moment from 'react-moment';
import 'moment-timezone';
import CardPosicoes from './CardPosicaoVeiculo'
import Swal from 'sweetalert2'





const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const {veiculo, posicoesTrajeto} = props;
  const [chaveTrajeto, setChaveTrajeto] = React.useState('');
  const toggleDrawer = () =>{
    setState(false)
    diferencaHoras();
  }

  React.useEffect(()=>{
    if(state){
     // props.setTrajeto([])
    }
  },[state])
  

  const diferencaHoras = () =>{
   
  }
 
  return (
    <div>
      <React.Fragment key={'1'}>
        <div className='fabIncluir' style ={{zIndex:'999999'}}>
         {props.veiculo && props.veiculo.placa != '' && !state && <Fab onClick={()=>setState(true)}>
            <RouteIcon size={48}/>
          </Fab>}
        </div>
          
          <Drawer            
            anchor={'right'} 
            hideBackdrop={false}  
            open={state}  
            variant="temporary" 
            type='paperAnchorDockedRight' 
            onClose={()=>{
              toggleDrawer()
            }}>
           
            <div style ={{width:'600px', padding:'20px', paddingTop:'10px', overflowX:'hidden' }}>
                <DivAvatarVeiculo veiculo = {props.veiculo}/>
                <br/>
            
                <Button disabled={props.loading} variant='outlined' color='primary' size='large' fullWidth onClick={()=>{
                  Swal.fire({
                    title : 'Printerconnect - Frota', 
                    text : 'Deseja realmente buscar todo histórico de posições do veículo selecionado?', 
                    icon:'question',
                    showConfirmButton : true, 
                    showCancelButton : true, 
                    confirmButtonText:'Sim', 
                    cancelButtonText : 'Não'
                  }).then(resp=>{
                    if(resp.isConfirmed){
                      props.listarPontosTrajeto()
                      setChaveTrajeto('ALL')
                    }
                  })
                }}>
                  Visualizar todo trajeto do dia
                </Button>
               
                   
                <br/>
                <br/>
                <br/>
                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                  <Tab eventKey={1} title="Trajeto Percorrido">
                  <br/>
                  <TextField 
                      fullWidth 
                      variant='outlined' 
                      placeholder='Digite o nome do motorista'
                      InputProps ={{
                        startAdornment : <InputAdornment position='start'>
                          <SearchIcon/>
                        </InputAdornment>
                      }}
                      className='input-blank'/>
                      <div style ={{width : '100%', padding:'10px', paddingTop : '20px', }}>
                      {
                              posicoesTrajeto && posicoesTrajeto.map(function(trajeto, i){
                                return <CardPosicoes chaveTrajeto={chaveTrajeto} setTrajeto={(data)=>{
                                    if(!props.loading){
                                      props.setTrajeto(data)
                                      setChaveTrajeto(trajeto.chavetrajeto)
                                    }                                                                      
                                  }} trajeto={trajeto}/> 
                              })
                            }
                      </div>
                  </Tab>
                  <Tab eventKey={2} title="Eventos">
                 
                  </Tab>
                  
                </Tabs>
                
            </div> 
          </Drawer>
        </React.Fragment>
    </div>
  );
}