import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableCell} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import TrocarSenha from './Components/ModalTrocaSenhaEmpresa'
var serialize = require('form-serialize');

const MeusDados = function(props){
    const [loadingCep, seLoadingCep] = useState(false)
    const [loading, setLoading]= useState(false)
    const [idempresa, setIdEmpresa] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [inscEstadual, setInscEstadual] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')
    const [endereco, setEndereco] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [cep, setCep] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade, setCidade] = useState('')
    const [estado, setEstado]  = useState('')
    const [telefoneComercial, setTelefoneComercial] = useState('')
    const [telefoneCelular, setTelefoneCelular] = useState('')
    const [email, setEmail] = useState('')



    useEffect(()=>{
        clearForm()
        getData();
    }, []);

    const getData = () =>{
        setLoading(true)
        Conexao.get('usuariosController/getEmpresa?idempresa='+props.location.state.empresa.idrevenda).then( response =>{
            if(response.data){
                setIdEmpresa(response.data.idusuario)
                setCnpj(response.data.cpf_cnpj)
                setInscEstadual(response.data.rg_insc)
                setRazaoSocial(response.data.nome)
                setCep(response.data.cep)
                setEndereco(response.data.logradouro)
                setNumero(response.data.numero)
                setComplemento(response.data.complemento)
                setBairro(response.data.bairro)
                setCidade(response.data.cidade)
                setEstado(response.data.estado)
                setTelefoneComercial(response.data.telefonecomercial)
                setTelefoneCelular(response.data.telefonecelular)
                setEmail(response.data.email)
            }else{
                clearForm()
            }
            
            setLoading(false)
        }).catch( error =>{
            clearForm();
            setLoading(false)
        });
    }

    const clearForm = () =>{
        setIdEmpresa('')
        setCnpj('')
        setInscEstadual('')
        setRazaoSocial('')
        setEndereco('')
        setComplemento('')
        setBairro('')
        setCidade('')
        setEstado('')
        setCep('')
        setTelefoneComercial('')
        setTelefoneCelular('')
        setEmail('')
    }

    const updataForm = ()=>{
        let form = document.querySelector('#form-meusDados');
 
        Conexao.post('usuariosController/updataData', {
            idempresa, 
            cnpj, 
            inscEstadual, 
            razaoSocial, 
            cep, 
            endereco, 
            numero, 
            complemento, 
            bairro, 
            cidade, 
            estado, 
            telefoneComercial, 
            telefoneCelular, 
            email
        }).then(response =>{
            if(response.status == 201){
                Swal.fire({
                    icon: 'success',
                    text: 'Informações salvas com sucesso !',
                    showConfirmButton: false,
                    timer: 1500, 
                    willClose : ()=>{
                        props.history.push('/home/empresa/'+props.location.state.empresa.idrevenda)
                    }
                  })
            }else{
                Swal.fire({
                    icon: 'warning',
                    text: 'Não foi possível gravas alterações',
                    showConfirmButton: false,
                    timer: 1500,
                    
                  })
            }
          
        }).catch(error =>{

        })
    }
    return <Principal atualizar = {loading} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Daddos da Empresa (Meus Dados)
            </Link>                
        </Breadcrumbs>
    }>
    
    
    <form id='form-meusDados' className='janela-elevation'>
        <Grid container style = {{marginTop:"10px"}} spacing={1}>
            <Grid item xs = {1}>
                <TextField
                    label ='Código'
                    name='idempresa'
                    id='idempresa'
                    fullWidth
            
                    variant='outlined'
                    inputProps = {{readOnly:true}}
                    InputLabelProps = {{shrink : true}}
                    value = {idempresa}
                    
                    />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='CNPJ * '
                    name='cnpj'
                    id='cnpj'
                    fullWidth
                    name='cnpj'
                    variant='outlined'
                    inputProps = {{readOnly:true}}
                    value = {cnpj}
                    onChange = {(e)=>setCnpj(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Insc. Estadual'
                    fullWidth
                    name='inscEstadual'
                    id = 'inscEstadual'
                    variant='outlined'
                    value = {inscEstadual}
                    onChange = {(e)=>setInscEstadual(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    />
            </Grid>

            <Grid item xs = {7}>
                <TextField
                    label ='Razão Social *'
                    name='razaoSocial'
                    id='razaoSocial'
                    variant='outlined'
                    value = {razaoSocial}
                    onChange = {(e)=>setRazaoSocial(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    fullWidth
                    />
            </Grid>
        </Grid>
        <Grid container spacing={1} style = {{marginTop:"20px"}}>
            <Grid item xs = {2}>
                <TextField
                    label ='CEP'
                    name='cep'
                    id='cep'
                    value = {cep}
                    onChange = {(e)=>setCep(e.target.value)}
                    variant='outlined'
                    fullWidth
                    
                    InputLabelProps = {{shrink : true}}
                    InputProps = {{
                        startAdornment : (
                            <InputAdornment position="end">
                                    {loadingCep && <CircularProgress size={24} variant="indeterminate" color='secondary'/>}
                                </InputAdornment>
                            ),
                    }}
                    onKeyUp = {(e)=>{
                        
                        if(e.keyCode == 13){
                            seLoadingCep(true)
                            ConexaoB.get('http://viacep.com.br/ws/'+e.target.value+'/json/ ').then( response =>{
                                seLoadingCep(false)
                              

                               if(!response.data.erro && response.data){
                                   setEndereco(response.data.logradouro)
                                   setComplemento(response.data.complemento)
                                   setBairro(response.data.bairro)
                                   setCidade(response.data.localidade)
                                   setEstado(response.data.uf)
                               }else{
                                   setEndereco('')
                                   setComplemento('')
                                   setBairro('')
                                   setCidade('')
                                   setEstado('')
                                Swal.fire({
                                    text:'Não existe endereço vinculado ao CEP informado !', 
                                    icon :'warning', 
                                    

                                })
                               }
                              
                               
                             
                            }).catch(error =>{
                                seLoadingCep(false)
                            })
                        }
                    }}
                    />
            </Grid>

            <Grid item xs = {4}>
                <TextField
                    label ='Endereço'
                    name='endereco'
                    id='endereco'
                    value = {endereco}
                    onChange = {(e)=>setEndereco(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    inputProps={{'name':'endereco'}}
                    variant='outlined'
                    fullWidth
                    />
            </Grid>
            <Grid item xs = {1}>
                <TextField
                    label ='Número'
                    name='numero'
                    id='numero'
                    value = {numero}
                    onChange = {(e)=>setNumero(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    variant='outlined'
                    fullWidth
                    />
            </Grid>

            <Grid item xs = {2}>
                <TextField
                    label ='Complemento'
                    name='complemento'
                    id='complemento'
                    value = {complemento}
                    onChange = {(e)=>setComplemento(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    variant='outlined'
                    fullWidth
                    />
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Bairro'
                    name='bairro'
                    id='bairro'
                    value = {bairro}
                    onChange = {(e)=>setBairro(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    variant='outlined'
                    fullWidth
                    />
            </Grid>
        </Grid>


        <Grid container spacing={1} style = {{marginTop:"20px"}}>
            <Grid item xs = {3}>
                <TextField
                    label ='Cidade'
                    name='cidade'
                    id='cidade'
                    value = {cidade}
                    onChange = {(e)=>setCidade(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    variant='outlined'
                    fullWidth
                    />
            </Grid>

            <Grid item xs = {1}>
                <TextField
                    label ='UF'
                    name='estado'
                    id='estado'
                    value = {estado}
                    onChange = {(e)=>setEstado(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    variant='outlined'
                    fullWidth
                    />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Telefone Comecial'
                    name='telefoneComercial'
                    id='telefoneComercial'
                    value = {telefoneComercial}
                    onChange = {(e)=>setTelefoneComercial(e.target.value)}
                    variant='outlined'
                    InputLabelProps = {{shrink : true}}
                    fullWidth
                    />
            </Grid>

            <Grid item xs = {2}>
                <TextField
                    label ='Telefone Celular'
                    name='telefoneCelular'
                    id='telefoneCelular'
                    value = {telefoneCelular}
                    onChange = {(e)=>setTelefoneCelular(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    variant='outlined'
                    fullWidth
                    />
            </Grid>
            <Grid item xs = {4}>
                <TextField
                    label ='Email *'
                    name='email'
                    id='email'
                    value = {email}
                    onChange = {(e)=>setEmail(e.target.value)}
                    InputLabelProps = {{shrink : true}}
                    inputProps = {{readOnly:true}}
                    variant='outlined'
                    type='email'
                    fullWidth
                    />
            </Grid>            
        </Grid>
        <Table className='table table-striped table-hover table-small table-sm' >
                <TableHead>
                    <TableCell>
                        prtAlertSeverityLevel 
                    </TableCell>
                    <TableCell>
                        prtAlertSeverityLevel 
                    </TableCell>
                </TableHead>
            </Table>
        <Grid container spacing={1} style = {{marginTop:"50px"}}>
            <Grid item xs = {2} style ={{textAlign:"left"}}>
                {idempresa &&  <TrocarSenha idUsuario={idempresa}/>}
            </Grid>
            <Grid item xs = {10} style ={{textAlign:"right"}}>
            
                <Button variant='outlined' color='secondary' onClick={()=>{
                    Swal.fire({
                    icon : 'info',
                    text :'Deseja cancelar as alterações ?',                  
                    showCancelButton: true,
                    confirmButtonText: 'Confirmar',
                    cancelButtonText : 'Cancelar', 
                    allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                    if (result.isConfirmed) {
                        props.history.push('/home/revenda/'+props.location.state.empresa.idrevenda)
                       
                    } 
                      
                  })
                }}>Cancelar</Button>
                <Button variant='contained' color='primary' style ={{marginLeft : '10px'}} onClick = {()=>{
                  Swal.fire({
                    icon : 'info',
                    text :'Deseja salvar as alterações ?',                  
                    showCancelButton: true,
                    confirmButtonText: 'Confirmar',
                    cancelButtonText : 'Cancelar', 
                    allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                    if (result.isConfirmed) {
                        updataForm()
                       
                    } 
                      
                  })  
                }}>Gravar</Button>
            </Grid>
        </Grid>
        </form>
    </Principal>
}



export default withRouter(MeusDados)