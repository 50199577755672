import React,{useEffect, useState, useRef, Fragment} from 'react'
import L from 'leaflet';
import "leaflet/dist/leaflet.css";//https://towardsdatascience.com/creating-a-bubbles-map-using-react-leaflet-e75124ca1cd2
import { point } from 'leaflet';
import Swal from 'sweetalert2'
import { Circle, TileLayer,withLeaflet,  Popup, Marker,  Tooltip, ZoomControl, Polygon, Polyline, Map  } from "react-leaflet";
import IconeVoltage from '@material-ui/icons/BatteryFullRounded'
import { Typography, Button , Divider, LinearProgress} from '@material-ui/core';
import Key from '@material-ui/icons/VpnKey'
import BoltOff from '@material-ui/icons/OfflineBoltOutlined'
import CalendarIcon from '@material-ui/icons/AccessTime'
import { LogoNodejs, SpeedometerOutline, FlashOutline, FlashOffOutline, SettingsOutline, KeyOutline , BatteryChargingOutline as CarregandoBateria, BatteryFullOutline as BateriaFull, BatteryHalfOutline as BateriaMeio,BatteryDeadOutline as BateriaZero
 } from 'react-ionicons'
 import BatteryCharging from '@material-ui/icons/BatteryChargingFull';
 import BatteryOff from '@material-ui/icons/BatteryUnknown'
 import SinalCelular0 from '@material-ui/icons/SignalCellular0Bar'
 import SinalCelular1 from '@material-ui/icons/SignalCellular1Bar'
 import SinalCelular2 from '@material-ui/icons/SignalCellular2Bar'
 import SinalCelular3 from '@material-ui/icons/SignalCellular3Bar'
 import SinalCelular4 from '@material-ui/icons/SignalCellular4Bar'
 import CombustivelIcon from '@material-ui/icons/LocalGasStation'
 import Geocoder from '../../../providers/Geocoding'
 import IconeOdometro from '@material-ui/icons/ThreeSixty'
import IconCar from '@material-ui/icons/DirectionsCar'
import IconUser from '@material-ui/icons/Person'
import Conexao from '../../../providers/Conexao'




function MarkerVeiculo(props){
    
    const {veiculo, index} = props;
    const [address, setAddress] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    useEffect(()=>{
      setAddress('')
    },[veiculo.latitude] )

    const renderStateBattery =(carregando) =>{
      if(carregando =='t'){
      return <BatteryCharging />
      }else{
        return <BatteryOff />
      }
      
    }

    const rendernivelBattery =(nivel) =>{      
      switch(nivel){
        case 0:return '0%'
        case 1:return '5%'
        case 2:return '10%'
        case 3:return '25%'
        case 4:return '50%'
        case 5:return '80%'
        case 6:return '100%'
      }
    }

    const renderNivelSinal =(nivel, type = 'icon') =>{   
      if(type == 'icon'){
        switch(nivel){
          case 0:return <SinalCelular0 style ={{color:'red'}}/>
          case 1:return <SinalCelular1 style ={{color:'orangered'}}/>
          case 2:return <SinalCelular2 style ={{color:'orange'}}/>
          case 3:return <SinalCelular3 style ={{color:'green'}}/>
          case 4:return <SinalCelular4 style ={{color:'lime'}}/>
        }
      } else{
        switch(nivel){
          case 0:return <span>Sem sinal</span>
          case 1:return <span>Extremamente fraco</span>
          case 2:return <span>Fraco</span>
          case 3:return <span>Bom</span>
          case 4:return <span>Excelente</span>
        }
      }  
      
    }

    const updatePosicoes = () =>{
      
    }
    return  <Marker onclick={()=>props.click()}
      key={'TEC'+index}                              
      position={[veiculo.latitude ? veiculo.latitude : 0, veiculo.longitude ? veiculo.longitude : 0]}                                                        
      icon={L.divIcon({
        className: 'custom-icon', 
        iconAnchor :[0, 0], 
        html: '<div class="div-marker-car"><img height="40" width:"40" style="transform: rotate('+(veiculo.azimuth )+'deg)" src="'+(veiculo.ignicao == '1'  ? "http://www.printerconnect.com.br/api/imagens/carroLigado.png" : "http://www.printerconnect.com.br/api/imagens/carroDesligado.png")+'"/><span class="centericone-rotulo'+(veiculo.ignicao == '1' ?'t':'f')+'">'+(props.display =='velocidade' ? veiculo.velocidade+' Km/h': veiculo.placa)+'</span></div>'
        })}>
        <Popup onClose={()=>setAddress('')} onOpen={()=>{

          setLoading(true)

          Geocoder.get('reverse?lat='+veiculo.latitude+'&lon='+veiculo.longitude+'&format=json').then(response=>{

            if(response.statusText == 'OK' && response.status == 200){
                let location = {placeId : response.data.place_id, address : response.data.display_name, posicoes : [{latitude :veiculo.latitude, longitude :  veiculo.longitude}]}
              
                for(let i = 0; i < response.data.boundingbox.length / 2; i++){
                  location.posicoes.push({
                    latitude : response.data.boundingbox[i], longitude : response.data.boundingbox[i+2]                  
                  })
                }

                location.posicoes.push({latitude : veiculo.latitude, longitude :veiculo.longitude })

                Conexao.post('veiculosController/updatePlace', {
                  location : location, 
                  uuid : veiculo.uuid,
                  latitude : veiculo.latitude,
                  longitude : veiculo.longitude                
                }).then(resp=>{
          
                }).catch(error=>{
        
              })
              setAddress(response.data.display_name)
            }
            setLoading(false)
          }).catch(error=>{
            setLoading(false)
          })
        }}>
          <div className ='popup-container-main'>
            <div className ='popup-container-status'>
              <div className='popup-container-placa'>{veiculo.placa}</div>
              <div className='popup-container-ignicao'>
              <div style ={{width : '20%', textAlign:'center'}}>{renderNivelSinal(veiculo.gsmlevel, 'icon')}</div>
                <div style ={{width : '80%', textAlign:'left', fontSize:'18px'}}>{renderNivelSinal(veiculo.gsmlevel, 'label')}</div>
              </div>
              <div className='popup-container-movimento'>
                <div style ={{width : '30%', textAlign:'center'}}>{renderStateBattery(veiculo.charging)}</div>
                <div style ={{width : '70%', textAlign:'left', fontSize:'18px'}}>{rendernivelBattery(veiculo.voltagemexterna)}</div>               
              </div>              
            </div>
                <div style={{ paddingTop :'5px'}}>
                <IconCar size={32}/><Typography variant='caption' style ={{fontWeight:'bold', color:'#404040', fontSize:'16px', paddingTop:'4px', paddingLeft:'5px'}}>{veiculo.marca+' ' +veiculo.modelo}</Typography>
                </div>
                <div style={{ paddingTop :'5px'}}>
                <IconUser /><Typography variant='caption' style ={{fontWeight:'bold', color:'#404040', fontSize:'12px', paddingTop:'4px', paddingLeft:'5px'}}>{veiculo.marca+' ' +veiculo.modelo}</Typography>
                </div>
            <div style={{display:'flex', paddingTop :'5px'}} className='mini-icon'>
                <div style ={{width : '10%', paddingLeft:'3px'}}><CalendarIcon /></div>
                <div style ={{width : '90%'}}>{veiculo.dataposicao+' '+(veiculo.horaposicao && veiculo.horaposicao.substr(0,8))}</div>
            </div>
            <div style={{display:'flex', paddingTop :'5px' }} >
              <div style={{ width :'25%', textAlign:'center'}} className='mini-icon'>
                <KeyOutline   color={veiculo.ignicao == 1 ? '#388E3C' : '#B71C1C'}                
                    height="18px"
                    width="18px"/><br/><span style={{paddingLeft :'5px', color : veiculo.ignicao == 1 ? '#388E3C' : '#B71C1C'}}>{veiculo.ignicao == 1 ? 'Ligado' : 'Desligado'}</span>
                  
              </div>
              <div style={{ width :'25%', textAlign:'center'}} className='mini-icon'>
                {
                    veiculo.velocidade > 0 ? <FlashOutline   color={'#388E3C'}                  
                      height="18px"
                      width="18px"/> : <FlashOffOutline   color={'#0D47A1' }                  
                      height="18px"
                      width="18px"/> 
                  }
                <br/><span style={{paddingLeft :'5px', color : veiculo.velocidade > 0 ? '#388E3C' : '#0D47A1' }}>{veiculo.velocidade > 0 ? veiculo.velocidade+' Km/h' : 'Parado'}</span>
              </div>
              <div style={{ width :'25%', textAlign:'center'}} className='mini-icon'>
                <CombustivelIcon /><br/>
                 <span>{veiculo.consumo} Km/l</span>
              </div>
              
              <div style={{ width :'25%', textAlign:'center'}} className='mini-icon'>
                 <SpeedometerOutline   color={'#0D47A1' }                  
                      height="18px"
                      width="18px"/><br/>
                 <span>{veiculo.odometro} Km(s)</span>
              </div>
            </div>
            
            <br/>
            <Divider/>
              <div style ={{fontSize:'10px', color:'#424242', marginTop:'10px'}}>{loading ? <LinearProgress variant='indeterminate' /> : 
                address}</div>
          </div>
              
      </Popup>
        
      </Marker>

}

export default MarkerVeiculo;