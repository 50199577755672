import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField, Container,   Button,Divider, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import TextFieldCPF from '../../components/TextFieldCPF'
import TextFieldTelefone from '../../components/TextFieldTelefone'
import TextFieldCelular from '../../components/TextFieldCelular'
import TextFieldCEP from '../../components/TextFieldCep'
import SearchIcon from '@material-ui/icons/Search';
import Backdrop from '@material-ui/core/Backdrop';

import TextFieldEmail from '../../components/TextFieldEmail'

const FormNovoFuncionario = function(props){
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [nomeCompleto, setNomeCompleto] = useState('');
    const [apelido, setApelido] = useState('');
    const [cep, setCep] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [telefoneComercial, setTelefoneComercial] = useState('');
    const [telefoneCelular, setTelefoneCelular] = useState('');
    const [email, setEmail] = useState('');
    const [senhaApp, setSenhaApp] = useState('');
    const [idRegiao, setIdRegiao]= useState(0);
    const [error,setError] = useState(false)
    const [regioes, setRegioes] = useState([])




    useEffect(()=>{
        if(props.edicaoFuncionario.idtecnico){
            setCpf(props.edicaoFuncionario.cpf_cnpj)
            setRg(props.edicaoFuncionario.rg_insc)
            setNomeCompleto(props.edicaoFuncionario.nomecompleto)
            setApelido(props.edicaoFuncionario.apelido)
            setCep(props.edicaoFuncionario.cep)
            setEndereco(props.edicaoFuncionario.logradouro)
            setNumero(props.edicaoFuncionario.numero)
            setComplemento(props.edicaoFuncionario.complemento)
            setBairro(props.edicaoFuncionario.bairro)
            setCidade(props.edicaoFuncionario.cidade)
            setEstado(props.edicaoFuncionario.estado)
            setTelefoneComercial(props.edicaoFuncionario.telefonecomercial)
            setTelefoneCelular(props.edicaoFuncionario.telefonecelular)
            setEmail(props.edicaoFuncionario.email)
            setSenhaApp(props.edicaoFuncionario.senha)
        }
    }, [props.edicaoFuncionario])

    useEffect(()=>{
        getRegioes()
    },[])
    const insertFuncionario = () =>{
        setError(false)
        if(!cpf || ! nomeCompleto || !cidade || !estado || ! email){
            setError(true);
            Swal.fire({
                icon : 'warning',
                title : 'Gestão de Técnicos', 
                text : 'Você precisa preencher os campos obrigatórios(*) !', 
                timer : 1500, 
                showCancelButton : false, 
                showConfirmButton: false
            })
            
        }else{
            Conexao.post('funcionariosController/'+( props.edicaoFuncionario.idtecnico ? 'updateFuncionario' : 'insertFuncionario' ), {
                idusuario : props.location.state.empresa.idrevenda, 
                idempresa :  props.location.state.empresa.idempresa, 
                idfuncionario : props.edicaoFuncionario.idtecnico ? props.edicaoFuncionario.idtecnico : 0,
                cpf, 
                rg, 
                nomeCompleto, 
                apelido, 
                cep, 
                endereco, 
                numero, 
                complemento, 
                bairro,
                cidade, 
                estado, 
                telefoneComercial, 
                telefoneCelular, 
                email, 
                idregiao : idRegiao, 
                senha : senhaApp
            }).then( response =>{
                if(response.data.status.trim() =='OK'){
                    Swal.fire({
                        icon : 'success',
                        title : 'Gestão de Técnicos', 
                        text : 'Técnico inserido com sucesso !', 
                        timer : 1500, 
                        showCancelButton : false, 
                        showConfirmButton: false
                    })

                    if(props.edicaoFuncionario.idtecnico && idRegiao != props.edicaoFuncionario.idregiao){

                        let dadosRegiao = regioes.filter(regiao => regiao.idregiao == idRegiao).map(function(regiao){
                            return regiao.descricao
                        })

                        Conexao.post('chamadosTecnicosController/pushNotification', {
                            destino : props.edicaoFuncionario.chavepush, 
                            titulo : 'Aviso de Mudança de Região - Printerconnect',  
                            mensagem : 'Você foi movido para a região: '+dadosRegiao[0]+'. Feche seu aplicativo e abra-o novamente para baixar suas novas configurações!',
                            data :{
                                atualizarTela :'All', 
                                mensagem : 'Você foi movido para a região: '+dadosRegiao[0]+'. Feche seu aplicativo e abra-o novamente para baixar suas novas configurações!',
                            }
                        }).then(response=>{
                            
                        }).catch(error=>{
                            console.log(error)
                        })
                    }
                }else{
                    Swal.fire({
                        icon : 'warning',
                        title : 'Gestão de Técnicos', 
                        text : 'Não foi possível fazer inclusão', 
                        timer : 1500, 
                        showCancelButton : false, 
                        showConfirmButton: false
                    })
                }
                props.updateFuncionarios();
                props.closeModal();
                
            }).catch( error =>{
                Swal.fire({
                    icon : 'warning',
                    title : 'Gestão de Técnicos', 
                    text : 'Não foi possível processar requisição !', 
                    timer : 1500, 
                    showCancelButton : false, 
                    showConfirmButton: false
                })
                props.closeModal();
            })
        }
    }

    const setEnderecoByCep = (data)=>{
      
        if(!data.erro && data.logradouro){
            setEndereco(data.logradouro)
            setComplemento(data.complemento)
            setBairro(data.bairro)
            setCidade(data.localidade)
            setEstado(data.uf)
        }else{
            setEndereco('')
            setComplemento('')
            setBairro('')
            setCidade('')
            setEstado('')         
        }
    }

    const getRegioes = async() =>{
        
        Conexao.get('empresasController/getComboRegioes?idusuario='+props.location.state.empresa.idrevenda+'&idempresa='+props.location.state.empresa.idempresa+'&distinct=false').then(response=>{

            if(response.data.status =='OK'){
                setRegioes(response.data.payload)
            }else{
                setRegioes([])
            }

           
        }).catch(error=>{
            setRegioes([])
            
        })
        
    }

return <Container>
        <Divider/>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs = {3} align='left'>
                    <TextFieldCPF 
                        variant = 'outlined'

                        label='CPF*'                 
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        value = {cpf}
                        onChange ={(e)=>setCpf(e.target.value)}
                    />
                </Grid>
           
                <Grid item xs = {2} align='left'>
                    <TextField 
                        variant = 'outlined'
                   
                        variant='outlined'
                        label='RG'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        inputProps = {{
                            maxlength : 20
                        }}
                        value = {rg}
                        onChange ={(e)=>setRg(e.target.value)}
                    />
                </Grid>
          
                <Grid item xs = {4} align='left'>
                    <TextField 
                        variant = 'outlined'
                   
                        variant='outlined'
                        label='Nome Completo'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        inputProps = {{
                            maxlength : 150
                        }}
                        value = {nomeCompleto}
                        onChange ={(e)=>setNomeCompleto(e.target.value)}
                    />
                </Grid>
          
                <Grid item xs = {3} align='left'>
                    <TextField 
                        variant = 'outlined'
                   
                        variant='outlined'
                        label='Apelido'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        inputProps = {{
                            maxlength : 100
                        }}
                        value = {apelido}
                        onChange ={(e)=>setApelido(e.target.value)}
                    />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs = {2} align='left'>
                    <TextFieldCEP 
                        variant = 'outlined'
                        label='Cep'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        inputProps = {{
                            maxlength : 9
                        }}
                       
                        value = {cep}
                        onChange ={(e)=>setCep(e.target.value)}
                        
                        retornoCep = {(data)=>{
                         
                            setEnderecoByCep(data)
                         }}
                    />
                </Grid>
          
                <Grid item xs = {6} align='left'>
                    <TextField 
                        variant = 'outlined'
                         label='Endereço'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        inputProps = {{
                            maxlength : 100
                        }}
                        value = {endereco}
                        onChange ={(e)=>setEndereco(e.target.value)}
                    />
                </Grid>
           
                <Grid item xs = {1} align='left'>
                    <TextField 
                        variant = 'outlined'
                        label='Nº'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        inputProps = {{
                            maxlength : 8
                        }}
                        value = {numero}
                        onChange ={(e)=>setNumero(e.target.value)}
                    />
                </Grid>
           
                <Grid item xs = {3} align='left'>
                    <TextField 
                        variant = 'outlined'
                        label='Complemento'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        inputProps = {{
                            maxlength : 60
                        }}
                        value = {complemento}
                        onChange ={(e)=>setComplemento(e.target.value)}
                    />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2}>
              
                <Grid item xs = {4} align='left'>
                    <TextField 
                        variant = 'outlined'
                        label='Bairro'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        inputProps = {{
                            maxlength : 60
                        }}
                        value = {bairro}
                        onChange ={(e)=>setBairro(e.target.value)}
                    />
                </Grid>
          
                <Grid item xs = {6} align='left'>
                    <TextField 
                        variant = 'outlined'
                        label='Cidade'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        inputProps = {{
                            maxlength : 150
                        }}
                        value = {cidade}
                        onChange ={(e)=>setCidade(e.target.value)}
                    />
                </Grid>
           
                <Grid item xs = {2} align='left'>
                    <TextField 
                        variant = 'outlined'
                        label='Estado'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        inputProps = {{
                            maxlength : 2
                        }}
                        value = {estado}
                        onChange ={(e)=>setEstado(e.target.value)}
                    />
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2}>
                
                <Grid item xs = {3} align='left'>
                    <TextFieldTelefone 
                        variant = 'outlined'
                        label='Telefone Comercial'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        value = {telefoneComercial}
                        onChange ={(e)=>setTelefoneComercial(e.target.value)}
                    />
                </Grid>
            
                <Grid item xs = {3} align='left'>
                    <TextFieldCelular 
                        variant = 'outlined'
                        label='Telefone Celular'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        value = {telefoneCelular}
                        onChange ={(e)=>setTelefoneCelular(e.target.value)}
                    />
                </Grid>
           

           
                <Grid item xs = {6} align='left'>
                    <TextFieldEmail 
                        variant = 'outlined'
                        label='Email'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        inputProps = {{
                            maxlength : 250
                        }}
                        value = {email}
                        onChange ={(e)=>setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs = {4} align='left'>
                    <TextField 
                        variant = 'outlined'
                        label='Senha Aplicativo'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        value = {senhaApp}
                        onChange ={(e)=>setSenhaApp(e.target.value)}
                    />
                </Grid>
                {props.edicaoFuncionario.idtecnico && 
                
                <Grid item xs = {4} align='left'>
                    <TextField 
                        variant = 'outlined'
                        label='Selecione uma região'
                        variant='outlined'
                        InputLabelProps ={{shrink : true}}                   
                        fullWidth
                        size='small'
                        SelectProps={{native: true}}
                        select
                        onChange ={(e)=>setIdRegiao(e.target.value)}
                    >
                        {
                            regioes && regioes.filter(regiao => regiao.idregiao == props.edicaoFuncionario.idregiao).map(function(regiao){
                                return <option value={regiao.idregiao}>{regiao.descricao}</option>
                            })
                        }
                        {
                            regioes && regioes.filter(regiao => regiao.idregiao != props.edicaoFuncionario.idregiao).map(function(regiao){
                                return <option value={regiao.idregiao}>{regiao.descricao}</option>
                            })
                        }
                    </TextField>
                </Grid>       
            }
            </Grid>
            
            
            <br/>

            
            <Divider/>
            <br/>
            <br/>

            <Grid container spacing={2}>
            <Grid item xs = {8} align={'right'}></Grid>
                <Grid item xs = {2} align={'right'}>
                    <Button fullWidth
                    size='small' size='large' variant ='contained' color='secondary' onClick = {()=>{
                        Swal.fire({
                            title : 'Gestão de Técnicos', 
                            text :'Deseja cancelar esta operação ?', 
                            showConfirmButton : true, 
                            confirmButtonText : 'Confirmar', 
                            showCancelButton : true, 
                            cancelButtonText : 'Cancelar',   
                            icon : 'question'                          
                        }).then( result =>{
                            if(result.isConfirmed){
                                props.closeModal();
                            }
                        })
                    }}>Cancelar</Button>
                </Grid>
                <Grid item xs = {2} align='left'>
                <Button fullWidth
                size='small' size='large' variant ='contained' color='primary' onClick = {()=>{
                        Swal.fire({
                            title : 'Gestão de Técnicos', 
                            text :'Deseja realmente gravar as informações ?', 
                            showConfirmButton : true, 
                            confirmButtonText : 'Confirmar', 
                            showCancelButton : true, 
                            cancelButtonText : 'Cancelar',   
                            icon : 'question'                          
                        }).then( result =>{
                            if(result.isConfirmed){

                                insertFuncionario();
                            }
                        })
                    }}>Confirmar</Button>
                </Grid>
            </Grid>
    </Container>
}


export default withRouter(FormNovoFuncionario)