import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search'
import {Table, TableHead, TableBody, TableRow, TableCell, TextField, LinearProgress} from "@material-ui/core"
import IconeSearchCar from '@material-ui/icons/Search'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [veiculos, setVeiculos] = React.useState([])

    const handleClickOpen = () =>{
        setOpen(true)
        getVeiculos();
    }

    const handleClose = () =>{
        setOpen(false)
    }

    const getVeiculos = () =>{
      setLoading(true)
        Conexao.post('veiculosController/getVeiculos', {
            idusuario : props.usuario
        }).then(response=>{
            if(response.data.status =='OK'){
                setVeiculos(response.data.payload)
            }else{
                setVeiculos([])
            }
      setLoading(false)

        }).catch(error=>{
            setVeiculos([])
            setLoading(false)
        })
    }
  return (
    <div>
      <Tooltip title='Selecionar um veículo para acompanhar seu trajeto'>
          <Button style ={{backgroundColor : '#f4f4f4'}} onClick={handleClickOpen}>
            <IconeSearchCar color='primary'  style={{cursor:'pointer'}}/>   
          </Button>                 
      </Tooltip>
      <Dialog         
        hideBackdrop
        scroll='paper'
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        {/*<DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Selecionar Veículo
           </DialogTitle>*/}
          {
            loading && <LinearProgress color='secondary' />
          }
        <DialogContent  style = {{minHeight:"100px", overflow:"auto", paddingTop:'0px!important'}} >
            {
              veiculos && veiculos.map(function(veiculo,i){
                return <div style ={{
                  borderRadius : '5px', 
                  backgroundColor : '#f6f6f6', 
                  display : 'flex', 
                  padding:'10px', 
                  cursor : 'pointer'
                  , marginTop :(i == 0 ?'0px': '10px')
                }} onClick={()=>{
                  Swal.fire({
                      title : 'Printerconnect - Frota', 
                      text  : 'Deseja selecionar este veículo?', 
                      icon : 'question', 
                      showConfirmButton : true,
                      showCancelButton : true
                  }).then(resp=>{
                      if(resp.isConfirmed){
                          if(props.setVeiculo){
                              props.setVeiculo({
                                  idusuario : veiculo.idusuario, 
                                  marca : veiculo.marca, 
                                  modelo : veiculo.modelo, 
                                  placa : veiculo.placa, 
                                  idrastreador : veiculo.idrastreador
                              })
                          }
                          setOpen(false)
                      }
                  })
              }} className='hovered'> 
                    <div style ={{width:'28%', display : 'flex', alignContent:'center', justifyContent:'center'}}>
                      <Typography style ={{fontSize:'26px', color:'#767676'}}>{veiculo.placa}</Typography>
                    </div>
                    <div style ={{width:'72%', }}>
                        <Typography variant='subtitle1' style ={{color:'#767676'}}>{veiculo.marca+' - '+veiculo.modelo}</Typography>
                        <Typography variant='subtitle2' style ={{color:'#767676'}}>Combusível: {veiculo.combustivel}</Typography>
                        <Typography variant='subtitle2' style ={{color:'#767676'}}>IMEI: {veiculo.idrastreador}</Typography>
                    </div>

                </div>
              })
            }            
        </DialogContent>        
      </Dialog>
    </div>
  );
}


export default withRouter(ModalPadrao)