import React from 'react';
import {Typography, Grid, TextField, Divider, Button} from '@material-ui/core'
import Swal from 'sweetalert2'
import Conexao from '../../../../providers/Conexao'

import CircularProgress from '@material-ui/core/CircularProgress';


export default function(props){
    const refNome = React.useRef();
    const refEmail = React.useRef();
    const refEmpresa = React.useRef();
    const refTelefone = React.useRef();
    const refMensagem = React.useRef();
    const [loading, setLoading] = React.useState(false)

    const insertContatoSite = () =>{
        setLoading(true)
        Conexao.post('usuariosController/insertContatoSite', {
            nome : refNome.current ? refNome.current.value : '', 
            email : refEmail.current ? refEmail.current.value : '', 
            empresa : refEmpresa.current ? refEmpresa.current.value : '', 
            telefone : refTelefone.current ? refTelefone.current.value : '', 
            mensagem :refMensagem.current ? refMensagem.current.value : ''
        }).then(response =>{
            if(response.data.status =='OK'){
                Swal.fire({
                    title : 'PrinterConnect', 
                    text : 'Contato enviado com sucesso !', 
                    showConfirmButton : false, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : false, 
                    cancelButtonText : 'Não', 
                    icon : 'success', 
                    backdrop : true, 
                    timer : 1500
                })
            }else{
                Swal.fire({
                    title : 'PrinterConnect', 
                    text : 'Não foi possível incluir contato !', 
                    showConfirmButton : false, 
                    confirmButtonText : 'Sim', 
                    showCancelButton : false, 
                    cancelButtonText : 'Não', 
                    icon : 'warning', 
                    backdrop : true, 
                    timer : 1500
                })
            }
            setLoading(false)
            refNome.current.value =  '';
            refEmail.current.value =  '';
            refEmpresa.current.value =  '';
            refTelefone.current.value =  '';
            refMensagem.current.value =  '';
        }).catch(error=>{
            Swal.fire({
                title : 'PrinterConnect', 
                text : 'Não foi possível incluir contato !', 
                showConfirmButton : false, 
                confirmButtonText : 'Sim', 
                showCancelButton : false, 
                cancelButtonText : 'Não', 
                icon : 'error', 
                backdrop : true, 
                timer : 1500
            })
            setLoading(false)
            refNome.current.value =  '';
            refEmail.current.value =  '';
            refEmpresa.current.value =  '';
            refTelefone.current.value =  '';
            refMensagem.current.value =  '';
        });

    }

    const  validateEmail = (email) =>{
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
      }


    return <div id='div-container-contato' style ={{ minHeight:"100vh"}}>
        <Typography variant='h4' color ='primary'>Fale conosco</Typography>
        <br/>
        <br/>
        <Grid container spacing={2}>
            <Grid item xs = {12}>
                <TextField
                    label='Nome'
                    variant = 'outlined' 
                    inputProps={{ maxLength: 60 }}
                    fullWidth
                    inputRef = {refNome}
                    InputLabelProps = {{shrink : true}}
                    />
            </Grid>
            <Grid item xs = {12}>
                <TextField
                    label='Email'
                    variant = 'outlined' 
                    inputProps={{ maxLength: 250 }}
                    fullWidth
                    type='email'
                    inputRef = {refEmail}
                    InputLabelProps = {{shrink : true}}
                   
                    />
            </Grid>
            <Grid item xs = {12}>
                <TextField
                    label='Empresa'
                    variant = 'outlined' 
                    fullWidth
                    inputRef = {refEmpresa}
                    inputProps={{ maxLength: 60 }}
                    InputLabelProps = {{shrink : true}}
                    />
            </Grid>
            <Grid item xs = {12}>
                <TextField
                    label='Telefone'
                    variant = 'outlined' 
                    fullWidth
                    inputRef = {refTelefone}
                    maxLength={12}
                    inputProps ={{
                        maxLength : 16
                    }}
                    InputLabelProps = {{shrink : true}}
                    />
            </Grid>

            <Grid item xs = {12}>
                <TextField
                    label='Mensagem'
                    variant = 'outlined' 
                    fullWidth
                    inputRef = {refMensagem}
                    multiline
                    rows={5}
                    rowsMax={5}
                    inputProps={{ maxLength: 500 }}
                    InputLabelProps = {{shrink : true}}
                    />
            </Grid>
            <br/>
            <Grid item xs = {12}>
                {!loading ? <Button fullWidth variant ='contained' style ={{height:"60px"}} color ='secondary' onClick = {()=>{
                    Swal.fire({
                        title : 'PrinterConnect', 
                        text : 'Deseja enviar o contato agora ?', 
                        showConfirmButton : true, 
                        confirmButtonText : 'Sim', 
                        showCancelButton : true, 
                        cancelButtonText : 'Não', 
                        icon : 'question', 
                        backdrop : true, 
                    }).then((resposta)=>{
                        if(resposta.isConfirmed){
                            if(refNome.current.value ==  '' || refEmail.current.value =='' || refTelefone.current.value == '' || refMensagem.current.value == ''  ){
                                Swal.fire({
                                    title : 'PrinterConnect', 
                                    text : 'Todos os campos são obrigatórios !', 
                                    showConfirmButton : false, 
                                    confirmButtonText : 'Sim', 
                                    showCancelButton : false, 
                                    cancelButtonText : 'Não', 
                                    icon : 'warning', 
                                    backdrop : true, 
                                    timer : 1500
                                })
                            }else{
                                if(!validateEmail(refEmail.current.value)){
                                    Swal.fire({
                                        title : 'PrinterConnect', 
                                        text : 'O email precisa estar em um formato válido', 
                                        showConfirmButton : false, 
                                        confirmButtonText : 'Sim', 
                                        showCancelButton : false, 
                                        cancelButtonText : 'Não', 
                                        icon : 'warning', 
                                        backdrop : true, 
                                        timer : 1500
                                    })
                                }else{
                                    insertContatoSite();
                                }
                                
                            }

            
                            
                        }
                    })
                }}>Enviar</Button> : <CircularProgress color="secondary" />}
            </Grid>
        </Grid>
      
    </div>
}
