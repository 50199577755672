import React, {Fragment} from 'react'
import {Typography, Divider} from '@material-ui/core'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';



export default function(props){
    return <footer id='footer-rodape'>
        <div className='footer-container-flex'>
            <div className='div-footer-logo'>
                <Typography variant='h4'>Databit Tecnologia da Informação</Typography>
                <Typography variant='subtitle1'>Outsourcing de Impressão na era do IoT</Typography>
            
            <div style = {{display:"flex", marginTop:"20px"}}>
            <FacebookIcon style = {{cursor:"pointer"}}/>
            <LinkedInIcon style = {{marginLeft:"10px",cursor:"pointer"}}/>
            <InstagramIcon style = {{marginLeft:"10px",cursor:"pointer"}}/>
            <TwitterIcon style = {{marginLeft:"10px",cursor:"pointer"}}/>
                
            </div>
                
         
            </div>
            <div className='div-footer-texto'> 
            <Typography variant='h5'>Institucional</Typography>
            <Typography variant='subtitle1'>A DATABIT é um grupo de empresas que atua no setor de Tecnologia, Softwares de Gestão e Serviços de Informática.</Typography>
            <br/>
            <Typography variant='subtitle2'>Rua Mário Campos, 197</Typography>
            <Typography variant='subtitle2'>Inconfidência</Typography>
            <Typography variant='subtitle2'>Belo Horizonte, Minas Gerais</Typography>

            <Typography variant='subtitle2'>31 3416-8225</Typography>
            <Typography variant='subtitle2'>contato@databit.com.br</Typography>
            </div>
        </div>
    </footer>
}