import React, {Component} from 'react';
import  {
  AreaChart, 
  Area, 
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend, 
  ResponsiveContainer
} from 'recharts';

const data = [
  {
    "name": "Page A",
    "uv": 4000,
    "pv": 2400,
    "amt": 2400
  },
  {
    "name": "Page B",
    "uv": 3000,
    "pv": 1398,
    "amt": 2210
  },
  {
    "name": "Page C",
    "uv": 2000,
    "pv": 9800,
    "amt": 2290
  },
  {
    "name": "Page D",
    "uv": 2780,
    "pv": 3908,
    "amt": 2000
  },
  {
    "name": "Page E",
    "uv": 1890,
    "pv": 4800,
    "amt": 2181
  },
  {
    "name": "Page F",
    "uv": 2390,
    "pv": 3800,
    "amt": 2500
  },
  {
    "name": "Page G",
    "uv": 3490,
    "pv": 4300,
    "amt": 2100
  }
]

const GraficoLinhasMagenta = (props) =>{
  const [data, setData] = React.useState([])
    
  React.useEffect(()=>{
    if(props.serial != ''){
      getData();
     
    }
      
  },[props.serial])


  const getData = () =>{
    props.conexao.get('coletasController/getDataNivelTonerGrafico?numeroserie='+props.serial+'&cor=MAGENTA').then(response =>{
      if(response.data.status == 'OK'){
        setData(response.data.payload);
      }else{
        setData([]);
      }
    }).catch(error =>{
      setData([]);
    })
  }
    

    return (
      <ResponsiveContainer width="100%" height={120}>
      <AreaChart  data={data}
      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
      
      <XAxis dataKey="datalog" />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Area type="monotone" dataKey="nivel" stroke={"#ad1457"} fillOpacity={0.1} fill="#ad1457"/>
     
    </AreaChart>
  </ResponsiveContainer>
    );
  
}

export default GraficoLinhasMagenta;