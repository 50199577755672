import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {Grid, TextField} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Swal from 'sweetalert2'
import Conexao from '../../../providers/Conexao'
var md5 = require('md5');



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalTrocaSenhaCliente(props) {
  const [open, setOpen] = React.useState(false);
  const [senha, setSenha] = React.useState('');
  const [confirmacao, setConfirmacao] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(()=>{
    setSenha('')
    setConfirmacao('')
  }, [open])

  const updateSenha = () =>{
    Conexao.post('usuariosController/updateSenha', {
        idEmpresa : props.idUsuario, 
        senha : md5(senha)
    }).then(response=>{
      if(response.data=='T'){
        Swal.fire({
          text:'Sua senha foi alterada com sucesso !', 
          icon :'success', 
          timer : 1500, 
          showCancelButton:false, 
          showConfirmButton:false
        })
        
      }else{
        Swal.fire({
          text:'Não foi possível alterar !', 
          icon :'warning', 
          timer : 1500, 
          showCancelButton:false, 
          showConfirmButton:false
        })
      }
      handleClose()

    }).catch(error=>{
      Swal.fire({
        text:'Não foi possível alterar ! erro de conexão', 
        icon :'danger', 
        timer : 1500, 
        showCancelButton:false, 
        showConfirmButton:false
      })
      handleClose()
    });
  }

  return (
    <div>
      <Button variant='outlined' color='default' onClick={handleClickOpen}>
        Trocar Senha
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Printerconnect - Alterar senha de acesso Master
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant='caption'>
            Este processo alterará sua senha de acesso, por isso deve ser realizado com atenção.
          </Typography>
          <br/>
          <Grid item xs = {12} spacing={5} style ={{marginTop:"25px"}}>
              <TextField
                label='Nova Senha'
                fullWidth
                variant='outlined'
                type='password'
                value = {senha}
                onChange={(e)=>setSenha(e.target.value)}
                InputLabelProps ={{shrink : true}}
                />
          </Grid>
          <br/>
          <Grid item xs = {12} spacing={5}>
              <TextField
                label='Confirmação'
                fullWidth
                variant='outlined'
                type='password'
                value = {confirmacao}
                onChange={(e)=>setConfirmacao(e.target.value)}
                InputLabelProps ={{shrink : true}}
                />
          </Grid>
          
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary">
            Cancelar
          </Button>
         
          <Button disabled = {((senha == '' || confirmacao == '') || (senha != confirmacao))} autoFocus onClick={()=>{
              Swal.fire({
                    icon : 'warning',
                    text :'Deseja gravar as alterações ?',                  
                    showCancelButton: true,
                    confirmButtonText: 'Confirmar',
                    cancelButtonText : 'Cancelar', 
                    allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                    if (result.isConfirmed) {
                        
                        updateSenha();

                       // props.history.push('/home/empresa/'+props.match.params.idempresa)
                       
                    } 
                      
                  })
          }} color="primary">
            Gravar
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
