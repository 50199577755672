import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../providers/Conexao'
import DisplayStatus from '../../../components/DisplayStatus'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal_original'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import DraftsTwoToneIcon from '@material-ui/icons/DraftsTwoTone';
import MailTwoToneIcon from '@material-ui/icons/MailTwoTone';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import LanguageIcon from '@material-ui/icons/Language';//pendereWeb 1
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback'; // TIPO ABERTURA MANUAL
import ComputerIcon from '@material-ui/icons/Computer';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BuildIcon from '@material-ui/icons/Build';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import WarningIcon from '@material-ui/icons/Warning';




const CadastroChamadosTecnicos = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false);
    const [loading, setLoading]= useState(false);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [paginas, setPaginas]= React.useState(1);
    const [tiposChamados, setTiposChamados] = useState([]);
    const [situacoesChamados, setSituacoesChamados] = useState([]);
    const [chamados, setChamados] = useState([]);
    const [equipamento, setEquipamento]= useState([]);
    const [empresa, setEmpresa] = useState(props.location.state)

    React.useEffect(()=>{
        getParametrosAberturaChamado();
        getChamadosAbertosGestao();
        
    }, [])
    

  


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getParametrosAberturaChamado = () =>{
        Conexao.get('chamadosTecnicosController/getParametrosAberturaChamado').then(response=>{
            if(response.data.status == 'OK'){
                setSituacoesChamados(response.data.payload.situacoesChamados);
                setTiposChamados(response.data.payload.tiposChamados)
            }
        }).catch(error=>{

        });
    }

    const getChamadosAbertosGestao = () =>{
        Conexao.post('chamadosTecnicosController/getChamadosAbertosGestao', {
            idusuario : empresa.idusuario, 
            idempresa : empresa.idempresa,
            idcliente : empresa.idcliente
        }).then(response=>{
           
            if(response.data.status == 'OK'){
                setChamados(response.data.payload);
               // console.log(chamados)
               
            }
        }).catch(error=>{

        });
    }
   
    const renderSituacao = (situacao) =>{
        
        switch(situacao){
            case 1: return <div style ={{textAlign:"center", color:"#444"}}><AccessAlarmIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>TRIAGEM</span> </div>; 
            case 2: return <div style ={{textAlign:"center", color:"#444"}}><AccessAlarmIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>TRIAGEM</span> </div>; 
            case 3: return <div style ={{textAlign:"center", color:"#444"}}><AccessTimeIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>EM ANDAMENTO</span> </div>; 
            case 4: return <div style ={{textAlign:"center", color:"#444"}}><DirectionsRunIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>A CAMINHO</span> </div>; 
            case 5: return <div style ={{textAlign:"center", color:"#444"}}><BuildIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>ATENDENDO</span> </div>; 
            case 6: return <div style ={{textAlign:"center", color:"#444"}}><CheckIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>PRÉ FINALIZADO</span> </div>; 
            case 7: return <div style ={{textAlign:"center", color:"#444"}}><WarningIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>SOLICITAÇÃO. RETORNO</span> </div>; 
            case 8: return <div style ={{textAlign:"center", color:"#444"}}><MenuOpenIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>CONCLUÍDA PARCIAL</span> </div>; 
            case 9: return <div style ={{textAlign:"center", color:"#444"}}><DoneAllIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>CONCLUÍDA</span> </div>; 
            case 10: return <div style ={{textAlign:"center", color:"#444"}}><AttachFileIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>AGUARDANDO PEÇAS</span> </div>; 
            default: return 
        }
    }

    const renderTipoAbertura = (tipo) =>{
        
        switch(tipo){
            case 'MANUAL': return <div style ={{textAlign:"center", color:"#444"}}><PhoneCallbackIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>MANUAL</span> </div>; 
            case 'WEB': return <div style ={{textAlign:"center", color:"#444"}}><ComputerIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>WEB</span> </div>; 
            case 'ALERTA': return <div style ={{textAlign:"center", color:"#444"}}><NotificationsActiveIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>ALERTA</span> </div>; 
            case 'TERMO E/S': return <div style ={{textAlign:"center", color:"#444"}}><PhoneCallbackIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>TERMO E/S</span> </div>; 
            case 'CHEK-LIST': return <div style ={{textAlign:"center", color:"#444"}}><FolderOpenIcon className='incon-large'/><br/><span style = {{fontSize:'9px'}}>CHEK-LIST</span> </div>;           
            default: return 
        }
    }

   

    return <Principal atualizar = {loading}>
    <Grid container spacing = {1}>
            <Grid item xs = {7}><Typography>Chamados Técnicos</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
            <Grid item xs = {2}>
                <TextField
                    label ='Tipo de Data'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    size='small'   
                    select
                    SelectProps={{native:true}}
                >
                    <option>ABERTURA</option>
                    <option>ATENDIMENTO</option>
                    <option>FECHAMENTO</option>
                </TextField>
            </Grid>

            <Grid item xs = {2}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                    size='small'   
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Chamado'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    size='small'    
                   />
            </Grid>
          
           
            <Grid item xs = {4}>
                <TextField
                    label ='Número Serie'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    size='small'              
                   />
            </Grid>
            
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' ><SearchIcon/> Buscar</Button>
                <Button color='secondary' variant='contained' ><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
        <Grid container spacing = {2}>
        <Grid item xs = {2}>
                <TextField
                    label ='Placa'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    size='small'    
                   />
            </Grid>
        <Grid item xs = {2}>
                <TextField
                    label ='O.S'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                    size='small'    
                   />
            </Grid>
            <Grid item xs = {4}>
                <TextField
                    label ='Modelo'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    size='small'              
                   />
            </Grid>
           
            <Grid item xs = {4}>
                <TextField
                    label ='Setor'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    size='small'              
                   />
            </Grid>
           
        </Grid>
        <br/>
        <div>
        <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={'grafico-coleta-clientes/revenda/'+empresa.idusuario+'/empresa/'+empresa.idempresa+'/cliente/'+empresa.idcliente} >
                    Home
                </Link>
            <Typography color="textPrimary">Relatórios de Bilhetagem</Typography>
            </Breadcrumbs>
        </div> 
        <div className='div-form-container'>
    <Grid container  spacing={1}>
        <Table  >
            <TableHead>
                <TableCell align='center' style = {{width:"2%"}}>#</TableCell>
                <TableCell align='center' style = {{width:"5%"}}>Chamado</TableCell>
                <TableCell align='center' style = {{width:"10%"}}>Abertura</TableCell>
                <TableCell align='left' style = {{width:"33%"}}>Equipamento</TableCell>
                <TableCell align='center' style = {{width:"10%"}}>Setor</TableCell>                       
                <TableCell align='center' style = {{width:"10%"}}>Abertura</TableCell>
                <TableCell align='center' style = {{width:"10%"}}>Status</TableCell>
                
            </TableHead>
            <TableBody>
                {
                    chamados && chamados.map(function(chamado, i){
                        return <TableRow key ={i}>
                        <TableCell align='center' style = {{width:"2%"}}></TableCell>
                        
                        <TableCell align='center' style = {{width:"5%"}}><Typography variant='subtitle2' color='primary'>{chamado.idchamadotecnico}</Typography></TableCell>
                        <TableCell align='center' style = {{width:"10%"}}><Typography variant='subtitle2' color='primary'>{chamado.dataabertura}</Typography></TableCell>
                        <TableCell align='left' style = {{width:"33%"}}>
                            <Typography variant='subtitle1' color='primary' className='texto-limitado'><strong>{chamado.modelo}</strong></Typography>
                            <Typography variant='caption' color='primary'><b>Série: </b>{chamado.numeroserie}</Typography><br/>
                            <Typography variant='caption' color='primary'><b>Placa: </b>{chamado.codigoadicional}</Typography><br/>
                            
                        </TableCell>
                        <TableCell align='center' style = {{width:"10%"}}><Typography variant='subtitle2' color='primary'>{chamado.setor}</Typography></TableCell>
                                     
                        <TableCell align='center' style = {{width:"10%"}}>
                            {
                                
                                renderTipoAbertura( chamado.tipoabertura )
                            }
                        </TableCell>
                        <TableCell align='center' style = {{width:"10%"}}>
                            {
                                renderSituacao( chamado.idsituacaochamadotecnico )
                            }
                        </TableCell>
                        
                    </TableRow>
                    })
                }
            </TableBody>

    
           
              
        </Table>
    </Grid>
    </div>
    
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroChamadosTecnicos)