import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField, Container,   Button,Divider, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import TextFieldCNPJ from '../../components/TextFieldCNPJ'
import TextFieldTelefone from '../../components/TextFieldTelefone'
import TextFieldCelular from '../../components/TextFieldCelular'
import SearchIcon from '@material-ui/icons/Search';
import Backdrop from '@material-ui/core/Backdrop';



const FormNovaEmpresa = (props) =>{
    const [loading, setLoading] = useState(false)
    const [cnpj, setCnpj] = useState('')
    const [inscEstadual, setInscEstadual] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')
    const [nomeFantasia, setNomeFantasia] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade, setCidade]  = useState('')
    const [estado, setEstado] = useState('')
    const [telefoneComercial, setTelefoneComercial] = useState('')
    const [telefoneCelular, setTelefoneCelular] = useState('')
    const [email, setEmail] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude]= useState('')

  

   

    useEffect(()=>{
        if(props.mode=='edicao'){
            setCnpj(props.edicaoEmpresa.cpf_cnpj)
            setInscEstadual(props.edicaoEmpresa.rg_insc)
            setRazaoSocial(props.edicaoEmpresa.razaosocial)
            setNomeFantasia(props.edicaoEmpresa.nomefantasia)
            setCep(props.edicaoEmpresa.cep)
            setEndereco(props.edicaoEmpresa.logradouro)
            setNumero(props.edicaoEmpresa.numero)
            setComplemento(props.edicaoEmpresa.complemento)
            setBairro(props.edicaoEmpresa.bairro)
            setCidade(props.edicaoEmpresa.cidade)
            setEstado(props.edicaoEmpresa.estado)
            setTelefoneComercial(props.edicaoEmpresa.telefonecomercial)
            setTelefoneCelular(props.edicaoEmpresa.telefonecelular)
            setEmail(props.edicaoEmpresa.email)
            setLatitude(props.edicaoEmpresa.latitude)
            setLongitude(props.edicaoEmpresa.longitude)
        }
    }, [props.edicaoEmpresa]);
    const cadastrarEmpresa = (event) =>{
        event.preventDefault();
        Swal.fire({
            icon : 'info',
            text :'Deseja Gravar as alterações ?',                  
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText : 'Cancelar', 
            allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                Conexao.post('empresasController/'+(props.mode =='cadastro' ? 'insertEmpresa' : 'updateEmpresa'),{
                    idusuario : props.revenda, 
                    idempresa : props.edicaoEmpresa.idempresa ? props.edicaoEmpresa.idempresa : -1,
                    cnpj, 
                    inscEstadual, 
                    razaoSocial, 
                    nomeFantasia, 
                    cep, 
                    endereco, 
                    numero, 
                    complemento, 
                    bairro, 
                    cidade, 
                    estado, 
                    telefoneComercial, 
                    telefoneCelular, 
                    email, 
                    latitude, 
                    longitude
                }).then( response =>{
                    setLoading(false)
                    if(response.status == 200){
                        if(response.data.status == 'OK'){
                            Swal.fire({
                                text:'Empresa adicionada com sucesso !', 
                                icon :'success', 
                                timer : 1500, 
                                showCancelButton:false, 
                                showConfirmButton:false
                            })
                            
                        }else{
                            Swal.fire({
                                text:'Não foi possivel adicionar !', 
                                icon :'warning', 
                                timer : 1500, 
                                showCancelButton:false, 
                                showConfirmButton:false
                            })
                        }
                    }else{
                        Swal.fire({
                            text:'Não foi possivel adicionar !', 
                            icon :'warning', 
                            timer : 1500, 
                            showCancelButton:false, 
                            showConfirmButton:false
                        })
                    }
                    
                    props.updateEmpresas()
                }).catch( error =>{
                    Swal.fire({
                        text:'Não foi possivel adicionar !', 
                        icon :'error', 
                        timer : 1500, 
                        showCancelButton:false, 
                        showConfirmButton:false
                    })
                    setLoading(false)

                    props.updateEmpresas()
                })
               
            } 
              
          })
    }

  
    return <Container style ={{width : '700px', paddingLeft : 30, paddingRight : 30}}>
    <form id='form-cadastro' onKeyDown={(e)=>{
        if(e.keyCode == 13){
            return
        }
    }} onSubmit = {(event)=>{
        cadastrarEmpresa(event)
    }}>

        <Grid container>
            <Grid item xs = {3}>CNPJ</Grid>
            <Grid item xs = {4}>
                <TextFieldCNPJ
              
                    variant ='outlined'
                    fullWidth
                    required
                    value={cnpj}
                    onChange = {(e)=>setCnpj(e.target.value)}
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}>Insc. Est.</Grid>
            <Grid item xs = {4}>
                <TextField
                    
                    variant ='outlined'
                    value={inscEstadual}
                    onChange={(e)=>setInscEstadual(e.target.value)}
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}><Typography style = {{fontWeight : 'bold'}}>Razão Social *</Typography></Grid>
            <Grid item xs = {9}>
                <TextField
                
                    value={razaoSocial}
                    onChange = {(e)=>setRazaoSocial(e.target.value)}
                    required
                    variant ='outlined'
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}><Typography style = {{fontWeight : 'bold'}}>Nome Fantasia *</Typography></Grid>
            <Grid item xs = {9}>
                <TextField
                    required
                    value={nomeFantasia}
                    onChange = {(e)=>setNomeFantasia(e.target.value)}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}>CEP</Grid>
            <Grid item xs = {3}>
                <TextField
                
                    variant ='outlined'
                    fullWidth
                    value ={cep}
                    onChange={(e)=>setCep(e.target.value)}
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    onKeyDown ={(e)=>{
                        if(e.keyCode == 13){
                            e.preventDefault();                        
                        }
                    }}
                    onKeyUp = {(e)=>{
                        if(e.keyCode == 13){
                            ConexaoB.get('https://viacep.com.br/ws/'+cep+'/json/ ').then( response =>{                             
                                if(!response.data.erro && response.data){
                                    setEndereco(response.data.logradouro)
                                    setComplemento(response.data.complemento)
                                    setBairro(response.data.bairro)
                                    setCidade(response.data.localidade)
                                    setEstado(response.data.uf)
                                }else{
                                    setEndereco('')
                                    setComplemento('')
                                    setBairro('')
                                    setCidade('')
                                    setEstado('')
                                    Swal.fire({
                                        text:'Não existe endereço vinculado ao CEP informado !', 
                                        icon :'warning', 
                                    })
                                }
                                
                                }).catch(error =>{
                                    Swal.fire({
                                        text:'Não foi possível estabelecer conexão com serviço remoto', 
                                        icon :'error', 
                                    })
                                })
                        }
                    }}
                    
                
                    />
            </Grid>
        </Grid>
    

        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}>Endereço</Grid>
            <Grid item xs = {5}>
                <TextField
                    
                    variant ='outlined'
                    fullWidth
                    value ={endereco}
                    onChange={(e)=>setEndereco(e.target.value)}
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
            
            
        </Grid>
        
        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}>Número</Grid>
            <Grid item xs = {2}>
                <TextField
                    
                    variant ='outlined'
                    value ={numero}
                    onChange={(e)=>setNumero(e.target.value)}
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>


        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}>Complemento</Grid>
            <Grid item xs = {6}>
                <TextField
                    
                    value ={complemento}
                    onChange={(e)=>setComplemento(e.target.value)}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}>Bairro</Grid>
            <Grid item xs = {6}>
                <TextField
                    
                    value ={bairro}
                    onChange={(e)=>setBairro(e.target.value)}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}><Typography style = {{fontWeight : 'bold'}}>Cidade *</Typography></Grid>
            <Grid item xs = {9}>
                <TextField
                    
                    value ={cidade}
                    onChange={(e)=>setCidade(e.target.value)}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}><Typography style = {{fontWeight : 'bold'}}>Estado *</Typography></Grid>
            <Grid item xs = {1}>
                <TextField
                    
                    value ={estado}
                onChange={(e)=>setEstado(e.target.value)}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>


        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}>Telefone</Grid>
            <Grid item xs = {3}>
                <TextFieldTelefone
                     value={telefoneComercial}
                     onChange = {(e)=>setTelefoneComercial(e.target.value)}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>
        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}>Celular</Grid>
            <Grid item xs = {3}>
                <TextFieldCelular
                     value={telefoneCelular}
                     onChange = {(e)=>setTelefoneCelular(e.target.value)}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}><Typography style = {{fontWeight : 'bold'}}>Email *</Typography></Grid>
            <Grid item xs = {9}>
                <TextField
                    required
                    type='email'
                    value={email}
                    onChange = {(e)=>setEmail(e.target.value)}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>
        {/*<Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}>Latitude</Grid>
            <Grid item xs = {3}>
                <TextField
                
                    value={latitude}
                    onChange = {(e)=>setLatitude(e.target.value)}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
        </Grid>

        <Grid container spacing = {1} style={{marginTop : 5}}>
            <Grid item xs = {3}>Longitude</Grid>
            <Grid item xs = {3}>
                <TextField
                    
                    value={longitude}
                    onChange = {(e)=>setLongitude(e.target.value)}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps = {{shrink :true}}
                    size='small'
                    />
            </Grid>
                </Grid>*/}

    <Grid container spacing = {1} style = {{marginTop : 10}}>
        <Grid item xs = {8}>

        </Grid>
        <Grid item xs = {2} >
        <Button  color ='secondary' variant='contained' fullWidth size='large' onClick = {()=>props.updateEmpresas()}>Cancelar</Button>
        </Grid>
        <Grid item xs = {2} >
            <Button type= 'submit' color ='primary' variant='contained' fullWidth size='large'>Gravar</Button>
        </Grid>
    </Grid>
    
    </form>
     <Backdrop  open={loading}  className='sobreposicaoMaxima'>
    <CircularProgress color="inherit" />
    </Backdrop>
    </Container>
}

export default withRouter(FormNovaEmpresa)