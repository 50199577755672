import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Grid, TextField, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import Conexao from '../../providers/Conexao'
import PowerSettingsNewTwoToneIcon from '@material-ui/icons/PowerSettingsNewTwoTone';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import ModalNovoItemColeta from './ModalNovoItemColeta'
import Swal from 'sweetalert2'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ModalEdicaoParametros from './ModalEdicaoItemColeta'
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

let contador = -1;
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalListaItemColeta(props) {
  const [open, setOpen] = React.useState(false);
  const [parametros, setParametros] = React.useState([]);
  const [parametro, setParametro] = React.useState([]);


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setParametros([])
    props.getData();
    props.closeModal()
  };

  React.useEffect(()=>{
    if(props.modelo.idmodelo > 0){
        getListaParametros() 
    }
       
  },[props.modelo.idmodelo])


  const getListaParametros = () =>{
      Conexao.get('parametrosController/getItemTabelaErro?idmodelo='+props.modelo.idmodelo).then(response =>{
        if(response.data.status =='OK'){
            setParametros(response.data.payload);
        }else{
            setParametros([]);
        }
      }).catch(error =>{
        setParametros([]);
      })
  }

  return (
    <div>
    
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" fullWidth maxWidth={'md'} open={props.modelo.idmodelo} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Tabela de Erros do modelo {props.modelo.descricao}
        </DialogTitle>
        <DialogContent dividers style={{height:"500px", overflow:"auto", overflowX:"hidden"}}>
          <Table>
              <TableHead>
                  <TableCell align='center' style ={{width:"5%"}}>#</TableCell>
                  <TableCell align='left' style ={{width:"50%"}}>Descrição do Erro</TableCell>
                  <TableCell align='left' style ={{width:"10%"}}>prtAlertSeverityLevel</TableCell>
                  <TableCell align='center' style ={{width:"10%"}}>prtAlertLocation</TableCell>
                  <TableCell align='center' style ={{width:"10%"}}>prtAlertCode</TableCell>
                  <TableCell align='center' style ={{width:"10%"}}>Abrir Chamado</TableCell>
                  <TableCell align='center' style ={{width:"5%"}}>Excluir</TableCell>
              </TableHead>
              <TableBody>
                  {
                      parametros && parametros.map(function(parametro, i){
                          return <TableRow key = {i}>
                            <TableCell align='center'>{(i + 1)}</TableCell>
                          <TableCell align='left' ></TableCell>
                          <TableCell align='center' ></TableCell>
                          <TableCell align='center' ></TableCell>
                          <TableCell align='center' ></TableCell>
                          <TableCell align='center' ></TableCell>
                           <TableCell align='center' > </TableCell>
                      </TableRow>
                      })
                  }
              </TableBody>
          </Table>
        </DialogContent>
        <DialogActions spacing = {5}>
        <ModalNovoItemColeta modelo={props.modelo} getData ={()=>{getListaParametros()}}/>
        <ModalEdicaoParametros modelo={props.modelo} getData ={()=>{getListaParametros()}} closeModal = {()=>setParametro([])} parametro={parametro}/>
          <Button autoFocus onClick={handleClose} color="secondary"  variant='contained'>
            Fechar
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
