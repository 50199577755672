import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {TextField, Grid} from '@material-ui/core'
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoConsumivel(props) {
  const [open, setOpen] = React.useState(false);
  const [descricao, setDescricao] = React.useState('');
  const [observacoes, setObservacoes] = React.useState('');


  React.useEffect(()=>{
    if(open){
      setDescricao(props.consumivelEdicao ? props.consumivelEdicao.descricao : '')
      setObservacoes(props.consumivelEdicao ? props.consumivelEdicao.observacoes :'')
    }
  }, [open])

  React.useEffect(()=>{
    if(props.consumivelEdicao){
   
    
     setOpen(true)
    }
  }, [props.consumivelEdicao])


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.limparEdicao()
    setOpen(false);
  };

  const insertConsumivel = () =>{
    Conexao.post('consumiveisController/'+ (props.consumivelEdicao ? 'updateConsumivel' : 'insertConsumivel'), {
      idusuario : props.idUsuario, 
      descricao : descricao , 
      observacoes : observacoes, 
      idconsumivel : props.consumivelEdicao ? props.consumivelEdicao.idconsumivel : -1
    }).then(response=>{
      if(response.data.status =='OK'){
        Swal.fire({
          title :'Gestão de Equipamentos', 
          text :'Consumível cadastrado/atualizado com sucesso !', 
          icon:'success', 
          showCancelButton : false,
          showConfirmButton : false, 
          timer : 1000, 
          onClose : ()=>{
            props.updateData();
            setOpen(false)
          }
        })
      }else{
        Swal.fire({
          title :'Gestão de Equipamentos', 
          text :'Não foi possível cadastrar/atualizar !', 
          icon:'error', 
          showCancelButton : false,
          showConfirmButton : false, 
          timer : 1000, 
          onClose : ()=>{
           
            setOpen(false)
          }
        })
      }

    }).catch(error =>{
      Swal.fire({
        title :'Gestão de Equipamentos', 
        text :'Não foi possível cadastrar/atualizar !', 
        icon:'error', 
        showCancelButton : false,
        showConfirmButton : false, 
        timer : 1000, 
        onClose : ()=>{
         
          setOpen(false)
        }
      })
    })
  }

  return (
    <div>
      <div className='fabIncluir'>
        <Fab size="large" color="primary" aria-label="add" onClick={()=>{
          props.limparEdicao()
          setOpen(true)}} >
          <AddIcon />
        </Fab>
      </div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth='sm' fullWidth disableBackdropClick disableEscapeKeyDown hideBackdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cadastro Novo Consumível
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs = {12} spacing={2}>
              <TextField
                label='Descrição'
                variant='outlined'
                InputLabelProps={{shrink : true}}
                fullWidth
                value={descricao}
                onChange={(e)=>setDescricao(e.target.value)} />
            </Grid>
            <br/>
            <br/>
            <Grid item xs = {12} spacing={2} style ={{marginTop:"10px"}}>
              <TextField
                label='Observações'
                variant='outlined'
                InputLabelProps={{shrink : true}}
                multiline
                rows={5}
                rowsMax={5}
                fullWidth 
                value={observacoes}
                onChange={(e)=>setObservacoes(e.target.value)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus onClick={()=>{
            Swal.fire({
              title :'Gestão Equipamentos', 
              text :'Deseja cadastrar este consumível agora ?', 
              icon : 'question',
              showCancelButton : true, 
              cancelButtonText : 'Cancelar', 
              showConfirmButton : true, 
              confirmButtonText : 'Confirmar'
            }).then(resposta =>{
              if(resposta.isConfirmed){
                insertConsumivel();
              }
            })
          }} color="primary" variant='contained'>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
