import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../../providers/Conexao'
import Chip from '@material-ui/core/Chip';
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'

import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../../../components/ModalFull'
import ModalDetalhes from './ModalEditarUsuario'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ComputerIcon from '@material-ui/icons/Computer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../../../components/LabelData'
import {Tooltip} from '@material-ui/core'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import Template from '../../Template/Template';


const PrintLoggerUsuarios = (props)=>{
   
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [cliente, setCliente] = React.useState(props.location.state.cliente)
  
    const [usuarios, setUsuarios] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [registros, setRegistros]= React.useState(0);

    const refDataInicial = React.useRef();
    const refDataFinal = React.useRef();
    const refNomeFila = React.useRef();
    const refHostName = React.useRef();   
    const refUserName = React.useRef();
    const refNomeDocumento = React.useRef();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(()=>{
        getUsuarios()
       // console.log(props.location.state.empresa.idcliente)
        
    },[]);
    useEffect(()=>{
       // getImpressoes()
    },[page,rowsPerPage ]);

    const getUsuarios = () =>{
        setLoading(true)
        Conexao.post('userPrinterController/getUsuarios',{
            idcliente: cliente.idcliente,
            idusuario: cliente.idusuario,
            idempresa: cliente.idempresa,        
           
           

        }).then(response=>{
            if(response.data.status =='OK'){
               //console.log(response.data.payload)
               setUsuarios(response.data.payload);
              
                
            }else{
                setUsuarios([])
              
            }
            setLoading(false)
        }).catch(error=>{setLoading(false)});
    }


  




  

    return <Template atualizar = {loading} cliente={props.location.state.cliente} breadcrumbs ={
        <Breadcrumbs aria-label="breadcrumb">  
            <Typography>Você esta em:</Typography>          
                <Link color="inherit" to={'/webclient/home'} >
                    Home
                </Link>   
                <Typography>PRINTLOGGER - Usuários</Typography>       
            </Breadcrumbs>
    }>
        <div style={{paddingLeft : '40px', paddingRight : '40px', paddingTop : '60px'}}>
        <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>PRINTLOGGER - Controle de Usuários</Typography></Grid>            
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
       
        <Grid container spacing={2}>
          
            <Grid item xs={5}>
                <TextField
                    
                    label='Nome'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                >
                   

                </TextField>
            </Grid>


            <Grid item xs = {5}>
                <TextField
                    
                    label ='Username'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}               
                    size ='small'
                    />                   
            </Grid>

            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' ><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained'  ><ClearIcon/> Limpar</Button>
            </Grid>
            
        </Grid>


   
       
       <div className='div-form-container'>

        <Table className='table-small' style = {{fontSize:"14px", color:"#444"}}>
            <TableHead>
                <TableCell style={{width:"15%", fontSize:"14px"}}>Username</TableCell>
                <TableCell align='center' style={{width:"15%", fontSize:"14px"}}>Nome Completo</TableCell>
                <TableCell align='center' style={{width:"20%", fontSize:"14px"}}>E-mail</TableCell>
                <TableCell align='center' style={{width:"20%", fontSize:"14px"}}>Domínio</TableCell>
                <TableCell align='center' style={{width:"10%", fontSize:"14px"}}>Dep.</TableCell>
                <TableCell align='center' style={{width:"15%", fontSize:"14px"}}>Centro Custo</TableCell>
                <TableCell  align='center' style={{width:"5%", fontSize:"14px"}}>PIN</TableCell>
                <TableCell  align='center' style={{width:"5%", fontSize:"14px"}}>Alterar</TableCell>
             
            </TableHead>
            <TableBody>
               
            { usuarios && usuarios.map(function (usuario, i) {
                        return <TableRow key={i}>
                        <TableCell   style={{ fontSize:"14px"}}><Typography variant='caption' color={'primary'}>{usuario.username}</Typography></TableCell>                        
                        
                        <TableCell align='center' style={{ fontSize:"12px"}}>
                        <Typography  variant='caption' color={'primary'}>{usuario.nomecompleto}</Typography><br/>
                                               
                        </TableCell>
                        
            <TableCell align='center' style={{ fontSize:"14px"}}><Typography variant='caption' color={'primary'}>{usuario.email}</Typography></TableCell>
                       
                        <TableCell align='center' style={{fontSize:"14px"}}><Typography variant='caption' color={'primary'}>{usuario.dominio}</Typography><br/>
                        
                                   
                        </TableCell>
                        <TableCell align='center' style={{fontSize:"14px"}}><Typography variant='caption' color={'primary'}>{usuario.departamento}</Typography></TableCell>
                        <TableCell align='center' style={{fontSize:"14px"}}><Typography variant='caption' color={'primary'}>{usuario.centrocusto}</Typography></TableCell>
                        
                        <TableCell  align='center' style={{ fontSize:"14px"}}>{
                            usuario.pincode ? '***' : <SettingsIcon titleAccess="Gerar PIN" style={{cursor: "pointer"}} color='primary' onClick ={()=>{
                                Swal.fire({
                                    title : 'Printerconnect',
                                    icon : 'question', 
                                    text : 'Deseja gerar um código PIN ?', 
                                    showCancelButton : true, 
                                    showConfirmButton : true, 
                         
                                  
                                    }).then((resposta)=>{
                                        if(resposta.isConfirmed){
                                            Conexao.post('userPrinterController/gerarPinCode',{
                                                idcliente: cliente.idcliente,
                                                idusuario: usuario.idusuario,
                                                idempresa: cliente.idempresa,    
                                                
                                            }).then(response=>{
                                                if(response.data.status =='OK'){
                                                   //console.log(response.data.payload)
                                                  getUsuarios()
                                                  
                                                    
                                                }else{
                                                 
                                                  
                                                }
                                                setLoading(false)
                                            }).catch(error=>{setLoading(false)});
                                 
                                        }
                                    })
                            }}/> 
                        }</TableCell>
                        
                        <TableCell  align='center' style={{ fontSize:"14px"}}><ModalDetalhes titulo={'Alterar Usuário'} usuario ={usuario} empresa={cliente} atualizar={()=>{getUsuarios()}}/></TableCell>
                        
                    </TableRow >
                
            })
        }
            </TableBody>
        </Table>


       </div>
       </div>
       </Template>

}


export default withRouter(PrintLoggerUsuarios)

