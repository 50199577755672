import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField, InputAdornment} from '@material-ui/core'
import Swal from 'sweetalert2'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import axios from 'axios'


function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[~/\d/,/\d/, /\d/, /\d/,/\d/,',',/\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }

  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };


const TextFieldCep = (props) =>{
   

   
    return <TextField 
            {...props} 
            InputProps={{
             inputComponent : TextMaskCustom    
            }}  
            
            inputProps={{
                size :'10'
            }}
           
            onKeyUp = {(e)=>{
                if(e.keyCode == 13){
                    axios.get('https://viacep.com.br/ws/'+e.target.value+'/json/ ').then( response =>{ 
                                              
                            if(!response.data.erro && response.data){
                              
                              if(props.retornoCep){
                            
                                props.retornoCep(response.data)
                              }
                               
                            }else{                                
                                Swal.fire({
                                    text:'Não existe endereço vinculado ao CEP informado !', 
                                    icon :'warning', 
                                })
                                if(props.retornoCep){
                                props.retornoCep([])
                              }
                            }
                            
                        }).catch(error =>{
                            Swal.fire({
                                text:'Não foi possível localizar o CEP informado !', 
                                icon :'error', 
                            })
                            if(props.TextFieldCEP){
                                props.retornoCep([])
                              }
                        })
                }
            }}                            
            />
}

export default TextFieldCep


