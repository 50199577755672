import React, {Fragment, useEffect, useState, useRef} from 'react'
import {withRouter} from 'react-router-dom'

import {Grid, TextField, Tooltip, Divider, InputAdornment, Badge} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Breadcrumbs} from '@material-ui/core'
import {Link , useHistory } from 'react-router-dom'

//icones
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PieChartIcon from '@material-ui/icons/PieChart';
import EcoIcon from '@material-ui/icons/Eco';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Template from '../../Template/Template';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const refDataInicial = React.useRef()
  const refDataFinal = React.useRef()
  const refComputador = React.useRef()
  const refImpressora = React.useRef()
  const refUsuario = React.useRef()
  const refDetalhado = React.useRef()
  const refTipoSaida = React.useRef()
  


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
  props.close()
  };

  const gerarLink=()=>{
    switch (props.nomeRelatorio){
      case 'Usuário':        
        window.open('https://www.printerconnect.com.br/api/index.php/relatoriosBillingController/impressoesByUserName?idempresa='+props.empresa.idempresa+'&idcliente='+props.empresa.idcliente+'&hostname='+(refComputador.current?refComputador.current.value:'')+'&nomefila='+(refImpressora.current?refImpressora.current.value:'')+'&username='+(refUsuario.current?refUsuario.current.value:'')+'&datainicial='+(refDataInicial.current?refDataInicial.current.value:'')+'&datafinal='+(refDataFinal.current?refDataFinal.current.value:'')+'&detalhes='+(refDetalhado.current?refDetalhado.current.value:''),'_blank');
        break; // relatorio por usuario -> link correto -> OK//
    }

    switch (props.nomeRelatorio){
      case 'TOP 5 Usuários':
        window.open('https://www.printerconnect.com.br/api/index.php/relatoriosBillingController/impressoesByUserName?idempresa='+props.empresa.idempresa+'&idcliente='+props.empresa.idcliente+'&hostname='+(refComputador.current?refComputador.current.value:'')+'&nomefila='+(refImpressora.current?refImpressora.current.value:'')+'&username='+(refUsuario.current?refUsuario.current.value:'')+'&datainicial='+(refDataInicial.current?refDataInicial.current.value:'')+'&datafinal='+(refDataFinal.current?refDataFinal.current.value:'')+'&detalhes='+(refDetalhado.current?refDetalhado.current.value:'')+'&topfive=T','_blank');
        break; // relatorio top5 usuarios  -> link correto -> OK//
    }

    switch (props.nomeRelatorio){    
      case 'TOP 5 Impressoras':    
        window.open('https://www.printerconnect.com.br/api/index.php/relatoriosBillingController/topFiveImpressoras?idempresa='+props.empresa.idempresa+'&idcliente='+props.empresa.idcliente+'&hostname='+(refComputador.current?refComputador.current.value:'')+'&nomefila='+(refImpressora.current?refImpressora.current.value:'')+'&username='+(refUsuario.current?refUsuario.current.value:'')+'&datainicial='+(refDataInicial.current?refDataInicial.current.value:'')+'&datafinal='+(refDataFinal.current?refDataFinal.current.value:'')+'&detalhes='+(refDetalhado.current?refDetalhado.current.value:'')+'&topfive=T','_blank');
        break; //relatorio top5 impressoras -> link correto -> OK//        
    }  

    switch (props.nomeRelatorio){    
      case 'Computador':               
        window.open('https://www.printerconnect.com.br/api/index.php/relatoriosBillingController/impressoesByHostName?idempresa='+props.empresa.idempresa+'&idcliente='+props.empresa.idcliente+'&hostname='+(refComputador.current?refComputador.current.value:'')+'&nomefila='+(refImpressora.current?refImpressora.current.value:'')+'&username='+(refUsuario.current?refUsuario.current.value:'')+'&datainicial='+(refDataInicial.current?refDataInicial.current.value:'')+'&datafinal='+(refDataFinal.current?refDataFinal.current.value:'')+'&detalhes='+(refDetalhado.current?refDetalhado.current.value:''),'_blank');
        break; // relatorio por computador -> link correto -> OK //        
    }  

    switch (props.nomeRelatorio){    
      case 'Impressora':
        
        if(refTipoSaida && refTipoSaida.current.value =='csv'){
          window.open('https://www.printerconnect.com.br/api/index.php/relatoriosBillingController/impressoesByFilaCsv?idempresa='+props.empresa.idempresa+'&idcliente='+props.empresa.idcliente+'&hostname='+(refComputador.current?refComputador.current.value:'')+'&nomefila='+(refImpressora.current?refImpressora.current.value:'')+'&username='+(refUsuario.current?refUsuario.current.value:'')+'&datainicial='+(refDataInicial.current?refDataInicial.current.value:'')+'&datafinal='+(refDataFinal.current?refDataFinal.current.value:'')+'&detalhes='+(refDetalhado.current?refDetalhado.current.value:'')+'&tipo='+(refTipoSaida.current?refTipoSaida.current.value:''),'_blank');
        }else{
          window.open('https://www.printerconnect.com.br/api/index.php/relatoriosBillingController/impressoesByFila?idempresa='+props.empresa.idempresa+'&idcliente='+props.empresa.idcliente+'&hostname='+(refComputador.current?refComputador.current.value:'')+'&nomefila='+(refImpressora.current?refImpressora.current.value:'')+'&username='+(refUsuario.current?refUsuario.current.value:'')+'&datainicial='+(refDataInicial.current?refDataInicial.current.value:'')+'&datafinal='+(refDataFinal.current?refDataFinal.current.value:'')+'&detalhes='+(refDetalhado.current?refDetalhado.current.value:'')+'&tipo='+(refTipoSaida.current?refTipoSaida.current.value:''),'_blank');
        }
        
        break; // relatorio por impressora -> link correto -> OK //
        
    }  
    switch (props.nomeRelatorio){    
      case 'Impacto Ambiental':    
        window.open('https://www.printerconnect.com.br/api/index.php/relatoriosBillingController/impactoAmbiental?idempresa='+props.empresa.idempresa+'&idcliente='+props.empresa.idcliente+'&hostname='+(refComputador.current?refComputador.current.value:'')+'&nomefila='+(refImpressora.current?refImpressora.current.value:'')+'&username='+(refUsuario.current?refUsuario.current.value:'')+'&datainicial='+(refDataInicial.current?refDataInicial.current.value:'')+'&datafinal='+(refDataFinal.current?refDataFinal.current.value:'')+'&detalhes='+(refDetalhado.current?refDetalhado.current.value:''),'_blank');
        break; // relatorio de impacto ambiental -> link correto -> OK//
        
    }  
    switch (props.nomeRelatorio){    
      case 'Centro de Custo':    
      // window.open('https://www.printerconnect.com.br/api/index.php/relatoriosBillingController/relatorioCentroCustoByUser?idempresa='+props.empresa.idempresa+'&idcliente='+props.empresa.idcliente+'&datainicial='+(refDataInicial.current?refDataInicial.current.value:'')+'&datafinal='+(refDataFinal.current?refDataFinal.current.value:'')+'&detalhes='+(refDetalhado.current?refDetalhado.current.value:''),'_blank');
        break; // LINK OK
        
    }  
    switch (props.nomeRelatorio){    
      case 'Departamento':    
      // window.open('https://www.printerconnect.com.br/api/index.php/relatoriosBillingController/relatorioDepartamentoByUser?idempresa='+props.empresa.idempresa+'&idcliente='+props.empresa.idcliente+'&datainicial='+(refDataInicial.current?refDataInicial.current.value:'')+'&datafinal='+(refDataFinal.current?refDataFinal.current.value:'')+'&detalhes='+(refDetalhado.current?refDetalhado.current.value:''),'_blank');
        break; // Link OK
        
    }  
    switch (props.nomeRelatorio){    
      case 'TOP 5 Departamentos':    
      // window.open('https://www.printerconnect.com.br/api/index.php/relatoriosBillingController/relatorioDepartamentoByUser?idempresa='+props.empresa.idempresa+'&idcliente='+props.empresa.idcliente+'&datainicial='+(refDataInicial.current?refDataInicial.current.value:'')+'&datafinal='+(refDataFinal.current?refDataFinal.current.value:'')+'&detalhes='+(refDetalhado.current?refDetalhado.current.value:'')+'&topfive=T', '_blank');
        break; // Link OK
        
    }  
    switch (props.nomeRelatorio){    
      case 'TOP 5 Centro de Custo':    
      //  window.open('https://www.printerconnect.com.br/api/index.php/relatoriosBillingController/relatorioCentroCustoByUser?idempresa='+props.empresa.idempresa+'&idcliente='+props.empresa.idcliente+'&datainicial='+(refDataInicial.current?refDataInicial.current.value:'')+'&datafinal='+(refDataFinal.current?refDataFinal.current.value:'')+'&detalhes='+(refDetalhado.current?refDetalhado.current.value:'')+'&topfive=T','_blank');
      
        break; //  LINK OK 
    }  

  } 

  return (
    <div>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open} hideBackdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.nomeRelatorio}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>

            <Grid item xs={6}>
              <TextField
                inputRef={refDataInicial}
                label='Data Incial'
                variant='outlined'
                fullWidth
                size='small'
                InputLabelProps={{ shrink: true }}
                type='date'


              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={refDataFinal}
                label='Data Final'
                variant='outlined'
                fullWidth
                size='small'
                InputLabelProps={{ shrink: true }}
                type='date'


              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                inputRef={refComputador}
                size={'small'}
                label='Nome do Computador'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}


              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                inputRef={refImpressora}
                size={'small'}
                label='Nome da Impressora'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}


              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                inputRef={refUsuario}
                size={'small'}
                label='Nome do Usuário'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}


              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                select
                SelectProps={{ native: true }}
                inputRef={refDetalhado}
                size={'small'}
                label='Detalhado'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}>
                <option value='F'>NÃO</option>
                <option value='T'>SIM</option>
              </TextField>

            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled = {props.nomeRelatorio != 'Impressora'}
                select
                SelectProps={{ native: true }}
                inputRef={refTipoSaida}
                size={'small'}
                label='Saída do Relatório'
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}>
                <option value='html'>Padrão</option>
                <option value='csv'>CSV-Excel</option>
              </TextField>

            </Grid>
            <Grid item xs={12}>
              <Button color='primary' variant='contained' fullWidth onClick={() => { gerarLink() }}><SearchIcon /> Gerar relatório</Button>

            </Grid>
          </Grid>
          <br />


        </DialogContent>
        <DialogActions>

        </DialogActions>
      </Dialog>
    </div>
  );
}

const PrintLoggerRelatorios = (props) =>{
    
    const [cliente, setEmpresa] = React.useState(props.location.state.cliente)
    const [meses, setMeses] = useState(1)
    const refReferencia = useRef();
    const [open, setOpen] = React.useState(false)
    const [nomeBotao, setNomeBotao] = React.useState('')
    let history = useHistory();


    useEffect(()=>{
     // console.log(props.location.state)
    },[])

    return <Template cliente={props.location.state.cliente} breadcrumbs ={
      <Breadcrumbs aria-label="breadcrumb">  
          <Typography>Você esta em:</Typography>          
              <Link color="inherit" to={'/webclient/home'} >
                  Home
              </Link>   
              <Typography>PRINTLOGGER - Relatórios de Bilhetagem</Typography>       
          </Breadcrumbs>
  }>


           
          
          
            <div style={{paddingLeft : '40px', paddingRight : '40px', paddingTop : '60px'}}>
   
            <p style = {{fontSize:"14px",  padding:"0px", color:'tomato'}}>
                      * Relatórios sem data selecionada = 7 últimos dias. </p>    
            {cliente.idcliente &&
            <Grid container spacing={1} style={{marginTop:'50px'}}>
                <Grid item xs ={2}>
                   
                  <div className='div-container-btn-relatorio'>
                      <Typography variant='subtitle1'>Por Impressora</Typography>
              
                      <div style ={{marginTop:"20px" }} onClick={()=>{
                        setOpen(true)
                        setNomeBotao('Impressora')}}>
                          <div style = {{textAlign:'center'}}>                        
                            <Tooltip title='Gerar relatório por impressora (fila de impressão)'> 
                            <PrintIcon style={{fontSize: '90px', color:'#6D4C41'}}  />
                          </Tooltip>                          
                          </div>                       
                      </div>
                  
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                          * Click para adicionar detalhes no relatório!
                      </p>
                  </div>  

                </Grid>
                <Grid item xs ={2}>
                   
                   <div className='div-container-btn-relatorio'>
                       <Typography variant='subtitle1'>Por Centro Custo</Typography>
               
                       <div style ={{marginTop:"20px" }} onClick={()=>{
                         setOpen(true)
                         setNomeBotao('Centro de Custo')}}>
                           <div style = {{textAlign:'center'}}>                        
                             <Tooltip title='Gerar relatório por Centro de Custo'> 
                             <Badge badgeContent = 'Breve' color='secondary'><MonetizationOnIcon style={{fontSize: '90px', color:'#6D4C41'}}  /> </Badge>
                           </Tooltip>                          
                           </div>                       
                       </div>
                   
                       <p style = {{fontSize:"9px",  padding:"0px"}}>
                           * Click para adicionar detalhes no relatório!
                       </p>
                   </div>  
 
                 </Grid>
                 <Grid item xs ={2}>
                   
                   <div className='div-container-btn-relatorio'>
                       <Typography variant='subtitle1'>Por Departamento</Typography>
               
                       <div style ={{marginTop:"20px" }} onClick={()=>{
                         setOpen(true)
                         setNomeBotao('Departamento')}}>
                           <div style = {{textAlign:'center'}}>                        
                             <Tooltip title='Gerar relatório por Departamento'> 
                             <Badge badgeContent = 'Breve' color='secondary'><AccountBalanceIcon style={{fontSize: '90px', color:'#6D4C41'}}  /> </Badge>
                           </Tooltip>                          
                           </div>                       
                       </div>
                   
                       <p style = {{fontSize:"9px",  padding:"0px"}}>
                           * Click para adicionar detalhes no relatório!
                       </p>
                   </div>  
 
                 </Grid>
                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio'  onClick={()=>{
                        setOpen(true)
                        setNomeBotao('Usuário')}}>
                      <Typography variant='subtitle1'>Por Usuário</Typography>
                 
                      <div style ={{marginTop:"20px" }} >
                          <div style = {{textAlign:'center'}}>                        
                            <Tooltip title='Gerar relatório simples ou detalhado por Usuário (username)'> 
                            <PeopleAltIcon style={{fontSize: '90px', color:'#6D4C41'}}  />
                          </Tooltip>                        
                          </div>                       
                      </div>
              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                      * Click para adicionar detalhes no relatório!
                      </p>
                  </div>  
                </Grid>

                <Grid item xs ={2}>
                  <div className='div-container-btn-relatorio' onClick={()=>{
                        setOpen(true)
                        setNomeBotao('Computador')}}>
                      <Typography variant='subtitle1'> Por Computador</Typography>
                 
                      <div style ={{marginTop:"20px" }}>
                          <div style = {{textAlign:'center'}}>                        
                            <Tooltip title='Gerar relatório de impressão por computadores (hostname)'> 
                            <LaptopChromebookIcon style={{fontSize: '90px', color:'#6D4C41'}}  />
                          </Tooltip>                     
                          </div>                       
                      </div>              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                      * Click para adicionar detalhes no relatório!
                      </p>
                  </div>  
                </Grid>
                

                           
                <Grid container spacing={1}>
                
                  <Grid item xs ={2}>
                  <br/>
                  <div className='div-container-btn-relatorio'  onClick={()=>{
                        setOpen(true)
                        setNomeBotao('TOP 5 Usuários')}}>
                      <Typography variant='subtitle1'>Top 5 Usuários</Typography>                 
                      <div style ={{marginTop:"20px" }}>
                          <div style = {{textAlign:'center'}}>
                        
                            <Tooltip title='Gerar relatório TOP 5 de usuários que mais imprimiram'> 
                            <TrendingUpIcon style={{fontSize: '90px', color:'#6D4C41'}}  />
                          </Tooltip>                          
                          </div>                       
                      </div>              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                      * Clique para imprimir o relatório TOP 5 Usuários.  
                      </p>
                  </div>  

                  </Grid>
                  <Grid item xs ={2}>
                  <br/>
                  <div className='div-container-btn-relatorio'  onClick={()=>{
                        setOpen(true)
                        setNomeBotao('TOP 5 Impressoras')}}>
                      <Typography variant='subtitle1'>Top 5 Impressoras</Typography>
                 
                      <div style ={{marginTop:"20px" }}>
                          <div style = {{textAlign:'center'}}>
                        
                            <Tooltip title='Gerar relatório de TOP 5 Impressoras mais utilizadas!'> 
                            <PieChartIcon style={{fontSize: '90px', color:'#6D4C41'}}  />
                          </Tooltip>                     
                          </div>                       
                      </div>              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                       * Clique para imprimir o relatório TOP 5 impressoras.
                      </p>
                  </div>  

                  </Grid>
                  <Grid item xs ={2}>
                  <br/>
                  <div className='div-container-btn-relatorio'  onClick={()=>{
                        setOpen(true)
                        setNomeBotao('TOP 5 Centro de Custo')}}>
                      <Typography variant='subtitle1'>Top 5 Centros de Custo</Typography>
                 
                      <div style ={{marginTop:"20px" }}>
                          <div style = {{textAlign:'center'}}>
                        
                            <Tooltip title='Gerar relatório de TOP 5 Centro de Custo!'> 
                            <Badge badgeContent = 'Breve' color='secondary'>   <MonetizationOnIcon style={{fontSize: '90px', color:'#6D4C41'}}  /> </Badge>
                          </Tooltip>                     
                          </div>                       
                      </div>              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                       * Clique para imprimir o relatório TOP 5 Centros de custo.
                      </p>
                  </div>  

                  </Grid>
                  <Grid item xs ={2}>
                  <br/>
                  <div className='div-container-btn-relatorio'  onClick={()=>{
                        setOpen(true)
                        setNomeBotao('TOP 5 Departamentos')}}>
                      <Typography variant='subtitle1'>Top 5 Departamentos</Typography>
                 
                      <div style ={{marginTop:"20px" }}>
                          <div style = {{textAlign:'center'}}>
                        
                            <Tooltip title='Gerar relatório de TOP 5 Departamentos'> 
                            <Badge badgeContent = 'Breve' color='secondary'><AccountBalanceIcon style={{fontSize: '90px', color:'#6D4C41'}}  /> </Badge>
                          </Tooltip>                     
                          </div>                       
                      </div>              
                      <p style = {{fontSize:"9px",  padding:"0px"}}>
                       * Clique para imprimir o relatório TOP 5 Departamentos.
                      </p>
                  </div>  

                  </Grid>
                  <Grid item xs ={2}>
                  <br/>
                  <div className='div-container-btn-relatorio'  onClick={()=>{
                        setOpen(true)
                        setNomeBotao('Impacto Ambiental')}}>
                      <Typography variant='subtitle1'>Impacto Ambiental</Typography>                 
                      <div style ={{marginTop:"20px" }}>
                          <div style = {{textAlign:'center'}}>                        
                            <Tooltip title='Veja o IMPACTO AMBIENTAL de sua empresa'> 
                            <EcoIcon style={{fontSize: '90px', color:'#68A678'}}  />
                          </Tooltip>                        
                          </div>                       
                      </div>    
                      
                      <p style = {{fontSize:"9px",  padding:"0px", color:'tomato'}}>
                      * Relatório sem data selecionada = 7 últimos dias.                   
                      </p>
                  </div>  
                  </Grid>
                </Grid>
            </Grid>
  }
  
    <CustomizedDialogs open={open} close={()=>{
      setOpen(false)
      setNomeBotao('')}} nomeRelatorio={nomeBotao} empresa={cliente}/>

      </div>
    </Template>
   
}


export default withRouter(PrintLoggerRelatorios)