import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import LinearProgress from '@material-ui/core/LinearProgress';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const SelecionaEmpresaAtiva = (props) => {
  const [open, setOpen] = React.useState(false);
  const [empresas, setEmpresas ] = React.useState('');
  const [empresa, setEmpresa ] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    if(open){
        getEmpresas();
    }
  }, [open])

  useEffect(()=>{
    console.log(empresa)
  }, [empresa])


  const getEmpresas = () =>{
    setLoading(true)
    Conexao.get('empresasController/getEmpresasByRevenda?idRevenda='+props.idUsuario).then(function(response){
        if(response.status == 200){
            if(response.data.status == 'OK'){
                setEmpresas(response.data.payload)
            }
        }
        setLoading(false)
    }).catch(error =>{
      setLoading(false)
    });
  }

  return (
    <div>
      <Tooltip title='Selecionar uma empresa cadastrada'>
        <Button size='small'  color="secondary" className = {!props.empresaAtiva ? 'btnAlerta' : ''} onClick={handleClickOpen}>
         {props.empresaAtiva ? <WrapTextIcon /> : <ReportProblemIcon/>}
        </Button>
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Selecionar Empresa Ativa 
        </DialogTitle>
        <DialogContent dividers style = {{height:"350px", overflow:"auto"}}>
        {loading &&<LinearProgress color="secondary" />}
          {
              empresas && empresas.map(function(empresa, i){
                  return <div  className = {empresa.idusuario == empresa.idUsuario ? 'selecionado line-emp' : 'line-emp'} style ={{
                                padding:"5px", 
                                paddingLeft:"10px",
                                paddingRight:"10px", 
                                cursor:'pointer', 
                                border:"solid 1px #ccc", 
                                borderRadius:"5px", 
                                marginTop:"5px"}} onClick = {()=>{
                                    Swal.fire({
                                        icon : 'info',
                                        text :'Deseja Selecionar esta Empresa ?',                  
                                        showCancelButton: true,
                                        confirmButtonText: 'Confirmar',
                                        cancelButtonText : 'Cancelar', 
                                        allowOutsideClick: () => !Swal.isLoading()
                                        }).then((result) => {
                                        if (result.isConfirmed) {
                                           let emp = {
                                               idrevenda : empresa.idusuario, 
                                               idempresa : empresa.idempresa, 
                                               razaosocial : empresa.razaosocial, 
                                               latitude : empresa.latitude, 
                                               longitude : empresa.longitude, 
                                               ativa : empresa.ativo
                                           }
                                           sessionStorage.setItem('empresaAtiva@printerConnect',JSON.stringify(emp))
                                           props.setEmpresaAtiva(emp)
                                           props.history.push('/home/revenda/'+props.match.params.idempresa)
                                           setOpen(false)    
                                        
                                        } 
                                        
                                    })
                                }}>
                            <Typography variant='subtitle1'>{empresa.razaosocial}</Typography>
                            <Typography variant='caption'>{empresa.logradouro+', '+empresa.numero}</Typography><br/>
                            <Typography variant='caption'>{empresa.cidade+'-'+empresa.estado}</Typography>
                        </div>
              })
          }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(SelecionaEmpresaAtiva)