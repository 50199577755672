import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {TextField, Grid} from '@material-ui/core'
import Conexao from '../../../providers/Conexao'
import Swal from 'sweetalert2'



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalHabilitarMonitoramentoEventos(props) {
  const [open, setOpen] = React.useState(false);
  const [habilitado, setHabilitado] = React.useState('');
  const [monitorarConsumiveis, setMonitorarConsumiveis] = React.useState(false);
  const [monitorarAlertasServico, setMonitorarAletasServico] = React.useState(false);
  const [nivelAmarelo, setNivelAmarelo] = React.useState(false);
  const [nivelPreto, setNivelPreto] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(()=>{
    if(open){
      setHabilitado(props.licenca.monitorareventos ? props.licenca.monitorareventos : 'f');
      setMonitorarConsumiveis(props.licenca.alertasparts ? props.licenca.alertasparts : 'f');
      setMonitorarAletasServico(props.licenca.alertasmanutencao ? props.licenca.alertasmanutencao : 'f');
    }
  }, [open])


  const updateData = () =>{
    Conexao.post('dcaController/updateMonitoramentoAlertas', {
      idcliente : props.licenca.idcliente, 
      idlicenca: props.licenca.idlicencacollector, 
      monitorar : habilitado, 
      monitorarConsumiveis :monitorarAlertasServico, 
      monitorarAlertasServico :monitorarAlertasServico
    }).then(response =>{
      if(response.data.status=='OK'){
        Swal.fire({
          title : 'Datacount', 
          text : 'Registro atualizado com sucesso !', 
          showCancelButton : false, 
          showConfirmButton : false, 
          timer : 1500, 
          icon : 'success'
        })
        props.getData();
      }else{
        Swal.fire({
          title : 'Datacount', 
          text : 'Não foi possível atualizar !', 
          showCancelButton : false, 
          showConfirmButton : false, 
          timer : 1500, 
          icon : 'warning'
        })
      }
      setOpen(false)
    }).catch(error =>{
      Swal.fire({
        title : 'Datacount', 
        text : 'Não foi possível atualizar !', 
        showCancelButton : false, 
        showConfirmButton : false, 
        timer : 1500, 
        icon : 'error'
      })
      setOpen(false)
    });
  }
  return (
    <div>
      {
        props.licenca.monitorareventos == 't' ? <CheckCircleOutlineIcon onClick={handleClickOpen} style = {{color:"#00695c", cursor:"pointer"}}/> : <RadioButtonUncheckedIcon onClick={handleClickOpen} style = {{color:"#b71c1c", cursor:"pointer"}}/>
      }
      
      <Dialog hideBackdrop maxWidth='md' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Importar Eventos : {props.licenca.razaosocial}
        </DialogTitle>
        <DialogContent dividers style = {{ minWidth:"450px", }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
                <Typography>Monitorar?</Typography>
            </Grid>
            <Grid item xs = {8}>
              <TextField
                select
                SelectProps ={{native : true}}
                fullWidth
                onChange={(e)=>setHabilitado(e.target.value)}
                InputLabelProps={{shrink : true}}
                variant='outlined'>
                  <option value ={habilitado}>{habilitado == 't' ? 'SIM': 'NÃO'}</option>
                  <option value ='f'>NÃO</option>
                  <option value ='t'>SIM</option>
              </TextField>
            </Grid>
          </Grid>
          

          <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography>Aletas Consumíveis</Typography>
            </Grid>
            <Grid item xs = {6}>
              <TextField
                    select
                    disabled={habilitado=='f'}
                    SelectProps ={{native : true}}
                  
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    onChange={(e)=>{
                      setMonitorarConsumiveis(e.target.value)
                    }}
                    variant='outlined'>
                      <option value={monitorarConsumiveis}>{monitorarConsumiveis  == 't' ? 'SIM' : 'NÃO'}</option>
                  
                      <option value ='f'>Não</option>
                      <option value ='t'>Sim</option>
                  </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography>Códigos de Serviço</Typography>
            </Grid>
            <Grid item xs = {6}>
              <TextField
                    select
                    disabled={habilitado=='f'}
                    SelectProps ={{native : true}}
                  
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    onChange={(e)=>{
                      setMonitorarAletasServico(e.target.value)
                    }}
                    variant='outlined'>
                     <option value={monitorarAlertasServico}>{monitorarAlertasServico  == 't' ? 'SIM' : 'NÃO'}</option>
                      <option value ='f'>Não</option>
                      <option value ='t'>Sim</option>
                  </TextField>
            </Grid>
          </Grid>


          

          

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant='contained' color="secondary">
            Cancelar
          </Button>
          <Button autoFocus onClick={()=>{
            Swal.fire({
              title : 'Datacount', 
              text : 'Deseja gravas as informações ?', 
              showConfirmButton : true, 
              confirmButtonText : 'Confirmar', 
              showCancelButton : true, 
              cancelButtonText : 'Cancelar', 
              icon : 'question'
            }).then(response=>{
              if(response.isConfirmed){
                updateData();
              }
            })
            
          }} variant='contained' color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
