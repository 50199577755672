import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import Fab from '@material-ui/core/Fab';
import {Grid, TextField, InputAdornment} from '@material-ui/core'
import Swal from 'sweetalert2'
import Conexao from '../../providers/Conexao'
import Search from '@material-ui/icons/Search'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalNovoParametro(props) {
  const [open, setOpen] = React.useState(false);

  const refDescricao = React.createRef();
  const refMarca= React.createRef();
  const refNome = React.createRef();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
   
  };

  return (
    <div>
        <Button onClick={handleClickOpen}>
            <Search />
        </Button>
      
      <Dialog scroll='body' onClose={handleClose} fullWidth maxWidth='lg' aria-labelledby="customized-dialog-title" open={open} hideBackdrop disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Lista de Erros ativos
        </DialogTitle>
        <DialogContent dividers style ={{height:'500px', overflow:'auto', overflowX:'hidden'}}>
 
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant='contained' color="secondary">
            Cancelar
          </Button>
          <Button autoFocus variant='contained' color='primary'  color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
