import React, { useEffect , useRef, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, TextField, Grid, Container, InputAdornment} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Build from '@material-ui/icons/Build'
import {Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import IconTecnico from '@material-ui/icons/Accessibility'
import IconRegiao from '@material-ui/icons/Public'
import Switch from '@material-ui/core/Switch'
import Slider from '@material-ui/core/Slider';
import ModalSelecionaCor from '../../components/ModalSelecionaCor'
import SearchIcon from '@material-ui/icons/Search'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = useState(false)

    

    const [loading, setLoading]= useState(false)

    const {idusuario, idempresa} = props;

    const [listaParametrosSelecionaveis, setListaParametrosSelecionaveis] = useState([])
    
    

    

    const [nomeBandeja, setNomeBandeja] = useState(props.bandeja.nomerecipiente && props.bandeja.nomerecipiente)   

    const [tipoBandeja, setTipoBandeja] = useState(props.bandeja.tipobandeja && props.bandeja.tipobandeja =='T' ? 'TECNICO' : 'REGIAO')

    const [parametroTecnicoRegiao, setParametroTecnicoRegiao] = useState(props.bandeja.tipobandeja && props.bandeja.tipobandeja =='T' ? props.bandeja.idtecnico : props.bandeja.idregiao)
    
    const [andamento, setAndamento] = useState(props.bandeja.statusandamento && props.bandeja.statusandamento == 't' ? true : false)
    const [caminho, setCaminho] = useState(props.bandeja.statuscaminho && props.bandeja.statuscaminho == 't' ? true : false)
    const [atendendo, setAtendendo] = useState(props.bandeja.statusatendendo && props.bandeja.statusatendendo == 't' ? true : false)
    const [aguardandoPecas, setAguardandoPecas] = useState(props.bandeja.statusaguardandopecas && props.bandeja.statusaguardandopecas == 't' ? true : false)
    const [finalizado, setFinalizando] = useState(props.bandeja.statusfinalizado && props.bandeja.statusfinalizado == 't' ? true : false)
    const [retorno, setRetorno] = useState(props.bandeja.statusretorno && props.bandeja.statusretorno == 't' ? true : false)
    const [orcamento, setOrcamento] = useState(props.bandeja.statusorcamento && props.bandeja.statusorcamento == 't' ? true : false)


    const [fontColor, setFontColor]= useState('#fff')
    const [backgroundColor, setBackgroundColor]= useState('#000')

    const refTipoBandeja = useRef();

    const handleClose = () =>{
        setOpen(false)
    }
    
    const handleClickOpen = () =>{
        setOpen(true)
    }

    React.useEffect(()=>{
      if(open){
        getJanelas();
      }
    }, [open])


    React.useEffect(()=>{
      console.log('Parametro selecioando : ' + parametroTecnicoRegiao)
    }, [parametroTecnicoRegiao])


    


    const getJanelas = async () =>{
        
     /* setLoading(true)

      Conexao.get('chamadosTecnicosController/getJanelasTelaGestao?idusuario='+idusuario+'&idempresa='+idempresa).then(response=>{

          if(response.data.status =='OK'){
              setJanelas(response.data.payload)
          }else{
              setJanelas([])
          }

          setLoading(false)

      }).catch(error=>{
          setJanelas([])
          setLoading(false)
      })*/

  }

  const handleColorBackground = (color) => {
      console.log(color)
    setBackgroundColor(color.hex )
  };
  const handleColorFont = (color) => {
    setFontColor(color.hex )
  };

  const renderOptionTecnicos = () =>{
    
       return <React.Fragment>
                <option value ={parametroTecnicoRegiao}>{props.tecnicos && props.tecnicos.filter(item => item.idtecnico ==  parametroTecnicoRegiao).map(tecnico=>{
                  return tecnico.idtecnico+' - '+tecnico.nomecompleto
                })}</option>
                {props.tecnicos.map(tecnico=>{
        return <option value ={tecnico.idtecnico}>{tecnico.idtecnico + ' - '+ tecnico.nomecompleto}</option>
       })}
       </React.Fragment>
    
  }

  const renderOptionRegioes = () =>{
    
       return <React.Fragment>
                <option value ={parametroTecnicoRegiao}>{props.regioes && props.regioes.filter(item => item.idregiao ==  parametroTecnicoRegiao).map(regiao=>{
                  return regiao.idregiao+' - '+regiao.descricao
                })}</option>
                {props.regioes.map(regiao=>{
        return <option value ={regiao.idregiao}>{regiao.idregiao + ' - '+ regiao.descricao}</option>
       })}
       </React.Fragment>
    
  }

  const updateData = () =>{
    let nome =  tipoBandeja == 'TECNICO' ? props.tecnicos.filter(item => item.idtecnico ==  parametroTecnicoRegiao).map(tecnico=>{
      return tecnico.nomecompleto
    }) : props.regioes.filter(item => item.idregiao ==  parametroTecnicoRegiao).map(regiao=>{
      return regiao.descricao
    })
    Conexao.post('chamadosTecnicosController/updateBandeja', {
      idusuario : props.bandeja.idusuario, 
      idempresa : props.bandeja.idempresa, 
      idbandeja : props.bandeja.idbandeja, 
      backgroundcolor : backgroundColor, 
      fontcolor : fontColor, 
      andamento :andamento ? 't' : 'f', 
      caminho :caminho? 't' : 'f', 
      atendendo :atendendo? 't' : 'f', 
      aguardandopecas :aguardandoPecas ? 't' : 'f', 
      finalizado :finalizado ? 't' : 'f', 
      retorno : retorno? 't' : 'f', 
      orcamento : orcamento? 't' : 'f', 

    }).then(response=>{
      if(response.data.status =='OK'){
        setOpen(false)
      }else{

      }
    }).catch();



    /*

    Conexao.post('chamadosTecnicosController/updateBandeja', {
      idusuario : props.bandeja.idusuario, 
      idempresa : props.bandeja.idempresa, 
      idbandeja : props.bandeja.idbandeja, 
      backgroundcolor : backgroundColor, 
      fontcolor : fontColor, 
      andamento :andamento ? 't' : 'f', 
      caminho :caminho? 't' : 'f', 
      atendendo :atendendo? 't' : 'f', 
      aguardandopecas :aguardandoPecas ? 't' : 'f', 
      finalizado :finalizado ? 't' : 'f', 
      retorno : retorno? 't' : 'f', 
      orcamento : orcamento, 
      idtecnico : tipoBandeja == 'TECNICO' ?  parametroTecnicoRegiao : 0, 
      idregiao :  tipoBandeja == 'REGIAO' ?  parametroTecnicoRegiao : 0, 
      tipobandeja : tipoBandeja == 'TECNICO' ? 'T': 'R', 
      nomerecipiente :nome ? nome[0] : ''

    }).then(response=>{
      if(response.data.status =='OK'){
        setOpen(false)
      }else{
        
      }
    }).catch();


    */
  }

  return (
    <React.Fragment>
     
       
        <Tooltip title='Adicionar/Remover janelas dessa visualização'><Build  onClick={handleClickOpen} style ={{cursor:'pointer'}}/></Tooltip>
   
    
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} >
          CONFIGURAR PARAMETROS DA BANDEJA
        </DialogTitle>
        <DialogContent dividers >
         <Grid container spacing={1}>
             <Grid item xs={12}>
                 <TextField 
                    label='Nome da Bandeja'
                    size='small'
                    defaultValue= {props.bandeja && props.bandeja.nomerecipiente}
                    fullWidth
                    InputLabelProps={{shrink:true}}
                    variant='outlined'/>
             </Grid>
         </Grid>
         <Grid container spacing={1} style ={{marginTop:'10px'}}>
             <Grid item xs={12}>
                 <TextField 
                    label='Tipo de Bandeja (Técnico ou Região)'
                    size='small'
                    fullWidth
                    select
                    onChange={(e)=>{
                      setTipoBandeja(e.target.value);
                      setParametroTecnicoRegiao(0)
                    }}
                    SelectProps={{native:true}}
                    InputLabelProps={{shrink:true}}
                    value={tipoBandeja}
                    variant='outlined'>
                        <option>TECNICO</option>
                        <option>REGIAO</option>
                    </TextField>
             </Grid>
         </Grid>

         <Grid container spacing={1} style ={{marginTop:'10px'}}>
             <Grid item xs={12}>
               {tipoBandeja == 'TECNICO' ?  <TextField 
                    label={'Selecione o técnico'}
                    fullWidth
                    key={'comboTecnicos'}
                    id={'comboTecnicos'}
                    size='small'
                    onChange={(e)=>setParametroTecnicoRegiao(e.target.value)}                    
                    InputLabelProps={{shrink:true}}
                    select
                    SelectProps={{native:true}}
                    variant='outlined'>
                    
                     {
                       renderOptionTecnicos()
                     }
                  </TextField>
                :
                 <TextField 
                    label={'Selecione a Região'}
                    fullWidth
                    key={'comboRregioes'}
                    id={'comboRregioes'}
                    size='small'             
                    onChange={(e)=>setParametroTecnicoRegiao(e.target.value)}                           
                    InputLabelProps={{shrink:true}}
                    select
                    SelectProps={{native:true}}
                    variant='outlined'>
                    
                     {
                       renderOptionRegioes()
                     }
                  </TextField>}
                        
             </Grid>
         </Grid>
      
         <Typography variant='subtitle1' color='parimary'>Situações Visualizaveis</Typography>
       
         <Grid container spacing={1} style ={{marginTop:'10px'}}>
             <Grid item xs={3}>
                 <Typography variant='caption' color='primary'>Andamento</Typography><br/>
               
                 <input type = "checkbox" id = "subscribeNews" name = "subscribe" value = {andamento} onChange={(e)=>{setAndamento(e.target.checked)}}  checked={andamento}/>
             </Grid>
             <Grid item xs={3}>
                 <Typography variant='caption' color='primary'>Caminho</Typography><br/>
              
                <input type = "checkbox" id = "subscribeNews" name = "subscribe" value = {caminho} onChange={(e)=>{setCaminho(e.target.checked)}}  checked={caminho}/>
             </Grid>
             <Grid item xs={3}>
                 <Typography variant='caption' color='primary'>Atendendo</Typography><br/>
                
                <input type = "checkbox" id = "subscribeNews" name = "subscribe" value = {atendendo} onChange={(e)=>{setAtendendo(e.target.checked)}}  checked={atendendo}/>
             </Grid>
             <Grid item xs={3}>
                 <Typography variant='caption' color='primary'>Peças</Typography><br/>
                
                <input type = "checkbox" id = "subscribeNews" name = "subscribe" value = {aguardandoPecas} onChange={(e)=>{setAguardandoPecas(e.target.checked)}}  checked={aguardandoPecas}/>
             </Grid>
         </Grid>

         <Grid container spacing={1} style ={{marginTop:'10px'}}>
             <Grid item xs={3}>
                 <Typography variant='caption' color='primary'>Finalizado</Typography><br/>
                
                <input type = "checkbox" id = "subscribeNews" name = "subscribe" value = {finalizado} onChange={(e)=>{setFinalizando(e.target.checked)}}  checked={finalizado}/>
             </Grid>
             <Grid item xs={3}>
                 <Typography variant='caption' color='primary'>Retorno</Typography><br/>
                
                <input type = "checkbox" id = "subscribeNews" name = "subscribe" value = {retorno} onChange={(e)=>{setRetorno(e.target.checked)}}  checked={retorno}/>
             </Grid>
             <Grid item xs={3}>
                 <Typography variant='caption' color='primary'>Orçamento</Typography><br/>
                
                <input type = "checkbox" id = "subscribeNews" name = "subscribe" value = {orcamento} onChange={(e)=>{setOrcamento(e.target.checked)}}  checked={orcamento}/>
             </Grid>
             
         </Grid>
         <Grid container spacing={1} style ={{marginTop:'10px'}}>
             <Grid item xs={6}>
                <TextField 
                    label='BackgroundColor'
                    size='small'
                    value= {backgroundColor}
                    fullWidth
                    InputLabelProps={{shrink:true}}                    
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <ModalSelecionaCor setCor={(cor)=>setBackgroundColor(cor)}/>
                            </InputAdornment>
                        ),
                        }}
                    variant='outlined'/>
                    
             </Grid>
             <Grid item xs={6}>
             <TextField 
                    label='FontColor'
                    size='small'
                    value= {fontColor}
                    fullWidth
                    InputLabelProps={{shrink:true}}                    
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <ModalSelecionaCor setCor={(cor)=>setFontColor(cor)}/>
                            </InputAdornment>
                        ),
                        }}
                    variant='outlined'/>
             </Grid>
             <Grid container>
                 <Typography variant='caption' color='primary'>Exemplo</Typography>
                 <div style = {{backgroundColor : backgroundColor, color:fontColor, paddingLeft:'5px', paddingRight:'5px', fontSize:'10px', marginLeft : '5px', borderRadius:'3px', paddingTop :'1px'}}>TITULO DA JANELA</div>

             </Grid>
             
             
         </Grid>
        <br/>
         
         
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button autoFocus onClick={updateData} color="primary">
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


export default withRouter(ModalPadrao)