import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {Grid, TextField, InputAdornment, Tooltip, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import SearcIcon from '@material-ui/icons/Print'
import Conexao from '../../../../providers/Conexao'
import Swal from 'sweetalert2';
import IconeGruposFranquia from '@material-ui/icons/AccountTree'
import ModalNovoGrupo from './ModalNovoGrupoFranquia'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditaIcon from '@material-ui/icons/Brush';
import ModalEditarGrupo from './ModalEditarGrupoFranquia'
import ModalBuscaEquipamento from '../../../../components/ModalBuscaEquipamento'
import IconeTermos from '@material-ui/icons/CompareArrows'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalCadastro(props) {
  const [open, setOpen] = React.useState(false);
  const [equipamento, setEquipamento] = React.useState([]);



  const handleClickOpen = () => {
    setOpen(true);
   
  };
  const handleClose = () => {
    setOpen(false);
  };

 
  return (
    <div>
         <div className='add-icon-modal' style = {{marginTop:'-100px',marginRight:'30px' }}>
            <Tooltip title='Gerar um novo termo de instalação de equipamento'>
                <Fab color='primary' onClick={handleClickOpen}>
                    <AddIcon/>
                </Fab>
            </Tooltip>
        </div>
      <Dialog 
        fullWidth 
        maxWidth='md' 
        onClose={handleClose} 
        aria-labelledby="customized-dialog-title" 
        open={open} 
        scroll='body'
        disableBackdropClick
        disableEscapeKeyDown 
        hideBackdrop>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Lançar novo Termo de Instalação
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing = {1} style = {{marginTop:'10px'}}>
                <Grid item xs = {6}>
                    <TextField
                        size= 'small'
                        label = 'Contrato'
                        variant='outlined'
                        fullWidth
                        value={props.contrato.nossonumero}
                        InputLabelProps={{shrink:true}}/>
                </Grid>

                <Grid item xs = {4}>
                    <TextField
                        size= 'small'
                        label = 'Operação'
                        value='INSTALAÇÃO'
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                <Grid item xs = {2}>
                    <TextField
                        size= 'small'
                        label = 'Data'
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}/>
                </Grid>

            </Grid>
            <Grid container spacing = {1} style = {{marginTop:'10px'}}>
                <Grid item xs = {2}>
                    <TextField
                        size= 'small'
                        label = 'Código'
                        value={props.contrato.idcliente}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                <Grid item xs = {10}>
                    <TextField
                        size= 'small'
                        label = 'Razão Social'
                        value={props.contrato.razaosocial}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>
            <Grid container spacing = {1} style = {{marginTop:'10px'}}>
                <Grid item xs = {4}>
                    <TextField
                        size= 'small'
                        label = 'Número de Série'
                        value={equipamento && equipamento.numeroserie}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}
                        InputProps={{
                            endAdornment : <InputAdornment position='end'><ModalBuscaEquipamento 
                                                                                disponivel='T' 
                                                                                Conexao = {Conexao} 
                                                                                url ='equipamentosController/getEquipamentosContratos'
                                                                                getEquipamento={(equipamento)=>setEquipamento(equipamento)}
                                                                                idusuario={props.contrato.idusuario} 
                                                                                idempresa={props.contrato.idempresa}/></InputAdornment>
                        }}/>
                </Grid>
                <Grid item xs = {2}>
                    <TextField
                        size= 'small'
                        value={equipamento && equipamento.codigoadicional}
                        label = 'Placa'
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}/>
                </Grid>

                <Grid item xs = {6}>
                    <TextField
                        size= 'small'
                        label = 'Marca'
                        value={equipamento && equipamento.marca}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>
            <Grid container spacing = {1} style = {{marginTop:'10px'}}>        
                <Grid item xs = {8}>
                    <TextField
                        size= 'small'
                        label = 'Modelo'
                        value={equipamento && equipamento.modelo}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}/>
                </Grid>
                <Grid item xs = {4}>
                    <TextField
                        size= 'small'
                        label = 'Setor'
                        
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </Dialog>

     
    </div>
  );
}
