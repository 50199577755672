import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SDICon from '@material-ui/icons/SdCard'
import {Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import BUildIcon from '@material-ui/icons/Build'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = React.useState(false)
    const [atualizar, setAtualizar] = React.useState(false)
    const [rastreadores, setRastreadores] = React.useState([])

    const handleClickOpen = () =>{
        getRastreadores();
        setOpen(true)
    }

    const handleClose = () =>{
        setOpen(false)
        if(1 == 1){
            if(props.getVeiculos){
                props.getVeiculos();
            }
        }
    }



    const getRastreadores = () =>{
        Conexao.get('veiculosController/getRastreadores?idusuario=' + props.usuario).then(response=>{
            if(response.data.status == 'OK'){
                setRastreadores(response.data.payload)
            }else{
                setRastreadores([])
            }
        }).catch(error=>{
            setRastreadores([])
        })
    }
  return (
    <div>
      <Tooltip title='Vincular um rastreador ao veículo'>
          <SDICon color='primary' onClick={handleClickOpen} style={{cursor:'pointer'}} />                  
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='md'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Rastreadores Disponíveis
        </DialogTitle>
        <DialogContent dividers style = {{height:"350px", overflow:"auto"}}>
         <Table className='table table-bordered table-hover table-sm table-striped table-small' style ={{fontSize:'12px'}}>
            <TableHead>
                <TableCell align='center' style ={{ width: '20%'}}>IMEI</TableCell>
                <TableCell style ={{ width: '20%'}}>Marca</TableCell>
                <TableCell style ={{ width: '30%'}}>Modelo</TableCell>
                <TableCell align='center' style ={{ width: '10%'}}>Cadastro</TableCell>
                
                <TableCell align='center' style ={{ width: '10%'}}>Disponível</TableCell>
                <TableCell align='center' style ={{ width: '10%'}}>Instalado</TableCell>
                <TableCell align='center' style ={{ width: '10%'}}>Selecionar</TableCell>
            </TableHead>
            <TableBody>
                {
                    rastreadores && rastreadores.map((rastreador, i)=><TableRow>
                    <TableCell align='center' >{rastreador.idrastreador}</TableCell>
                    <TableCell >{rastreador.marca}</TableCell>
                    <TableCell >{rastreador.modelo}</TableCell>
                    <TableCell align='center' >{rastreador.datacadastro}</TableCell>
                    <TableCell align='center' >{rastreador.disponivel == 't' ? 'SIM': 'NÃO'}</TableCell>
                    <TableCell align='center' >{rastreador.datainstalacao}</TableCell>
                    <TableCell align='center' >{rastreador.disponivel == 't' ? <Button variant='contained' color='primary' onClick={()=>{
                        Swal.fire({
                            title : 'Printerconnect - Frota', 
                            text : `Deseja vincular este rastreador ao veiculo ${props.modelo} placa ${props.placa}?`, 
                            showCancelButton : true, 
                            cancelButtonText : 'Não', 
                            confirmButtonText:'Sim',
                            showConfirmButton : true, 
                            icon : 'question'
                        }).then(resp=>{
                            if(resp.isConfirmed){
                                Conexao.post('veiculosController/ativarRastreadorVeiculo', {
                                    idusuario : props.usuario , 
                                    idrastreador : rastreador.idrastreador, 
                                    placa : props.placa
                                }).then(response=>{
                                    if(response.data.status =='OK'){
                                        getRastreadores();
                                        Swal.fire({
                                            title : 'Printerconnect - Frota', 
                                            text : 'Veículo vinculado com sucesso!', 
                                            icon:'success', 
                                            showCancelButton:false, 
                                            showConfirmButton : false, 
                                            timer : 1000
                                        })
                                        setAtualizar(true)
                                    }else{
                                        Swal.fire({
                                            title : 'Printerconnect - Frota', 
                                            text : 'Não foi possível vincular', 
                                            icon:'warning', 
                                            showCancelButton:false, 
                                            showConfirmButton : false, 
                                            timer : 1000
                                        })
                                    }
                                }).catch(error=>{
                                    Swal.fire({
                                        title : 'Printerconnect - Frota', 
                                        text : 'Não foi possível vincular', 
                                        icon:'error', 
                                        showCancelButton:false, 
                                        showConfirmButton : false, 
                                        timer : 1000
                                    })
                                })
                            }
                        })
                    }}><BUildIcon /></Button> : ''}</TableCell>
                </TableRow>)
                }
            </TableBody>
         </Table>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalPadrao)