
import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField, InputAdornment} from '@material-ui/core'
import Swal from 'sweetalert2'




const TextFieldEmail = (props) =>{
    const [error, setError] = React.useState(false);

   
    const testarEmail = (email) =>{
        var re = /\S+@\S+\.\S+/;        
        return re.test(email)  
    }

    const validateEmail = (email) =>{
        if(props.required){
            if(email == ''){
                Swal.fire({
                    title : 'Printerconnect', 
                    text : 'O campo e-mail é obrigatório !', 
                    icon : 'warning', 
                    showConfirmButton : true, 
                    confirmButtonText : 'OK', 
                    showCancelButton : false,
                                });
                return false;
            }else{
               if(!testarEmail(email)){
                    Swal.fire({
                        title : 'Printerconnect', 
                        text : 'O formato do e-mail é inválido !', 
                        icon : 'warning', 
                        showConfirmButton : true, 
                        confirmButtonText : 'OK', 
                        showCancelButton : false,
                                    });
                                    return false;
               }else{
                   return true;
               }
            }

        }else{
            if(email == ''){
                return true;
            }else{
                if(!testarEmail(email)){
                    Swal.fire({
                        title : 'Printerconnect', 
                        text : 'O formato do e-mail é inválido !', 
                        icon : 'warning', 
                        showConfirmButton : true, 
                        confirmButtonText : 'OK', 
                        showCancelButton : false,
                                    });
                    return false;
               }else{
                return true;
               }
               
            }
        }
    }





    return <TextField 
                {...props} 
                placeholder='contato@seuemail.com.br'
                onBlur = {(e)=>{
                    if(validateEmail(e.target.value)){
                        if(props.setError){
                            props.setError(false)
                        }
                    }else{
                        if(props.setError){
                            props.setError(true)
                        }
                    }
                }}                          
                />
}


export default TextFieldEmail
  