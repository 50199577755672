import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../../providers/Conexao'

import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';

import Pagination from '@material-ui/lab/Pagination';

import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';


import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';


const LicencasCollector = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)

   
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [paginas, setPaginas]= React.useState(1);
    const [ registros, setRegistros] = useState(0)
    const [rastreadores, setRastreadores]= useState([])

    const refPlaca = useRef();
    const refFrota = useRef();
    const refModelo = useRef();

    

    useEffect(()=>{
   
        getRastreadores()
    }, [page])

    useEffect(()=>{
        if(!openModal){
        
        }
        getRastreadores()
    }, [])

    


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getRastreadores = () =>{
        setLoading(true)
        Conexao.get('veiculosController/getRastreadores?idusuario='+props.match.params.idempresa).then(response=>{
            if(response.data.status =='OK'){
                setRastreadores(response.data.payload)
            }else{
                setRastreadores([])
            }
            setLoading(false)
        }).catch(error=>{
            setRastreadores([])
            setLoading(false)
        });
    }




   

    return <Principal atualizar = {loading}>
    {/* <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Cadastro de Rastreadores</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={registros}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>

            <Grid item xs = {2}>
                <TextField
                    label ='IMEI'
                    inputRef={refPlaca}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Marca'
                    inputRef={refFrota}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                        
                   />
            </Grid>
            <Grid item xs = {5}>
                <TextField
                    label ='Modelo'
                    inputRef={refModelo}
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                                  
                   />
            </Grid>
            <Grid item xs = {3} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' onClick={()=>getRastreadores()} ><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' ><ClearIcon/> Limpar</Button>
            </Grid>
</Grid>*/}
     
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Cadastro de Rastreadores</Typography>
            </Breadcrumbs>
        </div> 
        <div className='div-form-container'>
    <Grid container style = {{marginTop:"10px"}} spacing={1}>
        <Table  className='table table-bordered table-striped table-hover table-sm' style ={{fontSize:'12px'}}>
            <TableHead>
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>IMEI</TableCell>
                <TableCell align='left' style = {{width:"10%",fontSize:'12px'}}>Marca</TableCell>
                <TableCell align='left' style = {{width:"25%",fontSize:'12px'}}>Modelo</TableCell> 
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Operadora</TableCell>               
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Data Cadastro</TableCell>
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Hora Cadastro</TableCell>
                <TableCell align='center' style = {{width:"10%",fontSize:'12px'}}>Disponível</TableCell>
            </TableHead>

            <TableBody>
               {
                   rastreadores && rastreadores.map(function(rastreador, i){
                       return <TableRow>
                       <TableCell align='center' style = {{fontSize:'12px'}}>{rastreador.idrastreador}</TableCell>
                       <TableCell align='left' style = {{fontSize:'12px'}}>{rastreador.marca}</TableCell>
                       <TableCell align='left' style = {{fontSize:'12px'}}>{rastreador.modelo}</TableCell> 
                       <TableCell align='center' style = {{fontSize:'12px'}}>{rastreador.operadora}</TableCell>               
                       <TableCell align='center' style = {{fontSize:'12px'}}>{rastreador.datacadastro}</TableCell>
                       <TableCell align='center' style = {{fontSize:'12px'}}>{rastreador.horacadastro.substr(0,8)}</TableCell>
                       <TableCell align='center' style = {{fontSize:'12px'}}>{rastreador.disponivel == 't' ? 'Disponível' : rastreador.placaveiculo }</TableCell>
                   </TableRow>
                   })
               }
           
               
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>
    {/*<div className='fabIncluir'>
        <div style ={{paddingBottom:'10px'}}>
            <ModalModelos usuario={props.match.params.idempresa}/>
        </div>
        <div style ={{paddingBottom:'10px'}}>
            <ModalMarcas usuario={props.match.params.idempresa}/>
        </div>
       
        <ModalNovoVeiculo getRastreadores={()=>{
            getRastreadores()
        }} usuario={props.match.params.idempresa}/>
    </div>*/}
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(LicencasCollector)