import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Principal/Principal'
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import FormNovoLocalClientes from './FormNovoLocalClientes'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FilterListIcon from '@material-ui/icons/FilterList';
import BlockIcon from '@material-ui/icons/Block';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import LanguageIcon from '@material-ui/icons/Language';
import { useHistory, } from "react-router-dom";


const CadastroLocaisClientes = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [locaisClientes, setLocaisClientes] = useState([])
    const [empresa, setEmpresa] = useState([])
    const [edicao, setEdicao] = useState([])
    const [parametroFiltro, setParametroFiltro] = useState('ativos')
    const [filtro, setFiltro] = useState([])

    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [paginas, setPaginas]= React.useState(1);

    const [localCliente, setLocalCliente] = useState([])
    const history = useHistory();


    useEffect(()=>{
        getLocaisClientes();
        console.log('Dda ' + props.empresaAtiva)
        console.log(props.teste)
    }, [])

    useEffect(()=>{
        if(!openModal){
            setEdicao([])
        }
    }, [openModal])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const getLocaisClientes = (page) =>{
        
        setLoading(true)
        Conexao.post('LocaisInstalacaoClienteController/getLocaisClientes', {
            idUsuario : props.match.params.idempresa, 
            idEmpresa : props.match.params.chaveEmpresa, 
            filtro : filtro, tipoFiltro : parametroFiltro, 
            registros : rowsPerPage, 
            pagina : page ? page : props.match.params.page
        }).then(response =>{
            
            if(response.status == 200){
               
                if(response.data.status == 'OK'){
                   setLocaisClientes(response.data.payload.registros);
                   setPaginas(response.data.payload.paginas)
                   
                }else{
                   setLocaisClientes([])
                }
            }else{
            setLocaisClientes([])
            }
            setLoading(false)
        }).catch(error =>{
            setLoading(false)
        })
    }

   

    return <Principal atualizar = {loading}>
    <Grid container spacing = {2}>
            <Grid item xs = {7}><Typography>Cadastro de Locais de Instalação</Typography></Grid>
            <Grid item xs = {5}>
            <TablePagination
                component="div"
                count={0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
        <Grid container spacing = {2}>
            <Grid item xs = {2}>
                <TextField
                    label ='Data para a Pesquisa'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}
                    select
                    SelectProps={{native : true}}
                   
                    >
                    <option value = 'F'></option>
                    <option>CRIAÇÃO</option>
                    <option>INSTALAÇÃO</option>
                    <option>ACESSO</option>
                    </TextField>
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Data'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                    type= 'date'      
                             
                   />
            </Grid>
            <Grid item xs = {2}>
                <TextField
                    label ='Chave de Instalação'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}}  
                                
                   />
            </Grid>
            <Grid item xs = {3}>
                <TextField
                    label ='Razão Social'
                    variant ='outlined'
                    fullWidth
                    InputLabelProps={{shrink : true}} 
                                  
                   />
            </Grid>
            <Grid item xs = {2} spacing={2} className='container-buttons'>
                <Button color = 'primary' variant='contained' ><SearchIcon/> Pesquisar</Button>
                <Button color='secondary' variant='contained' ><ClearIcon/> Limpar</Button>
            </Grid>
        </Grid>
        <br/>
        <div>
            <Breadcrumbs aria-label="breadcrumb">            
                <Link color="inherit" to={"/home/revenda/"+props.match.params.idempresa} >
                    Home
                </Link>
            <Typography color="textPrimary">Locais de instalação</Typography>
            </Breadcrumbs>
        </div> 
        <div className='div-form-container'>
    <Grid container style = {{marginTop:"10px"}} spacing={1}>
        <Table  >
            <TableHead>
                
                <TableCell align='center'>Descrição</TableCell>
                <TableCell>Razão Social</TableCell>                
                <TableCell align='center'>Cidade</TableCell>
                <TableCell align='center'>Estado</TableCell>
                <TableCell align='center'>Posicionar</TableCell>
                <TableCell align='center'>Opções</TableCell>
            </TableHead>

            <TableBody>
                {
                    locaisClientes && locaisClientes.map(function(localClientes, i){
                        return <TableRow>
                           
                                <TableCell align='center'><Typography variant='caption' color='primary'>{localClientes.descricao}</Typography></TableCell>
                                <TableCell>
                                    <Typography variant='subtitle2' color='primary'>{localClientes.razaosocial}</Typography>
                                    {localClientes.logradouro && <Typography variant='caption' color='primary'>{localClientes.logradouro+', '+localClientes.numero}</Typography>}
                                    {localClientes.logradouro && <br/> }
                                    {localClientes.bairro && <Typography variant='caption' color='primary'>{localClientes.cep+' - '+localClientes.bairro}</Typography>}
                                    
                                </TableCell>
                                
                                <TableCell align='center'><Typography variant='caption' color='primary'>{localClientes.cidade}</Typography></TableCell>
                                <TableCell align='center'><Typography variant='caption' color='primary'>{localClientes.estado}</Typography></TableCell>
                                <TableCell align='center'>
                                    <LanguageIcon style = {{cursor:"pointer"}} onClick={()=>{
                                        Swal.fire({
                                            title :'Printerconenct', 
                                            text :'Deseja posicionar este local agora ?', 
                                            icon :'question', 
                                            showConfirmButton : true, 
                                            confirmButtonText : 'Sim', 
                                            showCancelButton : true, 
                                            cancelButtonText : 'Não'
                                        }).then(resposta =>{
                                            if(resposta.isConfirmed){
                                                history.push({pathname : '/mapa-local-clientes/revenda/'+props.match.params.idempresa+'/empresa/'+props.match.params.chaveEmpresa, 
                                                state : {
                                                    localClientes : localClientes
                                                }})
                                            }
                                        })
                                        
                                    }}/>
                                </TableCell>
                                <TableCell align='center'>
                                    
                                <Tooltip title = 'Editar dados do cliente'>
                                        <Button color='primary' onClick={()=>{

                                                    setLocalCliente(localClientes);
                                                    setOpenModal(true)
                                                }}><EditIcon/></Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow> 
                    }) 
                }
                {locaisClientes.length == 0 && <TableRow>
                    <TableCell colSpan={6}>
                    <Typography variant = 'caption' color='primary'>* Não há registros !</Typography>
                    </TableCell>
                    
                    
                    </TableRow>}
            </TableBody>
           
              
        </Table>
    </Grid>
    </div>
    <ModalFull 
        showModal={openModal} 
        closeModal = {()=>{setOpenModal(false)}}
        titulo={localCliente.razaosocial ? 'Cadastro/Edição de Locais de Instalação : '+localCliente.razaosocial + ' ( ' +localCliente.idcliente+' )' : ''} >
        {<FormNovoLocalClientes  
            idempresa={props.match.params.idempresa}
            localCliente = {localCliente}
            chaveEmpresa={props.match.params.chaveEmpresa}
            updateLocalClientes = {()=>getLocaisClientes()}
           
            closeModal = {()=>{setOpenModal(false)}}/>}
    </ModalFull>
   

    {/*<div className='fabIncluir'>
        <Fab size="large" color="primary" aria-label="add" onClick={()=>setOpenModal(true)} >
          <AddIcon />
        </Fab>
    </div>*/}
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(CadastroLocaisClientes)