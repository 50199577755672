import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Conexao from '../../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip, TextField, Grid, Divider, InputAdornment} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalPadrao = (props) => {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [marcas, setMarcas] = React.useState([])
    const [idMarca, setIdMarca]= React.useState(-1)
    const [descricao, setDescricao]= React.useState()

    const handleClickOpen = () =>{
        setIdMarca(-1)
        setDescricao('')
        setOpen(true)
        getMarcas()
    }

    const handleClose = () =>{
        setOpen(false)
    }

    const getMarcas = () =>{
  
      Conexao.get('veiculosController/getMarcas?idusuario='+props.usuario).then(response=>{
          if(response.data.status =='OK'){
              setMarcas(response.data.payload)
              
          }else{
              setMarcas([])
          }
         

      }).catch(error=>{
       
          setMarcas([])
         

      })
  }


  return (
    <div>
      <Tooltip title='Cadastrar um novo modelo'>
          <Button color='primary' variant='contained' onClick={handleClickOpen} >
              <AddIcon/> Novo Modelo
          </Button>
        
      </Tooltip>
      <Dialog 
        disableBackdropClick disableEscapeKeyDown
        hideBackdrop
        fullWidth
        maxWidth='sm'
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Cadastro de Novo Modelo
        </DialogTitle>
        <DialogContent dividers >
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <TextField
                        label ='Marca'
                        InputLabelProps={{shrink:true}}
                        fullWidth
                        variant='outlined'
                        select
                        value={idMarca}
                        onChange={(e)=>setIdMarca(e.target.value)}
                        SelectProps={{native : true}}>
                          <option value ='f'>Selecione uma marca</option>
                          {
                             marcas && marcas.map(function(marca, i){
                                 return <option value ={marca.idmarca}>{marca.descricao}</option>
                             })
                          }
                    </TextField>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        label ='Descrição do Modelo'
                        InputLabelProps={{shrink:true}}
                        fullWidth
                        variant='outlined'
                        value={descricao}
                        onChange={(e)=>setDescricao(e.target.value)}
                        />
                    
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" disabled={loading} variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus  color="primary" variant='contained' disabled={loading} onClick={()=>{
            Swal.fire({
              title : 'Printerconnect - Frota', 
              text : 'Deseja incluir um novo modelo agora?', 
              icon : 'question', 
              showCancelButton : true, 
              showCancelButton :true,
            }).then(resposta =>{
              if(resposta.isConfirmed){
                if(idMarca == 'f' ){
                  Swal.fire({
                    title : 'Printerconnect - Frota', 
                    text : 'Você deve selecionar uma marca!', 
                    icon : 'warning', 
                    showCancelButton : false, 
                    showCancelButton :false,
                    timer : 1000
                  })
                  return
                }

                if(descricao == '' ){
                  Swal.fire({
                    title : 'Printerconnect - Frota', 
                    text : 'Você deve informar a descrição do modelo!', 
                    icon : 'warning', 
                    showCancelButton : false, 
                    showCancelButton :false,
                    timer : 1000
                  })
                  return
                }

                setLoading(true)
                Conexao.post('veiculosController/insertModelo', {
                  idusuario : props.usuario, 
                  idmarca : idMarca, 
                  descricao : descricao
                }).then(response=>{
                  
                  if(response.data.status != 'OK'){
                    Swal.fire({
                      title : 'Printerconnect - Frota', 
                      text : 'Não foi possível incluir', 
                      icon : 'warning', 
                      showCancelButton : false, 
                      showCancelButton :false,
                      timer : 1000
                    })
                    setOpen(false)
                    setLoading(false)
                  }else{
                   
                    Swal.fire({
                      title : 'Printerconnect - Frota', 
                      text : 'Incluído com sucesso!', 
                      icon : 'success', 
                      showCancelButton : false, 
                      showCancelButton :false,
                      timer : 1000
                    })
                    setOpen(false)
                    props.getModelos();
                    setLoading(false)
                  }
                }).catch(error=>{
                  Swal.fire({
                    title : 'Printerconnect - Frota', 
                    text : 'Não foi possível incluir', 
                    icon : 'error', 
                    showCancelButton : false, 
                    showCancelButton :false,
                    timer : 1000
                  })
                  setLoading(false)
                  setOpen(false)
                })
              }
            })
          }}>
            gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalPadrao)