import React from 'react'
import './MenuPrincipal.css'
import {withRouter, Link} from 'react-router-dom'
import {TextField, Typography, Divider} from '@material-ui/core'
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import PrintIcon from '@material-ui/icons/Print';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import BuildIcon from '@material-ui/icons/Build';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import UsbIcon from '@material-ui/icons/Usb';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Badge from '@material-ui/core/Badge';
import Forward5Icon from '@material-ui/icons/Forward5';
import ModalSobre from '../ModalSobre'
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Replay5Icon from '@material-ui/icons/Replay5';
import ProdutosIcone from '@material-ui/icons/Archive';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import AddIcon from '@material-ui/icons/Add';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import BusinessIcon from '@material-ui/icons/Business';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import GrainIcon from '@material-ui/icons/Grain';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CategoryIcon from '@material-ui/icons/Category';
import EventNoteIcon from '@material-ui/icons/EventNote';
import LeiturasIcon from '@material-ui/icons/CalendarViewDay'
import CallIcon from '@material-ui/icons/Call';
import IconeDesabilitados from '@material-ui/icons/Block'
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import AppsIcon from '@material-ui/icons/Apps';
import BallotIcon from '@material-ui/icons/Ballot';
import RoomIcon from '@material-ui/icons/Room';
import LanguageIcon from '@material-ui/icons/Language';
import WarningIcon from '@material-ui/icons/Warning';
import AppRegistration from '@material-ui/icons/FormatLineSpacing'
import SetaDownload from '@material-ui/icons/ArrowDownward'
import GestureIcon from '@material-ui/icons/Gesture'
import ClipIcon from '@material-ui/icons/AttachFile'
import IconeMovEstoque from '@material-ui/icons/LowPriority'
import GetAppIcon from '@material-ui/icons/GetApp';
import IconeTipoContador from '@material-ui/icons/Filter2'
import IconeCadastroRastreador from '@material-ui/icons/Memory'
import IconeCadastroVeiculo from '@material-ui/icons/DirectionsCar'
import IconeMapaAoVivo from '@material-ui/icons/Map'
/*

react native tabs

https://www.jonataoliveira.com.br/tabbar-personalizada-em-react-native/


*/

const MenuPrincipal = (props) =>{
    const [empresa, setEmpresa] = React.useState([]);
    const [empresaAtiva, setEmpresaAtiva] = React.useState([]); 
    
    React.useEffect(()=>{
        const emp = sessionStorage.getItem('empresa@printerConnect', []);
        const empAtiva = sessionStorage.getItem('empresaAtiva@printerConnect', null);
        setEmpresa(JSON.parse(emp))
        if(empAtiva){
          setEmpresaAtiva(JSON.parse(empAtiva))
      }else{
          setEmpresaAtiva({idempresa:-1, razaosocial:''})
          
      }
        
      }, [props.location])

    return <div className="navbar" style={{paddingLeft :'5%', paddingRight : '5%'}}>
    
    <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/grafico-clientes' : '', state :{
                  empresa : empresaAtiva, 
                }}}> 
        <div><NetworkCheckIcon /></div><div>Dashboard</div>
    </Link>
    <div className="subnav">
      <button className="subnavbtn"><div><AccountTreeIcon /></div><div>Empresas</div><i className="fa fa-caret-down"></i></button>
      <div className="subnav-content" style={{paddingLeft : '9%',}}>
        <Link to ={{pathname : '/minheempresa', state :{
                  empresa : empresaAtiva, 
                }}}>
        <div><LibraryBooksIcon /></div><div>Meus Dados</div>
        </Link>
        <Link to ={{pathname : '/cadastro-empresa', state :{
                  empresa : empresa, 

                }}}>
        <div><AddIcon /></div><div>Cadastro de Empresas</div>
        </Link>  

        <Link to ={{pathname : '/mapa-empresa/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa, state:{
          empresa : empresa, 
          usuario : empresa.idusuario
        }}}>
        <div><RoomIcon /></div><div>Visão Parque (mapa)</div>
        </Link>  

        <Link to ={{pathname : '/cadastro-regioes/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa}}>
        <div><LanguageIcon /></div><div>Cadastro de Regiões</div>
        </Link>  
       
           
      </div>
    </div>

  


    <div className="subnav">
            <Link to ={{
                pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/cadastro-clientes':'', state:{
                  empresa : empresaAtiva, 
                }}}>
                  <div><GroupAddIcon /></div><div>Clientes</div>
            </Link>
   
    </div>

   

  

    

   
    

    <div className="subnav">
      <button className="subnavbtn"><div><UsbIcon /></div><div>Dispositivos</div><i className="fa fa-caret-down"></i></button>
      <div className="subnav-content" style={{paddingLeft : '18%',}}>
        <div  className="subnav-content-2" >



        <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/licencas_collect/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
          <div><VpnKeyIcon /></div><div>Licenças</div>
        </Link>

        <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/dca/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
          <div><UsbIcon /></div><div>DCA</div>
        </Link>

       

        <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/contadores/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
          <div><AccessAlarmsIcon /></div><div>Ao Vivo</div>
        </Link>

        <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/gestao_contadores/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
          <div><EventNoteIcon /></div><div>Coletas</div>
        </Link>
        <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/gestao_contadores/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
          <div><LeiturasIcon /></div><div>Leituras</div>
        </Link>

        <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/gestao_contadores/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
          <div><IconeDesabilitados /></div><div>Desabilitados</div>
        </Link>

      
       

        <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/alertas-atolamentos' : '/404', state :{
          empresa : empresaAtiva
        }}}>
          <div><GestureIcon /></div><div>Atolamentos</div>
        </Link>

        <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/alertas_suprimentos/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
        <div><NotificationsActiveIcon /></div><div>Alertas Toner</div>
        </Link>

       

        <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/trocas_suprimento/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa+'/page/1' : '/404'}}>
        <div><Replay5Icon /></div><div>Trocas Toner</div>
        </Link>   

        <Link to ={{pathname : (empresaAtiva.ativa =='t' && empresaAtiva.idempresa > 0 ) ? '/relatorios/revenda/'+empresaAtiva.idrevenda+'/empresa/'+empresaAtiva.idempresa : '/404'}}>
        <div><PrintIcon /></div><div>Relatórios</div>
        </Link>
   
        </div>
        </div>
    </div>


 

    <div className="subnav">
      <button className="subnavbtn"><div><GetAppIcon /></div><div>Downloads</div><i className="fa fa-caret-down"></i></button>
      <div className="subnav-content">
        <a href ="https://www.printerconnect.com.br/downloads/printerconnec-app-setup.msi" target='_blank'>
          <div><GetAppIcon /></div><div>Databit DCA</div>
        </a>
      </div>
    </div>
   
    

  </div>
}

export default withRouter(MenuPrincipal)


