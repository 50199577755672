import React, {Fragment, useState, useEffect, useRef} from 'react'
import {Carousel, Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import ImagenContadores from './Imagens/bannerContadores2.jpg'
import ImagemFinanceiro from './Imagens/bannerFinanceiro.jpg'
import ImagemTransito from './Imagens/bannerTransito.jpg'
import ImagemGestao from './Imagens/bannerContadores.jpg'
import TestimonialCard from './TestimonialCard'
import MockupCollector1 from './Imagens/mockupCollector1.png'
import MockupChamados1 from './Imagens/mockupChamados1.png'
import MockupRastreador from './Imagens/mockupRastreador.png'
import MockupBilling1 from './Imagens/mockupBilling1.png'
import BannerCopiadora from './Imagens/bannerCopiadoras.svg'
import ImgRastreador from './Imagens/rastreador.png'

export default function Carrossel(props){
    return <div id='testemonial' style = {{paddingTop:"50px"}}>
        <br/>
        <Carousel fade  pause ={'hover'} variant='dark'  indicators >
          <Carousel.Item>
            <TestimonialCard title='Coleta automática de contadores' foto={MockupCollector1} >
            <p>Através da nossa tecnologia, você pode acompanhar em tempo real a "saúde" do seu parque de equipamentos:</p>
            <ul>
              <li>1 - Obtenha informações sobre contadores de impressão, cópia, digitalização.</li>
              <li>2 - Tenha acesso a erros e anomalias dos equipamentos.</li>
              <li>3 - Contadores de suprimentos e parts.</li>
              <li>4 - Acompanhe os níveis de suprimento e suas trocas.</li>
            </ul>
            </TestimonialCard>
          </Carousel.Item>

          <Carousel.Item>
            <TestimonialCard title='Gestão de Contratos e Ordens de Serviço' foto={MockupChamados1} >
              <p>Controle de forma eficiênte todo processo de atendimento técnico:</p>
              <ul>
                <li>1 - Abertura de ordens de serviço com opção de Check-in e Check-out.</li>
                <li>2 - Colete a assinatura do cliente direto na tela do aplicativo.</li>
                <li>3 - Tenha acesso a localização dos técnicos em tempo real.</li>
                <li>4 - Receba Ordens de Serviço abertas pelo seu cliente através do nosso portal.</li>
              </ul>
            </TestimonialCard>
          </Carousel.Item>


          <Carousel.Item>
            <TestimonialCard title='Rastreamento de Frota integrada' foto={MockupRastreador} foto2={ImgRastreador} >
              <p>Monitore seus veículos de forma integrada, tenha informações de geolocalização para uma melhor gestão de atendimento:</p>
              <ul>
                <li>1 - Posição em tempo real.</li>
                <li>2 - Alertas de cerca.</li>
                <li>3 - Relatórios de trajeto.</li>
                <li>4 - Alertas de velocidade e consumo de combustível.</li>
              </ul>
            </TestimonialCard>
          </Carousel.Item>
          

          <Carousel.Item>
            <TestimonialCard title='Bilhetagem e Gestão de Impressão' foto={MockupBilling1} >
              <p>Entregue para seus clientes gestão e controle do que é e por quem é utilizados os equipamentos.</p>
              <ul>
                <li>1 - Relatórios de impressão (Usuário, Impressora, Computador, Data, Hora, Paginas.....).</li>
                <li>2 - Restrição de impressão.</li>
                <li>3 - Impressões por grupos.</li>
                <li>4 - Cotas.</li>
              </ul>
            </TestimonialCard>
          </Carousel.Item>
        </Carousel>
  </div>
}