import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Typography, TextField} from '@material-ui/core';
import WrapTextIcon from '@material-ui/icons/WrapText';

import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { SketchPicker,   } from 'react-color'
import BrushIcon from '@material-ui/icons/Brush'
import IconTecnico from '@material-ui/icons/Accessibility'



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalSelecionaCor = (props) => {
    const [backgroundColor, setBackgroundColor]= React.useState('#000')
    const [open, setOpen]= React.useState(false)
    const [loading, setLoading]= React.useState(false)
    const [tecnicos, setTecnicos]= React.useState([])
    const [tecnico, setTecnico]= React.useState('f')
    


    useEffect(()=>{
        if(open){
            getTecnicos()
        }
        
    },[open])

    const getTecnicos = async() =>{
        setLoading(true)
        props.conexao.get('funcionariosController/getComboFuncionarios?idUsuario='+props.usuario+'&idEmpresa='+props.empresa+'&distinct=true').then(response=>{

            if(response.data.status =='OK'){
                setTecnicos(response.data.payload)
            }else{
                setTecnicos([])
            }
            setLoading(false)

        }).catch(error=>{
            setTecnicos([])
            setLoading(false)
        })
        
    }
  return (
    <div>
      <Tooltip title='Cadastrar um novo técnico (bandeja)'>
      <Button autoFocus onClick={()=>setOpen(true)} color="primary" variant='contained'>
            <IconTecnico/> Técnico
          </Button>
         
      </Tooltip>
      <Dialog 
        hideBackdrop
        maxWidth={'md'}
        disableBackdropClick disableEscapeKeyDown
        onClose={()=>setOpen(false)}
        aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">
          Nova Bandeja Técnico
        </DialogTitle>
        <DialogContent dividers>
           <TextField
            label='Nome do Técnico'
            variant='outlined'
            InputLabelProps={{shrink:true}}
            fullWidth
            select
            onChange={(e)=>setTecnico(e.target.value)}
            SelectProps={{native : true}} 
            >
                <option value = 'F'>Selecione um técnico na lista</option>
                {
                    tecnicos && tecnicos.map(function(tecnico, i){
                        return <option value = {tecnico.idtecnico}>{tecnico.nomecompleto}</option>
                    })
                }
            </TextField>

            {/*<TextField
              label='Cor do título'
              variant='outlined'
              InputLabelProps={{shrink:true}}
              fullWidth
              select
              onChange={(e)=>setBackgroundColor(e.target.value)}
              SelectProps={{native : true}} 
              >
                  <option value = '#B0BEC5' style={{backgroundColor:'#B0BEC5', color:'#fff'}}>#B0BEC5</option>
                  <option value = '#90A4AE' style={{backgroundColor:'#90A4AE', color:'#fff'}}>#90A4AE</option>
                  <option value = '#607D8B' style={{backgroundColor:'#607D8B', color:'#fff'}}>#607D8B</option>
                  <option value = '#546E7A' style={{backgroundColor:'#546E7A', color:'#fff'}}>#546E7A</option>
                  <option value = '#D7CCC8' style={{backgroundColor:'#D7CCC8', color:'#000'}}>#D7CCC8</option>
                  <option value = '#A1887F' style={{backgroundColor:'#A1887F', color:'#fff'}}>#A1887F</option>
                  <option value = '#8D6E63' style={{backgroundColor:'#8D6E63', color:'#fff'}}>#8D6E63</option>
                  <option value = '#6D4C41' style={{backgroundColor:'#6D4C41', color:'#fff'}}>#6D4C41</option>
                  <option value = '#3E2723' style={{backgroundColor:'#3E2723', color:'#fff'}}>#3E2723</option>

                  <option value = '#FFAB91' style={{backgroundColor:'#FFAB91', color:'#fff'}}>#FFAB91</option>
                  <option value = '#FF7043' style={{backgroundColor:'#FF7043', color:'#fff'}}>#FF7043</option>
                  <option value = '#F4511E' style={{backgroundColor:'#F4511E', color:'#fff'}}>#F4511E</option>
                  <option value = '#D84315' style={{backgroundColor:'#D84315', color:'#fff'}}>#D84315</option>
                  <option value = '#FFCC80' style={{backgroundColor:'#FFCC80', color:'#fff'}}>#FFCC80</option>
                  <option value = '#FFA726' style={{backgroundColor:'#FFA726', color:'#fff'}}>#FFA726</option>
                  <option value = '#FFB300' style={{backgroundColor:'#FFB300', color:'#fff'}}>#FFB300</option>

                  <option value = '#EF6C00' style={{backgroundColor:'#EF6C00', color:'#fff'}}>#EF6C00</option>
                  <option value = '#EF6C00' style={{backgroundColor:'#EF6C00', color:'#fff'}}>#EF6C00</option>
                  <option value = '#9CCC65' style={{backgroundColor:'#9CCC65', color:'#fff'}}>#9CCC65</option>
                  <option value = '#43A047' style={{backgroundColor:'#43A047', color:'#fff'}}>#43A047</option>
                  <option value = '#2E7D32' style={{backgroundColor:'#2E7D32', color:'#fff'}}>#2E7D32</option>
                  <option value = '#9FA8DA' style={{backgroundColor:'#9FA8DA', color:'#fff'}}>#9FA8DA</option>
                  <option value = '#42A5F5' style={{backgroundColor:'#42A5F5', color:'#fff'}}>#42A5F5</option>

                  <option value = '#1E88E5' style={{backgroundColor:'#1E88E5', color:'#fff'}}>#1E88E5</option>
                  <option value = '#1565C0' style={{backgroundColor:'#1565C0', color:'#fff'}}>#1565C0</option>
                  <option value = '#B39DDB' style={{backgroundColor:'#B39DDB', color:'#fff'}}>#B39DDB</option>
                  <option value = '#5E35B1' style={{backgroundColor:'#5E35B1', color:'#fff'}}>#5E35B1</option>
                  <option value = '#8E24AA' style={{backgroundColor:'#8E24AA', color:'#fff'}}>#8E24AA</option>
                  <option value = '#4A148C' style={{backgroundColor:'#4A148C', color:'#fff'}}>#4A148C</option>
                  <option value = '#EF5350' style={{backgroundColor:'#EF5350', color:'#fff'}}>#EF5350</option>
                  <option value = '#E53935' style={{backgroundColor:'#E53935', color:'#fff'}}>#E53935</option>
                  <option value = '#B71C1C' style={{backgroundColor:'#B71C1C', color:'#fff'}}>#B71C1C</option>
                
            </TextField>*/}
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="secondary" variant='contained' onClick={()=>{
              
              setOpen(false)}
              }>
            Cancelar
          </Button>
          <Button autoFocus color="primary" variant='contained' disabled={loading} onClick={()=>{
              if(tecnico == 'f'){
                Swal.fire({
                    title : 'Printerconnect', 
                    text : 'Informe o Técnico antes de continuar', 
                    icon :'warning', 
                    showConfirmButton : true, 
                    confirmButtonText : 'OK', 
                    showCancelButton : false, 
                    cancelButtonText : 'Não'
                }) 
                return;
              }
               Swal.fire({
                    title : 'Printerconnect', 
                    text : 'Deseja criar esta bandeja de gestão agora?', 
                    icon :'question', 
                    showConfirmButton : true, 
                    confirmButtonText : 'SIM', 
                    showCancelButton : true, 
                    cancelButtonText : 'Não'
                }).then(resposta =>{
                if(resposta.isConfirmed){
                    let nome = tecnicos.filter(item => item.idtecnico == tecnico).map(tecnico=>{
                        return tecnico.nomecompleto
                    }) 
                    setLoading(true)
                    props.conexao.post('chamadosTecnicosController/insertBandejaTecnico',{
                        idusuario :props.usuario, 
                        idempresa : props.empresa, 
                        idtecnico : tecnico,
                        nomerecipiente :nome ? nome[0] : '' , 
                        backgroundcolor : '#00227b', 
                        fontcolor : '#fff'
                
                    }).then(response=>{
                        if(response.data.status=='OK'){
                            setOpen(false)
                            props.getJanelas()
                        }
                        setLoading(false)
                    }).catch(error=>{
                        setLoading(false)
                    })
                }
            })
             
          }}>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalSelecionaCor)

