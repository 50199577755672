import React, { useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import WrapTextIcon from '@material-ui/icons/WrapText';
//import Conexao from '../providers/Conexao'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import {TextField, Tooltip, Grid,} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import TextFieldCNPJ from '../../components/TextFieldCNPJ';
import Conexao from '../../providers/Conexao'

var md5 = require('md5');


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ModalNovaRevenda = (props) => {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [erro, setErro]= React.useState(true)
    const refCNPJ = useRef();
    const refRazaoSocial = useRef();
    const refNomeFantasia = useRef();
    const refEndereco = useRef();
    const refNumero = useRef();
    const refComplemento = useRef();
    const refBairro = useRef();
    const refCep = useRef();
    const refCidade = useRef();
    const refEstado = useRef();
    const refEmail = useRef();
    const refSenha = useRef();
    const refConfirmaSenha = useRef();

    const handleClickOpen = () =>{
        setOpen(true)
        if(refCNPJ.current){
            refCNPJ.current.value = ''
        }

        if(refRazaoSocial.current){
            refRazaoSocial.current.value = ''
        }

        if(refNomeFantasia.current){
            refNomeFantasia.current.value = ''
        }

        if(refEndereco.current){
            refEndereco.current.value = ''
        }

        if(refNumero.current){
            refNumero.current.value = ''
        }

        if(refComplemento.current){
            refComplemento.current.value = ''
        }

        if(refBairro.current){
            refBairro.current.value = ''
        }

        if(refCep.current){
            refCep.current.value = ''
        }

        if(refCidade.current){
            refCidade.current.value = ''
        }

        if(refEstado.current){
            refEstado.current.value = ''
        }

        if(refEmail.current){
            refEmail.current.value = ''
        }

        if(refSenha.current){
            refSenha.current.value = ''
        }
        if(refConfirmaSenha.current){
            refConfirmaSenha.current.value = ''
        }
        
    }

    const handleClose = () =>{
        setOpen(false)
    }
  return (
    <div>
      <Tooltip title='Cadastrar uma nova Revenda'>
          <Fab color='primary' onClick={handleClickOpen} style={{cursor:'pointer'}}>
              <AddIcon/>
          </Fab>
        
      </Tooltip>
      <Dialog 
        disableBackdropClick 
        disableEscapeKeyDown
        hideBackdrop
        scroll='body'
        maxWidth='md'
        fullWidth
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Cadastrar Revenda
        </DialogTitle>
        <DialogContent dividers style={{paddingLeft : 30, paddingRight : 30}} >
            <Grid container spacing = {1} style ={{marginTop : '5px', }}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>CNPJ *</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextFieldCNPJ 
                        fullWidth
                        inputRef={refCNPJ}
                        setErro = {(value)=>setErro(value)}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>
            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>Razão Social *</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField 
                        fullWidth
                        inputRef={refRazaoSocial}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>
            {/*
            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>Nome Fantasia *</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField 
                        fullWidth
                        inputRef={refNomeFantasia}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>
  */}


            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1'>Endereço</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField 
                        fullWidth
                        inputRef={refEndereco}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1'>Numero</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        fullWidth
                        inputRef={refNumero}
                        variant='outlined'
                        size='small'
                        InputProps={{
                            maxLength: 4
                        }}
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1'>Complemento</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        fullWidth
                        inputRef={refComplemento}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1'>Cep</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        fullWidth
                        inputRef={refCep}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1'>Bairro</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField 
                        fullWidth
                        inputRef={refBairro}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>Cidade *</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField 
                        fullWidth
                        inputRef={refCidade}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>


            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>Estado *</Typography>
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        fullWidth
                        inputRef={refEstado}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>Email *</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField 
                        fullWidth
                        inputRef={refEmail}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1' style = {{fontWeight : 'bold'}}>Senha *</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField 
                        fullWidth
                        inputRef={refSenha}
                        variant='outlined'
                        size='small'
                        type = 'password'
                        InputLabelProps={{shrink:true}}/>
                </Grid>
            </Grid>

            <Grid container spacing = {1} style ={{marginTop : '5px'}}>
                <Grid item xs={4} style ={{backgroundColor : '#f5f5f5'}}>
                    <Typography variant='subtitle1'>Confirmação de Senha</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField 
                        fullWidth
                        inputRef={refConfirmaSenha}
                        variant='outlined'
                        size='small'
                        type = 'password'
                        InputLabelProps={{shrink:true}} onBlur={()=>{
                            if(refSenha.current && refConfirmaSenha.current){
                                if((refSenha.current.value != refConfirmaSenha.current.value)){
                                    refSenha.current.value = '';
                                    refConfirmaSenha.current.value = ''
                                    Swal.fire({
                                        title : 'Databit Collector', 
                                        text: 'Senha e confirmação não são iguais!', 
                                        icon : 'error', 
                                        showConfirmButton:false, 
                                        showCancelButton : false, 
                                        timer : 1000
                                    })
                                }
                            }
                        }}/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary" variant='contained'>
            Cancelar
          </Button>
          <Button autoFocus onClick={()=>{
           
            if(refCNPJ.current && refRazaoSocial.current && refCidade.current && refEstado.current && refEmail.current && refSenha.current){
               
                
                
                if(erro){
                    Swal.fire({
                        title : 'Databit Collector', 
                        text: 'Informe o CNPJ antes de continuar!', 
                        icon : 'error', 
                        showConfirmButton:false, 
                        showCancelButton : false, 
                        timer : 1000
                    })
                    return;
                }

                if(refRazaoSocial.current.value == ''){
                    Swal.fire({
                        title : 'Databit Collector', 
                        text: 'Informe a Razão Social antes de continuar!', 
                        icon : 'error', 
                        showConfirmButton:false, 
                        showCancelButton : false, 
                        timer : 1000
                    })
                    return;
                }

         

                if(refCidade.current.value == ''){
                    Swal.fire({
                        title : 'Databit Collector', 
                        text: 'Informe a Cidade antes de continuar!', 
                        icon : 'error', 
                        showConfirmButton:false, 
                        showCancelButton : false, 
                        timer : 1000
                    })
                    return;
                }

                if(refEstado.current.value == ''){
                    Swal.fire({
                        title : 'Databit Collector', 
                        text: 'Informe o Estado antes de continuar!', 
                        icon : 'error', 
                        showConfirmButton:false, 
                        showCancelButton : false, 
                        timer : 1000
                    })
                    return;
                }

                if(refSenha.current.value == ''){
                    Swal.fire({
                        title : 'Databit Collector', 
                        text: 'Informe a Senha antes de continuar!', 
                        icon : 'error', 
                        showConfirmButton:false, 
                        showCancelButton : false, 
                        timer : 1000
                    })
                    return;
                }


                Swal.fire({
                    title : 'Databit Collector', 
                    text: 'Deseja cadatrar esta nova revenda?', 
                    icon : 'question', 
                    showConfirmButton:true,
                    confirmButtonText : 'Sim', 
                    showCancelButton : true,
                    cancelButtonText: 'Não'
                }).then(resposta=>{
                    if(resposta.isConfirmed){
                        setLoading(true)
                        Conexao.post('empresasController/insertRevenda', {
                            razaoSocial : refRazaoSocial.current ? refRazaoSocial.current.value : '',
                            nomeFantasia : refRazaoSocial.current ? refRazaoSocial.current.value : '',
                            cnpj : refCNPJ.current ? refCNPJ.current.value : '',
                            inscEstadual : '',
                            endereco : refEndereco.current ? refEndereco.current.value : '', 
                            numero : refNumero.current ? refNumero.current.value : '',
                            cep : refCep.current ? refCep.current.value : '',
                            complemento : refComplemento.current ? refComplemento.current.value : '',
                            bairro : refBairro.current ? refBairro.current.value : '',
                            cidade : refCidade.current ? refCidade.current.value : '',
                            estado : refEstado.current ? refEstado.current.value : '',
                            telefoneComercial  :'' ,
                            telefoneCelular : '',
                            email : refEmail.current ? refEmail.current.value : '',
                            senha : refSenha.current ? md5(refSenha.current.value) : '',
                        }).then(response=>{
                            setLoading(false)
                            setOpen(false)
                           if(response.status == 200 && response.data.status =='OK'){
                                Swal.fire({
                                    title : 'Databit Collector', 
                                    text: 'Revenda cadastrada com sucesso!', 
                                    icon : 'success', 
                                    showConfirmButton:false, 
                                    showCancelButton : false, 
                                    timer : 1000
                                })
                           }else{
                                Swal.fire({
                                    title : 'Databit Collector', 
                                    text: 'Não foi possível cadastrar nova empresa!', 
                                    icon : 'warning', 
                                    showConfirmButton:false, 
                                    showCancelButton : false, 
                                    timer : 1000
                                })
                           }
                          
                        }).catch(error=>{
                            setOpen(false)
                            setLoading(false)
                            Swal.fire({
                                title : 'Databit Collector', 
                                text: 'Não foi possível cadastrar nova empresa! Erro Remoto', 
                                icon : 'error', 
                                showConfirmButton:false, 
                                showCancelButton : false, 
                                timer : 1000
                            })
                        })
                    }

                })
            }
          }} color="primary" variant='contained' disabled={loading}>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(ModalNovaRevenda)