import React, {Fragment, useState, useEffect} from 'react'
import {Typography} from '@material-ui/core'


export default function(props){

    return <div className='testimonial-container-main'>
        <div className='testinonial-img-container'>
            <img src={props.foto}  />
            {
                props.foto2 && <img src={props.foto2} className='img-home-flutter'/>
            }
        </div>
        <div className='testinonial-text-container'>
 
            <div>
                <br/>
                {
                    props.children
                }
            </div>         
        </div>
       
    </div>
}