import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Container, Grid, TextField, InputAdornment, Tooltip, Checkbox,Table, TableBody, TableRow,  TableHead, TableCell} from '@material-ui/core'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ModalBuscaModelo from '../../Modelos/Components/ModalBuscaModelo'
import LoopIcon from '@material-ui/icons/Loop';
import Swal from 'sweetalert2';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ModalBuscaClientes from '../../Clientes/ModalBuscaCliente'
import {Divider, Chip} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Conexao from '../../../providers/Conexao'
import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';

import ModalTipoNumerador from './ModalCadastroTipoNumerador'
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalEditarEquipamento(props) {

  const [equipamento, setEquipamento]= React.useState(props.equipamento);
  const [open, setOpen] = React.useState(false);
  const [modeloTemp, setModeloTemp] = React.useState([]);
  const [modeloAcessorioCompativel, setModeloAcessorioCompativel] = React.useState([]);
  const [clienteTemp, setClienteTemp] = React.useState({idcliente:'', razaosocial:''});
  const [optLocacao, setOptLocacao]= React.useState('');
  const [optAcessorio, setOptAcessorio ]= React.useState('');
  const [numeroSerie, setNumeroSerie]= React.useState('');
  const [observacoes, setObservacoes]= React.useState('');
  const [codigoAdicional , setCodigoAdicional]= React.useState(props.equipamento.codigoadicional);
  const [equipamentoExiste, setEquipamentoExiste]= React.useState(false);
  const [idCliente, setIdCliente]= React.useState(props.equipamento.idcliente);
  const [razaoSocial, setRazaoSocial]= React.useState(props.equipamento.razaosocial);
  const [marca, setMarca] = React.useState([])
  const [modelo, setModelo] = React.useState([])
  const [idMarca, setIdMarca]= React.useState('');
  const [idModelo, setIdModelo]= React.useState('');
  const [tiposContadores, setTiposContadores] = React.useState([])

  const [error, setError] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true);
    
  };
  const handleClose = () => {
    setOpen(false);
  
  };


 

  useEffect(()=>{
    if(open){
      console.log(props.equipamento)
      setMarca(props.equipamento.marca)
      setModelo(props.equipamento.modelo)
      setObservacoes(props.equipamento.observacoes)
      setOptLocacao(props.equipamento.locacao)     
      setIdMarca(props.equipamento.idmarca)  
      setIdModelo(props.equipamento.idmodelo)  
      setIdCliente(props.equipamento.idcliente)
      setRazaoSocial(props.equipamento.razaosocial)
      getDetalhesEquipamento();
      setError(false)        
      setEquipamentoExiste(false)
      
    }if(props.equipamento.acessorio == 'f'){
      setOptAcessorio(false)
      
    }else{
      setOptAcessorio(true)
    }if(props.equipamento.locacao =='f'){
      setOptLocacao(false)
    }else{
      setOptLocacao(true)
    }

    
  }, [open])

  useEffect(()=>{
    if(props.equipamentoSelecao){
        //setClienteTemp({idcliente:equipamentoSelecao, razaosocial:''})
        setModeloTemp(props.equipamentoSelecao)
    }
  }, [props.equipamentoSelecao])

  const updateEquipamento = () =>{
    Conexao.post('equipamentosController/updateEquipamento',{
      idempresa : props.equipamento.idempresa,
      idequipamento : props.equipamento.idequipamento,
      idcliente : idCliente,  
      modelo : modelo, 
      marca: marca,
      numeroserie : props.equipamento.numeroserie, 
      codigoadicional :codigoAdicional, 
      observacoes :observacoes, 
      locacao : optLocacao, 
      acessorio : optAcessorio
     
    }).then( response =>{
      if(response.data.status=='OK'){
        
        Swal.fire({
          title :'Gestão de Equipamentos', 
          text :'Equipamento Alterado  com sucesso !', 
          showCancelButton : false, 
          cancelButtonText : 'Cancelar', 
          showConfirmButton : false, 
          confirmButtonText : 'Confirmar', 
          icon:'success', 
          timer : 2000
        })
        props.getData()
      }else{
        console.log(response.data)
        Swal.fire({
          title :'Gestão de Equipamentos', 
          text :'Não foi possível Alterar !', 
          showCancelButton : false, 
          cancelButtonText : 'Cancelar', 
          showConfirmButton : false, 
          confirmButtonText : 'Confirmar', 
          icon:'warning', 
          timer : 2000
        })
      }
      setOpen(false)
    }).catch(error =>{
      
      Swal.fire({
        title :'Gestão de Equipamentos', 
        text :'Não foi possível alterar !', 
        showCancelButton : false, 
        cancelButtonText : 'Cancelar', 
        showConfirmButton : false, 
        confirmButtonText : 'Confirmar', 
        icon:'error', 
        timer : 1000
      })
      setOpen(false)
    });
  }


  const gerarPlaca = () =>{
    if(props.equipamento.numeroserie == null){
     Swal.fire({
       title :'Gestão de Equipamentos', 
       text :'É necessário preencher o NÚMERO DE SÉRIE!', 
       showCancelButton : false, 
       cancelButtonText : 'Cancelar', 
       showConfirmButton : false, 
       confirmButtonText : 'Confirmar', 
       icon:'warning', 
       timer : 3000
     })
   

    }else{
   Conexao.get('equipamentosController/createPatrimonioEquipamento?idEmpresa='+props.equipamento.idempresa+'&numeroSerie='+props.equipamento.numeroserie).then( response =>{
     if(response.data.status=='OK'){
       setCodigoAdicional(response.data.payload.idpatrimonio)
       Swal.fire({
         title :'Gestão de Equipamentos', 
         text :'Patrimonio gerado com sucesso!', 
         showCancelButton : false, 
         cancelButtonText : 'Cancelar', 
         showConfirmButton : false, 
         confirmButtonText : 'Confirmar', 
         icon:'success', 
         timer : 2000
       })
       
     }else{
       Swal.fire({
         title :'Gestão de Equipamentos', 
         text :'Não foi possível gerar !', 
         showCancelButton : false, 
         cancelButtonText : 'Cancelar', 
         showConfirmButton : false, 
         confirmButtonText : 'Confirmar', 
         icon:'warning', 
         timer : 2000
       })
     }

   }).catch(error =>{
     Swal.fire({
       title :'Gestão de Equipamentos', 
       text :'Não foi possível gerar patrimonio !', 
       showCancelButton : false, 
       cancelButtonText : 'Cancelar', 
       showConfirmButton : false, 
       confirmButtonText : 'Confirmar', 
       icon:'error', 
       timer : 1000
     })
     setOpen(false)
   });
 

  }
 }

  const getDetalhesEquipamento = () =>{
      Conexao.get('equipamentosController/getDetalhesEquipamento?idUsuario='+props.equipamento.idusuario+'&idEmpresa='+props.equipamento.idempresa+'&idEquipamento='+props.equipamento.idequipamento).then(response=>{
        if(response.data.status == 'OK'){
         
          setObservacoes(response.data.payload.observacoes)
          setClienteTemp({idcliente:props.equipamento.idcliente, razaosocial:props.equipamento.razaosocial})

     
      
        }else{
        //  alert(JSON.stringify(response.data))
        }
      }).catch(error=>{
       // alert(error)
      })
  }

  const getTiposNumeradorEquipamento = () =>{
    Conexao.post('equipamentosController/getTiposNumeradorEquipamento', {
      idempresa : props.equipamento.idempresa, 
      idequipamento : props.equipamento.idequipamento
    }).then(response=>{
      if(response.data.status=='OK'){
        setTiposContadores(response.data.payload)
      }else{
        setTiposContadores([])
      }
    }).catch(error=>{
      setTiposContadores([])
    })
  }

  return (
    <div>
      
      <Button variant='outlined' color='primary' onClick={handleClickOpen}><BuildTwoToneIcon /></Button>
      


      <Dialog disableBackdropClick hideBackdrop  onClose={handleClose} scroll='body' fullWidth aria-labelledby="customized-dialog-title" open={open } disableBackdropClick disableEscapeKeyDown maxWidth='md'>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Editar Informações do  Equipamento
        </DialogTitle>
        <DialogContent dividers >
          <Grid container spacing={1}>
              <Grid item xs={3}>
                  <TextField
                    disabled
                    label='Número de Série'
                  
                    fullWidth
                    variant='outlined'
                    InputLabelProps ={{shrink : true}}
                    placeholder='Informe o serial'
                    value = {props.equipamento.numeroserie}
                   
                    size='small'
                    inputProps={{maxlength:"60"}}
                    
                    />
              </Grid> 
              <Grid item xs={2}>
                 {props.equipamento.codigoadicional == null ? <TextField
                    label='Patrimônio'
                    fullWidth
                    disabled={props.equipamento.codigoadicional  == !null}
                    variant='outlined'
                    placeholder='Número placa'
                    defaultValue = {props.equipamento.codigoadicional ? props.equipamento.codigoadicional : codigoAdicional}
                    value = {props.equipamento.codigoadicional ? props.equipamento.codigoadicional : codigoAdicional}
                    onChange = {(e)=>setCodigoAdicional(e.target.value)}
                    inputProps={{maxlength:"25"}}                    
                    InputProps ={{
                        startAdornment : (
                            <InputAdornment position ='start'>                              
                                <LoopIcon 
                                    style ={{cursor :'pointer'}}
                                    onClick={()=>{
                                      if(props.equipamento.codigoadicional == null){                                     
                                    Swal.fire({
                                        title :'Gestão de Equipamentos', 
                                        text:'Deseja gerar um Número de Patrimônio automáticamente ?', 
                                        icon :'question', 
                                        showCancelButton : true, 
                                        cancelButtonText : 'Cancelar', 
                                        showConfirmButton : true, 
                                        confirmButtonText : 'Confirmar'
                                    }).then(resposta=>{
                                        if(resposta.isConfirmed){
                                           
                                          gerarPlaca()
                                          setCodigoAdicional(codigoAdicional)
                                        }
                                    })
                                  }else{
                                    Swal.fire({
                                      title :'Gestão de Equipamentos', 
                                      text:'Já Existe uma placa para esse Equipamento!', 
                                      icon :'warning', 
                                    
                                      showConfirmButton : true, 
                                      confirmButtonText : 'Confirmar'
                                  })

                                  }
                                }}/>
                            </InputAdornment>
                        )
                    }} 
                  
                    size='small'
                    onBlur = {(e)=>{
                      if(e.target.value){
                        Conexao.post('equipamentosController/codigoAdicionalExiste', {
                          idusuario : props.idUsuario, 
                          idempresa : props.idEmpresa,
                          codigoadicional : e.target.value
                        }).then(response =>{
                    
                          if(response.data.payload =='T'){
                            
                            Swal.fire({
                              title :'Gestão de Equipamentos', 
                              text :'Já existe um equipamento cadastrado com este número de Patrimônio', 
                              showCancelButton : false, 
                              showConfirmButton : true, 
                              confirmButtonText : 'OK', 
                              icon : 'info'
                            })
                          }else{
                          
                          }
                        // setEquipamentoExiste(false)
                        }).catch(error =>{
                          
                        })
                    }
                    }}
                    InputLabelProps ={{shrink : true}}
                    /> : <TextField
                    label='Patrimônio'
                    fullWidth
                    disabled={props.equipamento.codigoadicional  == !null}
                    variant='outlined'
                    placeholder='Número placa'
                    defaultValue = {props.equipamento.codigoadicional ? props.equipamento.codigoadicional : codigoAdicional}
                    value = {props.equipamento.codigoadicional ? props.equipamento.codigoadicional : codigoAdicional}
                    onChange = {(e)=>setCodigoAdicional(e.target.value)}
                    inputProps={{maxlength:"25"}} 
                    size='small'
                    onBlur = {(e)=>{
                      if(e.target.value){
                        Conexao.post('equipamentosController/codigoAdicionalExiste', {
                          idusuario : props.idUsuario, 
                          idempresa : props.idEmpresa,
                          codigoadicional : e.target.value
                        }).then(response =>{
                    
                          if(response.data.payload =='T'){
                            
                            Swal.fire({
                              title :'Gestão de Equipamentos', 
                              text :'Já existe um equipamento cadastrado com este número de Patrimônio', 
                              showCancelButton : false, 
                              showConfirmButton : true, 
                              confirmButtonText : 'OK', 
                              icon : 'info'
                            })
                          }else{
                          
                          }
                        // setEquipamentoExiste(false)
                        }).catch(error =>{
                          
                        })
                    }
                    }}
                    InputLabelProps ={{shrink : true}}
                  /> }
              </Grid> 

              <Grid item xs={2}>
                    <TextField
                        label='Marca'
                        fullWidth
                        size='small'
                        error={error}  
                        helperText={(error && marca =='') ? 'Informe a Marca' : ''}                  
                        variant='outlined'
                        value = {marca}
                        onChange={(e)=>setMarca(e.target.value)}
                        placeholder='Marca do equipamento'
                        InputLabelProps ={{shrink : true}}
                        />                    
             
                </Grid>   

                <Grid item xs={5}>
                  <TextField
                    label='Modelo'
                    fullWidth
                    variant='outlined'
                    placeholder='Modelo'                    
                    error={error}   
                    value = {modelo}
                    onChange={(e)=>setModelo(e.target.value)}               
                    helperText={(error && modelo =='') ? 'Informe o Modelo' : ''}                    
                    InputLabelProps ={{shrink : true}}
                   
                    size='small'
                    />
                       
              </Grid>         
            </Grid>

          <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs={5}>
                    <TextField 
                        size='small'
                        label='Cliente '
                        fullWidth
                        variant='outlined'                        
                        placeholder='Cliente'  
                        value = {idCliente ? idCliente+' - '+razaoSocial : ''}    
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        InputProps={{
                          endAdornment : <InputAdornment position='end'>
                            <ModalBuscaClientes updateCliente={(cliente)=>{
                              setIdCliente(cliente.idcliente)
                              setRazaoSocial(cliente.razaosocial)
                            }}/>
                          </InputAdornment>
                        }}
                        />
                </Grid>

                <Grid item xs={3}>
                    <TextField 
                        size='small'
                        label='Endereço Instalação'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''      
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>

                <Grid item xs={1}>
                    <TextField 
                        size='small'
                        label='Número'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''      
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>
                <Grid item xs={1}>
                    <TextField 
                        size='small'
                        label='CEP'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''      
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        size='small'
                        label='Bairro'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''      
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>
          </Grid>

          <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs={2}>
                    <TextField 
                        size='small'
                        label='Setor'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''    
                        value={equipamento.setor}  
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        size='small'
                        label='Cidade'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''    
                        value={equipamento.setor}  
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>

                <Grid item xs={1}>
                    <TextField 
                        size='small'
                        label='Estado'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''    
                        value={equipamento.setor}  
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        size='small'
                        label='Responsável'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''      
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        size='small'
                        label='Tel. Responsável'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''      
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>
          </Grid>
     
          <Grid container spacing = {1} style ={{marginTop:'10px'}}>
            
                <Grid item xs={12}>
                    <TextField 
                        size='small'
                        label='Observações '
                        multiline
                        rowsMax={2}
                        rows={2}
                        fullWidth
                        variant='outlined'                        
                        placeholder='Observações gerais'
                        value = {observacoes}
                        inputProps={{maxlength:"500"}}
                        onChange = {(e)=>setObservacoes(e.target.value)}
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>
              </Grid>
              <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs={2}>
                    <TextField 
                        size='small'
                        label='Forma de cadastro'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''    
                        value={equipamento.formacadastro}  
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        />
                </Grid>
                <Grid item xs={5}>
                    <TextField 
                        size='small'
                        label='Equipamento de locação?'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''    
                        select 
                        SelectProps={{native:true}} 
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        onChange={(e)=>setOptLocacao(e.target.value)}
                      >
                        <option value={optLocacao}>{optLocacao ? 'SIM' : 'NÃO'}</option>
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                    </TextField>
                </Grid>

                <Grid item xs={5}>
                    <TextField 
                        size='small'
                        label='É um acessório?'
                        fullWidth
                        variant='outlined'                        
                        placeholder=''    
                        select 
                        SelectProps={{native:true}} 
                        inputProps={{maxlength:"500"}}            
                        InputLabelProps ={{shrink : true}}
                        onChange={(e)=>setOptAcessorio(e.target.value)}
                      >
                         <option value={optAcessorio}>{optAcessorio ? 'SIM' : 'NÃO'}</option>
                         <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                    </TextField>
                </Grid>
              </Grid>
          {/*    
         <Table className='table table-striped table-bordered table-hover table-sm table-small' style ={{marginTop:'10px'}}>
                <TableHead>
                  <TableCell align='left' style ={{width : '70%'}}>Descrição do numerador</TableCell>
                  <TableCell align='center' style ={{width : '20%'}}>Multiplicador</TableCell>
                  <TableCell align='center' style ={{width : '10%'}}>Remover</TableCell>
                </TableHead>
         </Table>
          */}
        </DialogContent>
        <DialogActions>
          <Grid container spacing= {2}>
            <Grid item xs ={3}>
             {
               //<ModalTipoNumerador equipamento={props.equipamento}/>
             } 
            </Grid>
            <Grid item xs ={5}>

            </Grid>

            <Grid item xs ={2}>
              <Button fullWidth color='secondary' onClick={handleClose} variant='contained'>Cancelar</Button>
            </Grid>

            <Grid item xs ={2}>
              <Button autoFocus disabled={equipamentoExiste} variant='contained' fullWidth onClick={()=>{
              
              Swal.fire({
                title :'Gestão de Equipamentos', 
                text :'Deseja alterar o equipamento agora ?', 
                showCancelButton : true, 
                cancelButtonText : 'Cancelar', 
                showConfirmButton : true, 
                confirmButtonText : 'Confirmar', 
                icon:'question'
              }).then(resposta=>{
                if(resposta.isConfirmed){
      
                updateEquipamento();
                  
                }else{
                            
                }
              })
            }} color="primary">
              Gravar
            </Button>
            </Grid>
          </Grid>
         
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
